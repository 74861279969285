import { toLocaleWithFallback } from '@peloton/internationalize/models/locale';
import { NextNotFoundError } from '@peloton/next/errors/NextNotFoundError';
import { NextRedirectError } from '@peloton/next/errors/NextRedirectError';
import type { FetcherOpts, Fetcher } from '@page-builder/hooks/useCustomPageData';
import { fetchClassContent } from './fetchClassContent';
import { fetchFreeClassDataByDiscipline } from './fetchFreeClassDataByDiscipline';
import { isDisciplineSlug } from './helpers';
import { transformLocale } from './transformLocale';
import { validateDisciplinePath } from './validateDisciplinePath';

export const fetchClassDataApi: Fetcher = async ({
  slug,
  locale,
  preview,
  allowsRejections,
}: FetcherOpts) => {
  const localeApi = transformLocale(locale!);
  const fallbackLocale = toLocaleWithFallback(localeApi); // TODO: Remove if Austria implements separate classes
  const urlArray = slug.split('/');
  const classSlug = urlArray.pop();
  const disciplineUrl = urlArray.join('/');
  const disciplineSlug = urlArray.pop();

  try {
    const localeClassData = await fetchClassContent(classSlug!, fallbackLocale);
    const freeClass = await fetchFreeClassDataByDiscipline({
      locale,
      preview,
      discipline: disciplineSlug,
    });

    if (allowsRejections) {
      validateDisciplinePath({
        discipline: localeClassData.fitnessDiscipline,
        path: slug,
      });
    }

    return { ...localeClassData, freeClass };
  } catch (error) {
    if (
      error instanceof NextNotFoundError &&
      isDisciplineSlug(disciplineSlug as string)
    ) {
      //Redirect to discipline page if class is missing
      throw new NextRedirectError({
        statusCode: 302,
        currentPath: slug,
        newPath: `${disciplineUrl}`,
      });
    }
    throw error;
  }
};
