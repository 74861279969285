import { css } from 'styled-components';
import { zIndex } from './constants';

export type ATCBandProps = {
  top?: string;
  bottom?: string;
};

const sharedScrollHeadbandStyles = css<ATCBandProps>`
  left: 0;
  z-index: ${zIndex.HEADBAND};
  position: fixed;
  width: 100%;
`;

export const webScrollHeadbandStyles = css<ATCBandProps>`
  ${sharedScrollHeadbandStyles}
  top: ${props => props.top};
  transition: top 0.2s ease-in-out;
`;

export const mobileScrollHeadbandStyles = css<ATCBandProps>`
  ${sharedScrollHeadbandStyles}
  bottom: ${props => props.bottom};
  transition: bottom 0.2s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
`;
