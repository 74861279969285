import { useEffect, useState } from 'react';

type MobileProps = {
  isAndroid: boolean;
  isiOS: boolean;
};

const useNextMobilePlatform = (): MobileProps => {
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);

  useEffect(() => {
    const sanitizedUserAgent = navigator.userAgent.toLowerCase();
    setIsAndroid(sanitizedUserAgent.includes('android'));
    const iPadVersion13 =
      sanitizedUserAgent.includes('mac') &&
      (navigator.maxTouchPoints > 1 || 'ontouchstart' in window);
    setIsiOS(/ipad|iphone|ipod/.test(sanitizedUserAgent) || iPadVersion13);
  }, []);

  return {
    isAndroid,
    isiOS,
  };
};

export default useNextMobilePlatform;
