import React from 'react';
import styled from 'styled-components';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { getContentLinkAndName } from '../layout/error-handling/ErrorMessage';

const CopyXray: React.FC<
  React.PropsWithChildren<{
    block: any;

    children: JSX.Element;
  }>
> = ({ block, children }) => {
  const isCopyXrayActive = useIsToggleActive()('copyXray');

  const { href, moduleName } = getContentLinkAndName(block);
  return isCopyXrayActive ? (
    <StyledCopyXray>
      <a target="_blank" href={href} rel="noreferrer" className="copyxraylink">
        Module Name: {moduleName}
        {children}
      </a>
    </StyledCopyXray>
  ) : (
    children
  );
};

const StyledCopyXray = styled.div`
  .copyxraylink {
    color: blue;
  }
  padding: 1rem;
  margin: 1rem;
  outline: 2px dashed green;
  outline-offset: 0.5em;
  position: relative;

  :hover {
    cursor: pointer;
    outline: 2px dashed purple;
  }
`;
export default CopyXray;
