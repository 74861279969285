import type { CompareProps } from './CompareModule';
import type { ProductProps, Rating } from './types';

const DEFAULT_RATING_THRESHOLD = 4.0;
const MINIMUM_REVIEWS_QUANTITY = 20;

export const isValidRating = (rating: Rating) =>
  rating.averageOverallRating >= DEFAULT_RATING_THRESHOLD &&
  rating.totalCount >= MINIMUM_REVIEWS_QUANTITY;

export const formatRating = (rating: Rating['averageOverallRating']) => {
  return Math.round(rating * 10) / 10;
};

export const getValuePropsLength = (
  isExpanded: boolean,
  product: ProductProps,
  maxValuePropAmount: number,
) => {
  return isExpanded ? product.valueProps?.length : maxValuePropAmount;
};

export const getGridRowCount = (
  products: CompareProps['products'],
  isExpanded: boolean,
  maxValuePropAmount: number,
) => {
  const maxRowCount = Math.max(
    ...products.map(p => getValuePropsLength(isExpanded, p, maxValuePropAmount) || 0),
  );
  return maxRowCount + 1;
};
