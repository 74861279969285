import { Container, Eyebrow, Flex, Label, spacing } from '@pelotoncycle/design-system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ReviewQuery } from '@ecomm/reviews/models';
import {
  CAROUSEL_CLICK_DELAY,
  OBSERVER_HANDLER_DELAY,
} from '@page-builder/modules/VideoCarousel/constants';
import Pagination from '@page-builder/modules/VideoCarousel/Pagination';
import {
  getObserver,
  getScrollToLeftPosition,
  usePaginationPropValues,
} from '@page-builder/modules/VideoCarousel/utils';
import AIReviewCard from './AIReviewCard';
import { CARD_HEIGHTS, CARD_WIDTHS, MAX_CARDS_PER_SLIDE } from './constants';
import { themes } from './themes';

export type RecentReviewsCarouselProps = {
  reviewsProps: ReviewQuery[];
  numberOfReviewsToDisplay: number;
  setActiveRating: (rating: number | undefined) => void;
  setActiveReviewId: (reviewId: string | undefined) => void;
  setIsReviewsModalOpen: () => void;
  theme: 'White' | 'Black';
};

const RecentReviewsCarousel = ({
  reviewsProps,
  numberOfReviewsToDisplay,
  setActiveRating,
  setActiveReviewId,
  setIsReviewsModalOpen,
  theme,
}: RecentReviewsCarouselProps) => {
  const { trackEvent } = useTracking();
  const {
    cardBackgroundColor,
    modalBackgroundColor,
    textColor,
    starsColor,
    itemTextColor,
  } = themes[theme];
  const reviewCardStyles = {
    textColor: textColor,
    starsColor: starsColor,
    itemTextColor: itemTextColor,
    cardBackgroundColor: cardBackgroundColor,
    cardWidths: CARD_WIDTHS,
    cardHeights: CARD_HEIGHTS,
  };
  const [activePageIndex, setActivePageIndex] = useState<number>(0);

  // Set number of reviews per slide and total number of slides based on viewport.
  const isMobile = useMedia(`(max-width: ${BreakpointWidth.tablet}px)`);
  const maxCardsPerSlide = isMobile
    ? MAX_CARDS_PER_SLIDE.mobile
    : MAX_CARDS_PER_SLIDE.desktop;
  const totalSlideCount = Math.ceil(numberOfReviewsToDisplay / maxCardsPerSlide);
  // Only get the specified number of most recent reviews.
  const recentReviews = reviewsProps.slice(0, numberOfReviewsToDisplay);

  // Element Refs that help determine the scroll position of the carousel items.
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  // Used for intersection observers for mobile viewports to allow user to swipe through cards.
  const timeoutIdsRef = useRef<Record<number, ReturnType<typeof setTimeout>>>({});
  const observerRefs = useRef<(IntersectionObserver | null)[]>([]);
  const activePageIndexRef = useRef(activePageIndex);

  // Related microcopy to display on carousel.
  const seeAllLabel = useMicroCopy('seeAllLabel');
  const carouselHeadline = useMicroCopy('reviewsMostRecentCarouselHeadline');
  const carouselPaginationTextRaw = useMicroCopy(
    'reviewsMostRecentCarousel.paginationTextRaw',
  );

  const sendCarouselTrackingEvent = useCallback(() => {
    trackEvent({
      event: TrackingEvent.ClickedReviewsCarousel,
      properties: {
        page: window.location.pathname,
        parent: 'Component: Reviews',
        parentType: 'AI Most Recent Reviews Carousel',
      },
    });
  }, [trackEvent]);

  const handleActiveItem = (slideIndex: number) => {
    setActivePageIndex(slideIndex);
    scrollToActiveItem(slideIndex);
    sendCarouselTrackingEvent();
  };

  // Values to pass into the Pagination component.
  const paginationPropValues = usePaginationPropValues(
    activePageIndex,
    totalSlideCount,
    carouselPaginationTextRaw,
    handleActiveItem,
  );

  useEffect(() => {
    // Used to keep track of the active index as state is not up to date within the observer handler.
    activePageIndexRef.current = activePageIndex;
  }, [activePageIndex]);

  /**
   * Handles setting the inactive review card based on the scroll position (FOR MOBILE).
   */
  const handleActiveCardOnMobileScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]; // each observer is observing a single element
      if (!entry) {
        return;
      }

      const isElementWithinRootBounds = entry.isIntersecting;
      const cardIndex = itemRefs.current.indexOf(entry.target as HTMLElement);

      if (isElementWithinRootBounds && activePageIndexRef.current !== cardIndex) {
        timeoutIdsRef.current[cardIndex] = setTimeout(() => {
          setActivePageIndex(cardIndex);
          sendCarouselTrackingEvent();
        }, OBSERVER_HANDLER_DELAY);
      } else {
        clearTimeout(timeoutIdsRef.current[cardIndex]);
      }
    },
    [sendCarouselTrackingEvent],
  );

  useEffect(() => {
    // Disconnect all current observers
    observerRefs.current.forEach(observer => observer?.disconnect());
    const observers = observerRefs.current;

    // Set up functionality for mobile viewports where users are able to set inactive cards via scroll.
    if (isMobile) {
      for (const [cardIndex, cardElement] of itemRefs.current.entries()) {
        const totalCardsLength = itemRefs.current.length;

        if (scrollContainerRef.current && cardElement) {
          const newObserver = getObserver(
            scrollContainerRef,
            cardElement,
            cardIndex,
            totalCardsLength,
            handleActiveCardOnMobileScroll,
          );

          observers[cardIndex] = newObserver;
          observers[cardIndex]?.observe(cardElement);
        }
      }
    }

    return () => {
      observers.forEach(observer => observer?.disconnect());
    };
  }, [handleActiveCardOnMobileScroll, isMobile]);

  /**
   * Scrolls the new active card to the center position of the scroll container.
   * @param slideIndex index of the current slide.
   */
  const scrollToActiveItem = (slideIndex: number) => {
    const scrollReviewsContainer = scrollContainerRef.current;
    const activeSlide = itemRefs.current[slideIndex];

    if (activeSlide && scrollReviewsContainer) {
      const activeItemPosition = getScrollToLeftPosition(
        activeSlide,
        scrollReviewsContainer.offsetWidth,
        slideIndex,
        totalSlideCount,
      );

      setTimeout(
        () => scrollReviewsContainer.scrollTo({ left: activeItemPosition }),
        CAROUSEL_CLICK_DELAY,
      );
    }
  };

  /**
   * Renders the review cards in a wrapper for each slide and sets the ref on the slide for the carousel functionality.
   * @param slideIndex index of the slide being created. This is used to set the ref on the slide for the carousel to scroll to.
   * @param cards the AI review card elements that will be displayed in the slide.
   */
  const renderSlide = (slideIndex: number, cards: React.JSX.Element[]) => {
    return (
      <Slide
        data-test-id="review-card-slide"
        key={`review-card-slide-${slideIndex}`}
        ref={(el: HTMLElement | null) => {
          itemRefs.current[slideIndex] = el;
        }}
      >
        {cards}
      </Slide>
    );
  };

  /**
   * Creates a card for each review, then wraps and renders the expected number of reviews in a slide.
   */
  const renderAndMapCardsToSlide = () => {
    const reviewCardPages = [];
    let slideIndex = 0;

    // Maps and creates an AIReviewCard element for each review.
    const reviewCards = recentReviews.map(review => (
      <AIReviewCard
        key={`review-${review.id}`}
        review={review}
        isInCarousel={true}
        setActiveRating={setActiveRating}
        setActiveReviewId={setActiveReviewId}
        setIsReviewsModalOpen={setIsReviewsModalOpen}
        {...reviewCardStyles}
      />
    ));

    while (reviewCards.length) {
      // Wraps the max number of cards for each slide in the carousel.
      const cardsInSlide = reviewCards.splice(0, maxCardsPerSlide);
      const slide = renderSlide(slideIndex, cardsInSlide);
      reviewCardPages.push(slide);
      slideIndex++;
    }

    return reviewCardPages;
  };

  const handleSeeAllCarouselCtaClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        page: window.location.pathname,
        parent: 'Component: Reviews',
        parentType: 'AI Most Recent Reviews Carousel',
        unitName: 'See All Reviews CTA',
        linkName: seeAllLabel,
        linkTo: 'See All Reviews Modal',
      },
    });
    setActiveRating(undefined);
    setActiveReviewId(undefined);
    setIsReviewsModalOpen();
  };

  const reviewCardsPages = renderAndMapCardsToSlide();

  return (
    <Container verticalPadding={spacing[48]}>
      <Flex
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        alignItems={{ mobile: 'flex-start', desktop: 'center' }}
        flexGrow={1}
        justifyContent={carouselHeadline ? 'space-between' : 'flex-end'}
        gap={{ mobile: spacing[8] }}
        horizontalPadding={{ mobile: spacing[16], tablet: spacing[16], desktop: 0 }}
      >
        <Flex gap={{ mobile: spacing[12], desktop: spacing[16] }}>
          <Eyebrow size="medium" textColor={textColor}>
            {carouselHeadline}
          </Eyebrow>
          <button onClick={handleSeeAllCarouselCtaClick}>
            <StyledLabel size="medium" textColor={textColor}>
              {seeAllLabel}
            </StyledLabel>
          </button>
        </Flex>
        <Pagination
          {...paginationPropValues}
          textColorOverride={textColor}
          buttonOutlineColorOverride={textColor}
          backgroundColorOverride={modalBackgroundColor}
        />
      </Flex>
      <ReviewCardsCarouselContainer
        data-test-id="review-cards-carousel"
        flexDirection="row"
        verticalPadding={{ mobile: spacing[16], desktop: spacing[24] }}
        ref={scrollContainerRef}
        isMobile={isMobile}
      >
        <SlideContainer isMobile={isMobile}>{reviewCardsPages}</SlideContainer>
      </ReviewCardsCarouselContainer>
    </Container>
  );
};

export default RecentReviewsCarousel;

const StyledLabel = styled(Label)`
  text-decoration: underline;
  text-underline-offset: 3px;
`;

const ReviewCardsCarouselContainer = styled(Flex)<{ isMobile: boolean }>`
  overflow: ${({ isMobile }) => (isMobile ? 'scroll' : 'hidden')};
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  white-space: nowrap;

  // to hide the scrollbar
  box-sizing: content-box;

  // hide scrollbar on safari
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SlideContainer = styled.ul<{ isMobile: boolean }>`
  display: grid;
  grid-gap: ${({ isMobile }) => (isMobile ? `${spacing[16]}` : `${spacing[24]}`)};
  grid-auto-flow: column;
  list-style-type: none;
  margin: 0;
  padding: 0 ${spacing[16]};
  position: relative;
  width: fit-content;

  @media (min-width: ${BreakpointWidth.desktopLarge}px) {
    padding: 0;
  }
`;

const Slide = styled.li`
  display: flex;

  @media (min-width: ${BreakpointWidth.smallScreen}px) {
    gap: 0;
  }
  @media (min-width: ${BreakpointWidth.tablet}px) {
    gap: ${spacing[24]};
    position: relative;
    width: calc(100vw - 32px);
  }
  @media (min-width: ${BreakpointWidth.tabletXLarge}px) {
    width: calc(100vw - 160px);
  }
  @media (min-width: ${BreakpointWidth.desktopLarge}px) {
    width: calc(100vw - 80px);
  }
  @media (min-width: ${BreakpointWidth.desktopXLarge}px) {
    width: 100vw;
    max-width: 1224px;
  }
`;
