import { MuscleGroups } from '@peloton/models/PelotonClass';
import type { MuscleGroupScore } from '@peloton/models/PelotonClass';

export type MuscleGroupBreakdown = {
  readonly muscleGroup: keyof typeof MuscleGroups;
  percentage?: number;
  bucket?: MuscleGroupScore['bucket'];
};

export const buildOrderedMuscleGroupBreakdowns = (
  muscleGroupScores: MuscleGroupScore[],
) => {
  return muscleGroupScores
    .sort((first, second) => (second.score ?? 0) - (first.score ?? 0))
    .flatMap(({ muscleGroup, percentage, bucket }) =>
      muscleGroup
        ? {
            muscleGroup,
            percentage,
            bucket,
          }
        : [],
    );
};

export const mergeExcessMuscleGroupBreakdowns = (
  orderedMuscleGroupBreakdowns: MuscleGroupBreakdown[],
  maxCount: number,
  renderOtherTagAtMaxCount?: boolean,
) => {
  if (
    orderedMuscleGroupBreakdowns.length < maxCount ||
    (orderedMuscleGroupBreakdowns.length === maxCount && !renderOtherTagAtMaxCount)
  )
    return orderedMuscleGroupBreakdowns;

  const unmergedCount = maxCount - 1;
  const otherMuscleGroupBreakdown: MuscleGroupBreakdown = orderedMuscleGroupBreakdowns
    .slice(unmergedCount)
    .reduce(
      (acc: MuscleGroupBreakdown, curr) => {
        acc.percentage = (acc.percentage ?? 0) + (curr.percentage ?? 0);
        return acc;
      },
      {
        muscleGroup: MuscleGroups.other,
        percentage: 0,
      },
    );

  return [
    ...orderedMuscleGroupBreakdowns.slice(0, unmergedCount),
    otherMuscleGroupBreakdown,
  ];
};

export const buildMuscleGroupBreakdownRows = (
  muscleGroupScores: MuscleGroupScore[],
  maxCount?: number,
): MuscleGroupBreakdown[] => {
  const orderedMuscleGroupBreakdowns = buildOrderedMuscleGroupBreakdowns(
    muscleGroupScores,
  );

  return maxCount && orderedMuscleGroupBreakdowns.length >= maxCount
    ? mergeExcessMuscleGroupBreakdowns(orderedMuscleGroupBreakdowns, maxCount, true)
    : orderedMuscleGroupBreakdowns;
};
