import { Locale, toLocaleFromString } from '@peloton/internationalize/models/locale';

// locale pattern can be .locale from TLD or lang-Locale from route
export const transformLocale = (locale: string): Locale => {
  // if locale is from route
  if (locale.includes('-')) {
    // for english we're building with US locale always
    if (locale.includes('en')) return Locale.Default;

    // otherwise we assume locale is correct and return locale from route
    return toLocaleFromString(locale);
  }

  // Next approach could provide more locales, that currently are not supported
  // return toLocaleFromCountry(toCountry(locale));

  const localeMap = {
    '.de': Locale.GermanGermany,
    '.com': Locale.Default,
    '.co.uk': Locale.Default,
    '.ca': Locale.Default,
    '.com.au': Locale.Default,
    '.at': Locale.GermanGermany,
  };

  return localeMap[locale] || Locale.Default;
};
