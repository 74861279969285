import {
  Container,
  Display,
  Flex,
  Label,
  Media,
  grey,
  spacing,
  brand,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media as mediaBreakpoints } from '@peloton/styles';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import usePromoDiscount from '@content/client/hooks/usePromoDiscount';
import { toBundleTypeFromDisplayName } from '@ecomm/shop/models';
import { BundleType } from '@ecomm/shop/models/BundleType';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { toImageProps, getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';

const BIKE_IMAGE_DESKTOP_SIZE = 168;
const BIKE_IMAGE_MOBILE_SIZE = 144;

type MediaSectionProps = {
  item: TypeComponentGenericTextWithMedia;
};

const refurbMap = {
  [BundleType.Bike]: BundleType.RefurbishedBike,
  [BundleType.BikePlus]: BundleType.RefurbishedBikePlus,
};

const CompareBikesMediaSection: React.FC<React.PropsWithChildren<MediaSectionProps>> = ({
  item,
}) => {
  const { media } = item.fields;
  const {
    headline: productName,
    body: subheadDescription,
    label: pricingLabel,
  } = getTextFieldsWithRequiredKeys(['headline', 'body', 'label'], item.fields.text);
  const image = toImageProps(media);

  const bundleType = toBundleTypeFromDisplayName(productName) as BundleType;
  const { data } = useCfuPromo(bundleType);
  const buyNewDiscount = usePromoDiscount(data);

  const { data: refurbData } = useCfuPromo(refurbMap[bundleType]);
  const refurbDiscount = refurbData?.fields.bundles[0].fields.couponDiscount;

  return (
    <Container>
      <Flex
        gap={spacing[8]}
        flexDirection="column"
        padding={{
          mobile: `${spacing[24]} ${spacing[8]} ${spacing[24]}`,
          desktop: `${spacing[24]} ${spacing[24]} ${spacing[32]}`,
        }}
      >
        <Container gap={spacing[8]}>
          <Display as="h2" size="small" color={grey[90]}>
            {productName}
          </Display>
          <Label size="large" textColor={grey[70]}>
            {subheadDescription}
          </Label>
        </Container>
        {image && (
          <StyledMedia
            data-test-id="compare-bikes-media-section-image"
            media={{
              mobile: toCloudinarySrc({
                src: image.mobile,
                height: BIKE_IMAGE_MOBILE_SIZE,
              }),
              desktop: toCloudinarySrc({
                src: image.mobile,
                height: BIKE_IMAGE_DESKTOP_SIZE,
              }),
              alt: image.alt,
              type: 'image',
              loading: 'lazy',
            }}
          />
        )}
        {pricingLabel && (
          <Flex
            flexDirection="column"
            gap={spacing[4]}
            data-test-id="compare-bikes-media-section-priceInfo"
          >
            {pricingLabel
              .split('|')
              .filter(Boolean)
              .map((pricingText: string, index: number) => {
                const hasDiscount =
                  (index === 1 && refurbDiscount) || (index === 2 && buyNewDiscount);
                return (
                  <Label
                    key={`media-section-${pricingText}`}
                    size="small"
                    textColor={grey[70]}
                  >
                    <Markdown
                      content={pricingText}
                      markdown={{
                        renderers: { emphasis: hasDiscount ? PromoText : NonPromoText },
                      }}
                    />
                  </Label>
                );
              })}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

const StyledMedia = styled(Media)`
  img {
    height: ${BIKE_IMAGE_MOBILE_SIZE}px;
    width: ${BIKE_IMAGE_MOBILE_SIZE}px;

    ${mediaBreakpoints.tablet`
      height: ${BIKE_IMAGE_DESKTOP_SIZE}px;
      width: ${BIKE_IMAGE_DESKTOP_SIZE}px;
    `}
  }
`;

const PromoText = styled(Label).attrs({ size: 'small', is: 'span' })`
  color: ${brand.primary};
`;

const NonPromoText = styled(Label).attrs({ size: 'small', is: 'span' })`
  color: ${grey[70]};
`;

export default CompareBikesMediaSection;
