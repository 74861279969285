import React from 'react';

type Options = {
  observerOptions?: Partial<IntersectionObserverInit>;
};

const useSectionObserver = (sectionIds: Array<string>, options?: Options) => {
  const [activeId, setActiveId] = React.useState<string | undefined>(sectionIds[0]);
  const observerRef = React.useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    // 1: Always disconnect existing observer
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // 2: Exit if there's nothing to observe
    if (!sectionIds.length) {
      observerRef.current = null;
      return;
    }

    // 3: Build list of IDs to be observed, and include any IDs which should clear activeId (ex. modules at bottom of page)
    const clearIds = document.getElementById('clear') ? ['#clear'] : [];
    const observedIds = [...sectionIds, ...clearIds, '#footer'];

    // 4: Instantiate observer
    const observer = new IntersectionObserver(entries => {
      // On initial load (everything comes into view), just use whatever is currently intersecting
      // Otherwise need final entry in case more than one is observed, ex. due to fast scrolling
      const isInitialLoad = entries.length >= sectionIds.length;
      const entry = isInitialLoad
        ? entries.find(e => e.isIntersecting) || entries[0]
        : entries.slice(-1)[0];

      const entryId = `#${entry.target.getAttribute('id')}`;

      // Ignore intersections off-page: we only care that a section crosses a certain boundary on-page
      // Visual aid: https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/42616389738/Intersection+Observers#6
      const isOnPage = entry.boundingClientRect.top >= 0;
      if (isOnPage) {
        if (entry.isIntersecting) {
          // We are scrolling DOWN (into a section) and should use its id
          setActiveId(entryId);
        } else {
          // We are scrolling UP (out of a section) and should use previous id
          const idIdx = observedIds.findIndex(id => id === entryId);
          const prevId = observedIds[idIdx - 1];
          setActiveId(idIdx > 0 ? prevId : undefined);
        }
      }
    }, options?.observerOptions);

    // 5: Observe target IDs
    observedIds.forEach(id => {
      if (id) {
        const targetId = id.split('#')[1];
        const targetEle = document.getElementById(targetId);
        if (targetEle) {
          observer.observe(targetEle);
        }
      }
    });

    observerRef.current = observer;
    return () => observer.disconnect();
  }, [sectionIds]);

  const value = React.useMemo(
    () => ({
      activeId,
      setActiveId,
      observer: observerRef.current,
    }),
    [activeId, setActiveId, observerRef.current],
  );

  return value;
};

export default useSectionObserver;
