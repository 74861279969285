import React, { createContext } from 'react';
import type { Themes } from '@page-builder/themes';
import { themes } from '@page-builder/themes';

export const ThemeContext = createContext(themes['Grey 80']);
export const ThemeProvider: React.FC<React.PropsWithChildren<{ theme: Themes }>> = ({
  theme,
  children,
}) => {
  return <ThemeContext.Provider value={themes[theme]}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => React.useContext(ThemeContext);
