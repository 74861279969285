import ReviewCards, {
  ApiReviewCards,
  AIReviewCards,
} from '@page-builder/modules/ReviewCards';
import type { GenericReviewMap } from '@page-builder/utils/maps';

const formGenericModules: GenericReviewMap = {
  ['Review Cards']: ReviewCards,
  ['API Review Cards']: ApiReviewCards,
  ['AI Review Cards']: AIReviewCards,
  ['API Review Section']: () => null,
};

const ReviewTreatmentMap: GenericReviewMap = {
  ...formGenericModules,
};

export default ReviewTreatmentMap;
