import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useCitizens } from '@ecomm/financing/citizens/Context';
import { Partners } from '@ecomm/financing/models/Partners';
import useTagHandler from '@page-builder/hooks/useTagHandler';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericTextWithMedia,
} from '@page-builder/lib/types';
import { isEntryType } from '@page-builder/utils/unions';
import { useShopContext } from './../../ShopContext';
import AffirmCta from './AffirmCta';
import CitizensCta from './CitizensCta';
import type FinancingCtaProps from './FinancingCtaProps';
import FinancingTooltip from './FinancingTooltip';
import KlarnaCta from './KlarnaCta';
import toFinancingPartnerFromTags from './toFinancingPartnerFromTags';
import TruemedCta from './TruemedCta';
import ZipCta from './ZipCta';

const partnerComponentMap: Partial<
  Record<Partners, React.FC<React.PropsWithChildren<FinancingCtaProps>>>
> = {
  [Partners.Affirm]: AffirmCta,
  [Partners.Zip]: ZipCta,
  [Partners.Klarna]: KlarnaCta,
  [Partners.Citizens]: CitizensCta,
  [Partners.Truemed]: TruemedCta,
};

type Props = {
  cta: TypeComponentCtaFields;
  tags: string[];
  onClick: () => void;
  isDrawerFinancingSection: boolean;
  financingPriceOverride?: number;
};

const FinancingCta: React.FC<React.PropsWithChildren<Props>> = props => {
  const { onClick, tags, cta, isDrawerFinancingSection, financingPriceOverride } = props;
  const { productBundleType } = useShopContext();
  const { citizensProvider } = useCitizens();

  const partner = toFinancingPartnerFromTags(tags);

  // Temp fix: the presence of the non-experiment tag used to determine partner above unintentionally causes resolvedStatus to become false
  const experimentTagsFiltered = tags.filter(tag => tag.startsWith('experiment'));
  const { resolvedStatus } = useTagHandler(experimentTagsFiltered);

  const PartnerComponent = partnerComponentMap[partner]!;

  if (!productBundleType || !resolvedStatus || partner === Partners.None) {
    return null;
  }

  const isCitizensPrequalified =
    partner === Partners.Citizens && !!citizensProvider?.['locaStatusObj'];

  return (
    <Flex gap={spacing[8]} alignItems="center">
      <PartnerComponent
        cta={cta}
        bundleType={productBundleType}
        onClick={onClick}
        isDrawerFinancingSection={isDrawerFinancingSection}
        financingPriceOverride={financingPriceOverride}
      />
      {cta.modal &&
        isEntryType<TypeComponentGenericTextWithMedia>(cta.modal, 'text') &&
        !isCitizensPrequalified && (
          <FinancingTooltip data-test-id="financing-tooltip" tooltip={cta.modal} />
        )}
    </Flex>
  );
};

export default FinancingCta;
