import { LegacyTOCAdapter as TableOfContents } from '../../../../apps/freeform/modules/champ-ui/TableOfContents';
import AppFiftyFifty from '../AppFiftyFifty';
import AppMembershipCard from '../AppMembershipCard/AppMembershipCard';
import ARHomeView from '../ARHomeView';
import DownloadAppBanner from '../Banner/DownloadAppBanner';
import BellyBand from '../BellyBand';
import BlogCards from '../BlogCards';
import ClassesCarousel from '../ClassesCarousel';
import ClassesCarouselV2 from '../ClassesCarouselV2';
import { ClassesFilters } from '../ClassesFilters/ClassesFilters';
import { ClassesGrid, ClassCard } from '../ClassesGrid';
import ClassesTeasers from '../ClassesTeasers';
import { ClassPage } from '../ClassPage';
import ContainedPromoBanner from '../ContainedPromoBanner';
import { ContentCards } from '../ContentCards';
import { CtaBanner, CtaBannerDark } from '../CtaBanner';
import DigitalPromo from '../DigitalPromo';
import { FeatureCardModal, FeatureCardModalItem } from '../FeatureCardModal';
import { FeatureCards, FeatureCard } from '../FeatureCards';
import { FeaturedSpaces } from '../FeaturedSpaces';
import FiftyFiftyMedia from '../FiftyFiftyMedia';
import FiftyFiftySteps from '../FiftyFiftySteps';
import FullWidthMediaModule from '../FullWidthMediaModule';
import HeroCarousel from '../HeroCarousel';
import HeroFiftyFiftyHybrid from '../HeroFiftyFiftyHybrid/HeroFiftyFiftyHybrid';
import HighlightReel from '../HighlightReel';
import { LinkListCarousel } from '../LinkListCarousel/LinkListCarousel';
import Marquee from '../Marquee';
import NearestShowroom from '../NearestShowroom';
import PosterWithContentPanel from '../PosterWithContentPanel';
import { PreCartSubnav } from '../PreCartSubnav';
import PrequalifyNowModule from '../PrequalifyNowModule';
import PrequalifyWithCard from '../PrequalifyWithCard';
import PressQuotes from '../PressQuotes';
import ProductRecommendationsProductGrid from '../ProductRecommendations/ProductGrid';
import ProgrammingCarousel from '../ProgrammingCarousel';
import ReportHero from '../ReportHero';
import ReportTable from '../ReportTable';
import { SparePartsGrid } from '../SpareParts';
import TextWithMultipleIcons from '../TextWithMultipleIcons';
import TryClasses from '../TryClasses';
import { TwoColumnsModal } from '../TwoColumnsModal/TwoColumnsModal';
import VideoCarousel from '../VideoCarousel';
import VideoTrailers from '../VideoTrailers';
/*
  This map is used to build out custom modules in Page Builder
  Any module added here MUST be CRA and Next.js compatible
*/
const pageBuilderGenericModules = {
  ['Try Classes']: TryClasses,
  ['Digital Promo']: DigitalPromo,
  ['Belly Band']: BellyBand,
  ['Nearest Showroom']: NearestShowroom,
  Prequalify: PrequalifyNowModule,
  ['Prequalify No Modal']: PrequalifyNowModule,
  ['Prequalify With Card']: PrequalifyWithCard,
  ['Blog Cards']: BlogCards,
  ['Classes Carousel']: ClassesCarousel,
  ['Featured Spaces']: FeaturedSpaces,
  ['AR Home View']: ARHomeView,
  ['Hero Carousel']: HeroCarousel,
  ['Press Quotes']: PressQuotes,
  ['Pre-cart Subnav']: PreCartSubnav,
  ['Product Recommendations Top of Page']: () => null,
  ['Product Recommendations Bottom of Page']: () => null,
  ['Video Trailers']: VideoTrailers,
  ['Specs View']: () => null,
  ['Classes Teasers']: ClassesTeasers,
  ['Marquee']: Marquee,
  ['CTA Banner']: CtaBanner,
  ['CTA Banner Dark']: CtaBannerDark,
  ['Classes Carousel v2']: ClassesCarouselV2,
  ['Programming Carousel']: ProgrammingCarousel,
  ['Text With Multiple Icons']: TextWithMultipleIcons,
  ['Text With Multiple Icons Dark']: TextWithMultipleIcons,
  ['Feature Card']: FeatureCard,
  ['Feature Cards']: FeatureCards,
  ['Feature Card Modal']: FeatureCardModal,
  ['Feature Card Modal Item']: FeatureCardModalItem,
  ['Fifty Fifty Steps']: FiftyFiftySteps,
  ['Classes Filters']: ClassesFilters,
  ['Classes Grid']: ClassesGrid,
  ['Class Card']: ClassCard,
  ['Class Page']: ClassPage,
  ['Download App Banner']: DownloadAppBanner,
  ['Product Recommendations Upsell Grid']: ProductRecommendationsProductGrid,
  ['Deals Page Product Grid']: ProductRecommendationsProductGrid,
  ['PLP Product Grid']: ProductRecommendationsProductGrid,
  ['Carousel Product Grid']: ProductRecommendationsProductGrid,
  ['Table of Contents']: TableOfContents,
  ['Report Table']: ReportTable,
  ['ReportHero']: ReportHero,
  ['Two Column Modal']: TwoColumnsModal,
  ['App Membership']: AppMembershipCard,
  ['Hero Fifty Fifty Hybrid']: HeroFiftyFiftyHybrid,
  ['Contained Promo Banner']: ContainedPromoBanner,
  ['Link List Carousel']: LinkListCarousel,
  ['App Fifty Fifty QR Code']: AppFiftyFifty,
  ['Full-Width Media']: FullWidthMediaModule,
  ['Content Cards']: ContentCards,
  ['Fifty Fifty Media']: FiftyFiftyMedia,
  ['Highlight Reel']: HighlightReel,
  ['Video Carousel']: VideoCarousel,
  ['Poster With Content Panel']: PosterWithContentPanel,
  ['Spare Parts']: SparePartsGrid,
};

// Key should match "Treatment" name specified in Contentful
const TreatmentToComponentMap = {
  ...pageBuilderGenericModules,
};

export default TreatmentToComponentMap;
