import { grey, white, Body, Icon, Label } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCountry } from '@peloton/internationalize/models/country';
import { Link } from '@peloton/next/components/Link';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import { media } from '@peloton/styles/breakpoints';
import {
  LinkView as AffirmModalLink,
  PageType,
} from '@ecomm/financing/affirm/ModalLinkView';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import useGetFinancingPartner from '@ecomm/financing/hooks/useGetFinancingPartner';
import { Partners } from '@ecomm/financing/models/Partners';
import { BundleType } from '@ecomm/shop/models/BundleType';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';

const PAGE_TYPE = PageType.BANNER;
const PRODUCT_NAME = 'Generic';

const PrequalifyNowModule: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ ctas, name, text, treatment }) => {
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();
  const financingPartner = useGetFinancingPartner();
  const isClickableHeadline = treatment === 'Prequalify No Modal';

  const headlineText = useFormattedText(text?.fields.headline || '', {
    zeroDollars: 0,
  });
  const ctaText = ctas?.[0].fields?.text || '';

  if (!financingPartner) {
    console.warn(
      `Warning: No financing partner available for ${name}. Component was not rendered.`,
    );
    return null;
  }

  const trackClick = () => {
    trackEvent({
      event: 'Clicked Prequalify CTA',
      properties: {
        page: pathName,
        productName: PRODUCT_NAME,
        unitName: name,
      },
    });
  };

  const financingPartnerIconMap = {
    affirm: {
      iconName: 'affirmCircle' as const,
      iconHeight: 18,
      iconVerticalAdjustment: -6,
    },
    klarna: {
      iconName: 'klarna' as const,
      iconHeight: 20,
      iconVerticalAdjustment: -2,
    },
  };

  const { iconName, iconHeight, iconVerticalAdjustment } = financingPartnerIconMap[
    financingPartner
  ];

  const V1Component = () => (
    <Container>
      <StyledBody data-test-id="prequalify-homepage-module-heading">
        <Body size="small" is="span" display="inline-block">
          {headlineText}
        </Body>
        <StyledIcon name={iconName} height={iconHeight} />
        <StyledLink
          component={({ children, className }) => (
            <ModalLink name={name} className={className}>
              {children}
            </ModalLink>
          )}
          variant="body"
          size="large"
        >
          {ctaText}
        </StyledLink>
      </StyledBody>
      <Hairline />
    </Container>
  );

  const V2Component = () => (
    <Container>
      <StyledLabel data-test-id="prequalify-homepage-module-heading">
        {headlineText}
        <StyledIcon
          name={iconName}
          height={iconHeight}
          transform={`translate(0, ${iconVerticalAdjustment})`}
        />
        <StyledLinkV2 href="/financing" variant="body" size="small" onClick={trackClick}>
          {ctaText}
        </StyledLinkV2>
      </StyledLabel>
    </Container>
  );

  return isClickableHeadline ? <V2Component /> : <V1Component />;
};

export default PrequalifyNowModule;

type ModalLinkProps = {
  bundleType?: BundleType;
  pageType?: PageType;
  children: React.ReactNode;
  className?: string;
  name: string;
};

export const ModalLink: React.FC<React.PropsWithChildren<ModalLinkProps>> = ({
  bundleType = BundleType.Bike,
  pageType = PAGE_TYPE,
  children,
  className,
  name,
}) => {
  const { total, term } = useFinancingNext(bundleType);
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const trackClick = () => {
    trackEvent({
      event: 'Clicked Prequalify CTA',
      properties: {
        page: pathName,
        productName: PRODUCT_NAME,
        unitName: name,
        billingPartner: Partners.Affirm,
      },
    });
  };

  const trackPrequalComplete = React.useCallback(
    (data: any) => {
      trackEvent({
        event: 'Completed Prequal',
        properties: {
          productName: PRODUCT_NAME,
          unitName: name,
          installmentAmount: data.installment_amount,
          installmentCount: data.installment_count,
          interestAmount: data.interest_amount,
          prequalAmount: data.prequal_amount,
          status: data.status,
          country: toCountry(),
        },
      });
    },
    [name, trackEvent],
  );

  return (
    <AffirmModalLink
      amount={total}
      term={term}
      bundleType={BundleType.Bike}
      pageType={pageType}
      className={className}
      onPrequalComplete={trackPrequalComplete}
      onClick={trackClick}
    >
      {() => <>{children}</>}
    </AffirmModalLink>
  );
};

const Container = styled.div`
  background-color: ${white};

  ${media.desktop`
  background-color: ${grey[30]};
`}
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 40px 0;
  text-align: center;

  ${media.desktop`
  align-items: normal;
  padding: 48px 0px;
`}
`;

const StyledLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 32px 24px;
  text-align: center;
  line-height: 1.66;

  & > a {
    text-decoration: none;
  }

  ${media.desktop`
  padding: 0 0 24px;
`}
`;

const StyledIcon = styled(Icon)`
  margin-left: 6px;
`;

const StyledLink = styled(Link)`
  padding: 3px 0 0 20px;

  &:after {
    transform: translateY(-5px);
  }
`;

const StyledLinkV2 = styled(Link)`
  margin-left: 10px;
`;

const Hairline = styled.hr`
  border: 0;
  border-top: 1px solid ${grey[40]};
  margin: 0 32px;

  ${media.tablet`
    margin 0 auto;
    padding: 0;
    width: 460px;
  `};

  ${media.desktopLarge`
    width: 900px;
  `};
`;
