import { spacing, Media } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

type Props = {
  image: string;
};

const ClassCardImage: React.FC<React.PropsWithChildren<Props>> = ({ image }) => {
  return (
    <ClassCardImageWrapper>
      <CardImage
        width="100%"
        objectFit="cover"
        media={{
          alt: '',
          mobile: image,
          loading: 'eager',
          type: 'image',
        }}
      />
    </ClassCardImageWrapper>
  );
};

export default ClassCardImage;

const ClassCardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 201px;

  ${media.desktop`
    height: 192px;
    `}
`;

const CardImage = styled(Media)`
  border-radius: ${spacing[4]} ${spacing[4]} 0 0;
  height: 201px;
  min-width: 100%;
  overflow: hidden;

  picture img {
    object-fit: cover;
    min-height: 100%;
    max-width: 100%;
    width: 100vw;
  }

  ${media.desktop`
    height: 192px;
  `}
`;
