/**
 * Formats the metadata tag to the appropriate query value by removing the taggedPrefix from the beginning of the tag entry and lowercasing the string.
 * For example, if tag is `productInterestRefurbished` and tagPrefix is 'productInterest', the returned value will be `refurbished`.
 *
 * @param tagPrefix - the prefix string in the tag from the metadata.
 * @param tag - the tag entry from the metadata (should include the tagPrefix string).
 * @return {string | undefined} the formatted query value
 */
export const transformTagToQueryValue = (
  tagPrefix: string,
  tag?: string,
): string | undefined => {
  const prefixRegex = new RegExp('^' + tagPrefix);

  return tag?.replace(prefixRegex, '').toLowerCase();
};

/**
 * This method dynamically appends or changes the current url with the passed in arguments, in this format: `${paramName}=${queryParamValue}`
 *
 * @param paramName - the url query param name that is set in the url.
 * @param paramValues - the url query param values that is set in the url.
 */
export const setUrlQueryParams = (paramName: string, paramValues: string): void => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(paramName, paramValues);

  window.history.replaceState(null, '', currentUrl.toString());
};

/**
 * This method dynamically removes a query parameter from the url with the passed in argument
 *
 * @param paramName - the url query param name that is removed in the url.
 */
export const deleteUrlQueryParam = (paramName: string): void => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete(paramName);

  window.history.replaceState(null, '', currentUrl.toString());
};

/**
 * Checks the current url for the specified paramName and its value, and returns the value
 *
 * @param paramName - the url query param name that corresponds to the value we want to get from the url
 * @return {string | null} the query value for the paramName or null
 */
export const getExistingQueryValueFor = (paramName: string): string | null => {
  if (typeof window !== 'undefined') {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const queryValue = currentUrlParams.get(paramName);

    return queryValue;
  } else {
    return null;
  }
};
