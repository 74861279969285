import { Button as BaseButton } from '@pelotoncycle/design-system';
import React from 'react';
import { LinkButton as BaseLinkButton } from '@ecomm/internationalize-ui';
import type { ButtonMouseEvent, ButtonProps } from './LoadMoreButton';
import { useLoadMoreContext } from './LoadMoreContext';

export const LoadPreviousButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  label,
  loadingLabel,
  ...remainingProps
}) => {
  const {
    isLoading,
    isLoadingPrevious,
    hasPrevious,
    decrementPage,
    previousPageHref,
  } = useLoadMoreContext();

  if (!hasPrevious) {
    return null;
  }

  const handleClick = (e: ButtonMouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    decrementPage();
  };

  const buttonLabel = isLoadingPrevious ? loadingLabel : label;
  const ariaLive = isLoadingPrevious ? 'assertive' : 'polite';

  return previousPageHref ? (
    <BaseLinkButton
      {...remainingProps}
      href={previousPageHref}
      role="button"
      onClick={handleClick}
      isDisabled={isLoading}
      aria-live={ariaLive}
    >
      {buttonLabel}
    </BaseLinkButton>
  ) : (
    <BaseButton
      {...remainingProps}
      onClick={handleClick}
      isDisabled={isLoading}
      aria-live={ariaLive}
    >
      {buttonLabel}
    </BaseButton>
  );
};
