import { black } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { IconProps } from './types';

type Props = IconProps;

export const ARIcon: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg role="img" width="20" height="22" viewBox="0 0 24 26" {...props}>
    <defs>
      <path d="M.38 0h6.798v2.675H.38z" />
      <path d="M.174 0h.998v4.652H.174z" />
      <path d="M0 .07h3.896v2.672H0z" />
      <path d="M0 .012h3.893v2.675H0z" />
      <path d="M.214.215h10.521v6.312H.215z" />
      <path d="M.388.215h10.507v6.312H.388z" />
      <path d="M.215.18h20.46v12.07H.214z" />
      <path d="M.215.125h10.52v6.327H.215z" />
      <path d="M.388.125h10.508v6.327H.388z" />
      <path d="M.215.18h.569v12.07h-.57z" />
      <path d="M.388.215h.57v23.537h-.57z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g opacity=".5">
        <g transform="translate(8.444 1)">
          <mask fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={black}
            d="M6.678 2.675a.498.498 0 0 1-.25-.067L3.784 1.077 1.13 2.608a.499.499 0 1 1-.499-.866L3.535.067a.497.497 0 0 1 .499 0l2.895 1.676a.5.5 0 0 1-.25.932"
            mask="url(#b)"
          />
        </g>
        <path
          fill={black}
          d="M12.228 16.843a.5.5 0 0 1-.499-.5v-3.35a.5.5 0 1 1 .998 0v3.35a.5.5 0 0 1-.499.5M2.277 11.091a.5.5 0 0 1-.499-.5v-3.35a.5.5 0 1 1 .998 0v3.35a.5.5 0 0 1-.5.5M22.167 11.091a.5.5 0 0 1-.499-.5v-3.35a.5.5 0 0 1 .997 0v3.35a.5.5 0 0 1-.498.5M22.168 19.243a.499.499 0 0 1-.5-.5l-.002-3.35a.499.499 0 0 1 .998 0l.002 3.35c.001.276-.223.5-.498.5zM2.277 19.243a.5.5 0 0 1-.499-.5v-3.35a.5.5 0 1 1 .998 0v3.35a.5.5 0 0 1-.5.5"
        />
        <g transform="translate(11.556 1)">
          <mask fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill={black}
            d="M.673 4.652a.5.5 0 0 1-.5-.5V.5a.5.5 0 1 1 .999 0v3.652a.5.5 0 0 1-.5.5"
            mask="url(#d)"
          />
        </g>
        <path
          fill={black}
          d="M12.228 25a.5.5 0 0 1-.499-.5v-3.349a.5.5 0 1 1 .998 0v3.35a.5.5 0 0 1-.499.499"
        />
        <path
          fill={black}
          d="M12.228 25a.506.506 0 0 1-.25-.066l-2.914-1.687a.5.5 0 0 1 .5-.865l2.664 1.541 2.629-1.523a.5.5 0 0 1 .5.866l-2.879 1.667a.499.499 0 0 1-.25.067M12.228 13.492a.503.503 0 0 1-.25-.067L9.075 11.75a.5.5 0 0 1 .5-.866l2.654 1.533 2.645-1.532a.499.499 0 1 1 .5.865l-2.895 1.676a.494.494 0 0 1-.25.067M19.269 9.418a.499.499 0 0 1-.25-.932l2.898-1.677a.499.499 0 1 1 .499.866l-2.898 1.676a.498.498 0 0 1-.25.067"
        />
        <path
          fill={black}
          d="M22.166 7.742a.496.496 0 0 1-.25-.067l-2.902-1.678a.5.5 0 0 1 .5-.865l2.901 1.677a.5.5 0 0 1-.25.933M5.177 9.418a.498.498 0 0 1-.25-.067l-2.9-1.676a.5.5 0 0 1 .5-.866l2.9 1.676a.5.5 0 0 1-.25.933"
        />
        <g transform="translate(1.778 5)">
          <mask fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            fill={black}
            d="M.5 2.742a.5.5 0 0 1-.25-.933L3.147.137a.498.498 0 1 1 .499.866L.748 2.675a.502.502 0 0 1-.249.067"
            mask="url(#f)"
          />
        </g>
        <path
          fill={black}
          d="M19.263 20.919a.499.499 0 0 1-.25-.933l2.906-1.675a.5.5 0 0 1 .498.865l-2.905 1.676a.504.504 0 0 1-.249.067"
        />
        <path
          fill={black}
          d="M22.167 19.243a.5.5 0 0 1-.25-.067l-2.9-1.676a.5.5 0 0 1 .5-.865l2.9 1.675a.5.5 0 0 1-.25.933M5.177 20.92a.5.5 0 0 1-.25-.066l-2.9-1.678a.5.5 0 0 1 .5-.865l2.9 1.677a.5.5 0 0 1-.25.933"
        />
        <g transform="translate(1.778 16.556)">
          <mask fill="#fff">
            <use xlinkHref="#g" />
          </mask>
          <path
            fill={black}
            d="M.5 2.687a.5.5 0 0 1-.25-.932L3.143.08a.499.499 0 1 1 .5.865L.749 2.621a.504.504 0 0 1-.25.066"
            mask="url(#h)"
          />
        </g>
        <g transform="translate(1.778 1)">
          <mask fill="#fff">
            <use xlinkHref="#i" />
          </mask>
          <path
            fill={black}
            fillOpacity=".3"
            d="M.5 6.527a.285.285 0 0 1-.143-.532l9.95-5.742a.285.285 0 1 1 .285.494L.643 6.488a.284.284 0 0 1-.143.038"
            mask="url(#j)"
          />
        </g>
        <path
          fill={black}
          fillOpacity=".3"
          d="M2.277 19.028a.285.285 0 0 1-.143-.531l19.89-11.502a.284.284 0 1 1 .284.494l-19.89 11.5a.279.279 0 0 1-.14.04"
        />
        <g transform="translate(11.556 1)">
          <mask fill="#fff">
            <use xlinkHref="#k" />
          </mask>
          <path
            fill={black}
            d="M10.61 6.527a.283.283 0 0 1-.141-.038L.53.746A.285.285 0 0 1 .815.253l9.938 5.742a.285.285 0 0 1-.143.532"
            mask="url(#l)"
            opacity=".3"
          />
        </g>
        <g transform="translate(1.778 6.778)">
          <mask fill="#fff">
            <use xlinkHref="#m" />
          </mask>
          <path
            fill={black}
            fillOpacity=".2"
            d="M20.39 12.25a.278.278 0 0 1-.142-.038L.357.712A.285.285 0 0 1 .64.216L20.532 11.72a.285.285 0 0 1-.143.531"
            mask="url(#n)"
          />
        </g>
        <g transform="translate(1.778 18.333)">
          <mask fill="#fff">
            <use xlinkHref="#o" />
          </mask>
          <path
            fill={black}
            fillOpacity=".3"
            d="M10.45 6.452a.283.283 0 0 1-.142-.038L.357.656A.284.284 0 1 1 .642.163l9.95 5.757a.285.285 0 0 1-.142.532"
            mask="url(#p)"
          />
        </g>
        <g transform="translate(11.556 18.333)">
          <mask fill="#fff">
            <use xlinkHref="#q" />
          </mask>
          <path
            fill={black}
            fillOpacity=".3"
            d="M.673 6.452A.285.285 0 0 1 .53 5.92L10.469.164a.285.285 0 0 1 .286.493L.815 6.413a.285.285 0 0 1-.142.038"
            mask="url(#r)"
          />
        </g>
        <g transform="translate(1.778 6.778)">
          <mask fill="#fff">
            <use xlinkHref="#s" />
          </mask>
          <path
            fill={black}
            fillOpacity=".3"
            d="M.499 12.25a.284.284 0 0 1-.285-.285V.464a.285.285 0 1 1 .57 0v11.501a.285.285 0 0 1-.285.285"
            mask="url(#t)"
          />
        </g>
        <path
          fill={black}
          fillOpacity=".3"
          d="M22.168 19.028a.285.285 0 0 1-.285-.285l-.001-11.501a.285.285 0 0 1 .57 0v11.501a.284.284 0 0 1-.284.285"
        />
        <g transform="translate(11.556 1)">
          <mask fill="#fff">
            <use xlinkHref="#u" />
          </mask>
          <path
            fill={black}
            d="M.673 23.752a.285.285 0 0 1-.285-.284V.5a.285.285 0 0 1 .57 0v22.968a.285.285 0 0 1-.285.284"
            mask="url(#v)"
            opacity=".3"
          />
        </g>
      </g>
    </g>
  </svg>
);

const ARIconWithCircle = styled(ARIcon)`
  flex-shrink: 0;
  padding: 8px;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 50%;
  box-shadow: 2px 4px 10px 0 ${rgba(black, 0.1)};
`;

export default ARIconWithCircle;
