import type {
  TypeComponentGenericTextWithMediaFields,
  TypeComponentGenericTextFields,
  TypeComponentCta,
  TypeComponentCtaFields,
  TypeComponent_overviewFields,
} from '@pelotoncycle/page-builder';
import React from 'react';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import useGetUpsellTagNames from './useGetUpsellTagNames';
import {
  toDrawerContent,
  toDrawerFinancingData,
  useFinancingFormattingValues,
} from './utils';

export type DrawerFinancingData = {
  eyebrow?: TypeComponentGenericTextFields['eyebrow'];
  cta?: TypeComponentCta;
};

export type PageBuilderData = {
  ctas: TypeComponentCta[];
  drawerBody?: TypeComponentGenericTextFields['body'];
  financingCtas?: TypeComponentCta[];
  financingText?: string;
  isOpen: boolean;
  upsellSectionIntro: {
    heading?: string;
    subheading?: string;
  };
  upsellItemContent: {
    text?: {
      productSubhead?: TypeComponentGenericTextFields['headline'];
      productDescription?: TypeComponentGenericTextFields['body'];
      isIncludedText?: TypeComponentGenericTextFields['label'];
      configDropdownLabel?: TypeComponentGenericTextFields['support'];
      editConfigText?: TypeComponentGenericTextFields['eyebrow'];
    };
    productSlug?: TypeComponentCtaFields['productSlug'];
    itemName?: TypeComponentCtaFields['name'];
    key?: TypeComponentGenericTextWithMediaFields['name'];
  }[];
};

type ProviderProps = Pick<TypeComponent_overviewFields, 'product'>;

export type DrawerContentContextProps = {
  isMultiShopOptions: boolean;
  pageBuilderData: PageBuilderData;
  drawerFinancingData: Map<number, DrawerFinancingData>;
};

const defaultPageBuilderData = {
  ctas: [],
  drawerBody: '',
  financingCtas: [],
  financingText: '',
  isOpen: false,
  upsellSectionIntro: {},
  upsellItemContent: [],
};

const defaultDrawerFinancingData = new Map<number, DrawerFinancingData>();

export const DrawerContentContext = React.createContext<DrawerContentContextProps>({
  isMultiShopOptions: false,
  pageBuilderData: defaultPageBuilderData,
  drawerFinancingData: defaultDrawerFinancingData,
});

export const DrawerContentContextProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ product, children }) => {
  const {
    activeShopDrawer,
    visibleShopDrawers,
    availableShopDrawers,
  } = useDrawerSelectionContext();
  const isMultiShopOptions = Boolean(
    availableShopDrawers && availableShopDrawers.length > 1,
  );

  const tagNames = useGetUpsellTagNames(product.fields.productId);

  const options = { isMultiShopOptions, tagNames };

  const pageBuilderData =
    toDrawerContent(activeShopDrawer, options) || defaultPageBuilderData;

  const drawerFinancingData = toDrawerFinancingData(visibleShopDrawers);

  const financingFormattingValues = useFinancingFormattingValues('shopDrawer');

  pageBuilderData.financingText = useFormattedText(
    pageBuilderData.financingText,
    financingFormattingValues,
  )
    .toString()
    .trim();

  const value: DrawerContentContextProps = {
    isMultiShopOptions,
    pageBuilderData,
    drawerFinancingData,
  };

  return (
    <DrawerContentContext.Provider value={value}>
      {children}
    </DrawerContentContext.Provider>
  );
};

export const useDrawerContentContext = () => React.useContext(DrawerContentContext);
