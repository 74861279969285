import { ACCESSORY_BUNDLE_PRODUCT_TYPE } from '../constants';
import type { ProductCatalogType, ProductProjectionType } from '../models/main';

export type AccessoryForCheck = Pick<
  ProductProjectionType | ProductCatalogType,
  'productType'
>;

export const isAccessoryBundleCT = (accessory: AccessoryForCheck) =>
  accessory.productType?.key === ACCESSORY_BUNDLE_PRODUCT_TYPE;
