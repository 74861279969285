import {
  Container,
  grey,
  spacing,
  Label,
  Body,
  Headline,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { WithMarkdown } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';

type CustomParagraphProps = {
  children: React.ReactNode;
  [key: string]: any;
};

const ReportHero: React.FC<TypeComponentGenericTextWithMediaFields> = ({ text }) => {
  const headerText = text?.fields.headline;
  const bodyText = text?.fields.body;
  const supportDateText = text?.fields.support;
  const date = text?.fields.label;
  return (
    <StyledContainer
      horizontalPadding={{
        mobile: spacing[16],
        tablet: 0,
        desktop: 0,
      }}
    >
      <Headline size="large" style={{ fontWeight: 900, marginBottom: spacing[16] }}>
        {headerText}
      </Headline>
      <Body size="small" style={{ marginTop: spacing[16], color: grey[90] }}>
        <WithMarkdown text={bodyText ?? ''} />
      </Body>
      <Body size="small" style={{ marginTop: spacing[16], color: grey[90] }}>
        <span>
          <Label size="extraLarge" style={{ fontWeight: 500 }}>
            {supportDateText}
          </Label>
          <StyledDateBody size="small">
            <WithMarkdown renderers={customRenderers} text={date ?? ''} />
          </StyledDateBody>
        </span>
      </Body>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: ${spacing[40]};
`;

const StyledDateBody = styled(Body)`
  padding-left: 4px;
  display: inline;
  color: ${grey[90]};
`;

const customRenderers: Record<string, React.ComponentType<CustomParagraphProps>> = {
  paragraph: ({ children, ...props }: CustomParagraphProps) => (
    <span {...props}>{children}</span>
  ),
};

export default ReportHero;
