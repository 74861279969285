import { spacing, Container } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { CustomPricing } from '@onewellness/pg-benefit-options/ui/CustomPriceDisplay';
import type {
  TypeComponentProductCard,
  TypeComponentProductBlockFields,
} from '@page-builder/lib/types';
import { CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';
import ProductCard from './ProductCard';

type Props = {
  cardAlignment?: TypeComponentProductBlockFields['cardAlignment'];
  cards: TypeComponentProductCard[];
  totalCards: number;
  customPricing?: CustomPricing[];
};

const getIsAtLeastOneDisclaimerActive = (cards: TypeComponentProductCard[]) => {
  return cards.reduce((acc, curr) => {
    if (curr.fields.disclaimer) return true;
    return acc;
  }, false);
};

const getIsAtLeastOneTotalPricingOrPriceInfoActive = (
  cards: TypeComponentProductCard[],
) => {
  return cards.reduce((acc, curr) => {
    if (curr.fields.totalPricing || curr.fields.priceInfo) return true;
    return acc;
  }, false);
};

const ProductGrid: React.FC<React.PropsWithChildren<Props>> = ({
  cardAlignment,
  cards,
  totalCards,
  customPricing,
}) => {
  const { Track } = useTracking({
    event: 'Clicked Link',
    properties: {
      parent: 'Product Info Card',
      parentType: 'Product Block',
      unitName: 'Product Block CTA',
    },
  });

  const alignment = totalCards === 1 ? 'row' : cardAlignment;

  const hideUpperSpacing = !getIsAtLeastOneTotalPricingOrPriceInfoActive(cards);
  const hideLowerSpacing = !getIsAtLeastOneDisclaimerActive(cards);

  return (
    <StyledContainer
      data-test-id="product-cards"
      count={totalCards}
      alignment={alignment}
    >
      <Track>
        <StyledProductGrid count={totalCards} alignment={alignment}>
          {cards.map((card, index) => (
            <ProductCard
              hideLowerSpacing={hideLowerSpacing}
              hideUpperSpacing={hideUpperSpacing}
              card={card}
              key={`${card.sys.id}${index}`}
              alignment={alignment}
              customPricing={customPricing}
            />
          ))}
        </StyledProductGrid>
      </Track>
    </StyledContainer>
  );
};

export default ProductGrid;

type GridProps = {
  count: number;
  alignment: TypeComponentProductBlockFields['cardAlignment'];
};

// There could be a case where there needs to be a 1 column card stacked on top of
// the columns.  This is done by adding one product block with one card and another
// content block with the rest of the cards.  In this case the product block with one
// card will override the padding of the block next to it.  It will only work if the
// blocks are consecutive.
const StyledContainer = styled(Container)<GridProps>`
  & + [data-test-id='product-cards'] {
    padding-top: 0;
  }
`;

const columnsCount = (count: GridProps['count'], alignment: GridProps['alignment']) => {
  if (count > 1 && alignment !== 'row') {
    return count % 2 === 0 ? 2 : 3;
  } else {
    return 1;
  }
};

const StyledProductGrid = styled.div<GridProps>`
  max-width: ${CONTAINER_MAXWIDTH};
  margin: 0 auto;
  display: grid;
  gap: ${spacing[32]};
  grid-template-columns: auto;
  ${media.tabletXLarge<GridProps>`
    gap: ${spacing[8]};
  `}
  ${media.desktopLarge<GridProps>`
    ${({ count, alignment }) =>
      count && `grid-template-columns: repeat(${columnsCount(count, alignment)}, 1fr)`};
    gap: ${spacing[24]};
  `}
`;
