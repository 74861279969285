import { useEffect } from 'react';
import type { Cart } from '@ecomm/cart/models';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import type { Slug } from '@ecomm/models';
import { usePackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import { BundleType } from '../models/BundleType';
import { useTrackAddToCart } from './useTrackAddToCart';

const useAddPackageToCartAnalytics = (
  bundleSlug: Slug,
  bundleType?: BundleType,
  cart?: Cart,
  hasAccessory?: boolean,
  hasAccessoryBundle?: boolean,
  accessoryName?: string,
  addedFromCart?: boolean,
  hasGuide?: boolean,
  hasOPC?: boolean,
  hasCPO?: boolean,
) => {
  const { data: packageQuery } = usePackageBySlugOptionalWarrantyQuery(
    useCatalogQueryOptions({ variables: { package: bundleSlug } }),
  );
  const { trackAddToCart } = useTrackAddToCart();

  useEffect(() => {
    // TODO: create helper in models to check if bundleType is "equipment"
    if (cart && packageQuery && bundleType && bundleType !== BundleType.Accessories) {
      trackAddToCart({
        packageQuery,
        cartId: cart.id,
        bundleType,
        hasAccessory,
        hasAccessoryBundle,
        accessoryName,
        addedFromCart,
        hasGuide,
        hasOPC,
        hasCPO,
        hasTradeIn: cart?.hasOwnProperty('tradeIn')
          ? Boolean((cart as Cart)?.tradeIn)
          : false,
      });
    }
  }, [packageQuery, cart, bundleType]);
};

export default useAddPackageToCartAnalytics;
