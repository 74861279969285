import type { TypeProductFields } from '@pelotoncycle/page-builder';
import type { TypeComponentProductCard } from '@page-builder/lib/types';
import { PRODUCT_INTEREST, ROUTE_PATTERNS } from '../ShopDrawers/constants';
export const useActiveButton = (
  leftButton: TypeComponentProductCard,
  rightButton: TypeComponentProductCard,
  currentProductId: TypeProductFields['productId'],
) => {
  const leftButtonActive =
    leftButton.fields.product.fields.productId === currentProductId;
  return leftButtonActive ? leftButton : rightButton;
};
export const getCrossShopUrl = (
  activeProductCard: TypeProductFields,
  inactiveProductCard: TypeProductFields,
  currentURL: URL,
) => {
  const isRentalPath = currentURL.pathname.includes(ROUTE_PATTERNS.rent);
  const isRefurbPath = currentURL.pathname.includes(ROUTE_PATTERNS.refurb);
  const isRefurbishedBuyPath = ROUTE_PATTERNS.refurbishedBuy.test(currentURL.pathname);

  const productInterestParam = currentURL.searchParams.get(
    PRODUCT_INTEREST.queryParamName,
  );
  const { bundleType: currentBundle } = activeProductCard;
  const {
    bundleType: crossShopBundle,
    hasRefurb: crossShopHasRefurb,
    hasRental: crossShopHasRental,
    shopLink: crossShopLink,
  } = inactiveProductCard;

  // First prioritize product-interest query params, which reflects drawer selection,
  // and return the cross-shop path equivalent of that product-interest
  if (productInterestParam) {
    const isRentalParam = productInterestParam === PRODUCT_INTEREST.rent;
    const isRefurbParam = productInterestParam === PRODUCT_INTEREST.refurb;
    if (isRentalParam && crossShopHasRental) {
      return `${ROUTE_PATTERNS.rent}/${crossShopBundle}`;
    } else if (isRefurbParam && crossShopHasRefurb) {
      return `${ROUTE_PATTERNS.refurb}/${crossShopBundle}`;
    } else {
      // The product shop link is configured as the 'new' PDP route in PB
      return crossShopLink?.fields.url;
    }

    // If no product-interest param is present, use the path and directly replace the
    // bundles in the path (mainly so /shop/opc/{bundle},
    // /shop/opc/{bundle}/rent and /shop/refurbished/{bundle}/rent are maintained)
  } else if ((isRentalPath || isRefurbishedBuyPath) && crossShopHasRental) {
    return currentURL.pathname.replace(currentBundle, crossShopBundle);
  } else if (isRefurbPath && crossShopHasRefurb) {
    return `${ROUTE_PATTERNS.refurb}/${crossShopBundle}`;
    // If the cross-shop option doesn't have refurb or rent options, always fallback to the new PDP
  } else {
    return crossShopLink?.fields.url;
  }
};
