import {
  toLocaleFromTLD,
  toLocaleWithFallback,
} from '@peloton/internationalize/models/locale';
import { extractProductRecommendationsCohortFields } from '@content/client/lib/extractAggregateFields';
import { parseContent } from '@content/client/lib/parseContent';
import { getClient as getPeloContentClient } from '@content/client/peloContentClient';

// This regex uses named capture groups for cfu and buyMethod so that we can get the parameters by name
const UPSELL_PAGE_REGEX = /shop\/(?<cfu>.*?)(?:\/(?<buyMethod>.*))?\/complete-cart/;

const fetchRecommendationsForCFUandBuyMethod = async (
  cfu: string,
  buyMethod: string,
  preview: boolean,
) => {
  const cohortKey = `productRecommendations.${cfu}.${buyMethod}.completeYourCart`;

  const client = getPeloContentClient(preview);

  const locale = toLocaleFromTLD();

  const { items } = await client.getEntries({
    limit: 1,
    include: 10,
    locale: toLocaleWithFallback(locale), // TODO: remove if upsell classes launches in .at
    'fields.key': cohortKey,
    content_type: 'productRecommendationsCohort',
  });

  if (items.length === 0) {
    console.log(`Failed to find product recommendations cohort with key ${cohortKey}`);

    return null;
  }

  const [cohortEntry] = items;

  // Need to use parseContent to remove undefined values that next.js can't serialize in props
  const cohort = parseContent(extractProductRecommendationsCohortFields(cohortEntry));

  return {
    cohort,
  };
};

const fetchUpsellProductRecommendations = async ({
  slug,
  preview,
}: {
  slug: string;
  preview: boolean;
}) => {
  const pathMatch = slug.match(UPSELL_PAGE_REGEX);

  if (!pathMatch) {
    return null;
  }

  // We can assert that groups is present because our regex provides capture groups
  const { cfu, buyMethod = 'buy' } = pathMatch.groups!;

  return fetchRecommendationsForCFUandBuyMethod(cfu, buyMethod, preview);
};

export default fetchUpsellProductRecommendations;
