import { toLocaleWithFallback } from '@peloton/internationalize/models/locale';
import { fetchClassContent } from '@ecomm/classes/utils/fetchClassContent';
import { transformLocale } from '@ecomm/classes/utils/transformLocale';
import { fetchFreeClassListingFromPageConfig } from './fetchFreeClassDataApi';

export const fetchFreeClassDataByDiscipline = async ({
  locale,
  preview,
  discipline,
}: {
  locale: string | undefined;
  preview: boolean;
  discipline: string | undefined;
}) => {
  const localeApi = transformLocale(locale!);
  const fallbackLocale = toLocaleWithFallback(localeApi);
  const freeClassListingFromPageConfig = await fetchFreeClassListingFromPageConfig(
    preview,
    fallbackLocale,
  );
  const freeClassSlugFromPageConfig = Object.keys(freeClassListingFromPageConfig).find(
    freeClassSlug => {
      return freeClassListingFromPageConfig[freeClassSlug].classType.includes(
        discipline!,
      );
    },
  );

  if (!freeClassSlugFromPageConfig) {
    return null;
  }

  const freeClassFromPageConfig =
    freeClassListingFromPageConfig[freeClassSlugFromPageConfig];
  const freeClassDetailsFromAPI = await fetchClassContent(
    freeClassSlugFromPageConfig,
    fallbackLocale,
  );
  const hasFreeClass = !!freeClassDetailsFromAPI && !!freeClassFromPageConfig;

  if (!hasFreeClass) {
    return null;
  }

  return {
    ...freeClassDetailsFromAPI,
    ...freeClassFromPageConfig,
    extraCardType: 'FreeClass',
    isFree: true,
  };
};
