import { ClassFilters } from '@ecomm/classes/api/fetchFiltersData';
import type { SelectedFilters } from '../hooks/useClassFilters';
import type { Filters } from './toFilters';

export type FiltersWithAvailability = {
  label: string;
  name: ClassFilters;
  defaultValue: string;
  options: {
    label: string;
    value: string;
    hasResults: boolean;
  }[];
}[];

const FILTERS_PATH_ORDER = [ClassFilters.DURATIONS, ClassFilters.CLASS_TYPES];

export const toFiltersWithAvailability = (
  filters: Filters,
  selectedFilters: SelectedFilters,
  validFilterCombinations: string[],
): FiltersWithAvailability => {
  const filtersWithAvailability = filters.map(filter => ({
    ...filter,
    options: filter.options.map(option => {
      if (!option.value) {
        return {
          ...option,
          hasResults: true,
        };
      }

      const mappedPath = FILTERS_PATH_ORDER.map(key =>
        filter.name === key ? option.value : selectedFilters[key],
      )
        .filter(Boolean)
        .join('/');

      const hasResults = validFilterCombinations.includes(mappedPath);

      return {
        ...option,
        hasResults,
      };
    }),
  }));

  return filtersWithAvailability;
};
