import React from 'react';
import styled from 'styled-components';
import useBannerReference from '@acme-ui/global/hooks/useBannerReference';
import { a11yOutline, Button } from '@ecomm/buttons/buttons';
import EcommBanner, { CtaText } from '@ecomm/cms-promo-banner/EcommBanner';
import Markdown from '@page-builder/utils/Markdown';
import type { HybridBanner } from './utils';

const startInteraction = (interactionId: number) =>
  (window as any).drift.api.startInteraction({ interactionId });

const DriftInteractionBanner: React.FC<React.PropsWithChildren<HybridBanner>> = ({
  links,
  name,
  text,
  theme,
}) => {
  const bannerRef = useBannerReference();

  if (!links || !links.length) {
    return null;
  }

  const { driftInteractionId, text: ctaText } = links[0];

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => <StyledSpan data-test-id={`${name}Text`}>{children}</StyledSpan>;

  return (
    <BannerButton
      data-test-id={`${name}Url`}
      data-element-id="banner"
      onClick={() => {
        startInteraction(Number(driftInteractionId));
      }}
    >
      <EcommBanner ref={bannerRef} theme={theme}>
        <div>
          <Markdown
            content={text}
            markdown={{ renderers: { paragraph: TextWithDataTestId } }}
          />{' '}
          <CtaText tabIndex={0} data-test-id={`${name}Cta`} theme={theme}>
            {ctaText}
          </CtaText>
        </div>
      </EcommBanner>
    </BannerButton>
  );
};

export default DriftInteractionBanner;

const StyledSpan = styled.span`
  ${a11yOutline}
`;

const BannerButton = styled(Button)`
  height: auto;
  padding: 0;
  width: 100%;
`;
