import React from 'react';
import ctaPlaceholder, { PLACEHOLDER_IDS } from '../../Placeholder/CtaPlaceholder';

/**
 * Add modules here
 */
const placeholderMap = {};

/**
 * @param treatment - CF placeholder name
 * @returns React.ReactNode
 */
export const placeholderMatcher = (treatment: string) => {
  const hasCtaPlaceholder = PLACEHOLDER_IDS.some(id => treatment.includes(id));
  if (hasCtaPlaceholder) {
    return ctaPlaceholder(treatment);
  }
  return placeholderMap.hasOwnProperty(treatment) ? placeholderMap[treatment] : <></>;
};
