import type { IconNames, MediaImageProps } from '@pelotoncycle/design-system';
import {
  BreakpointWidths,
  Display,
  Flex,
  grey,
  white,
  Icon,
  Support,
  Eyebrow,
  spacing,
  Headline,
} from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toImageProps } from '@page-builder/utils/helpers/media';
import Markdown from '@page-builder/utils/Markdown';
import InfiniteScrollCarousel from './InfiniteScrollCarousel';
import 'slick-carousel/slick/slick.css'; // Anything using 'slick' requires this. Was also imported in apps/www/src/index.tsx
import type { ResponsiveCellDimensions } from './InfiniteScrollCarousel/InfiniteScrollCarousel';
import Card from './ProgrammingCarouselCard';

const ProgrammingCarousel: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text }) => {
  // a11y requirement
  useEffect(() => {
    const listWeNeedToChange = document.querySelector(
      '#valuesSection .slick-list .slick-track',
    );
    listWeNeedToChange?.setAttribute('role', 'group');
  }, []);

  const { eyebrow, headline, support, label, body } = getTextFieldsWithRequiredKeys(
    ['headline', 'support', 'label'],
    text,
  );

  const cards = items.map(item => {
    const mediaFields = toImageProps(item.fields.media);
    const { label: cardLabel } = getTextFieldsWithRequiredKeys(
      ['label'],
      item.fields.text,
    );

    return {
      image: mediaFields,
      title: cardLabel,
    };
  });

  return (
    <ProgrammingCarouselInner
      cards={cards}
      eyebrow={eyebrow}
      headline={headline}
      support={support}
      label={label}
      body={body}
    />
  );
};

type Card = {
  image: MediaImageProps | undefined;
  title: string;
};

export type Props = {
  cards: Card[];
  eyebrow?: string;
  headline: string;
  support: string;
  label: string;
  body?: string;
};

export const ProgrammingCarouselInner: React.FC<React.PropsWithChildren<Props>> = ({
  cards,
  eyebrow,
  headline,
  support,
  label,
  body,
}) => {
  return (
    <Flex
      aria-live="polite"
      id="valuesSection"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={white}
      className="programming-carousel"
    >
      <StyledWrapper>
        <Eyebrow size="small" style={{ marginBottom: spacing[16] }}>
          {body}
        </Eyebrow>
        <Markdown
          content={headline}
          markdown={{
            renderers: {
              heading:
                // eslint-disable-next-line react/display-name
                ({ node, ...props }) => (
                  <StyledDisplay size="medium" textColor={grey[90]}>
                    {props.children}
                  </StyledDisplay>
                ),
              paragraph:
                // eslint-disable-next-line react/display-name
                ({ node, ...props }) => (
                  <Headline size="small" textColor={grey[90]}>
                    {props.children}
                  </Headline>
                ),
              image:
                // eslint-disable-next-line react/display-name
                ({ node, ...props }) => (
                  <StyledIcon
                    name={props.src as IconNames}
                    height={48}
                    primaryColor={grey[90]}
                    style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                  />
                ),
            },
          }}
        />
      </StyledWrapper>
      {cards && cards.length > 0 && (
        <CarouselWrapper ariaLabelText={label} currentSlideText={support}>
          {cards.map((card: any, index: number) => {
            const { title, image } = card;
            return (
              <span key={title} data-test-id={`program-${index}`}>
                <StyledCard title={title} image={image} />
              </span>
            );
          })}
        </CarouselWrapper>
      )}
      <StyledSupport size="small">
        {eyebrow && <Markdown content={eyebrow} />}
      </StyledSupport>
    </Flex>
  );
};

export default ProgrammingCarousel;

const StyledDisplay = styled(Display)`
  display: flex;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 32px;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    margin-top: 88px;
    margin-bottom: 48px;
  }
`;

const StyledInfiniteScrollCarousel = styled(InfiniteScrollCarousel)`
  cursor: ew-resize;
  position: relative;

  .slick-slide.slick-active button {
    background-color: transparent;
  }

  .slick-bottom {
    padding-top: 10px !important;
  }

  .slick-next,
  .slick-prev {
    background-color: ${grey[70]} !important;
    opacity: 0.6;
    transition: all 0.15s ease-in-out 0s;

    &:hover {
      opacity: 0.9;
    }
  }
`;

const StyledCard = styled(Card)`
  width: 258px;
  height: 392px;
  max-width: 258px;
  max-height: 392px;
  box-shadow: none;
  overflow: hidden;
  border-radius: 6px;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    width: 288px;
    height: 440px;
    max-width: 288px;
    max-height: 440px;
  }

  @media (min-width: ${BreakpointWidths.desktop}px) {
    width: 288px;
    height: 440px;
    max-width: 288px;
    max-height: 440px;
  }
`;

const cellSizes: Required<ResponsiveCellDimensions> = {
  mobile: {
    width: 258,
    height: 392,
    spacing: 16,
  },
  tablet: {
    width: 280,
    height: 431,
    spacing: 12,
  },
  desktop: {
    width: 288,
    height: 440,
    spacing: 24,
  },
};

type WrapperProps = {
  ariaLabelText: string;
  currentSlideText: string;
};

const CarouselWrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  ariaLabelText,
  currentSlideText,
}) => {
  return (
    <StyledInfiniteScrollCarousel
      swipe
      arrowPadding={60}
      cellSizes={cellSizes}
      name="programmingCarousel"
      centerMode={true}
      dots
      responsive={[
        {
          breakpoint: BreakpointWidths.desktop,
          settings: {
            centerMode: true,
          },
        },
        {
          breakpoint: BreakpointWidths.tablet,
          settings: {
            arrows: false,
            centerMode: true,
          },
        },
      ]}
      ariaLabelText={ariaLabelText}
      currentSlideText={currentSlideText}
    >
      {children}
    </StyledInfiniteScrollCarousel>
  );
};

const StyledIcon = styled(Icon)`
  height: 38px;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    height: 48px;
  }
`;

const StyledSupport = styled(Support)`
  margin: 20px 1.5rem 3rem;
  text-align: center;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    margin-bottom: 5.5rem;
  }
`;
