import { Support, white } from '@pelotoncycle/design-system';
import React from 'react';
import type { Playlist } from '@peloton/models/PelotonClass';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

type Props = {
  playlist: Playlist;
  featuringMusicByLabel: string;
};

export const ClassDetailsFeaturedArtists: React.FC<React.PropsWithChildren<Props>> = ({
  playlist,
  featuringMusicByLabel,
}) => {
  const { topArtists, isTopArtistsShown, isTopArtistShown } = playlist;

  if (isTopArtistsShown === false || isTopArtistShown === false) return null;

  const featuredArtists = topArtists.map(artist => artist.artistName).join(', ');

  return (
    <ClassDetailsCardSection title={featuringMusicByLabel} isTextOnly>
      <Support size="large" textColor={white} as="p">
        {featuredArtists}
      </Support>
    </ClassDetailsCardSection>
  );
};
