import { Flex, spacing } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { isIOS } from '@peloton/browser';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import type { ExtLinkEnv } from '@peloton/external-links/models';
import { toAccountLink, toDigitalLink, toHref } from '@peloton/external-links/models';
import type { Locale } from '@peloton/internationalize';
import { localeTldMap, useLocale } from '@peloton/internationalize';
import { useSocialProspects, generateSocialDeepLink } from '@peloton/social-prospects';
import { TrackingEvent } from '@ecomm/analytics/models';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { RedemptionCodePayload } from '@ecomm/guest-pass-exchange';
import {
  useAppleRedemptionCodeUrl,
  toGuestPassCode,
  getTierIdFromProductName,
} from '@ecomm/guest-pass-exchange';
import type { TypeComponentProductCard } from '@page-builder/lib/types';
import { ctaExtractData } from '@page-builder/utils/helpers';
import { useCheckoutContext, MONTHLY, ANNUAL } from './CheckoutContext';
import type { CheckoutCta } from './CheckoutContext';
import ProductComparisonCard from './ProductComparisonCard';

export type Props = {
  cards: TypeComponentProductCard[];
  isMonthlyToggleSelected: boolean;
  activeProductCardIndex: number;
  setActiveProductCardIndex: (index: number) => void;
};

export const getActiveProductWithBilling = (
  isMonthlyCycle: boolean,
  activeProduct: string,
) => {
  return `${isMonthlyCycle ? MONTHLY : ANNUAL} ${activeProduct}`;
};

export const getActiveLink = (
  isMonthlyCycle: boolean,
  isSocialProspects: boolean,
  iosDeepLink: string,
  externalLinks: ExtLinkEnv,
  locale: Locale,
  productLink?: string,
) => {
  if (!productLink) return undefined;

  // Guest Pass and Social Prospects flow must append a code url param to track redemption
  if (productLink.includes('guest-pass')) {
    // iOS mobile device Social Propsects flow - send users directly to App Store for app checkout
    if (isIOS() && isSocialProspects && iosDeepLink !== '') {
      return iosDeepLink;
    }

    // Desktop Social Prospects, Android Social Prospects, and all Guest Pass flows
    // continue to web app checkout
    const accountLink = toAccountLink(
      `${productLink}/${isMonthlyCycle ? MONTHLY : ANNUAL}${window.location.search}`,
    );
    externalLinks.hostname = externalLinks.hostname.replace(
      /.com$/,
      localeTldMap[locale],
    );
    return toHref(accountLink, externalLinks);
  }

  return `${productLink}/${isMonthlyCycle ? MONTHLY : ANNUAL}`;
};

const ProductComparisonCardContainer: React.FC<React.PropsWithChildren<Props>> = ({
  cards,
  isMonthlyToggleSelected,
  activeProductCardIndex,
  setActiveProductCardIndex,
}) => {
  const { setCta } = useCheckoutContext();
  const { trackEvent } = useTracking();
  const {
    fields: {
      product: {
        fields: { name: productName },
      },
      ctas,
    },
  } = cards[activeProductCardIndex];

  const externalLinks = useExtLinkEnv();
  const locale = useLocale();
  const router = useRouter();

  const isSocialProspectsEnabled = useIsToggleActive()('socialprospectsexperience');
  const {
    inviteData,
    isSocialProspectsExperience,
    source,
    invitationId,
  } = useSocialProspects(isSocialProspectsEnabled);

  let redemptionCodeUrl = '';

  const code = router.query?.['code']?.toString();
  const accessCode = code != null && code !== '' ? toGuestPassCode(code) : '';

  const payload: RedemptionCodePayload = {
    accessCode: accessCode,
    tierId: getTierIdFromProductName(productName),
  };

  const { data } = useAppleRedemptionCodeUrl(payload);
  redemptionCodeUrl = data ?? '';

  const teamUrlPath = toHref(toDigitalLink('/community/teams/'), externalLinks);
  const iosDeepLink =
    generateSocialDeepLink(
      source,
      inviteData,
      teamUrlPath,
      redemptionCodeUrl,
      invitationId,
    ) ?? '';

  useEffect(() => {
    const cta = ctas?.[0];
    const ctaFields = cta && ctaExtractData(cta);

    const checkoutCta: CheckoutCta = {
      text: ctaFields?.text,
      link: getActiveLink(
        isMonthlyToggleSelected,
        isSocialProspectsExperience,
        iosDeepLink,
        externalLinks,
        locale,
        ctaFields?.url,
      ),
      product: getActiveProductWithBilling(isMonthlyToggleSelected, productName),
    };

    setCta(checkoutCta);
  }, [
    isMonthlyToggleSelected,
    ctas,
    productName,
    activeProductCardIndex,
    setCta,
    iosDeepLink,
    isSocialProspectsExperience,
  ]);

  const handleClick = (index: number) => {
    const billingCycle = isMonthlyToggleSelected ? MONTHLY : ANNUAL;
    const activeProduct = cards[index].fields.product.fields.name.split('(')[0].trim();
    setActiveProductCardIndex(index);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Product Block',
        parent: 'Product Comparison Cards',
        unitName: `${billingCycle} ${activeProduct} Product Card`,
        linkName: '',
        product: {
          name: activeProduct,
          billingType: billingCycle,
        },
      },
    });
  };

  return (
    <Flex
      gap={{ mobile: spacing[24], desktop: spacing[32] }}
      flexDirection={{ mobile: 'column', tablet: 'row' }}
      data-test-id="product-comparison-card-container"
    >
      {cards.map((card, i) => (
        <Flex
          flexGrow={1}
          flexBasis={{ tablet: '50%' }}
          onClick={() => handleClick(i)}
          key={card.fields.name}
        >
          <ProductComparisonCard
            card={card}
            isCardActive={activeProductCardIndex === i}
            isMonthlyToggleSelected={isMonthlyToggleSelected}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default ProductComparisonCardContainer;
