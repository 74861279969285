import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ARIconWithCircle } from '@ecomm/icons';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import FullWidthText from '@page-builder/modules/FullWidthText';

const ARHomeView: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ text }) => {
  return (
    <FullWidthText
      beforeTextContent={<StyledARIcon />}
      textAlign="center"
      headlineText={text?.fields?.headline}
      eyebrowText={text?.fields?.eyebrow}
      bodyText={text?.fields?.body}
      theme="Grey 30"
    />
  );
};

export default ARHomeView;

const StyledARIcon = styled(ARIconWithCircle)`
  width: 40px;
  height: 40px;
  margin-bottom: ${spacing[32]};
`;
