import { Container } from '@pelotoncycle/design-system';
import React from 'react';
import { useToggle } from 'react-use';
import getAffirm, { refreshAffirmUI } from '@ecomm/financing/affirm/api';
import { useOverviewLoadingWithMinimumWait } from '@page-builder/modules/Overview/utils';
import LoadingContentContainer from './LoadingContentContainer';
import LoadingSpinner from './LoadingSpinner';

type Props = {
  isLoading: boolean;
};

const LoadingWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading: trackedLoadingState,
  children,
}) => {
  const isLoading = useOverviewLoadingWithMinimumWait(trackedLoadingState);
  const [showContent, toggleShowContent] = useToggle(false);

  React.useEffect(() => {
    let timeout: number;
    if (!isLoading && !showContent) {
      setTimeout(toggleShowContent, 300);
      if (Boolean(getAffirm())) {
        refreshAffirmUI();
      }
    }

    return () => clearTimeout(timeout);
  }, [toggleShowContent, isLoading, showContent]);

  return (
    <Container position="relative" minHeight="100px">
      {!showContent && <LoadingSpinner isLoading={isLoading} />}
      <LoadingContentContainer isLoading={isLoading}>{children}</LoadingContentContainer>
    </Container>
  );
};

export default LoadingWrapper;
