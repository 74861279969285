import { Container, grey, Support, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import NextContentSizedDiv from '@peloton/content-sized-div/NextContentSizedDiv';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Partners } from '@ecomm/financing/models/Partners';
import { Chevron, Orientation } from '@ecomm/icons';
import type { TypeComponentCta, TypeComponentCtaFields } from '@page-builder/lib/types';
import { TRANSITION_SETTINGS } from '@page-builder/modules/Overview/animations';
import { toEntryTags } from '@page-builder/utils/helpers';
import CtaHandler from '../CtaHandler';
import toFinancingPartnerFromTags from './toFinancingPartnerFromTags';

type Props = {
  financingText: string | undefined;
  ctas: TypeComponentCta[];
  financingDrawerCta: TypeComponentCtaFields;
  product: string;
  parentName: string;
  isOpen: boolean;
};

const FinancingCtaHandler: React.FC<React.PropsWithChildren<Props>> = ({
  financingText,
  ctas: ctasEntry,
  financingDrawerCta,
  product,
  parentName,
  isOpen,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isToggleActive = useIsToggleActive();
  const isCitizensEnabled = isToggleActive('cpayPrequalify');

  const getCtaPartner = (cta: TypeComponentCta) => {
    const tags = toEntryTags(cta);
    return toFinancingPartnerFromTags(tags);
  };

  const filteredCtas = ctasEntry.filter(cta => {
    if (getCtaPartner(cta) === Partners.Citizens) {
      return isCitizensEnabled && cta.fields.name !== financingDrawerCta.name;
    }

    return cta.fields.name !== financingDrawerCta.name;
  });

  const [visibleCta, ...restCta] = filteredCtas;

  const buildFinancingCta = (cta: TypeComponentCta) => {
    const isCitizens = getCtaPartner(cta) === Partners.Citizens;

    return (
      <Container
        horizontalPadding={spacing[16]}
        verticalPadding={isCitizens ? `${spacing[16]} 19px` : spacing[16]}
        backgroundColor={grey[30]}
        borderRadius={spacing[4]}
        verticalMargin={`0 ${spacing[8]}`}
        position="relative"
        style={{ zIndex: 0 }}
        key={cta.fields.name}
      >
        {financingText && <Support>{financingText}</Support>}
        <CtaHandler
          cta={cta}
          product={product}
          drawerName={parentName}
          modalLayout="financing"
          isActive={isOpen}
        />
      </Container>
    );
  };

  return (
    <HeightTransition>
      {buildFinancingCta(visibleCta)}
      {!isExpanded && !!restCta.length && (
        <StyledButton onClick={() => setIsExpanded(true)}>
          <StyledSupport size="medium">{financingDrawerCta.text}</StyledSupport>
          <Chevron
            orientation={Orientation.Down}
            fill="currentColor"
            height={9}
            width={9}
          />
        </StyledButton>
      )}
      {isExpanded && restCta.map(cta => buildFinancingCta(cta))}
    </HeightTransition>
  );
};

export default FinancingCtaHandler;

const HeightTransition = styled(NextContentSizedDiv)`
  transition: ${TRANSITION_SETTINGS};
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing[4]};
  margin: ${spacing[16]} auto 0;
`;

const StyledSupport = styled(Support)`
  border-bottom: 1px solid currentColor;
  font-weight: 400;
`;
