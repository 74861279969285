import React from 'react';
import CopyXray from '@page-builder/utils/CopyXray';
import { convertTags } from '@page-builder/utils/helpers';
import type { BlockRendererProps } from '../types';
import type { BlockMap } from './getBlockProps';
import getBlockProps from './getBlockProps';

// eslint-disable-next-line react/display-name
const createBlock = (contentTypeMap: BlockMap) => ({
  block,
  index,
  fallback,
}: BlockRendererProps) => {
  const { Component, contentTypeId, id, props, className } = getBlockProps({
    block,
    map: contentTypeMap,
    index,
    fallback,
  });

  const anchorRefTag = React.useMemo(() => {
    const tags = convertTags(props.metadata.tags || []);
    const targetTag = tags.find(tag => tag.startsWith('anchor-link-ref'));
    return targetTag?.replace('anchor-link-ref-', '');
  }, [props.metadata.tags]);

  return (
    <>
      {anchorRefTag && <div aria-hidden data-test-id={anchorRefTag} id={anchorRefTag} />}
      <CopyXray block={block}>
        <Component key={`${contentTypeId}-${id}`} className={className} {...props} />
      </CopyXray>
    </>
  );
};

export default createBlock;
