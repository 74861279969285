import { useRouter } from 'next/router';
import { useLocale } from '@peloton/internationalize';
import { mapDisciplineToClassDisplayName } from '@peloton/internationalize/consolidatedAppConfigs/ecomm/classes/mapDisciplineToClassDisplayName';
import { mapDisciplineToDisplayName } from '@peloton/internationalize/consolidatedAppConfigs/ecomm/classes/mapDisciplineToDisplayName';
import { useMicroCopyBulk } from '@content/client/microCopy';
import { getClassListingPageSegments } from '@ecomm/classes/utils/getClassListingPageSegments';
import { mapSlugToDiscipline } from '@ecomm/classes/utils/mappers/mapSlugToDiscipline';
import { getFilterOptionLabel } from '../helpers/toFilters';

export type FilteredPageLabels = {
  disciplineLabel: string;
  disciplineClassLabel: string;
  durationLabel: string | null;
  classTypeLabel: string | null;
  seoPageTitleTemplate: string;
  seoPageDescriptionTemplate: string;
};

const FILTER_SEO_TITLE_TEMPLATE_KEY = 'classFiltersSeoPageTitle';
const FILTER_SEO_DESCRIPTION_TEMPLATE_KEY = 'classFiltersSeoPageDescription';

export const useFilteredPageLabels = (): FilteredPageLabels => {
  const router = useRouter();
  const locale = useLocale();
  const microcopy = useMicroCopyBulk();

  const {
    disciplineOrLanding: disciplineSlug,
    duration,
    classType,
  } = getClassListingPageSegments(router.asPath);

  const discipline = mapSlugToDiscipline(disciplineSlug);
  const disciplineLabel = mapDisciplineToDisplayName(discipline, locale);
  const disciplineClassLabel = mapDisciplineToClassDisplayName(discipline, locale);
  const durationLabel = duration && getFilterOptionLabel(microcopy, duration);
  const classTypeLabel = classType && getFilterOptionLabel(microcopy, classType);
  const seoPageTitleTemplate = microcopy[FILTER_SEO_TITLE_TEMPLATE_KEY];
  const seoPageDescriptionTemplate = microcopy[FILTER_SEO_DESCRIPTION_TEMPLATE_KEY];

  return {
    disciplineLabel,
    disciplineClassLabel,
    durationLabel,
    classTypeLabel,
    seoPageTitleTemplate,
    seoPageDescriptionTemplate,
  };
};
