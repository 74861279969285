import type { ContentfulClientApi } from 'contentful';
import type { Locale } from '@peloton/internationalize/models/locale';
import type { TypePageConfig, TypePageConfigFields } from '@page-builder/lib/types';

export enum PageConfigTags {
  CLASS_DISCIPLINE_PAGE = 'classDisciplinePage',
  CLASS_LANDING_PAGE = 'classLandingPage',
}

export const getPageConfigs = async (
  client: ContentfulClientApi,
  params: {
    slugs?: string[];
    tags?: PageConfigTags[];
    limit?: number;
    locale: Locale;
  },
): Promise<TypePageConfig[]> => {
  const { locale, slugs, tags, limit } = params;
  const criteria: Record<string, string | number> = {
    content_type: 'pageConfig',
    locale,
  };

  if (slugs) {
    criteria['fields.name[in]'] = slugs.join(',');
  }

  if (tags) {
    criteria['metadata.tags.sys.id[in]'] = tags.join(',');
  }

  if (limit) {
    criteria.limit = limit;
  }

  const { items } = await client.getEntries<TypePageConfigFields>(criteria);

  return items;
};
