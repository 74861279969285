import {
  Flex,
  Media,
  Eyebrow,
  Display,
  Support,
  grey,
  white,
  Container,
  Body,
  spacing,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toMediaProps } from '@page-builder/utils/helpers/media';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';

type Props = TypeComponentGenericTextWithMediaFields;

const Marquee: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  text,
  media,
  ctas,
}) => {
  const mediaProps = media && toMediaProps(media);
  const moduleId = nameToModuleId(name);

  if (!mediaProps) {
    throw new Error('Missing media');
  }

  const { eyebrow, headline, support, body } = getGenericTextNodes(text, ['headline']);
  const parsedCtas = ctas?.map(cta => ctaExtractData(cta));

  return (
    <Container
      backgroundColor={grey[30]}
      padding={{ desktop: `${spacing[48]} ${spacing[40]} 0` }}
      id={moduleId}
    >
      <Container maxWidth={CONTAINER_MAXWIDTH} centered>
        <Flex
          flexDirection={{ mobile: 'column', desktop: 'row-reverse' }}
          backgroundColor={grey[80]}
          centered
          borderRadius={{ desktop: spacing[12] }}
          minHeight={{ desktop: '400px' }}
        >
          {media && (
            <Flex>
              <StyledMedia media={mediaProps} />
              <MediaGradient
                display={{ desktop: 'none' }}
                width="100%"
                height="56px"
                position="absolute"
              />
            </Flex>
          )}
          <Flex
            horizontalPadding={{
              mobile: spacing[24],
              tablet: spacing[64],
              desktop: spacing[32],
            }}
            verticalPadding={{
              mobile: `${spacing[32]} ${spacing[16]}`,
              tablet: `${spacing[48]} ${spacing[16]}`,
            }}
            centered
          >
            <Flex
              justifyContent="space-between"
              flexDirection="column"
              maxWidth={{ desktop: '440px' }}
              minWidth={{ desktop: '384px' }}
            >
              <div>
                {eyebrow && (
                  <Eyebrow textColor={white} size="medium" marginBottom={spacing[16]}>
                    {eyebrow}
                  </Eyebrow>
                )}
                {headline && (
                  <Display textColor={white} is="h2" marginBottom={spacing[16]}>
                    {headline}
                  </Display>
                )}
                {body && (
                  <Body textColor={white} size="small">
                    {body}
                  </Body>
                )}
                {ctas && (
                  <LinkButtons
                    ctas={parsedCtas}
                    hasDropShadow={true}
                    size="medium"
                    buttonWidth="adaptive"
                    horizontalAlignment="left"
                    margin={`${spacing[24]} 0 ${spacing[48]} 0;`}
                    parentName={name}
                    parentId="Marquee"
                  />
                )}
              </div>
              {support && (
                <Support textColor={white} size="small">
                  {support}
                </Support>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
};

export default Marquee;

const MediaGradient = styled(Container)`
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0.9%, rgba(0, 0, 0, 0.3) 99.7%);
`;

const StyledMedia = styled(Media)`
  & > div video,
  img {
    min-height: 280px;
  }
  @media (min-width: ${BreakpointWidths.desktop}px) {
    & > div video,
    img {
      border-radius: 0 ${spacing[12]} ${spacing[12]} 0;
      max-width: 688px;
    }
  }
`;
