import type * as Contentful from 'contentful';
import type {
  TypeComponentCta,
  TypeComponentGenericTextWithMediaFields,
} from '@page-builder/lib/types';
import { ctaExtractData } from '@page-builder/utils/helpers';
import type { GenericCtaData } from '@page-builder/utils/helpers/cta';

const CTASections = ['membershipSection', 'joinForFree'];

export const mapClassPageCTAsFromContentfulToProps = (
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
) => {
  const arrayOfItems = items.filter(item => CTASections.includes(item.fields.name));

  return arrayOfItems.reduce((acc, { fields: { name, ctas } }): Record<
    string,
    GenericCtaData[]
  > => {
    acc[name] = ctas!.map((cta: TypeComponentCta) => {
      return ctaExtractData(cta);
    });

    return acc;
  }, {} as Record<string, GenericCtaData[]>);
};
