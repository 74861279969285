import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ProductStates } from '@ecomm/product-states/models/productState';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';

type ColorChoiceProps = {
  bikeColor: string;
  colorName: string;
  colorSlug: string;
  onClick: (colorSlug: string) => void;
  selected: boolean;
};

const SvgBox: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    {children}
  </svg>
);

const ColorBlob: React.FC<React.PropsWithChildren<{ bikeColor: string }>> = ({
  bikeColor,
}) => (
  <circle
    cx="16"
    cy="16"
    r="11.5"
    className="inner-circle"
    data-test-id="inner-circle"
    fill={bikeColor}
    stroke={grey[60]}
  />
);

const OuterCircle: React.FC<React.PropsWithChildren<unknown>> = () => (
  <circle cx="16" cy="16" r="15" className="outer-circle" data-test-id="outer-circle" />
);

const LineStrikethrough: React.FC<React.PropsWithChildren<unknown>> = () => (
  <line
    x1="26.3657"
    y1="5.341"
    x2="6.24673"
    y2="26.9159"
    data-test-id="circle-strikethrough"
  />
);

const ColorChoice: React.FC<React.PropsWithChildren<ColorChoiceProps>> = ({
  bikeColor,
  colorName,
  colorSlug,
  onClick,
  selected,
}) => {
  const { productPackage, productPackageLoading } = useShopContext();
  const available =
    productPackageLoading ||
    productPackage?.availability?.state === ProductStates.Available;

  return (
    <StyledButton
      data-test-id={`color-select-${colorName}`}
      onClick={() => onClick(colorSlug)}
      selected={selected}
      available={available}
    >
      <SvgBox>
        {/* no matter the status, we always show the color */}
        <ColorBlob bikeColor={bikeColor} />

        {/* this outer circle is always rendered, but opacity changes based on selected / hovered state */}
        <OuterCircle />

        {/* also show strikethrough when unavailable */}
        {!available && <LineStrikethrough />}
      </SvgBox>
    </StyledButton>
  );
};

export default ColorChoice;

const StyledButton = styled.button<{ available: boolean; selected: boolean }>`
  // the outer circle is only shown when a color is selected or not available (part of the cross out)
  circle.outer-circle,
  line {
    transition: opacity 0.15s ease-in-out, stroke 0.15s ease-in-out;
    opacity: ${({ available, selected }) => (selected || !available ? '1' : '0')};
  }

  // the outer circle and line are darker and heavier stroke when selected
  circle.outer-circle,
  line {
    stroke: ${({ selected }) => (selected ? grey[90] : grey[60])};
    stroke-width: ${({ selected }) => (selected ? '2' : '1')};
  }

  // on hover the outer circle and line are darker and the outer circle is shown (even though it's not selected from above)
  :hover {
    circle.outer-circle,
    line {
      stroke: ${grey[90]};
    }
    circle.outer-circle {
      opacity: 1;
    }
  }
`;
