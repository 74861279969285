import type {
  TypeComponentImageFields,
  TypeComponentMedia,
  TypeComponentVideoFields,
  TypeProductFields,
} from '@page-builder/lib/types';
import type {
  MediaImageProps,
  MediaVideoProps,
  ProductProps,
} from '../../../../apps/freeform/types/referenceTypes';
import type { ImageCloudinaryOptions } from './cloudinary';
import { toUrlOrBreakpointCloudinarySrc } from './cloudinary';

export const imageFieldsToMediaImageProps = (
  data: TypeComponentImageFields,
  skipCloudinary?: boolean,
  imageCloudinaryOptions: ImageCloudinaryOptions = {},
): MediaImageProps => {
  const { mobile, tablet, desktop, alt, caption } = data;

  return {
    mobile: mobile
      ? toUrlOrBreakpointCloudinarySrc({
          asset: mobile,
          breakpoint: 'mobile',
          skipCloudinary,
          imageCloudinaryOptions,
        })
      : '',
    tablet: tablet
      ? toUrlOrBreakpointCloudinarySrc({
          asset: tablet,
          breakpoint: 'tablet',
          skipCloudinary,
          imageCloudinaryOptions,
        })
      : '',
    desktop: desktop
      ? toUrlOrBreakpointCloudinarySrc({
          asset: desktop,
          breakpoint: 'desktop',
          skipCloudinary,
          imageCloudinaryOptions,
        })
      : '',
    alt: alt || '',
    caption: caption || '',
    type: 'image',
  };
};

export const videoFieldsToMediaVideoProps = (
  data: TypeComponentVideoFields,
  overrides?: Partial<MediaVideoProps>,
): MediaVideoProps => {
  const {
    autoplay = true,
    loop = true,
    theme = 'light',
    playPauseButtonPosition = 'right',
    showMuteButton,
    poster,
    tracks,
    video,
    mobile,
    tablet,
    desktop,
  } = data;

  return {
    src: video ? video.fields.file.url : '',
    ...(poster
      ? {
          poster: poster.fields.mobile.fields.file.url,
          thumbnail: imageFieldsToMediaImageProps(poster.fields),
        }
      : {}),
    ...(tracks
      ? {
          tracks: tracks.map(({ fields }) => ({
            src: fields.src.fields.file.url,
            kind: fields.kind,
          })),
        }
      : {}),
    autoplay,
    loop,
    playPauseButtonPosition,
    muteButtonPosition: showMuteButton ? 'right' : 'hide',
    theme,
    ...(mobile
      ? {
          responsiveVideoSrc: {
            mobile: mobile.fields.file.url,
            tablet: tablet?.fields.file.url,
            desktop: desktop?.fields.file.url,
          },
        }
      : {}),
    type: 'video',
    ...overrides,
  };
};

export const toMediaProps = (
  data: TypeComponentMedia,
  skipCloudinary?: boolean,
  imageCloudinaryOptions?: ImageCloudinaryOptions,
): MediaVideoProps | MediaImageProps => {
  const mediaFields = data.fields.media.fields;

  if ('video' in mediaFields) {
    return videoFieldsToMediaVideoProps(mediaFields);
  }

  if ('code' in mediaFields) {
    throw new Error(`import useIFrameProps instead`);
  }

  return imageFieldsToMediaImageProps(
    mediaFields,
    skipCloudinary,
    imageCloudinaryOptions,
  );
};

export const toProductProps = (data: TypeProductFields): ProductProps => {
  const {
    bundleType,
    link,
    shopLink,
    image,
    productId,
    productType,
    hasRental,
    hasRefurb,
  } = data;

  return {
    bundleType,
    link: link.fields.url,
    shopLink: shopLink?.fields.url,
    image: {
      url: image
        ? toUrlOrBreakpointCloudinarySrc({
            asset: image,
            breakpoint: 'mobile',
            skipCloudinary: false,
            imageCloudinaryOptions: {},
          })
        : undefined,
      alt: image?.fields.description || '',
    },
    productId,
    productType,
    hasRental: Boolean(hasRental),
    hasRefurb: Boolean(hasRefurb),
  };
};
