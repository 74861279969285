import { black, grey, white, spacing, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { TOCItemProps } from './types';

export const scrollToCenter = (element: HTMLAnchorElement | null) => {
  const clientRects = element?.getClientRects();
  const scrolledAncestor = element?.parentElement?.parentElement?.parentElement;
  const parentClientRects = scrolledAncestor?.getClientRects();

  if (clientRects?.length === 0 || parentClientRects?.length === 0) return;

  const halfWidth = parentClientRects && parentClientRects[0].width / 2;
  const buttonCenter = clientRects && clientRects[0].x + clientRects[0].width / 2;
  if (buttonCenter && halfWidth) {
    scrolledAncestor?.scrollTo({ left: buttonCenter - halfWidth, behavior: 'smooth' });
  }
};

const TOCItem: React.FC<React.PropsWithChildren<TOCItemProps>> = ({
  activeId,
  cta,
  handleClick,
}) => {
  const sectionId = cta.link.url;
  const currentRef = React.useRef<HTMLAnchorElement>(null);

  if (cta.variant === 'outline') {
    return (
      <StyledOutlineLink
        key={cta.text}
        onClick={e => handleClick(e, cta, sectionId)}
        href={sectionId}
        ref={currentRef}
        isActive={activeId === sectionId}
      >
        <Label as="span">{cta.text}</Label>
      </StyledOutlineLink>
    );
  }

  return (
    <StyledDefaultLink
      key={cta.text}
      onClick={e => handleClick(e, cta, sectionId)}
      href={sectionId}
      isActive={activeId === sectionId}
    >
      <Label as="span" size="large">
        {cta.text}
      </Label>
    </StyledDefaultLink>
  );
};

export default TOCItem;

const StyledOutlineLink = styled.a<{ isActive: boolean }>`
  box-shadow: 0 0 0 1px ${grey[50]};
  border-radius: ${spacing[8]};
  padding: ${spacing[8]} ${spacing[16]};
  height: ${spacing[40]};
  display: flex;
  align-items: center;
  background: ${white};
  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 1.5px ${black};
      transition: box-shadow 500ms ease-in-out;
    }
  }
  ${({ isActive }) => isActive && `color: ${white}; background-color: ${black};`}
  transition: all 300ms ease-in-out;
`;

const StyledDefaultLink = styled.a<{ isActive: boolean }>`
  color: ${white};
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${grey[50]};
  }

  ::after {
    content: '';
    display: block;
    height: 1px;
    margin-top: -1px;
    transition: background-color 0.15s ease-in-out;
  }

  ${({ isActive }) =>
    isActive &&
    `
      &:hover::after {
        background-color: ${grey[50]};
      }

      ::after {
        background-color: ${white};
      }
    `}
`;
