import { zodResolver } from '@hookform/resolvers/zod';
import type { TypeMetadataLeadGenFields } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import type { BaseSyntheticEvent } from 'react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTracking } from 'react-tracking';
import type { ZodType } from 'zod';
import { z } from 'zod';
import { toCountry } from '@peloton/internationalize';
import { postHistorySummary } from './api';

interface Props {
  children: React.ReactNode;
  emailCaptureMetaData: Entry<TypeMetadataLeadGenFields>;
}

export type HistoryReportForm = {
  serialNumber: string;
  email: string;
  buyingOrSelling: string;
  termsAndConditionsAccepted: boolean;
  marketingEmailConsentAccepted?: boolean;
};

const schema: ZodType<HistoryReportForm> = z.object({
  serialNumber: z.string().max(16).min(12),
  email: z.string().email().min(1),
  buyingOrSelling: z.string().min(1),
  termsAndConditionsAccepted: z.boolean().refine(value => value === true),
  marketingEmailConsentAccepted: z.boolean(),
});

export const HistoryReportFormWrapper: React.FC<Props> = ({
  children,
  emailCaptureMetaData,
}) => {
  const { trackEvent } = useTracking();
  const methods = useForm<HistoryReportForm>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    values: {
      serialNumber: '',
      email: '',
      buyingOrSelling: '',
      termsAndConditionsAccepted: false,
      marketingEmailConsentAccepted: false,
    },
  });

  const submitEmailForMarketing = async (email: string, buyingOrSelling: string) => {
    return trackEvent({
      event: 'Submitted Email for Marketing',
      properties: {
        unitName: buyingOrSelling,
        campaignId: emailCaptureMetaData.fields.campaignId,
        campaignName: emailCaptureMetaData.fields.campaignName,
        marketingConsent: emailCaptureMetaData.fields.marketingConsent,
        country: toCountry(),
        email,
      },
    });
  };

  const onSubmit = async (data: HistoryReportForm, e: BaseSyntheticEvent) => {
    e.preventDefault();
    const { email, buyingOrSelling, marketingEmailConsentAccepted, serialNumber } = data;
    const response = await postHistorySummary({ email, serialNumber });
    const { success, data: summaryHistory, message, error, limitError } = response;
    if (success && summaryHistory !== null) {
      marketingEmailConsentAccepted &&
        (await submitEmailForMarketing(email, buyingOrSelling));
      const historySummaryURl = `/history-summary/${serialNumber}`;
      window.open(historySummaryURl, '_blank') ||
        window.location.assign(historySummaryURl);
    } else if (error) {
      if (limitError) {
        methods.setError('root.dailyLimitError', {
          type: 'dailyLimitError',
          message:
            "You've reached your daily limit of requests. Please try again in 24 hours.",
        });
        trackEvent({
          event: 'Daily Limit Error',
          properties: {
            message:
              "You've reached your daily limit of requests. Please try again in 24 hours.",
          },
        });
      } else {
        methods.setError('root.httpErrorMessage', {
          type: 'httpError',
          message: message,
        });
        trackEvent({
          event: 'HTTP Error Message',
          properties: {
            message: message,
          },
        });
      }
    } else {
      methods.setError('root.serverError', {});
      trackEvent({
        event: 'Server Error',
        properties: {
          message: message,
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
