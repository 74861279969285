import type { NextRouter } from 'next/router';
import { TrackingEvent } from '@ecomm/analytics/models';

//qr codes that lead to the page that this component is used on have two query params, sub=true|false and referrer=checkout|web-home
const useTrackingParams = (query: NextRouter['query']) => {
  const hasSubscription = query['sub'] ?? '';
  const referrerParam = query['referrer'];
  const eventType = referrerParam
    ? referrerParam === 'checkout'
      ? TrackingEvent.CheckoutQrCodeScanned
      : TrackingEvent.FeaturedQrCodeScanned
    : '';

  return { hasSubscription, eventType };
};

export default useTrackingParams;
