import { useState, useEffect } from 'react';
import type { Progress } from '../models/progress';

const readProgressFromSession = (sessionKey: string): Progress => {
  const savedProgress = sessionStorage.getItem(sessionKey);
  const savedState = (savedProgress && JSON.parse(savedProgress)) || {};

  return { ...savedState };
};

export const writeProgressToSession = (progress: Progress, sessionKey: string) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(progress));
};

export const useSyncProgressWithSession = (progress: Progress, sessionKey: string) => {
  useEffect(() => {
    if (Object.keys(progress).length) {
      writeProgressToSession(progress, sessionKey);
    }
  }, [progress, sessionKey]);
};

export const useSessionProgressState = (sessionKey: string) => {
  const [progress, setProgress] = useState<Progress>({});

  useEffect(() => {
    setProgress(readProgressFromSession(sessionKey));
  }, [sessionKey]);

  useSyncProgressWithSession(progress, sessionKey);

  return [progress, setProgress, sessionKey] as const;
};
