import {
  Container,
  white,
  grey,
  accessibility,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';

const SIZE = 8;
const GAP = 16;
export const SUBNAV_SIZE = 72;

type SectionWrapperProps = {
  position: number;
  total: number;
  children: React.ReactNode;
  subnavSize: number;
  className?: string;
};

// Important: This should only ever be used within a modal or other guaranteed client-side, code;
const SectionWrapper: React.FC<React.PropsWithChildren<SectionWrapperProps>> = ({
  position,
  total,
  subnavSize,
  className,
  children,
}) => {
  const wrapperEl = React.useRef<HTMLDivElement>(null);

  const wrapperHeight = wrapperEl?.current?.clientHeight ?? 0;
  const modalHeight = window?.innerHeight ?? 0;
  const wrapperWithHeaderHeight = wrapperHeight + subnavSize;
  const marginBottom = wrapperWithHeaderHeight - modalHeight;

  const { ref: notARealRef, inView } = useInView({
    threshold: 0.5,
    rootMargin: `-${subnavSize}px 0% ${marginBottom}px 0%`,
  });

  const zoomIntoImageCopy = useMicroCopy('zoomIntoImage');

  return (
    <Container
      backgroundColor={white}
      ref={wrapperEl}
      className={`pos${position} ${className}`}
    >
      <Container backgroundColor={white} ref={notARealRef}>
        {children}
        <Container display={{ mobile: 'none', desktop: 'block' }}>
          <Breadcrumb
            aria-label={`${zoomIntoImageCopy} - ${position + 1}`}
            position={position}
            total={total}
            data-test-id={`breadcrumbNavDot${position}`}
            active={inView}
            onClick={() => wrapperEl?.current?.scrollIntoView({ behavior: 'smooth' })}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default SectionWrapper;

export type BreadcrumbProps = {
  active: boolean;
  position: number;
  total: number;
};

export const Breadcrumb = styled.button<BreadcrumbProps>`
  all: unset;
  position: fixed;
  cursor: pointer;
  top: ${props =>
    `calc(50vh - ${
      (props.total / 2) * (SIZE + GAP) -
      (props.total % 2 ? 0 : GAP / 2) -
      props.position * (SIZE + GAP) -
      SUBNAV_SIZE / 2
    }px)`};
  right: ${spacing[32]};
  width: ${spacing[8]};
  height: ${spacing[8]};
  border-radius: ${spacing[8]};
  background-color: ${props => (props.active ? grey[90] : grey[50])};
  &:hover {
    background-color: ${grey[70]};
  }
  &:focus-visible {
    background-color: ${grey[70]};
    outline: 2px solid ${accessibility.outline};
  }
  &::after {
    content: '';
    position: absolute;
    top: -${GAP / 2}px;
    left: -${GAP / 2}px;
    width: ${SIZE + GAP}px;
    height: ${SIZE + GAP}px;
  }
`;
