import { Label, grey, spacing, white } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import { CLASSES_FILTERS_ID } from './constants';
import { GenericDropdown } from './GenericDropdown';
import type { FiltersWithAvailability } from './helpers/toFiltersWithAvailability';
import type { SelectedFilters } from './hooks/useClassFilters';
import { toPageSlug } from './hooks/useClassFilters';
import { RadioLink } from './RadioLink';

export type DesktopClassesFiltersProps = {
  filters: FiltersWithAvailability;
  initialSelectedState: SelectedFilters;
  clearedState: SelectedFilters;
  disciplineSlug: string;
  hasSelectedFilters: boolean;
  selectedFilters: SelectedFilters;
  requiresSeparation: boolean;
};

export const DesktopClassesFilters: React.FC<
  React.PropsWithChildren<DesktopClassesFiltersProps>
> = ({
  filters,
  initialSelectedState,
  clearedState,
  disciplineSlug,
  hasSelectedFilters,
  selectedFilters,
  requiresSeparation,
}) => {
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();
  const classFiltersClearLabel = useMicroCopy('classFiltersClearLabel');
  const classFiltersClearSingleLabel = useMicroCopy('classFiltersClearSingleLabel');
  const classFiltersClearSingleA11yLabel = useMicroCopy(
    'classFiltersClearSingleA11yLabel',
  );
  const { asPath } = useRouter();
  const [highlightedOptions, setHighlightedOptions] = useState(clearedState);
  const hasInitialFilterSelected = !!Object.values(initialSelectedState).join('');

  useEffect(() => {
    setHighlightedOptions(clearedState);
  }, [asPath]);

  const highlightAllSelectedFilters = () => {
    const allClearedFilters = Object.entries(initialSelectedState).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value ? ':cleared:' : '',
      }),
      {} as SelectedFilters,
    );
    setHighlightedOptions(allClearedFilters);
  };

  const trackClick = useCallback(
    (filterName: string, itemSlug: string) => {
      trackEvent({
        event: 'Clicked Filter Option',
        properties: {
          parentType: 'Component: Generic List',
          parent: 'Desktop Classes Filters',
          unitName: filterName,
          linkName: itemSlug,
          page: pathName,
        },
      });
    },
    [pathName, trackEvent],
  );

  return (
    <StyledFilters
      requiresSeparation={requiresSeparation}
      data-test-id="desktop-classes-filters-wrapper"
    >
      {/* Filter dropdowns */}
      {filters.map(filter => {
        const filterId = `filter-${filter.name}`;
        const hasOptionSelected = !!initialSelectedState[filter.name];
        const slugWithClearedFilter = toPageSlug(disciplineSlug, {
          ...initialSelectedState,
          [filter.name]: '',
        });

        const selectedOptionValue = initialSelectedState[filter.name];
        const selectedOptionLabel = hasOptionSelected
          ? filter.options.find(({ value }) => value === selectedOptionValue)?.label
          : null;
        const dropdownLabel = selectedOptionLabel || filter.label;
        const dropdownAriaLabel =
          typeof selectedOptionLabel === 'string'
            ? `${filter.label}: ${selectedOptionLabel}`
            : filter.label;

        return (
          <StyledFilter key={filterId}>
            <GenericDropdown
              label={dropdownLabel}
              ariaLabel={dropdownAriaLabel}
              id={filterId}
              isHighlighted={!!selectedFilters[filter.name]}
              isLoading={!!highlightedOptions[filter.name]}
              isBorderHighlighted={
                !!initialSelectedState[filter.name] || !!highlightedOptions[filter.name]
              }
            >
              <StyledOptionList>
                {filter.options.map(option => {
                  if (option.value === '') {
                    return null;
                  }

                  const filterTargetSlug = toPageSlug(disciplineSlug, {
                    ...initialSelectedState,
                    [filter.name]: option.value,
                  });

                  const isChecked = highlightedOptions[filter.name]
                    ? highlightedOptions[filter.name] === option.value
                    : initialSelectedState[filter.name] === option.value;

                  return (
                    <StyledOptionListItem key={`filter-${filter.name}-${option.value}`}>
                      <DSLink
                        href={`${filterTargetSlug}`}
                        scroll={!hasInitialFilterSelected}
                        isDisabled={!option.hasResults}
                        hasUnderline={false}
                      >
                        <RadioLink
                          label={option.label}
                          disabled={!option.hasResults}
                          checked={isChecked}
                          onClick={() => {
                            setHighlightedOptions({
                              ...highlightedOptions,
                              [filter.name]: option.value,
                            });
                            trackClick(filter.name, filterTargetSlug);
                          }}
                        />
                      </DSLink>
                    </StyledOptionListItem>
                  );
                })}

                <StyledOptionListItem>
                  <StyledClearLink
                    href={`${slugWithClearedFilter}#${CLASSES_FILTERS_ID}`}
                    hasUnderline
                    isDisabled={!hasOptionSelected}
                    ariaLabel={classFiltersClearSingleA11yLabel.replace(
                      '{filter}',
                      filter.label.toLowerCase(),
                    )}
                    onClick={() =>
                      setHighlightedOptions({
                        ...highlightedOptions,
                        [filter.name]: ':clear:',
                      })
                    }
                  >
                    {classFiltersClearSingleLabel}
                  </StyledClearLink>
                </StyledOptionListItem>
              </StyledOptionList>
            </GenericDropdown>
          </StyledFilter>
        );
      })}

      {/* Reset button */}
      {hasSelectedFilters && (
        <StyledFilter>
          <Link
            href={`${toPageSlug(disciplineSlug, clearedState)}#${CLASSES_FILTERS_ID}`}
            hasUnderline={false}
          >
            {/* eslint-disable-next-line */}
            <StyledResetLink onClick={highlightAllSelectedFilters}>
              <StyledResetLinkLabel>
                <Label size="medium" is="span">
                  {classFiltersClearLabel}
                </Label>
              </StyledResetLinkLabel>
            </StyledResetLink>
          </Link>
        </StyledFilter>
      )}
    </StyledFilters>
  );
};

const StyledFilters = styled.div<{ requiresSeparation: boolean }>`
  display: none;
  gap: ${spacing[8]};
  margin-right: ${spacing[16]};
  padding-right: ${spacing[16]};
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: ${28 / 16}rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${({ requiresSeparation }) =>
      requiresSeparation ? grey[50] : 'transparent'};
  }

  ${media.tabletXLarge`
    display: flex;
    align-items: baseline;
    flex-shrink: 0;
  `}
`;

const StyledFilter = styled.div`
  flex-shrink: 0;
`;

const StyledOptionList = styled.ol``;

const StyledOptionListItem = styled.li`
  & + & {
    margin-top: ${spacing[16]};
  }

  & + &:last-of-type {
    margin-top: ${spacing[40]};
  }
`;

const StyledClearLink = styled(Link)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? `
    color: ${grey[50]} !important;
    pointer-events: none;
    &:hover { color: ${white} !important; }
  `
      : `color: ${white} !important;`}
`;

const StyledResetLink = styled.span`
  display: block;
  align-self: center;
  padding: 0 0 0 ${spacing[8]};
  color: ${white};
  transition: color 0.15s ease-out;
`;

const StyledResetLinkLabel = styled.span`
  display: inline;
  background-image: linear-gradient(180deg, currentColor, currentColor);
  background-size: 100% 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
`;

const DSLink = styled(Link)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? `
    color: ${grey[50]};
    pointer-events: none;
    &:hover { color: ${white}; }
    &:focus { outline: none; }
  `
      : `color: ${white}; &:hover { color: ${white}; }`}
`;
