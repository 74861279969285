import type { SyntheticEvent } from 'react';

/*
 autoPlayWithSound
 - an event that can be passed to the onCanPlay event of a video
   to enable a video to autoplay with the sound enabled.
 */

const autoPlayWithSound = (e: SyntheticEvent<HTMLMediaElement>) => {
  const video = e.target as HTMLVideoElement;
  video.autoplay = true;
  video.muted = false;
  video.play().catch(err => console.error(err));
};

export default autoPlayWithSound;
