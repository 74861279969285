import { red, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { CoveredType } from '@onewellness/models/programBenefit';

export type CustomPricing = {
  subscriptionType: CoveredType;
  retailRate: string;
  userPays: string;
  overrideOnClick?: () => void;
};

type Props = {
  retailRate: CustomPricing['retailRate'];
  userPays: CustomPricing['userPays'];
};

const CustomPriceDisplay: React.FC<React.PropsWithChildren<Props>> = ({
  userPays,
  retailRate,
}) => {
  return (
    <PriceDisplay data-test-id="price-display">
      {userPays} <s>{retailRate}</s>
    </PriceDisplay>
  );
};

export default CustomPriceDisplay;

const PriceDisplay = styled.p`
  color: ${red[50]};

  s {
    color: ${grey[60]};
  }
`;
