import { toBundlePriceRange, hasVariablePricingBundle } from '@ecomm/accessories/models';
import type {
  ProductItemOfBundle,
  AccessoryBundleCommercetools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { Attribute } from '@ecomm/product-recommendations/models/Product';
import type { BundleFieldsFragment } from '@ecomm/product-recommendations/queries/AllProducts.generated';
import { toPriceRangeTuple } from '@ecomm/product-recommendations/utils/productUtils';

type Bundle = AccessoryBundleCommercetools | BundleFieldsFragment;

export const toCommonProductFieldsFromBundle = (bundle: Bundle) => {
  const bundleProducts = bundle.products as ProductItemOfBundle[];

  const nonConfigurableProductsArray = bundleProducts.filter(
    prod => prod.attributes.length === 0,
  );

  const uniqueBundleImages = new Set<string>(
    [bundle.image?.src, ...bundleProducts.map(product => product.images[0]?.src)].filter(
      Boolean,
    ),
  );

  const price = hasVariablePricingBundle(bundle)
    ? toPriceRangeTuple(toBundlePriceRange(bundleProducts))
    : bundle.price.amount;

  return {
    productType: 'bundle' as const,
    slug: bundle.slug,
    name: bundle.name,
    description: bundle.description,
    imageUrl: bundle.image!.src,
    images: [...uniqueBundleImages],
    price,
    attributes: bundleProducts
      .map(prod => {
        const attrs: Attribute = prod.attributes[0];
        if (attrs) {
          attrs.productSlug = prod.slug;
        }
        return attrs ? [attrs] : [];
      })
      .flat(),
    variants: bundleProducts.map(prod => prod.variants).flat(),
    nonConfigurableProducts: nonConfigurableProductsArray.map(prod => prod.slug),
    nonConfigurableProductsPrices: nonConfigurableProductsArray.map(
      prod => prod.price.amount as number,
    ),
    isConfigurable: bundle.products.some(prod => prod.attributes.length > 0),
  };
};
