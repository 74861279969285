import { Flex, spacing, Label, Container, Grid } from '@pelotoncycle/design-system';
import type {
  TypeComponentGenericListFields,
  TypeComponentCtaFields,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toCtaFields } from '@page-builder/utils/helpers';
import {
  MOBILE_CARD_INFO_HEIGHT,
  MOBILE_MEDIA_HEIGHT,
  TRANSITION_DURATION,
} from './constants';
import HighlightReelCard from './HighlightReelCard';
import HighlightReelMobileCtas from './HighlightReelMobileCtas';

type Props = {
  items: TypeComponentGenericListFields['items'];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onVideoEnded: () => void;
  isInView: boolean;
  handleJumpScroll: (
    event: React.MouseEvent<Element, MouseEvent>,
    cta: TypeComponentCtaFields,
    sectionId: string,
  ) => void;
  headlineColor: string;
};

const MobileHighlightReel: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  activeIndex,
  setActiveIndex,
  onVideoEnded,
  isInView,
  handleJumpScroll,
  headlineColor,
}) => {
  return (
    <Flex
      flexDirection="column"
      gap={spacing[16]}
      minWidth="100%"
      data-test-id="mobile-highlight-reel"
    >
      <HighlightReelMobileCtas
        items={items}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <MobileCardContainer>
        {items.map((item, idx) => {
          const active = activeIndex === idx;

          return (
            <HighlightReelCard
              {...item.fields}
              key={item.fields.name}
              active={active}
              onVideoEnded={onVideoEnded}
              position={idx}
              isInView={isInView}
            />
          );
        })}
      </MobileCardContainer>
      <CardInfoContainer position="relative">
        {items.map((item, idx) => {
          const active = activeIndex === idx;
          const { eyebrow } = getGenericTextNodes(item.fields.text);
          const secondaryCta = item.fields.ctas && toCtaFields(item.fields.ctas)[1];
          const sectionId = secondaryCta?.link?.fields.url || '';
          const ctaText = secondaryCta?.text;

          return (
            <ContentContainer active={active} key={item.fields.name}>
              {eyebrow && (
                <EyebrowContainer active={active}>
                  <Label size="large" textColor={headlineColor} weight="regular">
                    {eyebrow}
                  </Label>
                </EyebrowContainer>
              )}
              {ctaText && (
                <LearnMoreLinkContainer active={active}>
                  <StyledLink
                    hasUnderline
                    size="small"
                    href={sectionId}
                    is="button"
                    onClick={e => handleJumpScroll(e, secondaryCta, sectionId)}
                    textColor={headlineColor}
                  >
                    <Label size="large" weight="medium">
                      {ctaText}
                    </Label>
                  </StyledLink>
                </LearnMoreLinkContainer>
              )}
            </ContentContainer>
          );
        })}
      </CardInfoContainer>
    </Flex>
  );
};

export default MobileHighlightReel;

const MobileCardContainer = styled.div`
  height: ${MOBILE_MEDIA_HEIGHT}px;
  position: relative;
  width: 100%;
`;

const CardInfoContainer = styled(Grid)`
  min-height: ${MOBILE_CARD_INFO_HEIGHT}px;
  width: 100%;
  grid-template-columns: 1fr;
`;

const ContentContainer = styled(Container)`
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: ${(props: { active: boolean }) => (props.active ? 1 : 0)};
  opacity: ${(props: { active: boolean }) => (props.active ? 1 : 0)};
  transition: ${TRANSITION_DURATION} opacity;
`;

const LearnMoreLinkContainer = styled(Container)`
  bottom: 0;
  left: 0;
  width: 100%;
  transition: ${TRANSITION_DURATION} opacity;
  opacity: ${(props: { active: boolean }) => (props.active ? 1 : 0)};
`;

const EyebrowContainer = styled(Container)`
  top: 0;
  left: 0;
  width: 100%;
  transition: ${TRANSITION_DURATION} opacity;
  opacity: ${(props: { active: boolean }) => (props.active ? 1 : 0)};
`;

const StyledLink = styled(Link)`
  color: ${(props: { textColor: string }) => props.textColor};
`;
