import React from 'react';

export const RightArrow: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 2 Copy">
      <path
        id="Oval Copy 8 (Stroke)"
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.799999C24.3947 0.799999 31.2 7.60527 31.2 16C31.2 24.3947 24.3947 31.2 16 31.2C7.60527 31.2 0.8 24.3947 0.8 16C0.8 7.60527 7.60527 0.799999 16 0.799999ZM16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0Z"
        fill="#F5F7F9"
      />
      <g id="icon / arrow / large">
        <path
          id="Path 59 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.167 23.765L13.0343 22.635L19.6706 15.9828L13.0687 9.36501L14.2014 8.235L21.9307 15.9828L14.167 23.765Z"
          fill="#F5F7F9"
        />
      </g>
    </g>
  </svg>
);
