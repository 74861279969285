import { Link as BaseLink } from '@pelotoncycle/design-system';
import type { LinkProps } from '@pelotoncycle/design-system';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { USE_NEXT_LINK } from '@peloton/app-config';
import {
  useIsExternalToProspects,
  useIsNextApp,
  useIsProspectsToExternalUrl,
} from '@peloton/next/hooks/useIsNextHooks';
import { Link as I18nLink } from '@ecomm/internationalize-ui';

type LinkableProps = LinkProps & {
  prefetch?: boolean;
  replace?: boolean;
  scroll?: boolean;
};
export const Link = withI18n(BaseLink);

function withI18n(
  Component: React.ComponentType<React.PropsWithChildren<LinkableProps>>,
) {
  return function WithI18n({
    href,
    prefetch,
    replace,
    scroll,
    ...props
  }: LinkableProps & {
    prefetch?: boolean;
    replace?: boolean;
    scroll?: boolean;
  }) {
    const router = useRouter();
    const isNextApp = useIsNextApp();
    const isProspectsToExternalUrl = useIsProspectsToExternalUrl(href ?? '');
    const isExternalToProspects = useIsExternalToProspects(href ?? '');
    const isProspectsApp = router?.basePath === '/classes';

    if (
      USE_NEXT_LINK &&
      isNextApp &&
      !isProspectsToExternalUrl &&
      !isExternalToProspects
    ) {
      return (
        <Component
          component={NextLink}
          componentProps={{
            passHref: true,
            legacyBehavior: true,
            scroll,
          }}
          is={'a'}
          href={
            isProspectsApp && href?.startsWith('/classes')
              ? href.replace('/classes', '')
              : href
          }
          {...props}
        />
      );
    }

    if (isProspectsApp && !href?.startsWith('https') && !href?.startsWith('/classes')) {
      href = `/classes${href}`;
    }

    return <I18nLink href={href} {...props} />;
  };
}
