import { ReviewEquipmentType } from '@ecomm/graphql/types.generated';
import { DeviceType } from '@ecomm/models/DeviceType';

export const toEquipmentType = (deviceType: DeviceType): ReviewEquipmentType => {
  switch (deviceType) {
    case DeviceType.Tread:
      return ReviewEquipmentType.Tread;
    case DeviceType.TreadPlus:
      return ReviewEquipmentType.Treadplus;
    case DeviceType.BikePlus:
      return ReviewEquipmentType.Bikeplus;
    case DeviceType.Guide:
      return ReviewEquipmentType.Guide;
    case DeviceType.Row:
      return ReviewEquipmentType.Row;
    case DeviceType.RefurbishedBike:
      return ReviewEquipmentType.Refurbbike;
    case DeviceType.RefurbishedBikePlus:
      return ReviewEquipmentType.Refurbbikeplus;
    default:
      return ReviewEquipmentType.Bike;
  }
};
