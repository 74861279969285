import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { asyncComponent } from '@peloton/code-splitting';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import type {
  PageBuilderTheme,
  GridTheme,
} from '@ecomm/product-recommendations/utils/theme';
import {
  DEFAULT_THEME_NAME,
  toGridTheme,
} from '@ecomm/product-recommendations/utils/theme';
import toGridOptions from '@ecomm/product-recommendations/utils/toGridOptions';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';

const GridContentWrapper = asyncComponent(() => import('./GridContentWrapper'));

type Props = {
  cohort: ProductRecommendationsCohort;
  treatment: TypeComponentGenericListFields['treatment'];
  themeName?: PageBuilderTheme;
};

const GridOuterWrapper: React.FC<React.PropsWithChildren<Props>> = props => {
  const { cohort, themeName = DEFAULT_THEME_NAME } = props;
  const gridTheme = toGridTheme(themeName);

  // TODO: Refactor this method to not require the cohort or products
  const { verticalPadding, minHeight, removeOuterContainerTheme } = toGridOptions({
    cohort,
    products: [],
    treatment: props.treatment,
  });

  return (
    <GridOuterContainer
      theme={removeOuterContainerTheme ? undefined : themeName}
      gridTheme={gridTheme}
      verticalPadding={verticalPadding}
      horizontalPadding="0"
      maxWidth="100%"
      minHeight={minHeight}
    >
      <GridContentWrapper {...props} />
    </GridOuterContainer>
  );
};

const GridOuterContainer = styled(OuterContainer)<{
  gridTheme: GridTheme;
  minHeight: number;
}>`
  min-height: ${props => props.minHeight}px;
  background-color: ${props => props.gridTheme.backgroundColor};
  color: ${props => props.gridTheme.textColor};

  --strikethrough-color: ${props => props.gridTheme.strikethroughColor};
  --highlight-color: ${props => props.gridTheme.highlightColor};
`;

export default GridOuterWrapper;
