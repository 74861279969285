import { spacing, Grid, ErrorMsg, RadioBox } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';

import { useProductConfigurationContext } from '../ProductConfigurationContext';
import type { AccessoryWithMetaData } from './types';

const ErrorMsgContainer = styled.div`
  margin-top: ${spacing[8]};
`;

const StyledRadioBox = styled(RadioBox)`
  height: 56px !important;
`;

const SingleSelectRadioGroup: React.FC<
  React.PropsWithChildren<{
    accessory: AccessoryWithMetaData;
  }>
> = ({ accessory }) => {
  const selectMicroCopy = useMicroCopy('selectQuantity');
  const {
    setProductConfigurations,
    productConfigurations,
    misconfiguredProducts,
    trackProductConfigurationSelection,
  } = useProductConfigurationContext();
  const hasError = !!misconfiguredProducts[accessory.slug];

  const handleRadioChange = (slug: string) => {
    const variant = accessory.attributeSlugToVariant[slug];
    setProductConfigurations({
      accessorySlug: accessory.slug,
      productSlug: accessory.slug,
      variant,
    });
    trackProductConfigurationSelection(accessory, variant, true);
  };

  return (
    <Grid
      columnCount={3}
      gap={spacing[8]}
      data-test-id="product-configuartion-radio-group"
    >
      {accessory.attributes[0].options.map(({ name, slug }, i) => {
        const selectedVariant =
          productConfigurations[accessory.slug]?.[accessory.slug]?.configurations[0]
            .option;

        return (
          <StyledRadioBox
            data-test-id={`${accessory.slug}-${i}`}
            key={slug}
            hasError={hasError}
            errorMessage={hasError && 'error'}
            name="select all"
            theme="light"
            labelText={name}
            checked={selectedVariant === slug}
            onChange={() => handleRadioChange(slug)}
          />
        );
      })}
      {hasError && (
        <ErrorMsgContainer
          aria-live="polite"
          data-test-id="product-configuartion-radio-group-error-box"
        >
          <ErrorMsg id={'select-group-error'}>
            {`${selectMicroCopy} ${accessory.occurrence}`}
          </ErrorMsg>
        </ErrorMsgContainer>
      )}
    </Grid>
  );
};

export default SingleSelectRadioGroup;
