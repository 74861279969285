import React from 'react';
import type { TypeComponentForm } from '@page-builder/lib/types';
import FormTreatmentMap from './maps';

const Forms: React.FC<React.PropsWithChildren<TypeComponentForm>> = ({ fields, sys }) => {
  const { treatment } = fields;
  const Component = FormTreatmentMap[treatment];

  if (!Component) {
    console.warn(`${treatment} can not be handled`);
    return null;
  }

  const contentTypeId = sys.contentType.sys.id;

  return <Component key={`${contentTypeId}-${treatment}`} {...fields} />;
};

export default Forms;
