import { useEffect, useState } from 'react';
import {
  generateTeamDeepLink,
  generateChallengeDeepLink,
} from '@peloton/apps-flyer/social-prospects';
import { useQueryParams } from '@peloton/hooks';
import { fetchInvitation } from '../api/api';
import { TEAM_SHARING_PARAM, CHALLENGE_SHARING_PARAM } from '../constants';
import type { InviteDataResponse } from '../models/personalizedData.types';

interface SocialProspectHook {
  inviteData: InviteDataResponse | undefined;
  isSocialProspectsExperience: boolean;
  source: string | undefined;
  inviteError: string | undefined;
  invitationId: string | undefined;
}

export const generateSocialDeepLink = (
  source: string | undefined,
  inviteData: InviteDataResponse | undefined,
  teamUrlPath: string,
  appleRedemptionCodeUrl: string,
  invitationId: string | undefined,
  logoUrl?: string,
) => {
  if (!inviteData || !source || !invitationId) {
    return '';
  }
  const { teamId, name } = inviteData.teamInfo;
  const { username, imageUrl } = inviteData.invitedBy;
  const { challengeId, name: challengeName } = inviteData.challengeInfo || {};

  const sharedDeepLinkProps = {
    teamId: teamId,
    teamName: name,
    inviterName: username,
    inviterImageUrl: imageUrl,
    teamsLink: teamUrlPath,
    appleRedemptionCodeUrl,
    logoUrl,
    inviteId: invitationId,
  };

  let deepLink;
  switch (source) {
    case CHALLENGE_SHARING_PARAM:
      deepLink = generateChallengeDeepLink({
        ...sharedDeepLinkProps,
        challengeId: challengeId as string,
        challengeName: challengeName as string,
      });
      break;
    case TEAM_SHARING_PARAM:
      deepLink = generateTeamDeepLink(sharedDeepLinkProps);
      break;
    default:
      deepLink = null;
      break;
  }
  return deepLink;
};

export const useSocialProspects = (toggleValue: boolean): SocialProspectHook => {
  const [inviteData, setInviteData] = useState<InviteDataResponse | undefined>(undefined);
  const [inviteError, setInviteError] = useState<string | undefined>(undefined);

  const queryParams = useQueryParams();
  const source = queryParams?.source as string;
  const invitationId = queryParams?.invite as string;

  const isSocialProspectsExperienceEnabled = toggleValue;

  useEffect(() => {
    const fetchData = async () => {
      if (isSocialProspectsExperienceEnabled) {
        if (!invitationId) {
          return;
        }
        try {
          const response = await fetchInvitation(invitationId);
          setInviteData(response);
        } catch (error) {
          setInviteError(error);
          console.error('useSocialProspect: Error fetching invitation data', error);
        }
      }
    };
    fetchData();
  }, [invitationId, isSocialProspectsExperienceEnabled]);

  const isSocialProspectsExperience =
    (source === TEAM_SHARING_PARAM || source === CHALLENGE_SHARING_PARAM) &&
    !!invitationId &&
    isSocialProspectsExperienceEnabled;

  return {
    inviteData,
    isSocialProspectsExperience,
    source,
    inviteError,
    invitationId,
  };
};
