import {
  Container,
  Eyebrow,
  Flex,
  Label,
  black,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { SCORE_WHEELS } from './constants';

type ScoreWheelProps = {
  score: number;
  theme: TypeComponentReviewsFields['theme'];
  title: string;
  subtitle: string;
  maxScore?: number;
};

const { height: svgHeight, width: svgWidth } = SCORE_WHEELS.svgContainer;
const { circumference, radius, strokeWidth, maximumScore, minimumScore } = SCORE_WHEELS;

const ScoreWheel: React.FC<ScoreWheelProps> = ({
  score,
  theme,
  title,
  subtitle,
  maxScore = maximumScore,
}) => {
  if (score < minimumScore) {
    return null;
  }

  const strokeDashoffset = ((maxScore - score) / maxScore) * circumference;

  const circleBackgroundColor = theme === 'Black' ? grey[75] : grey[40];
  const circleProgressColor = theme === 'Black' ? white : black;

  return (
    <Flex gap={spacing[8]}>
      <Container position="relative" width={`${svgWidth}px`} height={`${svgHeight}px`}>
        <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
          <circle
            stroke={circleBackgroundColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            r={radius}
            cx={svgWidth / 2}
            cy={svgHeight / 2}
          />
          <circle
            stroke={circleProgressColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={strokeDashoffset}
            r={radius}
            cx={svgWidth / 2}
            cy={svgHeight / 2}
            style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
          />
        </svg>
        <StyledLabel size="medium" textColor={circleProgressColor}>
          {score.toFixed(1)}
        </StyledLabel>
      </Container>

      <Flex flexDirection="column" justifyContent="center">
        <Eyebrow size="extraSmall" textColor={circleProgressColor}>
          {title}
        </Eyebrow>
        <Label size="small" textColor={circleProgressColor}>
          {subtitle}
        </Label>
      </Flex>
    </Flex>
  );
};

const StyledLabel = styled(Label)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default ScoreWheel;
