import type React from 'react';
import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { track } from '@peloton/analytics/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';

type videoStatusProperties = {
  length: number | null;
  currentTime: number | null;
};

export enum VideoEvent {
  STARTED = 'Video Content Started',
  CLOSED = 'Video Content Closed',
  PLAYED = 'Video Content Played',
  PAUSED = 'Video Content Paused',
  COMPLETED = 'Video Content Completed',
}

interface VideoStatus {
  duration?: number;
  currentTime: number;
}

export const toVideoStatusProperties = (
  videoStatus?: VideoStatus,
): videoStatusProperties => {
  if (videoStatus) {
    return {
      length: videoStatus.duration ? Math.ceil(videoStatus.duration) : null,
      currentTime: Math.ceil(videoStatus.currentTime),
    };
  }
  return { length: null, currentTime: null };
};

export const trackVideo = (
  event: VideoEvent,
  properties: object,
  videoStatus?: VideoStatus,
) => {
  const videoStatusProperties = toVideoStatusProperties(videoStatus);
  track({
    event,
    properties: {
      page: getCurrentRelativePathname(),
      ...videoStatusProperties,
      ...properties,
    },
  });
};

export const useTrackVideo = (event: VideoEvent, properties: Record<string, any>) => {
  const { trackEvent } = useTracking();

  return useCallback(
    (e?: React.SyntheticEvent<HTMLMediaElement>) => {
      const video = e?.target as HTMLMediaElement | undefined;

      const videoStatusProperties = toVideoStatusProperties(video);

      if (video?.ended && event === VideoEvent.PAUSED) {
        return;
      }

      trackEvent({
        event,
        properties: {
          ...videoStatusProperties,
          ...properties,
        },
      });
    },
    [trackEvent, event, properties],
  );
};
