import { grey, white, Eyebrow, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { FreeClass } from '@peloton/models/PelotonClass';
import { useMicroCopy } from '@content/client/microCopy';
import FreeClassCardDescription from './FreeClassCardDescription';
import FreeClassCardImage from './FreeClassCardImage';

type Props = {
  freeClass: FreeClass;
};

const FreeClassCard: React.FC<React.PropsWithChildren<Props>> = ({ freeClass }) => {
  const freeClassBadge = useMicroCopy('freeClassBadge');
  const freeClassStream = useMicroCopy('freeClassStream');
  const { imageUrl, h1, fitnessDisciplineDisplayName } = freeClass;

  return (
    <CtaWrapper>
      <StyledEyebrow size="small">{freeClassStream}</StyledEyebrow>
      <Container>
        <FreeClassCardImage image={imageUrl} />
        <FreeClassCardDescription
          badgeLabel={freeClassBadge}
          title={h1}
          discipline={fitnessDisciplineDisplayName}
        />
      </Container>
    </CtaWrapper>
  );
};

export default FreeClassCard;

const CtaWrapper = styled.div``;

const StyledEyebrow = styled(Eyebrow)`
  color: ${white};
  margin-bottom: ${spacing[16]};
`;

const Container = styled.div`
  background-color: ${grey[80]};
  display: flex;
  align-items: center;
  height: 100%;
  min-height: auto;
`;
