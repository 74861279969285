import { Flex, Display, Label, spacing, Stars } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { useMicroCopy } from '@content/client/microCopy';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { themes } from '../themes';

type AIRatingAndStarssProps = {
  averageRating: number;
  theme: TypeComponentReviewsFields['theme'];
  totalReviews: number;
  setActiveRating: (rating: number | undefined) => void;
  setIsReviewsModalOpen: () => void;
  trackStatsClick: (text: string) => void;
};

const RatingAndStars: React.FC<AIRatingAndStarssProps> = ({
  averageRating,
  theme,
  totalReviews,
  setActiveRating,
  setIsReviewsModalOpen,
  trackStatsClick,
}) => {
  const toFormattedText = useGetTextFormatter();
  const reviewsMicrocopy = useMicroCopy('reviewsCount');
  const reviewsCountText = toFormattedText(reviewsMicrocopy, {
    count: totalReviews.toLocaleString(),
  });

  const { starsColor, textColor } = themes[theme];

  const onClick = (text: string) => {
    setActiveRating(undefined);
    setIsReviewsModalOpen();
    trackStatsClick(text);
  };

  return (
    <Flex gap={spacing[12]}>
      <Display size="xl" textColor={textColor}>
        {averageRating.toFixed(1)}
      </Display>
      <Flex flexDirection="column" gap={spacing[4]} justifyContent="center">
        <Stars rating={averageRating} starSize={16} theme={starsColor} />
        <button
          type="button"
          onClick={() => onClick(reviewsCountText as string)}
          data-test-id="total-reviews-modal-button"
        >
          <StyledLabel size="large" textColor={textColor}>
            {reviewsCountText}
          </StyledLabel>
        </button>
      </Flex>
    </Flex>
  );
};

export default RatingAndStars;

const StyledLabel = styled(Label)`
  text-decoration: underline;
  text-underline-offset: 3px;
`;
