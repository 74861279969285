import type { TypeComponentImageFields } from '@pelotoncycle/page-builder';

export type ProductRecommendationsCopyKey =
  | 'addedToCart'
  | 'inYourCart'
  | 'quickAdd'
  | 'deliveryAndSetup'
  | 'loadingHeadline'
  | 'delivery'
  | 'freeReturns'
  | 'addToCart'
  | 'factoidHeading'
  | 'productDetails'
  | 'defaultError'
  | 'selectAttribute'
  | 'isRequired'
  | 'dropdownOptionSelected';

export type ProductRecommendations = {
  copy: Record<ProductRecommendationsCopyKey, string>;
  cohorts: Record<string, ProductRecommendationsCohort>;
};

export type ProductRecommendationsCohort = {
  cohortKeyName: string;
  headline?: string;
  subHeadline?: string;
  eyebrow?: string;
  position: 'top' | 'bottom';
  products: ProductRecommendationsTile[];
  marketingTiles: ProductRecommendationsMarketingTile[];
};

export const PillThemes = ['dark', 'primary', 'accent'] as const;

export type ProductRecommendationsPillTheme = typeof PillThemes[number];

export type ProductRecommendationsTile = {
  slug: string;
  factoid?: string;
  factoidIcon?: {
    url: string;
    title: string;
  };
  pill?: string;
  pillTheme?: ProductRecommendationsPillTheme;
  priceDisplay?: string;
  factoidEyebrow?: string;
  financingText?: string;
  overrideImageUrl?: string;
  overrideName?: string;
};

export type ProductRecommendationsMarketingTile = {
  name: string;
  url: string;
  image: TypeComponentImageFields;
  title: string;
};
