import { Eyebrow, Flex, Label, spacing, white } from '@pelotoncycle/design-system';
import { GuestPass } from '@prospects/guest-pass/components/GuestPass/GuestPass';
import type { GuestPassInfo } from '@prospects/guest-pass/hooks/useGuestPassInfo';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import { generateMembersClassUrl } from '@peloton/members-urls/membersUrlUtils';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useLoginUrl, useNextLoginUrl } from '@ecomm/oauth/OAuthProvider';
import PageContext from '@page-builder/layout/context/PageContext';
import type { LogInSectionData } from '@page-builder/modules/ClassPage/utils/getLogInSectionData';
import type { GenericCtaData } from '@page-builder/utils/helpers/cta';

import { MembershipSectionTooltip } from './MembershipSectionTooltip';

import {
  StyledSectionHeader,
  StyledSectionBody,
  StyledActions,
  StyledMobilePrimaryAction,
  StyledLink,
} from './styles';

type Props = {
  headbandTitle?: string | null;
  guestPass: GuestPassInfo | null;
  membershipSectionCTAs: GenericCtaData[];
  primaryCta: GenericCtaData;
  className?: string;
  discipline?: string;
  classId: string;
  logInSection?: LogInSectionData;
};

export const MembershipSection: React.FC<React.PropsWithChildren<Props>> = ({
  headbandTitle,
  guestPass,
  membershipSectionCTAs,
  primaryCta,
  className,
  classId,
  discipline,
  logInSection,
}) => {
  const { trackEvent } = useTracking();
  const { asPath } = useRouter();
  const envType = process.env.APP_CONFIG_PRESET;
  const shouldUseProductionEnv = envType === undefined || envType === 'prod';
  const membersBaseUrl = useMicroCopy('membersBaseUrl');
  const membersStageUrl = useMicroCopy('membersStageUrl');
  const membersRedirectUrl = shouldUseProductionEnv ? membersBaseUrl : membersStageUrl;
  const membersClassUrl = generateMembersClassUrl(
    membersRedirectUrl,
    classId,
    discipline?.toLocaleLowerCase(),
  );
  const legacyLoginUrl = useLoginUrl()(membersClassUrl);
  const nextLoginUrl = useNextLoginUrl()(membersClassUrl);
  const isToggleActive = useIsToggleActive();
  const isNextAuthLoginActive = isToggleActive('nextAuthLogin');
  const isGuestPassEnabled =
    useIsToggleActive()('isProspectsGuestPassEnabled') && guestPass !== null;
  const [loginUrl, setLoginUrl] = useState('');

  useEffect(() => {
    setLoginUrl(isNextAuthLoginActive ? nextLoginUrl : legacyLoginUrl);
  }, [isNextAuthLoginActive, legacyLoginUrl, nextLoginUrl]);

  const dispatchPrimaryTrackingEvent = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: 'Prospect Classes Page',
        href: primaryCta.url,
        clickedFrom: asPath,
        linkName: primaryCta.text,
        unitName: primaryCta.name,
        linkTo: primaryCta.url,
        parentType: 'Page',
      },
    });
  };

  return (
    <>
      <StyledSectionHeader>
        {/* Desktop primary CTA */}
        {primaryCta && (
          <Flex justifyContent="space-between" alignItems="center">
            {isGuestPassEnabled ? (
              <GuestPass guestPass={guestPass!} guestPassLabel={headbandTitle!} />
            ) : (
              <Eyebrow size="large" textColor={white}>
                {headbandTitle}
              </Eyebrow>
            )}
            <Flex justifyContent="space-between" gap={spacing[16]} alignItems="center">
              {logInSection && (
                <div>
                  <Label size="medium" textColor="white">
                    {logInSection.questionText}&nbsp;
                  </Label>
                  <LogInLink linkText={logInSection.linkLabel ?? ''} href={loginUrl} />
                </div>
              )}
              <LinkButton
                width="adjustable"
                color="primary"
                size="medium"
                data-test-id={primaryCta.name}
                href={primaryCta.url}
                text={primaryCta.text}
                onClick={dispatchPrimaryTrackingEvent}
              />
            </Flex>
          </Flex>
        )}
      </StyledSectionHeader>
      <StyledSectionBody>
        {/* Actions (with tooltips) */}
        <StyledActions>
          <Flex
            flexDirection="row"
            justifyContent={{ mobile: 'flex-start', tablet: 'space-between' }}
            gap={{ mobile: spacing[8], tablet: spacing[16] }}
          >
            {membershipSectionCTAs &&
              membershipSectionCTAs.map((ctaData: GenericCtaData) => (
                <MembershipSectionTooltip
                  key={ctaData.name}
                  tooltipData={ctaData}
                  className={className}
                  classId={classId}
                  discipline={discipline}
                  loginUrl={loginUrl}
                />
              ))}
          </Flex>
        </StyledActions>

        {/* Mobile/tablet primary CTA */}
        {primaryCta && (
          <StyledMobilePrimaryAction>
            {isGuestPassEnabled && (
              <GuestPass guestPass={guestPass!} guestPassLabel={headbandTitle!} />
            )}
            <Flex flexDirection="column" gap={spacing[16]} alignItems="center">
              <LinkButton
                width="adaptive"
                color="primary"
                size="medium"
                data-test-id={primaryCta.name}
                href={primaryCta.url}
                text={primaryCta.text}
                onClick={dispatchPrimaryTrackingEvent}
              />
              {logInSection && (
                <div style={{ width: '100%' }}>
                  <Label size="medium" textColor="white">
                    {logInSection.questionText}&nbsp;
                  </Label>
                  <LogInLink linkText={logInSection.linkLabel ?? ''} href={loginUrl} />
                </div>
              )}
            </Flex>
          </StyledMobilePrimaryAction>
        )}
      </StyledSectionBody>
    </>
  );
};

const LogInLink: React.FC<
  React.PropsWithChildren<{ linkText: string; href: string }>
> = ({ linkText, href }) => {
  const { asPath } = useRouter();
  const { trackEvent } = useTracking();
  const { additionalTrackingData } = useContext(PageContext);
  const {
    isLoggedIn,
    hasDeviceSubscription,
    hasDigitalSubscription,
  } = additionalTrackingData;

  const onClickDecorated = () => {
    trackEvent({
      event: 'Clicked Login',
      properties: {
        page: asPath,
        isLoggedIn,
        hasDeviceSubscription,
        hasDigitalSubscription,
        parent: 'Prospect Classes Page',
        linkName: linkText,
        unitName: 'Log in: CTA',
        parentType: 'Page',
      },
    });
  };

  return (
    <StyledLink
      data-test-id="card-log-in"
      href={href}
      to={href}
      variant="body"
      size="small"
      onClick={onClickDecorated}
    >
      {linkText}
    </StyledLink>
  );
};
