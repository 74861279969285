import type { TypeBundlePromo } from '@pelotoncycle/page-builder';
import React from 'react';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import {
  CommercetoolsPromoPrice,
  PromoPriceDisplay,
} from '../Promo/PromoPrice/PromoPrice';
import DisplayPrice, { PriceDisplay } from './DisplayPrice';

type Props = {
  specialOffer: TypeBundlePromo;
  bundleType: BundleType;
  productSlug?: string;
};

const DisplayPriceForBundlePromo: React.FC<React.PropsWithChildren<Props>> = ({
  bundleType,
  specialOffer,
  productSlug,
}) => {
  const financingValues = useFinancingNext(bundleType);
  const discount = specialOffer?.fields.couponDiscount;

  if (productSlug) {
    return (
      <PriceDisplay>
        <CommercetoolsPromoPrice
          bundleSlug={productSlug}
          cfu={bundleType}
          bundlePromo={specialOffer}
        />
      </PriceDisplay>
    );
  }

  if (discount) {
    return (
      <PriceDisplay>
        <PromoPriceDisplay price={financingValues.total} discount={discount} />
      </PriceDisplay>
    );
  }

  return <DisplayPrice bundleType={bundleType} displayValue="total" />;
};

export default DisplayPriceForBundlePromo;
