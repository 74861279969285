import React from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import styled from 'styled-components';

type Props = {
  position: {
    lat: number;
    lng: number;
  };
  icon: any;
  isOpen: boolean;
  clickable?: boolean;
  children?: React.ReactNode;
};

type State = {
  isOpen: boolean;
};

class MapMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    const { isOpen } = this.props;
    this.setState({ isOpen });
  }

  public onToggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  public render() {
    const { position, icon, children, clickable = true } = this.props;

    const { isOpen } = this.state;

    return (
      // @ts-expect-error (TODO:robinojw) react-google-maps types do not include implicit children for React 18. This is a known issue.
      <Marker
        position={position}
        icon={icon}
        clickable={clickable}
        onClick={this.onToggle}
      >
        {isOpen && (
          // @ts-expect-error (TODO:robinojw) react-google-maps types do not include implicit children for React 18. This is a known issue.
          <InfoWindow onCloseClick={this.onToggle}>
            <StyledInfoContainer>{children}</StyledInfoContainer>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default MapMarker;

const StyledInfoContainer = styled.div`
  padding: 8px 0 0 8px;
`;
