import type { TypeComponent_overviewFields } from '@pelotoncycle/page-builder';
import { createContext, useContext } from 'react';

export type ShopDrawer = NonNullable<TypeComponent_overviewFields['shopDrawers']>[number];

export type DrawerSelectionContextType = {
  visibleShopDrawers?: ShopDrawer[];
  availableShopDrawers?: ShopDrawer[];
  activeShopDrawer?: ShopDrawer;
  setActiveShopDrawer: (drawer: ShopDrawer) => void;
  hasDynamicDrawers: boolean;
  dynamicDrawersExpanded: boolean;
  expandDynamicDrawers: () => void;
};

export const DrawerSelectionContext = createContext<DrawerSelectionContextType>({
  setActiveShopDrawer: () => {},
  hasDynamicDrawers: false,
  dynamicDrawersExpanded: false,
  expandDynamicDrawers: () => {},
});

export const useDrawerSelectionContext = () => useContext(DrawerSelectionContext);
