import React from 'react';
import styled, { css } from 'styled-components';

type Ratio = '16:9';

type Props = {
  ratio: Ratio;
};

const VideoContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => <Wrapper {...props}>{children}</Wrapper>;

export default VideoContainer;

const ratioToPct = (ratio: Ratio) => {
  if (ratio === '16:9') {
    return '56.25%';
  } else {
    return '';
  }
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 0px;
  height: 0;
  ${({ ratio }: Props) =>
    ratio &&
    css`
      padding-bottom: ${ratioToPct(ratio)};
    `}
  iframe,
  object,
  video,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
