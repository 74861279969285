import type { TypeComponentVideo, TypeTrack } from '@page-builder/lib/types';

export type LightboxVideoProps = {
  name: string;
  label: string;
  url: string;
  tracks: {
    src: string;
    kind: string;
  }[];
  campaignName: string;
};

export const toLightboxVideoProps = (
  lightbox: TypeComponentVideo | undefined,
): LightboxVideoProps => ({
  name: lightbox?.fields?.name || '',
  label: lightbox?.fields?.video?.fields?.title || '',
  url: lightbox?.fields?.video?.fields?.file?.url || '',
  tracks:
    (lightbox?.fields?.tracks &&
      lightbox?.fields?.tracks.map(({ fields }) => ({
        src: fields?.src?.fields?.file?.url,
        kind: fields?.kind,
      }))) ||
    [],
  campaignName: lightbox?.fields?.campaignName || '',
});

export const toLightboxTracks = (tracks: TypeTrack[]) =>
  tracks.map(item => ({
    src: item.fields?.src?.fields?.file?.url,
    kind: item.fields?.kind,
  }));
