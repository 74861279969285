import type { BadgeProps } from '@pelotoncycle/design-system';
import { Badge as Pill } from '@pelotoncycle/design-system';
import React from 'react';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import type { PromoProps, CfuPromoProps, CfuAndBundlePromoProps } from '../types';

type ThemeProps = { theme?: BadgeProps['theme'] };

type PromoPillProps = ThemeProps & PromoProps;

const PromoPill: React.FC<React.PropsWithChildren<PromoPillProps>> = ({
  cfu,
  bundleSlug,
  theme,
}) => {
  if (cfu && !bundleSlug) {
    return <CfuPromoPill cfu={cfu} theme={theme} />;
  }

  if (cfu && bundleSlug) {
    return <CfuAndBundlePromoPill cfu={cfu} bundleSlug={bundleSlug} theme={theme} />;
  }

  return null;
};

export default PromoPill;

const CfuPromoPill: React.FC<React.PropsWithChildren<CfuPromoProps & ThemeProps>> = ({
  cfu,
  theme,
}) => {
  const { data } = useCfuPromo(cfu);
  const pillMessaging = data?.fields.pillMessaging;
  if (!pillMessaging) return null;
  return <PillComponent text={pillMessaging} theme={theme} />;
};

const CfuAndBundlePromoPill: React.FC<
  React.PropsWithChildren<CfuAndBundlePromoProps & ThemeProps>
> = ({ cfu, bundleSlug, theme }) => {
  const { data } = useBundlePromo(cfu, bundleSlug);
  const pillMessaging = data?.fields.pillMessaging;
  if (!pillMessaging) return null;
  return <PillComponent text={pillMessaging} theme={theme} />;
};

const PillComponent: React.FC<React.PropsWithChildren<{ text: string } & ThemeProps>> = ({
  text,
  theme = 'accent',
}) => (
  <Pill variant="horizontal" theme={theme}>
    {text}
  </Pill>
);
