import {
  brand,
  Container,
  grey,
  Headline,
  spacing,
  Flex,
  Support,
  Accordion,
  Body,
  Label,
} from '@pelotoncycle/design-system';
import type { MarkdownCellTableSync } from 'parse-markdown-table';
import { createMarkdownArrayTableSync } from 'parse-markdown-table';
import React from 'react';
import styled from 'styled-components';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import useMarkdownValues from '@page-builder/hooks/useMarkdownValues';
import Markdown from '@page-builder/utils/Markdown';

/**
 * Returns a list of content that will be passed into the values props for the Accordion component to render in mobile view.
 * @param bodyArray - the markdown table formatted into an array that will be parsed into the accordion content format.
 */
const toAccordionValues = (bodyArray: MarkdownCellTableSync) => {
  const { headers, rows } = bodyArray;
  const accordionContent = [];

  for (const row of rows) {
    const sectionTitle = row[0];
    // Remove the first element in rows and headers because that is the content / buffer for the section title.
    const options = headers.slice(1);
    const optionsInfo = row.slice(1);

    accordionContent.push({
      title: (
        <Label size="large" weight="medium" textColor={grey[90]}>
          {sectionTitle}
        </Label>
      ),
      content: (
        <div>
          {options.map((option, optionIndex) => {
            const optionInfo = optionsInfo[optionIndex];
            const isLast = optionIndex === options.length - 1;
            return (
              <Flex
                key={option}
                horizontalPadding={spacing[16]}
                verticalPadding={`0 ${spacing[24]}`}
                // This needs a negative margin to account for the bottom padding on the accordion contents
                verticalMargin={`0 -${isLast ? spacing[12] : 0}}`}
                gap={spacing[8]}
                flexDirection="column"
                backgroundColor={grey[30]}
              >
                <Label size="medium">
                  <Markdown content={option} />
                </Label>
                <Container>
                  <Support size="small" is="span">
                    <Markdown
                      content={optionInfo}
                      markdown={{
                        renderers: {
                          strong: LargeText,
                          emphasis: RedText,
                        },
                      }}
                    />
                  </Support>
                </Container>
              </Flex>
            );
          })}
        </div>
      ),
    });
  }

  return accordionContent;
};

type Props = {
  eyebrow: string;
  body: string;
};

const CompareAccordion: React.FC<React.PropsWithChildren<Props>> = ({
  eyebrow,
  body,
}) => {
  /* We want to share copy between the accordion (mobile view) and table (desktop view).
In order to do this, we are using createMarkdownArrayTableSync which turns the markdown
table into an array that allows us to parse easily into the accordion content format.*/
  const formatValues = useMarkdownValues();
  const formattedBody = useFormattedText(body, formatValues).toString();
  const bodyArray = createMarkdownArrayTableSync(formattedBody);

  return (
    <Container
      verticalPadding={{
        mobile: spacing[40],
      }}
      horizontalPadding={{
        mobile: spacing[16],
      }}
    >
      <StyledAccordion
        theme={'light'}
        title={
          <Headline size="small" textColor={grey[90]}>
            {eyebrow}
          </Headline>
        }
        values={toAccordionValues(bodyArray)}
        multiple={true}
        narrow
      />
    </Container>
  );
};

const StyledAccordion = styled(Accordion)`
  color: ${grey[90]};

  button {
    padding: ${spacing[24]} ${spacing[16]};

    &[aria-expanded='true'] {
      background: ${grey[30]};
    }
  }

  path {
    fill: ${grey[90]};
  }
`;

const LargeText = styled(Body).attrs({ size: 'large', is: 'span' })`
  margin-bottom: ${spacing[4]};
`;

const RedText = styled(Body).attrs({ size: 'large', is: 'span' })`
  color: ${brand.primary};
`;

export default CompareAccordion;
