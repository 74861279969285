import type {
  TypeComponentImage,
  TypeComponentVideo,
  TypeComponentMedia,
  TypeComponentExternalLink,
  TypeComponent_wwwLink,
  TypePageConfig,
  TypeComponentJsonFields,
  TypeProduct,
} from '@page-builder/lib/types';
import {
  imageFieldsToMediaImageProps,
  videoFieldsToMediaVideoProps,
  toMediaProps,
  toProductProps,
} from '@page-builder/utils/helpers/reference';
import type { JSONReference } from '../../../../apps/freeform/types/utilityTypes';

export type ReferenceContentTypes =
  | TypeComponentImage
  | TypeComponentVideo
  | TypeComponentMedia
  | TypeComponentExternalLink
  | TypeComponent_wwwLink
  | TypePageConfig
  | TypeProduct;

type ParentMetadata = {
  sysId: string;
  component: string;
};

export const truncateReference = (reference: ReferenceContentTypes) => {
  if (reference.sys.contentType.sys.id === 'componentImage') {
    return imageFieldsToMediaImageProps((reference as TypeComponentImage).fields);
  }

  if (reference.sys.contentType.sys.id === 'componentVideo') {
    return videoFieldsToMediaVideoProps((reference as TypeComponentVideo).fields);
  }

  if (reference.sys.contentType.sys.id === 'componentMedia') {
    return toMediaProps(reference as TypeComponentMedia);
  }

  if (reference.sys.contentType.sys.id === 'componentExternalLink') {
    return { url: (reference as TypeComponentExternalLink).fields.url };
  }

  if (reference.sys.contentType.sys.id === 'component_wwwLink') {
    return { url: (reference as TypeComponent_wwwLink).fields.url };
  }

  if (reference.sys.contentType.sys.id === 'pageConfig') {
    return (reference as TypePageConfig).fields.config;
  }

  if (reference.sys.contentType.sys.id === 'product') {
    return toProductProps((reference as TypeProduct).fields);
  }
};

const isReference = (ref: Record<string, any>): ref is JSONReference<'Entry'> =>
  ref.id && ref.referenceType === 'Entry';

export const parseWithReferences = (
  referenceMap: Map<string, any>,
  metadata?: ParentMetadata,
) => {
  const parsePayload = (
    payloadValue: TypeComponentJsonFields['payload'],
    trace: string[] = [],
  ): any => {
    if (Array.isArray(payloadValue)) {
      return payloadValue.map((payload, index) =>
        parsePayload(payload, [...trace, `@index ${index}`]),
      );
    }
    if (typeof payloadValue === 'object') {
      if (isReference(payloadValue)) {
        // Reference entry (ReferenceContentTypes - link, media, etc.) was not saved correctly in the "References" field
        if (!referenceMap.has(payloadValue.id)) {
          console.log('Trace: ', trace.join(' > '));

          console.log(
            `${metadata?.component} JSON entry https://app.contentful.com/spaces/7vk8puwnesgc/entries/${metadata?.sysId} is missing https://app.contentful.com/spaces/7vk8puwnesgc/entries/${payloadValue.id} in the "References" field`,
          );

          throw new Error(
            `Reference ${payloadValue.id} not found in referenceMap - Make sure that entry ${payloadValue.id} is added to the "References" field in ${metadata?.sysId} in Contentful`,
          );
        }
        return referenceMap.get(payloadValue.id);
      } else {
        return Object.entries(payloadValue).reduce(
          (parsedPayload, [currentKey, currentValue]) => ({
            ...parsedPayload,
            [currentKey]: parsePayload(currentValue, [...trace, currentKey]),
          }),
          {},
        );
      }
    }
    return payloadValue;
  };

  return parsePayload;
};
