import { EquipmentType } from '@ecomm/graphql/types.generated';

const equipmentTypeMap = {
  RENTALBIKE: EquipmentType.Bike,
  RENTALBIKEPLUS: EquipmentType.Bikeplus,
};

const mapRentalEquipmentType = (rawEquipmentType: string) => {
  return equipmentTypeMap[rawEquipmentType] || rawEquipmentType;
};

export default mapRentalEquipmentType;
