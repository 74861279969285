import { spacing, white, Container, Flex, Media } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { ModalGlobalStyles } from '@ecomm/modal/ProductImageModal';
import SectionWrapper from '@ecomm/modal/SectionWrapper';
import setRootElement from '@ecomm/modal/setRootElement';
import type { Props as BaseProps } from '@ecomm/modal/ViewportModalWithHeader/ViewportModalWithHeader';
import ViewportModalWithHeader from '@ecomm/modal/ViewportModalWithHeader/ViewportModalWithHeader';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { SUBNAV_SIZE } from '@page-builder/modules/FeatureCardModal/utils';
import { toMediaProps } from '@page-builder/utils/helpers/media';
import { ButtonCard } from './MediaCard';

type Props = BaseProps & {
  galleryMedia: TypeComponentGenericTextWithMedia[];
  scrollPosition?: number;
};

const GalleryModal: React.FC<React.PropsWithChildren<Props>> = ({
  galleryMedia,
  scrollPosition,
  ...props
}) => {
  const zoomOutOfImageCopy = useMicroCopy('zoomOutOfImage');

  const handleOnClose = () => {
    props.closeHandler && props.closeHandler();
  };

  return (
    <ViewportModalWithHeader {...props}>
      <ModalGlobalStyles />
      <Flex
        padding={spacing[24]}
        backgroundColor={white}
        flexDirection="column"
        gap={spacing[24]}
        minHeight="100vh"
      >
        {galleryMedia.map((mediaItem, index) => {
          const {
            fields: { media, name },
          } = mediaItem;
          const mediaWithCloudinary = media && toMediaProps(media);
          return (
            <SectionWrapper
              key={`sections-${name}`}
              position={index}
              total={galleryMedia.length}
              subnavSize={SUBNAV_SIZE}
            >
              <Container
                key={name}
                width="fit-content"
                margin="0 auto"
                position="relative"
              >
                {mediaWithCloudinary && <StyledMedia media={mediaWithCloudinary} />}
                {/* The below is to ensure that the Media Container can close the modal as a button, while also supporting the pause/play buttons and remaining accessible */}
                <ButtonCard
                  type="button"
                  onClick={handleOnClose}
                  zoomDirection="out"
                  aria-label={zoomOutOfImageCopy}
                />
              </Container>
            </SectionWrapper>
          );
        })}
      </Flex>
    </ViewportModalWithHeader>
  );
};

export default GalleryModal;

const StyledMedia = styled(Media)`
  picture > img,
  div > video {
    max-width: 808px;
    margin: auto;
  }
`;

setRootElement();
