import React, { createContext, useState, useMemo } from 'react';
import type { InViewHookResponse } from 'react-intersection-observer';
import { useInView } from 'react-intersection-observer';

export type InViewRef = InViewHookResponse[0];

export const MONTHLY = 'monthly';
export const ANNUAL = 'annual';

export type CheckoutCta = {
  product?: string;
  text?: string;
  link?: string;
};

type CheckoutContextType = {
  cta: CheckoutCta;
  setCta: (cta: CheckoutCta) => void;
  activeCtaRef: InViewRef;
  shouldShowCTABand: boolean;
};

const emptyContextValue = {
  cta: {},
  setCta: () => {},
  activeCtaRef: () => {},
  shouldShowCTABand: false,
};

export const CheckoutContext = createContext<CheckoutContextType>(emptyContextValue);

export const CheckoutProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [cta, setCta] = useState<CheckoutCta>({});
  const [activeCtaRef, ctaInView] = useInView();

  // The CTA band should show up when the page CTA is not in the view
  const shouldShowCTABand = !ctaInView;

  const value = useMemo(() => {
    return {
      cta,
      setCta,
      activeCtaRef,
      shouldShowCTABand,
    };
  }, [cta, setCta, activeCtaRef, shouldShowCTABand]);

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export const useCheckoutContext = () => React.useContext(CheckoutContext);
