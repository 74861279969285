import type { Options as CloudinaryOptions } from '@peloton/images-ui';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';

type Image = {
  mobile: string;
  tablet?: string;
  desktop?: string;
};

export const toCloudinaryImage = (image: Image, options: CloudinaryOptions): Image => {
  return Object.keys(image).reduce((wrappedImageUrls, key) => {
    return {
      ...wrappedImageUrls,
      [key]: toCloudinarySrc({ src: image[key], ...options }),
    };
  }, {}) as Image;
};
