// THIS IS AN AUTOGENERATED FILE (@ecomm/commercetools/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
import * as Types from '../types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type BundleProductPriceFragmentFragment = { __typename?: 'ProductVariant' } & {
  price?: Types.Maybe<
    { __typename?: 'ProductPrice' } & Pick<Types.ProductPrice, 'id'> & {
        value:
          | ({ __typename?: 'Money' } & Pick<Types.Money, 'currencyCode' | 'centAmount'>)
          | ({ __typename?: 'HighPrecisionMoney' } & Pick<
              Types.HighPrecisionMoney,
              'currencyCode' | 'centAmount'
            >);
        discounted?: Types.Maybe<
          { __typename?: 'DiscountedProductPriceValue' } & {
            value:
              | ({ __typename?: 'Money' } & Pick<
                  Types.Money,
                  'currencyCode' | 'centAmount'
                >)
              | ({ __typename?: 'HighPrecisionMoney' } & Pick<
                  Types.HighPrecisionMoney,
                  'currencyCode' | 'centAmount'
                >);
          }
        >;
      }
  >;
};

export type BundleProductReferencedCatalogFragmentFragment = {
  __typename?: 'ProductVariant';
} & Pick<Types.ProductVariant, 'key' | 'sku'> & {
    images: Array<{ __typename?: 'Image' } & Pick<Types.Image, 'url' | 'label'>>;
    attributesRaw: Array<
      { __typename?: 'RawProductAttribute' } & Pick<
        Types.RawProductAttribute,
        'name' | 'value'
      >
    >;
    availability?: Types.Maybe<
      { __typename?: 'ProductVariantAvailabilityWithChannels' } & {
        channels: { __typename?: 'ProductVariantAvailabilitiesResult' } & {
          results: Array<
            { __typename?: 'ProductVariantAvailabilityWithChannel' } & {
              availability: { __typename?: 'ProductVariantAvailability' } & Pick<
                Types.ProductVariantAvailability,
                'availableQuantity'
              >;
            }
          >;
        };
      }
    >;
  } & BundleProductPriceFragmentFragment;

export type BundleProductReferencedFragmentFragment = { __typename?: 'Product' } & Pick<
  Types.Product,
  'id' | 'key'
> & {
    productType?: Types.Maybe<
      { __typename?: 'ProductTypeDefinition' } & Pick<Types.ProductTypeDefinition, 'key'>
    >;
    masterData: { __typename?: 'ProductCatalogData' } & {
      current?: Types.Maybe<
        { __typename?: 'ProductData' } & Pick<
          Types.ProductData,
          'name' | 'slug' | 'description'
        > & {
            masterVariant: {
              __typename?: 'ProductVariant';
            } & BundleProductReferencedCatalogFragmentFragment;
            variants: Array<
              {
                __typename?: 'ProductVariant';
              } & BundleProductReferencedCatalogFragmentFragment
            >;
          }
      >;
    };
  };

export type SearchBundleByKeyQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.Scalars['String']>;
  acceptLanguage?: Types.Maybe<Array<Types.Scalars['Locale']>>;
  currency: Types.Scalars['Currency'];
  country: Types.Scalars['Country'];
}>;

export type SearchBundleByKeyQuery = { __typename?: 'Query' } & {
  products: { __typename?: 'ProductQueryResult' } & {
    results: Array<
      { __typename?: 'Product' } & Pick<Types.Product, 'id' | 'key'> & {
          productType?: Types.Maybe<
            { __typename?: 'ProductTypeDefinition' } & Pick<
              Types.ProductTypeDefinition,
              'key'
            >
          >;
          masterData: { __typename?: 'ProductCatalogData' } & {
            current?: Types.Maybe<
              { __typename?: 'ProductData' } & Pick<
                Types.ProductData,
                'name' | 'slug' | 'description'
              > & {
                  masterVariant: { __typename?: 'ProductVariant' } & Pick<
                    Types.ProductVariant,
                    'key' | 'sku'
                  > & {
                      attributesRaw: Array<
                        { __typename?: 'RawProductAttribute' } & Pick<
                          Types.RawProductAttribute,
                          'name' | 'value'
                        > & {
                            referencedResourceSet: Array<
                              | { __typename?: 'Cart' }
                              | { __typename?: 'Customer' }
                              | { __typename?: 'TypeDefinition' }
                              | { __typename?: 'CustomerGroup' }
                              | { __typename?: 'Store' }
                              | ({
                                  __typename?: 'Product';
                                } & BundleProductReferencedFragmentFragment)
                              | { __typename?: 'ProductTypeDefinition' }
                              | { __typename?: 'Category' }
                              | { __typename?: 'Channel' }
                              | { __typename?: 'ProductDiscount' }
                              | { __typename?: 'State' }
                              | { __typename?: 'TaxCategory' }
                              | { __typename?: 'CartDiscount' }
                              | { __typename?: 'ShippingMethod' }
                              | { __typename?: 'Zone' }
                              | { __typename?: 'DiscountCode' }
                              | { __typename?: 'BusinessUnit' }
                              | { __typename?: 'AssociateRole' }
                              | { __typename?: 'Order' }
                              | { __typename?: 'ShoppingList' }
                              | { __typename?: 'CustomObject' }
                              | { __typename?: 'InventoryEntry' }
                              | { __typename?: 'Message' }
                              | { __typename?: 'Review' }
                              | { __typename?: 'Extension' }
                            >;
                            referencedResource?: Types.Maybe<
                              | { __typename?: 'Cart' }
                              | { __typename?: 'Customer' }
                              | { __typename?: 'TypeDefinition' }
                              | { __typename?: 'CustomerGroup' }
                              | { __typename?: 'Store' }
                              | ({
                                  __typename?: 'Product';
                                } & BundleProductReferencedFragmentFragment)
                              | { __typename?: 'ProductTypeDefinition' }
                              | { __typename?: 'Category' }
                              | { __typename?: 'Channel' }
                              | { __typename?: 'ProductDiscount' }
                              | { __typename?: 'State' }
                              | { __typename?: 'TaxCategory' }
                              | { __typename?: 'CartDiscount' }
                              | { __typename?: 'ShippingMethod' }
                              | { __typename?: 'Zone' }
                              | { __typename?: 'DiscountCode' }
                              | { __typename?: 'BusinessUnit' }
                              | { __typename?: 'AssociateRole' }
                              | { __typename?: 'Order' }
                              | { __typename?: 'ShoppingList' }
                              | { __typename?: 'CustomObject' }
                              | { __typename?: 'InventoryEntry' }
                              | { __typename?: 'Message' }
                              | { __typename?: 'Review' }
                              | { __typename?: 'Extension' }
                            >;
                          }
                      >;
                      images: Array<
                        { __typename?: 'Image' } & Pick<Types.Image, 'url' | 'label'>
                      >;
                    } & BundleProductPriceFragmentFragment;
                  categories: Array<
                    { __typename?: 'Category' } & Pick<Types.Category, 'id' | 'key'>
                  >;
                }
            >;
          };
        }
    >;
  };
};

export const BundleProductPriceFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyCode' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BundleProductReferencedCatalogFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availability' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availability' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableQuantity' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BundleProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyCode' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BundleProductReferencedFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductReferencedFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productType' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'masterData' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BundleProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BundleProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyCode' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availability' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availability' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableQuantity' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BundleProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
  ],
};
export const SearchBundleByKeyDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchBundleByKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'acceptLanguage' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productType' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterData' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'acceptLanguage' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'acceptLanguage' },
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'acceptLanguage' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'acceptLanguage' },
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'acceptLanguage' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'acceptLanguage' },
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'masterVariant' },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'key' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'sku' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributesRaw' },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'referencedResourceSet',
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'Product',
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'BundleProductReferencedFragment',
                                                            },
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'referencedResource',
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'Product',
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'BundleProductReferencedFragment',
                                                            },
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'BundleProductPriceFragment',
                                          },
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'images' },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'categories' },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'key' },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyCode' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availability' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availability' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableQuantity' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BundleProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BundleProductReferencedFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productType' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'masterData' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BundleProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BundleProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSearchBundleByKeyQuery__
 *
 * To run a query within a React component, call `useSearchBundleByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBundleByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBundleByKeyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      acceptLanguage: // value for 'acceptLanguage'
 *      currency: // value for 'currency'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSearchBundleByKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchBundleByKeyQuery,
    SearchBundleByKeyQueryVariables
  >,
) {
  return Apollo.useQuery<SearchBundleByKeyQuery, SearchBundleByKeyQueryVariables>(
    SearchBundleByKeyDocument,
    baseOptions,
  );
}
export function useSearchBundleByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchBundleByKeyQuery,
    SearchBundleByKeyQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SearchBundleByKeyQuery, SearchBundleByKeyQueryVariables>(
    SearchBundleByKeyDocument,
    baseOptions,
  );
}
export type SearchBundleByKeyQueryHookResult = ReturnType<
  typeof useSearchBundleByKeyQuery
>;
export type SearchBundleByKeyLazyQueryHookResult = ReturnType<
  typeof useSearchBundleByKeyLazyQuery
>;
export type SearchBundleByKeyQueryResult = Apollo.QueryResult<
  SearchBundleByKeyQuery,
  SearchBundleByKeyQueryVariables
>;
