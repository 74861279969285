import type { MediaImageProps } from '@pelotoncycle/design-system';
import {
  BreakpointWidths,
  Eyebrow,
  Flex,
  ResponsiveImage,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';

export type Props = {
  title: string;
  image: MediaImageProps;
  className?: string;
};

const Card: React.FC<React.PropsWithChildren<Props>> = ({ title, image, className }) => {
  const { trackEvent } = useTracking();

  const trackClick = () => {
    trackEvent({
      event: 'Clicked Carousel Slide',
      properties: {
        linkTo: '',
        unitName: title,
        parentType: 'Programming Carousel',
      },
    });
  };

  return (
    <CardWrapper
      key={title}
      className={className}
      onClick={trackClick}
      onKeyDown={trackClick}
    >
      <StyledImage alt={image.alt} mobile={image.mobile} />
      <ImageMask justifyContent="center" alignItems="center">
        <Eyebrow size="large" textColor={white}>
          {title}
        </Eyebrow>
      </ImageMask>
    </CardWrapper>
  );
};

export default Card;

// button for a11y, since we want to send events on click
const CardWrapper = styled.button`
  position: relative;
  margin: 0 8px;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    margin: 0 12px;
  }
`;

const StyledImage = styled(ResponsiveImage)`
  pointer-events: none;

  img {
    height: 100%;
  }
`;

const ImageMask = styled(Flex)`
  width: 100%;
  height: 104px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180.69deg, rgba(0, 0, 0, 0) 6.48%, #000000 99.7%);
  padding: 12px 2rem;
  text-align: center;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    padding: 16px 16px 16px;
  }
`;
