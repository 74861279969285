import React from 'react';
import { useTracking } from 'react-tracking';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import { LinkButton } from '@peloton/next/components/LinkButton';

type LinkProps = {
  children: React.ReactNode;
  href: string;
};

type DestructuredHref = [url: string, target?: '_blank' | '_self'];

const LinkRenderer: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  href,
}) => {
  const [url, target] = href.split('|') as DestructuredHref;
  const { trackEvent } = useTracking();

  const onClick = () => {
    trackEvent({
      event: 'Clicked Link',
      linkTo: url,
    });
  };

  return (
    <>
      {url.endsWith('.pdf') ? (
        <LinkButton
          href={url}
          onClick={onClick}
          target="_blank"
          color="primary"
          text={children as string}
        />
      ) : (
        <a href={url} onClick={onClick} target={target}>
          {children}
        </a>
      )}
    </>
  );
};

export default withI18n(LinkRenderer);
