import { Headline, spacing, Display } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';

type HeaderLabelProps = {
  headline?: string;
};

const HeaderLabel: React.FC<React.PropsWithChildren<HeaderLabelProps>> = props => {
  const { headline } = props;

  return (
    headline && (
      <BreakpointSwitchCSS
        breakpoints={{
          mobile: <StyledDisplay size="small">{headline}</StyledDisplay>,
          desktopLarge: <StyledHeadline size="medium">{headline}</StyledHeadline>,
        }}
      />
    )
  );
};

export default HeaderLabel;

const StyledDisplay = styled(Display)`
  margin-bottom: ${spacing[8]};
  font-weight: 700;
`;

const StyledHeadline = styled(Headline)`
  font-weight: 600;
  margin-bottom: ${spacing[40]};
`;
