import { spacing, Flex } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { toCountry } from '@peloton/internationalize';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import type {
  TypeComponentCta,
  TypeComponentGenericTextWithMedia,
} from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers/cms';
import { isEntryType } from '@page-builder/utils/unions';
import MembershipModal from '../Membership/MembershipModal';
import OverviewModal from '../OverviewModal/OverviewModal';
import { toProductInterest } from '../utils';
import type { ClickEventProps } from './ValueProps';
import ValueProps from './ValueProps';

type Props = {
  valueProps: TypeComponentCta[];
  parentName?: string;
  variant: 'body' | 'primary';
  size: 'small' | 'large';
  product: BundleType;
};

const ValuePropsContainer: React.FC<React.PropsWithChildren<Props>> = ({
  valueProps,
  parentName,
  variant,
  size,
  product,
}) => {
  const { trackEvent } = useTracking();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formattedCtas = toCtaFields(valueProps);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const handleClick = (isModal: boolean, index: number, eventProps: ClickEventProps) => {
    isModal && setIsModalOpen(true);
    isModal && setCurrentModalIndex(index);
    trackEvent({
      event: 'Clicked Link',
      ...eventProps,
    });
  };

  const handleModalOpened = () => {
    setIsModalOpen(true);
    trackEvent({
      event: 'Modal Opened',
      properties: {
        productInterest: toProductInterest(product),
        parentType: 'Component: Overview',
        parent: `Overview ${product} Module`,
        propertyType: 'Web',
        unitName: `Overview ${product} Value Props Container Modal Open`,
        unitPlacement: 'Modal',
        modalTrigger: 'click',
        country: toCountry(),
      },
    });
  };

  const isMembershipModal = () => {
    const possibleModalNames = ['AAM', 'MEMBERSHIP'];
    let modal;
    let modalName: string;

    if (formattedCtas[currentModalIndex]?.modal) {
      modal = formattedCtas[currentModalIndex]?.modal!;
      modalName = modal.fields.name.toUpperCase();
      if (possibleModalNames.some(s => modalName.includes(s))) {
        return true;
      }
    }
    return false;
  };

  const ctaModalData = formattedCtas[currentModalIndex].modal;
  const ctaModal =
    ctaModalData && isEntryType<TypeComponentGenericTextWithMedia>(ctaModalData, 'text')
      ? ctaModalData
      : undefined;

  return (
    <Flex flexDirection="column" gap={spacing[16]} data-test-id="valueProps" as="ul">
      <ValueProps
        formattedCtas={formattedCtas}
        size={size}
        variant={variant}
        parentName={parentName}
        handleClick={handleClick}
      />
      {formattedCtas[currentModalIndex] &&
        isModalOpen &&
        ctaModal &&
        (isMembershipModal() ? (
          <MembershipModal
            modalContent={ctaModal.fields}
            isOpen={isModalOpen}
            openHandler={() => handleModalOpened()}
            closeHandler={() => setIsModalOpen(false)}
          />
        ) : (
          <OverviewModal
            modalContent={ctaModal?.fields}
            isOpen={isModalOpen}
            openHandler={() => handleModalOpened()}
            closeHandler={() => setIsModalOpen(false)}
          />
        ))}
    </Flex>
  );
};

export default ValuePropsContainer;
