import { grey, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { media } from '@peloton/styles';
import { Spinner } from '@ecomm/spinner';
import { MODAL_CLOSE_TRANSITION_TIME } from './ScrollFromBottomModalStyles';
export { MODAL_CLOSE_TRANSITION_TIME } from './ScrollFromBottomModalStyles';

export const SlideUpModalStyles = createGlobalStyle`
    .slide-up-modal-body {
        width: 100%;
        height: 100%;
        max-height: none;
        padding: ${spacing[32]};
        overflow: auto;

        ${media.tablet`
            max-height: calc(100vh - 3.5rem);
        `}

        ${media.desktop`
            max-height: calc(100vh - ${spacing[80]} * 2);
        `}

        ${media.desktopLarge`
            padding: ${spacing[40]};
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content {
        width: 100%;
        height: calc(100% - ${spacing[56]});
        margin: 0 auto;
        position: fixed;
        top: ${spacing[56]};
        transform: translateY(100%);
        opacity: 1;
        border-radius: ${spacing[12]} ${spacing[12]} 0 0;
        color: ${white};
        background-color: ${grey[90]};

        ${media.tablet`
            max-width: calc(100% - ${spacing[32]});
            height: auto;
        `}

        ${media.desktop`
            position: relative;
            transform: translateY(30%);
        `}

        ${media.desktopLarge`
            max-width: ${900 / 16}rem;
            margin: ${spacing[80]} 0 auto;
            border-radius: ${spacing[4]};
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content--before-open {
        transition: transform 500ms ease;
    }

    #prospects-slide-up-modal.ReactModal__Content--after-open {
        transition: transform 500ms ease;
        transform: translateY(0);
    }

    #prospects-slide-up-modal.ReactModal__Content--before-close {
        transform: translateY(30%);
        background-color: rgba(0, 0, 0, 0) !important;
        opacity: 1;
        transition: transform ${MODAL_CLOSE_TRANSITION_TIME}ms ease, opacity ${MODAL_CLOSE_TRANSITION_TIME}ms ease, background-color ${MODAL_CLOSE_TRANSITION_TIME}ms ease;
    }

    #prospects-slide-up-modal.ReactModal__Content--before-open .async-modal-body {
        opacity: 1;

        ${media.desktop`
            transition: opacity ${MODAL_CLOSE_TRANSITION_TIME - 100}ms linear;
            transition-delay: 100ms;
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content--before-close .async-modal-body {
        opacity: 0;

        ${media.desktop`
            transition: opacity ${MODAL_CLOSE_TRANSITION_TIME - 100}ms linear;
            transition-delay: 0;
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content > div:first-of-type {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);

        ${media.desktop`
            transform: translateY(calc(-100% - ${spacing[12]}));
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content > div:first-of-type svg {
        fill: ${white};
        height: ${spacing[24]} !important;
        width: ${spacing[24]} !important;
    }
 
    #prospects-slide-up-modal.ReactModal__Content > div:first-of-type svg path {
        fill: ${white} !important;
    }

    #prospects-slide-up-modal.ReactModal__Content > div:first-of-type button {
        display: flex;
        padding: ${spacing[16]};

        ${media.desktop`
            padding: 0;
            transition: opacity 250ms ease;
            transition-duration: 250ms;
            opacity: 0.8;
        `}
    }

    #prospects-slide-up-modal.ReactModal__Content > div:first-of-type button:hover {
        opacity: 1;
    }
`;

export const ModalLoader: React.FC<React.PropsWithChildren<unknown>> = () => (
  <StyledLoaderContainer>
    <Spinner area={48} fill={white} />
  </StyledLoaderContainer>
);

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;
