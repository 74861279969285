import React from 'react';
import { useLocale } from '@peloton/internationalize';
import { toLanguageFromLocale } from '@peloton/internationalize/models/language';
import { toTime } from '@peloton/time';
import { useMicroCopy } from '@content/client/microCopy';
import type { Language } from '@ecomm/graphql/types.generated';
import type { ReviewableTypes } from '@ecomm/models/DeviceType';
import {
  toFormattedReviewRating,
  toFormattedReviewAuthor,
} from '@ecomm/reviews/data/utils/formattedReviewValues';
import { useReviewWithStatsQuery } from '@ecomm/reviews/data/utils/useReviewWithStatsQuery';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers';
import GenericReviewCards from './GenericReviewCards';
import type { ReviewCardProps } from './ReviewCard';
import {
  orderBy,
  reviewsToDisplay,
  minimalReviewRating,
  defaultReviewQuantities,
} from './utils/apiReviewQueryParams';

type ApiReviewCardProps = Omit<TypeComponentReviewsFields, 'reviews' | 'treatment'>;
const ApiReviewCards: React.FC<React.PropsWithChildren<ApiReviewCardProps>> = ({
  text,
  ctas,
  theme,
  deviceType,
  includeDeviceFamily,
}) => {
  const seeMoreLabel = useMicroCopy('seeMoreLabel');
  const seeLessLabel = useMicroCopy('seeLessLabel');
  const lang = (toLanguageFromLocale(useLocale()) as unknown) as Language;
  const excludeFamily = !includeDeviceFamily;

  const {
    reviewsProps,
    totalReviews,
    statsProps: averageRating,
  } = useReviewWithStatsQuery(
    (deviceType ?? 'bike') as ReviewableTypes,
    orderBy,
    reviewsToDisplay,
    defaultReviewQuantities,
    lang,
    excludeFamily,
    minimalReviewRating,
  );

  if (deviceType === undefined || reviewsProps.length === 0) {
    console.warn(
      `No reviews returned from API ${
        deviceType === undefined && '-- deviceType not specified'
      }`,
    );
    return null;
  }

  const { headline, label } = text?.fields || {};

  const ctaButtons = ctas
    ? toCtaFields(ctas).map(
        cta =>
          cta && {
            href: cta.link?.fields.url,
            text: cta.text,
            color: cta.color,
            variant: cta.variant,
          },
      )
    : [undefined, undefined];

  const totalReviewsText = label ? `${totalReviews} ${label}` : totalReviews.toString();

  const primaryButton = ctaButtons[0];
  const secondaryButton = ctaButtons[1];

  const reviewItems: ReviewCardProps[] = reviewsProps.map(
    ({
      title,
      rating,
      submittedAt,
      authorName,
      authorCity,
      authorState,
      authorLocation,
      reviewText,
      isVerifiedPurchaser,
    }) => ({
      title,
      rating,
      date: toTime(submittedAt).fromNow(),
      heading: toFormattedReviewAuthor({
        authorName,
        city: authorCity,
        state: authorState,
        authorLocation,
      }),
      reviewText,
      verified: isVerifiedPurchaser,
      moreLabel: seeMoreLabel,
      lessLabel: seeLessLabel,
    }),
  );

  return (
    <GenericReviewCards
      title={headline}
      totalReviews={totalReviewsText}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      rating={toFormattedReviewRating(averageRating)}
      reviewItems={reviewItems}
      theme={theme}
      deviceType={deviceType}
    />
  );
};

export default ApiReviewCards;
