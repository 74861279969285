import { Icon, Label } from '@pelotoncycle/design-system';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import AddZipScriptToPage from '@ecomm/financing/zip/AddZipScriptToPage';
import { ZipModalView } from '@ecomm/financing/zip/ZipModalView';
import { toDollars } from '@ecomm/models';
import { usePackageFinancing } from '@page-builder/modules/Overview/utils';
import FinancingCtaContentWrapper from './FinancingCtaContentWrapper';
import type FinancingCtaProps from './FinancingCtaProps';

const ZipCta: React.FC<React.PropsWithChildren<FinancingCtaProps>> = ({
  cta,
  bundleType,
  onClick,
}) => {
  const { ready, total, term, monthly } = usePackageFinancing(bundleType);

  const toFormattedText = useGetTextFormatter();

  const zipModalRef = useRef<null | HTMLDivElement>(null);

  if (!ready) {
    return null;
  }

  const onButtonClick = () => {
    onClick();
    zipModalRef.current?.click();
  };

  return (
    <>
      <ZipModalView price={toDollars(total)} ref={zipModalRef} bundleType={bundleType} />
      <AddZipScriptToPage />
      <ZipButton className="ds__link" onClick={onButtonClick}>
        <FinancingCtaContentWrapper>
          <Label size="medium" display="inline">
            {toFormattedText(cta.text, {
              monthlyTotal: toDollars(monthly),
              term: term,
            })}
          </Label>
          {cta.ctaIcon && <Icon name={cta.ctaIcon} height={12} />}
        </FinancingCtaContentWrapper>
      </ZipButton>
    </>
  );
};

const ZipButton = styled.button`
  &:focus {
    outline: none;
  }
`;

export default ZipCta;
