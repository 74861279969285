import { data } from '@pelotoncycle/design-system';
import React, { createContext } from 'react';

const { teal, lime, watermelon, purple, orange, darkYellow } = data;

export const themes = ['Green', 'Blue', 'Purple', 'Orange'];

type DonutGradient = {
  color: string;
  percentage: string;
};

export type Theme = {
  gradientStart: string;
  gradientEnd: string;
  questionBackground: string;
  navBackground: string;
  disciplineBackground: string;
  donutGradient: string;
  donutStart: DonutGradient;
  donutMiddle?: DonutGradient;
  donutEnd: DonutGradient;
};

export const themeMap: Record<typeof themes[number], Theme> = {
  Green: {
    gradientStart: teal,
    gradientEnd: lime,
    questionBackground: `linear-gradient(180deg, #347F6F 28.33%, ${lime} 86.45%)`,
    navBackground: lime,
    disciplineBackground: `radial-gradient(91.12% 91.12% at 15.76% 20.06%, ${lime} 0%, ${teal} 100%)`,
    donutGradient: `radial-gradient(47.6% 47.6% at 50.16% 50.16%, ${teal} 56.84%, ${lime} 100%)`,
    donutStart: {
      color: teal,
      percentage: '50%',
    },
    donutEnd: {
      color: lime,
      percentage: '80%',
    },
  },
  Blue: {
    gradientStart: teal,
    gradientEnd: '#914EFF',
    questionBackground: `linear-gradient(180deg, ${purple} -21.06%, ${teal} 91.17%)`,
    navBackground: teal,
    disciplineBackground: `radial-gradient(89% 89% at 9% 0%, #855CF8 0%, ${teal} 100%)`,
    donutGradient: `radial-gradient(38.15% 38.15% at 50.2% 50.12%, ${purple} 43.74%, ${teal} 100%)`,
    donutStart: {
      color: purple,
      percentage: '37%',
    },
    donutEnd: {
      color: teal,
      percentage: '84%',
    },
  },
  Purple: {
    gradientStart: watermelon,
    gradientEnd: '#914EFF',
    questionBackground: `linear-gradient(180deg, ${purple} 5.79%, ${watermelon} 79.09%)`,
    navBackground: watermelon,
    disciplineBackground: `radial-gradient(94.5% 94.5% at 0% 5%, #855CF8 0%, #FF3347 85.56%)`,
    donutGradient: `radial-gradient(41.12% 41.12% at 50.16% 50.16%, ${purple} 54.48%, #FF3347 99.32%)`,
    donutStart: {
      color: purple,
      percentage: '50.48%',
    },
    donutEnd: {
      color: '#FF3347',
      percentage: '83.32%',
    },
  },
  Orange: {
    gradientStart: darkYellow,
    gradientEnd: watermelon,
    questionBackground: `linear-gradient(180deg, #CC3947 23.65%, ${orange} 84.24%)`,
    navBackground: orange,
    disciplineBackground: `radial-gradient(116.67% 116.67% at -16.67% 0%, #FF3347 0%, #F19039 60.68%, ${darkYellow} 100%)`,
    donutGradient: `radial-gradient(42.6% 42.6% at 49.79% 49.91%, ${watermelon} 48.43%, #F19039 81.03%, ${darkYellow} 100%)`,
    donutStart: {
      color: watermelon,
      percentage: '48.43%',
    },
    donutMiddle: {
      color: '#F19039',
      percentage: '81.03%',
    },
    donutEnd: {
      color: darkYellow,
      percentage: '100%',
    },
  },
};

export const getOtherTypeBackgrounds = (mainBackground: string, type: string) => {
  return Object.values(themeMap)
    .map(theme => theme[type])
    .filter(background => background !== mainBackground);
};

export const ThemeContext = createContext(themeMap.Orange);

export const toThemeBackgroundImage = (
  theme: Theme,
  xPercent: number = 10,
  yPercent: number = 10,
) => {
  const { gradientStart, gradientEnd } = theme;

  return `radial-gradient(at ${xPercent}% ${yPercent}%, ${gradientStart}, ${gradientEnd})`;
};

export const useThemeGradientAnimation = (
  gradientContainerRef: React.RefObject<HTMLElement>,
) => {
  const theme = React.useContext(ThemeContext);

  React.useEffect(() => {
    let gradientXincrease = true;
    let gradientYincrease = true;
    let gradientXpercentage = 10;
    let gradientYpercentage = 10;

    const moveGradient = () => {
      // x continues in same direction
      if (gradientXincrease) {
        gradientXpercentage++;
      } else {
        gradientXpercentage--;
      }

      // y continues in same direction
      if (gradientYincrease) {
        gradientYpercentage = gradientYpercentage + 2;
      } else {
        gradientYpercentage = gradientYpercentage - 2;
      }

      // window bounds
      if (gradientXpercentage >= 100) {
        gradientXincrease = false;
      } else if (gradientXpercentage <= 0) {
        gradientXincrease = true;
      }

      if (gradientYpercentage >= 100) {
        gradientYincrease = false;
      } else if (gradientYpercentage <= 0) {
        gradientYincrease = true;
      }

      if (gradientContainerRef.current) {
        gradientContainerRef.current.style.backgroundImage = toThemeBackgroundImage(
          theme,
          gradientXpercentage,
          gradientYpercentage,
        );
      }
    };

    const gradientInterval = setInterval(moveGradient, 70);

    return () => {
      clearInterval(gradientInterval);
    };
  }, [theme, gradientContainerRef]);
};

export const ThemeProvider: React.FC<
  React.PropsWithChildren<{ theme?: typeof themes[number] }>
> = ({ theme, children }) => {
  return (
    <ThemeContext.Provider value={themeMap[theme || themes[0]]}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
