// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type UgcReviewsStatsQueryVariables = Types.Exact<{
  equipmentType: Types.ReviewEquipmentTypeUgc;
  lang?: Types.Maybe<Types.LanguageUgc>;
  excludeFamily?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type UgcReviewsStatsQuery = { __typename?: 'Query' } & {
  reviewsByCriteria: { __typename?: 'ReviewsUgc' } & Pick<
    Types.ReviewsUgc,
    'totalCount' | 'averageOverallRating'
  > & {
      includes?: Types.Maybe<
        Array<{ __typename?: 'Includes' } & Pick<Types.Includes, 'RatingValue' | 'Count'>>
      >;
      secondaryRatingAverages: { __typename?: 'SecondaryRatingsAverages' } & Pick<
        Types.SecondaryRatingsAverages,
        'varietyOfInstructors' | 'quality' | 'delivery' | 'easeOfUse' | 'value'
      >;
    };
};

export const UgcReviewsStatsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UGCReviewsStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipmentType' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReviewEquipmentTypeUgc' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LanguageUgc' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeFamily' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewsByCriteria' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipmentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'equipmentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lang' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeFamily' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeFamily' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCount' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageOverallRating' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'includes' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RatingValue' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Count' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondaryRatingAverages' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'varietyOfInstructors' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quality' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'delivery' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'easeOfUse' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUgcReviewsStatsQuery__
 *
 * To run a query within a React component, call `useUgcReviewsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUgcReviewsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUgcReviewsStatsQuery({
 *   variables: {
 *      equipmentType: // value for 'equipmentType'
 *      lang: // value for 'lang'
 *      excludeFamily: // value for 'excludeFamily'
 *   },
 * });
 */
export function useUgcReviewsStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UgcReviewsStatsQuery,
    UgcReviewsStatsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<UgcReviewsStatsQuery, UgcReviewsStatsQueryVariables>(
    UgcReviewsStatsDocument,
    baseOptions,
  );
}
export function useUgcReviewsStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UgcReviewsStatsQuery,
    UgcReviewsStatsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    UgcReviewsStatsQuery,
    UgcReviewsStatsQueryVariables
  >(UgcReviewsStatsDocument, baseOptions);
}
export type UgcReviewsStatsQueryHookResult = ReturnType<typeof useUgcReviewsStatsQuery>;
export type UgcReviewsStatsLazyQueryHookResult = ReturnType<
  typeof useUgcReviewsStatsLazyQuery
>;
export type UgcReviewsStatsQueryResult = ApolloReactCommon.QueryResult<
  UgcReviewsStatsQuery,
  UgcReviewsStatsQueryVariables
>;
