import { grey, Icon, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';

type Props = {
  isExpanded: boolean;
  onClick: () => void;
};

const PostalCodeToggleButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isExpanded,
  onClick,
}) => {
  return (
    <StyledButton onClick={onClick}>
      <UnderlinedText>{children}</UnderlinedText>
      <Chevron
        name="chevron"
        primaryColor="currentColor"
        rotate={isExpanded ? 90 : 270}
        height={9}
      />
    </StyledButton>
  );
};

export default PostalCodeToggleButton;

const StyledButton = styled.button`
  ${hoverTransition({
    property: 'color',
    to: grey[70],
  })};

  display: inline-flex;
  gap: ${spacing[4]};
  align-items: center;
  color: ${grey[90]};
`;

const UnderlinedText = styled.span`
  border-bottom: 1px solid currentColor;
`;

const Chevron = styled(Icon)`
  width: 9px;
`;
