import {
  ContainedMedia,
  LeadGen,
  Tabs,
  TextWithInlineMedia,
  ValueProps,
} from '@pelotoncycle/page-builder';
import dynamic from 'next/dynamic';
import { ComponentContentTypes } from '@page-builder/lib/constants';
import Banner, { toBannerBlock } from '../../Banner';
import CardGroup from '../../CardGroup';
import ComponentJsonHandler from '../../ComponentJsonHandler';
import Faq from '../../FaqWrapper';
import Forms from '../../Forms';
import GenericModuleWithTreatment from '../../GenericModuleWithTreatment';
import Headband from '../../Headband';
import Hero from '../../Hero';
import LegalTextBlock from '../../LegalText/LegalTextBlock';
import Overview from '../../Overview';
import NextPlaceholder from '../../Placeholder/NextPlaceholder';
import ProductBlock from '../../ProductBlock';
import Quiz from '../../Quiz';
import Reviews from '../../Reviews';
import Video from '../../Video';

const LeadGenModal = dynamic(() => import('../../LeadGenModal/LeadGenModal'), {
  ssr: false,
});

// ADD NEW CONTENT TYPES HERE
export const ContentTypeMap = {
  [ComponentContentTypes.Banner]: toBannerBlock(Banner),
  [ComponentContentTypes.ComponentJson]: ComponentJsonHandler,
  [ComponentContentTypes.Headband]: Headband,
  [ComponentContentTypes.ContainedMedia]: ContainedMedia,
  [ComponentContentTypes.Faq]: Faq,
  [ComponentContentTypes.Forms]: Forms,
  [ComponentContentTypes.GenericList]: GenericModuleWithTreatment,
  [ComponentContentTypes.GenericTextWithMedia]: GenericModuleWithTreatment,
  [ComponentContentTypes.Hero]: Hero,
  [ComponentContentTypes.ImageCards]: CardGroup,
  [ComponentContentTypes.LeadGen]: LeadGen,
  [ComponentContentTypes.LeadGenModal]: LeadGenModal,
  [ComponentContentTypes.LegalText]: LegalTextBlock,
  [ComponentContentTypes.Overview]: Overview,
  [ComponentContentTypes.Placeholder]: NextPlaceholder,
  [ComponentContentTypes.ProductBlock]: ProductBlock,
  [ComponentContentTypes.Reviews]: Reviews,
  [ComponentContentTypes.Tabs]: Tabs,
  [ComponentContentTypes.TextWithInlineMedia]: TextWithInlineMedia,
  [ComponentContentTypes.ValueProps]: ValueProps,
  [ComponentContentTypes.Video]: Video,
  [ComponentContentTypes.Quiz]: Quiz,
};
