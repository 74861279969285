import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from '@peloton/error-reporting';
import { toLanguageFromLocale, useLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { getStrikethroughPricingOffset } from '@ecomm/feature-toggle/MockupStrikethroughPrice';
import type { Language } from '@ecomm/graphql/types.generated';
import { ReviewsOrderByInput } from '@ecomm/graphql/types.generated';
import { toDollars } from '@ecomm/models';
import type { ReviewableTypes } from '@ecomm/models/DeviceType';
import { bundleTypeToDeviceType } from '@ecomm/models/DeviceType';
import { getRentalSlugs, isRentalSlug, useRentalPrices } from '@ecomm/rentals/models';
import { useReviewWithStatsQuery } from '@ecomm/reviews/data/utils/useReviewWithStatsQuery';
import type { ReviewQuery } from '@ecomm/reviews/models';
import type { BundlePackage } from '@ecomm/shop-packages/models';
import type { PackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import type { PackageBySlugQuery } from '@ecomm/shop/graphql/PackageBySlugQuery.generated';
import isRefurbishedBundleType from '@ecomm/shop/isRefurbishedBundleType';
import { BundleType } from '@ecomm/shop/models';
import { usePromoContext } from '@page-builder/modules/Overview/PromoProvider';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import {
  defaultReviewQuantities,
  minimalReviewRating,
  reviewsToDisplay,
} from '@page-builder/modules/ReviewCards/utils/apiReviewQueryParams';

type StructuredDataReviewType = {
  '@type': string;
  name: string;
  datePublished: string;
  reviewRating: {
    '@type': string;
    ratingValue: string;
  };
  author: {
    '@type': string;
    name: string;
  };
  positiveNotes?: string;
};

type ProductPackage = NonNullable<
  | PackageBySlugQuery['catalog']['packageBySlug']
  | PackageBySlugOptionalWarrantyQuery['catalog']['packageBySlugOptionalWarranty']
>;

type StructuredDataType = {
  '@context': string;
  '@type': string;
  name?: string;
  brand?: {
    '@type': string;
    name: string;
  };
  description?: string;
  sku?: string;
  image?: Array<string> | string;
  offers?: {
    '@type': string;
    url: string;
    price: number | string;
    priceCurrency: string;
    itemCondition: string;
    availability?: string;
    businessFunction?: string;
  };

  review?: Array<StructuredDataReviewType> | StructuredDataReviewType;

  aggregateRating?: {
    '@type': string;
    ratingValue: string;
    ratingCount: string;
    bestRating?: string;
  };
};

const getDiscountedPrice = (
  price: number,
  discount: number,
  slug: string,
  bundleType: BundleType,
  isToggleActive: (arg: string) => boolean,
  locale: Locale,
) => {
  const showRowAndTreadStrikethrough =
    isToggleActive('showRowAndTreadStrikethrough') &&
    (bundleType === BundleType.Row || bundleType === BundleType.Tread);

  const activeToggleName = showRowAndTreadStrikethrough
    ? 'showRowAndTreadStrikethrough'
    : '';
  const priceOffset = getStrikethroughPricingOffset(
    activeToggleName,
    bundleType,
    locale,
    slug,
  );

  const anyDiscountActive = showRowAndTreadStrikethrough;

  const showPriceOffset = anyDiscountActive && priceOffset !== 0;

  if (showPriceOffset) {
    const priceNew = (() => {
      // For price drops, the price is already changed in CMS/CT and can be used as-is.
      // For coupons, we need to subtract the diff from the value in CMS/CT

      // if (false) { // replace when we have an active promo which uses coupons
      //   const renderedDiscount = toDollars(anyDiscountActive ? priceOffset : discount);
      //   return price - renderedDiscount;
      // }

      return price;
    })();
    return priceNew;
  }
  return price;
};

const getRentalPrice = (
  productPackage: ProductPackage,
  bikeRentalMonthlyPrice: number,
  bikePlusRentalMonthlyPrice: number,
) => {
  const { bikeSlug, bikeWithDeliverySlug } = getRentalSlugs();
  const isBike = [bikeSlug, bikeWithDeliverySlug].includes(productPackage.slug);
  return isBike ? bikeRentalMonthlyPrice : bikePlusRentalMonthlyPrice;
};

const getOfferFromProductPackage = (
  productPackage: ProductPackage,
  bundleType: BundleType,
  isToggleActive: (arg: string) => boolean,
  locale: Locale,
  isRefurb: boolean,
  isRental: boolean,
  promoDiscount: number = 0,
  bikeRentalMonthlyPrice: number,
  bikePlusRentalMonthlyPrice: number,
) => {
  const price = isRental
    ? getRentalPrice(productPackage, bikeRentalMonthlyPrice, bikePlusRentalMonthlyPrice)
    : toDollars(productPackage.price.amount);

  const priceWithDiscount = getDiscountedPrice(
    price,
    0,
    productPackage.slug,
    bundleType,
    isToggleActive,
    locale,
  );

  const priceWithPromo = priceWithDiscount - promoDiscount;
  const priceCurrency = productPackage?.price?.currency || 'USD';

  const offerData = {
    '@type': 'Offer',
    url: window.location.href,
    price: priceWithPromo,
    priceCurrency,
    itemCondition: isRefurb
      ? 'https://schema.org/RefurbishedCondition'
      : 'https://schema.org/NewCondition',
    availability:
      productPackage?.availability.state === 'available'
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
  };

  if (isRental) {
    offerData['businessFunction'] = 'http://purl.org/goodrelations/v1#LeaseOut';
  }

  return offerData;
};

const buildReviewsStructuredData = (bundleName: string, reviewsData: ReviewQuery[]) => {
  return reviewsData.map(
    review =>
      ({
        '@type': 'Review',
        name: bundleName,
        author: {
          '@type': 'Person',
          name: review.authorName,
        },
        datePublished: review.submittedAt,
        reviewBody: review.reviewText,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: `${review.rating}`,
        },
        positiveNotes: review.wouldRecommend ? review.reviewText : undefined,
      } as StructuredDataReviewType),
  );
};

type StructuredDataProps = {
  bundleType: BundleType;
  bundle: ProductPackage | BundlePackage;
};

export const StructuredData: React.FC<React.PropsWithChildren<StructuredDataProps>> = ({
  bundle,
  bundleType,
}) => {
  const isRental = isRentalSlug(bundle.slug);
  const isToggleActive = useIsToggleActive();

  const locale = useLocale();
  const lang = (toLanguageFromLocale(locale) as unknown) as Language;
  const {
    reviewsProps: reviewsData,
    totalReviews,
    statsProps: averageRating,
  } = useReviewWithStatsQuery(
    bundleTypeToDeviceType(bundleType || BundleType.Bike) as ReviewableTypes,
    ReviewsOrderByInput.OverallRatingDesc,
    reviewsToDisplay,
    defaultReviewQuantities,
    lang,
    false,
    minimalReviewRating,
  );

  const { bundlePromo } = usePromoContext();
  const promoDiscountDollars = bundlePromo?.fields?.couponDiscount || 0;

  const bikeRentalMonthlyPrice = useRentalPrices('BIKE', locale).monthly;
  const bikePlusRentalMonthlyPrice = useRentalPrices('BIKE_PLUS', locale).monthly;

  const structuredData = React.useMemo(() => {
    if (bundle) {
      const data: StructuredDataType = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: bundle.name,
        brand: {
          '@type': 'Brand',
          name: 'Peloton',
        },
        description: bundle.connectedFitnessUnit.description,
        sku: bundle.slug,
        image: bundle.connectedFitnessUnit.image?.src,
        offers: getOfferFromProductPackage(
          bundle,
          bundleType,
          isToggleActive,
          locale,
          isRefurbishedBundleType(bundleType),
          isRental,
          promoDiscountDollars,
          bikeRentalMonthlyPrice,
          bikePlusRentalMonthlyPrice,
        ),
        review: buildReviewsStructuredData(bundle.name, reviewsData),
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: `${averageRating}`,
          ratingCount: `${totalReviews}`,
          ...(reviewsData.length && { bestRating: `${reviewsData[0].rating}` }),
        },
      };

      return data;
    }

    return undefined;
  }, [
    averageRating,
    bikePlusRentalMonthlyPrice,
    bikeRentalMonthlyPrice,
    bundle,
    bundleType,
    isRental,
    isToggleActive,
    locale,
    promoDiscountDollars,
    reviewsData,
    totalReviews,
  ]);

  const isStructuredDataEnabled = useIsToggleActive()('newPDPStructuredData');

  return structuredData && isStructuredDataEnabled ? (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
  ) : null;
};

const StructuredDataWrapper = () => {
  const { productPackage, productPackageLoading, productBundleType } = useShopContext();
  return productPackage && !productPackageLoading && productBundleType ? (
    <ErrorBoundary renderError={() => <></>}>
      <StructuredData bundle={productPackage} bundleType={productBundleType} />
    </ErrorBoundary>
  ) : null;
};

export default StructuredDataWrapper;
