import { ClassFilters } from '@ecomm/classes/api/fetchFiltersData';
import type { SelectedFilters, RawFilters } from '../hooks/useClassFilters';

export type Filters = {
  label: string;
  name: ClassFilters;
  defaultValue: string;
  options: {
    label: string;
    value: string;
  }[];
}[];

type RawFilter = RawFilters[keyof RawFilters];

const FILTERS_ORDER: ClassFilters[] = [ClassFilters.DURATIONS, ClassFilters.CLASS_TYPES];

export const getFilterLabel = (
  microcopy: Record<string, string>,
  handle: string,
): string => {
  const microcopyKey = `classFiltersLabel.${handle}`;
  const filterLabel = microcopy[microcopyKey];

  if (!filterLabel) {
    console.warn(`Missing filter label for ${handle} (key: ${microcopyKey})`);
  }

  return filterLabel;
};

export const getFilterOptionLabel = (
  microcopy: Record<string, string>,
  handle: string,
): string => {
  const microcopyKey = `classFiltersOptionLabel.${handle}`;
  const filterOptionLabel = microcopy[microcopyKey];

  if (!filterOptionLabel) {
    console.warn(`Missing filter option label for ${handle} (key: ${microcopyKey})`);
  }

  return filterOptionLabel;
};

export const toFilters = (
  rawFilters: RawFilters,
  microcopy: Record<string, string>,
  initialSelectedFilters: SelectedFilters | {},
): Filters => {
  const sortedRawFilters: (
    | [ClassFilters, RawFilter]
    | null
  )[] = FILTERS_ORDER.map(filterHandle =>
    filterHandle in rawFilters ? [filterHandle, rawFilters[filterHandle]] : null,
  );

  const validRawFilters = sortedRawFilters.filter(Boolean) as [ClassFilters, RawFilter][];

  const filters = validRawFilters
    .map(([filterHandle, rawOptions]) => {
      const options = rawOptions
        .map(handle => ({
          value: handle,
          label: getFilterOptionLabel(microcopy, handle),
        }))
        .filter(option => option.label);

      const defaultOption = {
        value: '',
        label: getFilterOptionLabel(microcopy, `all.${filterHandle}`),
      };

      return {
        label: getFilterLabel(microcopy, filterHandle),
        name: filterHandle,
        defaultValue: initialSelectedFilters[filterHandle] || '',
        options: [defaultOption].concat(options),
      };
    })
    .filter(filter => filter.label);

  return filters;
};
