import React from 'react';
import PostalCodeToggleButton from '@page-builder/modules/Overview/ProductAvailability/PostalCodeToggleButton';

const toPostalCodeLinkMarkdownRenderers = (
  onClick: () => void,
  isExpanded: boolean = false,
): Record<string, React.ElementType> => ({
  link: ({ children }) => (
    <PostalCodeToggleButton onClick={onClick} isExpanded={isExpanded}>
      {children}
    </PostalCodeToggleButton>
  ),
});

export default toPostalCodeLinkMarkdownRenderers;
