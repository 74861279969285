import React from 'react';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import type { PageHandler as VariationHandler } from '@page-builder/layout/types';
import type { TypeVariationFields } from '@page-builder/lib/types';

type ProductUnion = `${Product}`;

type ProductAvailabilityProps = {
  productId: ProductUnion;
  control: TypeVariationFields['control'];
  variations: TypeVariationFields['variations'];
};

type Props = VariationHandler<ProductAvailabilityProps>;

const toProduct = (id: string): Product => {
  switch (id) {
    case Product.Bike:
    case Product.BikePlus:
    case Product.DigitalApp:
    case Product.RainforestCafe:
    case Product.Tread:
    case Product.TreadPlus:
    case Product.Row:
      return id;
    default:
      return Product.Bike;
  }
};

const ProductAvailabilityBlock: React.FC<React.PropsWithChildren<Props>> = ({
  productId,
  control,
  variations,
  block: VariationBlock,
}) => {
  const { isProductAvailableForMarketingPages } = useProductStates();
  const product = toProduct(productId);

  const isAvailable = isProductAvailableForMarketingPages(product);
  const moduleData = isAvailable ? variations?.[0] : control;

  if (moduleData) {
    return <VariationBlock block={moduleData} />;
  }

  return null;
};

export default ProductAvailabilityBlock;
