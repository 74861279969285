import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';

enum ClassListingFilterKeys {
  PAGE = 'page',
}

export type ClassListingFilters = {
  page?: number;
};

const CLASS_LISTING_FILTERS_KEYS: string[] = Object.values(ClassListingFilterKeys);

export const buildFiltersQueryParams = (query: NextParsedUrlQuery): string => {
  const filtersQueryParams = Object.entries(query)
    .filter(([key]) => CLASS_LISTING_FILTERS_KEYS.includes(key))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return filtersQueryParams ? `?${filtersQueryParams}` : '';
};
