import { Eyebrow, Flex, Support, spacing } from '@pelotoncycle/design-system';
import { EmailCapture, WithMarkdown } from '@pelotoncycle/page-builder';
import type {
  TypeComponentLeadGenFields,
  TypeMetadataLeadGenFields,
} from '@pelotoncycle/page-builder';
import React, { useState } from 'react';
import styled from 'styled-components';
import Markdown from '@peloton/copy/Markdown';
import { media } from '@peloton/styles';
import { useGlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import { useColorSelectionContext } from '@page-builder/modules/Overview/ColorSelectionContext';
import { colorSlugMap } from '../constants';
import toPostalCodeLinkMarkdownRenderers from './toPostalCodeLinkMarkdownRenderers';

type Props = {
  emailLeadGenFields?: TypeComponentLeadGenFields;
  eyebrowValues?: Object;
  postalCode: string;
  onTogglePostalCode: () => void;
};

const SubmitEmailLeadGen = ({
  emailLeadGenFields,
  eyebrowValues,
  postalCode,
  onTogglePostalCode,
}: Props) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { globalTheme } = useGlobalUiStateContext();
  const { activeColorSlug } = useColorSelectionContext();
  const formattedGlobalTheme = globalTheme?.replace(
    globalTheme[0],
    globalTheme[0].toUpperCase(),
  );

  if (!emailLeadGenFields) return null;

  const {
    bodyText: rawBodyText = '',
    ctaText,
    failureMessage,
    headlineText: rawEyebrowText,
    metadata,
    placeholder,
    successMessage,
    supportText: rawSupportText,
    theme = 'White',
    zipcodeErrorMessage,
  } = emailLeadGenFields;

  const bodyTextContent =
    showSuccessMessage && successMessage ? successMessage : rawBodyText;
  const supportText = rawSupportText && <WithMarkdown text={rawSupportText} />;

  const trackingProps: Partial<TypeMetadataLeadGenFields> = {};
  if (activeColorSlug) {
    const { displayName } = colorSlugMap[activeColorSlug];
    trackingProps.unitName = metadata.fields.unitName.replace('{color}', displayName);
  }

  return (
    <Flex
      flexDirection="column"
      verticalMargin={`${spacing[24]} ${spacing[16]}`}
      gap={spacing[16]}
    >
      <Flex flexDirection="column" gap={spacing[8]}>
        <Eyebrow size="medium">
          <Markdown content={rawEyebrowText} values={eyebrowValues} />
        </Eyebrow>
        <Support size="large">
          <Markdown
            content={bodyTextContent}
            markdown={{
              renderers: toPostalCodeLinkMarkdownRenderers(onTogglePostalCode),
            }}
            values={{
              postalCode,
            }}
          />
        </Support>
      </Flex>
      <StyledEmailCapture
        ctaText={ctaText}
        failureMessage={failureMessage}
        inputId={metadata.fields.unitName}
        onSuccess={() => setShowSuccessMessage(true)}
        onReset={() => setShowSuccessMessage(false)}
        metadata={metadata.fields}
        placeholder={placeholder}
        theme={formattedGlobalTheme || theme}
        zipcodeErrorMessage={zipcodeErrorMessage}
        showSuccessMessage={false}
        trackingProps={trackingProps}
      />
      <Support size="small">{supportText}</Support>
    </Flex>
  );
};

const StyledEmailCapture = styled(EmailCapture)`
  button[type='submit'] {
    width: 100%;

    ${media.tabletXLarge`
      min-width: 123px;
      width: auto;
    `}
  }
`;

export default SubmitEmailLeadGen;
