import { Container, Media, Label, spacing, white } from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { toMediaProps, toCtaProps } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useVideoAnalyticsEventHandlers from '@page-builder/hooks/useVideoAnalyticsEventHandlers';
import {
  DESKTOP_MEDIA_HEIGHT,
  MOBILE_MEDIA_HEIGHT,
  TRANSITION_DURATION,
} from './constants';
import { HighlightReelCta } from './HighlightReelCta';

type ActiveProps = {
  active: boolean;
};

type Props = TypeComponentGenericTextWithMediaFields &
  ActiveProps & {
    position: number;
    onVideoEnded: () => void;
    isInView: boolean;
  };

const HighlightReelCard: React.FC<React.PropsWithChildren<Props>> = ({
  ctas,
  media: mediaEntry,
  active,
  onVideoEnded,
  position,
  isInView,
}) => {
  const { onPause } = useVideoAnalyticsEventHandlers({
    properties: {
      contentId: `Highlight Reel Card ${position + 1}`,
      contentPosition: position + 1,
      title: mediaEntry!.fields.media.fields.name,
    },
  });

  const mediaProps = {
    ...toMediaProps(mediaEntry!),
    autoplay: active,
    loop: false,
    playPauseButtonPosition: active ? 'right' : 'hide',
    progressTracked: true,
    onEnded: onVideoEnded,
    onPause,
  } as const;

  const cta = ctas?.length && toCtaProps(ctas[0]);

  if (!isInView) {
    return <OutOfViewPlaceholder />;
  }

  return (
    <Card active={active} data-test-id="highlight-reel-card">
      {cta && (
        <CardCtaWrapper>
          <HighlightReelCta active={active}>
            <Label size="medium" textColor={white}>
              {cta.text}
            </Label>
          </HighlightReelCta>
        </CardCtaWrapper>
      )}
      <CardMedia
        media={mediaProps}
        active={active}
        key={active ? 'active' : 'inactive'}
      />
    </Card>
  );
};

const Card = styled(Container)`
  line-height: 0;
  overflow: hidden;
  border-radius: 12px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${(props: ActiveProps) => (props.active ? 1 : 0)};
  transition: 1s opacity;

  ${media.desktopLarge`
    position: relative;
    opacity: 1;
  `}
`;

const CardMedia = styled(Media)`
  overflow: hidden;
  height: ${MOBILE_MEDIA_HEIGHT}px;
  transition: ${TRANSITION_DURATION} opacity;

  video {
    aspect-ratio: 1.15;
    height: ${MOBILE_MEDIA_HEIGHT}px;
    width: 100%;
  }

  ${media.tabletXLarge`
    video {
      aspect-ratio: 1.53;
    }
  `}

  ${media.desktopLarge`
  height: ${DESKTOP_MEDIA_HEIGHT}px;
  transition: transform ${TRANSITION_DURATION} ease 0s, opacity ${TRANSITION_DURATION} ease;

  &:hover {
    transform: ${(props: ActiveProps) => (props.active ? 'scale(1)' : 'scale(1.03)')};
    opacity: ${(props: ActiveProps) => (props.active ? 1 : 0.6)};
  }

    opacity: ${(props: ActiveProps) => (props.active ? 1 : 0.3)};
    
    > div {
      display: flex;
      justify-content: center;
    }

    video {
      aspect-ratio: 1.53;
      height: ${DESKTOP_MEDIA_HEIGHT}px;
      width: auto;
    }
  `}
`;

const CardCtaWrapper = styled.div`
  position: absolute;
  bottom: ${spacing[16]};
  left: ${spacing[16]};
  z-index: 2;
  display: none;

  ${media.desktopLarge`
    display: block;
  `}
`;

const OutOfViewPlaceholder = styled.div`
  width: 100%;
  height: ${MOBILE_MEDIA_HEIGHT}px;

  ${media.desktopLarge`
      height: ${DESKTOP_MEDIA_HEIGHT}px;
  `}
`;

export default HighlightReelCard;
