const DEFAULT_TIMEOUT_IN_MILLISECONDS = 7500;

enum ErrorType {
  ABORT = 'AbortError',
}

const initialiseTimeout = (timeout: number) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return {
    signal: controller.signal,
    cancelTimeout: () => clearTimeout(timeoutId),
  };
};

type Options = {
  timeout?: number;
};

export const monitoredFetch = (
  label: string,
  { timeout = DEFAULT_TIMEOUT_IN_MILLISECONDS }: Options = {},
) => async (input: string | URL, init?: RequestInit | undefined): Promise<Response> => {
  try {
    const { signal, cancelTimeout } = initialiseTimeout(timeout);
    const startTime = performance.now();
    const response = await fetch(input, {
      ...(init || {}),
      signal: init?.signal || signal,
    });

    cancelTimeout();
    const endTime = performance.now();
    const durationInSeconds = ((endTime - startTime) / 1000).toFixed(2);
    console.log(`${label} request took ${durationInSeconds}s to complete`);

    return response;
  } catch (error) {
    if (error.name === ErrorType.ABORT) {
      console.error(`${label} request timed out and couldn’t complete`);
    } else {
      console.error(`${label} request failed (${error.toString()})`);
    }

    throw error;
  }
};
