import { Flex, spacing } from '@pelotoncycle/design-system';
import type { FlexProps } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

export type ColumnsProps = {
  gap?: FlexProps['gap'];
  reverse?: boolean;
};

const defaults = {
  gap: spacing[12],
};

const Columns: React.FC<React.PropsWithChildren<ColumnsProps>> = ({
  gap = defaults.gap,
  reverse,
  children,
}) => (
  <StyledFlex
    flexDirection={{ mobile: 'column', desktop: reverse ? 'row-reverse' : 'row' }}
    justifyContent={{ desktop: 'space-between' }}
    gap={gap}
    removeOuterMargins
  >
    {children}
  </StyledFlex>
);

export default Columns;

const StyledFlex = styled(Flex)`
  width: 100%;
  & > * {
    flex: 1;
  }
`;
