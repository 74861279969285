import type { Upsell } from '@ecomm/pg-checkout-commercetools/utils/types';
import type {
  AccessoryCommerceTools,
  ProductItemOfBundle,
} from '@ecomm/pg-shop-accessories-display/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import type { AccessoryVariantsType } from '@page-builder/modules/Overview/UpsellAccessoryContext';
import { isAccessoryBundle } from '@page-builder/modules/Overview/utils';

const getUpsells = (
  selectedAccessories: (AccessoryCommerceTools | BundleAccessoryType)[],
  accessoryVariants: AccessoryVariantsType,
): Upsell[] => {
  return selectedAccessories.map(
    (bundleOrAccessory: AccessoryCommerceTools | BundleAccessoryType) => {
      if (isAccessoryBundle(bundleOrAccessory)) {
        const bundle = bundleOrAccessory as BundleAccessoryType;

        const selections = bundle.products.map((p: ProductItemOfBundle, i) => ({
          product: p.slug,
          selections: [
            {
              sku: accessoryVariants[bundle.slug][`${p.slug}-${i}`]?.sku ?? '',
            },
          ],
        }));

        return {
          bundle,
          productSelection: selections,
        };
      }

      return accessoryVariants[bundleOrAccessory.slug][bundleOrAccessory.slug];
    },
  ) as Upsell[];
};

export default getUpsells;
