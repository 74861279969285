import type { Client } from '@peloton/api/utils';
import { pipeDataTwice } from '@peloton/api/utils';
import { toStoreSlug } from '@peloton/stores';
import type { ID, Slug } from '@ecomm/models';
import { toIsEligible } from './mappers';

type ProductIdentifier = ID | Slug;

export const checkPostalCodeEligibilityUrl = (
  productIdOrSlug: ProductIdentifier,
  postalCode: String,
) =>
  `ecomm/store/${toStoreSlug()}/products/${productIdOrSlug}/eligibility/postal_code/${postalCode}`;

const checkPostalCodeEligibility = (
  api: Client,
  productIdOrSlug: ProductIdentifier,
  postalCode: String,
) => {
  return api
    .get(checkPostalCodeEligibilityUrl(productIdOrSlug, postalCode))
    .then(pipeDataTwice(toIsEligible));
};

export default checkPostalCodeEligibility;
