import { useMicroCopy } from '@content/client/microCopy';

const useMicroDisciplineName = (discipline: string) => {
  const disciplineDisplayNames = useMicroCopy('disciplineNavDisplayNames') as string;
  const disciplineCategories = useMicroCopy('disciplineNavCategories') as string;

  const displayNamesArray = disciplineDisplayNames.split(',');
  const disciplineIndex = disciplineCategories
    ?.split(',')
    .findIndex(disciplineName => disciplineName === discipline.toLowerCase());
  if (disciplineIndex >= 0) {
    return displayNamesArray[disciplineIndex];
  }
  return discipline;
};

export { useMicroDisciplineName };
