import { Icon, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import useIsAppCtaExperimentActive from '@ecomm/feature-toggle/AppCta';
import useAppDownloadRedirect from './useAppDownloadRedirect';

const DownloadRedirect: React.FC<React.PropsWithChildren<unknown>> = () => {
  useAppDownloadRedirect();
  const isAppCTAExperimentActive = useIsAppCtaExperimentActive();
  const minHeight = isAppCTAExperimentActive ? '32px' : '700px';

  return (
    <Flex minHeight={minHeight} alignItems="center" justifyContent="center">
      {!isAppCTAExperimentActive && <Icon name="spinner" height={32} />}
    </Flex>
  );
};

export default DownloadRedirect;
