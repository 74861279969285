import type { FlexProps } from '@pelotoncycle/design-system';
import { Flex, Support, Label, spacing } from '@pelotoncycle/design-system';
import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import { TEXT_MAXWIDTH } from '@page-builder/modules/tokens';

export type LowerTextBlockTextProps = {
  supportText?: string | ReactNode;
  labelText?: string | ReactNode;
};

export type LowerTextBlockStyleProps = {
  textColor?: string;
  supportTextColor?: CSSProperties['color'];
  labelTextColor?: CSSProperties['color'];
  textAlign?: FlexProps['textAlign'];
  maxWidth?: FlexProps['maxWidth'];
  margin?: FlexProps['margin'];
  hasDropShadow?: boolean;
  style?: CSSProperties;
};

export type LowerTextBlockProps = LowerTextBlockTextProps & LowerTextBlockStyleProps;

const defaults: LowerTextBlockProps = {
  textAlign: 'center',
  maxWidth: {
    mobile: TEXT_MAXWIDTH['mobile'],
    tablet: TEXT_MAXWIDTH['tablet'],
    desktop: TEXT_MAXWIDTH['desktop'],
  },
  margin: {
    mobile: `${spacing[32]} auto 0`,
    desktop: `${spacing[56]} auto 0`,
  },
};

/**
 * @deprecated prefer import { LowerTextBlock } from '@pelotoncycle/page-builder'
 */

const LowerTextBlock: React.FC<React.PropsWithChildren<LowerTextBlockProps>> = ({
  textColor,
  supportText,
  labelText,
  supportTextColor,
  labelTextColor,
  textAlign = defaults.textAlign,
  maxWidth = defaults.maxWidth,
  margin = defaults.margin,
  hasDropShadow,
  style,
}) => (
  <Flex
    flexDirection="column"
    textAlign={textAlign}
    gap={spacing[8]}
    maxWidth={maxWidth}
    margin={margin}
    style={style}
  >
    {supportText && (
      <Support textColor={supportTextColor || textColor} hasShadow={hasDropShadow}>
        {supportText}
      </Support>
    )}
    {labelText && (
      <Label textColor={labelTextColor || textColor} hasShadow={hasDropShadow}>
        {labelText}
      </Label>
    )}
  </Flex>
);

export default LowerTextBlock;
