import { Flex, spacing, black, grey } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCountry } from '@peloton/internationalize';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import CompareModal from './CompareModal';

type Props = {
  title: TypeComponentGenericTextWithMedia;
  product: string;
};

const ComparePricingModalCta: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  product,
}) => {
  const { trackEvent } = useTracking();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    fields: { ctas },
  } = title;
  const cta = ctas && toCtaFields(ctas)[0];

  const handleModalOpened = () => {
    setIsModalOpen(true);
    trackEvent({
      event: 'Modal Opened',
      properties: {
        productInterest: product,
        parentType: 'Component: Overview',
        parent: 'Overview Drawers',
        propertyType: 'Web',
        unitName: `${product} Overview: Multi Shop Option Modal Open`,
        unitPlacement: 'Modal',
        modalTrigger: cta && `Clicked on '${cta.name}' cta`,
        country: toCountry(),
      },
    });
  };

  const handleModalClick = () => {
    setIsModalOpen(true);

    trackEvent({
      event: 'Clicked Link',
      properties: {
        href: '',
        parentType: 'Component: Overview',
        parent: 'Overview Drawers',
        unitName: `${product} Overview: Multi Shop Option Modal Click`,
        linkName: cta?.text,
        linkTo: '',
      },
    });
  };

  return (
    <>
      <Flex
        width="100%"
        justifyContent="center"
        verticalMargin={`${spacing[16]} 0`}
        data-test-id="compare-pricing-modal-cta"
      >
        {cta && <ModalButton onClick={handleModalClick}>{cta.text}</ModalButton>}
      </Flex>
      {cta &&
        cta.modal &&
        isEntryType<TypeComponentGenericTextWithMedia>(cta.modal, 'text') && (
          <CompareModal
            isOpen={isModalOpen}
            openHandler={() => handleModalOpened()}
            closeHandler={() => setIsModalOpen(false)}
            modalContent={cta.modal.fields}
          />
        )}
    </>
  );
};
export default ComparePricingModalCta;

const ModalButton = styled.button`
  border-bottom: solid 1px ${black};
  font-size: 14px;

  &:hover {
    color: ${grey[70]};
    border-bottom: solid 1px ${grey[70]};
    transition: all 300ms ease;
  }
`;
