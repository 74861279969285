import {
  Grid,
  Eyebrow,
  Headline,
  Flex,
  Body,
  spacing,
  white,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import React, { useContext, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { PrevArrowIcon, NextArrowIcon } from '@ecomm/themed-modules/VideoGallery/icons';
import autoPlayWithSound from '@ecomm/video/autoPlayWithSound';
import PageContext from '@page-builder/layout/context/PageContext';
import type {
  TypeComponentGenericListFields,
  TypeComponentVideo,
} from '@page-builder/lib/types';
import Card from '@page-builder/modules/CarouselCard';
import VideoModal from '@page-builder/modules/VideoModal';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toLightboxVideoProps } from '@page-builder/utils/helpers/lightbox';
import { imageFieldsToMediaImageProps } from '@page-builder/utils/helpers/media';
import TrackedVideo from '@page-builder/utils/TrackingVideoElement';

const VideoTrailers: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const { trackEvent } = useTracking();
  const { additionalTrackingData } = useContext(PageContext);
  const nextLabel = useMicroCopy('nextButtonLabel');
  const previousLabel = useMicroCopy('previousButtonLabel');

  const { eyebrow, headline } = getGenericTextNodes(text, ['headline']);
  const trailers = items.map(({ fields }) => {
    const video = fields.media?.fields.media as TypeComponentVideo;
    const poster = imageFieldsToMediaImageProps(video.fields.poster.fields);
    const { label, url, tracks, campaignName } = toLightboxVideoProps(video);
    const { headline: title } = getGenericTextNodes(fields.text, ['headline']);

    return {
      title,
      label,
      url,
      poster,
      tracks,
      videoMetadata: {
        properties: {
          campaignName: campaignName || '',
          ...additionalTrackingData,
        },
      },
    };
  });

  const mobileGridColumnCount = trailers.length < 4 ? 1 : 2;

  const openNextModal = () => {
    const nextIndex = videoIndex + 1;
    if (nextIndex < trailers.length) {
      setVideoIndex(nextIndex);
    } else {
      setVideoIndex(0);
    }
  };

  const openPreviousModal = () => {
    const nextIndex = videoIndex - 1;
    if (nextIndex >= 0) {
      setVideoIndex(nextIndex);
    } else {
      setVideoIndex(trailers.length - 1);
    }
  };

  const ArrowIcon: React.FC<React.PropsWithChildren<{ direction: 'prev' | 'next' }>> = ({
    direction,
  }) => {
    return direction === 'prev' ? (
      <button onClick={openPreviousModal} aria-label={previousLabel}>
        <PrevArrowIcon height={32} />
      </button>
    ) : (
      <button onClick={openNextModal} aria-label={nextLabel}>
        <NextArrowIcon height={32} />
      </button>
    );
  };

  return (
    <Flex
      aria-live="polite"
      id="videoTrailersSection"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      padding={{
        mobile: `${spacing[48]} ${spacing[16]}`,
        tablet: `${spacing[64]}`,
        desktop: `${spacing[104]} ${spacing[40]}`,
      }}
    >
      {eyebrow && <StyledEyebrow>{eyebrow}</StyledEyebrow>}
      <StyledHeadline size="medium">{headline}</StyledHeadline>
      <StyledGrid
        columnCount={{
          mobile: mobileGridColumnCount,
          tablet: mobileGridColumnCount,
          desktop: Math.min(trailers.length, 4),
        }}
        margin={{
          mobile: `${spacing[32]} 0 0`,
          tablet: `${spacing[40]} 0 0`,
          desktop: `${spacing[56]} 0 0`,
        }}
        gap={{
          mobile: spacing[16],
          tablet: spacing[24],
        }}
      >
        {trailers.map(({ title, poster }, index) => {
          const openModal = () => {
            setVideoIndex(index);
            setIsModalOpen(true);
          };

          return (
            <button
              key={`trailerTile_${index}`}
              onClick={openModal}
              data-test-id={`videoModalButton_${index}`}
            >
              <StyledCard title={title} image={poster} index={index} />
            </button>
          );
        })}
      </StyledGrid>
      <VideoModal
        closeHandler={() => {
          setIsModalOpen(false);
          trackEvent({
            event: 'Video Content Closed',
            ...trailers[videoIndex].videoMetadata,
          });
        }}
        isOpen={isModalOpen}
        darkMode={true}
        contentLabel={trailers[videoIndex].label}
        withContent
      >
        <Flex
          id="videoTrailerContainer"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding={{
            desktop: `0 ${spacing[40]}`,
          }}
        >
          <VideoControls>
            <ArrowIcon direction="prev" />
            <div>
              <StyledTrackedVideo
                src={trailers[videoIndex].url}
                onCanPlay={autoPlayWithSound}
                tracks={trailers[videoIndex].tracks}
                autoPlay
                controls
                eventProps={trailers[videoIndex].videoMetadata}
              />
            </div>
            <ArrowIcon direction="next" />
            <MobileControls>
              <ArrowIcon direction="prev" />
              <VideoInfo>
                <StyledBody>{trailers[videoIndex].title}</StyledBody>
                <Eyebrow size="small">
                  {`${videoIndex + 1} of ${trailers.length}`}
                </Eyebrow>
              </VideoInfo>
              <ArrowIcon direction="next" />
            </MobileControls>
          </VideoControls>
        </Flex>
      </VideoModal>
    </Flex>
  );
};

export default VideoTrailers;

const StyledGrid = styled(Grid)`
  width: 100%;
  max-width: 1224px;
`;

const VideoControls = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > button {
    display: none;
  }

  @media (min-width: ${BreakpointWidths.desktop}px) {
    flex-direction: row;
    gap: ${spacing[24]};

    > button {
      display: inline-flex;
    }
  }
`;

const StyledEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[16]};
`;

const StyledHeadline = styled(Headline)`
  text-align: center;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    max-width: 680px;
  }
`;

const StyledTrackedVideo = styled(TrackedVideo)`
  width: 100%;
  max-width: 1280px;
`;

const StyledCard = styled(Card)`
  margin: 0;
  overflow: hidden;
  max-width: unset;
`;

const MobileControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 0 32px;
  width: 100%;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    display: none;
  }
`;

const StyledBody = styled(Body)`
  padding-bottom: 16px;
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${white};
`;
