import { List } from '@pelotoncycle/design-system';
import React from 'react';

type ListProps = {
  ordered: boolean;
  children: MarkdownChildren[];
  className?: string;
};

type MarkdownChildren = {
  props: {
    children: React.ReactNode[];
  };
};

const ListRenderer: React.FC<ListProps> = ({ ordered, className, children }) => {
  // Flattening to remove extra nested <li>s
  const flattenedChildren = children.map(child => child?.props?.children);

  if (flattenedChildren) {
    return (
      <List
        listStyle={ordered ? 'decimal' : 'disc'}
        size="small"
        items={flattenedChildren}
        gap="0"
        className={className}
      />
    );
  }

  return null;
};

export default ListRenderer;
