import { LinkButton as BaseLinkButton } from '@pelotoncycle/design-system';
import type { LinkButtonProps, ButtonProps } from '@pelotoncycle/design-system';
import NextLink from 'next/link';
import React from 'react';
import { USE_NEXT_LINK } from '@peloton/app-config';
import {
  useIsExternalToProspects,
  useIsNextApp,
  useIsProspectsToExternalUrl,
} from '@peloton/next/hooks/useIsNextHooks';
import { LinkButton as I18nLinkButton } from '@ecomm/internationalize-ui';

type LinkableProps = ButtonProps & LinkButtonProps & { prefetch?: boolean };
export const LinkButton = withI18n(BaseLinkButton);

function withI18n(
  Component: React.ComponentType<React.PropsWithChildren<LinkableProps>>,
) {
  return function WithI18n({
    href,
    prefetch,
    ...props
  }: LinkableProps & { prefetch?: boolean }) {
    const isNextApp = useIsNextApp();
    const isProspectsToExternalUrl = useIsProspectsToExternalUrl(href);
    const isExternalToProspects = useIsExternalToProspects(href);

    if (
      USE_NEXT_LINK &&
      isNextApp &&
      !isProspectsToExternalUrl &&
      !isExternalToProspects
    ) {
      return (
        <Component
          component={NextLink}
          componentProps={{
            passHref: true,
            legacyBehavior: false,
          }}
          href={href}
          {...props}
        />
      );
    }

    return <I18nLinkButton href={href} {...props} />;
  };
}
