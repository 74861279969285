import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { useContext, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { toEntryTags } from '@page-builder/utils/helpers';

export const MOBILE_BAND_CTA_TAG = 'mobileBandCTA';

const useStickyProps = (cta: TypeComponentCta) => {
  const [activeCtaRef, ctaInView] = useInView();

  const { footerInViewProps } = useContext(GlobalReferenceContext);
  const footerInView = !!footerInViewProps?.inView;
  const showStickyCta = !ctaInView && !footerInView;

  const isStickyCta = useMemo(() => toEntryTags(cta).includes(MOBILE_BAND_CTA_TAG), [
    cta,
  ]);

  return {
    activeCtaRef,
    isStickyCta,
    showStickyCta,
  };
};

export default useStickyProps;
