import { grey, spacing, Container, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import { useTracking } from 'react-tracking';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import type { WwwLinkReferenceType as WwwLinkReference } from '../../../types/referenceTypes';
import HeroCarousel from './HeroCarousel';
import HeroCTA from './HeroCTA';
import HeroText from './HeroText';
import type { VideoCard as VideoCardProps } from './types';

const CLICKED_BROWSE_CLASSES_EVENT_TYPE = 'Clicked Link';

export type HeroBannerProps = {
  headline: {
    staticText: string;
    highlightedText: string[];
  };
  cta: {
    text: string;
    link: WwwLinkReference;
  };
  videoCards: VideoCardProps[];
};

const HeroBanner: FC<HeroBannerProps> = ({ headline, cta, videoCards }) => {
  const { trackEvent } = useTracking();

  const trackClickedBrowseClasses = () => {
    trackEvent({
      event: CLICKED_BROWSE_CLASSES_EVENT_TYPE,
      properties: {
        parent: 'Prospect Classes Page',
        linkTo: `${cta.link.url}`,
        linkName: `${cta.text}`,
        unitName: 'HeroBanner',
        parentType: 'Page',
      },
    });
  };

  return (
    <>
      <Container
        backgroundColor={grey[90]}
        padding={{
          mobile: `${spacing[24]} 0 ${spacing[32]} 0`,
          tablet: `${spacing[32]} 0 ${spacing[64]} ${spacing[40]}`,
          desktop: `${spacing[56]} 0 ${spacing[64]} 0`,
        }}
      >
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: (
              <MobileHeroBanner
                headline={headline}
                cta={cta}
                videoCards={videoCards}
                trackClickedBrowseClasses={trackClickedBrowseClasses}
              />
            ),
            tabletXLarge: (
              <DesktopTabletHeroBanner
                headline={headline}
                cta={cta}
                videoCards={videoCards}
                trackClickedBrowseClasses={trackClickedBrowseClasses}
              />
            ),
          }}
        />
      </Container>
    </>
  );
};

const DesktopTabletHeroBanner: FC<
  HeroBannerProps & { trackClickedBrowseClasses: () => void }
> = ({ headline, cta, videoCards, trackClickedBrowseClasses }) => (
  <Flex flexDirection="row" maxWidth="1224px" margin="auto">
    <Flex
      margin={{ tablet: `${spacing[80]} 0 114px 0`, desktop: '68px 0 78px 0' }}
      flexDirection="column"
    >
      <HeroText
        staticText={headline.staticText}
        highlightedText={headline.highlightedText}
      />
      <HeroCTA
        text={cta.text}
        link={cta.link}
        onClick={() => trackClickedBrowseClasses()}
      />
    </Flex>
    <Flex
      margin={{ tablet: `0 0 0 ${spacing[64]}`, desktop: '0 0 0 128px' }}
      flexDirection="column"
      style={{ overflow: 'hidden' }}
    >
      <HeroCarousel videoCards={videoCards} />
    </Flex>
  </Flex>
);

const MobileHeroBanner: FC<
  HeroBannerProps & { trackClickedBrowseClasses: () => void }
> = ({ headline, cta, videoCards, trackClickedBrowseClasses }) => (
  <Flex flexDirection="column" maxWidth="1224px" margin="auto">
    <HeroText
      staticText={headline.staticText}
      highlightedText={headline.highlightedText}
    />
    <HeroCarousel videoCards={videoCards} />
    <Flex justifyContent="center">
      <HeroCTA
        text={cta.text}
        link={cta.link}
        onClick={() => trackClickedBrowseClasses()}
      />
    </Flex>
  </Flex>
);

export default HeroBanner;
