import { AccessoryBundleKeys } from '../constants';
import type { ProductProjectionType } from '../models/main';
import type { RawProductAttribute } from '../types.generated';
import { getAttributeValueByName } from './getAttributeValueByName';

const isBundleConfigurable = (bundle: ProductProjectionType): boolean => {
  const productValues: Array<RawProductAttribute[]> = getAttributeValueByName(
    bundle.masterVariant.attributesRaw,
    AccessoryBundleKeys.PRODUCTS,
  );
  const isConfigurable = productValues.some((attributes): boolean =>
    getAttributeValueByName(attributes, AccessoryBundleKeys.IS_CONFIGURABLE),
  );

  return isConfigurable;
};

export default isBundleConfigurable;
