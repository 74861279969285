import { useCallback, useState } from 'react';
import { useTracking } from 'react-tracking';
import { getFormattedPageName } from '@ecomm/cms-hero/trackingUtils';
import useInterval from './useInterval';

// Consolidation of useCarousel and useCarouselWithAutoAdvance from @ecomm/cms-hero

const useTrackSlideNavigation = () => {
  const { trackEvent } = useTracking();
  const pathname = typeof window !== 'undefined' ? window?.location?.pathname : '';
  const parent = getFormattedPageName(pathname);
  const trackSlideNavigation = useCallback(
    (index: number) => {
      trackEvent({
        event: 'Carousel Slide Viewed',
        properties: {
          parent,
          linkTo: '',
          page: pathname,
          unitName: `${parent}-hero-nav-slide-${index + 1}`,
        },
      });
    },
    [parent, trackEvent, pathname],
  );

  return { trackSlideNavigation };
};

const useCarousel = (size: number, interval?: number) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleAutoAdvance = useCallback(() => {
    const nextSlide = (activeSlide + 1) % size;
    setActiveSlide(nextSlide);
  }, [activeSlide, size]);

  const { resetInterval } = useInterval(
    handleAutoAdvance,
    isPlaying ? interval : undefined,
  );

  const { trackSlideNavigation } = useTrackSlideNavigation();

  const handlePrevious = useCallback(() => {
    const previousSlide = activeSlide === 0 ? size - 1 : activeSlide - 1;
    trackSlideNavigation(previousSlide);
    setActiveSlide(previousSlide);
    resetInterval();
  }, [size, activeSlide, resetInterval, trackSlideNavigation]);

  const handleNext = useCallback(() => {
    const nextSlide = (activeSlide + 1) % size;
    trackSlideNavigation(nextSlide);
    setActiveSlide(nextSlide);
    resetInterval();
  }, [size, activeSlide, resetInterval, trackSlideNavigation]);

  const handleClickIndicator = useCallback(
    (index: number) => {
      trackSlideNavigation(index);
      setActiveSlide(index);
      resetInterval();
    },
    [resetInterval, trackSlideNavigation],
  );

  const handleStart = () => setIsPlaying(true);
  const handlePause = () => setIsPlaying(false);

  return {
    activeSlide,
    handlePrevious,
    handleNext,
    handleClickIndicator,
    handleAutoAdvance,
    handleStart,
    handlePause,
    isPlaying,
  };
};

export default useCarousel;
