import React from 'react';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { ErrorBoundary, ErrorMessage } from '@page-builder/layout/error-handling';
import HeadbandContainer from '@page-builder/modules/Headband/HeadbandContainer';
import HeadbandTitle from '@page-builder/modules/Headband/HeadbandTitle';
import { filterFalsyValues } from '@page-builder/utils/unions';
import type { WwwLinkReferenceType as WwwLinkReference } from '../../../types/referenceTypes';
import { TOCItems, TOCItemsDropdown, useSectionObserver } from '../TableOfContents';
import type { TOCItemLinkProps as TypeTOCItemLink } from '../TableOfContents';
import { HeadbandCtaWithInfo } from './HeadbandCtaWithInfo';
import { MobileCTABanner } from './MobileCTABanner';

export type HeadbandProps = {
  title?: string;
  tableOfContents?: TypeTOCItemLink[];
  ctas?: {
    text: string;
    link?: WwwLinkReference;
  }[];
  financingText?: string;
  disclaimerText?: string;
  stackHeadband?: boolean;
};

const Headband: React.FC<HeadbandProps> = ({
  title,
  tableOfContents,
  ctas,
  financingText,
  disclaimerText,
  stackHeadband,
}) => {
  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const sectionIds = React.useMemo(
    () =>
      (tableOfContents || []).map(tocCta => tocCta.link.url).filter(filterFalsyValues),
    [tableOfContents],
  );
  const { activeId } = useSectionObserver(sectionIds, {
    observerOptions: {
      // An anchor section is considered in-view when it's halfway up the viewport (50%)
      rootMargin: '50% 0px -50% 0px',
    },
  });

  return (
    <ErrorBoundary
      renderError={({ error }) => (
        <ErrorMessage error={error} module="Freeform Headband" />
      )}
    >
      <HeadbandContainer stackHeadband={stackHeadband} theme="Grey 90">
        <HeadbandTitle title={title} />
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: tableOfContents?.length ? (
              <>
                <TOCItemsDropdown
                  activeId={activeId}
                  ctas={tableOfContents}
                  headbandRef={headbandRef}
                  parentName={title}
                />
                <MobileCTABanner ctas={ctas} title={title} />
              </>
            ) : (
              <HeadbandCtaWithInfo
                parentTitle={title}
                ctas={ctas}
                financingText={financingText}
                disclaimerText={disclaimerText}
              />
            ),
            desktop: (
              <>
                {tableOfContents?.length && (
                  <TOCItems
                    ctas={tableOfContents}
                    headbandRef={headbandRef}
                    parentName={title}
                    activeId={activeId}
                  />
                )}
                <HeadbandCtaWithInfo
                  parentTitle={title}
                  ctas={ctas}
                  financingText={financingText}
                  disclaimerText={disclaimerText}
                />
              </>
            ),
          }}
        />
      </HeadbandContainer>
    </ErrorBoundary>
  );
};

export default Headband;
