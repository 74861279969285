import type { NextRouter } from 'next/router';
import { parsePathToLocationMap } from '@page-builder/modules/ClassesGrid/utils/parsePathToLocationMap';
import { checkHasPaginationSlug, excludePaginationSlug } from './pagination';

export enum QueryParams {
  PAGE = 'page',
}

export type GetPagePathOptions = {
  includeBasePath?: boolean;
  includeQueryParams?: boolean;
  allowedQueryParams?: QueryParams[];
};

/*
 This function helps parsing a router state into a public path. The paths we want
 to expose publicly do not always match the NextJS page routes which is why this
 function exists. It also provides the possibility to include query parameters
 that impact the page content such as pagination (eg.: `?page=2`).
 */
export const getPagePath = (
  router: NextRouter,
  {
    includeBasePath = false,
    includeQueryParams = false,
    allowedQueryParams = Object.values(QueryParams),
  }: GetPagePathOptions = {},
) => {
  const { asPath, basePath } = router;
  const locationMap = parsePathToLocationMap(asPath);
  const rawPath = locationMap.path;
  const completePath = `${includeBasePath && basePath ? basePath : ''}${rawPath}`;
  const publicPath = excludePaginationSlug(completePath);

  if (!includeQueryParams) {
    return publicPath;
  }

  const initialQueryParams = locationMap.queryParams.replace('?', '').split('&');
  const filteredQueryParams = initialQueryParams.filter(queryParam => {
    const queryParamKey = queryParam.split('=')[0];
    return allowedQueryParams.includes(queryParamKey as QueryParams);
  });

  if (
    allowedQueryParams.includes(QueryParams.PAGE) &&
    checkHasPaginationSlug(completePath)
  ) {
    const paginationQueryParam = `page=${completePath.split('/').pop()}`;
    const queryParams = filteredQueryParams.length
      ? `?${filteredQueryParams.join('&')}&${paginationQueryParam}`
      : `?${paginationQueryParam}`;

    return `${publicPath}${queryParams}`;
  }

  const queryParams = filteredQueryParams.length
    ? `?${filteredQueryParams.join('&')}`
    : '';

  return `${publicPath}${queryParams}`;
};
