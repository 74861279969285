import { spacing, BreakpointWidths } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { MuscleGroupScore } from '@peloton/models/PelotonClass';
import { BodyActivityDiagram } from './BodyActivity/BodyActivityDiagram';
import buildBucketByMuscle from './BodyActivity/buildBucketByMuscle';
import { MuscleGroupBreakdown } from './BodyActivity/MuscleGroupBreakdown';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

const BODY_ACTIVITY_DIAGRAM_WIDTH = 188;
const BODY_ACTIVITY_DIAGRAM_HEIGHT = 214;

type Props = {
  muscleGroupLabel: string;
  otherGroupLabel: string;
  primaryMuscleGroupLabel: string;
  secondaryMuscleGroupLabel: string;
  tertiaryMuscleGroupLabel: string;
  muscleGroupScore?: Array<MuscleGroupScore>;
};

export const ClassDetailsBodyActivity: React.FC<React.PropsWithChildren<Props>> = ({
  muscleGroupScore,
  muscleGroupLabel,
  otherGroupLabel,
  primaryMuscleGroupLabel,
  secondaryMuscleGroupLabel,
  tertiaryMuscleGroupLabel,
}) => {
  if (!muscleGroupScore || muscleGroupScore.length < 1) {
    return null;
  }
  return (
    <ClassDetailsCardSection title={muscleGroupLabel}>
      <BodyActivityDiagramWrapper>
        <BodyActivityDiagram
          bucketByMuscle={buildBucketByMuscle(muscleGroupScore)}
          width={BODY_ACTIVITY_DIAGRAM_WIDTH}
          height={BODY_ACTIVITY_DIAGRAM_HEIGHT}
          data-test-id="bodyActivityDiagram"
        ></BodyActivityDiagram>
      </BodyActivityDiagramWrapper>
      <MuscleGroupBreakdown
        muscleGroupScore={muscleGroupScore}
        gap={spacing[16]}
        otherGroupLabel={otherGroupLabel}
        primaryMuscleGroupLabel={primaryMuscleGroupLabel}
        secondaryMuscleGroupLabel={secondaryMuscleGroupLabel}
        tertiaryMuscleGroupLabel={tertiaryMuscleGroupLabel}
      ></MuscleGroupBreakdown>
    </ClassDetailsCardSection>
  );
};

const BodyActivityDiagramWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    display: block;
    margin-left: ${spacing[88]};
  }
`;
