import gql from 'graphql-tag';
import type { Cart } from '@ecomm/cart/models';
import { useMutation } from '@ecomm/graphql/hooks';

// Where the schema would be
// type ProductSelection {
//   product: Slug!
//   selections: [SelectedOption!]!
// }

const addPackageToCartMutation = gql`
  mutation AddPackageToCart(
    $locale: Locale!
    $currency: Currency!
    $slug: Slug!
    $packageId: ID!
    $productSelections: [ProductSelection!]!
    $warrantySelection: ProductSelection!
    $hasTradeIn: Boolean
    $bundleSlug: Slug!
    $upsellBundles: [BundlePayload!]!
    $productOptionIds: [ID]
    $subscriptionId: ID
  ) {
    addPackageToCart(
      locale: $locale
      currency: $currency
      slug: $slug
      packageId: $packageId
      productSelections: $productSelections
      warrantySelection: $warrantySelection
      hasTradeIn: $hasTradeIn
      bundleSlug: $bundleSlug
      upsellIds: $upsellIds
      upsellBundles: $upsellBundles
      productOptionIds: $productOptionIds
      subscriptionId: $subscriptionId
    ) @client
  }
`;

type ProductSelection = {
  product: string;
  selections: {
    attribute: string;
    option: string;
  }[];
};

export type Variables = {
  packageId: string;
  productSelections: ProductSelection[];
  warrantySelection?: ProductSelection;
  locale: string;
  currency: string;
  hasTradeIn: boolean;
  slug: string;
  bundleSlug: string;
  upsellIds: string[];
  upsellBundles: {
    bundleId: string;
    productOptions: string[];
  }[];
  productOptionIds?: string[];
  subscriptionId?: string;
};

export const useAddPackageToCartMutation = () =>
  useMutation<{ addPackageToCart: Cart }, Variables>(addPackageToCartMutation);
