import { useMemo } from 'react';
import {
  Locale,
  toCountryFromLocale,
  toCurrency,
  useLocale,
} from '@peloton/internationalize';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import {
  AccessoryKeys,
  COMMERCETOOLS_AVAILABILITY_FF,
} from '@ecomm/commercetools/constants';
import { useIsCTAvailabilityActive } from '@ecomm/commercetools/hooks/useIsCTAvailabilityActive';
import { mapAllCTAccessories } from '@ecomm/commercetools/mappers';
import { toChannelAvailability } from '@ecomm/commercetools/models/channelAvailability';
import { useProjectionSearchAllAccessoriesQuery } from '@ecomm/commercetools/queries/ProjectionSearchAllAccessories.generated';
import type { ProductProjection } from '@ecomm/commercetools/types.generated';
import type { TypeComponentCtaFields } from '@page-builder/lib/types';

const useUpsellProducts = (productSlugs: TypeComponentCtaFields['productSlug'][]) => {
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const acceptLanguage = [locale, Locale.Default];
  const client = useCommercetoolsClient();

  const isCTAvailabilityActive = useIsCTAvailabilityActive();

  const { loading, error, data } = useProjectionSearchAllAccessoriesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      acceptLanguage,
      currency: toCurrency(country),
      country,
      limit: productSlugs.length,
      sorts: [],
      filters: [
        {
          model: {
            value: {
              path: `variants.attributes.${AccessoryKeys.CHANNEL_AVAILABILITY}.key`,
              values: toChannelAvailability(),
            },
          },
        },
        {
          model: {
            value: {
              path: 'key',
              values: productSlugs as string[],
            },
          },
        },
      ],
    },
    client,
    notifyOnNetworkStatusChange: true,
    skip: productSlugs.length === 0,
  });

  return useMemo(() => {
    const rawResults = (data?.productProjectionSearch?.results ||
      []) as ProductProjection[];
    const { bundles, products } = mapAllCTAccessories(rawResults, locale, {
      [COMMERCETOOLS_AVAILABILITY_FF]: isCTAvailabilityActive,
    });

    return {
      loading,
      error,
      bundles,
      products,
    };
  }, [loading, error, data, locale, isCTAvailabilityActive]);
};

export default useUpsellProducts;
