import {
  Container,
  Media,
  Flex,
  OverlaidChildren,
  spacing,
  Label,
} from '@pelotoncycle/design-system';
import type { MediaImageProps, MediaVideoProps } from '@pelotoncycle/design-system';
import { getGenericTextNodes } from '@pelotoncycle/page-builder';
import type { TypeComponentProductBlock } from '@pelotoncycle/page-builder';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import {
  nameToModuleId,
  toMediaProps,
  DOUBLE_BREAKPOINT_WIDTH_OPTIONS,
} from '@page-builder/utils/helpers';
import { useCheckoutContext } from './CheckoutContext';
import ProductComparisonCards from './ProductComparisonCards';
import ProductComparisonCardsHeader from './ProductComparisonCardsHeader';
import ProductComparisonCtas from './ProductComparisonCtas';
import StickyCTABand from './StickyCTABand/StickyCTABand';
import { useTheme, themeMap, DARK } from './themes';

export const ProductComparisonCardsContainer: React.FC<
  React.PropsWithChildren<TypeComponentProductBlock>
> = ({ fields: { name, panel, cards, annualPromotion, monthlyPromotion } }) => {
  // On the initial page load, the user should be at the top of the page.
  // This is passed into the StartTrialStickyCTA and used to hide the component when the user is at the top of the page.
  const [atTopOfPage, setAtTopOfPage] = useState<boolean>(true);
  const { productCardBackgroundColor, secondaryTextColor, name: themeName } = useTheme();
  const backgroundMedia = panel?.fields.media;
  const mediaProps =
    backgroundMedia &&
    toMediaProps(backgroundMedia, false, DOUBLE_BREAKPOINT_WIDTH_OPTIONS);
  const { eyebrow, support, label, body } = getGenericTextNodes(panel?.fields.text);
  const { trackEvent } = useTracking();
  const { cta, activeCtaRef, shouldShowCTABand } = useCheckoutContext();
  const { width: screenWidth } = useWindowSize();
  const isMediaVideo = mediaProps?.type === 'video';

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY === 0) {
        setAtTopOfPage(true);
      } else {
        setAtTopOfPage(false);
      }
    };
  }, [atTopOfPage]);

  const handleCheckoutClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        href: cta.link,
        parentType: 'ProductBlock:ProductCard',
        parent: `${cta.product} Product Card`,
        unitName: `${cta.product} Checkout CTA`,
        linkName: cta.text,
        linkTo: cta.link,
      },
    });
  };

  return (
    <>
      {themeName === DARK && (
        <StickyCTABand
          cta={cta}
          handleCheckoutClick={handleCheckoutClick}
          shouldShowCTABand={shouldShowCTABand}
        />
      )}
      <Container
        data-test-id="product-comparison-cards-container"
        backgroundColor={productCardBackgroundColor}
        minHeight={{ mobile: '400px', tablet: '500px' }}
        maxHeight="100%"
        position="relative"
        id={nameToModuleId(name)}
      >
        <OverlaidChildren>
          {mediaProps && (
            <StyledMediaContainer
              backgroundColor={productCardBackgroundColor}
              height={{ mobile: '300px', desktop: '400px' }}
              position="relative"
              screenWidth={isMediaVideo ? screenWidth : -1}
            >
              <StyledMedia
                height={isMediaVideo ? 'unset' : '100%'}
                width={isMediaVideo ? '100%' : 'unset'}
                data-test-id={
                  isMediaVideo ? 'product-comparison-video' : 'product-comparison-image'
                }
                media={
                  ({
                    ...mediaProps,
                    'data-test-id': isMediaVideo
                      ? 'product-comparison-video'
                      : 'product-comparison-image',
                    className: isMediaVideo ? 'video-wrapper' : 'image-wrapper',
                  } as unknown) as MediaImageProps | MediaVideoProps
                }
              />
            </StyledMediaContainer>
          )}
          <Container id={panel?.fields.name}>
            {eyebrow && (
              <ProductComparisonCardsHeader
                header={eyebrow}
                eyebrow={label}
                body={body}
              />
            )}
            <Flex
              maxWidth="fit-content"
              margin="0 auto"
              justifyContent="center"
              horizontalPadding={{
                mobile: spacing[16],
                tablet: spacing[32],
                desktop: undefined,
              }}
              verticalPadding={
                eyebrow
                  ? {
                      mobile: `0 ${spacing[24]}`,
                      tablet: `0 ${spacing[32]}`,
                      desktop: `0 ${spacing[40]}`,
                    }
                  : {
                      mobile: `${spacing[32]} ${spacing[24]}`,
                      tablet: `${spacing[64]} ${spacing[32]}`,
                      desktop: `${spacing[80]} ${spacing[40]}`,
                    }
              }
            >
              <ProductComparisonCards
                productCards={cards}
                annualPromotion={annualPromotion}
                monthlyPromotion={monthlyPromotion}
              />
            </Flex>
          </Container>
        </OverlaidChildren>
        <Flex
          flexDirection="column"
          alignItems="center"
          verticalPadding={{
            mobile: `0 ${spacing[48]}`,
            tablet: `0 ${spacing[64]}`,
            desktop: `0 ${spacing[104]}`,
          }}
          horizontalPadding={{ mobile: spacing[16], tablet: 0, desktop: 0 }}
          gap={spacing[24]}
          margin="auto"
        >
          {support && (
            <Label size="extraLarge" textColor={secondaryTextColor} textAlign="center">
              {support}
            </Label>
          )}
          <Container maxWidth="358px">
            <LinkButton
              id="product-comparison-cards-checkout-cta"
              href={cta.link}
              to={cta.link}
              isDisabled={!cta.link}
              onClick={handleCheckoutClick}
              ref={activeCtaRef}
              size="medium"
              width="adaptive"
            >
              {cta.text}
            </LinkButton>
          </Container>
          {panel?.fields.ctas && (
            <ProductComparisonCtas
              ctas={panel.fields.ctas}
              productName={`${cta.product}`}
            />
          )}
        </Flex>
      </Container>
    </>
  );
};

const StyledMediaContainer = styled(Container)<{
  screenWidth: number;
}>`
  position: relative;
  overflow: hidden;

  // target video container div to center video element
  & .video-wrapper {
    ${({ screenWidth }) => (screenWidth > 1080 ? 'top: -100px' : '')};
    ${({ screenWidth }) => (screenWidth <= 425 ? 'height: 100%' : '')};
  }

  // target the pause/play button
  & .video-wrapper > div {
    ${({ screenWidth }) =>
      screenWidth > 1080 ? 'bottom: unset; top: 445px' : 'top: 17px'};
  }

  & .video-wrapper > video {
    height: unset;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      transparent,
      ${themeMap[DARK].productCardBackgroundColor}
    );
  }
`;

const StyledMedia = styled(Media)`
  position: absolute;
  inset: 0;
`;
