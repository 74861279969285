import { grey, red, Flex, List, spacing, Body, Label } from '@pelotoncycle/design-system';
import dynamic from 'next/dynamic';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { TypeComponentGenericText } from '@page-builder/lib/types';
import { useUrgencyMessagingMarkdownValues } from '@page-builder/modules/Overview/utils';
import Markdown from '@page-builder/utils/Markdown';

// needed to work around the SSR build problems around the
// resizeobserver component
const Accordion = dynamic(() => import('./DynamicAccordion'), {
  ssr: false,
});

type Props = {
  items: TypeComponentGenericText[];
  product: string;
};

type MarkdownProps = {
  href: string;
  children: { props: { children: string } }[];
};

type MarkdownRendererProps = {
  content: string;
  linkClickCallback: (
    props: MarkdownProps,
    e: React.MouseEvent<Element, MouseEvent>,
  ) => void;
};

const MarkdownRenderer: React.FC<React.PropsWithChildren<MarkdownRendererProps>> = ({
  content,
  linkClickCallback,
}) => {
  const markdownValues = useUrgencyMessagingMarkdownValues();

  return (
    <Flex flexDirection="column" gap={spacing[8]}>
      <Markdown
        content={content}
        values={markdownValues}
        markdown={{
          renderers: {
            paragraph: ({ node, ...props }) => <Body size="small">{props.children}</Body>,
            emphasis: ({ node, ...props }) => (
              <Body size="small" textColor={red[50]}>
                {props.children}
              </Body>
            ),
            list: ListRenderer,
            link: ({ node, ...props }) => (
              <StyledLink
                variant="body"
                size="medium"
                href={props.href}
                to={props.href}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                  linkClickCallback(props, e)
                }
                children={props.children}
                target="_blank"
              />
            ),
          },
        }}
      />
    </Flex>
  );
};

const AccordionWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  product,
}) => {
  const { trackEvent } = useTracking();

  const handleItemClick = (i: number) => {
    const data = items[i];

    trackEvent({
      event: TrackingEvent.ClickedAccordion,
      properties: {
        href: '',
        parentType: 'Component: Overview',
        parent: `${product} Overview Accordion`,
        unitName: `${product} Overview Accordion Title: ${data.fields.eyebrow || ''}`,
        linkName: data.fields.eyebrow || '',
        linkTo: '',
      },
    });
  };

  const handleMarkdownLinkClick = (
    props: MarkdownProps,
    e: React.MouseEvent<Element, MouseEvent>,
  ) => {
    const { href, children } = props;
    const path = href.split('.com')[1];

    if (href[0] == '#') {
      e.preventDefault();
      e.stopPropagation();

      const jumpScrollElementId = href.split('#')[1];
      const jumpScrollElement = document.getElementById(jumpScrollElementId);
      if (jumpScrollElement) {
        const y = jumpScrollElement.getBoundingClientRect().top + window.scrollY - 48;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }

    trackEvent({
      event: 'Clicked Link',
      properties: {
        href: href,
        parentType: 'Component: Generic Text',
        parent: `${product} Overview Accordion Link`,
        unitName: `${product} Accordion Link Text: ${children[0]?.props.children}`,
        linkName: children[0]?.props.children,
        linkTo: path,
      },
    });
  };

  const accordionItems = items.reduce((acc, { fields: { eyebrow, body } }) => {
    return [
      ...acc,
      {
        title: (
          <Label size="extraLarge" weight="medium">
            {eyebrow || ''}
          </Label>
        ),
        content: body ? (
          <MarkdownRenderer content={body} linkClickCallback={handleMarkdownLinkClick} />
        ) : (
          ''
        ),
      },
    ];
  }, []);

  return (
    <StyledAccordion>
      {accordionItems.length > 0 && (
        <Accordion
          multiple={true}
          narrow={true}
          values={accordionItems}
          track={handleItemClick}
        />
      )}
    </StyledAccordion>
  );
};

type ListProps = {
  children: MarkdownChildren[];
};

type MarkdownChildren = {
  props: {
    children: React.ReactNode;
  };
};

const ListRenderer: React.FC<ListProps> = ({ children }) => {
  // Flattening to remove extra nested <li>s
  const flattenedChildren = children.map(child => child?.props?.children);

  if (flattenedChildren) {
    return <StyledList items={flattenedChildren} size="small" textColor={grey[90]} />;
  }

  return null;
};

const StyledAccordion = styled.div`
  > section {
    padding: 0;
    > ul {
      > li {
        padding-left: ${spacing[16]};
        padding-right: ${spacing[16]};
        max-width: unset;
      }
    }
  }

  p > a {
    margin-top: ${spacing[8]};
  }

  button {
    color: ${grey[90]};
  }

  button:hover {
    color: ${grey[70]};
  }

  path {
    fill: currentColor !important;
  }
`;

const StyledList = styled(List)`
  padding-left: ${spacing[16]};

  li {
    line-height: ${spacing[24]};
    strong {
      font-weight: 400;
    }
  }

  li::marker {
    font-size: 0.7rem;
  }
`;

const StyledLink = styled(Link)`
  > span {
    &:hover {
      color: ${grey[70]};
      transition: all 300ms ease;
    }
  }
  &::after {
    margin-top: 1px;
  }
`;

export default AccordionWrapper;
