import React from 'react';
import { useProductStates } from '@ecomm/product-states/Context';
import {
  bundleTypeToProduct,
  ProductStates,
  toProductBundle,
} from '@ecomm/product-states/models';
import type { BundleType } from '@ecomm/shop/models';

const useRequiresPostalCode = () => {
  const { verifyProductState } = useProductStates();

  return React.useCallback(
    (productBundleType: BundleType) => {
      return verifyProductState(bundleTypeToProduct(toProductBundle(productBundleType)), [
        ProductStates.AvailableRestrictedByPostalCode,
      ]);
    },
    [verifyProductState],
  );
};

export default useRequiresPostalCode;
