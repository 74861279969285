import { Flex, grey, spacing } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles/breakpoints';
import { focusHighlight } from '@ecomm/styles/accessibility';
import NextModal from '../NextModal';

export const StyledModal = styled(NextModal)<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : '1336px')};
  margin-top: ${spacing[48]};
  width: 100%;
  transition: all 500ms ease-in-out;
  @media screen and (min-width: ${BreakpointWidth.desktopLarge}px) and (max-width: 1400px) {
    margin-inline: ${spacing[16]};
  }
`;

export const ModalTransition = createGlobalStyle`
  .ReactModalPortal .ReactModal__Content.lifted-modal-content  {
    position: relative;
    bottom: -100vh;
  };
  
  .ReactModalPortal .ReactModal__Content--after-open.lifted-modal-content {
    bottom: 0;
  };

  .ReactModalPortal .ReactModal__Content .lifted-modal__sections {
    opacity: 0;
    transition: opacity 300ms 450ms ease-in-out;
  }
  
  .ReactModalPortal .ReactModal__Content--after-open .lifted-modal__sections {
    opacity: 1;
  }
  `;

export const ModalContent = styled.div<{
  backgroundColor: string;
  hasExtendedBottomMargin?: boolean;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${spacing[12]} ${spacing[12]} 0 0;

  ${media.desktopLarge<{ hasExtendedBottomMargin?: boolean }>`
    border-radius: ${spacing[12]};
    margin-bottom: ${({ hasExtendedBottomMargin }) =>
      hasExtendedBottomMargin ? '25vh' : spacing[48]};  
  `}
`;

export const StickyHeader = styled(Flex)<{ headerColor: string }>`
  z-index: 1000;
  background-color: ${({ headerColor }) => rgba(headerColor, 0.9)};
  backdrop-filter: blur(${spacing[12]});
  top: 0;
  height: ${spacing[56]};
  border-bottom: 1px solid ${grey[70]};
  border-radius: ${spacing[12]} ${spacing[12]} 0 0;
  ${media.tabletLarge`
    height: ${spacing[64]};
  `}
`;

export const CloseButton = styled.button`
  display: flex;
  ${focusHighlight}
`;
