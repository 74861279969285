import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { appStorePath, playStorePath } from '@peloton/links/storePaths';
import { app } from '@peloton/links/wwwPaths';
import useIsAppCtaExperimentActive from '@ecomm/feature-toggle/AppCta';
import usePlatform, { Platform } from './usePlatform';
import useTrackingParams from './useTrackingParams';

const platformMapping = {
  [Platform.Android]: [playStorePath, 'Google Play Store'],
  [Platform.iOS]: [appStorePath, 'Apple App Store'],
  [Platform.Web]: [app, '/app'],
};

const useAppDownloadRedirect = () => {
  const { query, isReady } = useRouter();
  const isAppCTAExperimentActive = useIsAppCtaExperimentActive();
  const { hasSubscription, eventType } = useTrackingParams(query);
  const platform = usePlatform();
  const { trackEvent } = useTracking();

  useEffect(() => {
    // isReady will be true upon hydration and when the query params are available
    // platform is based on the user agent, so will not be available until hydration
    if (isReady && platform) {
      const [url, linkTo] = platformMapping[platform];

      trackEvent({
        event: eventType,
        properties: {
          linkTo,
          hasSubscription,
        },
      });

      const shouldRedirect =
        isAppCTAExperimentActive !== null &&
        !(isAppCTAExperimentActive && platform === Platform.Web);
      if (shouldRedirect) {
        window.location.assign(url);
      }
    }
  }, [eventType, hasSubscription, isReady, platform, trackEvent]);
};

export default useAppDownloadRedirect;
