import { black, spacing } from '@pelotoncycle/design-system';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';

export const DefaultList = styled.li`
  flex: 0 0 calc(50% - 16px);

  ${media.tablet`
    flex: 0 0 calc(50% - ${spacing[24]});
  `}

  ${media.desktopLarge`
    flex: 0 0 calc(25% - ${spacing[24]});
  `}
`;

export const containerHover = css`
  &:hover {
    img {
      transform: scale(1.03);
      opacity: 0.8;
    }
    svg,
    span {
      opacity: 0.8;
    }

    svg {
      .triangle {
        transition: fill 500ms ease;
        fill: ${black};
        stroke: ${black};
      }
    }
  }
`;
