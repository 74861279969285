import {
  Flex,
  Eyebrow,
  grey,
  spacing,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { toSlug } from '../utils/toSlug';

type Props = {
  namespace?: string;
  title?: string;
  isTextOnly?: boolean;
  withBorder?: boolean;
};

export const ClassDetailsCardSection: React.FC<React.PropsWithChildren<Props>> = ({
  namespace,
  title,
  isTextOnly,
  children,
  withBorder = true,
}) => {
  return (
    <Section withBorder={withBorder}>
      <Flex
        gap={isTextOnly ? spacing[16] : { mobile: spacing[24], desktop: spacing[32] }}
        flexDirection="column"
      >
        {title && (
          <Eyebrow
            size="small"
            textColor={grey[50]}
            id={(namespace || '') + toSlug(title)}
          >
            {title}
          </Eyebrow>
        )}
        <div>{children}</div>
      </Flex>
    </Section>
  );
};

const Section = styled.section<{ withBorder: boolean }>`
  padding: ${spacing[24]} 0;
  margin: 0 ${spacing[16]};

  ${media.tablet`
    margin: 0 ${spacing[32]};
  `}

  border-top: ${props =>
    props.withBorder ? `1px solid ${rgba(grey[70], 0.4)}` : 'none'};

  @media (min-width: ${BreakpointWidths.tablet}px) {
    padding: ${spacing[32]} 0;
  }
`;
