import { spacing, grey, Label, Flex } from '@pelotoncycle/design-system';
import type { FlexProps } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import type { MuscleGroupScore } from '@peloton/models/PelotonClass';
import { precisionRound } from '@peloton/numbers';
import { toSlug } from '../../utils/toSlug';
import { buildMuscleGroupBreakdownRows } from './buildMuscleGroupBreakdowns';
import { MuscleGroupBreakdownBucket } from './MuscleGroupBreakdownBucket';

const MAX_MUSCLE_GROUP_BREAKDOWN_ROWS = 6;
const BUCKET_ICON_HEIGHT_PIXELS = 9;

const groupAsOther = (index: number): boolean => {
  return index >= MAX_MUSCLE_GROUP_BREAKDOWN_ROWS - 1;
};

type Props = {
  muscleGroupScore: Array<MuscleGroupScore>;
  gap?: FlexProps['gap'];
  otherGroupLabel: string;
  primaryMuscleGroupLabel: string;
  secondaryMuscleGroupLabel: string;
  tertiaryMuscleGroupLabel: string;
};

export const MuscleGroupBreakdown: React.FC<React.PropsWithChildren<Props>> = ({
  muscleGroupScore,
  gap = spacing[12],
  otherGroupLabel,
  primaryMuscleGroupLabel,
  secondaryMuscleGroupLabel,
  tertiaryMuscleGroupLabel,
}) => {
  const muscleGroupBreakdowns = buildMuscleGroupBreakdownRows(
    muscleGroupScore,
    MAX_MUSCLE_GROUP_BREAKDOWN_ROWS,
  );
  const locale = useLocale();

  return (
    <MuscleGroupBreakdownTable>
      {muscleGroupBreakdowns.map(({ muscleGroup, percentage, bucket }, index) => {
        const displayName = groupAsOther(index)
          ? otherGroupLabel
          : muscleGroupScore[index].displayName;
        const rowNamespace = toSlug(displayName) + '-';

        return (
          <MuscleGroupBreakdownRow
            key={muscleGroup}
            gap={gap}
            data-test-id="muscleGroupBreakdownRow"
          >
            <MuscleGroupBreakdownName
              size="large"
              data-test-id="muscleGroupBreakdownName"
            >
              {displayName}
            </MuscleGroupBreakdownName>
            <MuscleGroupBreakdownPercentage data-test-id="muscleGroupBreakdownPercentage">
              {percentage
                ? `${precisionRound(percentage).toLocaleString(locale)}%`
                : null}
            </MuscleGroupBreakdownPercentage>
            <MuscleGroupBreakdownBucketCell data-test-id="muscleGroupBreakdownBucketCell">
              {bucket ? (
                <MuscleGroupBreakdownBucket
                  namespace={rowNamespace}
                  bucket={bucket}
                  height={BUCKET_ICON_HEIGHT_PIXELS}
                  primaryMuscleGroupLabel={primaryMuscleGroupLabel}
                  secondaryMuscleGroupLabel={secondaryMuscleGroupLabel}
                  tertiaryMuscleGroupLabel={tertiaryMuscleGroupLabel}
                />
              ) : null}
            </MuscleGroupBreakdownBucketCell>
          </MuscleGroupBreakdownRow>
        );
      })}
    </MuscleGroupBreakdownTable>
  );
};

const MuscleGroupBreakdownTable = styled.div`
  background-color: inherit;
`;

const MuscleGroupBreakdownRow = styled(Flex)`
  height: 48px;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap} 0;
  background-color: inherit;
  border-bottom: 1px solid ${rgba(grey[70], 0.4)};
`;

const MuscleGroupBreakdownName = styled(Label)`
  flex-grow: 1;
  color: white;
`;

const MuscleGroupBreakdownPercentage = styled.div`
  text-align: right;
  padding: 0 ${spacing[8]} 0;
  color: ${grey[50]};
`;

const MuscleGroupBreakdownBucketCell = styled.div`
  align-self: center;
  background-color: inherit;
  width: 22px;
`;
