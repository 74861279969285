import {
  Button,
  Eyebrow,
  Flex,
  FlexChild,
  Support,
  Container,
  spacing,
  ControlledInput,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import Formatters from '@peloton/forms/formatters';
import Normalizers from '@peloton/forms/normalizers';
import { getPostalCodeRegexForLocale, useLocale } from '@peloton/internationalize';
import { usePostalCodeContext } from '@ecomm/postal-code/PostalCodeContext';
import type { TypeComponentFormFields } from '@page-builder/lib/types';
import WithMarkdown from '@page-builder/modules/WithMarkdown/WithMarkdown';
import {
  ctaExtractData,
  getTextFieldsWithRequiredKeys,
} from '@page-builder/utils/helpers';

type Props = {
  isLoadingEligibility: boolean;
  postalCodeInputFields?: TypeComponentFormFields;
  renderHeadings?: boolean;
  isHidden?: boolean;
  onFormSubmit?: () => void;
  hasError?: boolean;
};

/**
 * Renders the form input for users to enter their postal code when product availability is restricted by zip.
 */
const PostalCodeInput = ({
  isLoadingEligibility,
  postalCodeInputFields,
  renderHeadings,
  isHidden,
  onFormSubmit,
  hasError,
}: Props) => {
  const {
    postalCode: defaultPostalCode,
    setPostalCode: setSavedPostalCode,
  } = usePostalCodeContext();
  // We need this local state because React doesn't like us changing the input from an uncontrolled component to a
  // controlled one if we set defaultValue instead of value
  const [postalCode, setPostalCode] = useState(defaultPostalCode);
  const [hasInvalidZip, setHasInvalidZip] = useState(false);
  const locale = useLocale();

  // Reset form when it goes from hidden -> visible
  React.useEffect(() => {
    if (!isHidden) {
      setPostalCode(defaultPostalCode);
      setHasInvalidZip(false);
    }
  }, [isHidden, defaultPostalCode]);

  if (!postalCodeInputFields) return null;

  const { text, formFields, submit, failureMessage } = postalCodeInputFields;
  const { eyebrow, support } = getTextFieldsWithRequiredKeys(['eyebrow'], text);
  const inputField = formFields[0].fields;
  const { text: submitButtonText, variant: submitButtonVariant } = ctaExtractData(submit);

  const postalRegExp = getPostalCodeRegexForLocale(locale);

  const toNormalizedZip = Normalizers.zipCode[locale];
  const toFormattedZip = Formatters.zipCode[locale];

  const isValidZip = (value: string) => {
    return postalRegExp.test(toNormalizedZip(value));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidZip(postalCode)) {
      setHasInvalidZip(true);
      return;
    }

    onFormSubmit?.();
    setSavedPostalCode(postalCode);
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const newPostalCode = e.currentTarget.value;
    if (isValidZip(newPostalCode)) {
      setHasInvalidZip(false);
    }
    // Make sure we're always storing the normalized (unformatted) zip code in state
    setPostalCode(toNormalizedZip(newPostalCode));
  };

  return (
    <Form onSubmit={onSubmit}>
      {renderHeadings && (
        <Flex
          flexDirection="column"
          verticalMargin={`${spacing[24]} ${spacing[16]}`}
          gap={spacing[8]}
        >
          <Eyebrow size="medium">{eyebrow && <WithMarkdown text={eyebrow} />}</Eyebrow>
          <Support size="large">{support && <WithMarkdown text={support} />}</Support>
        </Flex>
      )}
      <Flex gap={spacing[8]} flexDirection={{ mobile: 'column', tablet: 'row' }}>
        <FlexChild flexGrow={1}>
          <ControlledInput
            theme="light"
            data-test-id="restricted-by-zip"
            label={inputField.label}
            name={inputField.name}
            // Format the zip code before passing it to the input so that it displays correctly
            value={toFormattedZip(postalCode)}
            onInput={onChange}
            required={inputField.required}
            disabled={isHidden}
            autoComplete={`billing postal-code`}
            errorMessage={hasError || hasInvalidZip ? failureMessage : undefined}
          />
        </FlexChild>
        <Container width={{ tablet: '123px' }}>
          <Button
            type="submit"
            color="dark"
            isLoading={isLoadingEligibility}
            variant={submitButtonVariant}
            isDisabled={isHidden}
            width="adaptive"
          >
            {submitButtonText}
          </Button>
        </Container>
      </Flex>
    </Form>
  );
};

const Form = styled.form`
  max-width: 400px;
`;

export default PostalCodeInput;
