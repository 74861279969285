import { AccessoryKeys } from '../constants';
import type { ProductVariant } from '../types.generated';

export const sortVariantsByOrder = (variants: ProductVariant[]) => {
  const sortVariantAttribute = (variant: ProductVariant) =>
    variant.attributesRaw.find(attribute => attribute.name === AccessoryKeys.SORT_ORDER);

  return variants.sort(
    (a, b) => sortVariantAttribute(a)?.value - sortVariantAttribute(b)?.value,
  );
};
