import { grey, spacing, white } from '@pelotoncycle/design-system';
import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { BreakpointWidth, media } from '@peloton/styles';
import { TARGET_IDS } from '@acme-ui/global/skip-links/SkipLinkToMain';
import { useMicroCopy } from '@content/client/microCopy';
import { useLoadMoreContext } from '@ecomm/pagination/LoadMore/LoadMoreContext';

const HASH_TARGET = `#${TARGET_IDS.main}`;

const scrollToTarget = (
  e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
) => {
  const targetElement = document.querySelector<HTMLElement>(HASH_TARGET);

  if (!targetElement) {
    return;
  }

  e.preventDefault();

  targetElement.focus({
    preventScroll: true,
  });

  const top = targetElement.getBoundingClientRect().top;
  scrollTo({
    top,
    behavior: 'smooth',
  });
};

const HideWebChatStyles = createGlobalStyle`
  #drift-frame-controller {
    display: none !important;
  }
`;

export const ScrollToTopButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { highestLoadedPage, lowestLoadedPage } = useLoadMoreContext();
  const hasPaginationChanged = highestLoadedPage !== lowestLoadedPage;
  const [isInitialViewportPassed, setIsInitialViewportPassed] = useState(true);
  const backToTopButtonLabel = useMicroCopy('backToTopButtonLabel');

  useEffect(() => {
    if (!hasPaginationChanged) {
      return;
    }

    const scrollHandler = throttle(() => {
      if (scrollY > innerHeight) {
        setIsInitialViewportPassed(true);
      } else {
        setIsInitialViewportPassed(false);
      }
    }, 250);

    scrollHandler();
    addEventListener('scroll', scrollHandler);
    return () => removeEventListener('scroll', scrollHandler);
  }, [hasPaginationChanged]);

  if (!hasPaginationChanged) {
    return null;
  }

  return (
    <>
      <HideWebChatStyles />
      <StyledWrapper isVisible={isInitialViewportPassed}>
        <StyledLinkButton
          size="small"
          color="dark"
          icon="circledChevron"
          onClick={scrollToTarget}
          href={HASH_TARGET}
        >
          {backToTopButtonLabel}
        </StyledLinkButton>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div<{ isVisible: boolean }>`
  margin: ${spacing[16]};
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  ${({ isVisible }) =>
    isVisible
      ? `animation: slide-in 0.4s ease-out forwards;`
      : `
        animation: slide-out 0.4s ease-out forwards;
        pointer-events: none;
      `}

  ${media.tablet`
    margin: ${spacing[32]};
  `}

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }
`;

const StyledLinkButton = styled(LinkButton)`
  width: auto !important;
  padding: ${spacing[8]} ${spacing[16]} !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: ${white};
  background-color: ${grey['70']} !important;
  border: none !important;

  & svg {
    width: ${spacing[16]};
    height: ${spacing[16]};
    margin-right: ${spacing[8]} !important;
  }

  @media screen and (max-width: ${BreakpointWidth.smallScreen - 1}px) {
    width: ${66 / 16}rem !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${spacing[8]};
    font-size: 12px !important;

    & svg {
      margin: 0 !important;
    }
  }
`;
