import type { ClassPageLabels } from '@page-builder/modules/ClassPage/utils/mapClassPageLabelsFromContentfulToProps';

export const mapDisciplineToClassLabel = ({
  classPageLabels,
  disciplineDisplayName,
}: {
  classPageLabels: ClassPageLabels;
  disciplineDisplayName: string;
}): string => {
  switch (disciplineDisplayName) {
    case 'Cardio':
      return classPageLabels.cardioClassLabel;
    case 'Cycling':
      return classPageLabels.cyclingClassLabel;
    case 'Meditation':
      return classPageLabels.meditationClassLabel;
    case 'Outdoor':
      return classPageLabels.outdoorClassLabel;
    case 'Rowing':
    case 'Rudern':
      return classPageLabels.rowingClassLabel;
    case 'Running':
    case 'Laufen':
      return classPageLabels.runningClassLabel;
    case 'Strength':
    case 'Kraft':
      return classPageLabels.strengthClassLabel;
    case 'Stretching':
      return classPageLabels.stretchingClassLabel;
    case 'Yoga':
      return classPageLabels.yogaClassLabel;
    case 'Walking':
      return classPageLabels.walkingClassLabel;
    default:
      return disciplineDisplayName;
  }
};
