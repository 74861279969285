import React, { useMemo } from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useColorSelectionContext } from '@page-builder/modules/Overview/ColorSelectionContext';
import CommercetoolsCFUProvider from '@page-builder/modules/Overview/CommercetoolsCFUContext';
import { colorBikes } from '@page-builder/modules/Overview/constants';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import GetPackageBySlugProvider from '@page-builder/modules/Overview/GetPackageBySlugQuery';
import {
  CFUPackageContext,
  useCFUPackageContext,
} from '@page-builder/modules/Overview/useCFUPackageContext';
import {
  isValidCommercetoolsProductSlug,
  toPrimaryDrawerCta,
} from '@page-builder/modules/Overview/utils';

export const CFUProvider: React.FC<React.PropsWithChildren<{ slug: string }>> = ({
  children,
  slug,
}) => {
  const { shouldUseCTProvider } = useCFUPackageContext();
  const Provider = shouldUseCTProvider
    ? CommercetoolsCFUProvider
    : GetPackageBySlugProvider;

  return <Provider slug={slug}>{children}</Provider>;
};

const CFUPackageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { activeShopDrawer } = useDrawerSelectionContext();

  const addToCartCta = activeShopDrawer && toPrimaryDrawerCta(activeShopDrawer);
  const { activeColorSlug } = useColorSelectionContext();
  const productSlug = activeColorSlug || addToCartCta?.fields.productSlug || '';

  const isToggleActive = useIsToggleActive();

  const isCommerceToolsNewPDPToggleActive = isToggleActive('commercetoolsNewPDP');
  // Temporary fallback to ensure CT stays active on Colored Bikes PDP. Can be removed when 'commercetoolsNewPDP' is active on prod.
  const isCTActiveOnColorBikesPDP = Boolean(
    isToggleActive('commercetoolsColoredBikes') && colorBikes[productSlug],
  );

  const isCTRentalsEnabled = isToggleActive('commercetoolsRentalsPDP');

  const shouldUseCTProvider =
    isValidCommercetoolsProductSlug(productSlug, isCTRentalsEnabled) &&
    (isCommerceToolsNewPDPToggleActive || isCTActiveOnColorBikesPDP);

  const value = useMemo(() => {
    return {
      shouldUseCTProvider,
    };
  }, [shouldUseCTProvider]);

  return (
    <CFUPackageContext.Provider value={value}>
      <CFUProvider slug={productSlug}>{children}</CFUProvider>
    </CFUPackageContext.Provider>
  );
};

export default CFUPackageProvider;
