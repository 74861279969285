import { Button, Container, spacing, grey } from '@pelotoncycle/design-system';
import type * as Contentful from 'contentful';
import React from 'react';
import styled from 'styled-components';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import type {
  TypeComponentCtaFields,
  TypeComponentHeadband,
} from '@page-builder/lib/types';

import Headband from '@page-builder/modules/Headband';
import { webScrollHeadbandStyles } from '@page-builder/modules/Headband/styles';

type HistoryReportHeadbandProps = {
  top?: string;
  bottom?: string;
};

export interface Props {
  cta: Contentful.Entry<TypeComponentCtaFields> | undefined;
  formInView: boolean;
  entry: IntersectionObserverEntry | undefined;
}

const HistoryReportHeadband: React.FC<React.PropsWithChildren<Props>> = ({
  cta,
  formInView,
  entry,
}) => {
  const shouldShowHeadband = !formInView;

  const headband: TypeComponentHeadband = ({
    sys: cta?.sys,
    fields: {
      name: cta?.fields.name,
      title: cta?.fields.name,
    },
  } as unknown) as TypeComponentHeadband;

  return (
    <BreakpointSwitchCSS
      breakpoints={{
        mobile: (
          <MobileHistoryBandWrapper
            top={shouldShowHeadband ? '-1px' : '-100px'}
            aria-hidden={!shouldShowHeadband}
            data-test-id="history-report-headband-mobile"
          >
            <Headband {...headband}>
              {cta?.fields.text ? (
                <StyledLinkButton>
                  <Button
                    size="small"
                    width="adjustable"
                    margin={`${spacing[8]} 0;`}
                    onClick={() =>
                      entry?.target?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }
                    data-test-id="history-report-headband-cta"
                  >
                    {cta.fields.text}
                  </Button>
                </StyledLinkButton>
              ) : null}
            </Headband>
          </MobileHistoryBandWrapper>
        ),
        desktop: (
          <HeadHistoryBandWrapper
            top={shouldShowHeadband ? '0' : '-100px'}
            aria-hidden={!shouldShowHeadband}
            data-test-id="history-report-headband-desktop"
          >
            <Headband {...headband}>
              {cta?.fields.text ? (
                <Button
                  size="small"
                  width="adjustable"
                  margin={`${spacing[8]} 0;`}
                  onClick={() =>
                    entry?.target?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                  data-test-id="history-report-headband-cta"
                >
                  {cta.fields.text}
                </Button>
              ) : null}
            </Headband>
          </HeadHistoryBandWrapper>
        ),
      }}
    />
  );
};

export default HistoryReportHeadband;

const HeadHistoryBandWrapper = styled(Container)<HistoryReportHeadbandProps>`
  ${webScrollHeadbandStyles}
`;

const MobileHistoryBandWrapper = styled(Container)<HistoryReportHeadbandProps>`
  ${webScrollHeadbandStyles}
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  border-top: 1px solid ${grey[40]};

  button {
    opacity: 1;
  }
  h2 {
    overflow: visible;
  }
`;

const StyledLinkButton = styled.div`
  flex-shrink: 0;
`;
