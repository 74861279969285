import type {
  ShopConfiguration,
  useToShopConfiguration,
} from '@ecomm/shop-configuration';
import type { ProductPackageType } from '../ShopContext';
import type {
  AccessoryWithMetaData,
  AccessoryVariant,
  AccessoryAttribute,
  Accessory,
  ConfigurableProductsMap,
  Slug,
} from './types';

const buildAccessoryMetadata = (
  accessory: AccessoryWithMetaData,
): AccessoryWithMetaData => {
  const attributeSlugToVariant = {} as Record<string, AccessoryVariant>;
  const variantSlugToAccessory = {} as Record<string, AccessoryAttribute>;

  const options = accessory.attributes[0].options;

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    for (let j = 0; j < accessory.variants.length; j++) {
      const variant = accessory.variants[j];

      if (option.slug === variant.configurations[0].option) {
        attributeSlugToVariant[option.slug] = variant;
        variantSlugToAccessory[variant.slug] = option;
      }
    }
  }

  accessory.occurrence = 1;
  accessory.attributeSlugToVariant = attributeSlugToVariant;
  accessory.variantSlugToAccessory = variantSlugToAccessory;
  return accessory;
};

const productIsConfigurable = (accessory: Accessory): boolean =>
  accessory.variants.length > 1;

const productIsNotConfigurable = (accessory: Accessory): boolean =>
  accessory.variants.length === 1;

const mergeProductDataWithConfiguration = (
  product: ProductPackageType,
  toShopConfiguration: (slug: Slug, bundleSlug: Slug) => ShopConfiguration,
) => (accessory: Accessory): Accessory & ShopConfiguration => ({
  ...accessory,
  ...toShopConfiguration(accessory.slug, product!.slug),
});

const buildConfigurableProduct = (map: ConfigurableProductsMap, accessory: Accessory) => {
  if (map[accessory.slug]) {
    map[accessory.slug].occurrence += 1;
    return map;
  }
  return {
    ...map,
    [accessory.slug]: buildAccessoryMetadata(accessory as AccessoryWithMetaData),
  };
};

/**
 * This takes in product data, merges ShopConfiguration per
 * product and returns a object keyed by product slug.
 */
export const buildConfigurableProducts = (
  product: ProductPackageType,
  toShopConfigurationFn: ReturnType<typeof useToShopConfiguration>,
): ConfigurableProductsMap => {
  return product!.accessories
    .filter(productIsConfigurable)
    .map(mergeProductDataWithConfiguration(product, toShopConfigurationFn))
    .reduce(buildConfigurableProduct, {});
};

export const buildNonConfigurableProducts = (product: ProductPackageType) =>
  product!.accessories.filter(productIsNotConfigurable);
