export const PRODUCT_INTEREST = {
  tagPrefix: 'productInterest',
  queryParamName: 'product-interest',
  rent: 'rent',
  new: 'new',
  refurb: 'refurbished',
};

export const DRAWER_TO_ROUTE_MATCHER = 'routeMatcher';

export const DYNAMIC_DRAWERS_SLUGS = {
  '/shop/opc/bike/rent': 1,
  '/shop/opc/bike-plus/rent': 1,
  '/shop/refurbished/bike/buy': 1,
  '/shop/refurbished/bike-plus/buy': 1,
};

export const ROUTE_PATTERNS = {
  rent: '/shop/opc',
  refurb: '/shop/refurbished',
  refurbishedBuy: /^\/shop\/refurbished\/.+\/buy$/,
};

export const DRAWER_RIBBON_TAG = 'pdpDrawerRibbon';
