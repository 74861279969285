import React from 'react';
import { Spinner } from '@ecomm/spinner';
import BannerContainer from './components/BannerContainer';

const Loader = () => (
  <BannerContainer theme="red">
    <Spinner />
  </BannerContainer>
);

export default Loader;
