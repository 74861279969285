import type { Equipment, Class } from '@peloton/models/PelotonClass';

export const mapEquipmentTags = (
  equipmentTags: string[] | null,
): Class['equipmentTags'] => {
  return (
    equipmentTags?.map((equipmentTag: string) => ({
      name: equipmentTag,
      slug: equipmentTag as Equipment['slug'],
    })) || null
  );
};
