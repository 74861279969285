import { SingleSelect, Container } from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextFields } from '@pelotoncycle/page-builder';
import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { useMicroCopy } from '@content/client/microCopy';
import type {
  AccessoryCommerceTools,
  ProductItemOfBundle,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import { HEADER_HEIGHT_DESKTOP_LARGE } from '@page-builder/modules/Headband/constants';
import { TRANSITION_SETTINGS } from '@page-builder/modules/Overview/animations';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import type { Props as UpsellProps } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import { useUpsellAccessoryContext } from '@page-builder/modules/Overview/UpsellAccessoryContext';
import {
  isConfigurable,
  getUpsellAccessoryVariant,
  allBundleProductsHaveVariants,
  getBundleVariantsWithSelection,
} from '@page-builder/modules/Overview/utils';

type Setter<V> = Dispatch<SetStateAction<V>>;

type Props = {
  accessory: AccessoryCommerceTools | ProductItemOfBundle;
  dropdownSupportText: TypeComponentGenericTextFields['support'];
  setAccessoryVariant: UpsellProps['setVariantForAccessory'];
  variantsForMainAccessory?: Record<string, VariantCommerceTools | undefined>;
  isAnimating: boolean;
  showErrorMessage: boolean;
  setShowErrorMessage: Setter<boolean>;
  onClickAdditions?: Setter<boolean>;
  bundleAccessory?: BundleAccessoryType;
  indexOfProduct: number;
};

export const AccessoryOptions: React.FC<React.PropsWithChildren<Props>> = ({
  dropdownSupportText = '',
  accessory,
  setAccessoryVariant,
  variantsForMainAccessory,
  isAnimating,
  showErrorMessage,
  setShowErrorMessage,
  onClickAdditions,
  bundleAccessory,
  indexOfProduct,
}) => {
  const upsellRequiredCopy = useMicroCopy('upsellRequiredField');
  const { shouldFocusError, setShouldFocusError } = useShopContext();
  const { upsellAccessoriesSelected } = useUpsellAccessoryContext();
  const containerRef = React.useRef<HTMLElement>(null);
  const { subHeader } = React.useContext(GlobalReferenceContext);
  const ERROR_SCROLL_OFFSET = 216;
  React.useEffect(() => {
    if (shouldFocusError && isConfigurable(accessory)) {
      const { top } = containerRef.current!.getBoundingClientRect();

      const headbandHeight = subHeader?.height ?? HEADER_HEIGHT_DESKTOP_LARGE;
      // We need to add the vertical scroll position because the top returned by getBoundingClientRect is within the
      // viewport, not the entire page
      const elementTop = top + window.scrollY;

      // We subtract the headband height because it's a sticky element and doesn't take up space, and add an offset
      // so that the error message doesn't end up touching the headband
      const newScrollPosition = elementTop - headbandHeight - ERROR_SCROLL_OFFSET;

      window.scrollTo({
        top: newScrollPosition,
        behavior: 'smooth',
      });
    }
  }, [accessory, shouldFocusError, subHeader]);

  if (!isConfigurable(accessory)) return null;

  const accessoryKey = bundleAccessory
    ? accessory.slug + '-' + indexOfProduct.toString()
    : accessory.slug;

  const selectionForCurrentProduct =
    variantsForMainAccessory?.[accessoryKey]?.configurations[0]?.option;
  const selectedSlugsForMainAccessory = Object.keys(variantsForMainAccessory || {}).map(
    key => variantsForMainAccessory?.[key]?.configurations[0]?.option,
  );

  const items = accessory.attributes[0].options.map(({ name, slug }) => {
    const isSelectedForCurrentProduct = selectionForCurrentProduct === slug;
    const isSelected = selectedSlugsForMainAccessory.includes(slug);
    const isDisabled = isSelected && !isSelectedForCurrentProduct;

    return {
      option: name,
      value: slug,
      disabled: isDisabled,
    };
  });

  return (
    <StyledContainer ref={containerRef}>
      <SingleSelect
        key={`${accessory.attributes[0].name}-${indexOfProduct}`}
        data-test-id="accessory-configuration-dropdown"
        items={items}
        label={dropdownSupportText}
        theme="light"
        required
        disabled={isAnimating}
        errorMessage={
          showErrorMessage &&
          !variantsForMainAccessory?.[accessoryKey]?.configurations[0]?.option
            ? upsellRequiredCopy
            : ''
        }
        handleSelectedItemChange={(selectedAccessory: {
          option: string;
          value: string;
        }) => {
          const currentVariant = selectedAccessory?.option
            ? getUpsellAccessoryVariant(accessory, selectedAccessory.option)
            : undefined;

          setShouldFocusError(
            upsellAccessoriesSelected.includes(accessory.slug) && !currentVariant,
          );

          if (bundleAccessory) {
            const currentBundleVariantsWithSelection = getBundleVariantsWithSelection(
              bundleAccessory,
              accessory,
              indexOfProduct.toString(),
              selectedAccessory?.option,
              variantsForMainAccessory,
            );
            const isBundleConfigured = allBundleProductsHaveVariants(
              bundleAccessory,
              currentBundleVariantsWithSelection,
            );

            setAccessoryVariant(currentVariant, accessory, indexOfProduct.toString());
            onClickAdditions && isBundleConfigured && onClickAdditions(false);
          } else {
            setAccessoryVariant(currentVariant);
            setShowErrorMessage(!selectedAccessory?.option);
            onClickAdditions && onClickAdditions(false);
          }
        }}
        selectedItem={selectionForCurrentProduct}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  // targets the dropdown, configuration text, and dropdown arrow icon
  div,
  p,
  path {
    transition: ${TRANSITION_SETTINGS};
  }
`;
