import type * as Contentful from 'contentful';
import React from 'react';
import type { TypeComponentFormElement, TypeQuizQuestion } from '@page-builder/lib/types';
import { useSelectedQuestionGroups } from '../utils/helpers';
import { useAnswersForQuestion } from '../utils/progress';
import { getQuizQuestionSlug } from '../utils/questions';
import { RadioGroupContainer } from './RadioGroupContainer';

type Props = {
  options: TypeComponentFormElement[];
  questionName: Contentful.EntryFields.Symbol;
  getsGroupsFrom: TypeQuizQuestion;
};

const GroupQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  questionName,
  options,
  getsGroupsFrom,
}) => {
  const { groupAnswers, saveGroupAnswer } = useAnswersForQuestion(questionName);
  const groups = useSelectedQuestionGroups(getQuizQuestionSlug(getsGroupsFrom), options);
  const optionObjs = options.map(({ fields }) => {
    return fields?.items?.map(item => {
      const [title, value] = item.split(':');
      return { title, value };
    });
  });

  return (
    <>
      {groups.map(({ group, title }, index) => (
        <RadioGroupContainer
          key={group}
          group={group}
          answers={groupAnswers[group] || []}
          options={optionObjs[index]!}
          saveGroupAnswer={saveGroupAnswer}
        >
          {title}
        </RadioGroupContainer>
      ))}
    </>
  );
};

export default GroupQuestion;
