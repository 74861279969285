import { BreakpointWidths, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { a11yOutlineStyles } from '@peloton/accessibility/styles';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles/breakpoints';
import useBannerReference from '@acme-ui/global/hooks/useBannerReference';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import EcommBanner, {
  CtaText,
  themedBannerLayoutStyles,
} from '@ecomm/cms-promo-banner/EcommBanner';
import type { Theme } from '@ecomm/cms-promo-banner/themes';
import { THEMES } from '@ecomm/cms-promo-banner/themes';
import type { HybridBanner } from './utils';

export type Props = Omit<HybridBanner, 'text'> & {
  text: JSX.Element | string;
};

const SingleCtaBanner: React.FC<React.PropsWithChildren<Props>> = ({
  links,
  name,
  text,
  theme = 'white',
}) => {
  const bannerRef = useBannerReference();
  const { trackLinkClick } = useTrackLinkClick();

  if (!links || !links.length) {
    return null;
  }

  const { url, text: ctaText, name: ctaName } = links[0];

  if (!url) {
    throw new Error('Please provide a valid url');
  }

  return (
    <StyledLink
      data-test-id={`${name}Url`}
      data-element-id="banner"
      variant="body"
      href={url || ''}
      theme={theme}
      onClick={(e: { stopPropagation: () => void }) => {
        trackLinkClick({
          href: url || '',
          parent: 'hurricane banner',
          additionalProps: {
            parentType: 'componentBanner',
            linkName: ctaText || '',
            unitName: ctaName || 'rentalsBannerLink',
          },
        });
        e.stopPropagation();
      }}
    >
      <EcommBanner ref={bannerRef} theme={theme}>
        <WrapBalancedDiv>
          {text}
          <CtaText
            className="singleCta"
            data-test-id={`${name}SingleCta`}
            theme={theme}
            style={{ fontWeight: 500 }}
          >
            {ctaText}
          </CtaText>
        </WrapBalancedDiv>
      </EcommBanner>
    </StyledLink>
  );
};

export default SingleCtaBanner;

const StyledLink = styled(Link)<{ theme: string | undefined }>`
  background: ${({ theme }): Theme => THEMES[theme].backgroundColor};
  color: ${({ theme }): Theme => THEMES[theme].color};
  width: 100%;
  &:focus {
    .singleCta {
      ${a11yOutlineStyles}
    }
  }

  &::after {
    display: none;
  }

  span {
    font-size: inherit !important;
    font-weight: inherit !important;
  }

  & > span {
    ${themedBannerLayoutStyles}
    min-height: 46px !important;

    ${media.tablet`
      min-height: 56px !important;
    `}

    ${media.desktop`
      min-height: 66px !important;
    `}
  }

  & [data-element-id='banner'] {
    padding: unset;
    min-height: unset;
  }
`;

const WrapBalancedDiv = styled.div`
  @supports (text-wrap: balance) {
    text-wrap: balance;
  }

  display: block;
  line-height: 20px;
  white-space: pre-line;

  & > .singleCta {
    font-weight: 400 !important;
    @media (min-width: ${BreakpointWidths.mobile}px) {
      font-size: 0.8125rem;
    }
    @media (min-width: ${BreakpointWidths.tablet}px) {
      font-size: 0.875rem;
    }
  }

  ${media.tabletLarge`
  display: inline-flex;
  align-items: center;
  gap: ${spacing[8]};
  white-space: normal;
`}
  p {
    font-weight: 400;
    display: inline;
  }
`;
