import { Media, grey, spacing } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';

/*
 1. Erases 1px line between components.
 */
export const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -1px; /* [1] */
  margin-bottom: ${spacing[56]};
  background: ${grey[90]};
`;

export const StyledClassContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  width: 808px;
  margin: 0 auto;
  top: 0;

  @media (min-width: ${BreakpointWidth.desktopLarge}px) {
    border-radius: ${spacing[4]};
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledClassPage = styled.article`
  width: 100%;
  ${media.desktopLarge`
    margin-bottom: ${spacing[120]}
  `}
`;

export const StyledPaddedContainer = styled.div`
  background: ${grey[80]};
  ${media.desktop`
    padding: 0 ${spacing[80]} 0;
  `}
  ${media.desktopLarge`
    padding: 0 ${spacing[80]} ${spacing[40]};
  `}
`;

export const StyledMediaContainer = styled.div`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  position: relative;
`;

/*
 1. Erases 1px surrounding the hero.
 */
export const StyledHeroGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  /* [1] */
  @supports (height: calc(100% + 2px)) {
    height: calc(100% + 2px);
    top: -1px;
  }
  background: linear-gradient(
    180deg,
    ${rgba(grey[80], 0)} 0%,
    ${rgba(grey[80], 0)} 55%,
    ${rgba(grey[80], 1)} 100%
  );
  ${media.desktopLarge`
    background: linear-gradient(180deg, transparent 0%, transparent 70%,${grey[90]} 100%), linear-gradient(345deg, ${grey[90]}, transparent 0% 78%, rgba(24,26,29,0.5) 100%), linear-gradient(90deg, rgba(24,26,29,0.2) 0%, transparent 40%, transparent 100%);
  `}
`;

export const StyledFixedHero = styled.div`
  max-width: 808px;
  margin: 0 auto;
  position: relative;
  ${media.desktopLarge`
    width: 100%;
    max-width: 100%;
    height: 31.25rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  `}
  ${media.desktopXLarge`
    height: 37.5rem;
  `}
`;

export const StyledFixedHeroBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledClassMedia = styled(Media)<{ showBackground: boolean }>`
  background-color: ${({ showBackground }) => (showBackground ? grey[70] : '')};
  ${media.desktopLarge`
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 60%;
    margin-left: auto;
  `}
`;

export const StyledMirroredMedia = styled(Media)<{ showBackground: boolean }>`
  display: none;
  background-color: ${({ showBackground }) => (showBackground ? grey[70] : '')};
  ${media.desktopLarge`
    display: block;
    height: 100%;
    width: 60%;
    position: absolute;
    top: 0;
    left: -20%;
    bottom: 0;
    transform: scaleX(-1);
  `}
`;

export const StyledBlurredArea = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(50px);
  ${media.desktopLarge`
    display: block;
    width: 50%;
    mask-image: linear-gradient(to right, white 80%, transparent 100%);
  `};
  @media and (min-width: 1440px) {
    width: 55%;
    mask-image: linear-gradient(to right, white 75%, transparent 100%);
  }
  @media (prefers-contrast: more) {
    background: ${grey[90]};
  }
`;
