import type { Entry } from 'contentful';
import type { FieldValues } from 'react-hook-form';
import type { TypeComponentFormElementFields } from '@page-builder/lib/types';

export const EMAIL_INPUT_FIELD = 'email';
export const SERIAL_NUMBER_INPUT_FIELD = 'serial number';
export const BUYING_OR_SELLING_FIELD = 'History Report Buying Or Selling Field';
export const TERMS_AND_CONDITIONS = 'History Report Terms And Conditions';
export const MARKETING_CONSENT_FIELD = 'History Report Email Marketing Capture';
type HistoryFormFields = {
  emailInputField?: Entry<TypeComponentFormElementFields>;
  serialNumberInputField?: Entry<TypeComponentFormElementFields>;
  buyingOrSellingInputField?: Entry<TypeComponentFormElementFields>;
  termsAndConditionsInputField?: Entry<TypeComponentFormElementFields>;
  marketingConsentInputField?: Entry<TypeComponentFormElementFields>;
};

export const extractHistoryFormFields = (
  data?: Entry<TypeComponentFormElementFields>[],
): HistoryFormFields => {
  return {
    emailInputField: data?.find(
      formField => formField?.fields?.name === EMAIL_INPUT_FIELD,
    ),
    serialNumberInputField: data?.find(
      formField => formField?.fields?.name === SERIAL_NUMBER_INPUT_FIELD,
    ),
    buyingOrSellingInputField: data?.find(
      formField => formField?.fields?.name === BUYING_OR_SELLING_FIELD,
    ),
    termsAndConditionsInputField: data?.find(
      formField => formField?.fields?.name === TERMS_AND_CONDITIONS,
    ),
    marketingConsentInputField: data?.find(
      formField => formField?.fields?.name === MARKETING_CONSENT_FIELD,
    ),
  };
};

export const serialNumberErrors = (
  formError: FieldValues,
  serialNumberControl: { name: string | number },
  serialNumberInputField: { fields: { errorMessage?: any } },
) => {
  if (formError.errors?.root?.httpErrorMessage?.type === 'httpError') {
    return formError.errors.root.httpErrorMessage.message;
  } else if (formError.errors?.[serialNumberControl.name]) {
    return serialNumberInputField.fields.errorMessage;
  }
};
