import React from 'react';
import type { TypeQuiz } from '@page-builder/lib/types';
import ProgressProvider from './components/ProgressProvider';
import QuizContent from './components/QuizContent';

const Quiz: React.FC<React.PropsWithChildren<TypeQuiz>> = ({
  fields: { quizName, questions, resultPageUrl, loadingPageText },
}) => {
  return (
    <ProgressProvider sessionKey={quizName}>
      <QuizContent
        questions={questions}
        resultPageUrl={resultPageUrl}
        loadingPageText={loadingPageText}
      />
    </ProgressProvider>
  );
};

export default Quiz;
