import type { IconNames } from '@pelotoncycle/design-system';
import {
  white,
  Label,
  Icon,
  spacing,
  black,
  accessibility,
} from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';

type Props = {
  moduleId: string;
  cta: TypeComponentCta;
  isExpanded: boolean;
  toggleExpanded: () => void;
};

const ToggleCta: React.FC<Props> = ({ moduleId, cta, isExpanded, toggleExpanded }) => {
  const { trackLinkClick } = useTrackLinkClick();
  const { icon, text } = ctaExtractData(cta);

  const onClick = () => {
    trackLinkClick({
      href: '',
      parent: 'Poster With Content Panel',
      additionalProps: {
        unitName: 'Toggle CTA',
        linkName: text,
      },
    });

    toggleExpanded();
  };

  return (
    <ToggleButton
      onClick={onClick}
      disabled={isExpanded}
      aria-expanded={isExpanded}
      aria-controls={`${moduleId}-panel`}
    >
      {icon && <Icon name={icon as IconNames} primaryColor="currentColor" />}
      <Label size="large">{text}</Label>
    </ToggleButton>
  );
};

const ToggleButton = styled.button`
  backdrop-filter: blur(12px);
  background: ${rgba(black, 0.33)};
  border-radius: 100px;
  color: ${white};
  display: flex;
  flex-direction: row;
  gap: ${spacing[8]};
  padding: ${spacing[12]} ${spacing[24]};
  transition: 400ms opacity;
  width: fit-content;
  outline: none;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  [data-whatintent='keyboard'] &:focus {
    outline: 2px solid ${accessibility.outline};
    outline-offset: 4px;
  }
`;

export default ToggleCta;
