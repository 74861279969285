import { spacing } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { designMaxWidth, designGutter } from '../utils/layout';

type Props = {
  columns: number;
};

export const CardGrid = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: ${spacing[40]} auto;

  ${designGutter}
  ${(props: Props) => designMaxWidth(props.columns * 2)};

  ${media.desktopLarge`
    grid-template-columns: ${(props: Props) => `repeat(${props.columns}, 1fr)}`};
    margin-top: ${spacing[48]};
  `}
`;
