import { white, Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import DrawerCtaBox from './DrawerCtaBox';

const BIKEPLUS_RENT_US_PATH = '/shop/opc/bike-plus';

const RentDrawerCtaBox: React.FC = () => {
  const { trackEvent } = useTracking();
  const rawText = useMicroCopy('disabledRentDrawerText');
  const [labelText, ctaText] = rawText.split('|').map(str => str.trim());

  const containerProps = {
    backgroundColor: white,
  };

  const onLinkClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Generic List',
        parent: `Peloton Bike Overview Drawer`,
        unitName: 'Peloton Bike Overview Rent Drawer',
        linkName: ctaText,
        linkTo: BIKEPLUS_RENT_US_PATH,
      },
    });
  };

  const CtaComponent = ctaText ? (
    <Flex justifyContent="center" position="relative">
      <StyledLink href={BIKEPLUS_RENT_US_PATH} onClick={onLinkClick}>
        <Label size="small">{ctaText}</Label>
      </StyledLink>
    </Flex>
  ) : null;

  return (
    <DrawerCtaBox
      containerProps={containerProps}
      labelText={labelText}
      CtaComponent={CtaComponent}
    />
  );
};

export default RentDrawerCtaBox;

// match alignment of financing CTA's text & underline
const StyledLink = styled(Link)`
  transform: translateY(1px);

  ::after {
    transform: translateY(-2px);
  }
`;
