// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type StatsQueryVariables = Types.Exact<{
  equipmentType: Types.ReviewEquipmentType;
  lang?: Types.Maybe<Types.Language>;
  excludeFamily?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type StatsQuery = { __typename?: 'Query' } & {
  reviewsByEquipmentType: { __typename?: 'Reviews' } & Pick<
    Types.Reviews,
    'averageOverallRating' | 'totalCount'
  >;
};

export const StatsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Stats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipmentType' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReviewEquipmentType' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Language' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeFamily' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewsByEquipmentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipmentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'equipmentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lang' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeFamily' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeFamily' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageOverallRating' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCount' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *      equipmentType: // value for 'equipmentType'
 *      lang: // value for 'lang'
 *      excludeFamily: // value for 'excludeFamily'
 *   },
 * });
 */
export function useStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StatsQuery, StatsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    baseOptions,
  );
}
export function useStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatsQuery, StatsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<StatsQuery, StatsQueryVariables>(
    StatsDocument,
    baseOptions,
  );
}
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsQueryResult = ApolloReactCommon.QueryResult<
  StatsQuery,
  StatsQueryVariables
>;
