import { spacing } from '@pelotoncycle/design-system';
import type {
  TypeComponentCtaFields,
  TypeComponentReviewsFields,
} from '@pelotoncycle/page-builder';
import React, { useRef, useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import { toCountry, toLanguageFromLocale } from '@peloton/internationalize/models';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { Language } from '@ecomm/graphql/types.generated';
import type { ReviewableTypes } from '@ecomm/models';
import { useReviewWithStatsQuery } from '@ecomm/reviews/data/utils/useReviewWithStatsQuery';
import { LiftedModal } from '@page-builder/modules/NextModal/LiftedModal/LiftedModal';
import { REVIEWS_MODAL } from './constants';
import ReviewsModalContent from './ReviewsModalContent';
import { themes } from './themes';

type ReviewsModalType = {
  headline?: string;
  isOpen: boolean;
  isFirstReview: boolean;
  deviceType: ReviewableTypes;
  activeRating: number | undefined;
  activeReviewId?: string;
  theme: TypeComponentReviewsFields['theme'];
  closeHandler: () => void;
  setActiveRating: (rating: number | undefined) => void;
  cta?: TypeComponentCtaFields;
};

const ReviewsModal: React.FC<ReviewsModalType> = ({
  headline,
  isOpen,
  isFirstReview,
  deviceType,
  activeRating,
  activeReviewId,
  theme,
  closeHandler,
  setActiveRating,
  cta,
}) => {
  const { trackEvent } = useTracking();
  const { modalBackgroundColor, textColor } = themes[theme];
  const lang = (toLanguageFromLocale(useLocale()) as unknown) as Language;
  const excludeFamily = true; // update later
  const [reviewsFetchedCount, setReviewsFetchedCount] = useState(
    REVIEWS_MODAL.defaultNumberOfReviews,
  );

  const {
    reviewsProps: reviews,
    totalReviews: totalReviewCount,
    ratingsDistribution,
  } = useReviewWithStatsQuery(
    (deviceType ?? 'bike') as ReviewableTypes,
    REVIEWS_MODAL.defaultSortOrder,
    reviewsFetchedCount,
    reviewsFetchedCount,
    lang,
    excludeFamily,
    undefined,
    undefined,
    activeRating,
  );

  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleOnAfterOpen = () => {
    trackEvent({
      event: TrackingEvent.ModalOpened,
      properties: {
        country: toCountry().toLocaleUpperCase(),
        pageName: window.location.pathname,
        productInterest: deviceType,
        propertyType: 'Web',
        unitName: `${deviceType} Reviews Modal`,
        unitPlacement: 'Modal',
        modalTrigger: 'Click',
      },
    });
    setTimeout(() => {
      const activeReview = modalContentRef.current?.querySelector(
        `.review-card-${activeReviewId}`,
      );

      const activeReviewTop = activeReview?.getBoundingClientRect().top ?? 0;
      const modalTop = modalContentRef.current?.getBoundingClientRect().top ?? 0;
      const innerContent = document.querySelector('.ReactModal__Overlay--after-open');

      if (innerContent) {
        innerContent.scrollTo({
          top: isFirstReview
            ? 0
            : activeReviewTop - modalTop - REVIEWS_MODAL.cardPaddingOffset,
          behavior: 'smooth',
        });
      }
    }, REVIEWS_MODAL.transitionDuration);
  };

  useEffect(() => {
    setActiveRating(activeRating);
    setReviewsFetchedCount(REVIEWS_MODAL.defaultNumberOfReviews);
  }, [activeRating, setActiveRating]);

  return (
    <StyledLiftedModal
      isOpen={isOpen}
      contentLabel={headline || ''}
      backgroundColor={modalBackgroundColor}
      headerColor={modalBackgroundColor}
      textColor={textColor}
      closeHandler={closeHandler}
      onAfterOpen={handleOnAfterOpen}
      modalContentRef={modalContentRef}
      maxWidth="780px"
      hasExtendedBottomMargin={false}
    >
      {reviews && (
        <ReviewsModalContent
          reviews={reviews}
          totalReviewCount={totalReviewCount}
          activeRating={activeRating}
          theme={theme}
          setActiveRating={starRating => setActiveRating(starRating)}
          handleFetchMoreReviews={() => {
            if (reviewsFetchedCount < 100) {
              setReviewsFetchedCount(
                prevCount => prevCount + REVIEWS_MODAL.defaultNumberOfReviews,
              );
            }
          }}
          activeReviewId={activeReviewId}
          cta={cta}
          ratingsDistribution={ratingsDistribution}
        />
      )}
    </StyledLiftedModal>
  );
};

const StyledLiftedModal = styled(LiftedModal)`
  border-radius: ${spacing[12]};
`;

export default ReviewsModal;
