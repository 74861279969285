import { Eyebrow, grey } from '@pelotoncycle/design-system';
import type {
  TypeComponentCta,
  TypeComponentGenericTextWithMedia,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition } from '@peloton/styles/animation';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import ProductComparisonModal from '@page-builder/modules/ProductComparisonCards/ProductComparisonModal/ProductComparisonModal';
import { getTextFieldsWithRequiredKeys, toCtaFields } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import { useTheme } from '../themes';
import type { ThemeProps } from '../themes';

type Props = {
  cta: TypeComponentCta;
  productName: string;
};

const ProductComparisonModalCta: React.FC<React.PropsWithChildren<Props>> = ({
  cta,
  productName,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const theme = useTheme();

  const { trackLinkClick } = useTrackLinkClick();

  const [{ text, modal, name: ctaName }] = toCtaFields([cta]);

  const modalContent =
    modal &&
    isEntryType<TypeComponentGenericTextWithMedia>(modal, 'text') &&
    modal.fields.text;

  if (!modalContent) {
    throw new Error('Product comparison modal missing on cta');
  }

  const { body, name, support } = getTextFieldsWithRequiredKeys(
    ['body', 'name', 'support'],
    modalContent,
  );

  const toggleModalOpen = () => setIsModalOpen(isOpen => !isOpen);

  const onToggleClick = () => {
    trackLinkClick({
      href: '',
      parent: `${productName}`,
      additionalProps: {
        linkName: text,
        parentType: `${productName} Compare Modal CTA`,
        unitName: ctaName,
      },
    });
    toggleModalOpen();
  };

  return (
    <>
      <ModalToggleButton onClick={onToggleClick} ctaTextColor={theme.modalCtaTextColor}>
        <Eyebrow size="small" as="span">
          {text}
        </Eyebrow>
      </ModalToggleButton>
      <ProductComparisonModal
        isOpen={isModalOpen}
        name={name}
        content={body}
        membershipOptionsText={support}
        onClose={toggleModalOpen}
        productName={productName}
      />
    </>
  );
};

const ModalToggleButton = styled.button<{
  ctaTextColor: ThemeProps['modalCtaTextColor'];
}>`
  display: flex;
  flex-direction: column;
  gap: 1px;
  ${props => `color: ${props.ctaTextColor}`};

  ${defaultTransition('color')}

  &:hover {
    color: ${grey[70]};
  }

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: currentColor;
  }
`;

export default ProductComparisonModalCta;
