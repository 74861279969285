import React from 'react';
import type { IconProps } from './types';

const FiltersIcon: React.FC<React.PropsWithChildren<IconProps>> = props => (
  <svg width={14} height={10} viewBox="0 0 14 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3335 0.999674C8.59712 0.999674 8.00016 1.59663 8.00016 2.33301C8.00016 3.06939 8.59712 3.66634 9.3335 3.66634C10.0699 3.66634 10.6668 3.06939 10.6668 2.33301C10.6668 1.59663 10.0699 0.999674 9.3335 0.999674ZM0.666829 1.99967H7.36115C7.51984 1.05377 8.3425 0.333008 9.3335 0.333008C10.3245 0.333008 11.1472 1.05377 11.3058 1.99967H13.3335C13.5176 1.99967 13.6668 2.14891 13.6668 2.33301C13.6668 2.5171 13.5176 2.66634 13.3335 2.66634H11.3058C11.1472 3.61225 10.3245 4.33301 9.3335 4.33301C8.3425 4.33301 7.51984 3.61225 7.36115 2.66634H0.666829C0.482735 2.66634 0.333496 2.5171 0.333496 2.33301C0.333496 2.14891 0.482735 1.99967 0.666829 1.99967ZM0.666829 7.33301C0.482735 7.33301 0.333496 7.48225 0.333496 7.66634C0.333496 7.85044 0.482735 7.99967 0.666829 7.99967H2.36115C2.51984 8.94558 3.3425 9.66634 4.3335 9.66634C5.32449 9.66634 6.14715 8.94558 6.30584 7.99967H13.3335C13.5176 7.99967 13.6668 7.85044 13.6668 7.66634C13.6668 7.48225 13.5176 7.33301 13.3335 7.33301H6.30584C6.14715 6.3871 5.32449 5.66634 4.3335 5.66634C3.3425 5.66634 2.51984 6.3871 2.36115 7.33301H0.666829ZM5.66683 7.66634C5.66683 6.92996 5.06988 6.33301 4.3335 6.33301C3.59712 6.33301 3.00016 6.92996 3.00016 7.66634C3.00016 8.40272 3.59712 8.99967 4.3335 8.99967C5.06988 8.99967 5.66683 8.40272 5.66683 7.66634Z"
      fill="white"
    />
  </svg>
);

export default FiltersIcon;
