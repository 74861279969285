type Options = {
  hasCenteredScrollSnap: boolean;
};

export type ConfigurableOptions = Partial<Options>;

/* 
 1. Position is shifted left to account for centered scroll-snap behaviour.
 */
const getNextRightScrollPosition = (
  scrollableEl: HTMLElement,
  childEls: HTMLElement[],
  options: Options,
): number | null => {
  const scrollProgress = scrollableEl.scrollLeft + scrollableEl.offsetWidth;
  const newActiveChild = childEls.find(
    child => child.offsetLeft + child.offsetWidth > scrollProgress,
  );

  if (!newActiveChild) {
    return null;
  }

  /* [1] */
  const offset = options.hasCenteredScrollSnap ? newActiveChild.offsetWidth * -1 : 0;

  return newActiveChild.offsetLeft + offset;
};

/* 
 1. Position is shifted right to account for centered scroll-snap behaviour.
 */
const getNextLeftScrollPosition = (
  scrollableEl: HTMLElement,
  childEls: HTMLElement[],
  options: Options,
): number | null => {
  const newActiveChild = childEls.find(
    child => child.offsetLeft + child.offsetWidth - scrollableEl.scrollLeft >= 0,
  );

  if (!newActiveChild) {
    return null;
  }

  /* [1] */
  const offset = options.hasCenteredScrollSnap ? newActiveChild.offsetWidth : 0;

  return (
    newActiveChild.offsetLeft +
    newActiveChild.offsetWidth +
    offset -
    scrollableEl.offsetWidth
  );
};

export const slide = ({
  direction,
  scrollableEl,
  childEls,
  options = {},
}: {
  direction: 'left' | 'right';
  scrollableEl: HTMLElement;
  childEls: HTMLElement[];
  options?: ConfigurableOptions;
}) => {
  const hasCenteredScrollSnap = options.hasCenteredScrollSnap || false;
  const scrollPosition =
    direction === 'right'
      ? getNextRightScrollPosition(scrollableEl, childEls, { hasCenteredScrollSnap })
      : getNextLeftScrollPosition(scrollableEl, childEls, { hasCenteredScrollSnap });

  if (scrollPosition === null) {
    return;
  }

  scrollableEl.scrollTo({
    left: scrollPosition,
    top: 0,
    behavior: 'smooth',
  });
};
