import type { CoveredType } from '@onewellness/models/programBenefit';

/* checks if contentful entry has subscription type in entry name */
const checkEntryNameSubscriptionType = (
  entryName: string,
  subscriptionType: CoveredType | string,
) => {
  const regex = new RegExp(`\\b${subscriptionType}\\b`, 'i');

  return regex.test(entryName);
};

export default checkEntryNameSubscriptionType;
