import {
  Container,
  Display,
  Flex,
  Layout,
  LinkButton,
  Media,
  OverlaidChildren,
  Support,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { isAndroid, isIOS } from '@peloton/browser';
import { media as mediaBreakpoints } from '@peloton/styles/breakpoints';
import { TrackingEvent } from '@ecomm/analytics/models';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import Markdown from '@page-builder/utils/Markdown';
import type { MediaReferenceType as MediaReference } from '../../../types/referenceTypes';
import LightboxModal from './Lightbox/LightboxModal';
import ScrollChevron from './ScrollChevron';
import type { CtaProps as CtaPropsType } from './types';
import ViewportLinkButton from './ViewportLinkButton';

const TEXT_MAXWIDTH = {
  mobile: 440,
  desktop: 680,
};

export type ViewportHeroProps = {
  headline: string;
  support: string;
  media: MediaReference;
  ctas: CtaPropsType[];
};

const ViewportHero: React.FC<ViewportHeroProps> = ({
  headline,
  support,
  media,
  ctas,
}) => {
  const pathName = useWindowPathname();
  const { trackEvent } = useTracking();

  const heroRef = useRef<HTMLElement | null>(null);
  const [heroDimensions, setHeroDimensions] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isWaysToAccessModalOpen, setIsWaysToAccessModalOpen] = useState<boolean>(false);
  const [isLightboxModalOpen, setIsLightboxModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const heroBottom = heroRef.current?.getBoundingClientRect()?.bottom;
    setHeroDimensions(heroBottom ?? 0);
  }, []);

  useEffect(() => {
    if (isAndroid() || isIOS()) {
      setIsMobile(true);
    }
  }, []);

  const toLinkProps = (discipline: string, text: string, addProps?: any) => {
    return {
      parentType: 'Viewport Module (Classes)',
      parent: `${discipline} Viewport Module`,
      unitName: `${text} Viewport Module CTA`,
      ...addProps,
    };
  };

  const handleLightboxLinkClick = (desktopText: string) => {
    const additionalTrackingProps = {
      contentPosition: 'video1',
      campaignName: 'Discipline Class Videos',
    };
    const trackingEvent = {
      event: TrackingEvent.ClickedLink,
      properties: toLinkProps(headline, desktopText, additionalTrackingProps),
    };

    trackEvent(trackingEvent);
    setIsLightboxModalOpen(true);
  };

  const handleLinkClick = (text: string, linkTo?: string) => {
    const additionalTrackingProps = {
      linkName: text,
      linkTo: linkTo,
    };
    const trackingEvent = {
      event: TrackingEvent.ClickedLink,
      properties: toLinkProps(headline, text, additionalTrackingProps),
    };

    trackEvent(trackingEvent);
  };

  const handleWaysToAccessModalLinkClick = (desktopText: string) => {
    const additionalTrackingProps = {
      page: pathName,
      unitName: 'Component: CTA',
      linkName: desktopText,
    };
    const trackingEvent = {
      event: 'Clicked Ways To Take Classes CTA' as TrackingEvent,
      properties: toLinkProps(headline, desktopText, additionalTrackingProps),
    };

    trackEvent(trackingEvent);
    setIsWaysToAccessModalOpen(true);
  };

  return (
    <Wrapper
      backgroundColor="transparent"
      ref={el => {
        heroRef.current = el;
      }}
    >
      <OverlaidChildren>
        <Container>
          <Media media={media} />
          <StyledOverlay />
        </Container>
        <Layout
          backgroundColor="transparent"
          verticalAlignment="center"
          horizontalAlignment="center"
        >
          <ContentFlex flexDirection="column" removeOuterMargins>
            <Display
              size="large"
              hasShadow={true}
              textColor={white}
              style={{ marginBottom: spacing[24] }}
            >
              {headline}
            </Display>

            <Flex
              alignItems="center"
              flexWrap="wrap"
              justifyContent="center"
              verticalMargin={`0 ${spacing[24]}`}
              gap={spacing[16]}
              width="100%"
            >
              {ctas.map(cta => {
                const { icon, lightbox, link, modal, desktopText, variant } = cta;
                if (lightbox) {
                  return (
                    <>
                      <LinkButton
                        key={`${desktopText}-lightbox`}
                        size="medium"
                        color="light"
                        variant={variant}
                        icon={icon as IconNames}
                        onClick={() => handleLightboxLinkClick(desktopText)}
                      >
                        {desktopText}
                      </LinkButton>

                      <LightboxModal
                        isOpen={isLightboxModalOpen}
                        lightbox={lightbox}
                        closeHandler={() => setIsLightboxModalOpen(false)}
                        trackingProps={toLinkProps(headline, desktopText)}
                        isMobile={isMobile}
                        handleLinkClick={handleLinkClick}
                      />
                    </>
                  );
                }

                if (link && modal) {
                  return (
                    <ViewportLinkButton
                      isMobile={isMobile}
                      cta={cta}
                      handleLinkClick={handleLinkClick}
                      handleWaysToAccessModalLinkClick={handleWaysToAccessModalLinkClick}
                      isWaysToAccessModalOpen={isWaysToAccessModalOpen}
                      setIsWaysToAccessModalOpen={setIsWaysToAccessModalOpen}
                    />
                  );
                }

                return null;
              })}
            </Flex>

            <Markdown
              content={support}
              markdown={{
                renderers: {
                  paragraph: ({ children }) => (
                    <Support textColor={white} as="p">
                      {children}
                    </Support>
                  ),
                },
              }}
            />
          </ContentFlex>
        </Layout>
        <ScrollChevron scrollTo={heroDimensions} />
      </OverlaidChildren>
    </Wrapper>
  );
};

const Wrapper = styled(Layout)`
  position: relative;

  height: 500px;
  @media screen and (height < 900px) and (width > 768px) {
    height: 600px;
  }
  @media screen and (height > 900px) and (width > 768px) {
    height: 700px;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${rgba(grey[90], 0.3)};
`;

const ContentFlex = styled(Flex)`
  align-items: center;

  max-width: ${TEXT_MAXWIDTH.mobile}px;
  ${mediaBreakpoints.desktop`
    max-width: ${TEXT_MAXWIDTH.desktop}px;
  `}
  }
`;

export default ViewportHero;
