import { grey, Body, Icon, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export const QuoteCard: React.FC<React.PropsWithChildren<{ cardText: ReactNode }>> = ({
  cardText,
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor={grey[80]}
      hasShadow={true}
      borderRadius="8px"
      padding={{ mobile: '24px', tablet: '24px 88px', desktop: '24px 24px 40px' }}
      width="100%"
      maxWidth={{ tablet: '551px', desktop: '351px' }}
    >
      <Flex
        minWidth="40px"
        minHeight="40px"
        border={`solid 1px ${grey[60]}`}
        borderRadius="50%"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="quotes" primaryColor={grey[60]} height={20} />
      </Flex>
      <QuoteText size="small" textColor={grey[20]}>
        {cardText}
      </QuoteText>
    </Flex>
  );
};

const QuoteText = styled(Body)`
  margin: 24px 0px;
  text-align: center;
  letter-spacing: 0.025em;
`;
