export const SUBNAV_SIZE = 72;
export const BREADCRUMB_SIZE = 8;
export const BREADCRUMB_GAP = 16;

export const isEven = (num: number) => num % 2 === 0;

export const getRootElement = (): HTMLElement | null => {
  const craRootElement = document.getElementById('root');
  const nextRootElement = document.getElementById('__next');
  return craRootElement || nextRootElement;
};
