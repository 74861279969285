import React from 'react';
import styled from 'styled-components';

type MessageProps = {
  error: any;
  module: any;
};

const CONTENTFUL_URL = 'https://app.contentful.com';

export const getContentLinkAndName = (module: any) => {
  const spaceId = module?.sys?.space?.sys?.id;
  const entryId = module?.sys?.id;
  const { name } = module.fields;

  return {
    href:
      spaceId && entryId ? `${CONTENTFUL_URL}/spaces/${spaceId}/entries/${entryId}` : '',
    moduleName: name,
  };
};

export const ErrorMessage = ({ error, module }: MessageProps) => {
  const { href, moduleName } = getContentLinkAndName(module);
  return (
    <ErrorContainer>
      <p>ERROR:</p>
      {moduleName && <p>Module Name: {moduleName}</p>}
      {href && (
        <p style={{ wordWrap: 'break-word' }}>
          Link:{' '}
          <a href={href} target="_blank" rel="noreferrer">
            {href.toString()}
          </a>
        </p>
      )}
      {error && <p>Message: {error.message}</p>}
    </ErrorContainer>
  );
};

const ErrorContainer = styled.section`
  border: 3px solid red;
  padding: 10%;
  width: 100%;
  a {
    font-weight: 700;
    text-decoration: underline;
  }
`;
