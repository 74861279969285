import { Icon, Label, spacing, blue } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import CitizensCopy from '@ecomm/financing/citizens/CitizensCopy';
import { useCitizens } from '@ecomm/financing/citizens/Context';
import { usePackageFinancing } from '@page-builder/modules/Overview/utils';
import FinancingCtaContentWrapper from './FinancingCtaContentWrapper';
import type FinancingCtaProps from './FinancingCtaProps';

const CITIZENS_LOGO_WIDTH = '87px';

const CitizensCta: React.FC<React.PropsWithChildren<FinancingCtaProps>> = ({
  cta,
  bundleType,
}) => {
  const { ready, total } = usePackageFinancing(bundleType);
  const { citizensProvider } = useCitizens();
  const isPrequalified = !!citizensProvider?.['locaStatusObj'];

  if (!ready) {
    return null;
  }

  return (
    <CitizensContentWrapper isPrequalified={isPrequalified}>
      <Label size="medium" display="inline" is="span">
        <CitizensCopyWrapper
          id={cta.name}
          text={cta.text}
          bundleType={bundleType}
          pageType="product"
          price={total}
          isPrequalified={isPrequalified}
          withIcon={!!cta.ctaIcon}
        />
      </Label>
      {cta.ctaIcon && !isPrequalified && <CitizensIcon name={cta.ctaIcon} height={12} />}
    </CitizensContentWrapper>
  );
};

export default CitizensCta;

const CitizensCopyWrapper = styled(CitizensCopy)<{
  isPrequalified: boolean;
  withIcon: boolean;
}>`
  // Override inline styles set by citizens sdk
  display: inline !important;
  padding: 0 !important;
  font-weight: ${props => props.isPrequalified && 300};

  a {
    color: inherit !important;
    text-decoration: unset;
    transition: inherit !important;
    ${props =>
      props.withIcon && `padding-right: calc(${CITIZENS_LOGO_WIDTH} + ${spacing[4]});`}

    :focus {
      outline: none;
    }

    [data-whatintent='keyboard'] &:focus {
      outline: ${spacing[2]} solid ${blue[50]};
      outline-offset: ${spacing[4]};
      border-radius: ${spacing[4]};
    }
  }
`;

const CitizensContentWrapper = styled(FinancingCtaContentWrapper)<{
  isPrequalified: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  border: ${props => props.isPrequalified && 'unset'};

  &:hover {
    color: ${props => props.isPrequalified && 'inherit'};
  }
`;

const CitizensIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
  width: ${CITIZENS_LOGO_WIDTH};
`;
