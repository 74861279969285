import { Container, Flex, Icon, Label, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys, toCtaFields } from '@page-builder/utils/helpers';
import { Divider } from '../ShopDrawers/DrawerContent/UpsellContent/SharedComponents';

type IconSectionProps = {
  item: TypeComponentGenericTextWithMedia;
};

const CompareBikesIconSection: React.FC<React.PropsWithChildren<IconSectionProps>> = ({
  item,
}) => {
  const { ctas, text } = item.fields;
  const { eyebrow: iconTextTitle, support: iconTextBody } = getTextFieldsWithRequiredKeys(
    ['eyebrow', 'support'],
    text,
  );
  if (!ctas) return null;
  const [cta] = toCtaFields(ctas);
  return (
    <Container>
      <Flex
        flexDirection="column"
        padding={{
          mobile: `0 ${spacing[8]} ${spacing[24]}`,
          desktop: `0 ${spacing[24]} ${spacing[32]}`,
        }}
      >
        <Divider topPadding={0} />
        <Container
          verticalPadding={{ mobile: `${spacing[24]} 0`, desktop: `${spacing[32]} 0` }}
        >
          <Flex flexDirection="column">
            {cta.ctaIcon && <StyledIcon name={cta.ctaIcon} />}
            <Flex
              flexDirection="column"
              verticalPadding={{
                mobile: `${spacing[12]} 0`,
                desktop: `${spacing[16]} 0`,
              }}
              gap={spacing[8]}
            >
              <Label size="large" weight="medium" textColor={grey[90]}>
                {iconTextTitle}
              </Label>
              <Label size="large" textColor={grey[70]}>
                {iconTextBody}
              </Label>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
};

const StyledIcon = styled(Icon)`
  path {
    fill: ${grey[70]};
  }
  height: ${spacing[40]};

  ${mediaBreakpoints.desktop`
    height: ${spacing[48]};
  `}
`;

export default CompareBikesIconSection;
