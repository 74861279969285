import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { WaysToAccessCTA } from '../../ClassPage/modules/WaysToAccessCTA';
import type { BannerData } from '../../ClassPage/utils/getCFUbyDiscipline';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

type Props = {
  waysToAccessLabel: string;
  fitnessDiscipline: string;
  waysToAccessCFUData: BannerData;
  waysToAccessAppData: BannerData;
};

export const ClassDetailsWaysToAccess: React.FC<React.PropsWithChildren<Props>> = ({
  waysToAccessLabel,
  fitnessDiscipline,
  waysToAccessCFUData,
  waysToAccessAppData,
}) => {
  return (
    <ClassDetailsCardSection
      title={waysToAccessLabel.replace(
        '{fitnessDiscipline}',
        fitnessDiscipline.toLowerCase(),
      )}
    >
      <FlexContainer>
        <WaysToAccessCTA {...waysToAccessCFUData} />
        <WaysToAccessCTA {...waysToAccessAppData} />
      </FlexContainer>
    </ClassDetailsCardSection>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  gap: ${spacing[16]};

  ${media.desktopLarge`
    flex-direction: row;
    justify-content: 'space-between';
    gap: ${spacing[16]};
  `}
`;
