import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { PagePropsContext } from '@peloton/next/data/toPagePropsContext';
import { NextNotFoundError } from '@peloton/next/errors/NextNotFoundError';
import { useLocale } from '@peloton/next/hooks/useLocale';
import type { GetPagePathOptions } from '@peloton/next/utils/getPagePath';
import { getPagePath } from '@peloton/next/utils/getPagePath';
import { excludePaginationSlug } from '@peloton/next/utils/pagination';
import { IMMUTABLE, REVALIDATE_DEFAULT } from '@content/client/revalidation';
import {
  CLASS_LISTING_PAGES_REGEX,
  FREE_CLASS_VIDEO_PAGES_REGEX,
  isClassIdSlug,
} from '@ecomm/classes/models/constants';
import {
  CLASS_PAGE_REGEX,
  CLASS_PAGE_REGEX_BROAD,
} from '@ecomm/classes/models/disciplines';
import { fetchClassDataApi } from '@ecomm/classes/utils/fetchClassDataApi';
import { fetchDisciplineDataApi } from '@ecomm/classes/utils/fetchDisciplineDataApi';
import { fetchFreeClassDataApi } from '@ecomm/classes/utils/fetchFreeClassDataApi';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import upsellCustomDataFetchers from '@ecomm/product-recommendations/utils/upsellCustomDataFetchers';

export type FetcherOpts = {
  slug: string;
  preview: boolean;
  locale?: string;
  allowsRejections?: boolean;
  query?: NextParsedUrlQuery;
};

export type Fetcher<Response = unknown> = (opts: FetcherOpts) => Promise<Response>;

type CustomPageDataProps = Pick<
  PagePropsContext,
  'pageSlugWithBasePath' | 'enablePreview' | 'atFallbackLocale'
>;

const staticDataFetchers: Record<string, Fetcher> = {
  ...upsellCustomDataFetchers,
};

const customPageDataFetchers = (
  path: string,
  allowsRejections: boolean = false,
): Fetcher => {
  if (CLASS_LISTING_PAGES_REGEX.test(path)) {
    const isClassIdPage = isClassIdSlug(path);

    if (isClassIdPage) {
      return (props: FetcherOpts) =>
        fetchClassDataApi({ allowsRejections: false, ...props });
    }

    return fetchDisciplineDataApi;
  }

  if (CLASS_PAGE_REGEX_BROAD.test(path)) {
    if (FREE_CLASS_VIDEO_PAGES_REGEX.test(path)) {
      return (props: FetcherOpts) =>
        fetchFreeClassDataApi({ allowsRejections, ...props });
    }
    if (CLASS_PAGE_REGEX.test(path)) {
      return (props: FetcherOpts) => fetchClassDataApi({ allowsRejections, ...props });
    }
    //catch anything that's in prospects app but not a valid class
    return () => {
      throw new NextNotFoundError({ message: `Invalid classpath: ${path}` });
    };
  }

  return staticDataFetchers[path];
};

export const fetchCustomPageData = async (
  { pageSlugWithBasePath, enablePreview, atFallbackLocale }: CustomPageDataProps,
  query?: NextParsedUrlQuery,
) => {
  const fetcher = customPageDataFetchers(pageSlugWithBasePath, true);

  if (fetcher) {
    return await fetcher({
      slug: excludePaginationSlug(pageSlugWithBasePath),
      preview: enablePreview,
      locale: atFallbackLocale,
      query,
    });
  }

  return null;
};

export const toCacheKey = (slug: string) => `custom+${slug}`;

export const getCacheKey = (router: NextRouter, options?: GetPagePathOptions) => {
  const pagePath = getPagePath(router, {
    ...options,
    includeBasePath: options?.includeBasePath ?? true,
    includeQueryParams: options?.includeQueryParams ?? true,
  }).slice(1);
  const cacheKey = toCacheKey(pagePath);
  return cacheKey;
};

export const useCacheKey = (options?: GetPagePathOptions) => {
  const router = useRouter();
  const cacheKey = getCacheKey(router, options);
  return cacheKey;
};

export const useCustomPageData = <CustomPageDataType extends unknown = unknown>() => {
  const previewCopy = useIsToggleActive()('previewCopy');
  const preview = previewCopy || ALLOW_PREVIEW_COPY;
  const revalidation = preview
    ? REVALIDATE_DEFAULT
    : { ...IMMUTABLE, revalidateOnMount: false };

  const router = useRouter();
  const locale = useLocale();
  const pagePath = getPagePath(router, { includeBasePath: true }).slice(1);
  const customFetcher = customPageDataFetchers(pagePath);
  const cacheKey = getCacheKey(router);

  const { data } = useSWR(cacheKey, {
    fetcher: () =>
      customFetcher({
        slug: pagePath,
        preview,
        locale,
        query: router.query,
      }),
    ...revalidation,
  });

  return data as CustomPageDataType;
};
