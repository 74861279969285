import {
  Display,
  Body,
  grey,
  white,
  Flex,
  BreakpointWidths,
  Container,
  spacing,
  Support,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { ctaExtractData } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';

type CtaBannerProps = TypeComponentGenericTextWithMediaFields & {
  backgroundColor?: string;
};

const CtaBanner: React.FC<React.PropsWithChildren<CtaBannerProps>> = ({
  text,
  ctas,
  media: mediaField,
  name,
  backgroundColor = grey[30],
}) => {
  const { eyebrow, support, body } = getGenericTextNodes(text, ['eyebrow']);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const moduleId = nameToModuleId(name);

  const { trackLinkClick } = useTrackLinkClick();

  const image = toImageProps(mediaField);

  if (!image) {
    console.error(
      `Malformed Contentful Entry for ${name}. 
        * Ensure you have defined media with an image
        * Ensure you have all breakpoints defined
          * mobile
          * tablet
          * desktop
      `,
    );
    return <div data-test-id="image-error"></div>;
  }

  return (
    <Container
      backgroundColor={backgroundColor}
      padding={{ desktop: spacing[40] }}
      id={moduleId}
      data-test-id="cta-banner-container"
      data-module="CtaBanner"
    >
      <StyledBackgroundContainer
        maxWidth="1224px"
        minHeight={{ mobile: '380px', tablet: '264px' }}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        borderRadius={{ desktop: spacing[12] }}
        mobile={image?.mobile}
        tablet={image?.tablet}
        desktop={image?.desktop}
        body={body !== undefined}
        centered
      >
        <Container
          maxWidth={{
            mobile: '316px',
            tablet: '400px',
            desktop: body ? '800px' : '400px',
          }}
        >
          <Display
            is="h2"
            size="small"
            textAlign="center"
            textColor={white}
            marginBottom="24px"
          >
            {eyebrow}
          </Display>
          {body && (
            <Body size="small" textAlign="center" textColor={white} marginBottom="24px">
              {body}
            </Body>
          )}
        </Container>
        <StyledLinkContainer textAlign="center">
          <LinkButton
            size={{
              mobile: 'small',
              tablet: 'medium',
            }}
            variant={primaryCta?.variant}
            href={primaryCta?.url}
            color={primaryCta?.color}
            text={primaryCta?.text}
            data-test-id="cta-banner"
            onClick={() =>
              trackLinkClick({
                href: primaryCta?.url || '',
                parent: name,
                additionalProps: {
                  linkTo: primaryCta?.url,
                  linkName: primaryCta?.text,
                  unitName: primaryCta?.name,
                  parentType: 'Component: Generic Text with Media',
                },
              })
            }
          />
        </StyledLinkContainer>
        {support && (
          <Flex
            justifyContent="center"
            maxWidth={{
              mobile: '316px',
              tablet: '400px',
            }}
          >
            <Support
              as="p"
              size="small"
              textColor={white}
              textAlign="center"
              style={{ marginTop: spacing[24] }}
            >
              {support}
            </Support>
          </Flex>
        )}
      </StyledBackgroundContainer>
    </Container>
  );
};

export default CtaBanner;

type BackgroundProps = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
  body: boolean;
};

const StyledBackgroundContainer = styled(Flex)<BackgroundProps>`
  ${({ mobile }) => mobile && `background-image: url(${mobile})`};
  ${({ body }) =>
    body &&
    `
      padding: ${spacing[56]} 0;

      h2 {
        margin: ${spacing[24]} 0;
      }
  `};

  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: ${({ body }) => (body ? 0 : 'center')};

  @media (min-width: ${BreakpointWidths.tablet}px) {
    ${({ tablet }) => tablet && `background-image: url(${tablet})`};
  }

  @media (min-width: ${BreakpointWidths.desktop}px) {
    ${({ desktop }) => desktop && `background-image: url(${desktop})`};
  }

  @media (min-width: 1280px) {
    margin: 0 auto;
  }
`;

const StyledLinkContainer = styled(Container)`
  & a {
    width: auto !important;
    min-width: ${126 / 16}rem;

    ${media.tablet`
      min-width: ${180 / 16}rem;
    `}
  }
`;
