import {
  Body,
  Container,
  Eyebrow,
  Flex,
  Headline,
  Support,
  spacing,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { TrackingEvent } from '@ecomm/analytics/models';
import useVideoAnalyticsEventHandlers from '@page-builder/hooks/useVideoAnalyticsEventHandlers';
import { isEven } from '@page-builder/modules/FeatureCardModal/utils';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import { getTextFieldsWithRequiredKeys, toCtaProps } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { configureMediaProps } from '../utils';
import type { TypeModalSection } from '../utils';
import { ActiveMedia, SectionContainer } from './modalStyles';
import { MultiItemModalSection } from './MultiItemModalSection';

type Props = {
  section: TypeModalSection;
  sectionIndex: number;
  isLastSection: boolean;
};

export const ModalSection: React.FC<Props> = ({
  section,
  sectionIndex,
  isLastSection,
}) => {
  const { trackEvent } = useTracking();
  const {
    sectionName,
    sectionContent: {
      fields: { items: sectionItems, ctas, text },
    },
  } = section;
  const isMultiItemSection = sectionItems.length > 1;
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);
  const activeItem = sectionItems[activeMediaIndex].fields.text?.fields.eyebrow;
  const { onPause, onEnded } = useVideoAnalyticsEventHandlers({
    properties: {
      contentId: `${section.sectionName} - ${activeItem}`,
      contentPosition: `Content Cards Modal section ${sectionIndex + 1}, item ${
        activeMediaIndex + 1
      }`,
      title: activeItem,
    },
  });

  const { headlineColor } = useTheme();

  const { eyebrow, headline, body, support } = getTextFieldsWithRequiredKeys(
    ['name'],
    text,
  );

  const mediaProps = configureMediaProps(
    sectionItems[activeMediaIndex],
    onPause,
    onEnded,
  );

  const handleThumbnailClick = (index: number) => {
    const itemName = sectionItems[index].fields.text?.fields.eyebrow;
    setActiveMediaIndex(index);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: `${sectionName} Section`,
        parentType: 'Component: Generic List',
        linkName: itemName,
        unitName: `${itemName} item of ${sectionName} Section`,
      },
    });
  };

  return (
    <SectionContainer
      isLastSection={isLastSection}
      margin="auto"
      padding={{
        mobile: `${spacing[32]} ${spacing[16]}`,
        tablet: `${spacing[80]} ${spacing[24]}`,
      }}
      flexDirection={{
        mobile: 'column',
        desktop: `${isEven(sectionIndex) ? 'row' : 'row-reverse'}`,
      }}
      gap={spacing[24]}
      className={`pos-${sectionName}`}
    >
      <Flex
        maxWidth={{ desktop: '392px' }}
        minWidth={{ desktop: '355px' }}
        gap={spacing[24]}
        flexDirection="column"
        justifyContent="center"
      >
        <Flex gap={spacing[8]} flexDirection="column">
          {eyebrow && (
            <Eyebrow textColor={headlineColor} size="small">
              {eyebrow}
            </Eyebrow>
          )}
          {headline && (
            <Headline textColor={headlineColor} size="extraSmall">
              {headline}
            </Headline>
          )}
        </Flex>
        <Container
          display={{
            mobile: `${!isMultiItemSection ? 'block' : 'none'}`,
            desktop: 'none',
          }}
        >
          <ActiveMedia media={mediaProps} />
        </Container>
        {isMultiItemSection && (
          <MultiItemModalSection
            items={sectionItems}
            activeIndex={activeMediaIndex}
            onClick={itemIndex => handleThumbnailClick(itemIndex)}
            videoEventHandlers={{ onPause, onEnded }}
          />
        )}
        {body && (
          <Body size="small" textColor={headlineColor}>
            <Markdown content={body} />
          </Body>
        )}
        {ctas?.length && (
          <Flex gap={spacing[24]} data-test-id="modal-section-cta-container">
            {ctas?.map(cta => (
              <LinkButton
                key={cta.fields.name}
                {...toCtaProps(cta)}
                width="fixed"
                size="medium"
              />
            ))}
          </Flex>
        )}

        {support && (
          <Support textColor={headlineColor}>
            <Markdown content={support} />
          </Support>
        )}
      </Flex>
      <Flex
        display={{ mobile: 'none', desktop: 'flex' }}
        maxWidth="776px"
        style={{ alignSelf: 'center' }}
      >
        <ActiveMedia media={mediaProps} />
      </Flex>
    </SectionContainer>
  );
};
