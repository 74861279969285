type DetailPageSegments = {
  discipline: string;
  class: string;
};

export const getClassDetailPageSegments = (path: string): DetailPageSegments => {
  const normalizedSlug = path
    .split('?')[0]
    .split('#')[0]
    ?.replace(/^\//, '')
    ?.replace(/classes\/?/, '');

  const [discipline, classSegment] = normalizedSlug.split('/');

  return {
    discipline,
    class: classSegment,
  };
};
