import { Flex, white, grey, Label, Support, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import type { ExtraCFUCardType } from '../utils/insertExtraCFUCard';

type Props = {
  item: ExtraCFUCardType;
  trackCardClick: (title: string, url: string) => void;
};

const ClassCardCTADescription: React.FC<React.PropsWithChildren<Props>> = ({
  item,
  trackCardClick,
}) => {
  const { headline, support, cta } = item;
  const ariaLabel = headline;
  const url = cta?.url;

  return (
    <ClassCardDetailsWrapper aria-label={ariaLabel}>
      <Flex flexDirection="column">
        <Label size="extraLarge">{headline}</Label>
        <StyledSupport size="medium" is="span">
          {support}
        </StyledSupport>
        <span>
          {url && (
            <StyledLink
              variant="primary"
              size="small"
              href={url}
              onClick={() => trackCardClick(headline, url)}
            >
              <>{cta.text}</>
            </StyledLink>
          )}
        </span>
      </Flex>
    </ClassCardDetailsWrapper>
  );
};

export default ClassCardCTADescription;

const ClassCardDetailsWrapper = styled.div`
  border-radius: 0 0 ${spacing[4]} ${spacing[4]};
  color: ${white};
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  padding: ${spacing[24]};
  height: 122px;
`;

const StyledSupport = styled(Support)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 8px;
  color: ${grey[40]};
`;

const StyledLink = styled(Link)`
  color: ${white};
`;
