import { brand, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

const PlusIcon = () => (
  <Plus viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15" strokeWidth="2" />
    <rect x="8" y="15" width="16" height="2" rx="1" />
    <rect x="17" y="8" width="16" height="2" rx="1" transform="rotate(90 17 8)" />
  </Plus>
);

const Plus = styled.svg`
  width: ${spacing[24]};
  circle {
    transition: stroke 0.4s ease 0s;
    stroke: ${brand.lightest};
  }
  rect {
    transition: fill 0.4s ease 0s;
    fill: ${brand.lightest};
  }
  ${media.desktopLarge`
    width: ${spacing[32]};
  `}
`;

export default PlusIcon;
