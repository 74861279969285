import {
  BreakpointWidths,
  grey,
  spacing,
  white,
  Flex,
  Label,
  Container,
} from '@pelotoncycle/design-system';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSubHeaderReference } from '@acme-ui/global/hooks';
import { toGridWidth } from '@ecomm/product-recommendations/utils/toGridWidth';
import { filterFalsyValues } from '@page-builder/utils/unions';
import TOCItems from './TOCItems';
import type { TOCItemLinkProps as TypeTOCItemLink } from './types';
import useSectionObserver from './useSectionObserver';

export type TableOfContentsProps = {
  ctas?: TypeTOCItemLink[];
  title?: string;
};

const TableOfContents: React.FC<TableOfContentsProps> = ({ ctas, title }) => {
  const [isScrolledBelow, setIsScrolledBelow] = useState(false);
  const headbandRef = useSubHeaderReference();

  useEffect(() => {
    const headbandTop = headbandRef.current?.offsetTop || 0;
    window.onscroll = () => {
      setIsScrolledBelow(window.scrollY >= headbandTop);
    };
  }, [headbandRef]);

  const sectionIds = React.useMemo(
    () => (ctas || []).map(cta => cta.link.url).filter(filterFalsyValues),
    [ctas],
  );

  const { activeId = sectionIds[0] } = useSectionObserver(sectionIds, {
    observerOptions: {
      // An anchor section is considered in-view when it's halfway up the viewport (50%)
      rootMargin: '50% 0px -50% 0px',
    },
  });

  return (
    <FullWidthContainerWithDivider
      hasBorder={isScrolledBelow}
      position="sticky"
      ref={headbandRef}
      backgroundColor={white}
      padding={{
        mobile: `${spacing[12]} 0 ${spacing[12]} ${spacing[12]}`,
        tablet: `${spacing[12]} ${spacing[64]}`,
        desktop: `${spacing[16]} ${spacing[40]}`,
      }}
    >
      <Flex
        flexDirection="row"
        alignItems="left"
        maxWidth={{ desktop: toGridWidth(12) }}
        centered
      >
        <JumpToContainer alignItems="center">
          <StyledLabel size="large">{title}</StyledLabel>
        </JumpToContainer>
        <NavContentContainer padding={`1px ${spacing[16]} 1px 0`}>
          <Flex
            horizontalPadding={`${spacing[16]} 0`}
            gap={spacing[8]}
            alignItems="center"
            flexDirection="row"
          >
            {ctas && (
              <TOCItems
                ctas={ctas}
                activeId={activeId}
                parentName="TableOfContents (CHAMP)"
                headbandRef={headbandRef.current}
              />
            )}
          </Flex>
        </NavContentContainer>
      </Flex>
    </FullWidthContainerWithDivider>
  );
};

export default TableOfContents;

const StyledLabel = styled(Label)`
  width: max-content;
`;

const JumpToContainer = styled(Flex)`
  border-right: 1px solid ${grey[40]};
  padding-right: ${spacing[16]};
`;

const FullWidthContainerWithDivider = styled(Container)<{ hasBorder: boolean }>`
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  z-index: 2;
  ${({ hasBorder }) =>
    hasBorder
      ? `border-bottom: 1px solid ${grey[40]};`
      : `border-bottom: 1px solid transparent;`};
  transition: all 0.3s linear;
`;

const NavContentContainer = styled(Flex)`
  white-space: nowrap;
  width: 100%;

  @media (max-width: ${BreakpointWidths.tablet}px) {
    ::-webkit-scrollbar {
      display: none;
    }

    overflow-x: scroll;
  }
`;
