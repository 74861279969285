import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useConnectedVideo from '@acme-ui/global/hooks/useConnectedVideo';
import useSnapAxis, { videoSnapCss } from '@acme-ui/global/hooks/useSnapAxis';
import type { SnapAxis } from '@acme-ui/global/hooks/useSnapAxis';
import useResponsiveVideoSrc from './useResponsiveVideoSrc';
import type { ResponsiveVideoSrc } from './useResponsiveVideoSrc';
import VideoToggle from './VideoToggle';

export type ConnectedVideoProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  windowActiveTether?: boolean;
  reduceMotionTether?: boolean;
  freezeTether?: boolean;
  toggleTheme?: 'light' | 'dark';
  toggleBearing?: 'left' | 'right';
  showPlayPauseToggle?: boolean;
  shouldPlay?: boolean;
  responsiveSrc?: ResponsiveVideoSrc;
};

const ConnectedVideo: React.FC<React.PropsWithChildren<ConnectedVideoProps>> = ({
  src,
  poster,
  windowActiveTether,
  reduceMotionTether,
  freezeTether,
  toggleTheme = 'light',
  toggleBearing = 'left',
  shouldPlay,
  showPlayPauseToggle,
  responsiveSrc,
  ...props
}) => {
  const { ref: videoRef } = useConnectedVideo({
    windowActiveTether,
    reduceMotionTether,
    freezeTether,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const { snapAxis, supportsObjectFit } = useSnapAxis(videoRef.current);
  const { videoSrc } = useResponsiveVideoSrc({ fallbackSrc: src, responsiveSrc });

  useEffect(() => {
    if (shouldPlay) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [shouldPlay, videoRef]);

  useEffect(() => {
    const activeRef = videoRef.current;
    const togglePlayState = () => setIsPlaying(!videoRef.current?.paused);

    if (activeRef) {
      activeRef.addEventListener('play', togglePlayState);
      activeRef.addEventListener('pause', togglePlayState);
    }

    return () => {
      activeRef?.removeEventListener('play', togglePlayState);
      activeRef?.removeEventListener('pause', togglePlayState);
    };
  }, [videoRef]);

  const togglePlayPause = () => {
    videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause();
  };

  return (
    <>
      {/* It is safe to do this because we don't need captions on our hero vids */}
      {/* eslint-disable-next-line */}
      <StyledVideo
        ref={videoRef}
        snapAxis={snapAxis}
        supportsObjectFit={supportsObjectFit}
        src={responsiveSrc ? videoSrc : src}
        autoPlay={shouldPlay}
        {...props}
      >
        {showPlayPauseToggle && (
          <VideoToggle
            onClick={togglePlayPause}
            playing={isPlaying}
            theme={toggleTheme}
            bearing={toggleBearing}
          />
        )}
      </StyledVideo>
    </>
  );
};

export default ConnectedVideo;

type StyledVideoProps = {
  snapAxis?: SnapAxis;
  supportsObjectFit: boolean;
};

const StyledVideo = styled.video<StyledVideoProps>`
  object-fit: cover;
  object-position: top center;

  position: relative;
  ${props => videoSnapCss(props.supportsObjectFit, props.snapAxis)};
`;
