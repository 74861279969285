import useGetBreakpointLabel from '@pelotoncycle/design-system/hooks/useGetBreakpointLabel';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

export type ResponsiveVideoSrc = Partial<Record<'mobile' | 'tablet' | 'desktop', string>>;

type Props = {
  fallbackSrc?: string;
  responsiveSrc?: ResponsiveVideoSrc;
};

const useResponsiveVideoSrc = ({ fallbackSrc, responsiveSrc }: Props) => {
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const { width } = useWindowSize();
  const screenSizeLabel = useGetBreakpointLabel(width);

  useEffect(() => {
    const { mobile, tablet, desktop } = responsiveSrc || {};
    switch (screenSizeLabel) {
      case 'desktop':
        setVideoSrc(desktop || tablet || mobile || fallbackSrc);
        break;
      case 'tablet':
        setVideoSrc(tablet || mobile || fallbackSrc);
        break;
      case 'mobile':
        setVideoSrc(mobile || fallbackSrc);
        break;
      default:
        setVideoSrc(fallbackSrc);
    }
  }, [responsiveSrc, fallbackSrc, screenSizeLabel]);

  return { videoSrc, setVideoSrc, screenSizeLabel };
};

export default useResponsiveVideoSrc;
