import React from 'react';
import styled from 'styled-components';

type Props = {
  href: string;
  onClick?: () => void;
  className?: string;
};

enum LinkTarget {
  Blank = '_blank',
  Self = '_self',
}

const LinkRenderer: React.FC<React.PropsWithChildren<Props>> = ({
  href,
  onClick,
  className,
  children,
}) => {
  const [url, target] = href.split('|').map(str => str.trim());
  const isExternalLink = target !== LinkTarget.Self && url.startsWith('http');
  const isTargetBlank = target === LinkTarget.Blank || isExternalLink;

  return (
    <Anchor
      href={url}
      target={isTargetBlank ? LinkTarget.Blank : LinkTarget.Self}
      rel={isTargetBlank ? 'noreferrer' : undefined}
      onClick={onClick}
      className={className}
    >
      {children}
    </Anchor>
  );
};

export default LinkRenderer;

const Anchor = styled.a`
  &::after {
    display: none;
  }
`;
