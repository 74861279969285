import type { MediaImageProps } from '@pelotoncycle/design-system';
import { Flex, spacing, brand, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { a11yOutline } from '@ecomm/buttons/buttons';
import { b5 } from '@ecomm/typography/body';
import PerformantImage from '@page-builder/modules/PerformantImage';

export type BannerProps = {
  dark: boolean;
};

type Props = {
  quote: string;
  image?: MediaImageProps;
};

const PressQuote: React.FC<React.PropsWithChildren<Props>> = ({ quote, image }) => {
  return (
    <QuoteContainer>
      {image && (
        <Flex alignItems="center" height={spacing[32]} justifyContent="center">
          <Logo
            alt={image.alt || ''}
            breakpointOptions={{ mobile: { height: 32 } }}
            loading="lazy"
            src={image.mobile || ''}
          />
        </Flex>
      )}
      <Quote dark={true}>{quote}</Quote>
    </QuoteContainer>
  );
};

export default PressQuote;

export const Logo = styled(PerformantImage)`
  height: 100%;

  img {
    height: 100%;
  }
`;

export const QuoteContainer = styled.li`
  min-width: 100vw;
  text-align: center;
  ${a11yOutline};

  ${media.tabletLarge`
    flex: 1;
    min-width: auto;
  `}
`;

export const Quote = styled.p`
  ${b5}
  color: ${({ dark }: BannerProps) => (dark ? white : brand.darkest)};
  max-width: 260px;
  margin: 15px auto 0;

  ${media.tabletLarge`
    max-width: 220px;
  `}

  ${media.desktopLarge`
    max-width: 260px;
  `}
`;
