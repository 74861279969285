import type { AxiosResponse, AxiosInstance } from 'axios';
import { AccessCodeErrorCode } from './AccessCodeErrorCode';
import { GUEST_PASS_EXCHANGE_ENDPOINT } from './endpoints';

export interface RedemptionCodeResponse {
  thirdPartyRefName: string;
  redemptionCode: string;
  redemptionUrl: string;
}

export interface RedemptionCodePayload {
  accessCode: string;
  tierId: string;
}

export const fetchAppleRedemptionCodeUrl = async (
  client: AxiosInstance,
  payload: RedemptionCodePayload,
): Promise<string> => {
  try {
    const response = await client.post<
      RedemptionCodePayload,
      AxiosResponse<RedemptionCodeResponse>
    >(GUEST_PASS_EXCHANGE_ENDPOINT, payload, {
      headers: {
        'Peloton-Platform': 'iOS_app',
      },
    });
    if (
      response.data.thirdPartyRefName !== 'apple' ||
      response.data.redemptionCode == null ||
      response.data.redemptionUrl == null
    ) {
      return Promise.reject(AccessCodeErrorCode.BaseException);
    }

    return response.data.redemptionUrl;
  } catch (error) {
    if (Number.isInteger(error.response.data?.errorCode)) {
      return Promise.reject(error.response.data.errorCode);
    }

    return Promise.reject(AccessCodeErrorCode.BaseException);
  }
};
