import React, { useState } from 'react';
import type { TypeComponent_overviewFields } from '@page-builder/lib/types';
import { toColorContent } from './utils';

export type ColorSelectionContextProps = {
  activeColorCaption?: string;
  activeColorSlug?: string;
  hasColorSelection: boolean;
  colorSlugs: string[];
  colorSelectionTitle: string;
  setActiveColorSlug: Function;
};

const DEFAULT_COLOR_SELECTION_VALUES = {
  activeColorCaption: undefined,
  activeColorSlug: undefined,
  hasColorSelection: false,
  colorSlugs: [],
  colorSelectionTitle: '',
  setActiveColorSlug: () => {},
};

export const ColorSelectionContext = React.createContext<ColorSelectionContextProps>(
  DEFAULT_COLOR_SELECTION_VALUES,
);

type ProviderProps = Pick<TypeComponent_overviewFields, 'colorSelection'>;

export const ColorSelectionContextProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ colorSelection, children }) => {
  const colorData =
    (colorSelection && toColorContent(colorSelection)) || DEFAULT_COLOR_SELECTION_VALUES;
  const { hasColorSelection, colorSelectionTitle, colorSlugs } = colorData;

  const [activeColorSlug, setActiveColorSlug] = useState(colorSlugs[0]);

  const activeColorCaption = (colorSelection?.fields.ctas || []).find(
    cta => cta.fields.productSlug === activeColorSlug,
  )?.fields.text;

  const value: ColorSelectionContextProps = {
    activeColorCaption,
    activeColorSlug,
    colorSelectionTitle,
    colorSlugs,
    hasColorSelection,
    setActiveColorSlug,
  };

  return (
    <ColorSelectionContext.Provider value={value}>
      {children}
    </ColorSelectionContext.Provider>
  );
};

export const useColorSelectionContext = () => React.useContext(ColorSelectionContext);
