import { black, spacing, Container, Support, List } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';

export const ModalContent = styled(Container)`
  strong {
    font-weight: 400;
  }
`;

export const NumberedList = styled.ol`
  counter-reset: orderedlist;
  margin: ${spacing[24]} 0;
`;

export const ListItem = styled.li`
  font-size: 14px;
  line-height: ${spacing[24]};
  letter-spacing: 0.3px;
  list-style: none;
  padding-left: 38px;
  padding-bottom: ${spacing[16]};
  ${media.tablet`
    font-size: 18px;
    line-height: 27px;
    padding-bottom: ${spacing[16]};
  `}
  &:last-of-type {
    padding-bottom: 0;
  }
  &::before {
    color: ${black};
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${spacing[24]};
    width: ${spacing[24]};
    float: left;
    margin: ${spacing[2]} 0 0 -${spacing[40]};
    padding: 1px 0 0 1px;
    font-weight: bold;
    text-align: center;
    border: 1px solid;
    border-radius: 100%;
    counter-increment: orderedlist;
    content: counter(orderedlist);
    ${media.tablet`
    font-size: 14px;
  `}
  }
`;

export const FinancingText = styled(Support)`
  margin: ${spacing[24]} auto;
  max-width: 960px;
`;

export const TextContainer = styled(Container)`
  flex: 1 1 0%;
`;

type BackgroundProps = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
};

export const StyledBackgroundImage = styled(Container)`
  ${({ mobile }) => mobile && `background-image: url(${mobile || ''})`};
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  border-radius: 5px;
  height: 175px;
  width: 100%;
  ${media.tablet<BackgroundProps>`
    ${({ tablet }) => tablet && `background-image: url(${tablet || ''})`};
    height: 370px;
  `}
  ${media.desktopLarge<BackgroundProps>`
    ${({ desktop }) => desktop && `background-image: url(${desktop || ''})`};
    background-size: cover;
    height: auto;
    width: 370px;
  `}
`;

const checkSvg =
  '<?xml version="1.0" encoding="utf-8"?><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.58681 7.89831L1.32733 4.63757L0 6.04698L4.95109 11L11 1.0423L9.38472 0L4.58681 7.89831Z" fill="%23888B93"/></svg>';

export const StyledList = styled(List)`
  padding-left: ${spacing[16]};
  > li > li {
    list-style-image: url('data:image/svg+xml;utf8,${checkSvg}');
    padding-left: ${spacing[8]};
  }
`;
