import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

export type CollapsibleProps = {
  collapseWidth?: boolean;
  collapseHeight?: boolean;
  toggleInert?: boolean;
};

export const Collapsible: React.FC<React.PropsWithChildren<CollapsibleProps>> = ({
  children,
  collapseWidth = false,
  collapseHeight = false,
  toggleInert = true,
}) => {
  const innerElementRef = useRef<HTMLDivElement | null>(null);
  const [innerDimensions, setInnerDimensions] = useState<DOMRect | null>(null);

  const isCollapsed = collapseWidth || collapseHeight;
  const isInert = toggleInert && isCollapsed;
  const staticWidth = innerDimensions?.width ? `${innerDimensions.width}px` : 'auto';
  const staticHeight = innerDimensions?.height ? `${innerDimensions.height}px` : 'auto';

  useEffect(() => {
    if (innerElementRef.current) {
      const dimensions = innerElementRef.current.getBoundingClientRect();
      setInnerDimensions(dimensions);
    } else {
      setInnerDimensions(null);
    }
  }, []);

  return (
    <StyledCollapsibleContainer
      {...{ inert: isInert ? true : undefined }}
      aria-hidden={isInert ? 'true' : 'false'}
      width={collapseWidth ? '0' : staticWidth}
      height={collapseHeight ? '0' : staticHeight}
      data-test-id="collapsible-container"
    >
      <div ref={innerElementRef}>{children}</div>
    </StyledCollapsibleContainer>
  );
};

const StyledCollapsibleContainer = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
  transition: width 0.2s ease-out, height 0.2s ease-out;
`;
