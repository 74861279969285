import type { TagLink } from 'contentful';
import React from 'react';
import type { TypeComponentCtaFields } from '@page-builder/lib/types';
import ValuePropsFormattedCta from './ValuePropsFormattedCta';

type FormattedCtaProps = Pick<
  TypeComponentCtaFields,
  'name' | 'text' | 'ctaIcon' | 'modal' | 'link'
> & {
  tags: TagLink[] | string[];
};

export type ClickEventProps = {
  properties: {
    href?: string;
    parentType: string;
    parent?: string;
    unitName: string;
    linkName?: string;
    linkTo?: string;
  };
};

type Props = {
  parentName?: string;
  variant: 'body' | 'primary';
  size: 'small' | 'large';
  formattedCtas: FormattedCtaProps[];
  handleClick: (isModal: boolean, index: number, eventProps: ClickEventProps) => void;
};

const ValueProps: React.FC<React.PropsWithChildren<Props>> = ({
  parentName,
  variant,
  size,
  formattedCtas,
  handleClick,
}) => {
  return (
    <>
      {formattedCtas.map((formattedCta, index) => {
        return (
          <ValuePropsFormattedCta
            parentName={parentName}
            variant={variant}
            size={size}
            formattedCta={formattedCta}
            handleClick={handleClick}
            index={index}
            key={index}
          />
        );
      })}
    </>
  );
};

export default ValueProps;
