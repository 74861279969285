import { spacing, Support, Label, Flex } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';
import LinkOrModalCTA from '@page-builder/components/LinkOrModalCTA/LinkOrModalCTA';
import { FullWidthMediaModuleModal } from '../FullWidthMediaModule';

type Props = {
  ctas?: TypeComponentCta[];
  labelText?: ReactNode;
  supportText?: ReactNode;
  primaryColor?: CSSProperties['color'];
  secondaryColor?: CSSProperties['color'];
};

const TextAndCTA: React.FC<React.PropsWithChildren<Props>> = ({
  ctas,
  labelText,
  supportText,
  primaryColor,
  secondaryColor,
}) => {
  const { trackEvent } = useTracking();

  const handleOnClick = (cta: TypeComponentCta) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Generic Text With Media',
        parent: 'Fifty Fifty Media',
        unitName: 'Fifty Fifty Media CTA',
        linkName: cta.fields.text,
        linkTo: cta.fields.link?.fields.url,
      },
    });
  };

  return (
    <Flex
      flexDirection="column"
      removeOuterMargins
      verticalPadding={{ desktop: `0 ${spacing[48]}` }}
      style={{ gridArea: 'cta', alignSelf: 'flex-end' }}
    >
      <Flex flexDirection={{ mobile: 'column', tablet: 'row' }} gap={spacing[16]}>
        {ctas &&
          ctas.map(cta => (
            <LinkOrModalCTA
              key={cta.fields.name}
              cta={cta}
              handleOnClick={() => handleOnClick(cta)}
              Modal={FullWidthMediaModuleModal}
              parent={{
                parentName: 'Fifty Fifty Media',
                parentType: 'Component: Generic Text with Media',
              }}
            />
          ))}
      </Flex>
      {labelText && (
        <Label
          size="extraLarge"
          weight="medium"
          textColor={secondaryColor}
          style={{ marginTop: spacing[16] }}
        >
          {labelText}
        </Label>
      )}
      {supportText && (
        <Support size="medium" textColor={primaryColor} style={{ marginTop: spacing[8] }}>
          {supportText}
        </Support>
      )}
    </Flex>
  );
};

export default TextAndCTA;
