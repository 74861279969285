import { Flex, spacing } from '@pelotoncycle/design-system';
import type {
  TypeComponentProductBlockFields,
  TypeComponentProductCard,
} from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';
import { ANNUAL, MONTHLY } from './CheckoutContext';
import ProductComparisonCardContainer from './ProductComparisonCardContainer';
import ProductComparisonCardsToggle from './ProductComparisonCardsToggle';

export type ProductComparisonCardsProps = {
  productCards: TypeComponentProductCard[];
  annualPromotion?: TypeComponentProductBlockFields['annualPromotion'];
  monthlyPromotion?: TypeComponentProductBlockFields['monthlyPromotion'];
};

const ProductComparisonCards: React.FC<
  React.PropsWithChildren<ProductComparisonCardsProps>
> = ({ productCards, annualPromotion, monthlyPromotion }) => {
  const { trackEvent } = useTracking();
  const [isMonthlyToggleSelected, setIsMonthlyToggleSelected] = React.useState(true);
  const [activeProductCardIndex, setActiveProductCardIndex] = React.useState(0);

  // Certain locales don't have annual billing; in that case we shouldn't render
  // the toggle and only show the monthly pricing.
  const showToggle =
    productCards &&
    productCards.every(card => {
      const { totalPricing: annualPricing } = card.fields;
      return annualPricing !== 'norender';
    });
  const monthlyToggleSubtext =
    monthlyPromotion !== 'norender' ? monthlyPromotion : undefined;
  const annualToggleSubtext =
    annualPromotion !== 'norender' ? annualPromotion : undefined;

  const handleToggleChange = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Product Block',
        parent: 'Product Comparison Cards Container',
        unitName: 'Product Comparison Card Toggle',
        billingType: !isMonthlyToggleSelected ? MONTHLY : ANNUAL,
        linkName: !isMonthlyToggleSelected ? MONTHLY : ANNUAL,
        page: window.location.pathname,
      },
    });
    setIsMonthlyToggleSelected(!isMonthlyToggleSelected);
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      gap={{
        mobile: spacing[24],
        tablet: spacing[32],
        desktop: spacing[40],
      }}
    >
      {showToggle && (
        <ProductComparisonCardsToggle
          handleToggleChange={handleToggleChange}
          monthlyPromotion={monthlyToggleSubtext}
          annualPromotion={annualToggleSubtext}
        />
      )}
      <ProductComparisonCardContainer
        cards={productCards}
        isMonthlyToggleSelected={isMonthlyToggleSelected}
        setActiveProductCardIndex={setActiveProductCardIndex}
        activeProductCardIndex={activeProductCardIndex}
      />
    </Flex>
  );
};

export default ProductComparisonCards;
