import {
  Body,
  Container,
  Display,
  Label,
  Flex,
  spacing,
  ResponsiveImage,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media } from '@peloton/styles';
import { toDisplayName } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import Markdown from '@page-builder/utils/Markdown';
import type {
  ImageReferenceType as ImageReference,
  ProductReferenceType as ProductReference,
} from '../../../types/referenceTypes';
import { Rating } from './Rating';
import ValueProps from './ValueProps';
import type { ValuePropsType } from './ValueProps';

export const ProductCard: React.FC<{
  product: ProductReference;
  imageOverride?: ImageReference;
  description?: string;
  financing?: string;
  valueProps?: ValuePropsType;
}> = ({ valueProps, product, description, financing, imageOverride }) => {
  const { textColor, headlineColor } = useTheme();

  const displayName = toDisplayName(product.bundleType as BundleType);
  const productImage = product.image;
  const cloudinaryDefault =
    imageOverride && toCloudinarySrc({ src: imageOverride.mobile, width: 600 });
  const image = imageOverride
    ? {
        mobile: cloudinaryDefault,
        tablet: imageOverride.tablet
          ? toCloudinarySrc({ src: imageOverride.tablet, width: 768 })
          : cloudinaryDefault,
        desktop: imageOverride.desktop
          ? toCloudinarySrc({ src: imageOverride.desktop, width: 1024 })
          : cloudinaryDefault,
        alt: imageOverride.alt,
      }
    : {
        mobile: productImage.url,
        tablet: productImage.url,
        desktop: productImage.url,
        alt: productImage.alt,
      };

  return (
    <>
      <Flex
        flexDirection="column"
        gap={spacing[16]}
        flexBasis="100%"
        alignItems="center"
        verticalMargin={{ mobile: `0 ${spacing[32]}`, desktop: `0 ${spacing[48]}` }}
        style={{ gridRow: '1/2' }}
      >
        <Container verticalMargin={`0 ${spacing[8]}`}>
          <StyledImage
            mobile={image.mobile || ''}
            tablet={image.tablet}
            desktop={image.desktop}
            alt={product.image.alt}
          />
        </Container>
        <Rating
          productName={product.bundleType}
          link={product.shopLink ?? product.link}
        />
        <Flex flexDirection="column" gap={spacing[16]}>
          <Flex flexDirection="column">
            <Display size="small" textColor={headlineColor}>
              {displayName}
            </Display>
            {description && (
              <Body size="small" textColor={textColor}>
                {description}
              </Body>
            )}
          </Flex>
          {financing && (
            <Label size="small" textColor={textColor}>
              <Markdown content={financing} />
            </Label>
          )}
        </Flex>
      </Flex>
      <ValueProps valuePropSet={valueProps} />
    </>
  );
};

const StyledImage = styled(ResponsiveImage)`
  > img {
    display: block;
    object-fit: cover;
    max-height: 135px;
    border-radius: ${spacing[8]};
    width: 100%;
    aspect-ratio: 19/17;
    object-fit: cover;
    ${media.tabletLarge`
      aspect-ratio: 75/49;
      max-height: 392px;
      border-radius: ${spacing[12]};
    `}
  }
`;
