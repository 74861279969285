import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

type Props = {
  children: React.ReactNode;
  isVisible?: boolean;
  marginTop?: { mobile: string; tablet: string };
};

const GridCards: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isVisible = true,
  marginTop = { mobile: '0', tablet: '0' },
}) => {
  return (
    <Wrapper isVisible={isVisible} marginTop={marginTop}>
      <StyledGridCards>{children}</StyledGridCards>
    </Wrapper>
  );
};

export default GridCards;

const Wrapper = styled.div<Props>`
  transition: max-height 400ms ease-in-out, opacity 300ms ease 300ms;
  padding-top: ${({ isVisible, marginTop }) => isVisible && marginTop?.mobile};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  max-height: ${({ isVisible, marginTop }) =>
    isVisible ? `calc(3000x + ${marginTop?.tablet})` : '0'};
  overflow: hidden;
  ${media.tabletXLarge<Props>`
    padding-top: ${({ isVisible, marginTop }) => isVisible && marginTop?.tablet};
  `};
  ${media.desktopLarge<Props>`
    max-height: ${({ isVisible, marginTop }) =>
      isVisible ? `calc(808px + ${marginTop?.tablet})` : '0'};
    
  `};
`;

const StyledGridCards = styled.div<Props>`
  display: grid;
  gap: ${spacing[16]};
  margin: 0 auto;

  & > * {
    border-radius: ${spacing[8]};
    overflow: hidden;
  }

  & > * {
    aspect-ratio: 348 / 311;
  }

  ${media.tabletXLarge`
    padding-inline: ${spacing[16]};
    gap: ${spacing[24]};
    padding-inline: ${spacing[64]};
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    & > * {
      aspect-ratio: 308 / 275;
    }
  `}

  ${media.desktopLarge`

    & > * {
      aspect-ratio: unset;
    }
  
    padding-inline: ${spacing[40]};
    box-sizing: content-box;
    max-width: 1224px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 40% 14% 40%;
    aspect-ratio: 153 / 101;
    & > *:nth-child(1) {
      grid-column: 1; 
      grid-row: 1 / 3;
    }
    & > *:nth-child(2) {
      grid-column: 2; 
      grid-row: 1 / 2;
    }
    & > *:nth-child(3) {
      grid-column: 1; 
      grid-row: 3 / -1;
    }
    & > *:nth-child(4) {
      grid-column: 2; 
      grid-row: 2 / -1;
    }
  `}
`;
