import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { VideoEvent, toVideoStatusProperties } from '@ecomm/analytics/trackVideo';
import autoPlayWithSound from '@ecomm/video/autoPlayWithSound';
import type { VideoProps, EventProps } from '@ecomm/video/VideoElement';
import VideoElement from '@ecomm/video/VideoElement';

type TrackingProps = {
  eventProps: {
    properties?: object;
  };
};

type TrackingVideoElementProps = VideoProps &
  EventProps &
  TrackingProps & { poster?: string };

type TrackType = (event: any, data: any) => void;

export const toOnPlay = (track: TrackType) => (
  event: React.SyntheticEvent<HTMLMediaElement>,
) => {
  const mediaEl = event.target as HTMLMediaElement;
  const videoStatusProperties = toVideoStatusProperties(mediaEl);
  track(VideoEvent.PLAYED, videoStatusProperties);
};

export const toOnPause = (track: TrackType) => (
  event: React.SyntheticEvent<HTMLMediaElement>,
) => {
  const mediaEl = event.target as HTMLMediaElement;
  const videoStatusProperties = toVideoStatusProperties(mediaEl);
  if (mediaEl.ended) return;
  track(VideoEvent.PAUSED, videoStatusProperties);
};

export const toOnStarted = (track: TrackType) => (
  event: React.SyntheticEvent<HTMLMediaElement>,
) => {
  const mediaEl = event.target as HTMLMediaElement;
  const videoStatusProperties = toVideoStatusProperties(mediaEl);

  if (mediaEl.currentTime > 0) {
    return;
  }

  mediaEl.autoplay && autoPlayWithSound(event);
  track(VideoEvent.STARTED, videoStatusProperties);
};

export const toOnEnded = (track: TrackType) => (
  event: React.SyntheticEvent<HTMLMediaElement>,
) => {
  const mediaEl = event.target as HTMLMediaElement;
  const videoStatusProperties = toVideoStatusProperties(mediaEl);
  track(VideoEvent.COMPLETED, videoStatusProperties);
};

export const TrackingVideoElement: React.FC<
  React.PropsWithChildren<TrackingVideoElementProps>
> = ({ eventProps, ...videoProps }) => {
  const { trackEvent } = useTracking();

  const track = useCallback(
    (event: string, additionalData: object) => {
      const passedProps = eventProps?.properties;
      trackEvent({
        event,
        properties: {
          ...additionalData,
          ...passedProps,
        },
      });
    },
    [trackEvent, eventProps],
  );

  const onPlay = React.useMemo(() => toOnPlay(track), [track]);
  const onPause = React.useMemo(() => toOnPause(track), [track]);
  const onStarted = React.useMemo(() => toOnStarted(track), [track]);
  const onEnded = React.useMemo(() => toOnEnded(track), [track]);
  return (
    <VideoElement
      {...videoProps}
      onCanPlay={onStarted}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
    />
  );
};

export default TrackingVideoElement;
