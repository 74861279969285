import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import { CURRENT_ROW_PRICE_FOR_CA } from '@ecomm/financing/priceConstants';
import { toDollars } from '@ecomm/models/Money';
import { BundleType } from '@ecomm/shop/models/BundleType';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  price?: string;
  bundleType: BundleType;
  displayValue: 'total' | 'monthly';
};

const DisplayPrice: React.FC<React.PropsWithChildren<Props>> = ({
  price,
  bundleType,
  displayValue,
}) => {
  const financingValues = useFinancingNext(bundleType);
  const perMonthSuffix = useMicroCopy('perMonthSuffix');
  const priceSuffix = displayValue === 'monthly' ? perMonthSuffix.slice(0, -1) : '';
  const isToggleActive = useIsToggleActive();
  const showRowStrikethroughActive = isToggleActive('showRowStrikethrough');

  return (
    <PriceDisplay>
      {price ? (
        <>
          <Markdown content={price} />
          {priceSuffix}
        </>
      ) : (
        <Markdown
          content={`{price, number, currency}${priceSuffix}`}
          values={{
            price:
              showRowStrikethroughActive && bundleType === BundleType.Row
                ? toDollars(CURRENT_ROW_PRICE_FOR_CA)
                : toDollars(financingValues[displayValue]),
          }}
        />
      )}
    </PriceDisplay>
  );
};

export default DisplayPrice;

export const PriceDisplay = styled.p`
  /*
    - our strikethrough text from Contentful renders as a <del> element by default
  */
  del {
    color: ${grey[60]};
  }

  /*
    - for strikethrough element a11y
    - we should consider making this a global style for all strikethrough elements
  */
  del::before,
  del::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  del::before {
    content: ' [deletion start] ';
  }

  del::after {
    content: ' [deletion end] ';
  }
`;
