import { grey, Button, spacing, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';
import { ChevronLight, Orientation } from '@ecomm/icons';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import { useCarouselBehavior } from './hooks/useCarouselBehavior';

export type LinkListItem = {
  id: string;
  text: string;
  href: string;
};

export type UpdateHandler = (p: {
  isOverflowing: boolean;
  hasScrollEnded: boolean;
  canSlideLeft: boolean;
  canSlideRight: boolean;
}) => void;

export type ScrollableLinkListProps = {
  items: LinkListItem[];
  onUpdate?: UpdateHandler;
};

export const ScrollableLinkList: React.FC<
  React.PropsWithChildren<ScrollableLinkListProps>
> = ({ items, onUpdate }) => {
  const scrollableListRef = useRef<HTMLUListElement | null>(null);
  const {
    isOverflowing,
    hasScrollEnded,
    canSlideLeft,
    canSlideRight,
    slideRight,
    slideLeft,
  } = useCarouselBehavior(scrollableListRef, {
    hasCenteredScrollSnap: true,
  });

  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const trackClick = useCallback(
    (name: string, url: string) => {
      trackEvent({
        event: 'Clicked CLP',
        properties: {
          parentType: 'Component: Generic Text with Media',
          parent: 'Component: Generic List',
          unitName: 'Scrollable Link List',
          linkName: name,
          page: pathName,
          linkTo: url,
        },
      });
    },
    [pathName, trackEvent],
  );

  useEffect(
    () =>
      onUpdate?.({
        isOverflowing,
        hasScrollEnded,
        canSlideLeft,
        canSlideRight,
      }),
    [isOverflowing, hasScrollEnded, canSlideLeft, canSlideRight],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {/* Previous button */}
      {isOverflowing && (
        <StyledButtonWrapper isPrevious isVisible={canSlideLeft}>
          <StyledPreviousButton
            onClick={slideLeft}
            type="button"
            disabled={!canSlideLeft}
            aria-hidden="true" /* [1] */
            tabIndex={-1}
            data-test-id="link-list-carousel-previous-button"
          >
            <ChevronLight
              width={24}
              height={24}
              orientation={Orientation.Left}
              color="white"
            />
          </StyledPreviousButton>
        </StyledButtonWrapper>
      )}

      {/* List */}
      <StyledScrollableList ref={scrollableListRef}>
        {items.map(item => {
          if (!item.href || !item.text) {
            return null;
          }

          return (
            <StyledScrollableListItem key={item.id}>
              <Link
                href={item.href}
                onClick={() => trackClick(item.text, item.href)}
                hasUnderline={false}
              >
                <StyledButton is="div" text={item.text} size="small" color="light" />
              </Link>
            </StyledScrollableListItem>
          );
        })}
      </StyledScrollableList>

      {/* Next button */}
      {isOverflowing && (
        <StyledButtonWrapper isVisible={canSlideRight}>
          <StyledNextButton
            onClick={slideRight}
            type="button"
            disabled={!canSlideRight}
            aria-hidden="true" /* [1] */
            tabIndex={-1}
            data-test-id="link-list-carousel-next-button"
          >
            <ChevronLight
              width={24}
              height={24}
              orientation={Orientation.Right}
              color="white"
            />
          </StyledNextButton>
        </StyledButtonWrapper>
      )}
    </>
  );
};

const ARROW_BUTTON_ANIMATION_DURATION = '.2s';
const SHADE_ANIMATION_DURATION = '.15s';
const SHADE_ANIMATION_DELAY = '.05s';

const StyledScrollableList = styled.ul`
  display: flex;
  padding: 1rem 0;
  margin: -1rem 0;
  position: relative;
  overflow: auto;
  scroll-snap-type: x proximity;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${media.tabletXLarge`
    gap: ${spacing[8]};
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledScrollableListItem = styled.li`
  scroll-snap-align: start;
  padding-left: ${spacing[8]};

  &:last-of-type {
    padding-right: ${spacing[32]};
  }

  ${media.tabletXLarge`
    scroll-snap-align: center;
    padding-left: 0;

    &:last-of-type {
      padding-right: 0;
    }
  `}
`;

const StyledButtonWrapper = styled.div<{ isVisible: boolean; isPrevious?: boolean }>`
  display: none;
  width: 0;
  height: ${spacing[40]};
  position: relative;

  ${media.tabletXLarge`
    display: block;
  `}

  &::before {
    content: '';
    display: block;
    width: ${spacing[56]};
    position: absolute;
    top: -1px;
    bottom: -1px;
    ${({ isVisible }) =>
      isVisible
        ? `
      transition: opacity ${SHADE_ANIMATION_DURATION} ease-in-out;
      opacity: 1;
      `
        : `
      transition: opacity ${SHADE_ANIMATION_DURATION} ${SHADE_ANIMATION_DELAY} ease-in-out;
      opacity: 0;
    `}
    z-index: 1;
    pointer-events: none;
    ${({ isPrevious }) =>
      isPrevious
        ? `
    left: -1px;
    background: linear-gradient(90deg, ${grey[90]} 0%, ${grey[90]} 30%, ${rgba(
            grey[90],
            0,
          )} 100%);
    `
        : `
    right: -1px;
    background: linear-gradient(-90deg, ${grey[90]} 0%, ${grey[90]} 30%, ${rgba(
            grey[90],
            0,
          )} 100%);
    `}
  }

  & button {
    ${({ isVisible }) =>
      isVisible
        ? `
      transition: opacity ${ARROW_BUTTON_ANIMATION_DURATION} ${SHADE_ANIMATION_DELAY} ease-in-out;
      opacity: 1;
      `
        : `
      transition: opacity ${ARROW_BUTTON_ANIMATION_DURATION} ease-in-out;
      opacity: 0;
      pointer-events: none;
    `}
  }
`;

const StyledPreviousButton = styled.button`
  display: flex;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-${22 / 16}rem, -50%);
  z-index: 2;
`;

const StyledNextButton = styled.button`
  display: flex;
  padding: 1rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(${22 / 16}rem, -50%);
  z-index: 2;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
  white-space: nowrap;
  font-size: ${13 / 16}rem !important;
  font-weight: 400 !important;
  width: auto !important;
  padding: ${spacing[12]} ${spacing[16]} !important;
  color: ${white} !important;
  background-color: ${rgba(grey[70], 0.25)} !important;
  border: none !important;

  &:hover {
    background-color: ${rgba(grey[70], 0.5)} !important;
  }
`;
