export type ClassPageItems = {
  fields: {
    name: string;
    text: { fields: { headline: string } };
  };
};

export interface ClassPageLabels {
  rideDateFormat: string;
  explicitLabel: string;
  subtitlesLabel: string;
  equipmentLabel: string;
  featuringMusicByLabel: string;
  playlistLabel: string;
  viewMoreLabel: string;
  viewLessLabel: string;
  classPlanLabel: string;
  moreInfoLabel: string;
  movementLabel: string;
  muscleGroupLabel: string;
  otherGroupLabel: string;
  primaryMuscleGroupLabel: string;
  secondaryMuscleGroupLabel: string;
  tertiaryMuscleGroupLabel: string;
  rowerEquipmentLabel: string;
  treadEquipmentLabel: string;
  bikeEquipmentLabel: string;
  beginnerLabel: string;
  intermediateLabel: string;
  advancedLabel: string;
  airTimeLabel: string;
  instructorLabel: string;
  disciplineLabel: string;
  contentSensitivityLabel: string;
  cardioClassLabel: string;
  cyclingClassLabel: string;
  meditationClassLabel: string;
  outdoorClassLabel: string;
  rowingClassLabel: string;
  runningClassLabel: string;
  strengthClassLabel: string;
  stretchingClassLabel: string;
  yogaClassLabel: string;
  walkingClassLabel: string;
  waysToAccessLabel: string;
}

const classPageLabelValues: ClassPageLabels = {
  rideDateFormat: 'rideDateFormat',
  explicitLabel: 'explicitLabel',
  subtitlesLabel: 'subtitlesLabel',
  equipmentLabel: 'equipmentLabel',
  featuringMusicByLabel: 'featuringMusicByLabel',
  playlistLabel: 'playlistLabel',
  viewMoreLabel: 'viewMoreLabel',
  viewLessLabel: 'viewLessLabel',
  classPlanLabel: 'classPlanLabel',
  moreInfoLabel: 'moreInfoLabel',
  movementLabel: 'movementLabel',
  muscleGroupLabel: 'muscleGroupLabel',
  otherGroupLabel: 'otherGroupLabel',
  primaryMuscleGroupLabel: 'primaryMuscleGroupLabel',
  secondaryMuscleGroupLabel: 'secondaryMuscleGroupLabel',
  tertiaryMuscleGroupLabel: 'tertiaryMuscleGroupLabel',
  rowerEquipmentLabel: 'rowerEquipmentLabel',
  treadEquipmentLabel: 'treadEquipmentLabel',
  bikeEquipmentLabel: 'bikeEquipmentLabel',
  beginnerLabel: 'beginnerLabel',
  intermediateLabel: 'intermediateLabel',
  advancedLabel: 'advancedLabel',
  airTimeLabel: 'airTimeLabel',
  instructorLabel: 'instructorLabel',
  disciplineLabel: 'disciplineLabel',
  contentSensitivityLabel: 'contentSensitivityLabel',
  cardioClassLabel: 'cardioClassLabel',
  cyclingClassLabel: 'cyclingClassLabel',
  meditationClassLabel: 'meditationClassLabel',
  outdoorClassLabel: 'outdoorClassLabel',
  rowingClassLabel: 'rowingClassLabel',
  runningClassLabel: 'runningClassLabel',
  strengthClassLabel: 'strengthClassLabel',
  stretchingClassLabel: 'stretchingClassLabel',
  yogaClassLabel: 'yogaClassLabel',
  walkingClassLabel: 'walkingClassLabel',
  waysToAccessLabel: 'waysToAccessLabel',
};

export const mapClassPageLabelsFromContentfulToProps = (
  items: any[],
): ClassPageLabels => {
  const arrayOfItems = items.map(item => ({
    fields: {
      name: item.fields?.name,
      text: { fields: { headline: item.fields?.text?.fields?.headline } },
    },
  }));

  const obj = arrayOfItems.reduce<Partial<ClassPageLabels>>(
    (acc, { fields: { name, text } }) => {
      if (classPageLabelValues[name]) {
        acc[classPageLabelValues[name]] = text.fields.headline;
      }

      return acc;
    },
    {},
  );

  const missingLabels = Object.keys(classPageLabelValues).filter(
    label => !(label in obj),
  );

  if (missingLabels.length > 0) {
    throw new Error(`Labels are missing: ${missingLabels.join(', ')}`);
  }

  return obj as ClassPageLabels;
};
