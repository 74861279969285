import { Container, Flex, Label, spacing, Support } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { useGlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import { CFUProvider } from '@page-builder/modules/Overview/CFUPackageProvider';
import { useColorSelectionContext } from '@page-builder/modules/Overview/ColorSelectionContext';
import { colorSlugMap } from '@page-builder/modules/Overview/constants';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import { Divider } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import useCanAddToCart from '@page-builder/modules/Overview/ShopDrawers/useCanAddToCart';
import {
  getExistingQueryValueFor,
  setUrlQueryParams,
} from '@page-builder/utils/helpers/urlParams';
import ColorChoice from './ColorChoice';

const COLOR_PARAM_NAME = 'color';

const ColorSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    activeColorCaption,
    activeColorSlug,
    colorSlugs,
    setActiveColorSlug,
    colorSelectionTitle,
    hasColorSelection,
  } = useColorSelectionContext();
  const { productBundleType } = useShopContext();
  const { setGlobalTheme } = useGlobalUiStateContext();
  const { trackEvent } = useTracking();
  const canAddToCart = useCanAddToCart();

  useEffect(() => {
    if (!hasColorSelection) {
      return;
    }

    // If color choice is provided via url param AND is valid, use it
    const colorQueryValue = getExistingQueryValueFor(COLOR_PARAM_NAME);
    if (colorQueryValue) {
      const urlSlug = Object.keys(colorSlugMap).find(
        slug => colorSlugMap[slug].displayName === colorQueryValue,
      );

      if (urlSlug) {
        setGlobalTheme(colorSlugMap[urlSlug].colorName);
        setActiveColorSlug(urlSlug);
        return;
      }
    }

    // Otherwise, default to first
    const firstSlug = colorSlugs[0];
    if (firstSlug) {
      const { colorName } = colorSlugMap[firstSlug];

      setGlobalTheme(colorName);
      setActiveColorSlug(firstSlug);
    }
  }, []);

  const isSingleColorOption = colorSlugs && colorSlugs.length === 1;

  if (!hasColorSelection || !activeColorSlug || isSingleColorOption) {
    return null;
  }

  const onClick = (colorSlug: string) => {
    const { colorName, packageName, displayName } = colorSlugMap[colorSlug];

    trackEvent({
      event: 'Selected Attribute',
      properties: {
        page: window?.location?.pathname || '',
        category: productBundleType,
        attributeCategory: colorSelectionTitle,
        selected: displayName,
        product: productBundleType,
        packageName: packageName,
        sku: colorSlug,
        unitName: 'CFUColorSelection',
        parentType: 'Component: Generic List',
        parent: 'CFU Color Selection',
      },
    });

    setActiveColorSlug(colorSlug);
    setGlobalTheme(colorName);
    setUrlQueryParams(COLOR_PARAM_NAME, displayName);
  };

  const colorButtons = colorSlugs.map(colorSlug => {
    if (!colorSlugMap[colorSlug]) {
      return null;
    }

    const { bikeColor, colorName } = colorSlugMap[colorSlug];
    const selected = colorSlug === activeColorSlug;
    // TODO: Consider useProductsByKeys hook to fetch multiple CFUs, instead of multiple Providers to fetch individually
    return (
      <CFUProvider key={`limited-edition-bike-plus-${colorName}`} slug={colorSlug}>
        <ColorChoice
          bikeColor={bikeColor}
          colorName={colorName}
          colorSlug={colorSlug}
          onClick={onClick}
          selected={selected}
        />
      </CFUProvider>
    );
  });

  const flexVerticalPadding = canAddToCart
    ? `${spacing[32]} ${spacing[16]}`
    : `${spacing[32]} ${spacing[8]}`;

  return (
    <Container verticalPadding={`${spacing[16]} 0`}>
      <Divider topPadding={0} />
      <Flex
        flexDirection="column"
        gap={spacing[16]}
        verticalPadding={flexVerticalPadding}
      >
        <Flex flexDirection="row" alignItems="center" gap={spacing[8]}>
          <Label weight="medium" size="large">
            {colorSelectionTitle}
          </Label>
          <Support size="large">{activeColorCaption}</Support>
        </Flex>
        <Flex flexDirection="row" gap={spacing[8]}>
          {colorButtons}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ColorSection;
