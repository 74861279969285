import { Eyebrow, grey, spacing, Stars } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import { useLocale } from '@peloton/internationalize';
import {
  bikePlusReviews,
  bikeReviews,
  guideReviews,
  refurbishedBikeReviews,
  refurbishedBikePlusReviews,
  rowReviews,
  treadPlusReviews,
  treadReviews,
} from '@peloton/links/wwwPaths';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import type { Language } from '@ecomm/graphql/types.generated';
import { DeviceType } from '@ecomm/models/DeviceType';
import { MINIMUM_REVIEWS_QUANTITY } from '@ecomm/reviews/consts';
import { useStatsQuery } from '@ecomm/reviews/data/reviews/Stats.generated';
import type { TypeComponentGenericTextFields } from '@page-builder/lib/types';
import { toEquipmentType } from './toEquipmentType';

type ReviewsProps = {
  totalReviewsText: TypeComponentGenericTextFields['headline'];
  device: TypeComponentGenericTextFields['name'];
  parentName?: string;
};

export const toReviewLink = (device: DeviceType) => {
  switch (device) {
    case DeviceType.Bike:
      return bikeReviews;
    case DeviceType.BikePlus:
      return bikePlusReviews;
    case DeviceType.RefurbishedBike:
      return refurbishedBikeReviews;
    case DeviceType.RefurbishedBikePlus:
      return refurbishedBikePlusReviews;
    case DeviceType.Tread:
      return treadReviews;
    case DeviceType.TreadPlus:
      return treadPlusReviews;
    case DeviceType.Guide:
      return guideReviews;
    case DeviceType.Row:
      return rowReviews;
    default:
      return bikeReviews;
  }
};

const DEFAULT_RATING_THRESHOLD = 4.0;

const Reviews: React.FC<React.PropsWithChildren<ReviewsProps>> = ({
  totalReviewsText,
  device,
}) => {
  const { trackEvent } = useTracking();
  const { errorReporter } = useErrorReporter();
  const locale = useLocale();
  const displayLang = locale.split('-')[0];
  const lang = displayLang as Language;
  const toFormattedText = useGetTextFormatter();

  const { loading, error, data } = useStatsQuery({
    throwError: false,
    suspend: false,
    variables: {
      equipmentType: toEquipmentType(device as DeviceType),
      lang: lang,
      excludeFamily: true,
    },
    reportSwallowedError: errorReporter.reportError,
  });

  if (loading) return <Placeholder />;
  else if (!data || error || loading) return <></>;

  const showRatingIfEnoughReviews =
    data.reviewsByEquipmentType.totalCount >= MINIMUM_REVIEWS_QUANTITY;

  const hasMinimumRating =
    data.reviewsByEquipmentType.averageOverallRating >= DEFAULT_RATING_THRESHOLD;

  const handleLinkClick = () => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        parentType: 'Component: Overview',
        parent: `${device} Overview Module`,
        unitName: device,
        linkName: 'Reviews',
      },
    });

    const reviewsSection =
      document.getElementById('generic-review-cards') ||
      document.getElementById('ai-review-cards');
    if (reviewsSection) {
      // offset by 48 pixels for subnav
      const y = reviewsSection.getBoundingClientRect().top + window.pageYOffset - 48;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const totalReviews = toFormattedText(totalReviewsText, {
    totalReviews: data.reviewsByEquipmentType.totalCount.toLocaleString(),
  });

  return showRatingIfEnoughReviews && hasMinimumRating ? (
    <Button data-test-id="reviews-btn" onClick={handleLinkClick}>
      <StarsWrapper data-test-id="review-stars">
        <Stars
          rating={data.reviewsByEquipmentType.averageOverallRating}
          starSize={16}
          theme="light"
        />
      </StarsWrapper>
      <ReviewCount
        display="inline-block"
        is="span"
        size="small"
        data-test-id="reviewsModuleInShopPage"
      >
        {totalReviews}
      </ReviewCount>
    </Button>
  ) : null;
};

const Placeholder = styled.div`
  min-height: 32px;
`;

const ReviewCount = styled(Eyebrow)`
  line-height: 16px;
  text-underline-offset: 4px;
  text-decoration: underline;
  margin-left: ${spacing[12]};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: auto;
  transition: color 250ms ease 0s;
  color: ${grey[90]};

  &:hover {
    color: ${grey[70]};
  }
`;

const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;

  &:focus {
    outline: none;
  }
`;

export default Reviews;
