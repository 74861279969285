import {
  Container,
  Eyebrow,
  Icon,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import type { RefObject } from 'react';
import React from 'react';
import type { NextModalProps } from '../NextModal';
import {
  ModalContent,
  StickyHeader,
  StyledModal,
  CloseButton,
  ModalTransition,
} from './liftedModalStyles';

export type LiftedModalProps = NextModalProps & {
  backgroundColor?: string;
  textColor?: string;
  headerColor?: string;
  modalContentRef?: RefObject<HTMLDivElement>;
  maxWidth?: string;
  hasExtendedBottomMargin?: boolean;
};

export const LiftedModal: React.FC<React.PropsWithChildren<LiftedModalProps>> = ({
  isOpen = true,
  closeHandler,
  onAfterOpen,
  contentLabel,
  backgroundColor = grey[80],
  textColor = white,
  headerColor = grey[90],
  modalContentRef,
  maxWidth,
  hasExtendedBottomMargin = true,
  children,
}) => {
  return (
    <>
      <ModalTransition />
      <StyledModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        closeHandler={closeHandler}
        shouldApplySavedScrollPosition={false}
        onAfterOpen={onAfterOpen}
        defaultCloseButton={false}
        maxWidth={maxWidth}
        className="lifted-modal-content"
      >
        <ModalContent
          ref={modalContentRef}
          backgroundColor={backgroundColor}
          hasExtendedBottomMargin={hasExtendedBottomMargin}
        >
          <StickyHeader
            headerColor={headerColor}
            position="sticky"
            justifyContent="space-between"
            alignItems="center"
            padding={{
              mobile: `0 ${spacing[16]}`,
              tablet: `0 ${spacing[24]}`,
            }}
            width="100%"
          >
            <Eyebrow size="small" textColor={textColor}>
              {contentLabel}
            </Eyebrow>
            <CloseButton onClick={() => closeHandler()} aria-label="Close" tabIndex={0}>
              <Icon name="close" height={24} primaryColor={textColor} />
            </CloseButton>
          </StickyHeader>
          <Container className="lifted-modal__sections">{children}</Container>
        </ModalContent>
      </StyledModal>
    </>
  );
};
