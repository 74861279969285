import type { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import { PROSPECTS_DISCIPLINE_SLUGS } from '@ecomm/classes/models/constants';

export const mapSlugToDiscipline = (slug: string): ProspectsDiscipline => {
  const matchingDiscipline = Object.keys(PROSPECTS_DISCIPLINE_SLUGS).find(
    disciplineKey => {
      const disciplineSlug = PROSPECTS_DISCIPLINE_SLUGS[disciplineKey];
      return disciplineSlug === slug;
    },
  );

  return matchingDiscipline as ProspectsDiscipline;
};
