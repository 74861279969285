import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { USE_NEXT_LINK } from '@peloton/app-config';
import { useLocale } from '@peloton/internationalize/locales';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';

export const useIsNextApp = (): boolean => {
  return !!process.env.IS_NEXT;
};

export const useIsProspectsToExternalUrl = (href: string) => {
  const router = useRouter();
  return router?.basePath === '/classes' && !href?.startsWith('/classes');
};

//TODO: logic here needs to be refactored when PCC is removed
export const useIsExternalToProspects = (href: string) => {
  const router = useRouter();
  return router?.basePath === '' && href?.includes('classes');
};

const useIsProspectsApp = (): boolean => {
  return typeof window !== 'undefined' && window.location.pathname.includes('/classes');
};

export const useNextLinkWrapperProviderProps = () => {
  const isProspectsApp = useIsProspectsApp();
  const isNextApp = useIsNextApp();
  const locale = useLocale();

  const useNextLinks = USE_NEXT_LINK && isNextApp && !isProspectsApp;
  return {
    wrapper: useNextLinks ? NextLink : undefined,
    wrapperProps: useNextLinks ? createWrapperProps() : {},
    is: useNextLinks ? ('a' as const) : undefined,
    localePrefix: LOCALE_TOGGLE ? locale : undefined,
  };
};

const createWrapperProps = () => {
  return {
    link: {
      passHref: true,
      legacyBehavior: true,
    },
    linkButton: {
      passHref: true,
      legacyBehavior: false,
    },
  };
};
