import { Eyebrow, lime, white, black } from '@pelotoncycle/design-system';
import type { GuestPassInfo } from '@prospects/guest-pass/hooks/useGuestPassInfo';
import React from 'react';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';

import { Container, HeadbandContainer, MemberAvatar, TextContainer } from './styles';

export const GuestPass: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      guestPass: GuestPassInfo;
      guestPassLabel: string;
      darkMode?: boolean;
    }>
  >
> = ({ guestPass, guestPassLabel, darkMode = true }) => (
  <Container darkMode={darkMode}>
    <MemberAvatar
      avatarUrl={toCloudinarySrc({ src: guestPass.avatarUrl, width: 33 })}
      darkMode={darkMode}
    />
    <Eyebrow
      size="small"
      textColor={darkMode ? white : black}
      style={{ fontWeight: darkMode ? undefined : 500 }}
    >
      {guestPassLabel}
    </Eyebrow>
    <Eyebrow
      size="small"
      textColor={darkMode ? lime[50] : black}
    >{` ${guestPass.username}`}</Eyebrow>
  </Container>
);

export const HeadbandGuestPass: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      guestPass: GuestPassInfo;
      guestPassLabel: string;
    }>
  >
> = ({ guestPass, guestPassLabel }) => (
  <HeadbandContainer>
    <MemberAvatar
      data-test-id="guestPassAvatar"
      avatarUrl={toCloudinarySrc({ src: guestPass.avatarUrl, width: 33 })}
      border
    />
    <TextContainer>
      <Eyebrow size="small" textColor={white}>
        {guestPassLabel}
      </Eyebrow>
      <Eyebrow size="small" textColor={lime[50]}>{` ${guestPass.username}`}</Eyebrow>
    </TextContainer>
  </HeadbandContainer>
);
