import { Flex, spacing } from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { TypeQuizQuestion } from '@page-builder/lib/types';
import { ProgressContext } from '@page-builder/modules/Quiz/components/ProgressProvider';
import { useQuestions } from '@page-builder/modules/Quiz/utils/questions';
import { useTheme } from '@page-builder/modules/Quiz/utils/theme';
import { useTrackQuestionComplete } from '@page-builder/modules/Quiz/utils/tracking';

type Props = {
  questions: TypeQuizQuestion[];
  question: TypeQuizQuestion;
  canGoBack: boolean;
  canGoForward: boolean;
  resultPageUrl: string;
  quizSessionKey: string;
};

const NavControls: React.FC<React.PropsWithChildren<Props>> = ({
  questions,
  question,
  canGoBack,
  canGoForward,
  resultPageUrl,
  quizSessionKey,
}) => {
  const { trackEvent } = useTracking();
  const { progress } = useContext(ProgressContext);

  const { getCurrentQuestionIndex } = useQuestions(questions, resultPageUrl, progress);
  const trackQuestionCompleteEventProps = useTrackQuestionComplete(
    question.fields.slug,
    getCurrentQuestionIndex(),
    quizSessionKey,
  );

  const {
    fields: { questionName, slug, ctas },
  } = question;

  const { navBackground } = useTheme();

  const parsedCtas = ctas?.map(cta => ctaExtractData(cta));

  const [prevCta, nextCta] = parsedCtas || [];
  const processedCtas = [
    prevCta && {
      ...prevCta,
      isDisabled: !canGoBack,
    },
    nextCta && {
      ...nextCta,
      isDisabled: !canGoForward,
      onClick: () => trackEvent(trackQuestionCompleteEventProps),
    },
  ].filter(Boolean);

  return (
    <Flex
      backgroundColor={navBackground}
      position="sticky"
      justifyContent="center"
      padding={{
        mobile: `${spacing[24]} ${spacing[32]} ${spacing[56]}`,
        tablet: `${spacing[24]} ${spacing[32]}`,
      }}
      hasShadow={true}
      style={{ bottom: 0, zIndex: 2 }}
    >
      <ButtonsContainer
        ctas={processedCtas}
        parentName={questionName}
        parentId={slug}
        hasDropShadow={false}
        buttonWidth="adaptive"
        buttonColor="light"
      />
    </Flex>
  );
};

const ButtonsContainer = styled(LinkButtons)`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;

  max-width: 392px;
`;

export default NavControls;
