import {
  Support,
  grey,
  Media,
  white,
  spacing,
  Eyebrow,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';
import type { BannerData } from '../utils/getCFUbyDiscipline';

export const WaysToAccessCTA: React.FC<React.PropsWithChildren<BannerData>> = ({
  image,
  bannerText,
  ctaData,
}) => {
  const { trackEvent } = useTracking();

  const trackCTAClick = useCallback(
    (title?: string, url?: string) => {
      trackEvent({
        event: 'Clicked Link',
        properties: {
          parent: 'WaysToAccessCTA',
          linkTo: url ?? '',
          linkName: title ?? '',
          unitName: ctaData.name,
          parentType: 'Component: CTA',
        },
      });
    },
    [ctaData.name, trackEvent],
  );

  return (
    <BannerContainer>
      <StyledImageDiv>
        <StyledMedia
          media={{
            desktop: toCloudinarySrc({
              src: image.desktop || image.mobile,
              height: 117 * 2,
            }),
            mobile: toCloudinarySrc({
              src: image.mobile,
              height: 104,
            }),
            alt: image.alt,
            type: 'image',
            loading: 'lazy',
          }}
        ></StyledMedia>
      </StyledImageDiv>
      <RightHalfContainer>
        <StyledSupport is="h2" size="large" textColor={white}>
          {bannerText}
        </StyledSupport>
        <span>
          <StyledLink
            href={ctaData.url ?? ''}
            variant="primary"
            data-test-id={ctaData.name}
            className="link"
            hasUnderline={true}
            onClick={() => trackCTAClick(ctaData.text, ctaData.url)}
          >
            <Eyebrow size="small">{ctaData.text}</Eyebrow>
          </StyledLink>
        </span>
      </RightHalfContainer>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  display: flex;
  background-color: ${rgba(grey[70], 0.25)};
  height: 130px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-around;
  gap: 21px;
  padding: ${spacing[16]} ${spacing[8]};

  ${media.tablet`
    gap: 51px;
    justify-content: flex-start;
    padding: ${spacing[24]} ${spacing[16]};
  `}

  ${media.desktopLarge`
    height: 134px;
    gap: ${spacing[16]};
    padding: ${spacing[24]} ${spacing[8]};
    justify-content: space-around;
    max-width: calc(50% - ${spacing[8]});
  `}
`;

const RightHalfContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16]};

  ${media.tabletLarge`
    gap: ${spacing[8]};
  `}

  ${media.desktopLarge`
    gap: ${spacing[16]};
  `}
`;

const StyledSupport = styled(Support)`
  max-width: 206px;

  ${media.tablet`
    max-width: 100%;  `}
`;

const StyledLink = styled(Link)`
  color: ${white};

  &:hover {
    color: ${grey[50]};
    text-decoration-color: ${grey[50]};
  }
`;

const StyledImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spacing[88]};
  width: ${spacing[88]};

  ${media.desktopLarge`
    max-width: ${spacing[80]};
    height: auto;
    width: auto;
  `};
`;

const StyledMedia = styled(Media)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
`;
