import {
  Grid,
  Container,
  grey,
  spacing,
  white,
  Support,
  Body,
} from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React, { useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import type { Class, FreeClass } from '@peloton/models/PelotonClass';
import { media } from '@peloton/styles';
import { DISCIPLINE_SLUGS } from '@ecomm/classes/models/constants';
import { LoadMoreButton } from '@ecomm/pagination/LoadMore/LoadMoreButton';
import { LoadMoreList } from '@ecomm/pagination/LoadMore/LoadMoreList';
import { LoadPreviousButton } from '@ecomm/pagination/LoadMore/LoadPreviousButton';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import type { ModalLabelPropsFromContentful } from '@page-builder/modules/ClassesGrid/utils/mapContentfulProps';
import { mapModalLabelPropsFromContentful } from '@page-builder/modules/ClassesGrid/utils/mapContentfulProps';
import type { ModalLabelValues } from '@page-builder/modules/ClassesGrid/utils/mapModalLabelsFromContentfulToProps';
import { mapModalLabelsFromContentfulToProps } from '@page-builder/modules/ClassesGrid/utils/mapModalLabelsFromContentfulToProps';
import { VERTICAL_PADDING } from '@page-builder/modules/tokens';
import { ScrollToTopButton } from './pagination/ScrollToTopButton';
import { withPagination } from './pagination/withPagination';
import { useClassGridHooks } from './utils/classesGridHooks';
import { renderExtraCard, renderClassCard } from './utils/classesGridRenderUtils';
import { insertExtraCFUCard } from './utils/insertExtraCFUCard';

export type ClassesGridComponent = React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
>;
export type ClassesGridComponentWithClasses = React.FC<
  React.PropsWithChildren<
    TypeComponentGenericListFields & {
      classes: Class[];
      freeClass?: FreeClass;
      totalClasses: number;
      isPaginated: boolean;
    }
  >
>;

const ClassesGrid: ClassesGridComponentWithClasses = ({
  name,
  items,
  classes,
  freeClass,
  totalClasses,
  ctas,
}) => {
  const {
    moduleId,
    trackEvent,
    router,
    previousPath,
    isDiscipline,
    isFiltered,
    isCLP,
    hasNext,
    hasPrevious,
    lowestLoadedPage,
    classesPaginationNextLabel,
    classesPaginationPreviousLabel,
    classesPaginationLoadingLabel,
    classesPaginationViewingLabel,
    classFiltersShowingLabel,
    classFiltersMoreOnAppLabel,
    fitnessDiscipline,
    isProductAvailable,
  } = useClassGridHooks(name, classes);

  const updatedClasses = insertExtraCFUCard({
    classes,
    freeClass,
    items,
    isDiscipline,
    isFiltered,
    lowestLoadedPage,
    isProductAvailable,
    fitnessDiscipline,
  });

  const trackCardClick = useCallback(
    (title: string, url: string) => {
      trackEvent({
        event: 'Clicked Classes Card',
        properties: {
          parent: name,
          linkTo: url,
          linkName: title,
          unitName: 'Class Card',
          parentType: 'Component: Generic List',
        },
      });
    },
    [name, trackEvent],
  );

  const parsedCtas = ctas?.map(ctaExtractData);

  const modalLabels = mapModalLabelsFromContentfulToProps(
    mapModalLabelPropsFromContentful(
      (items as unknown) as ModalLabelPropsFromContentful[],
    )?.items,
  ) as ModalLabelValues;

  const difficultyLabels = {
    beginner: modalLabels.beginnerLabel,
    intermediate: modalLabels.intermediateLabel,
    advanced: modalLabels.advancedLabel,
  };

  return (
    <>
      <GlobalStyles />
      {isCLP && <ClpStyles />}
      <StyledContainer
        id={moduleId}
        backgroundColor={grey[90]}
        verticalPadding={VERTICAL_PADDING['min']}
        horizontalPadding={{ mobile: spacing[32], desktop: spacing[104] }}
      >
        {/* Classes counter (discipline page only) */}
        {isDiscipline && (
          <StyledHeadingContainer>
            <StyledShowingHeading size="small" is="h2">
              {classFiltersShowingLabel.replace('{totalNumber}', `${totalClasses}`)}
            </StyledShowingHeading>
            <StyledSubShowingHeading size="small" is="p">
              {classFiltersMoreOnAppLabel}
            </StyledSubShowingHeading>
          </StyledHeadingContainer>
        )}

        {hasPrevious && (
          <StyledPagination>
            <LoadPreviousButton
              label={classesPaginationPreviousLabel}
              loadingLabel={classesPaginationLoadingLabel}
              variant="outline"
              color="light"
              size="medium"
            />
          </StyledPagination>
        )}

        <LoadMoreList
          items={updatedClasses}
          renderList={props => (
            <StyledGrid
              {...props}
              forwardedAs="ul"
              columnCount={{ mobile: 1, tablet: 2, desktop: 3 }}
              gap={spacing[16]}
            />
          )}
          renderListItem={(props, { item, index, isNew: listNew, newPortionIndex }) => {
            const isNestedPage = router.asPath.split('/').length > 2;
            const navigatedFromDisciplinePage = Boolean(
              isNestedPage && previousPath && previousPath !== router.asPath,
            );
            const isNew = listNew || navigatedFromDisciplinePage;
            const disciplineSlug = DISCIPLINE_SLUGS[item.fitnessDiscipline];
            const itemHref = `/classes/${disciplineSlug}/${item.slug}`;

            return (
              <StyledGridItem {...props} key={`${index}-${item.id}`}>
                {item.extraCardType
                  ? renderExtraCard(item, newPortionIndex)
                  : renderClassCard(
                      item,
                      itemHref,
                      trackCardClick,
                      difficultyLabels,
                      isNew,
                      newPortionIndex,
                    )}
              </StyledGridItem>
            );
          }}
        />

        {totalClasses > 0 && (
          <>
            <StyledPagination>
              <StyledPaginationInfo role="status" aria-live="polite">
                {classesPaginationViewingLabel
                  .replace('{number}', `${classes.length}`)
                  .replace('{totalNumber}', `${totalClasses}`)}
              </StyledPaginationInfo>

              <LoadMoreButton
                label={classesPaginationNextLabel}
                loadingLabel={classesPaginationLoadingLabel}
                variant="outline"
                color="light"
                size="medium"
              />

              {!hasNext && parsedCtas && (
                <LinkButtons linkSize="medium" ctas={parsedCtas} hasDropShadow={false} />
              )}
            </StyledPagination>

            <ScrollToTopButton />
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default withPagination(ClassesGrid);

const MAX_BODY_WIDTH = 1224;

const GlobalStyles = createGlobalStyle`
  #classes-grid + [data-module="CtaBanner"] {
    padding-top: 0;
  }
`;

const ClpStyles = createGlobalStyle`
  #main {
    margin-bottom: ${spacing[24]};

    ${media.tablet`
      margin-bottom: ${spacing[64]};
    `}
  }
`;

const StyledContainer = styled(Container)`
  padding-bottom: 0;
  margin-bottom: 0;
  padding-inline: 1rem;

  ${media.tablet`
    padding-inline: 2rem;
  `}
`;

const StyledHeadingContainer = styled.div`
  max-width: ${MAX_BODY_WIDTH / 16}rem;
  margin: 0 auto;
`;

const StyledGrid = styled(Grid)`
  justify-items: center;
  align-items: center;
  max-width: ${MAX_BODY_WIDTH / 16}rem;
  margin: 0 auto;

  ${media.tablet`
    justify-items: unset;
    gap: ${spacing[24]};
`}

  ${media.tabletXLarge`
    overflow: hidden;
    text-overflow: ellipsis;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${media.desktopLarge`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`;

const StyledGridItem = styled.li`
  width: 100%;
  height: 100%;
`;

const StyledPagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${spacing[40]} 0;
`;

const StyledPaginationInfo = styled(Support).attrs({ forwardedAs: 'p', size: 'small' })`
  width: 100%;
  margin-bottom: ${spacing[16]};
  text-align: center;
  color: ${white};
`;

const StyledShowingHeading = styled(Body)`
  line-height: 1;
  color: ${white};
`;

const StyledSubShowingHeading = styled(Support)`
  margin-bottom: ${spacing[24]};
  color: ${white};
`;
