import { useRouter } from 'next/router';
import { getPagePath } from '@peloton/next/utils/getPagePath';

const useNextPath = () => {
  const router = useRouter();

  // If slugs are defined we're using dynamic routing, otherwise just grab pathname.
  const path = router.query.slugs
    ? getPagePath(router, { includeBasePath: true })
    : router.pathname;

  return path;
};

export default useNextPath;
