import React, { useEffect, useRef } from 'react';
import { focusFirstNewElement } from '../utils/focusFirstNewElement';
import { useLoadMoreContext } from './LoadMoreContext';

type RenderListProps = React.PropsWithChildren<{ 'aria-busy': 'true' | 'false' }>;
type RenderListItemProps = React.PropsWithChildren<{
  ref: (e: HTMLLIElement) => void;
  tabIndex: -1;
}>;
type LoadMoreListProps<Item = any> = {
  items: Item[];
  renderList: (props: RenderListProps) => React.ReactElement;
  renderListItem: (
    props: RenderListItemProps,
    data: {
      item: Item;
      index: number;
      isNew: boolean;
      newPortionIndex: number | null;
    },
  ) => React.ReactElement;
};

export const LoadMoreList: React.FC<React.PropsWithChildren<LoadMoreListProps>> = ({
  items,
  renderList,
  renderListItem,
}) => {
  const { page, isLoading } = useLoadMoreContext();
  const listItemElements: HTMLElement[] = [];
  const previousItemsRef = useRef(items);
  const lastLoadedPageRef = useRef(page);
  let newPortionIndexCount = -1;

  const renderedListItems = items.map((item, index) => {
    const isNew = !previousItemsRef.current.includes(item);
    const newPortionIndex = isNew ? newPortionIndexCount++ : null;
    return renderListItem(
      {
        ref: el => listItemElements.push(el),
        tabIndex: -1,
      },
      {
        item,
        index,
        isNew,
        newPortionIndex,
      },
    );
  });

  const renderedList = renderList({
    'aria-busy': isLoading ? 'true' : 'false',
    children: <>{renderedListItems}</>,
  });

  useEffect(() => {
    if (lastLoadedPageRef.current === page) {
      return;
    }

    focusFirstNewElement({
      isInsertedBefore: lastLoadedPageRef.current > page,
      list: listItemElements,
      previousLength: previousItemsRef.current.length,
    });

    lastLoadedPageRef.current = page;
    previousItemsRef.current = items;
  }, [page]);

  return renderedList;
};
