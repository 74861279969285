import type { MediaImageProps } from '@pelotoncycle/design-system';
import { Eyebrow, black, brand, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { PlayIcon } from '@ecomm/themed-modules/VideoGallery/icons';
import { containerHover } from '@ecomm/themed-modules/VideoGallery/styles';
import Image from '../PerformantImage';

//card animation delay
const delay = 0.2;

export type Props = {
  title: ReactNode;
  image: MediaImageProps;
  index: number;
  className?: string;
  lazy?: boolean;
  shadowColor?: string;
};

const Card: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  image,
  index,
  className,
  lazy,
  shadowColor,
}) => (
  <Container
    index={index}
    key={`${title}-key`}
    className={className}
    shadowColor={shadowColor}
  >
    <ImageContainer>
      <StyledImage
        alt={image.alt}
        breakpointOptions={{
          mobile: { src: image.mobile, width: 800 },
          tablet: { src: image.tablet || image.mobile, width: 800 },
          desktop: { src: image.desktop || image.mobile, width: 280 },
        }}
        src={image.mobile}
        draggable={false}
        loading={lazy ? 'lazy' : undefined}
        layout={lazy ? 'fill' : undefined}
      />
    </ImageContainer>
    <ImageMask>
      <Title>
        <StyledPlayIcon height={24} fill={brand.darkest} background={grey[40]} />
        <Eyebrow as="span" size="small" textColor={grey[40]} hasShadow>
          {title}
        </Eyebrow>
      </Title>
    </ImageMask>
  </Container>
);

export default Card;

interface CardProps {
  index: number;
  shadowColor?: string;
}

const Container = styled.div<CardProps>`
  position: relative;
  margin: 8px;
  ${({ index }: CardProps) => `transition-delay: ${delay * (index + 1)}s !important;`}
  ${({ shadowColor = 'black' }) =>
    `box-shadow: 0px 16px 38px -20px ${rgba(shadowColor, 0.59)}`};

  ${
    /* This is required so that the images become darker on hover rather than lighter */ ''
  };
  background-color: ${brand.darkest};

  ${media.tablet`
    margin: 12px;
  `}

  ${media.tabletXLarge<CardProps>`
    ${({ shadowColor = 'black' }) =>
      `box-shadow: 0px 32px 54px -20px ${rgba(shadowColor, 0.59)}`};
  `}

  ${media.desktopLarge`
    max-width: 208px;
  `}

  ${media.desktopXLarge`
    max-width: 250px;
  `}

  ${media.infinity`
    max-width: unset;
  `}

  &:focus {
    outline: 0;
  }

  ${containerHover};
`;

const StyledImage = styled(Image)`
  img {
    border-radius: 3px;
    display: block;
    width: 100%;
    transition: all 500ms ease-in-out;
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
`;

const ImageMask = styled.div`
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(177.42deg, transparent 6.5%, ${black} 83.85%);
  border-radius: 3px;

  display: flex;
  align-items: flex-end;
  padding: 0 12px 12px 12px;

  ${media.tablet`
    padding: 16px 16px 16px;
  `}
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledPlayIcon = styled(PlayIcon)`
  overflow: visible;
  margin-right: 12px;
  border-radius: 50%;

  ${media.tabletXLarge`
    height: 32px;
  `}
`;
