import {
  Body,
  brand,
  Button,
  Checkbox,
  Container,
  ErrorMsg,
  Eyebrow,
  Flex,
  FlexChild,
  grey,
  Headline,
  Input,
  pixelToRem,
  Radio,
  spacing,
  Support,
  Label,
  red,
} from '@pelotoncycle/design-system';

import type { TypeComponentFormElementFields } from '@pelotoncycle/page-builder';
import { WithMarkdown } from '@pelotoncycle/page-builder';
import type { Entry, EntryFields } from 'contentful';
import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import Formatters from '@peloton/forms/formatters';
import { b5, med } from '@ecomm/typography';
import { serialNumberErrors } from '@page-builder/utils/helpers/historyReportForm';

export type Images = {
  mobile: string;
  tablet: string;
  desktop: string;
};

type Item = { value: string; option: string };

export type Select = {
  label: string;
  items: Item[];
};

type Cta = {
  text: string;
  url: string;
};

type Props = {
  headline: ReactNode;
  body: ReactNode;
  emailInputField: Entry<TypeComponentFormElementFields>;
  serialNumberInputField: Entry<TypeComponentFormElementFields>;
  buyingOrSellingInputField: Entry<TypeComponentFormElementFields>;
  cta: Cta;
  termsAndConditionsInputField: Entry<TypeComponentFormElementFields>;
  marketingConsentInputField: Entry<TypeComponentFormElementFields>;
  serialNumberLinkText: ReactNode;
  formRef?: (node?: Element | null | undefined) => void;
  failureMessage: EntryFields.Text;
};

const HistoryReport: React.FC<React.PropsWithChildren<Props>> = ({
  headline,
  body,
  emailInputField,
  serialNumberInputField,
  buyingOrSellingInputField,
  cta,
  termsAndConditionsInputField,
  marketingConsentInputField,
  serialNumberLinkText,
  formRef,
  failureMessage,
}) => {
  const TEST_ID_PREFIX = 'history-report';

  const useFormMethods = useFormContext();
  const { trackEvent } = useTracking();
  const { register, formState, getValues } = useFormMethods;

  /**
   * @param event onClick event passed by the input's blur handler
   * @description trigger tracking event with newest form value
   */
  const trackHistorySummaryStep = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const formStepMap = {
      email: 'Email',
      serialNumber: 'Serial Number',
      buyingOrSelling: 'Interested In',
      termsAndConditionsAccepted: 'Terms Accepted',
      marketingEmailConsentAccepted: 'Comms Accepted',
    };
    const stepName = formStepMap[name];
    const formValues = getValues();
    trackEvent({
      event: 'History Summary Step Completed',
      properties: {
        stepName,
        ...formValues,
      },
    });
  };

  const trackOnBlur = { onBlur: trackHistorySummaryStep };
  const validationErrors = formState.errors;
  const emailControl = register('email', trackOnBlur);
  const serialNumberControl = register('serialNumber', trackOnBlur);
  const buyingOrSellingControl = register('buyingOrSelling', trackOnBlur);
  const termsAndConditionsAcceptedControl = register(
    'termsAndConditionsAccepted',
    trackOnBlur,
  );
  const marketingEmailConsentAcceptedControl = register(
    'marketingEmailConsentAccepted',
    trackOnBlur,
  );
  const buyingOrSellingOptions = buyingOrSellingInputField.fields.items ?? [];

  const scrollToSerialNumberFaq = useCallback(() => {
    // number of pixels to offset the scroll height by to avoid scrolling into navbar
    const yOffset = -100;
    const serialNumberFaqElement = document?.getElementById?.('phist-where-to-find');
    const topVal = serialNumberFaqElement?.getBoundingClientRect()?.top;
    if (!topVal) {
      return;
    }
    const y = topVal + window.scrollY + yOffset;

    window?.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  }, []);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      serialNumberControl.onBlur(event);
    }
  };

  return (
    <Container ref={formRef}>
      <Headline
        style={{ fontWeight: 600, marginBottom: spacing[16] }}
        size="medium"
        data-test-id={`${TEST_ID_PREFIX}_copy`}
      >
        {headline}
      </Headline>
      <Body size="small" data-test-id={`${TEST_ID_PREFIX}_heading`} textAlign="start">
        {body}
      </Body>
      <Flex flexDirection="column" flexWrap="wrap" justifyContent="center">
        <FlexChild
          textAlign="left"
          style={{ marginTop: spacing[32], marginBottom: spacing[12] }}
        >
          <Input
            aria-label={serialNumberControl.name}
            format={Formatters.serialNumber}
            name={serialNumberControl.name}
            value={getValues(serialNumberControl.name)}
            ref={serialNumberControl.ref}
            onBlur={serialNumberControl.onBlur}
            data-test-id={`${TEST_ID_PREFIX}_serialNumber`}
            handleChange={(input: string) =>
              serialNumberControl.onChange({ target: { value: input } })
            }
            onKeyDown={handleInputKeyDown}
            type="text"
            label={serialNumberInputField.fields.label}
            errorMessage={serialNumberErrors(
              formState,
              serialNumberControl,
              serialNumberInputField,
            )}
          />
        </FlexChild>
        <FlexChild alignSelf="flex-start" verticalPadding={`0 ${spacing[32]}`}>
          {/* Assign a type to prevent the 'onSubmit' property from being assigned by default. 
            https://redux-form.com/8.3.0/docs/faq/buttontype.md/ */}
          <StyledLink
            data-test-id="where-can-i-find-my-serial-button"
            type="button"
            onClick={scrollToSerialNumberFaq}
          >
            {serialNumberLinkText}
          </StyledLink>
        </FlexChild>
        <FlexChild textAlign="left">
          <Input
            aria-label={emailControl.name}
            name={emailControl.name}
            value={getValues(emailControl.name)}
            ref={emailControl.ref}
            onBlur={emailControl.onBlur}
            data-test-id={`${TEST_ID_PREFIX}_email`}
            handleChange={(input: string) =>
              emailControl.onChange({ target: { value: input } })
            }
            type="text"
            label={emailInputField.fields.label}
            errorMessage={
              validationErrors?.[emailControl.name] && emailInputField.fields.errorMessage
            }
          />
        </FlexChild>
        <FlexChild
          textAlign="left"
          alignSelf="flex-start"
          verticalPadding={`${spacing[32]} 0`}
        >
          <Eyebrow textAlign="left" style={{ fontWeight: 600 }} size="extraSmall">
            {buyingOrSellingInputField.fields.label}
          </Eyebrow>
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            verticalPadding={`${spacing[8]} 0`}
            style={{ rowGap: `${spacing[12]}`, columnGap: `${spacing[32]}` }}
          >
            {buyingOrSellingOptions.map(option => {
              return (
                <FlexChild key={option}>
                  <Radio
                    aria-label={option}
                    value={option.split(':')[1]}
                    labelText={option.split(':')[0]}
                    theme="light"
                    {...buyingOrSellingControl}
                    data-test-id={`${TEST_ID_PREFIX}_${option.split(':')[0]}`}
                  />
                </FlexChild>
              );
            })}
          </Flex>
          {validationErrors?.[buyingOrSellingControl.name] && (
            <ErrorMsg> {buyingOrSellingInputField.fields.errorMessage}</ErrorMsg>
          )}
        </FlexChild>
        <FlexChild alignSelf="flex-start" textAlign={'left'}>
          <Checkbox
            aria-label={termsAndConditionsInputField.fields.name}
            inputAlignment="baseline"
            theme="light"
            labelText={
              <Support style={{ marginTop: pixelToRem(28) }} display="flex" size="medium">
                <WithMarkdown text={termsAndConditionsInputField.fields.tooltip ?? ''} />
              </Support>
            }
            {...termsAndConditionsAcceptedControl}
            data-test-id={`${TEST_ID_PREFIX}_termsAndConditions`}
          />
          {validationErrors?.[termsAndConditionsAcceptedControl.name] && (
            <ErrorMsg marginTop={pixelToRem(17)}>
              {termsAndConditionsInputField.fields.errorMessage}
            </ErrorMsg>
          )}
        </FlexChild>
        <FlexChild alignSelf="flex-start" textAlign={'left'}>
          <Checkbox
            aria-label={marketingConsentInputField.fields.name}
            inputAlignment="baseline"
            theme="light"
            labelText={
              <Support style={{ marginTop: pixelToRem(20) }} display="flex" size="medium">
                <WithMarkdown text={marketingConsentInputField.fields.tooltip ?? ''} />
              </Support>
            }
            {...marketingEmailConsentAcceptedControl}
          />
        </FlexChild>
        <FlexChild>
          <Button
            style={{ marginTop: spacing[24], width: '100%' }}
            variant={'outline'}
            color={'dark'}
            type="submit"
            data-test-id={`${TEST_ID_PREFIX}_button`}
            isLoading={formState.isLoading}
            onClick={() => {
              trackEvent({
                event: 'Clicked Link',
                properties: {
                  parent: 'History Report',
                  unitName: 'History Report Form',
                  linkName: cta.text,
                },
              });
            }}
            isDisabled={!!formState.errors?.root?.dailyLimitError}
          >
            {cta.text}
          </Button>
          {formState.errors?.root?.serverError && (
            <ErrorMsg marginTop={pixelToRem(17)}>{failureMessage}</ErrorMsg>
          )}
          {formState.errors?.root?.dailyLimitError && (
            <Label
              size="small"
              style={{ marginTop: spacing[16], color: red[50], fontWeight: 400 }}
              data-test-id="formstate.error.dailyLimitError"
            >
              {formState.errors?.root?.dailyLimitError.message}
            </Label>
          )}
        </FlexChild>
      </Flex>
    </Container>
  );
};

const StyledLink = styled.button`
  ${b5}
  ${med}
  color: ${brand.darkest};
  text-decoration: underline;
  &:hover {
    color: ${grey[70]};
  }
`;

export default HistoryReport;
