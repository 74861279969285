export const yogaFlowKeywords = [
  'min-yoga-flow',
  'balanced-yoga-flow',
  'morning-yoga-flow',
  'grounded-yoga',
  'version-',
  'funk-',
  'classical-',
  'sampha',
  'kali-uchis',
  'chaka-khan',
  'fkj-yoga',
  'crue-yoga',
  'hip-hop-50',
  'trevor-hall',
  'jazz',
  'motown',
  'rhymes-yoga',
  'hi-nrg',
  'little-mermaid',
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin-',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance-',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'blackpink',
  'bieber',
  'goulding',
  'lil-wayne',
  'britney-spears',
  'broadway',
  'earth-wind-fire',
  'classical',
  'techno',
  'new-wave',
  'hozier',
  'black-keys',
  'dave-matthews',
  'gorillaz',
  'machine-gun-kelly',
  'thirty-seconds-to-mars',
  'yeah-yeah-yeahs',
  'grunge-',
  '-afo-',
  '-muna-',
  '-rina-',
  'system-of-a-down',
  'red-hot-chili',
  'spotify',
  '-weezer-',
  'paramore',
  'new-tracks',
  'billie-eilish',
  'coldplay',
  'zz-top',
  'disco',
  'rave',
  'skrillex',
  'listening-party',
  'van-buuren',
  'garage',
  'kygo-',
  'fka-twigs',
  'afrobeats',
  'techno',
  'gospel',
  'zach-bryan',
  'chris-stapleton',
  'maren-morris',
  'luke-bryan',
  'florida-georgia',
  'reba-',
  'eric-church',
  'mickey-guyton',
  'keith-urban',
  'dolly-parton',
  'run-dmc',
  'dmx-',
  'ludacris-',
  'kid-laroi',
  '-rema-',
  'salt-n-pepa',
  'miguel-',
  'kendrick-lamar',
  'stormzy',
  'caribbean',
  'a-tribe-called-quest',
  'soul',
  'swizz-beatz',
  'verzuz',
  'lauryn-hill',
  'megan-thee-stallion',
  'outkast',
  'ferg-',
  'burna-',
  'feid',
  'rauw-',
  'dembow-',
  'merengue',
  'j-balvin',
  'bad-bunny',
  'ricky-martin',
  'anitta-',
  'motown',
  'kelly-clarkson',
  '60s-',
  'take-that',
  'taylors-version',
  'craig-david',
  'pablo-vittar',
  'janet-jackson',
  '-rb-',
  'aguilera',
  'madonna',
  'jonas-brothers',
  'kylie-minogue',
  'musical',
  'duran-duran',
  'mariah-carey',
  'taylor-swift',
  'nsync',
  'carly-rae',
  'metal',
  'blink-182',
  'motley',
  '50s',
  'miley-cyrus',
  'beatles',
  'eminem',
  'bob-marley',
  'lizzo',
  'david-guetta',
  'rent',
  'disney',
];

export const cyclingMusicKeywords = [
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin-',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance-',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'blackpink',
  'bieber',
  'goulding',
  'lil-wayne',
  'britney-spears',
  'broadway',
  'earth-wind-fire',
  'classical',
  'techno',
  'new-wave',
  'hozier',
  'black-keys',
  'dave-matthews',
  'gorillaz',
  'machine-gun-kelly',
  'thirty-seconds-to-mars',
  'yeah-yeah-yeahs',
  'grunge-',
  '-afo-',
  '-muna-',
  '-rina-',
  'system-of-a-down',
  'red-hot-chili',
  'spotify',
  '-weezer-',
  'paramore',
  'new-tracks',
  'billie-eilish',
  'coldplay',
  'zz-top',
  'disco',
  'rave',
  'skrillex',
  'listening-party',
  'van-buuren',
  'garage',
  'kygo-',
  'fka-twigs',
  'afrobeats',
  'techno',
  'gospel',
  'zach-bryan',
  'chris-stapleton',
  'maren-morris',
  'luke-bryan',
  'florida-georgia',
  'reba-',
  'eric-church',
  'mickey-guyton',
  'keith-urban',
  'dolly-parton',
  'run-dmc',
  'dmx-',
  'ludacris-',
  'kid-laroi',
  '-rema-',
  'salt-n-pepa',
  'miguel-',
  'kendrick-lamar',
  'stormzy',
  'caribbean',
  'a-tribe-called-quest',
  'soul',
  'swizz-beatz',
  'verzuz',
  'lauryn-hill',
  'megan-thee-stallion',
  'outkast',
  'ferg-',
  'burna-',
  'feid',
  'rauw-',
  'dembow-',
  'merengue',
  'j-balvin',
  'bad-bunny',
  'ricky-martin',
  'anitta-',
  'motown',
  'kelly-clarkson',
  '60s-',
  'take-that',
  'taylors-version',
  'craig-david',
  'pablo-vittar',
  'janet-jackson',
  '-rb-',
  'aguilera',
  'madonna',
  'jonas-brothers',
  'kylie-minogue',
  'musical',
  'duran-duran',
  'mariah-carey',
  'taylor-swift',
  'nsync',
  'carly-rae',
  'metal',
  'blink-182',
  'motley',
  '50s',
  'miley-cyrus',
  'beatles',
  'eminem',
  'bob-marley',
  'lizzo',
  'david-guetta',
  'rent',
];

export const rowingMusicThemeKeywords = [
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin-',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance-',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'blackpink',
  'bieber',
  'goulding',
  'lil-wayne',
  'britney-spears',
  'broadway',
  'earth-wind-fire',
  'classical',
  'techno',
  'new-wave',
  'hozier',
  'black-keys',
  'dave-matthews',
  'gorillaz',
  'machine-gun-kelly',
  'thirty-seconds-to-mars',
  'yeah-yeah-yeahs',
  'grunge-',
  '-afo-',
  '-muna-',
  '-rina-',
  'system-of-a-down',
  'red-hot-chili',
  'spotify',
  '-weezer-',
  'paramore',
  'new-tracks',
  'billie-eilish',
  'coldplay',
  'zz-top',
  'disco',
  'rave',
  'skrillex',
  'listening-party',
  'van-buuren',
  'garage',
  'kygo-',
  'fka-twigs',
  'afrobeats',
  'techno',
  'gospel',
  'zach-bryan',
  'chris-stapleton',
  'maren-morris',
  'luke-bryan',
  'florida-georgia',
  'reba-',
  'eric-church',
  'mickey-guyton',
  'keith-urban',
  'dolly-parton',
  'run-dmc',
  'dmx-',
  'ludacris-',
  'kid-laroi',
  '-rema-',
  'salt-n-pepa',
  'miguel-',
  'kendrick-lamar',
  'stormzy',
  'caribbean',
  'a-tribe-called-quest',
  'soul',
  'swizz-beatz',
  'verzuz',
  'lauryn-hill',
  'megan-thee-stallion',
  'outkast',
  'ferg-',
  'burna-',
  'feid',
  'rauw-',
  'dembow-',
  'merengue',
  'j-balvin',
  'bad-bunny',
  'ricky-martin',
  'anitta-',
  'motown',
  'kelly-clarkson',
  '60s-',
  'take-that',
  'taylors-version',
  'craig-david',
  'pablo-vittar',
  'janet-jackson',
  '-rb-',
  'aguilera',
  'madonna',
  'jonas-brothers',
  'kylie-minogue',
  'musical',
  'duran-duran',
  'mariah-carey',
  'taylor-swift',
  'nsync',
  'carly-rae',
  'metal',
  'blink-182',
  'motley',
  '50s',
  'miley-cyrus',
  'beatles',
  'eminem',
  'bob-marley',
  'lizzo',
  'david-guetta',
  'rent',
  '-pnk-',
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'earth-day',
  'valentine',
  'riopy-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
  'mood-hiit',
  'halloween',
  'from-home',
  'sweat-steady',
  'club-bangers',
  'boss-ride',
  'pick-me-up',
  'feel-good',
  'vinyl-vault',
  'reset',
  'jess-king-experience',
  'nye-',
  'top-50',
  'boxing',
  'move-as-one',
  'persons-with-disabilities',
  'liverpool',
  'sundays-with-love',
  'sing-along',
  'anniversary',
  'nba-',
  'black-history',
  'cover-to-cover',
  'movie',
  'lhhm-',
  'xoxo-',
  'sample-that',
  'barbie',
  'fourth-of-july',
  'sold-out-show',
  'speak-up',
  'memorial',
  'mixtape',
  'to-greatness',
  'dancehall',
  'st-patricks',
  'lol-cody',
  'fresh-friday',
  'oktoberfest',
  'carnival',
  'aussie-vibes',
  'wimbledon',
  'turn-it-up',
  'jubilee',
  'bhangra',
  'mood-row',
  'together-we',
  'appreciation',
  'reflection',
  'history-month',
  'football',
  'jke-',
  '-007-',
  'festive',
  'work-it-out',
  'latino-',
  'canada',
  'all-star',
  'armistice',
  'world-aids-day',
  'running-day',
  'our-future-selves',
  'walk-talk',
  'disney',
];

export const runningMusicKeywords = [
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin-',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance-',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'blackpink',
  'bieber',
  'goulding',
  'lil-wayne',
  'britney-spears',
  'broadway',
  'earth-wind-fire',
  'classical',
  'techno',
  'new-wave',
  'hozier',
  'black-keys',
  'dave-matthews',
  'gorillaz',
  'machine-gun-kelly',
  'thirty-seconds-to-mars',
  'yeah-yeah-yeahs',
  'grunge-',
  '-afo-',
  '-muna-',
  '-rina-',
  'system-of-a-down',
  'red-hot-chili',
  'spotify',
  '-weezer-',
  'paramore',
  'new-tracks',
  'billie-eilish',
  'coldplay',
  'zz-top',
  'disco',
  'rave',
  'skrillex',
  'listening-party',
  'van-buuren',
  'garage',
  'kygo-',
  'fka-twigs',
  'afrobeats',
  'techno',
  'gospel',
  'zach-bryan',
  'chris-stapleton',
  'maren-morris',
  'luke-bryan',
  'florida-georgia',
  'reba-',
  'eric-church',
  'mickey-guyton',
  'keith-urban',
  'dolly-parton',
  'run-dmc',
  'dmx-',
  'ludacris-',
  'kid-laroi',
  '-rema-',
  'salt-n-pepa',
  'miguel-',
  'kendrick-lamar',
  'stormzy',
  'caribbean',
  'a-tribe-called-quest',
  'soul',
  'swizz-beatz',
  'verzuz',
  'lauryn-hill',
  'megan-thee-stallion',
  'outkast',
  'ferg-',
  'burna-',
  'feid',
  'rauw-',
  'dembow-',
  'merengue',
  'j-balvin',
  'bad-bunny',
  'ricky-martin',
  'anitta-',
  'motown',
  'kelly-clarkson',
  '60s-',
  'take-that',
  'taylors-version',
  'craig-david',
  'pablo-vittar',
  'janet-jackson',
  '-rb-',
  'aguilera',
  'madonna',
  'jonas-brothers',
  'kylie-minogue',
  'musical',
  'duran-duran',
  'mariah-carey',
  'taylor-swift',
  'nsync',
  'carly-rae',
  'metal',
  'blink-182',
  'motley',
  '50s',
  'miley-cyrus',
  'beatles',
  'eminem',
  'bob-marley',
  'lizzo',
  'david-guetta',
  'rent',
  '-pnk-',
];

export const walkingMusicKeywords = [
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin-',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance-',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'blackpink',
  'bieber',
  'goulding',
  'lil-wayne',
  'britney-spears',
  'broadway',
  'earth-wind-fire',
  'classical',
  'techno',
  'new-wave',
  'hozier',
  'black-keys',
  'dave-matthews',
  'gorillaz',
  'machine-gun-kelly',
  'thirty-seconds-to-mars',
  'yeah-yeah-yeahs',
  'grunge-',
  '-afo-',
  '-muna-',
  '-rina-',
  'system-of-a-down',
  'red-hot-chili',
  'spotify',
  '-weezer-',
  'paramore',
  'new-tracks',
  'billie-eilish',
  'coldplay',
  'zz-top',
  'disco',
  'rave',
  'skrillex',
  'listening-party',
  'van-buuren',
  'garage',
  'kygo-',
  'fka-twigs',
  'afrobeats',
  'techno',
  'gospel',
  'zach-bryan',
  'chris-stapleton',
  'maren-morris',
  'luke-bryan',
  'florida-georgia',
  'reba-',
  'eric-church',
  'mickey-guyton',
  'keith-urban',
  'dolly-parton',
  'run-dmc',
  'dmx-',
  'ludacris-',
  'kid-laroi',
  '-rema-',
  'salt-n-pepa',
  'miguel-',
  'kendrick-lamar',
  'stormzy',
  'caribbean',
  'a-tribe-called-quest',
  'soul',
  'swizz-beatz',
  'verzuz',
  'lauryn-hill',
  'megan-thee-stallion',
  'outkast',
  'ferg-',
  'burna-',
  'feid',
  'rauw-',
  'dembow-',
  'merengue',
  'j-balvin',
  'bad-bunny',
  'ricky-martin',
  'anitta-',
  'motown',
  'kelly-clarkson',
  '60s-',
  'take-that',
  'taylors-version',
  'craig-david',
  'pablo-vittar',
  'janet-jackson',
  '-rb-',
  'aguilera',
  'madonna',
  'jonas-brothers',
  'kylie-minogue',
  'musical',
  'duran-duran',
  'mariah-carey',
  'taylor-swift',
  'nsync',
  'carly-rae',
  'metal',
  'blink-182',
  'motley',
  '50s',
  'miley-cyrus',
  'beatles',
  'eminem',
  'bob-marley',
  'lizzo',
  'david-guetta',
  'rent',
  '-pnk-',
];

export const outdoorWorkoutMusicRunKeywords = [
  'edm-run',
  '70s-run',
  '80s-run',
  '90s-run',
  'pop-run',
  'latin-run',
  'll-cool-j-run',
  'rock-run',
  'punk-run',
  'hip-hop-run',
  'shania-twain-run',
  'bad-boy-run',
  'reggaeton-run',
  'dance-run',
  'country-run',
  'trap-run',
  'gloria-estefan-run',
  'house-run',
  '2000s-run',
  'fall-out-boy-run',
  '2010s-run',
  'blackpink-run',
  'bieber-run',
  'goulding-run',
  'lil-wayne-run',
  'britney-spears-run',
  'broadway-run',
  'earth-wind-fire-run',
  'classical-run',
  'techno-run',
  'new-wave-run',
  'hozier-run',
  'black-keys-run',
  'dave-matthews-run',
  'gorillaz-run',
  'machine-gun-kelly-run',
  'thirty-seconds-to-mars-run',
  'yeah-yeah-yeahs-run',
  'grunge-run',
  '-afo-run',
  '-muna-run',
  'system-of-a-down-run',
  'red-hot-chili-peppers-run',
  'spotify-.*-run',
  '-weezer-run',
  'paramore-run',
  'new-tracks-run',
  'billie-eilish-run',
  'coldplay-run',
  'zz-top-run',
  'disco-run',
  'rave-run',
  'skrillex-run',
  'listening-party-run',
  'van-buuren-run',
  'garage-run',
  'kygo-run',
  'fka-twigs-run',
  'afrobeats-run',
  'techno-run',
  'gospel-run',
  'zach-bryan-run',
  'chris-stapleton-run',
  'maren-morris-run',
  'luke-bryan-run',
  'florida-georgia-run',
  'reba-run',
  'eric-church-run',
  'mickey-guyton-run',
  'keith-urban-run',
  'dolly-parton-run',
  'run-dmc-run',
  'dmx-run',
  'ludacris-run',
  'kid-laroi-run',
  '-rema-run',
  'salt-n-pepa-run',
  'miguel-run',
  'kendrick-lamar-run',
  'stormzy-run',
  'caribbean-run',
  'a-tribe-called-quest-run',
  'soul-run',
  'swizz-beatz-run',
  'verzuz-run',
  'lauryn-hill-run',
  'megan-thee-stallion-run',
  'outkast-run',
  'ferg-run',
  'burna-run',
  'feid-run',
  'rauw-run',
  'dembow-run',
  'merengue-run',
  'j-balvin-run',
  'bad-bunny-run',
  'ricky-martin-run',
  'anitta-run',
  'motown-run',
  'kelly-clarkson-run',
  '60s-run',
  'take-that-run',
  'taylors-version-run',
  'craig-david-run',
  'pablo-vittar-run',
  'janet-jackson-run',
  '-rb-run',
  'aguilera-run',
  'madonna-run',
  'jonas-brothers-run',
  'kylie-minogue-run',
  'musical-run',
  'duran-duran-run',
  'mariah-carey-run',
  'taylor-swift-run',
  'nsync-run',
  'carly-rae-run',
  'metal-run',
  'blink-182-run',
  'motley-run',
  '50s-run',
  'miley-cyrus-run',
  'beatles-run',
  'eminem-run',
  'bob-marley-run',
  'lizzo-run',
  'david-guetta-run',
  'rent-run',
];

export const outdoorWorkoutMusicWalkKeywords = [
  'edm-walk',
  '70s-walk',
  '80s-walk',
  '90s-walk',
  'pop-walk',
  'latin-walk',
  'll-cool-j-walk',
  'rock-walk',
  'punk-walk',
  'hip-hop-walk',
  'shania-twain-walk',
  'bad-boy-walk',
  'reggaeton-walk',
  'dance-walk',
  'country-walk',
  'trap-walk',
  'gloria-estefan-walk',
  'house-walk',
  '2000s-walk',
  'fall-out-boy-walk',
  '2010s-walk',
  'blackpink-walk',
  'bieber-walk',
  'goulding-walk',
  'lil-wayne-walk',
  'britney-spears-walk',
  'broadway-walk',
  'earth-wind-fire-walk',
  'classical-walk',
  'techno-walk',
  'new-wave-walk',
  'hozier-walk',
  'black-keys-walk',
  'dave-matthews-walk',
  'gorillaz-walk',
  'machine-gun-kelly-walk',
  'thirty-seconds-to-mars-walk',
  'yeah-yeah-yeahs-walk',
  'gwalkge-walk',
  '-afo-walk',
  '-muna-walk',
  'system-of-a-down-walk',
  'red-hot-chili-peppers-walk',
  'spotify-.*-walk',
  '-weezer-walk',
  'paramore-walk',
  'new-tracks-walk',
  'billie-eilish-walk',
  'coldplay-walk',
  'zz-top-walk',
  'disco-walk',
  'rave-walk',
  'skrillex-walk',
  'listening-party-walk',
  'van-buuren-walk',
  'garage-walk',
  'kygo-walk',
  'fka-twigs-walk',
  'afrobeats-walk',
  'techno-walk',
  'gospel-walk',
  'zach-bryan-walk',
  'chris-stapleton-walk',
  'maren-morris-walk',
  'luke-bryan-walk',
  'florida-georgia-walk',
  'reba-walk',
  'eric-church-walk',
  'mickey-guyton-walk',
  'keith-urban-walk',
  'dolly-parton-walk',
  'walk-dmc-walk',
  'dmx-walk',
  'ludacris-walk',
  'kid-laroi-walk',
  '-rema-walk',
  'salt-n-pepa-walk',
  'miguel-walk',
  'kendrick-lamar-walk',
  'stormzy-walk',
  'caribbean-walk',
  'a-tribe-called-quest-walk',
  'soul-walk',
  'swizz-beatz-walk',
  'verzuz-walk',
  'lauryn-hill-walk',
  'megan-thee-stallion-walk',
  'outkast-walk',
  'ferg-walk',
  'burna-walk',
  'feid-walk',
  'rauw-walk',
  'dembow-walk',
  'merengue-walk',
  'j-balvin-walk',
  'bad-bunny-walk',
  'ricky-martin-walk',
  'anitta-walk',
  'motown-walk',
  'kelly-clarkson-walk',
  '60s-walk',
  'take-that-walk',
  'taylors-version-walk',
  'craig-david-walk',
  'pablo-vittar-walk',
  'janet-jackson-walk',
  '-rb-walk',
  'aguilera-walk',
  'madonna-walk',
  'jonas-brothers-walk',
  'kylie-minogue-walk',
  'musical-walk',
  'duran-duran-walk',
  'mariah-carey-walk',
  'taylor-swift-walk',
  'nsync-walk',
  'carly-rae-walk',
  'metal-walk',
  'blink-182-walk',
  'motley-walk',
  '50s-walk',
  'miley-cyrus-walk',
  'beatles-walk',
  'eminem-walk',
  'bob-marley-walk',
  'lizzo-walk',
  'david-guetta-walk',
  'rent-walk',
];

export const walkingThemeKeywords = [
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'earth-day',
  'valentine',
  'riopy-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
  'mood-hiit',
  'halloween',
  'from-home',
  'sweat-steady',
  'club-bangers',
  'boss-ride',
  'pick-me-up',
  'feel-good',
  'vinyl-vault',
  'reset',
  'jess-king-experience',
  'nye-',
  'top-50',
  'boxing',
  'move-as-one',
  'persons-with-disabilities',
  'liverpool',
  'sundays-with-love',
  'sing-along',
  'anniversary',
  'nba-',
  'black-history',
  'cover-to-cover',
  'movie',
  'lhhm-',
  'xoxo-',
  'sample-that',
  'barbie',
  'fourth-of-july',
  'sold-out-show',
  'speak-up',
  'memorial',
  'mixtape',
  'to-greatness',
  'dancehall',
  'st-patricks',
  'lol-cody',
  'fresh-friday',
  'oktoberfest',
  'carnival',
  'aussie-vibes',
  'wimbledon',
  'turn-it-up',
  'jubilee',
  'bhangra',
  'mood-walk',
  'together-we',
  'appreciation',
  'reflection',
  'history-month',
  'football',
  'jke-',
  '-007-',
  'festive',
  'work-it-out',
  'latino-',
  'canada',
  'all-star',
  'armistice',
  'world-aids-day',
  'running-day',
  'our-future-selves',
  'walk-talk',
  'disney',
];

export const runningThemeKeywords = [
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'earth-day',
  'valentine',
  'riopy-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
  'mood-hiit',
  'halloween',
  'from-home',
  'sweat-steady',
  'club-bangers',
  'boss-ride',
  'pick-me-up',
  'feel-good',
  'vinyl-vault',
  'reset',
  'jess-king-experience',
  'nye-',
  'top-50',
  'boxing',
  'move-as-one',
  'persons-with-disabilities',
  'liverpool',
  'sundays-with-love',
  'sing-along',
  'anniversary',
  'nba-',
  'black-history',
  'cover-to-cover',
  'movie',
  'lhhm-',
  'xoxo-',
  'sample-that',
  'barbie',
  'fourth-of-july',
  'sold-out-show',
  'speak-up',
  'memorial',
  'mixtape',
  'to-greatness',
  'dancehall',
  'st-patricks',
  'lol-cody',
  'fresh-friday',
  'oktoberfest',
  'carnival',
  'aussie-vibes',
  'wimbledon',
  'turn-it-up',
  'jubilee',
  'bhangra',
  'mood-run',
  'together-we',
  'appreciation',
  'reflection',
  'history-month',
  'football',
  'jke-',
  '-007-',
  'festive',
  'work-it-out',
  'latino-',
  'canada',
  'all-star',
  'armistice',
  'world-aids-day',
  'running-day',
  'our-future-selves',
];

export const yogaThemeKeywords = [
  'lhm',
  'holiday',
  'martin-luther',
  'pride-celebration',
  'father',
  'chelsea-set',
  'turkey-burn',
  'reset',
  'mother',
  'flow-let-go',
  'mood-yoga',
  'whm',
  'kwanza',
  'breathe-in',
  'courage',
  'apihm',
  'mental health',
  'appreciation',
  'top-50',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
];

export const cyclingThemeKeywords = [
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'earth-day',
  'valentine',
  'riopy-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
  'mood-hiit',
  'halloween',
  'from-home',
  'sweat-steady',
  'club-bangers',
  'boss-ride',
  'pick-me-up',
  'feel-good',
  'vinyl-vault',
  'reset',
  'jess-king-experience',
  'nye-',
  'top-50',
  'boxing',
  'move-as-one',
  'persons-with-disabilities',
  'liverpool',
  'sundays-with-love',
  'sing-along',
  'anniversary',
  'nba-',
  'black-history',
  'cover-to-cover',
  'movie',
  'lhhm-',
  'xoxo-',
  'sample-that',
  'barbie',
  'fourth-of-july',
  'sold-out-show',
  'speak-up',
  'memorial',
  'mixtape',
  'to-greatness',
  'dancehall',
  'st-patricks',
  'lol-cody',
  'fresh-friday',
  'oktoberfest',
  'carnival',
  'aussie-vibes',
  'wimbledon',
  'turn-it-up',
  'jubilee',
  'bhangra',
  'mood-ride',
  'together-we',
  'appreciation',
  'reflection',
  'history-month',
  'football',
  'jke-',
  '-007-',
  'festive',
  'work-it-out',
  'latino-',
  'canada',
  'all-star',
  'disney',
];

export const cardioThemeMusicHiitKeywords = [
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'top-50',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'earth-day',
  'valentine',
  'riopy-',
  'afo-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
  'edm-',
  '70s-',
  '80s-',
  '90s-',
  'pop',
  'latin',
  'll-cool-j',
  'rock-',
  'punk',
  'hip-hop',
  'shania-twain',
  'bad-boy',
  'reggaeton',
  'dance',
  'country',
  'trap-',
  'gloria-estefan',
  'house-',
  '2000s-',
  'fall-out-boy-',
  '2010s-',
  'mood-hiit',
  'halloween',
  'blackpink',
  'bieber',
  'goulding-hiit',
  'bring-the-heat',
  'lil-wayne',
  'britney-spears',
  'from-home',
];

export const meditationThemeKeywords = [
  'lhm-',
  'holiday',
  'martin-luther',
  'pride',
  'father',
  'turkey-burn',
  'mother',
  'whm-',
  'kwanza',
  'apihm-',
  'mental-health',
  'top-50',
  'premiere',
  'juneteenth',
  'all-for-one',
  'move-for-you',
  'veteran',
  'seasonal',
  'new-year',
  'hanukkah',
  'let-go',
  'breathe-in',
  'zen-in-ten',
  'beyonce',
  'hozier',
  'earth-day',
  'valentine',
  'riopy-',
  'afo-',
  'api-heritage',
  'stacking-stuffer',
  'bhm-',
  'year-of-yes',
];

export const outdoorWorkoutThemeRunKeywords = [
  'month-run',
  'day-run',
  'heritage-run',
  'holiday-run',
  'top-50-.*-run',
  'awareness-run',
  'disney-run',
  'celebration-run',
  'liverpool-fc-run',
  'boro-run',
  'turkey-trot',
  'day-recovery-run',
  'running-day',
  'juneteenth-run',
  'beginner-run',
  'pace-setter',
  'homecoming-run',
  'fun-run',
  'disney-run',
];
