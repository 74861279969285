import { Headline, Flex, spacing, Container } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import { useLocale } from '@peloton/internationalize/locales';
import { toCountry } from '@peloton/internationalize/models';
import { toLanguageFromLocale } from '@peloton/internationalize/models/language';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { Language } from '@ecomm/graphql/types.generated';
import type { ReviewableTypes } from '@ecomm/models/DeviceType';
import { useReviewWithStatsQuery } from '@ecomm/reviews/data/utils/useReviewWithStatsQuery';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { RECENT_REVIEWS_QUERY_PARAMS } from './constants';
import RatingAndStars from './RatingAndStars';
import RecentReviewsCarousel from './RecentReviewsCarousel';
import ReviewsModal from './ReviewsModal';
import ReviewsStats from './ReviewsStats';
import ReviewsSummary from './ReviewsSummary';
import ScoreWheels from './ScoreWheels';
import { themes } from './themes';

type AIReviewCardProps = Omit<TypeComponentReviewsFields, 'reviews' | 'treatment'>;
const AIReviewCards: React.FC<React.PropsWithChildren<AIReviewCardProps>> = ({
  deviceType,
  text,
  theme = 'White',
  ctas,
}) => {
  const { trackEvent } = useTracking();
  const { backgroundColor, textColor } = themes[theme];
  const { headline } = text?.fields || {};

  const lang = (toLanguageFromLocale(useLocale()) as unknown) as Language;
  const excludeFamily = true; // update later
  const {
    orderBy,
    reviewsToDisplay,
    defaultReviewQuantities,
    minimalReviewRating,
  } = RECENT_REVIEWS_QUERY_PARAMS;

  const {
    ratingsDistribution,
    reviewsProps,
    statsProps,
    totalReviews,
    secondaryRatingAverages,
  } = useReviewWithStatsQuery(
    (deviceType ?? 'bike') as ReviewableTypes,
    orderBy,
    reviewsToDisplay,
    defaultReviewQuantities,
    lang,
    excludeFamily,
    minimalReviewRating,
  );

  const [isReviewsModalOpen, setIsReviewsModalOpen] = React.useState<boolean>(false);
  const [activeRating, setActiveRating] = React.useState<number | undefined>(undefined);
  const [activeReviewId, setActiveReviewId] = React.useState<string | undefined>(
    undefined,
  );

  if (deviceType === undefined || reviewsProps.length === 0) {
    console.warn(
      `No reviews returned from API ${
        deviceType === undefined && '-- deviceType not specified'
      }`,
    );
    return null;
  }

  const onModalClosed = () => {
    setIsReviewsModalOpen(false);
    setActiveRating(undefined);
    setActiveReviewId(undefined);
    trackEvent({
      event: TrackingEvent.ModalClosed,
      properties: {
        country: toCountry().toLocaleUpperCase(),
        pageName: window.location.pathname,
        productInterest: deviceType,
        propertyType: 'Web',
        unitName: `${deviceType} Reviews Modal`,
        unitPlacement: 'Modal',
        modalTrigger: 'Click',
      },
    });
  };

  const trackStatsClick = (statText: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'AI Reviews Component (CHAMP)',
        parent: 'Review Stats',
        unitName: 'Review Stat Item',
        linkName: statText,
        linkTo: 'Opened Reviews Modal',
      },
    });
  };

  return (
    <OuterContainer
      id="ai-review-cards"
      theme={theme}
      horizontalPadding={{
        tablet: spacing[64],
        desktop: spacing[40],
      }}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      flexDirection="column"
      backgroundColor={backgroundColor}
      style={{ boxSizing: 'content-box' }}
    >
      <Container horizontalPadding={{ mobile: spacing[16], desktop: 0 }}>
        <Headline size="small" textColor={textColor}>
          {headline}
        </Headline>
        <RatingAndStars
          averageRating={statsProps}
          theme={theme}
          totalReviews={totalReviews}
          setActiveRating={rating => setActiveRating(rating)}
          setIsReviewsModalOpen={() => setIsReviewsModalOpen(true)}
          trackStatsClick={trackStatsClick}
        />
        <Flex
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          alignItems="flex-start"
          verticalPadding={{
            mobile: `${spacing[24]} 0`,
            desktop: `${spacing[40]} 0`,
          }}
        >
          <Flex
            flexDirection="column"
            flexGrow={1}
            flexShrink={1}
            flexBasis="0%"
            horizontalMargin={{ mobile: '0', desktop: `0 ${spacing[64]}` }}
          >
            <ReviewsStats
              ratingsDistribution={ratingsDistribution}
              theme={theme}
              setActiveRating={rating => setActiveRating(rating)}
              setIsReviewsModalOpen={() => setIsReviewsModalOpen(true)}
              trackStatsClick={trackStatsClick}
            />
            <ScoreWheels
              secondaryRatingAverages={secondaryRatingAverages}
              theme={theme}
            />
          </Flex>
          <ReviewsSummary deviceType={deviceType} theme={theme} />
        </Flex>
      </Container>
      {(!reviewsProps || reviewsProps.length > 1) && (
        <RecentReviewsCarousel
          reviewsProps={reviewsProps}
          numberOfReviewsToDisplay={reviewsToDisplay}
          setActiveRating={rating => setActiveRating(rating)}
          setActiveReviewId={reviewId => setActiveReviewId(reviewId)}
          setIsReviewsModalOpen={() => setIsReviewsModalOpen(true)}
          theme={theme}
        />
      )}

      <ReviewsModal
        headline={headline}
        isOpen={isReviewsModalOpen}
        isFirstReview={reviewsProps[0].id === activeReviewId}
        deviceType={deviceType as ReviewableTypes}
        activeRating={activeRating}
        activeReviewId={activeReviewId}
        theme={theme}
        closeHandler={() => onModalClosed()}
        setActiveRating={rating => setActiveRating(rating)}
        cta={ctas && ctas[0].fields}
      />
    </OuterContainer>
  );
};

export default AIReviewCards;
