import { Type } from '@ecomm/pg-checkout-commercetools/utils/types';
import type {
  Product,
  Accessory,
  Bundle,
  CFU,
  CommerceToolsAccessory,
  CommerceToolsBundle,
  CommerceToolsCFU,
  SparePart,
  CommerceToolsSparePart,
} from './Product';

export const isProductAccessory = (product: Product): product is Accessory => {
  return product.productType === 'accessory';
};

export const isProductBundle = (product: Product): product is Bundle => {
  return product.productType === 'bundle';
};

export const isProductCFU = (product: Product): product is CFU => {
  return product.productType === 'cfu';
};

export const isProductSparePart = (product: Product): product is SparePart => {
  return product.productType === Type.SPARE_PART;
};

const isCommerceToolsProduct = (product: Product) => product.hasOwnProperty('ctProduct');

export const isCommerceToolsAccessory = (
  product: Product,
): product is CommerceToolsAccessory => {
  return isProductAccessory(product) && isCommerceToolsProduct(product);
};

export const isCommerceToolsBundle = (
  product: Product,
): product is CommerceToolsBundle => {
  return isProductBundle(product) && isCommerceToolsProduct(product);
};

export const isCommerceToolsCFU = (product: Product): product is CommerceToolsCFU => {
  return isProductCFU(product) && isCommerceToolsProduct(product);
};

export const isCommerceToolsSparePart = (
  product: Product,
): product is CommerceToolsSparePart => {
  return isProductSparePart(product) && isCommerceToolsProduct(product);
};
