import { Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useHasVariation, useSplitTestingContext } from '@peloton/split-testing/hooks';
import { Spinner } from '@ecomm/spinner';
import type { PageHandler as VariationHandler } from '@page-builder/layout/types';
import { ComponentContentTypes } from '@page-builder/lib/constants';
import { LoaderBlock } from '../Block/LoaderBlock';

type OptimizelyProps = {
  experimentName: string;
  variationName: string;
  moduleData?: any; // TODO: Create type that encompasses all possible module options
};

type Props = VariationHandler<OptimizelyProps>;

const OptimizelyBlock: React.FC<React.PropsWithChildren<Props>> = ({
  experimentName,
  variationName,
  moduleData,
  block: VariationBlock,
}) => {
  const { hasStalled } = useSplitTestingContext();

  // isActive will be null while user is getting bucketed
  const isActive = useHasVariation(experimentName, variationName);
  const isOriginalWithData = Boolean(variationName === 'Original' && moduleData);
  const showMainSpinner = isActive === null && isOriginalWithData;

  if (showMainSpinner && !hasStalled) {
    if (moduleData.sys.contentType.sys.id === ComponentContentTypes.Hero) {
      return <VariationBlock block={moduleData} />;
    }

    return (
      <LoaderBlock
        block={moduleData}
        fallback={
          <Loader flexDirection="column" justifyContent="center" alignItems="center">
            <Spinner />
          </Loader>
        }
      />
    );
  }

  // If we're stalled, just show the control if there is one
  if (hasStalled && isOriginalWithData) {
    return <VariationBlock block={moduleData} />;
  }

  if (isActive && moduleData) {
    return <VariationBlock block={moduleData} />;
  }

  return null;
};

export default OptimizelyBlock;

const Loader = styled(Flex)`
  min-height: 250px;
`;
