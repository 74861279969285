import {
  Body,
  Container,
  Flex,
  Support,
  spacing,
  Badge,
  red,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import shouldHideContent from '@ecomm/copy/helpers/shouldHideContent';
import type { TypeComponentGenericList } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { toPrimaryDrawerCta } from '../utils';
import { usePromoContext } from './../PromoProvider';
import { useShopContext } from './../ShopContext';
import DrawerContent from './DrawerContent/DrawerContent';
import DrawerFinancingSection from './DrawerContent/DrawerFinancingSection';
import { DrawerMarkdown } from './DrawerContent/DrawerMarkdown';
import { strikethroughTreatment } from './priceUtils';
import PriceWithPromo from './PriceWithPromo';

type DrawerProps = {
  drawer: TypeComponentGenericList;
  parentName: string;
  product: string;
};

const SoloDrawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  drawer,
  parentName,
  product,
}) => {
  const {
    fields: { text },
  } = drawer;
  const {
    headline: price,
    body: priceSubhead = 'norender',
  } = getTextFieldsWithRequiredKeys([], text);
  const showSubhead = !shouldHideContent(priceSubhead);

  const { packageSlug } = useShopContext();
  const { bundlePromo } = usePromoContext();

  const primaryCta = toPrimaryDrawerCta(drawer);

  return (
    <Container data-test-id="overview-solo-drawer">
      <Flex flexDirection="column" gap={spacing[8]}>
        <Price data-test-id="overview-drawer-price">
          {packageSlug !== '' && (
            <PriceWithPromo packageSlug={packageSlug} pageBuilderPrice={price} />
          )}
        </Price>
        {primaryCta && primaryCta.fields.productSlug && (
          <DrawerFinancingSection
            productSlug={primaryCta.fields.productSlug}
            product={product}
            isDrawerOpen
            index={0}
            parentName={parentName}
            isSoloDrawer
          />
        )}
        {bundlePromo?.fields.urgencyMessaging ? (
          <Support>
            <DrawerMarkdown content={bundlePromo.fields.urgencyMessaging} />
          </Support>
        ) : (
          showSubhead && (
            <Support>
              <Markdown
                content={priceSubhead}
                markdown={{
                  renderers: {
                    inlineCode: ({ node, ...props }) => (
                      <StyledBadge theme="lime" variant="horizontal">
                        {props.children}
                      </StyledBadge>
                    ),
                    link: ({ node, ...props }) => (
                      <StyledLink to={props.href} href={props.href} target="_blank">
                        <Support size="medium">{props.children}</Support>
                      </StyledLink>
                    ),
                    strong: ({ node, ...props }) => (
                      <span style={{ color: red[50] }}>{props.children}</span>
                    ),
                  },
                }}
              />
            </Support>
          )
        )}
      </Flex>
      <DrawerContent isOpen />
    </Container>
  );
};

export const StyledBadge = styled(Badge)`
  margin-top: ${spacing[8]};
`;

export const Price = styled(Body)`
  ${strikethroughTreatment}
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  transform: translateY(1px);

  ::after {
    transform: translateY(-6px);
  }
`;

export default SoloDrawer;
