import type { MediaImageProps } from '@pelotoncycle/design-system';
import { Media } from '@pelotoncycle/design-system';
import { TextWithInlineMedia } from '@pelotoncycle/page-builder';
import type {
  TypeComponentGenericTextWithMediaFields,
  TypeComponentImageFields,
  TypeComponentTextWithInlineMedia,
  TypeComponent_hero,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import type { BlockProps } from '@page-builder/lib/pages';
import Hero from '@page-builder/modules/Hero';
import HeroFiftyFiftyHybridBackLink from '@page-builder/modules/HeroFiftyFiftyHybrid/HeroFiftyFiftyHybridBackLink';
import { themes } from '@page-builder/themes';

type Props = TypeComponentGenericTextWithMediaFields & TypeComponent_hero & BlockProps;

const HeroFiftyFiftyHybrid: React.FC<React.PropsWithChildren<Props>> = props => {
  const { theme, name, media, text, ctas } = props;
  const heroTheme = theme || 'Black';

  const heroProps = {
    className: 'component_hero first-block-offset',
    fields: {
      name,
      alignContent: 'Left',
      size: 'Small',
      ctas: ctas?.map(cta => ({
        fields: cta.fields,
      })),
      backgroundMedia: {
        fields: media?.fields,
      },
      text: {
        fields: text?.fields,
      },
    },
  };

  const textInlineMediaProps = {
    fields: {
      name,
      ctas: ctas?.map(cta => ({
        fields: cta.fields,
        sys: cta.sys,
      })),
      backgroundMedia: {
        fields: media?.fields,
      },
      text: {
        fields: text?.fields || {
          headline: '',
          body: '',
        },
      },
      template: '50/50 Fullwidth',
      theme: 'Grey 90',
    },
  };

  const mediaFields = media?.fields?.media?.fields;
  const imageUrl =
    (mediaFields as TypeComponentImageFields)?.mobile?.fields?.file?.url || '';

  const mediaContainerImage: MediaImageProps = {
    type: 'image',
    mobile: imageUrl,
    alt: (mediaFields as TypeComponentImageFields)?.alt ?? '',
  };

  return (
    <BreakpointSwitchCSS
      breakpoints={{
        mobile: (
          <>
            <HeroFiftyFiftyHybridBackLink theme={heroTheme} />
            <MediaContainer data-test-id="mediaContainer">
              {media && <Media media={mediaContainerImage} />}
            </MediaContainer>
            <StyledTextDiv data-test-id="textContainer">
              <TextWithInlineMedia
                {...((textInlineMediaProps as unknown) as TypeComponentTextWithInlineMedia &
                  BlockProps)}
              />
            </StyledTextDiv>
          </>
        ),
        desktopLarge: (
          <StyledHeroContainer data-test-id="styledHero" theme={heroTheme}>
            <HeroFiftyFiftyHybridBackLink theme={heroTheme} />
            <StyledHero {...(heroProps as TypeComponent_hero & BlockProps)} />
          </StyledHeroContainer>
        ),
      }}
    />
  );
};

export default HeroFiftyFiftyHybrid;

const StyledHeroContainer = styled.div<{ theme: string }>`
  p {
    color: ${props => themes[props.theme].headlineColor};
  }
  width: 100%;
`;

const StyledHero = styled(Hero)`
  margin: 0 auto;
  h1 img {
    max-height: ${100 / 16}rem;
  }

  section {
    padding-top: ${100 / 16}rem;
    padding-left: 5.5rem;
    align-self: center;
    display: flex;
    justify-content: center;
  }

  section > div {
    max-width: 113rem;
  }

  p {
    max-width: 27rem;
  }

  [class^='View__StyledOverlay'] {
    background: linear-gradient(
      90deg,
      rgba(24, 26, 29, 0.33) 0%,
      rgba(24, 26, 29, 0) 100%
    );
  }
`;

const MediaContainer = styled.div`
  width: 100%;
  vertical-align: middle;
  img,
  video {
    width: 100%;
    height: auto;
  }
`;

const StyledTextDiv = styled.div`
  h1 img {
    max-height: ${75 / 16}rem;
  }
`;
