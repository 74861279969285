import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useTracking } from 'react-tracking';
import PageContext from '@page-builder/layout/context/PageContext';

export const useVideoTrackEvent = () => {
  const { asPath } = useRouter();
  const { trackEvent } = useTracking();
  const { additionalTrackingData } = useContext(PageContext);
  const {
    isLoggedIn,
    hasDeviceSubscription,
    hasDigitalSubscription,
  } = additionalTrackingData;

  const onClickTrackEvent = ({
    parent,
    linkName,
    parentType,
  }: {
    parent: string;
    linkName: string;
    parentType: string;
  }) => {
    trackEvent({
      event: 'Clicked Stream Video',
      properties: {
        page: asPath,
        isLoggedIn,
        hasDeviceSubscription,
        hasDigitalSubscription,
        parent,
        linkName,
        unitName: 'Free class',
        parentType,
      },
    });
  };

  return { onClickTrackEvent };
};
