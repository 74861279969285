import { Flex, Grid, Label, Container, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { useMicroCopy } from '@content/client/microCopy';
import type { RatingStats } from '@ecomm/reviews/data/utils/useReviewWithStatsQuery';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { themes } from './themes';

type AIReviewsStatsProps = {
  ratingsDistribution: RatingStats[];
  theme: TypeComponentReviewsFields['theme'];
  setActiveRating: (rating: number | undefined) => void;
  setIsReviewsModalOpen: () => void;
  trackStatsClick: (text: string) => void;
};

type ReviewStatsRowProps = {
  ratingStats: RatingStats;
  theme: TypeComponentReviewsFields['theme'];
  setActiveRating: (rating: number | undefined) => void;
  setIsReviewsModalOpen: () => void;
  trackStatsClick: (text: string) => void;
};

const ReviewsStats: React.FC<AIReviewsStatsProps> = ({
  ratingsDistribution,
  theme,
  setActiveRating,
  setIsReviewsModalOpen,
  trackStatsClick,
}) => {
  return (
    <Flex flexDirection="column">
      <Grid
        columnCount={1}
        columnSize="auto 1fr auto"
        gap={`${spacing[2]} ${spacing[16]}`}
      >
        {ratingsDistribution.map(ratingStats => (
          <ReviewCount
            key={`review-count-${ratingStats.ratingValue}`}
            ratingStats={ratingStats}
            theme={theme}
            setActiveRating={setActiveRating}
            setIsReviewsModalOpen={setIsReviewsModalOpen}
            trackStatsClick={trackStatsClick}
          />
        ))}
      </Grid>
    </Flex>
  );
};

const Button = styled.button`
  width: 100%;
`;
const Div = styled.div`
  width: 100%;
`;
const ReviewCountWrapper: React.FC<{
  isClickable: boolean;
  onClick: () => void;
  testId: string;
  children: React.ReactNode;
}> = ({ isClickable, onClick, testId, children }) => {
  return isClickable ? (
    <Button data-test-id={testId} onClick={onClick}>
      {children}
    </Button>
  ) : (
    <Div data-test-id={testId}>{children}</Div>
  );
};

const ReviewCount: React.FC<ReviewStatsRowProps> = ({
  ratingStats,
  theme,
  setActiveRating,
  setIsReviewsModalOpen,
  trackStatsClick,
}) => {
  const { count, percentOfTotal, ratingValue } = ratingStats;
  const barPercent = percentOfTotal + '%';
  const { secondaryBackgroundColor, textColor } = themes[theme];
  const onClick = (text: string) => {
    setActiveRating(ratingStats.ratingValue);
    setIsReviewsModalOpen();
    trackStatsClick(text);
  };

  const toFormattedText = useGetTextFormatter();
  const starsMicrocopy = useMicroCopy('reviewsStars');
  const starsText = toFormattedText(starsMicrocopy, {
    rating: ratingValue,
    isPlural: ratingValue !== 1,
  });

  const isClickable = count !== 0;

  return (
    <>
      <Container>
        <ReviewCountWrapper
          isClickable={isClickable}
          onClick={() => onClick(starsText as string)}
          testId={`review-stats-label-modal-button-${ratingValue}`}
        >
          <StyledLabel size="large" textColor={textColor} isClickable={isClickable}>
            {starsText}
          </StyledLabel>
        </ReviewCountWrapper>
      </Container>
      <Container verticalPadding={'3px 0'}>
        <ReviewCountWrapper
          isClickable={isClickable}
          onClick={() => onClick(`${starsText} bar`)}
          testId={`reviews-stats-bar-modal-button-${ratingValue}`}
        >
          <svg width="100%" height="12px">
            <rect
              width="100%"
              height="100%"
              fill={secondaryBackgroundColor}
              rx="2"
              ry="2"
            />
            <rect width={barPercent} height="100%" fill={textColor} rx="2" ry="2" />
          </svg>
        </ReviewCountWrapper>
      </Container>
      <Container>
        <ReviewCountWrapper
          isClickable={isClickable}
          onClick={() => onClick(`${starsText} %`)}
          testId={`review-stats-percentage-modal-button-${ratingValue}`}
        >
          <StyledLabel size="large" textColor={textColor} isClickable={isClickable}>
            {barPercent}
          </StyledLabel>
        </ReviewCountWrapper>
      </Container>
    </>
  );
};

export default ReviewsStats;

const StyledLabel = styled(Label)<{ isClickable: boolean }>`
  ${({ isClickable }) =>
    isClickable &&
    `
    text-decoration: underline;
    text-underline-offset: 3px;
  `}
`;
