import {
  Body,
  Eyebrow,
  grey,
  Headline,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TypeQuizQuestion } from '@page-builder/lib/types';
import { useTheme } from '@page-builder/modules/Quiz/utils/theme';
import { useTrackStepViewed } from '@page-builder/modules/Quiz/utils/tracking';
import { useQuestionProps } from '../utils/helpers';
import { designPaddings, designMaxWidth } from '../utils/layout';
import GroupQuestion from './GroupQuestion';
import SingleQuestion from './SingleQuestion';

type Props = {
  question: TypeQuizQuestion;
  currentQuestionIndex: number;
  quizSessionKey: string;
};

const QuizQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  currentQuestionIndex,
  quizSessionKey,
}) => {
  const {
    eyebrow,
    headline,
    support,
    options,
    getsGroupsFrom,
    slug,
    columns,
    singleAnswer,
  } = useQuestionProps(question);
  const { questionBackground } = useTheme();

  useTrackStepViewed(slug, currentQuestionIndex, quizSessionKey);

  return (
    <Content background={questionBackground}>
      {eyebrow && <StyledEyebrow textColor={grey[50]}>{eyebrow}</StyledEyebrow>}
      <Headline textColor={white} size="small">
        {headline}
      </Headline>
      {support && <Subtitle size="small">{support}</Subtitle>}
      {getsGroupsFrom ? (
        <GroupQuestion
          questionName={slug}
          options={options}
          getsGroupsFrom={getsGroupsFrom!}
        />
      ) : (
        <SingleQuestion
          questionName={slug}
          options={options}
          singleAnswer={singleAnswer}
          columns={columns}
        />
      )}
    </Content>
  );
};

export default QuizQuestion;

const HEADER_HEIGHT = 72;
const NAV_HEIGHT = 88;

const Content = styled.div<{ background: string }>`
  background: ${props => props.background};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${spacing[40]};
  padding-bottom: ${spacing[40]};
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${NAV_HEIGHT}px);

  ${media.desktopLarge`
    padding-top: ${spacing[80]};
    padding-bottom: ${spacing[80]};
  `}

  ${designPaddings}
`;

const Subtitle = styled(Body)`
  color: ${white};
  margin-top: ${spacing[16]};

  ${designMaxWidth(4)}
`;

const StyledEyebrow = styled(Eyebrow)`
  color: white;
  text-align: center;
  margin-bottom: ${spacing[16]};
`;
