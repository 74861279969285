import { brand, BreakpointWidths, Display, white } from '@pelotoncycle/design-system';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { b1, b2 } from '@ecomm/typography';

export const ContainerStyles = css`
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 500px;

  @media screen and (height < 900px) and (width > 768px) {
    height: 600px;
  }
  @media screen and (height > 900px) and (width > 768px) {
    height: 700px;
  }
`;

const WrapperStyles = css`
  color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem 1rem;

  h1,
  h2,
  h6,
  p {
    max-width: 480px;
  }

  ${media.iPhone6`
    padding: 2rem 1.5rem;
  `}

  ${media.tablet`
    padding: 2rem 3.125rem;    
  `}

  ${media.tabletXLarge`
    text-align: left;
    align-items: flex-start;
    h1, h2 {
      max-width: 680px;
    }

    h6, p {
      max-width: 520px;
    }
  `}

  ${media.desktop`
    padding: 2rem 8.75rem;
  `}
`;

export const ContentWrapper = styled.div`
  ${WrapperStyles}
`;

export const ContentWrapperWithGradient = styled.div`
  ${WrapperStyles}
  background-color: rgba(0, 0, 0, 0.95);
  mix-blend-mode: normal;
  height: 100%;
  transition: background-color 0.4s ease-in-out;

  &.active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${media.desktop`
    &.active {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, transparent 85%);
    }
  `}

  /* a style hack that enables us to style superscripts in contentful e.g. <sup>†</sup> */
  sup {
    font-size: 0.75rem;
    vertical-align: super;
    line-height: 0;
  }
`;

type SubheadProps = {
  increaseMaxWidth?: boolean;
};

export const StyledDisplay = styled(Display)`
  @media (max-width: ${BreakpointWidths.tablet}px) {
    font-size: 26px;
  }
`;

export const Subhead = styled.p<SubheadProps>`
  position: relative;
  ${b2};
  max-width: 284px;

  ${media.tablet`
    max-width: 400px;
  `}

  ${media.desktop<SubheadProps>`
    ${b1}
    max-width: ${props => `${props.increaseMaxWidth ? 530 : 510}px`};
  `}

  > span:first-of-type::not('[data-test-id="promo-hero-price"]') {
    &:before {
      content: '';
      display: block;
    }

    ${media.tabletXLarge`
      &:before {
        display: none;
      }
    `}
    color: ${brand.primary};
  }
`;

export const SupportTextWrapper = styled.div`
  a {
    text-decoration: underline;
  }
`;
