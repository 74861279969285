import { Video as StaticVideo } from '@pelotoncycle/design-system';
import React from 'react';
import type { TypeComponentVideo } from '@page-builder/lib/types';
import { getContentfulAssetUrl } from '@page-builder/utils/helpers/cms';

const Video: React.FC<React.PropsWithChildren<TypeComponentVideo>> = ({
  fields: { video, poster, loop, autoplay, playPauseButtonPosition },
}) => {
  const {
    fields: { mobile },
  } = poster;

  const {
    fields: {
      file: { url },
    },
  } = video;

  return (
    <StaticVideo
      src={url}
      poster={getContentfulAssetUrl(mobile)}
      loop={loop}
      autoplay={autoplay}
      playPauseButtonPosition={playPauseButtonPosition}
    />
  );
};

export default Video;
