import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import type { Class } from '@peloton/models/PelotonClass';
import { usePreviousRouteContext } from '@peloton/next/components/PreviousRouteProvider';
import { getPagePath } from '@peloton/next/utils/getPagePath';
import { useMicroCopy } from '@content/client/microCopy';
import { isClassLandingPage } from '@ecomm/classes/models/disciplines';
import { getClassListingPageSegments } from '@ecomm/classes/utils/getClassListingPageSegments';
import { useLoadMoreContext } from '@ecomm/pagination/LoadMore/LoadMoreContext';
import { useProductStates } from '@ecomm/product-states/Context';
import { getCFUByDiscipline } from '@page-builder/modules/ClassPage/utils/getCFUbyDiscipline';
import { nameToModuleId } from '@page-builder/utils/helpers';

export const useClassGridHooks = (name: any, classes: Class[]) => {
  const moduleId = nameToModuleId(name);
  const { trackEvent } = useTracking();
  const router = useRouter();
  const previousPath = usePreviousRouteContext();
  const pagePath = getPagePath(router);
  const { isDiscipline, isFiltered } = getClassListingPageSegments(router.asPath);
  const isCLP = isClassLandingPage(`classes${pagePath}`);
  const { hasNext, hasPrevious, lowestLoadedPage } = useLoadMoreContext();

  const classesPaginationNextLabel = useMicroCopy('classesPaginationNextLabel');
  const classesPaginationPreviousLabel = useMicroCopy('classesPaginationPreviousLabel');
  const classesPaginationLoadingLabel = useMicroCopy('classesPaginationLoadingLabel');
  const classesPaginationViewingLabel = useMicroCopy('classesPaginationViewingLabel');
  const classFiltersShowingLabel = useMicroCopy('classFiltersShowingLabel');
  const classFiltersMoreOnAppLabel = useMicroCopy('classFiltersMoreOnAppLabel');

  const fitnessDiscipline = classes[0]?.fitnessDiscipline;
  const CFU = getCFUByDiscipline(fitnessDiscipline);
  const { isProductAvailableForMarketingPages } = useProductStates();

  const isProductAvailable = isProductAvailableForMarketingPages(CFU.product);

  return {
    moduleId,
    trackEvent,
    router,
    previousPath,
    pagePath,
    isDiscipline,
    isFiltered,
    isCLP,
    hasNext,
    hasPrevious,
    lowestLoadedPage,
    classesPaginationNextLabel,
    classesPaginationPreviousLabel,
    classesPaginationLoadingLabel,
    classesPaginationViewingLabel,
    classFiltersShowingLabel,
    classFiltersMoreOnAppLabel,
    fitnessDiscipline,
    CFU,
    isProductAvailable,
  };
};
