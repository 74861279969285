import { Flex, spacing, Label, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

export type Difficulty = 'beginner' | 'intermediate' | 'advanced';
export type DifficultyLabels = Record<Difficulty, string>;

type Props = {
  difficultyRate: Difficulty | null;
  difficultyLabels: DifficultyLabels;
};

const ClassDifficultyRate: React.FC<React.PropsWithChildren<Props>> = ({
  difficultyRate = 'beginner',
  difficultyLabels,
}) => {
  return (
    difficultyRate && (
      <Flex flexDirection="row" gap="4px" alignItems="center">
        <ClassLevelColor complexity={difficultyRate} />
        <Label size="large" textColor={white} as="p" data-test-id="difficulty-rating">
          {difficultyLabels[difficultyRate]}
        </Label>
      </Flex>
    )
  );
};

export default ClassDifficultyRate;

const levelColors = {
  beginner: '#50C4AA',
  intermediate: '#FACB3E',
  advanced: '#FC820F',
};
const ClassLevelColor = styled.div<{
  complexity: 'beginner' | 'intermediate' | 'advanced';
}>`
  width: ${spacing[8]};
  height: ${spacing[8]};
  border-radius: ${spacing[2]};
  background-color: ${props => levelColors[props.complexity]};
`;
