export const API_DOMAIN = 'https://prospects.prod.k8s.onepeloton.com';
export const API_SITEMAP_PATH = '/seo/sitemap/v1/';
export const API_CLASS_PATH = '/seo/class/v1/';
export const API_HEADERS = {
  Accept: 'application/camel+json',
  'Peloton-Partner-Client': 'Prospects Class Catalog Web App',
  ...(process.env.PROSPECTS_API_TOKEN
    ? { 'X-Peloton-API-Key': process.env.PROSPECTS_API_TOKEN }
    : {}),
};
export const API_ODB_SITEMAP_PATH = '/api/sitemap';
export const API_ODB_HEADERS = {
  ...(process.env.ODB_API_TOKEN ? { 'X-ODB-API-Key': process.env.ODB_API_TOKEN } : {}),
};
