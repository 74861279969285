import type { MediaImageProps } from '@pelotoncycle/design-system';
import type Contentful from 'contentful';
import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import { Product } from '@ecomm/product-states/models/product';
import type {
  TypeComponentGenericTextWithMediaFields,
  TypeComponentImageFields,
} from '@page-builder/lib/types';
import {
  ctaExtractData,
  imageFieldsToMediaImageProps,
} from '@page-builder/utils/helpers';
import type { GenericCtaData } from '@page-builder/utils/helpers/cta';

enum ConnectedFitnessUnit {
  APP = 'App',
  BIKE = 'Bike',
  GUIDE = 'Guide',
  ROW = 'Row',
  TREAD = 'Tread',
}

type CFU = {
  slug: 'App' | 'Bike' | 'Guide' | 'Row' | 'Tread';
  product: Product;
};

export type BannerData = {
  image: MediaImageProps;
  bannerText: string;
  ctaData: GenericCtaData;
};

export function getCFUByDiscipline(fitnessDiscipline: ProspectsDiscipline): CFU {
  switch (fitnessDiscipline) {
    case ProspectsDiscipline.CYCLING:
      return { product: Product.Bike, slug: 'Bike' };
    case ProspectsDiscipline.ROWING:
      return { product: Product.Row, slug: 'Row' };
    case ProspectsDiscipline.RUNNING:
    case ProspectsDiscipline.WALKING:
      return { product: Product.Tread, slug: 'Tread' };
    case ProspectsDiscipline.STRENGTH:
      return { product: Product.RainforestCafe, slug: 'Guide' };
    default:
      return { product: Product.DigitalApp, slug: 'App' };
  }
}

export function getCFUBannerData(
  slug: string,
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
  isProductAvailable: boolean,
  classPageCfuBannerCta: boolean,
): BannerData | undefined {
  const pageSlug =
    classPageCfuBannerCta && isProductAvailable ? slug : ConnectedFitnessUnit.APP;
  const itemNameByCFU = `trialCta${pageSlug}`;
  const selectedItem = items.find((item: any) => item.fields.name === itemNameByCFU);

  if (selectedItem === undefined) {
    return;
  }

  return {
    bannerText: selectedItem.fields!.text!.fields!.eyebrow || '',
    image: imageFieldsToMediaImageProps(
      selectedItem.fields!.media!.fields.media.fields as TypeComponentImageFields,
    ),
    ctaData: ctaExtractData(selectedItem.fields!.ctas![0]),
  };
}

export type AccessType = 'CFU' | 'APP';

export function getWaysToAccessData(
  slug: string,
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
  accessType: AccessType = 'CFU',
): BannerData | null {
  const ctaName =
    accessType === 'CFU' ? `waysToAccessCTA${slug}` : `appWaysToAccessCTA${slug}`;

  const selectedItem = items.find((item: any) => item.fields.name === ctaName);

  if (selectedItem === undefined) {
    return null;
  }
  const text = selectedItem.fields?.text?.fields?.eyebrow || '';
  const mediaFields = selectedItem.fields?.media?.fields.media.fields as
    | TypeComponentImageFields
    | undefined;
  const cta = selectedItem.fields?.ctas?.[0];

  if (mediaFields === undefined || cta === undefined) {
    return null;
  }

  return {
    bannerText: text,
    image: imageFieldsToMediaImageProps(mediaFields),
    ctaData: ctaExtractData(cta),
  };
}
