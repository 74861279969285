import { useRouter } from 'next/router';
import { useCallback } from 'react';
import type { TypeQuizQuestion } from '@page-builder/lib/types';
import type { Progress } from '../models/progress';
import { getGroups } from './helpers';

type QuestionPathname = {
  quizQuestions: TypeQuizQuestion[];
  pathName: string;
};

export const getQuizQuestionSlug = (quizQuestion: TypeQuizQuestion) =>
  quizQuestion?.fields?.slug || '';
export const getQuizQuestionFromUrlPathname = ({
  quizQuestions,
  pathName,
}: QuestionPathname) =>
  quizQuestions.find(currentQuizQuestion => {
    return getQuizQuestionSlug(currentQuizQuestion) === pathName.split('?')[0];
  }) || quizQuestions[0];
const getQuizQuestionIndexFromUrlPathname = ({
  quizQuestions,
  pathName,
}: QuestionPathname) =>
  quizQuestions.findIndex(
    currentQuizQuestion =>
      getQuizQuestionSlug(currentQuizQuestion) === pathName.split('?')[0],
  );

export const useQuestions = (
  quizQuestions: TypeQuizQuestion[],
  resultPageUrl: string,
  progress: Progress,
) => {
  const { asPath: pathName } = useRouter();
  const getCurrentQuestionIndex = useCallback(() => {
    const index = getQuizQuestionIndexFromUrlPathname({ quizQuestions, pathName });
    const updatedIndex = (index === -1 ? 0 : index) || 0;

    return updatedIndex;
  }, [pathName, quizQuestions]);

  const getCurrentQuestion = useCallback(
    () => getQuizQuestionFromUrlPathname({ quizQuestions, pathName }),
    [pathName, quizQuestions],
  );

  const getCurrentQuestionProgress = useCallback(() => progress[pathName] || {}, [
    pathName,
    progress,
  ]);

  const hasStartedQuiz = useCallback(() => {
    const firstQuestion = quizQuestions[0] || {};
    const firstQuestionSlug = getQuizQuestionSlug(firstQuestion);
    const hasFirstQuestionProgress = !!progress[firstQuestionSlug];

    return firstQuestionSlug === pathName || hasFirstQuestionProgress;
  }, [pathName, progress, quizQuestions]);

  const isQuestionPath = useCallback(
    () =>
      quizQuestions.some(
        currentQuizQuestion => getQuizQuestionSlug(currentQuizQuestion) === pathName,
      ),
    [pathName, quizQuestions],
  );

  const canGoToNextQuestion = useCallback(() => {
    const currentQuestionIndex = getCurrentQuestionIndex();
    const previousAndCurrentQuizQuestions = quizQuestions?.slice(
      0,
      currentQuestionIndex + 1,
    );
    const hasAnswersForAllCurrentAndPreviousQuizQuestions = previousAndCurrentQuizQuestions?.every(
      quizQuestion => {
        // if a question that requires group selection
        if (quizQuestion.fields.getsGroupsFrom) {
          // get previous answers and groups from initial question
          const previousAnswers =
            progress[getQuizQuestionSlug(quizQuestion.fields.getsGroupsFrom)];
          const previousGroups = Array.from(getGroups(previousAnswers));

          // gets current answers and groups
          const currentAnswers = progress[getQuizQuestionSlug(quizQuestion)] || [];
          const currentGroups = currentAnswers.map(answer => answer.split(':')[0]);

          // every previous group is in current groups
          return previousGroups.every((group: string) => currentGroups.includes(group));
        } else {
          return progress[getQuizQuestionSlug(quizQuestion)]?.length > 0;
        }
      },
    );

    return hasAnswersForAllCurrentAndPreviousQuizQuestions;
  }, [getCurrentQuestionIndex, progress, quizQuestions]);

  const canGoToPreviousQuestion = useCallback(() => {
    const currentQuestionIndex = getCurrentQuestionIndex();
    const previousQuizQuestions = quizQuestions?.slice(0, currentQuestionIndex);
    const hasPreviousQuizQuestions = previousQuizQuestions?.length > 0;
    const hasAnswersForAllPreviousQuizQuestions = previousQuizQuestions?.every(
      currentPreviousQuizQuestion =>
        progress[getQuizQuestionSlug(currentPreviousQuizQuestion)],
    );

    return (
      hasStartedQuiz() &&
      hasPreviousQuizQuestions &&
      hasAnswersForAllPreviousQuizQuestions
    );
  }, [getCurrentQuestionIndex, hasStartedQuiz, progress, quizQuestions]);

  const shouldStartQuizOver = useCallback(
    () => getCurrentQuestionIndex() > 0 && isQuestionPath() && !canGoToPreviousQuestion(),
    [canGoToPreviousQuestion, getCurrentQuestionIndex, isQuestionPath],
  );

  const hasCompletedQuiz = useCallback(
    () =>
      quizQuestions.every(
        currentQuizQuestion => progress[getQuizQuestionSlug(currentQuizQuestion)],
      ),
    [progress, quizQuestions],
  );
  const isResultsPage = useCallback(() => pathName.split('?')[0] === resultPageUrl, [
    pathName,
    resultPageUrl,
  ]);

  return {
    canGoToPreviousQuestion,
    canGoToNextQuestion,
    getCurrentQuestion,
    getCurrentQuestionProgress,
    getQuizQuestionSlug,
    hasCompletedQuiz,
    hasStartedQuiz,
    isQuestionPath,
    shouldStartQuizOver,
    isResultsPage,
    getCurrentQuestionIndex,
  };
};
