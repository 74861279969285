import { Container, Label, grey, spacing, white } from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { media } from '@peloton/styles';
import { nameToModuleId } from '@page-builder/utils/helpers';
import type { LinkListItem } from './ScrollableLinkList';
import { ScrollableLinkList } from './ScrollableLinkList';

const ClassesGridOverrideStyles = createGlobalStyle`
  .link-list-carousel + #classes-grid {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
`;

/*
 1. Since the prev/next buttons will not ne focusable and do not provide any useful
    features to screen reader users, let’s mask them from assistive technologies. 
 */
export const LinkListCarousel: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ name, items, text }) => {
  const moduleId = nameToModuleId(name);
  const navLabel = text?.fields?.eyebrow;
  const navLabelId = `${moduleId}-label`;

  const links = items
    .map(item => {
      const link = item?.fields?.ctas?.[0];
      return {
        id: item.sys.id,
        text: link?.fields?.text,
        href: link?.fields?.link?.fields?.url,
      };
    })
    .filter<LinkListItem>((item): item is LinkListItem => !!item.text && !!item.href);

  return (
    <>
      <ClassesGridOverrideStyles />
      <Container
        id={moduleId}
        className="link-list-carousel"
        data-test-id="link-list-carousel"
        verticalPadding={{
          mobile: spacing[24],
          tablet: spacing[32],
        }}
        horizontalPadding={{ mobile: 0, tablet: spacing[32], desktop: spacing[104] }}
      >
        <StyledNav aria-labelledby={navLabelId}>
          {/* Label */}
          {navLabel && (
            <StyledNavLabel id={navLabelId} size="large">
              {navLabel}
            </StyledNavLabel>
          )}

          {/* List & controls */}
          <ScrollableLinkList items={links} />
        </StyledNav>
      </Container>
    </>
  );
};

const StyledNav = styled.nav`
  display: flex;
  max-width: ${1224 / 16}rem;
  margin: 0 auto;
  padding-left: ${spacing[32]};
  position: relative;
  color: ${white};

  ${media.tabletXLarge`
    padding-left: 0;
  `}
`;

const StyledNavLabel = styled(Label)`
  padding: ${spacing[8]} ${spacing[16]} ${spacing[8]} 0;
  flex-shrink: 0;
  padding-right: ${spacing[16]};
  border-right: 1px solid ${grey[70]};

  ${media.tabletXLarge`
    margin-right: ${spacing[16]};
  `}
`;
