import { spacing, black, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { defaultTransition, media } from '@peloton/styles';
import type { DifficultyLabels } from '../Modal/ClassDifficultyRate';
import { useMicroDisciplineName } from '../utils/useMicroDisciplineName';
import ClassCardDescription from './ClassCardDescription';
import ClassCardImage from './ClassCardImage';

type Props = {
  item: any;
  difficultyLabels: DifficultyLabels;
  isAnimated?: boolean;
  animationDelay?: number;
};

const ClassCard: React.FC<React.PropsWithChildren<Props>> = ({
  item,
  difficultyLabels,
  isAnimated = false,
  animationDelay = 0,
}) => {
  const {
    title,
    imageUrl,
    fitnessDisciplineDisplayName,
    difficultyLevel,
    instructorName,
  } = item;

  const fitnessDisciplineTranslated = useMicroDisciplineName(
    fitnessDisciplineDisplayName,
  );

  return (
    <CtaWrapper isAnimated={isAnimated} animationDelay={animationDelay}>
      <ClassCardImage
        image={toCloudinarySrc({ src: imageUrl, width: 392, devicePixelRatio: 2 })}
      />
      <ClassCardDescription
        difficultyRate={difficultyLevel}
        discipline={fitnessDisciplineTranslated}
        instructor={instructorName}
        title={title}
        difficultyLabels={difficultyLabels}
      />
      <link
        rel="preload"
        as="image"
        href={toCloudinarySrc({ src: imageUrl, width: 392 })}
      />
    </CtaWrapper>
  );
};

export default ClassCard;

const CtaWrapper = styled.div<{ isAnimated: boolean; animationDelay: number }>`
  cursor: pointer;
  background-color: ${grey[80]};
  border-radius: ${spacing[4]};
  display: flex;
  flex-direction: column;
  height: 100%;
  ${defaultTransition('box-shadow')}
  max-width: calc(100vw - ${spacing[32]});
  min-height: auto;

  ${media.tablet`
    max-width: calc(100vw - ${spacing[64]});
  `}

  ${({ isAnimated, animationDelay }) =>
    isAnimated
      ? `
      opacity: 0;
      animation: .4s ${animationDelay}s forwards class-cards-slide-in ease-out;
    `
      : ''}

  &:hover {
    box-shadow: 0 0 15px 6px ${rgba(black, 0.3)};
  }

  @keyframes class-cards-slide-in {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
