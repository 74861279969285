import { BreakpointWidths, Icon, Label, grey, white } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { usePreviousRouteContext } from '@peloton/next/components/PreviousRouteProvider';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { media } from '@peloton/styles';
import {
  createDisciplinePageHref,
  isDisciplineReferrer,
} from '@ecomm/classes/utils/helpers';
import { fadeIn } from '@ecomm/styles';
import type { TypeComponentCtaFields } from '@page-builder/lib/types';
import { BackLink } from './BackLink';

type Props = {
  backToDisciplineLink?: {
    fields?: TypeComponentCtaFields;
  };
  defaultBackLink?: {
    fields?: TypeComponentCtaFields;
  };
};

const BackToDisciplineLink: React.FC<React.PropsWithChildren<Props>> = ({
  backToDisciplineLink,
  defaultBackLink,
}) => {
  const router = useRouter();
  const previousRoute = usePreviousRouteContext();
  const disciplineHref = createDisciplinePageHref(router.asPath, previousRoute);
  const [isDefaultLink, setIsDefaultLink] = useState(false);
  const defaultLink = (
    <BackLink
      href="/classes"
      data-test-id="backLink"
      aria-label={defaultBackLink?.fields?.text}
    >
      <Container>
        <Icon name="chevron" primaryColor={white} />
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: <Label size="medium">{defaultBackLink?.fields?.text}</Label>,
            desktopLarge: <Label size="large">{defaultBackLink?.fields?.text}</Label>,
          }}
        />
      </Container>
    </BackLink>
  );
  const disciplineLink = (
    <BackLink
      href={disciplineHref}
      data-test-id="backToDisciplineLink"
      aria-label={backToDisciplineLink?.fields?.text}
    >
      <Container>
        <Icon name="chevron" primaryColor={white} />
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: <Label size="medium">{backToDisciplineLink?.fields?.text}</Label>,
            desktopLarge: (
              <Label size="large">{backToDisciplineLink?.fields?.text}</Label>
            ),
          }}
        />
      </Container>
    </BackLink>
  );

  useEffect(() => {
    if (!isDisciplineReferrer(disciplineHref)) {
      setIsDefaultLink(true);
    }
  }, []);

  if (backToDisciplineLink || defaultBackLink) {
    return (
      <StyledDiv>
        <AnimationOutWrapper animate={isDefaultLink}>
          {disciplineLink}
        </AnimationOutWrapper>
        <AnimationInWrapper animate={isDefaultLink}>{defaultLink}</AnimationInWrapper>
      </StyledDiv>
    );
  }

  return null;
};

export const fadeOut = keyframes`
  from {opacity: 1}
  to {opacity: 0; pointer-events: none;}
`;

const AnimationOutWrapper = styled.div<{ animate: boolean }>`
  will-change: opacity;
  animation: ${props =>
    props.animate &&
    css`
      ${fadeOut} 0.3s ease-in forwards;
    `};
`;

const AnimationInWrapper = styled.div<{ animate: boolean }>`
  position: absolute;
  will-change: opacity;
  opacity: 0;
  animation: ${props =>
    props.animate &&
    css`
      ${fadeIn} 0.6s ease-in 1s forwards;
    `};
`;

const StyledDiv = styled.div`
  display: flex;
  background-color: ${grey[80]};
  width: 100%;
  max-width: 88rem;
  margin: 0 auto;
  position: relative;

  opacity: 0;
  animation-delay: 1.5s;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: ${fadeIn};
  animation-timing-function: ease-in;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    background-color: transparent;
    position: relative;
    z-index: 1;
    padding: 11px 13px 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  color: white;
  height: 48px;

  > svg {
    margin-right: 12px;
    margin-left: 16px;

    ${media.tablet`
      margin-left: 19px;
    `}
  }
`;

export default BackToDisciplineLink;
