import React from 'react';
import useCartOtdContent from '@ecomm/cart/summary/OtdEstimate/useCartOtdContent';
import * as Types from '@ecomm/graphql/types.generated';
import MessageWhenNotEmpty from '@ecomm/otd/MessageWhenNotEmpty';

const PreorderEstimate: React.FC<React.PropsWithChildren<unknown>> = () => {
  // 'key' and 'cfuType' will be dynamic in a future preorder iteration
  // When otd lookup is supported, will swap in DynamicOtdEstimate component,
  // which renders MessageWhenNotEmpty component
  const cfuType = Types.EquipmentType.Bike;
  const key = 'PREORDER';
  const messaging = useCartOtdContent(key);

  return (
    <MessageWhenNotEmpty content={messaging} countryEstimate={''} cfuType={cfuType} />
  );
};

export default PreorderEstimate;
