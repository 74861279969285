import {
  CLASS_TYPE_SLUG_RULE,
  DISCIPLINE_SLUG_RULE,
  DURATION_SLUG_RULE,
} from '../models/constants';

type ListingPageSegments = {
  isDiscipline: boolean;
  isFiltered: boolean;
  disciplineOrLanding: string;
  duration: string | null;
  classType: string | null;
};

const checkIsDiscipline = (segment?: string): boolean => {
  return !!segment && new RegExp(`^${DISCIPLINE_SLUG_RULE}$`).test(segment);
};

const checkIsValidDuration = (segment?: string): boolean => {
  return !!segment && new RegExp(`^${DURATION_SLUG_RULE}$`).test(segment);
};

const checkIsValidClassType = (segment?: string): boolean => {
  return !!segment && new RegExp(`^${CLASS_TYPE_SLUG_RULE}$`).test(segment);
};

export const getClassListingPageSegments = (path: string): ListingPageSegments => {
  const normalizedSlug = path
    .split('?')[0]
    .split('#')[0]
    ?.replace(/^\//, '')
    ?.replace(/classes\/?/, '');

  const [
    disciplineOrLanding,
    durationOrClassTypeSegment,
    classTypeSegment,
  ] = normalizedSlug.split('/');

  const duration = checkIsValidDuration(durationOrClassTypeSegment)
    ? durationOrClassTypeSegment
    : null;
  const classType = checkIsValidClassType(classTypeSegment)
    ? classTypeSegment
    : !duration && checkIsValidClassType(durationOrClassTypeSegment)
    ? durationOrClassTypeSegment
    : null;

  return {
    isDiscipline: checkIsDiscipline(disciplineOrLanding),
    isFiltered: !!(duration || classType),
    disciplineOrLanding,
    duration,
    classType,
  };
};
