import type { Entry } from 'contentful';
import type { ReactNode } from 'react';
import React from 'react';
import useBuilderEntry from '@content/client/hooks/useBuilderEntry';
import NextBlock from './Block/NextBlock';

type Props = {
  id: string;
  fallback?: ReactNode;
  index?: number;
};

const AsyncBlock: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  fallback,
  index,
}) => {
  const { data } = useBuilderEntry<Entry<unknown>>(id);

  if (!data) {
    return fallback ? <>{fallback}</> : null;
  }

  return <NextBlock block={data} index={index} />;
};

export default AsyncBlock;
