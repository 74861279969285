import { Eyebrow, Flex, Headline, Support, spacing } from '@pelotoncycle/design-system';
import type {
  TypeComponentGenericListFields,
  TypeComponentGenericText,
  TypeComponentGenericTextWithMedia,
} from '@pelotoncycle/page-builder';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { ThemeProvider, useTheme } from '@page-builder/themes/ThemeProvider';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { ContentCards } from './ContentCards';

const ContentCardsContainer: React.FC<{
  text?: TypeComponentGenericText;
  items: TypeComponentGenericTextWithMedia[];
  theme: string;
}> = ({ text, items, theme = 'Grey 80' }) => {
  const { textColor, backgroundColor, headlineColor } = useTheme();
  const { eyebrow, headline, support, label: modalTitle } = getTextFieldsWithRequiredKeys(
    ['headline'],
    text,
  );

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      data-test-id="content-cards-container"
      verticalPadding={{
        mobile: spacing[48],
        tablet: spacing[80],
      }}
    >
      <Flex
        flexDirection="column"
        maxWidth={{ desktop: '1224px' }}
        gap={{ mobile: spacing[16], desktop: spacing[24] }}
        style={{ justifySelf: 'center', width: '100%' }}
      >
        <Flex flexDirection="column" gap={spacing[8]}>
          {eyebrow && (
            <Eyebrow size="small" textColor={headlineColor}>
              {eyebrow}
            </Eyebrow>
          )}
          <Headline size="small" textColor={headlineColor}>
            {headline}
          </Headline>
        </Flex>
        <ContentCards cards={items} modalTitle={modalTitle} />
        {support && (
          <Support textColor={textColor}>
            <Markdown content={support} />
          </Support>
        )}
      </Flex>
    </OuterContainer>
  );
};

export const CardsWithTheme: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ text, items, theme = 'Grey 80' }) => {
  return (
    <ThemeProvider theme={theme}>
      <ContentCardsContainer text={text} items={items} theme={theme} />
    </ThemeProvider>
  );
};
