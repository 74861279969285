/* Helpers related to packages/@page-builder/modules/ClassesGrid/Modal/ClassDetailsPlaylist.tsx */
export const MAX_COLLAPSED_SONG_ROWS = 3;
export const MAX_MULTI_COL_COLLAPSED_SONG_ROWS = 2;
export const SONG_ROW_HEIGHT = 48;
const SONG_ROW_GAP = 24;

export const getMaxRows = (numColumns: number): number => {
  return numColumns > 1 ? MAX_MULTI_COL_COLLAPSED_SONG_ROWS : MAX_COLLAPSED_SONG_ROWS;
};

export const getVisibleRowsCount = (
  canShowMoreSongs = false,
  numSongs = 0,
  numColumns = 1,
): number => {
  if (numSongs < 1) {
    return 0;
  }

  const maxRows = getMaxRows(numColumns);

  if (!canShowMoreSongs) {
    return maxRows;
  }
  return numColumns > 1 ? Math.ceil(numSongs / maxRows) : numSongs;
};

export const getHeight = (numRows: number): number => {
  if (numRows < 1) {
    return 0;
  }
  return numRows * SONG_ROW_HEIGHT + (numRows - 1) * SONG_ROW_GAP;
};
