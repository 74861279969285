import { grey, spacing, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled from 'styled-components';
import { TRANSITION_DURATION } from './constants';

export const HighlightReelCta = styled.span<{
  active: boolean;
}>`
  display: flex;
  height: ${spacing[40]};
  justify-content: center;
  align-items: center;
  padding: ${spacing[8]} ${spacing[16]};
  border-radius: ${spacing[8]};
  line-height: 0;
  background: ${rgba(grey[70], 0.5)};
  flex: 1;
  text-align: center;
  transition: ${TRANSITION_DURATION} border-color;
  backdrop-filter: blur(12px);
  border: 1.5px solid ${props => (props.active ? white : 'transparent')};
`;
