import { grey, red, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import Markdown from '@page-builder/utils/Markdown';
import type { AlignmentProps } from './ProductCard';

type LegalBlockProps = {
  alignment: AlignmentProps;
  disclaimer?: string | undefined;
};

type ConfigProps = {
  alignment: AlignmentProps;
};

const Legal: React.FC<React.PropsWithChildren<LegalBlockProps>> = ({
  alignment,
  disclaimer,
}) => {
  if (!disclaimer) return null;
  return (
    <StyledContainer alignment={alignment}>
      <Support as="p" size="small" textColor={grey[70]}>
        <Markdown
          content={disclaimer}
          markdown={{
            renderers: {
              emphasis: props => <span style={{ color: red[50] }} {...props} />,
            },
          }}
        />
      </Support>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<ConfigProps>`
  grid-column: 1 / -1;
  text-align: center;
  padding-inline: ${spacing[16]};

  ${media.tabletXLarge<ConfigProps>`
    padding-inline: unset;
  `};
`;

export default Legal;
