import {
  spacing,
  Icon,
  grey,
  Tooltip,
  Eyebrow,
  Label,
  Container,
} from '@pelotoncycle/design-system';
import React from 'react';
import Markdown from '@peloton/copy/Markdown';

export const OverviewTooltip: React.FC<React.PropsWithChildren<{ tooltip: string }>> = ({
  tooltip,
}) => {
  return (
    <Container
      display={{ mobile: 'none', desktop: 'contents' }}
      horizontalMargin={spacing[8]}
    >
      <Tooltip
        hideDelay={250}
        customTrigger={() => <Icon primaryColor={grey[70]} name="tooltip" height={16} />}
        style={{ marginLeft: spacing[8] }}
        thin
      >
        <Container padding={spacing[16]} textAlign="left">
          <Markdown
            content={tooltip}
            markdown={{
              renderers: {
                heading: ({ node, ...props }) => {
                  return (
                    <Container verticalMargin={`0 ${spacing[16]}`}>
                      <Eyebrow size="small" textColor={grey[90]}>
                        {props.children}
                      </Eyebrow>
                    </Container>
                  );
                },
                paragraph: ({ node, ...props }) => {
                  return (
                    <Label size="large" textColor={grey[90]} style={{ fontWeight: 300 }}>
                      {props.children}
                    </Label>
                  );
                },
              },
            }}
          />
        </Container>
      </Tooltip>
    </Container>
  );
};
