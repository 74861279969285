import {
  Support,
  grey,
  Eyebrow,
  Container,
  Flex,
  Icon,
  FlexChild,
  spacing,
  Headline,
} from '@pelotoncycle/design-system';
import type { PropsWithChildren } from 'react';
import React, { useMemo, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { I18N_LOCALE_TOGGLE } from '@peloton/app-config';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { Link } from '@peloton/next/components/Link';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { BreakpointWidth, media } from '@peloton/styles';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import {
  getCtaFields,
  getTextFieldsWithRequiredKeys,
  hasCtaFields,
} from '@page-builder/utils/helpers/cms';
import { ctaLinkToUrlRequired, ctaExtractData } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';
import InfiniteScrollCarousel from '../InfiniteScrollCarousel';
import type { ResponsiveCellDimensions } from '../InfiniteScrollCarousel/InfiniteScrollCarousel';
import type { Props as CardProps } from './Card';
import Card from './Card';

type WrapperProps = {
  ariaLabelText: string;
  currentSlideText: string;
};

const cellSizes: Required<ResponsiveCellDimensions> = {
  mobile: {
    width: 288 + 16,
    height: 192,
    spacing: 0,
  },
  tablet: {
    width: 288 + 24,
    height: 192,
    spacing: 24,
  },
  desktop: {
    width: 288 + 24,
    height: 192,
    spacing: 24,
  },
};

const ClassesCarousel: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text, ctas, name, theme = 'Grey 30' }) => {
  const { textColor, backgroundColor, headlineColor } = themes[theme];
  const { trackEvent } = useTracking();
  const { trackLinkClick } = useTrackLinkClick();
  const moduleId = nameToModuleId(name);
  const { support, eyebrow, headline } = getTextFieldsWithRequiredKeys(['eyebrow'], text);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const linkCards = useMemo(() => getCards(items), [items]);
  const cards = useMemo(
    () => [
      ...linkCards.map((card, index: number) => {
        return (
          <span key={`link_${card.title}`} data-test-id={'md-card'}>
            <Card
              {...card}
              LinkRenderer={getLinkRenderer({
                index,
                onClick: (href: string) =>
                  trackLinkClick({
                    href: primaryCta?.url || '',
                    parent: 'Classes Carousel V2',
                    additionalProps: {
                      linkTo: href,
                      linkName: primaryCta?.text,
                      unitName: primaryCta?.name,
                      parentType: 'Component: Generic List',
                    },
                  }),
              })}
              ImageContainer={getLinkImageContainer({
                index,
                href: card.url.includes('/classes') ? card.url : `/classes${card.url}`,
                onClick: () =>
                  trackEvent({
                    event: 'Clicked Discipline Icon',
                    properties: {
                      href: primaryCta?.url || '',
                      parent: 'Classes Carousel V2',
                      linkTo: card.url.includes('/classes')
                        ? card.url
                        : `/classes${card.url}`,
                      linkName: primaryCta?.text,
                      unitName: primaryCta?.name,
                      parentType: 'Component: Generic List',
                    },
                  }),
              })}
              textColor={textColor}
              headlineColor={headlineColor}
            >
              {card.icon && (
                <StyledIcon name={card.icon} height={40} primaryColor="currentColor" />
              )}
            </Card>
          </span>
        );
      }),
    ],
    [linkCards],
  );

  return (
    <Container
      verticalPadding={{ mobile: spacing[48], desktop: spacing[64] }}
      backgroundColor={backgroundColor}
      id={moduleId}
      data-test-id="classTeasersContainer"
      style={{ overflow: 'auto' }}
    >
      <Flex
        aria-live="polite"
        maxWidth="1220px"
        flexDirection="column"
        horizontalPadding={{
          mobile: spacing[24],
          tablet: spacing[64],
          desktop: spacing[40],
        }}
        gap={spacing[32]}
        centered
        textAlign="center"
        style={{ marginBottom: `${spacing[32]}`, boxSizing: 'content-box' }}
      >
        <Eyebrow is="p" size="large" textColor={headlineColor}>
          {eyebrow}
        </Eyebrow>
        {headline && (
          <Headline
            size="small"
            textColor={headlineColor}
            style={{ maxWidth: '600px', alignSelf: 'center' }}
          >
            {headline}
          </Headline>
        )}
      </Flex>
      <CarouselWrapper
        ariaLabelText={'label'}
        currentSlideText={support ?? eyebrow}
        children={cards}
      />
      <Flex
        aria-live="polite"
        maxWidth="1220px"
        flexDirection="column"
        horizontalPadding={{
          mobile: spacing[24],
          tablet: spacing[64],
          desktop: spacing[40],
        }}
        gap={spacing[32]}
        centered
        alignItems="center"
        style={{ boxSizing: 'content-box' }}
      >
        {primaryCta && (
          <FlexChild>
            <LinkButton
              href={primaryCta.url}
              variant={primaryCta.variant}
              color={primaryCta.color}
              text={primaryCta.text}
              size="small"
              width="adjustable"
              onClick={() =>
                trackLinkClick({
                  href: primaryCta.url || '',
                  parent: 'Classes Carousel V2',
                  additionalProps: {
                    linkTo: primaryCta.url,
                    linkName: primaryCta.text,
                    unitName: primaryCta.name,
                    parentType: 'Component: Generic List',
                  },
                })
              }
            />
          </FlexChild>
        )}

        <Support size="small" textColor={textColor}>
          {support && <Markdown content={support} />}
        </Support>
      </Flex>
    </Container>
  );
};

type ItemCard<T> = {
  contentId: string;
} & CardProps &
  T;

type LinkCard = ItemCard<{ url: string; icon?: TypeComponentCtaFields['ctaIcon'] }>;

function getCards(items: TypeComponentGenericListFields['items']): LinkCard[] {
  return items.reduce((linkCards: LinkCard[], item) => {
    const { body: caption, label: newLabel } = getTextFieldsWithRequiredKeys(
      ['body'],
      item.fields.text,
    );
    const image = toImageProps(item.fields.media);

    if (!image) {
      return linkCards;
    }

    if (hasCtaFields('link', item.fields.ctas)) {
      const {
        primaryCta: { text: title },
      } = getCtaFields('link', item.fields.ctas);
      const url = ctaLinkToUrlRequired(item.fields.ctas?.[0]);
      const icon = item.fields.ctas?.[0].fields.ctaIcon;
      const contentId = item.fields.name;

      linkCards.push({ contentId, caption, newLabel, image, title, url, icon });
    }

    return linkCards;
  }, []);
}

const getLinkImageContainer = ({
  index,
  href,
  onClick,
}: {
  index: number;
  href: string;
  onClick: VoidFunction;
  // eslint-disable-next-line react/display-name
}): React.FC<React.PropsWithChildren<unknown>> => ({ children }) => {
  return (
    <Link href={href} hasUnderline={false}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={onClick} data-test-id={`linkImage_${index}`}>
        {children}
      </div>
    </Link>
  );
};

const getLinkRenderer = ({
  index,
  onClick,
}: {
  index: number;
  onClick: (href: string) => void;
}): // eslint-disable-next-line react/display-name
React.FC<React.PropsWithChildren<PropsWithChildren<{ href: string }>>> => ({
  href,
  children,
}) => {
  return (
    // This is ok to leave as anchor for now because it is an external link in contentful
    <a
      href={addCurrentLocaleToUrl(href, I18N_LOCALE_TOGGLE)}
      onClick={() => onClick(href)}
      data-test-id={`link_${index}`}
    >
      {children}
    </a>
  );
};

export default ClassesCarousel;

const CarouselWrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  ariaLabelText,
  currentSlideText,
}) => {
  const [isReady, setIsReady] = useState(false);

  return (
    <StyledCarouselContainer isReady={isReady}>
      <StyledInfiniteScrollCarousel
        swipe
        arrowPadding={60}
        cellSizes={cellSizes}
        name="fitnessDisciplines"
        dots
        centerMode={true}
        speed={300}
        responsive={[
          {
            breakpoint: BreakpointWidth.desktopLarge,
            settings: {
              centerMode: true,
            },
          },
          {
            breakpoint: BreakpointWidth.tabletXLarge,
            settings: {
              arrows: false,
              centerMode: true,
            },
          },
        ]}
        ariaLabelText={ariaLabelText}
        currentSlideText={currentSlideText}
        onInit={() => setIsReady(true)}
      >
        {children}
      </StyledInfiniteScrollCarousel>
    </StyledCarouselContainer>
  );
};

const StyledCarouselContainer = styled.div<{ isReady: boolean }>`
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
  ${({ isReady }) =>
    isReady
      ? `
        opacity: 1;
        transform: translateX(0);
      `
      : `
        opacity: 0;
        transform: translateX(10%);
      `}
`;

const StyledInfiniteScrollCarousel = styled(InfiniteScrollCarousel)`
  cursor: ew-resize;
  position: relative;
  overflow: hidden;

  .slick-list {
    ${media.tabletXLarge`
      overflow: hidden;
      padding: 0 0 !important;
    `}

    ${media.tabletXLarge`
      padding: unset !important;
    `}
  }

  .slick-track {
    &::before,
    &::after {
      content: unset;
    }
  }

  .slick-slide {
    padding-right: ${spacing[16]};
    width: 288px;
    box-sizing: content-box;

    ${media.tabletXLarge`
      padding-right: ${spacing[24]};
    `}
    ${media.desktopLarge`
      padding-right: ${spacing[24]};
    `}
  }

  .slick-prev {
    left: ${spacing[16]};
    top: 76px;
  }
  .slick-next {
    right: ${spacing[16]};
    top: 76px;
  }

  .slick-slide.slick-active button {
    background-color: transparent;
  }

  .slick-list {
    overflow: unset;
    margin-left: ${spacing[24]};
  }

  .slick-bottom {
    padding-inline: ${spacing[24]};

    ${media.tabletXLarge`
      padding-inline: ${spacing[64]};
    `}

    ${media.desktopLarge`
      padding-inline: ${spacing[40]};
    `}
    box-sizing: content-box;
    margin: unset;
    max-width: 1224px;
    width: unset;
    margin: 0 auto;
    padding-top: ${spacing[32]};
    padding-bottom: ${spacing[32]};
  }
`;

const StyledIcon = styled(Icon)`
  padding: ${spacing[8]};
  background-color: ${grey[40]};
  border-radius: 50%;
  overflow: visible;
  transition: all 0.3s ease-in-out 0s;
`;
