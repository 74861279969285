import React from 'react';
import styled from 'styled-components';
import { isPageTypeCheck } from '@ecomm/financing/affirm/isPageTypeCheck';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import { isBundleTypeCheck } from '@ecomm/shop/models/Bundle';
import { CitizensModalLink } from '../CheckEligibilityModule';
import { ModalLink } from '../PrequalifyNowModule';
import { ZipModalLink } from '../ZipWidgetModule';
import { AsyncModalTrigger } from './Views/AsyncModalTrigger';

enum Placeholders {
  AFFIRM_MODAL = 'affirmModal',
  CITIZEN_MODAL = 'citizensModal',
  ZIP_MODAL = 'zipModal',
  MODAL = 'modal',
}

export const PLACEHOLDER_IDS = Object.values(Placeholders);

/*
 Using `placeholderMemory` ensures React is provided with a persistent
 component reference when `ctaPlaceholder` is being called 
 (which would otherwise cause the components to unmount/remount 
 every time the parent re-renders).
 */
const placeholderMemory: Record<string, React.FC<React.PropsWithChildren<unknown>>> = {};

const ctaPlaceholder = (name: string): React.FC<React.PropsWithChildren<unknown>> => {
  if (!(name in placeholderMemory)) {
    placeholderMemory[name] = ({ children }) => (
      <PlaceholderWrapper name={name} children={children} />
    );
  }
  return placeholderMemory[name];
};

export default ctaPlaceholder;

const PlaceholderWrapper: React.FC<React.PropsWithChildren<{ name: string }>> = ({
  name,
  children,
}) => {
  const [bundleTypeString, pageTypeString] = name.split('.');

  const bundleType = isBundleTypeCheck(bundleTypeString) ? bundleTypeString : undefined;
  const pageType = isPageTypeCheck(pageTypeString) ? pageTypeString : undefined;
  const financing = useFinancingNext(bundleType!);

  if (name.includes(Placeholders.AFFIRM_MODAL)) {
    return (
      <StyledPlaceholderWrapper>
        <ModalLink name={name} bundleType={bundleType} pageType={pageType}>
          {children}
        </ModalLink>
      </StyledPlaceholderWrapper>
    );
  }

  if (name.includes(Placeholders.CITIZEN_MODAL)) {
    return (
      <StyledPlaceholderWrapper>
        <CitizensModalLink name={name} bundleType={bundleType} price={financing?.total} />
      </StyledPlaceholderWrapper>
    );
  }

  if (name.includes(Placeholders.ZIP_MODAL)) {
    return (
      <StyledPlaceholderWrapper>
        <ZipModalLink name={name} bundleType={bundleType}>
          {children}
        </ZipModalLink>
      </StyledPlaceholderWrapper>
    );
  }

  if (name.startsWith(Placeholders.MODAL)) {
    const parameters = name.split(':');
    const parametersLength = parameters.length;
    const maybeEntryId = parameters.pop();

    if (parametersLength >= 2 && maybeEntryId) {
      return <AsyncModalTrigger entryId={maybeEntryId}>{children}</AsyncModalTrigger>;
    }
  }

  return null;
};

const StyledPlaceholderWrapper = styled.span`
  position: relative !important; // Overriding DS Style

  *:not(svg, a) {
    position: relative;
  }
`;
