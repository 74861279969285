import { spacing, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import { zIndex } from '@page-builder/modules/Headband/constants';
import type { CheckoutCta } from '../CheckoutContext';

export type Props = {
  cta: CheckoutCta;
  handleCheckoutClick: () => void;
  shouldShowCTABand: boolean;
};

const StickyCTABand: React.FC<React.PropsWithChildren<Props>> = ({
  cta,
  handleCheckoutClick,
  shouldShowCTABand,
}) => {
  return (
    <CTABandWrapper
      aria-hidden={!shouldShowCTABand}
      data-test-id="cta-band-wrapper"
      backgroundColor="rgba(34, 37, 41, 0.9)"
      bottom={shouldShowCTABand ? '0' : '-100px'}
      padding={`${spacing[16]} ${spacing[24]} ${spacing[24]} ${spacing[24]}`}
      position="fixed"
      minWidth="100%"
      justifyContent="center"
      alignItems="center"
    >
      <LinkButton
        href={cta.link}
        to={cta.link}
        isDisabled={!cta.link}
        onClick={handleCheckoutClick}
        size="medium"
        width={{ mobile: 'adaptive', tablet: 'fixed' }}
      >
        {cta.text}
      </LinkButton>
    </CTABandWrapper>
  );
};

export default StickyCTABand;

const CTABandWrapper = styled(Flex)<{ bottom: string }>`
  backdrop-filter: blur(${spacing[12]});

  // For transition to be triggered, bottom and display must be defined in css
  bottom: ${props => props.bottom};
  z-index: ${zIndex.HEADBAND};

  transition: bottom 0.2s ease-in-out;

  a {
    width: 100%;
  }

  ${media.tabletLarge`
    a {
      width: unset;
    }
  `}
`;
