import React from 'react';
import styled from 'styled-components';
import type { BreakpointKeys } from '@peloton/styles/breakpoints';
import { media } from '@peloton/styles/breakpoints';

type Breakpoints = 'mobile' | BreakpointKeys;

type Props = {
  breakpoints: { mobile?: React.ReactElement<any> } & Partial<
    Record<Breakpoints, React.ReactElement<any>>
  >;
};

const IndexMap: readonly Breakpoints[] = [
  'mobile',
  'iPhone6',
  'smallScreen',
  'tablet',
  'tabletLarge',
  'tabletXLarge',
  'desktop',
  'desktopLarge',
  'desktopXLarge',
  'infinity',
] as const;

const BreakpointSwitchCSS: React.FC<React.PropsWithChildren<Props>> = ({
  breakpoints,
}) => {
  const nonNullBreakpointsArr = IndexMap.map(breakpoint => ({
    breakpoint,
    component: breakpoints[breakpoint],
  })).filter(({ component }) => Boolean(component));

  if (nonNullBreakpointsArr.length < 2) {
    console.warn(
      'BreakpointSwitchCSS should only be used with at least 2 components, ideally more',
    );

    if (nonNullBreakpointsArr.length === 1) {
      const { breakpoint, component } = nonNullBreakpointsArr[0];
      return <LastBreakpoint start={breakpoint}>{component}</LastBreakpoint>;
    }

    return <></>;
  }

  return (
    <>
      {nonNullBreakpointsArr.map(({ breakpoint, component }, index) => {
        if (index === 0) {
          return (
            <FirstBreakpoint
              key={breakpoint}
              // casting b/c this will never be 'mobile' since 'mobile' will always be index 0 if it exists
              end={nonNullBreakpointsArr[1].breakpoint as BreakpointKeys}
            >
              {component}
            </FirstBreakpoint>
          );
        }
        if (index === nonNullBreakpointsArr.length - 1) {
          return (
            <LastBreakpoint key={breakpoint} start={breakpoint}>
              {component}
            </LastBreakpoint>
          );
        }
        return (
          <MiddleBreakpoint
            key={breakpoint}
            // casting b/c this will never be 'mobile' since 'mobile' will always be index 0 if it exists
            start={breakpoint as BreakpointKeys}
            end={nonNullBreakpointsArr[index + 1].breakpoint as BreakpointKeys}
          >
            {component}
          </MiddleBreakpoint>
        );
      })}
    </>
  );
};

export default BreakpointSwitchCSS;

const FirstBreakpoint = styled.div<{ end: BreakpointKeys }>`
  display: contents;

  ${({ end }) =>
    media[end]`
      display: none;
  `}
`;

const MiddleBreakpoint = styled.div<{
  start: BreakpointKeys;
  end: BreakpointKeys;
}>`
  display: none;

  ${({ start }) =>
    media[start]`
      display: contents;
  `}

  ${({ end }) =>
    media[end]`
      display: none;
  `}
`;

const LastBreakpoint = styled.div<{ start: Breakpoints }>`
  display: none;

  ${({ start }) => {
    if (start === 'mobile') {
      return `display: contents;`;
    }

    return media[start]`
        display: contents;
      `;
  }}
`;
