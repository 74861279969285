import type React from 'react';
import { usePartnerPromo } from '@ecomm/cart-next/hooks/usePartnerPromo';
import { useReferralCodeFromUrl } from '@ecomm/cart-next/hooks/useReferralCode';

const ReferralCodes: React.FC<React.PropsWithChildren<unknown>> = () => {
  useReferralCodeFromUrl();
  usePartnerPromo();

  return null;
};

export default ReferralCodes;
