import {
  Container,
  grey,
  Headline,
  Icon,
  Label,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import { Link } from '@peloton/next/components/Link';
import { usePreviousRouteContext } from '@peloton/next/components/PreviousRouteProvider';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { getClassListingPageSegments } from '@ecomm/classes/utils/getClassListingPageSegments';
import { nameToModuleId } from '@page-builder/utils/helpers';
import { LinkListCarousel } from '../LinkListCarousel/LinkListCarousel';
import type {
  LinkListItem,
  UpdateHandler as LinkListUpdateHandler,
} from '../LinkListCarousel/ScrollableLinkList';
import { ScrollableLinkList } from '../LinkListCarousel/ScrollableLinkList';
import { CLASSES_FILTERS_ID } from './constants';
import { DesktopClassesFilters } from './DesktopClassesFilters';
import { toFilteredPageTitle } from './helpers/toFilteredPageTitle';
import { useClassFilters } from './hooks/useClassFilters';
import { useFilteredPageLabels } from './hooks/useFilteredPageLabels';
import { MobileClassesFilters } from './MobileClassesFilters';

export const CONTENTFUL_IGNORE_KEY = '_IGNORE_';

const ClassesGridOverrideStyles = createGlobalStyle`
    .classes-filters + #classes-grid {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  `;

/*
 1. Allows us temporarily to add filters without CLP pills on pages.
 */
export const ClassesFilters: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = props => {
  const { name, items } = props;
  const moduleId = nameToModuleId(name);
  const [shrinkFilterButton, setShrinkFilterButton] = useState(false);
  const classFiltersNavLabel = useMicroCopy('classFiltersNavLabel');
  const disciplineBackButtonLabel = useMicroCopy('disciplineBackButton');
  const router = useRouter();
  const locale = useLocale();
  const {
    disciplineOrLanding: disciplineSlug,
    isDiscipline,
    isFiltered,
  } = getClassListingPageSegments(router.asPath);

  const {
    isFeatureEnabled,
    filters,
    selectedFilters,
    setSelectedFilters,
    hasSelectedFilters,
    clearFilters,
    initialSelectedState,
    clearedState,
  } = useClassFilters();

  const filteredPageLabels = useFilteredPageLabels();
  const previousPath = usePreviousRouteContext();

  /* [1] */
  const filteredItems = items.filter(
    entry => entry.fields.name !== CONTENTFUL_IGNORE_KEY,
  );

  if (!isFeatureEnabled && filteredItems.length) {
    return <LinkListCarousel {...props} />;
  } else if (!isFeatureEnabled) {
    return null;
  }

  const isReferrerInternal =
    typeof document !== 'undefined' && document.referrer.includes('peloton');
  const backHref = isReferrerInternal ? document.referrer : previousPath;

  const links = filteredItems
    .map(item => {
      const link = item?.fields?.ctas?.[0];
      return {
        id: item.sys.id,
        text: link?.fields?.text,
        href: link?.fields?.link?.fields?.url,
      };
    })
    .filter<LinkListItem>((item): item is LinkListItem => !!item.text && !!item.href);

  const linkListStateSync: LinkListUpdateHandler = ({ hasScrollEnded, canSlideLeft }) => {
    if (hasScrollEnded) {
      setShrinkFilterButton(canSlideLeft);
    }
  };

  return (
    <div id={moduleId} className="classes-filters">
      <ClassesGridOverrideStyles />

      {/* Back link */}
      {isDiscipline && isFiltered && backHref && (
        <StyledBackLinkContainer>
          <Link href={backHref} hasUnderline={false}>
            <StyledLink data-test-id="classes-filters-back-link">
              <Container>
                <Icon name="chevron" primaryColor={white} />
                <StyledLinkLabel size="large">
                  {disciplineBackButtonLabel}
                </StyledLinkLabel>
              </Container>
            </StyledLink>
          </Link>
        </StyledBackLinkContainer>
      )}

      <Container
        id={CLASSES_FILTERS_ID}
        data-test-id="classes-filters"
        backgroundColor={grey[90]}
        verticalPadding={{
          mobile: spacing[24],
          tablet: spacing[32],
        }}
        horizontalPadding={{ mobile: 0, tablet: spacing[32], desktop: spacing[104] }}
      >
        {/* Page heading */}
        {isDiscipline && isFiltered && (
          <>
            <StyledHeadline
              size="extraSmall"
              is="h1"
              textColor={white}
              data-test-id="classes-filters-heading"
            >
              {toFilteredPageTitle({
                filteredPageLabels,
                locale,
              })}
            </StyledHeadline>
          </>
        )}

        {/* Filters & CLPs */}
        <StyledNav aria-label={classFiltersNavLabel}>
          {/* Mobile filters */}
          <MobileClassesFilters
            filters={filters}
            initialSelectedState={initialSelectedState}
            selectedFilters={selectedFilters}
            shrinkFilterButton={shrinkFilterButton}
            setSelectedFilters={setSelectedFilters}
            hasSelectedFilters={hasSelectedFilters}
            clearFilters={clearFilters}
            disciplineSlug={disciplineSlug}
            requiresSeparation={!!filteredItems.length}
          />

          {/* Desktop filters */}
          <DesktopClassesFilters
            filters={filters}
            initialSelectedState={initialSelectedState}
            hasSelectedFilters={hasSelectedFilters}
            clearedState={clearedState}
            disciplineSlug={disciplineSlug}
            selectedFilters={selectedFilters}
            requiresSeparation={!!filteredItems.length}
          />

          {/* CLP pills */}
          <ScrollableLinkList items={links} onUpdate={linkListStateSync} />
        </StyledNav>
      </Container>
    </div>
  );
};

const StyledBackLinkContainer = styled.div`
  max-width: 88rem;
  margin: 0 auto;
`;

const StyledHeadline = styled(Headline)`
  max-width: ${1224 / 16}rem;
  margin: 0 auto;
  padding-left: ${spacing[32]};
  padding-bottom: ${spacing[16]};

  ${media.tabletXLarge`
    padding-left: 0;
  `}
`;

const StyledNav = styled.nav`
  display: flex;
  max-width: ${1224 / 16}rem;
  margin: 0 auto;
  padding-left: ${spacing[16]};
  position: relative;
  color: ${white};

  ${media.tablet`
    padding-left: ${spacing[32]};
  `}

  ${media.tabletXLarge`
    padding-left: 0;
  `}
`;

const StyledLink = styled.span`
  display: inline-block;
  margin-top: ${spacing[16]};
  margin-left: ${spacing[32]};
  margin-bottom: ${spacing[8]};
  color: ${white};

  ${media.tablet`
    margin-top: ${spacing[24]};
    margin-left: ${spacing[32]};
    margin-bottom: 0;
  `}
`;

const StyledLinkLabel = styled(Label)`
  margin-left: ${spacing[8]};
`;
