import type { LinkButtonProps } from '@pelotoncycle/design-system';
import { black, grey, white, limitedEdition } from '@pelotoncycle/design-system';

type Theme = {
  backgroundColor: string;
  buttonColor?: LinkButtonProps['color'];
  textColor: string;
  headlineColor: string;
  iconColor: string;
  type: string;
};
type GenericThemes = { [name: string]: Theme };

const colorType = {
  dark: {
    type: 'dark',
    textColor: grey[40],
    headlineColor: white,
    iconColor: grey[70],
    buttonColor: 'light',
  },
  light: {
    type: 'light',
    textColor: grey[70],
    headlineColor: grey[90],
    iconColor: grey[60],
    buttonColor: 'dark',
  },
} as const;

export const themes: GenericThemes = {
  White: { backgroundColor: white, ...colorType['light'] },
  'Grey 10': { backgroundColor: grey[10], ...colorType['light'] },
  'Grey 30': { backgroundColor: grey[30], ...colorType['light'] },
  'Grey 80': { backgroundColor: grey[80], ...colorType['dark'] },
  'Grey 90': { backgroundColor: grey[90], ...colorType['dark'] },
  Black: { backgroundColor: black, ...colorType['dark'] },
  Lilac: { backgroundColor: limitedEdition.lilac, ...colorType['light'] },
  Lime: { backgroundColor: limitedEdition.lime, ...colorType['light'] },
  Rose: { backgroundColor: limitedEdition.rose, ...colorType['light'] },
  Blue: { backgroundColor: limitedEdition.blue, ...colorType['dark'] },
  MichiganBlue: { backgroundColor: limitedEdition.michiganBlue, ...colorType['dark'] },
};

export type Themes = keyof typeof themes;
