import { spacing, white, grey, Display, Badge, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { Class } from '@peloton/models/PelotonClass';
import { media } from '@peloton/styles';
import { capitalize } from '@peloton/text';
import { toUtcTime } from '@peloton/time/time';
import type { DifficultyLabels } from '../../ClassesGrid/Modal/ClassDifficultyRate';
import ClassDifficultyRate from '../../ClassesGrid/Modal/ClassDifficultyRate';
import type { ClassPageLabels } from '../utils/mapClassPageLabelsFromContentfulToProps';

type ClassTitleProps = {
  currentClass: Class;
  classPageLabels: ClassPageLabels;
};

type MetadataBadgeProps = {
  type?: string;
  value: string;
};

const MetadataBadge: React.FC<React.PropsWithChildren<MetadataBadgeProps>> = ({
  type,
  value,
  ...rest
}) => (
  <StyledBadge
    variant="horizontal"
    theme="grey"
    {...rest}
    aria-label={type ? `${type}: ${value}` : undefined}
  >
    {value}
  </StyledBadge>
);

const ClassTitle: React.FC<React.PropsWithChildren<ClassTitleProps>> = ({
  currentClass,
  classPageLabels,
}) => {
  const {
    title,
    instructorName,
    fitnessDisciplineDisplayName,
    airTime,
    originalAirTime,
    difficultyLevel,
  } = currentClass;

  const displayedAirTime = airTime || originalAirTime;
  const utcAirTime = displayedAirTime && toUtcTime(displayedAirTime);
  const airTimeLabel = classPageLabels.airTimeLabel || '';
  const airTimeLabelBeforeDate = airTimeLabel.split('{date}')[0] || '';
  const airTimeLabelAfterDate = airTimeLabel.split('{date}')[1] || '';

  const difficultyLabels: DifficultyLabels = {
    beginner: classPageLabels.beginnerLabel,
    intermediate: classPageLabels.intermediateLabel,
    advanced: classPageLabels.advancedLabel,
  };

  return (
    <StyledHeader>
      <StyledHeaderContent>
        {/* Difficulty level */}
        <ClassDifficultyRate
          difficultyRate={difficultyLevel}
          difficultyLabels={difficultyLabels}
        />

        {/* Title */}
        <StyledDisplay size="large" textColor={white}>
          {title}
        </StyledDisplay>

        {/* Air time */}
        {utcAirTime && (
          <Label is="p" size="large" textColor={white}>
            {airTimeLabelBeforeDate}
            <time dateTime={utcAirTime.format('YYYY-MM-DDTHH:mm:ss')}>
              {utcAirTime.format(classPageLabels.rideDateFormat)}
            </time>
            {airTimeLabelAfterDate}
          </Label>
        )}

        {/* Metadata */}
        <StyledMetadata textColor={white}>
          {/* Instructor */}
          <p>
            <MetadataBadge
              type={classPageLabels.instructorLabel}
              value={instructorName}
            />
          </p>

          {/* Discipline */}
          <p>
            <MetadataBadge
              type={classPageLabels.disciplineLabel}
              value={capitalize(fitnessDisciplineDisplayName)}
            />
          </p>
        </StyledMetadata>
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default ClassTitle;

const StyledHeader = styled.header`
  max-width: 808px;
  margin: 0 auto;
  padding: ${spacing[24]} ${spacing[16]};
  position: relative;
  background: ${grey[80]};
  ${media.tablet`
    padding: ${spacing[24]} ${spacing[32]};
  `}
  ${media.desktop`
    padding: ${spacing[32]} ${spacing[80]};
  `}
  ${media.desktopLarge`
    max-width: 88rem;
    padding: 0;
    background: transparent;
  `}
`;

const StyledHeaderContent = styled.div`
  max-width: 29rem;
  ${media.desktop`
    padding: 0 ${spacing[32]};
    text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  `}
  ${media.desktopLarge`
    margin: ${spacing[88]};
    padding: 0;
  `}
  ${media.desktopXLarge`
    margin: 6.5rem ${spacing[88]} 8.5rem;
  `}
`;

const StyledDisplay = styled(Display)`
  margin-top: ${spacing[8]};
  ${media.desktop`
    text-shadow: 0 0 10px rgba(0, 0, 0, .2);
  `}
`;

const StyledMetadata = styled.div<{ textColor: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[8]};
  margin: ${spacing[8]} 0 0 0;
  color: ${props => props.textColor};
  ${media.desktopLarge`
    margin: ${spacing[16]} 0 0 0;
  `}
`;

const StyledBadge = styled(Badge)`
  padding: ${spacing[4]} ${spacing[8]};
  line-height: 24px;
  background: rgba(255, 255, 255, 0.3);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
`;
