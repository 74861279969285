import { useEffect, useState } from 'react';

const usePanelContentVisibility = (isExpanded: boolean) => {
  // We have this additional state so that we can remove the component from the DOM only after the height transition
  // has finished. If we leave it in the DOM at all times, buttons and other controls within the components are
  // accessible by keyboard but not visible
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isExpanded && !isVisible) {
      setIsVisible(true);
    }
  }, [isExpanded, isVisible]);

  const onTransitionEnd = () => {
    if (!isExpanded) {
      setIsVisible(false);
    }
  };

  return {
    isVisible,
    onTransitionEnd,
  };
};

export default usePanelContentVisibility;
