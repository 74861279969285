import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize/models/locale';
import type { Class, FreeClass } from '@peloton/models/PelotonClass';
import { PageConfigTags, getPageConfigs } from '@content/client/lib/pageConfig';
import { getClient } from '@content/client/pageBuilderClient';
import type { Fetcher } from '@page-builder/hooks/useCustomPageData';
import type { TypePageConfig } from '@page-builder/lib/types';
import type { SelectedFilters } from '@page-builder/modules/ClassesFilters/hooks/useClassFilters';
import type { ClassFiltersData } from '../api/fetchFiltersData';
import { fetchFiltersData } from '../api/fetchFiltersData';
import { CLASS_LISTING_LIMIT } from '../models/constants';
import { fetchClassContent } from './fetchClassContent';
import { fetchFreeClassDataByDiscipline } from './fetchFreeClassDataByDiscipline';
import { findDisciplineForVariant } from './findDisciplineForVariant';
import { getClassListingPageSegments } from './getClassListingPageSegments';
import { getPagefromQuery } from './getPageFromQuery';
import { transformLocale } from './transformLocale';
export { CLASS_LISTING_LIMIT } from '../models/constants';

export type ListingData = {
  classes: Class[];
  freeClass?: FreeClass | null;
  totalClasses: number;
};
export type DisciplineListingData = ListingData & {
  filters: ClassFiltersData['filters'];
  selectedFilters: SelectedFilters;
  noIndex: boolean;
  validFilterCombinations: ClassFiltersData['validFilterCombinations'];
};

export const fetchDisciplineDataApi: Fetcher<ListingData> = async ({
  slug,
  locale: localeParam,
  query,
  preview,
}) => {
  const {
    isDiscipline,
    disciplineOrLanding,
    duration,
    classType,
  } = getClassListingPageSegments(slug);
  const page = getPagefromQuery(query);
  const locale: Locale = transformLocale(localeParam!);
  const overridingDiscipline = findDisciplineForVariant(disciplineOrLanding);

  if (isDiscipline || overridingDiscipline) {
    const {
      filters,
      validFilterCombinations,
      classSlugs,
      suggestedClassSlugs: _suggestedClassSlugs,
      noIndex,
    } = await fetchFiltersData({
      discipline: overridingDiscipline || disciplineOrLanding!,
      duration,
      classType,
    });

    const { classes, totalClasses, freeClass } = await fetchClassListingData({
      classSlugs,
      disciplineSlug: overridingDiscipline || disciplineOrLanding!,
      locale,
      preview,
      page,
    });

    const selectedFilters: SelectedFilters = {
      durationBuckets: duration || '',
      classTypes: classType || '',
    };

    return {
      filters,
      selectedFilters,
      validFilterCombinations,
      classes,
      freeClass,
      totalClasses,
      noIndex,
    };
  } else {
    const { classSlugs, isCLP } = await fetchPageConfigData({
      disciplineOrLandingSlug: disciplineOrLanding!,
      locale,
      preview,
    });

    const { classes, freeClass, totalClasses } = await fetchClassListingData({
      classSlugs,
      disciplineSlug: disciplineOrLanding!,
      locale: locale,
      page,
      preview,
      isCLP,
    });

    return { classes, freeClass, totalClasses };
  }
};

export const fetchClassesForPage = async (classFetchers: Promise<Class>[]) => {
  const fetchedClassData = await Promise.allSettled(classFetchers);
  const classData = fetchedClassData.reduce((acc, result) => {
    if (result.status === 'rejected') {
      console.error(result.reason);
      return acc;
    }

    return [...acc, result.value];
  }, []);

  return classData;
};

const fetchPageConfigData = async ({
  disciplineOrLandingSlug,
  locale,
  preview,
}: {
  disciplineOrLandingSlug: string;
  locale: Locale;
  preview: boolean;
}): Promise<{ classSlugs: string[]; isCLP: boolean }> => {
  const client = getClient(preview || ALLOW_PREVIEW_COPY);

  const classPageConfigName = `classes/${disciplineOrLandingSlug}`;
  const pageConfigs: TypePageConfig[] = await getPageConfigs(client, {
    slugs: [classPageConfigName],
    locale: locale,
  });
  const isCLP = Boolean(
    pageConfigs[0]?.metadata?.tags[0]?.sys?.id === PageConfigTags['CLASS_LANDING_PAGE'],
  );

  const classSlugsValue = pageConfigs[0]?.fields?.config?.classes;

  return {
    classSlugs: Array.isArray(classSlugsValue) ? classSlugsValue : [],
    isCLP,
  };
};

const fetchClassListingData = async ({
  classSlugs,
  page,
  locale,
  preview,
  disciplineSlug,
  isCLP,
}: {
  classSlugs: string[];
  page: number;
  locale: Locale;
  disciplineSlug: string;
  preview: boolean;
  isCLP?: boolean;
}): Promise<ListingData> => {
  const listingStart = CLASS_LISTING_LIMIT * (page - 1);
  const listingEnd = listingStart + CLASS_LISTING_LIMIT;
  const paginatedClassSlugs = classSlugs.slice(listingStart, listingEnd);
  const totalClasses = classSlugs.length;
  const classFetchers = paginatedClassSlugs.map(classSlug =>
    fetchClassContent(classSlug, locale, disciplineSlug, isCLP),
  );
  const classes = await fetchClassesForPage(classFetchers);
  const freeClass = await fetchFreeClassDataByDiscipline({
    locale,
    preview,
    discipline: disciplineSlug,
  });

  return {
    classes,
    freeClass,
    totalClasses,
  };
};
