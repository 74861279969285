import React from 'react';
import type { FC } from 'react';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import DesktopTabletHeroCarousel from './DesktopTabletHeroCarousel';
import MobileHeroCarousel from './MobileHeroCarousel';
import type { VideoCard as VideoCardProps } from './types';

const HeroCarousel: FC<{
  videoCards: VideoCardProps[];
}> = ({ videoCards }) => {
  return (
    <BreakpointSwitchCSS
      breakpoints={{
        mobile: <MobileHeroCarousel videoCards={videoCards} />,
        tabletXLarge: <DesktopTabletHeroCarousel videoCards={videoCards} />,
      }}
    />
  );
};

export default HeroCarousel;
