import { AccessoryBundleKeys, PRODUCT_REF } from '../constants';
import type { ProductCatalogType } from '../models/main';
import type { RawProductAttribute } from '../types.generated';

type ProductValue = Pick<RawProductAttribute, 'name' | 'value'>;

export const getProductIdsFromBundle = (bundle?: ProductCatalogType): string[] => {
  const attributes = bundle?.masterData.current?.masterVariant.attributesRaw;

  const bundleProductsValue = attributes?.find(
    ({ name }: RawProductAttribute) => name === AccessoryBundleKeys.PRODUCTS,
  )?.value;

  const productIds: string[] =
    bundleProductsValue?.reduce((acc: ProductValue[], productValue: ProductValue[]) => {
      const productId = productValue.find(({ name }) => name === PRODUCT_REF)?.value.id;
      return productId ? [...acc, productId] : acc;
    }, [] as string[]) || [];

  return productIds;
};
