import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { themedBannerLayoutStyles } from '@ecomm/cms-promo-banner/EcommBanner';
import type { Theme } from '@ecomm/cms-promo-banner/themes';
import { THEMES } from '@ecomm/cms-promo-banner/themes';

const BannerContainer = styled.div<{ theme: string | undefined }>`
  background: ${({ theme }): Theme => THEMES[theme].backgroundColor};
  color: ${({ theme }): Theme => THEMES[theme].color};
  ${themedBannerLayoutStyles}
  min-height: 46px !important;

  ${media.tablet`
    min-height: 56px !important;
  `}

  ${media.desktop`
    min-height: 66px !important;
  `}

  [data-element-id='banner'] {
    padding: unset;
    min-height: unset;
  }
`;

export default BannerContainer;
