import { Container, Label, spacing, yellow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import {
  ADD_TO_CART_DEFAULT_ERROR,
  ADD_TO_CART_ERRORS,
  theUserIsInGermany,
} from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { HEADER_HEIGHT_DESKTOP_LARGE } from '@page-builder/modules/Headband/constants';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';

const ERROR_SCROLL_OFFSET = 216;

type Props = {
  errorCode: string;
  errorMessageOverride?: string;
};

const AddToCartError: React.FC<React.PropsWithChildren<Props>> = ({
  errorCode,
  errorMessageOverride,
}) => {
  const locale = useLocale();
  const language = theUserIsInGermany(locale) ? Locale.GermanGermany : Locale.Default;

  const errorMessage =
    errorMessageOverride ||
    ADD_TO_CART_ERRORS[errorCode]?.[language] ||
    ADD_TO_CART_DEFAULT_ERROR[language];

  const { shouldFocusError } = useShopContext();
  const containerRef = React.useRef<HTMLElement>(null);
  const { subHeader } = React.useContext(GlobalReferenceContext);

  React.useEffect(() => {
    if (shouldFocusError) {
      const { top } = containerRef.current!.getBoundingClientRect();

      const headbandHeight = subHeader?.height ?? HEADER_HEIGHT_DESKTOP_LARGE;
      // We need to add the vertical scroll position because the top returned by getBoundingClientRect is within the
      // viewport, not the entire page
      const elementTop = top + window.scrollY;

      // We subtract the headband ehight because it's a sticky element and doesn't take up space, and add an offset
      // so that the error message doesn't end up touching the headband
      const newScrollPosition = elementTop - headbandHeight - ERROR_SCROLL_OFFSET;

      window.scrollTo({
        top: newScrollPosition,
        behavior: 'smooth',
      });
    }
  }, [shouldFocusError, subHeader]);

  return (
    <Container
      padding={spacing[16]}
      backgroundColor={yellow[50]}
      borderRadius={spacing[4]}
      ref={containerRef}
    >
      <ErrorLabel size="small">{errorMessage}</ErrorLabel>
    </Container>
  );
};

const ErrorLabel = styled(Label)`
  display: block; // The default display property leads to a little bit of extra space at the bottom of the label
`;

export default AddToCartError;
