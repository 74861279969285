import type { TypeComponent_hero } from '@pelotoncycle/page-builder';
import { Hero } from '@pelotoncycle/page-builder';
import React, { useContext } from 'react';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import {
  HEADER_HEIGHT_MOBILE,
  HEADER_HEIGHT_DESKTOP_LARGE,
  HEADER_HEIGHT_TABLET_XLARGE,
} from '@ecomm/header/constants';
import type { BlockProps } from '@page-builder/lib/pages';

type Props = TypeComponent_hero & BlockProps;

const HeroWrapper: React.FC<React.PropsWithChildren<Props>> = props => {
  const { banner } = useContext(GlobalReferenceContext);
  const bannerHeight = banner?.height || 0;

  return (
    <Hero
      offsetHeight={{
        mobile: bannerHeight + HEADER_HEIGHT_MOBILE,
        tablet: bannerHeight + HEADER_HEIGHT_TABLET_XLARGE,
        desktop: bannerHeight + HEADER_HEIGHT_DESKTOP_LARGE,
      }}
      {...props}
    />
  );
};

export default HeroWrapper;
