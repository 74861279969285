import { Support, Flex, Container, spacing } from '@pelotoncycle/design-system';
import type { Theme } from 'apps/freeform/types/referenceTypes';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@page-builder/utils/Markdown';
import type { HeadbandCTAProps } from './CTA';
import { CTA } from './CTA';

export const HeadbandCtaWithInfo: React.FC<{
  ctas?: HeadbandCTAProps[];
  theme?: Theme;
  financingText?: string;
  disclaimerText?: string;
  parentTitle?: string;
}> = ({ parentTitle, ctas, financingText, disclaimerText }) => {
  return (
    <StyledWrapper>
      <Flex justifyContent="flex-end" alignItems="center" flexGrow={1}>
        {(financingText || disclaimerText) && (
          <Container
            minWidth="196px"
            maxWidth="262px"
            padding="0 15px 0 0"
            textAlign="right"
            style={{ flexDirection: 'column', alignItems: 'flex-end' }}
            display={{ mobile: 'none', desktop: 'flex' }}
          >
            {financingText && (
              <Support is="p" size="medium">
                <Markdown content={financingText} />
              </Support>
            )}
            {disclaimerText && (
              <Support is="p" size="small">
                <Markdown content={disclaimerText} />
              </Support>
            )}
          </Container>
        )}
        <Flex gap={spacing[16]}>
          {ctas?.map(cta => (
            <CTA
              key={cta.text}
              text={cta.text}
              link={cta.link}
              parentTitle={parentTitle}
            />
          ))}
        </Flex>
      </Flex>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  flex-shrink: 0;
`;
