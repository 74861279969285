import {
  Badge,
  Container,
  Eyebrow,
  grey,
  Headline,
  Icon,
  Support,
  Flex,
} from '@pelotoncycle/design-system';
import React from 'react';
import { ScreenReaderText } from '@peloton/accessibility/screenReaderOnly';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import Markdown from '@page-builder/utils/Markdown';
import { useTheme } from '../themes';

type CellProps = {
  children: string;
  column: number;
};

export const HeaderCell: React.FC<React.PropsWithChildren<CellProps>> = ({
  children,
  column,
}) => {
  return (
    <Container textAlign={toTextAlignment(column)}>
      <BreakpointSwitchCSS
        breakpoints={{
          mobile: <Eyebrow size="medium">{children}</Eyebrow>,
          tablet: <Headline size="extraSmall">{children}</Headline>,
        }}
      />
    </Container>
  );
};

export const BodyCell: React.FC<React.PropsWithChildren<CellProps>> = ({
  children,
  column,
}) => {
  return (
    <Flex textAlign={toTextAlignment(column)} flexDirection="column">
      <BodyCellContent>{children}</BodyCellContent>
    </Flex>
  );
};

const BodyCellContent: React.FC<React.PropsWithChildren<Pick<CellProps, 'children'>>> = ({
  children,
}) => {
  const theme = useTheme();

  switch (children.toLowerCase()) {
    case 'yes':
    case 'ja':
      return (
        <Icon
          name="checkCircle"
          height={24}
          primaryColor={theme.modalIconPrimaryColor}
          secondaryColor={theme.modalIconSecondaryColor}
          aria-labelledby=""
          aria-label={children}
          role="img"
        />
      );
    case 'no':
    case 'nein':
      return (
        <Support size="large" as="span" textColor={grey[50]}>
          <span aria-hidden="true">&ndash;</span>
          <ScreenReaderText>{children}</ScreenReaderText>
        </Support>
      );
    default:
      return (
        <Support size="large" as="span">
          <Markdown
            content={children}
            markdown={{
              renderers: {
                inlineCode: AccentBadge,
                emphasis: SmallSupport,
              },
            }}
          />
        </Support>
      );
  }
};

const AccentBadge: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Badge variant="horizontal" theme="accent">
    {children}
  </Badge>
);

const SmallSupport: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Support size="small" as="span">
    {children}
  </Support>
);

const toTextAlignment = (column: number) => (column > 0 ? 'center' : 'left');
