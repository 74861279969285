import {
  white,
  Flex,
  spacing,
  Container,
  Headline,
  Eyebrow,
} from '@pelotoncycle/design-system';
import type { SyntheticEvent } from 'react';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import { media } from '@peloton/styles';
import { PrevArrowIcon, NextArrowIcon } from '@ecomm/themed-modules/VideoGallery/icons';
import autoPlayWithSound from '@ecomm/video/autoPlayWithSound';
import VideoElement from '@ecomm/video/VideoElement';
import VideoModal from '@page-builder/modules/VideoModal';

export type TrackingProps = {
  campaignName: string;
  additionalProps?: object;
};

export type Props = {
  modalVideo: string;
  poster?: any;
  carouselLength: number;
  header: string;
  index: number;
  isModalOpen: boolean;
  openNextModal: () => void;
  openPreviousModal: () => void;
  setModalState: (setValue: boolean) => void;
  trackingProps?: TrackingProps;
  tracks?: any;
  currentSlideText: string;
  ariaLabelText: string;
  contentId?: string;
};

const ClassesCarouselVideo: React.FC<React.PropsWithChildren<Props>> = ({
  modalVideo,
  poster,
  carouselLength,
  header,
  index,
  isModalOpen,
  openNextModal,
  openPreviousModal,
  setModalState,
  trackingProps,
  tracks,
  currentSlideText,
  ariaLabelText,
  contentId,
}) => {
  const { trackEvent } = useTracking();
  const videoContainerRef = React.useRef<HTMLDivElement>(null);
  const videoElement = videoContainerRef.current?.firstElementChild as HTMLVideoElement;

  const trackVideo = (eventType: string, e?: SyntheticEvent<HTMLMediaElement>) => {
    if (trackingProps) {
      const getCurrentTime = (ev: string): number => {
        switch (ev) {
          case 'Video Content Started':
            return 0;
          case 'Video Content Closed':
            return Math.floor(videoElement?.currentTime);
          default:
            return Math.floor((e?.target as HTMLVideoElement).currentTime);
        }
      };
      trackEvent({
        event: eventType,
        properties: {
          campaignName: trackingProps?.campaignName,
          contentId,
          page: window.location.pathname,
          title: header,
          length:
            eventType == 'Video Content Closed'
              ? Math.floor(videoElement?.duration)
              : Math.floor((e?.target as HTMLVideoElement).duration),
          currentTime: getCurrentTime(eventType),
          contentPosition: `video${index + 1}`,
          ...trackingProps?.additionalProps,
        },
      });
    } else {
      return;
    }
  };
  const handleModalClose = () => {
    trackVideo('Video Content Closed');
    setModalState(false);
  };
  const onVideoStart = (e: SyntheticEvent<HTMLMediaElement>) => {
    if ((e.target as HTMLVideoElement)?.currentTime > 0) {
      return;
    }
    autoPlayWithSound(e);
    trackVideo('Video Content Started', e);
  };

  const slideValues = { currentSlide: index + 1, totalSlides: carouselLength };
  const ariaLabel = useFormattedText(ariaLabelText, slideValues) as string;
  const slideText = `${slideValues.currentSlide} of ${slideValues.totalSlides}`;

  return (
    <VideoModal
      closeHandler={handleModalClose}
      contentLabel="classes video modal"
      darkMode={true}
      isOpen={isModalOpen}
      withContent
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        horizontalPadding={{ desktop: spacing[32] }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          verticalPadding={{ desktop: spacing[32] }}
        >
          <DesktopArrowButton onClick={openPreviousModal}>
            <PrevArrowIcon height={32} />
          </DesktopArrowButton>
          <Container horizontalPadding={{ desktop: spacing[24] }} ref={videoContainerRef}>
            <StyledVideoElement
              onCanPlay={onVideoStart}
              onPlay={e => trackVideo('Video Content Played', e)}
              onPause={e => trackVideo('Video Content Paused', e)}
              onEnded={e => trackVideo('Video Content Completed', e)}
              src={modalVideo}
              poster={poster}
              tracks={tracks}
              autoPlay
              controls
            />
          </Container>
          <DesktopArrowButton onClick={openNextModal}>
            <NextArrowIcon height={32} />
          </DesktopArrowButton>
        </Flex>
        <SubSection
          header={header}
          openPreviousModal={openPreviousModal}
          openNextModal={openNextModal}
          slideText={slideText}
          ariaLabel={ariaLabel}
        />
      </Flex>
    </VideoModal>
  );
};

type SubSectionProps = {
  ariaLabel: string;
  header: string;
  openNextModal: () => void;
  openPreviousModal: () => void;
  slideText: string;
};

export const SubSection: React.FC<React.PropsWithChildren<SubSectionProps>> = ({
  ariaLabel,
  header,
  openPreviousModal,
  openNextModal,
  slideText,
}) => (
  <Flex
    justifyContent={{ mobile: 'space-between', desktop: 'center' }}
    alignItems="center"
    padding={`${spacing[32]} ${spacing[32]} 0`}
    minWidth="100%"
  >
    <MobileArrowButton onClick={openPreviousModal} data-test-id="previous-button">
      <PrevArrowIcon height={32} />
    </MobileArrowButton>
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ color: white }}
    >
      <Flex flexDirection="column" gap={spacing[16]} alignItems="center">
        <Headline
          size="medium"
          as="span"
          data-test-id="subsection-header"
          style={{ fontSize: 36 }}
        >
          {header}
        </Headline>
        <Eyebrow
          size="small"
          aria-live="polite"
          aria-label={ariaLabel}
          data-test-id="carouselCount"
          style={{ fontSize: 12, letterSpacing: '0.1em' }}
        >
          {slideText}
        </Eyebrow>
      </Flex>
    </Flex>
    <MobileArrowButton onClick={openNextModal} data-test-id="next-button">
      <NextArrowIcon height={32} />
    </MobileArrowButton>
  </Flex>
);

export default ClassesCarouselVideo;

const MobileArrowButton = styled.button`
  svg {
    fill: ${white};
  }

  ${media.desktopLarge`
    display: none;
  `}
`;

const DesktopArrowButton = styled.button`
  display: none;
  svg {
    fill: ${white};
  }

  ${media.desktopLarge`
    display: inline-block;
  `}
`;

const StyledVideoElement = styled(VideoElement)`
  width: 100%;
  max-width: 1280px;
`;
