import { BundleType } from '@ecomm/shop/models/BundleType';
import fetchUpsellProductRecommendations from './fetchUpsellProductRecommendations';

type UpsellPage = [BundleType] | [BundleType, 'buy' | 'rent'];

const pages: UpsellPage[] = [
  [BundleType.Bike],
  [BundleType.Bike, 'rent'],
  [BundleType.BikePlus],
  [BundleType.BikePlus, 'rent'],
  [BundleType.Tread],
  [BundleType.Row],
  [BundleType.RainforestCafe],
  [BundleType.RefurbishedBike],
  [BundleType.RefurbishedBikePlus],
];

type UpsellDataFetcherMap = Record<string, typeof fetchUpsellProductRecommendations>;

const toUpsellPagePath = (page: UpsellPage) => {
  const [cfu, buyMethod] = page;

  return ['shop', cfu, buyMethod, 'complete-cart'].filter(Boolean).join('/');
};

const toUpsellCustomDataFetchers = (): UpsellDataFetcherMap => {
  return pages.reduce((fetchers, page) => {
    const path = toUpsellPagePath(page);

    return {
      ...fetchers,
      [path]: fetchUpsellProductRecommendations,
    };
  }, {} as UpsellDataFetcherMap);
};

export default toUpsellCustomDataFetchers();
