import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import React from 'react';
import { ProductRecommendationsGrid } from '@ecomm/product-recommendations';
import toCohortFromGenericList from '@ecomm/product-recommendations/utils/toCohortFromGenericList';

const ProductRecommendationsProductGrid: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = props => {
  const cohort = toCohortFromGenericList(props);

  return (
    <ProductRecommendationsGrid
      cohort={cohort}
      treatment={props.treatment}
      themeName={props.theme}
    />
  );
};

export default ProductRecommendationsProductGrid;
