import {
  Body,
  Flex,
  Eyebrow,
  Icon,
  Support,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { themes } from './themes';

type AIReviewsSummaryProps = {
  deviceType: TypeComponentReviewsFields['deviceType'];
  theme: TypeComponentReviewsFields['theme'];
};

const AIReviewsSummary: React.FC<AIReviewsSummaryProps> = ({ deviceType, theme }) => {
  const summaryKey = `reviewsSummary.${deviceType}`;
  const summary = useMicroCopy(summaryKey);
  const summaryEyebrow = useMicroCopy('reviewsSummaryEyebrow');
  const summarySupport = useMicroCopy('reviewsSummarySupport');

  const { textColor, itemTextColor } = themes[theme];

  return (
    <StyledFlex
      theme={theme}
      borderRadius={spacing[8]}
      flexDirection="column"
      flexGrow={1}
      flexShrink={1}
      flexBasis="0%"
      padding={{
        mobile: spacing[16],
        desktop: spacing[24],
      }}
      verticalMargin={{
        mobile: `${spacing[24]} 0`,
        desktop: '0',
      }}
      gap={{
        mobile: spacing[16],
        desktop: spacing[24],
      }}
    >
      <Flex flexDirection="column" gap={spacing[8]}>
        <Flex flexDirection="row" gap={spacing[8]}>
          <Eyebrow size="medium" textColor={textColor}>
            {summaryEyebrow}
          </Eyebrow>
          <svg width="20" height="20" viewBox="0 0 20 20">
            <defs>
              <linearGradient
                id="reviewsIconGradient"
                x1="0"
                y1="32"
                x2="24"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BFB2FF" />
                <stop offset="0.5" stopColor="#7F66FF" />
                <stop offset="1" stopColor="#3399FF" />
              </linearGradient>
            </defs>
            <StyledIcon name="sparkle" height={20} />
          </svg>
        </Flex>

        <Support size="medium" textColor={itemTextColor}>
          {summarySupport}
        </Support>
      </Flex>

      <Body size="small" textColor={textColor}>
        {summary}
      </Body>
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)<{ theme: TypeComponentReviewsFields['theme'] }>`
  background ${({ theme }) =>
    // dark theme gradient
    theme === 'Black'
      ? `radial-gradient(
      75% 150% at 100% 25%,
      ${rgba('#6d51fb', 0.25)} 0%,
      ${rgba(grey[75], 0)} 100%
    ),
    ${grey[75]}`
      : // light theme solid color
        rgba('#eae5ff', 0.5)}
`;

const StyledIcon = styled(Icon)`
  path {
    fill: url(#reviewsIconGradient);
  }
`;

export default AIReviewsSummary;
