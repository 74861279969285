import type { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { StatusCode } from '@peloton/next/api/status';
import { APP_ONE_GP_TRIAL_SKU } from '@peloton/subscription-plans/skus';

export interface GuestPassInfo {
  username: string;
  avatarUrl: string;
  guestPassCode: string;
  query: URLSearchParams;
}

export interface ReferrerPayload {
  code: string;
}

const getSku = () => {
  return APP_ONE_GP_TRIAL_SKU;
};

/**
 * Get guestpass info completely redux free!
 * Validates a guest pass code and returns the referrer info.
 * @param client Axios instance to use for requests.
 * @returns referrer info.
 */
export const useGuestPassInfo = (
  client: AxiosInstance,
  apiEnv: string,
): GuestPassInfo | null => {
  const [guestPassCode, setGuestPassCode] = useState<string | null>(null);
  const [query, setQuery] = useState<URLSearchParams | null>(null);
  const VALIDATE_ENDPOINT = `https://${apiEnv}.onepeloton.com/ecomm/access_code/validate`;
  const REFERRAL_ENDPOINT = `https://${apiEnv}.onepeloton.com/ecomm/access_code/referrer`;
  const SKU_CODE = getSku();
  const isValidGuestPassCode = guestPassCode !== null;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hasValidShareCode = queryParams.has('code');

    if (hasValidShareCode) {
      const code = queryParams.get('code')!;
      setGuestPassCode(code);
      setQuery(queryParams);
    }
  }, []);

  const { data, error } = useSWR(isValidGuestPassCode ? VALIDATE_ENDPOINT : null, () =>
    client.post(VALIDATE_ENDPOINT, {
      code: toGuestPassCode(guestPassCode!),
      sku: SKU_CODE,
    }),
  );
  const isValidReferralCode = data?.status === StatusCode.OK && guestPassCode !== null;

  const { data: referrerData, error: referrerError } = useSWR(
    isValidGuestPassCode ? REFERRAL_ENDPOINT : null,
    () => client.get(`${REFERRAL_ENDPOINT}/${toGuestPassCode(guestPassCode!)}`),
  );

  if (error) {
    console.error(error);
  }

  if (referrerError) {
    console.error(referrerError);
  }

  const { username, imageUrl } = referrerData?.data || {};
  const isValidResponse =
    username && imageUrl && guestPassCode && isValidReferralCode && query;

  if (isValidResponse) {
    return {
      username,
      avatarUrl: imageUrl,
      guestPassCode: guestPassCode!,
      query: query!,
    };
  }

  return null;
};

/**
 * Converts a guest pass code to a legacy code atob encoded string.
 * Required to fetch the correct data from the /ecomm/access_code/referrer endpoint.
 * @param code guest pass code to encode.
 * @returns an encoded string.
 */
const toGuestPassCode = (code: string) => {
  try {
    const [legacyCode] = atob(code).split('|');
    return legacyCode;
  } catch (_) {
    return code;
  }
};
