import { DISCIPLINE_VARIANTS } from '../models/constants';

export const findDisciplineForVariant = (slug: string) => {
  const matchingDisciplineSlug = Object.keys(DISCIPLINE_VARIANTS).find(disciplineSlug => {
    const disciplineVariantSlug = DISCIPLINE_VARIANTS[disciplineSlug];
    return disciplineVariantSlug === slug;
  });

  return matchingDisciplineSlug;
};
