import {
  Flex,
  Media,
  Label,
  Body,
  spacing,
  white,
  grey,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import { CARD_MEDIA_HEIGHT } from '@page-builder/modules/VideoCarousel/constants';
import Markdown from '@page-builder/utils/Markdown';
import type { MediaCarouselCardProps } from './types';

type ActiveProps = {
  isActive: boolean;
  isInView?: boolean;
};

/**
 * This component maps to one item in the Media Carousel.
 * @param headline optional bolded text that is displayed right below the rendered media.
 * @param subtext optional subtext that is displayed right below the optional headline.
 * @param media the media asset that is rendered in the carousel card item.
 */
const MediaCarouselCard = ({
  headline,
  subtext,
  media,
  isActive,
  isInView,
}: MediaCarouselCardProps & ActiveProps) => {
  const showText = Boolean(headline || subtext);
  const mediaProps = {
    ...media,
    autoplay: isActive,
    progressTracked: true,
    playPauseButtonPosition: isActive ? 'right' : 'hide',
  } as const;

  return (
    <Card flexDirection="column" gap={spacing[16]} isActive={isActive}>
      {isInView ? (
        <CardMedia key={`${isActive}`} media={mediaProps} />
      ) : (
        <PlaceholderMedia data-test-id="placeholder-media" />
      )}
      {showText && (
        <Flex flexDirection="column" gap={spacing[8]} style={{ whiteSpace: 'normal' }}>
          {headline && (
            <Label size="extraLarge" textColor={white}>
              {headline}
            </Label>
          )}
          {subtext && (
            <Body size="small" textColor={grey[50]}>
              <Markdown content={subtext} />
            </Body>
          )}
        </Flex>
      )}
    </Card>
  );
};

export default MediaCarouselCard;

const Card = styled(Flex)`
  opacity: ${(props: ActiveProps) => (props.isActive ? 1 : 0.45)};
  transition: opacity 400ms ease;
`;

const CardMedia = styled(Media)`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  scroll-snap-align: center;
  img {
    object-fit: contain;
  }
`;

const PlaceholderMedia = styled.div`
  height: ${CARD_MEDIA_HEIGHT.mobile};
  ${mediaBreakpoints.desktopLarge`
      height: ${CARD_MEDIA_HEIGHT.desktop};
  `}
  border-radius: 12px;
  background: ${grey[60]};
`;
