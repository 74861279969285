import { Body, Headline, Flex } from '@pelotoncycle/design-system';
import type { ButtonProps } from '@pelotoncycle/design-system';
import React from 'react';
import type { ReactNode } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCountry } from '@peloton/internationalize/models/country';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles/breakpoints';
import { LinkView as AffirmModalLink } from '@ecomm/financing/affirm/ModalLinkView';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import { AffirmLogo } from '@ecomm/logos/AffirmLogo';
import type { BundleType } from '@ecomm/shop/models/BundleType';

type FinancingDetailsContainerProps = {
  name: string;
  headline: ReactNode;
  body: ReactNode;
  cta: string;
  bundle: BundleType;
  textColor: string;
  headlineColor: string;
  buttonColor: ButtonProps['color'];
};

export const FinancingDetailsContainer: React.FC<
  React.PropsWithChildren<FinancingDetailsContainerProps>
> = ({ name, headline, body, cta, bundle, textColor, headlineColor, buttonColor }) => {
  const { total, term } = useFinancingNext(bundle);

  const { trackEvent } = useTracking();

  const trackPrequalComplete = React.useCallback(
    (data: any) => {
      trackEvent({
        event: 'Completed Prequal',
        properties: {
          productName: bundle,
          unitName: name,
          installmentAmount: data.installment_amount,
          installmentCount: data.installment_count,
          interestAmount: data.interest_amount,
          prequalAmount: data.prequal_amount,
          status: data.status,
          country: toCountry(),
        },
      });
    },
    [name, trackEvent],
  );

  const trackClick = () => {
    trackEvent({
      event: 'Clicked Prequalify CTA',
      properties: {
        productName: bundle,
        unitName: name,
      },
    });
  };

  return (
    <Flex
      alignItems={{ mobile: 'stretch', tablet: 'flex-start' }}
      flexDirection="column"
      width="100%"
      maxWidth="551px"
      margin={{ desktop: '0 0 0 126px' }}
    >
      <Headline
        size="small"
        data-test-id="prequalify-now-headline"
        textColor={headlineColor}
        display="inline-block"
      >
        {headline}
        <StyledAffirmLogo textColor={textColor} swoopColor={textColor} />
      </Headline>
      <StyledBody size="small" textColor={textColor} data-test-id="prequalify-now-body">
        {body}
      </StyledBody>
      <StyledButton
        size="medium"
        width={{ mobile: 'adaptive', tablet: 'fixed' }}
        color={buttonColor}
        component={() => (
          <AffirmModalLink
            amount={total}
            term={term}
            bundleType={bundle as BundleType}
            onPrequalComplete={trackPrequalComplete}
            onClick={trackClick}
          >
            {() => <>{cta}</>}
          </AffirmModalLink>
        )}
      />
    </Flex>
  );
};

const StyledButton = styled(LinkButton)`
  > button {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.5px;
  }
`;

const StyledBody = styled(Body)`
  margin: 16px 0 24px;
  letter-spacing: unset;
`;

const StyledAffirmLogo = styled(AffirmLogo)`
  margin-left: 8px;
  width: 48px;

  ${media.tablet`
    width: 80px;
  `}
`;
