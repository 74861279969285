export enum ProspectsDiscipline {
  STRENGTH = 'strength',
  CARDIO = 'cardio',
  YOGA = 'yoga',
  RUNNING = 'running',
  STRETCHING = 'stretching',
  OUTDOOR = 'outdoor',
  ROWING = 'rowing',
  MEDITATION = 'meditation',
  CYCLING = 'cycling',
  WALKING = 'walking',
}

export enum EquipmentSlug {
  HeavyWeights = 'heavy_weights',
  LightWeights = 'light_weights',
  MediumWeights = 'medium_weights',
  ResistanceBand = 'resistance_band',
  WorkoutMat = 'workout_mat',
  YogaBlanket = 'yoga_blanket',
  YogaBlock = 'yoga_block',
  YogaBolster = 'yoga_bolster',
  YogaMat = 'yoga_mat',
  YogaStrap = 'yoga_strap',
}

export type Artist = {
  artistName: string;
  artistId: string;
};

export type Album = {
  imageUrl: string;
  name: string;
};

export type Song = {
  title: string;
  artists: Artist[];
  album: Album;
};

export type Playlist = {
  songs: Song[];
  isTopArtistsShown?: boolean;
  isTopArtistShown?: boolean;
  topArtists: Artist[];
  topAlbums: Album[];
};

export type Segment = {
  length: number;
  iconUrl: string;
  iconName: string;
  iconSlug: string;
  name: string;
  movementCount: number;
};

export enum MuscleGroups {
  biceps = 'biceps',
  triceps = 'triceps',
  forearms = 'forearms',
  shoulders = 'shoulders',
  traps = 'traps',
  lats = 'lats',
  mid_back = 'mid_back',
  low_back = 'low_back',
  glutes = 'glutes',
  core = 'core',
  chest = 'chest',
  calves = 'calves',
  hamstrings = 'hamstrings',
  quads = 'quads',
  obliques = 'obliques',
  hips = 'hips',
  other = 'other',
}

export type MuscleGroup =
  | MuscleGroups
  | 'biceps'
  | 'triceps'
  | 'forearms'
  | 'shoulders'
  | 'traps'
  | 'lats'
  | 'mid_back'
  | 'low_back'
  | 'glutes'
  | 'core'
  | 'chest'
  | 'calves'
  | 'hamstrings'
  | 'quads'
  | 'obliques'
  | 'hips';

export type MuscleGroupScore = {
  muscleGroup: MuscleGroup;
  displayName: string;
  score?: number;
  percentage?: number;
  bucket?: 3 | 2 | 1;
};

export type BucketByMuscle = Partial<
  Record<NonNullable<MuscleGroupScore['muscleGroup']>, number>
>;

export type Equipment = {
  slug: EquipmentSlug;
  name: string;
};

export type BrowseCategory = {
  id: string;
  name: string;
  slug: string;
};

export type ClassType = {
  id: string;
  name: string;
};

export type ApiClass = {
  id: string;
  originLocale: string;
  difficultyLevel: 'beginner' | 'intermediate' | 'advanced' | null;
  slug: string;
  fitnessDiscipline: string;
  fitnessDisciplineDisplayName: string;
  imageUrl: string;
  title: string;
  instructorName: string;
  instructorImageUrl: string;
  description: string;
  metadataDescription?: string;
  metaDesc?: string;
  hasClosedCaptions: boolean;
  isExplicit: boolean;
  captions: string[] | null;
  airTime?: number | string;
  // The `originalAirTime` property is deprecated and should be decomissioned for the Run phase.
  originalAirTime: number | string;
  playlist: Playlist;
  segments: Segment[] | null;
  equipmentTags: string[] | null;
  browseCategories: BrowseCategory[];
  classTypes: ClassType[];
  muscleGroupPercentages: MuscleGroupScore[] | null;
};

/* Final type once the API class data has been mapped. */
export type Class = Omit<ApiClass, 'fitnessDiscipline' | 'equipmentTags'> & {
  fitnessDiscipline: ProspectsDiscipline;
  equipmentTags: Equipment[] | null;
};

export type FreeClass = Class & {
  isFree: boolean;
  youtubeUrl: string;
  extraCardType?: string;
  classType: string[];
  metrics: string[];
  title: string;
  metaDesc: string;
  h1: string;
  description: string;
  videoUrl: string;
  imgUrl: string;
};

export type ClassWithFreeClass = Class & {
  freeClass: FreeClass | null;
};
