import { brand, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { white } from '@ecomm/colors';
import { CloseIcon } from '@ecomm/icons';
import { focusHighlight } from '@ecomm/styles';

type CloseButtonProps = {
  contentLabel: string;
  onClick: () => void;
  darkMode?: boolean;
};

const CloseButton: React.FC<React.PropsWithChildren<CloseButtonProps>> = ({
  contentLabel,
  onClick,
  darkMode = false,
}) => (
  <CloseButtonContainer darkMode={darkMode}>
    <StyledButton
      onClick={onClick}
      aria-label={`close ${contentLabel}`}
      data-test-id="dismissModal"
    >
      <CloseIcon
        width={30}
        height={30}
        fill={darkMode ? grey[40] : grey[70]}
        aria-hidden={true}
      />
    </StyledButton>
  </CloseButtonContainer>
);

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 1;

  &:hover path {
    fill: ${brand.darkest};
  }

  ${({ darkMode }: { darkMode: boolean }) =>
    darkMode &&
    `
    &:hover path {
      fill: ${white};
    }
  `};
`;

const StyledButton = styled.button`
  ${focusHighlight}
`;

export default CloseButton;
