import React from 'react';
import type { TypeComponentReviews } from '@page-builder/lib/types';
import TreatmentToComponentMap from './maps';

const Reviews: React.FC<React.PropsWithChildren<TypeComponentReviews>> = ({
  fields,
  sys,
}) => {
  const { treatment } = fields;

  const Component = TreatmentToComponentMap[treatment];

  if (!Component) {
    console.warn(`${treatment} can not be handled`);
    return null;
  }

  const contentTypeId = sys.contentType.sys.id;

  return <Component key={`${contentTypeId}-${treatment}`} {...fields} />;
};

export default Reviews;
