import {
  Flex,
  grey,
  Label,
  Media,
  spacing,
  Support,
  white,
} from '@pelotoncycle/design-system';
import MusicIcon from '@pelotoncycle/design-system/icons/marketing/Music';
import React, { useState } from 'react';
import styled from 'styled-components';
import type { Song, Album } from '@peloton/models/PelotonClass';
import { ellipsis } from '@peloton/styles';
import { toCloudinaryImage } from '@page-builder/modules/ClassesGrid/utils/toCloudinaryImage';

type Props = {
  namespace?: string;
  song: Song;
  matchingAlbum?: Album;
  idx: number;
  visible: boolean;
};

export const ClassDetailsPlaylistSong = React.forwardRef<HTMLLIElement, Props>(
  ({ namespace, song, matchingAlbum, idx, visible }, ref) => {
    const {
      artists,
      title,
      album: { imageUrl, name: albumName },
    } = song;

    const mobileImageUrl = () => {
      return imageUrl
        ? toCloudinaryImage({ mobile: imageUrl || '' }, { width: 48 }).mobile
        : '';
    };

    const [hasImageLoadError, setImageLoadError] = useState(false);
    const uniqueArtistNames = [...new Set(artists.map(artist => artist.artistName))];

    const artistNames = uniqueArtistNames.join(', ');

    return (
      <li
        aria-labelledby={`${namespace || ''}song-title-${idx} ${
          namespace || ''
        }song-artists-${idx}`}
        aria-hidden={!visible}
        tabIndex={-1}
        ref={ref}
      >
        <Flex gap={spacing[16]} alignItems="center">
          {imageUrl && !hasImageLoadError ? (
            <AlbumImage
              width="48px"
              height="48px"
              media={{
                alt: albumName || matchingAlbum?.name || '',
                type: 'image',
                loading: 'lazy',
                mobile: mobileImageUrl(),
                onError: () => {
                  setImageLoadError(true);
                },
              }}
            />
          ) : (
            <IconContainer data-test-id="music-icon">
              <MusicIcon name="music" primaryColor={grey[60]} height={28} />
            </IconContainer>
          )}
          <Flex flexDirection="column" minWidth="0">
            <SongTitle
              size="large"
              textColor={white}
              id={`${namespace || ''}song-title-${idx}`}
            >
              {title}
            </SongTitle>
            <SongArtists
              size="large"
              textColor={grey[50]}
              is="span"
              id={`${namespace || ''}song-artists-${idx}`}
            >
              {artistNames}
            </SongArtists>
          </Flex>
        </Flex>
      </li>
    );
  },
);

ClassDetailsPlaylistSong.displayName = 'ClassDetailsPlaylistSong';

const AlbumImage = styled(Media)`
  flex-shrink: 0;
`;

const SongTitle = styled(Label)`
  ${ellipsis};
`;

const SongArtists = styled(Support)`
  ${ellipsis};
`;

const IconContainer = styled.div`
  background-color: rgba(101, 102, 106, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
`;
