import type { ResponsiveImageProps } from '@pelotoncycle/design-system';
import {
  Body,
  black,
  grey,
  spacing,
  white,
  Container,
  Flex,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DSImageWithCloudinary from '@ecomm/image/DSImageWithCloudinary';
import SectionWrapper, { SUBNAV_SIZE } from './SectionWrapper';
import setRootElement from './setRootElement';
import type { Props as BaseProps } from './ViewportModalWithHeader/ViewportModalWithHeader';
import ViewportModalWithHeader from './ViewportModalWithHeader/ViewportModalWithHeader';

type Props = BaseProps & {
  images: ResponsiveImageProps[];
  scrollPosition?: number;
};

const ProductImageModal: React.FC<React.PropsWithChildren<Props>> = ({
  images,
  scrollPosition,
  ...props
}) => {
  const isClient = typeof window !== 'undefined';
  const innerHeight = (isClient && window.innerHeight) || 0;

  const handleOnClose = () => {
    props.closeHandler && props.closeHandler();
  };

  return (
    <ViewportModalWithHeader {...props}>
      <ModalGlobalStyles />
      <StyledContainer>
        {images.length &&
          images.map((image, index) => {
            const ImageAndLabel = (
              <Container
                key={image.mobile}
                onClick={handleOnClose}
                width="fit-content"
                margin="0 auto"
              >
                <StyledImage
                  key={image.mobile}
                  image={image}
                  // We want high quality images here
                  options={{ height: innerHeight }}
                />
                {image.alt && <StyledLabel size="large">{image.alt}</StyledLabel>}
              </Container>
            );
            return (
              <StyledSectionWrapperWithDotNav
                key={`sections-${image.mobile}`}
                position={index}
                total={images.length}
                subnavSize={SUBNAV_SIZE}
              >
                {ImageAndLabel}
              </StyledSectionWrapperWithDotNav>
            );
          })}
      </StyledContainer>
    </ViewportModalWithHeader>
  );
};

export default ProductImageModal;

const StyledImage = styled(DSImageWithCloudinary)`
  object-fit: contain;
  text-align: center;

  img {
    max-width: 808px;
    height: 100vh;

    &:hover {
      cursor: zoom-out;
    }
  }
`;

const StyledLabel = styled(Body)`
  color: ${grey[90]};
  text-align: left;
  padding: ${spacing[16]} 0 ${spacing[40]};
  text-align: center;
`;

const StyledContainer = styled(Flex)`
  padding: ${spacing[40]} 0 ${spacing[16]};
  background: ${white};
  min-height: 100vh;
  flex-direction: column;

  picture {
    display: block;
    img {
      margin: auto;
    }
  }
`;

const StyledSectionWrapperWithDotNav = styled(SectionWrapper)`
  div > button {
    right: ${spacing[32]};
  }
`;

export const ModalGlobalStyles = createGlobalStyle`
// hide the scroll bar on safari (since we use breadcrumbs)
    .ReactModal__Overlay::-webkit-scrollbar {
      display: none;
    }

// override the light gray stroke color of the X
    div.ReactModal__Content button[data-test-id="dismissModal"] svg path {
      stroke: ${black};
    }
  `;

setRootElement();
