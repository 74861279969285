import React from 'react';
import { useMicroCopy } from '@content/client/microCopy';
import { toFormattedReviewAuthor } from '@ecomm/reviews/data/utils/formattedReviewValues';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers';
import GenericReviewCards from './GenericReviewCards';
import type { ReviewCardProps } from './ReviewCard';

const ReviewCards: React.FC<React.PropsWithChildren<TypeComponentReviewsFields>> = ({
  reviews,
  averageRating,
  text,
  ctas,
  theme,
  deviceType,
}) => {
  const seeMoreLabel = useMicroCopy('seeMoreLabel');
  const seeLessLabel = useMicroCopy('seeLessLabel');
  const { headline, label } = text?.fields || {};

  const ctaButtons = ctas
    ? toCtaFields(ctas).map(
        cta =>
          cta && {
            href: cta.link?.fields.url,
            text: cta.text,
            color: cta.color,
            variant: cta.variant,
          },
      )
    : [undefined, undefined];

  const primaryButton = ctaButtons[0];
  const secondaryButton = ctaButtons[1];

  const reviewItems: ReviewCardProps[] = reviews.map(
    ({
      fields: {
        rating,
        memberName,
        location,
        text: reviewText,
        verified,
        moreLabel,
        lessLabel,
        date,
      },
    }) => {
      const { body, headline: title } = reviewText?.fields || {};
      return {
        title: title ?? '',
        rating,
        heading: toFormattedReviewAuthor({
          authorName: memberName,
          authorLocation: location,
        }),
        date,
        reviewText: body ?? '',
        verified,
        moreLabel: moreLabel || seeMoreLabel,
        lessLabel: lessLabel || seeLessLabel,
      };
    },
  );

  return (
    <GenericReviewCards
      title={headline}
      totalReviews={label}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      rating={averageRating}
      reviewItems={reviewItems}
      theme={theme}
      deviceType={deviceType}
    />
  );
};

export default ReviewCards;
