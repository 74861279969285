import { Flex } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import React from 'react';
import ProductComparisonModalCta from './ProductComparisonModal/ProductComparisonModalCta';

type Props = {
  ctas: TypeComponentCta[];
  productName: string;
};

const ProductComparisonCtas: React.FC<React.PropsWithChildren<Props>> = ({
  ctas,
  productName,
}) => {
  return (
    <Flex flexDirection="column" alignItems="center">
      {ctas.map(cta => {
        // TODO: Render the start trial CTA here

        if (cta.fields.styledAs === 'link') {
          return (
            <ProductComparisonModalCta
              key={cta.fields.name}
              cta={cta}
              productName={productName}
            />
          );
        }

        return null;
      })}
    </Flex>
  );
};

export default ProductComparisonCtas;
