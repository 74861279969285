import { spacing } from '@pelotoncycle/design-system';
import { css } from 'styled-components';
import { media } from '@peloton/styles';

const DESKTOP_COLUMN_WIDTH = 80;
const DESKTOP_GUTTER = 24;

const CUSTOM_LARGEST_BREAKPOINT = 1304;

export const designMaxWidth = (columns: number) => css`
  ${media.desktopLarge`
    max-width: ${DESKTOP_COLUMN_WIDTH * columns + DESKTOP_GUTTER * (columns - 1)}px;
  `}
`;

export const designGutter = css`
  grid-gap: ${spacing[16]};

  ${media.tabletXLarge`
    grid-gap: ${spacing[24]};
  `};
`;

export const designPaddings = css`
  padding-left: ${spacing[32]};
  padding-right: ${spacing[32]};

  ${media.tabletXLarge`
    padding-left: ${spacing[64]};
    padding-right: ${spacing[64]};
  `};

  ${media.desktopLarge`
    padding-left: ${spacing[40]};
    padding-right: ${spacing[40]};
  `};

  @media (min-width: ${CUSTOM_LARGEST_BREAKPOINT}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
