import { Flex, spacing, grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { HeadbandCTAProps } from './CTA';
import { CTA } from './CTA';

export const MobileCTABanner: React.FC<{
  ctas?: HeadbandCTAProps[];
  title?: string;
}> = ({ ctas, title }) => {
  if (!ctas) {
    return null;
  }

  return (
    <StickyContainer
      gap={spacing[16]}
      position="fixed"
      width="100%"
      justifyContent="center"
      padding={`${spacing[12]} ${spacing[16]}`}
      data-test-id="mobile-cta-banner"
    >
      {ctas.map(cta => (
        <CTA
          key={cta.text}
          text={cta.text}
          link={cta.link}
          parentTitle={title}
          behavior="adaptive"
        />
      ))}
    </StickyContainer>
  );
};

const StickyContainer = styled(Flex)`
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${transparentize(0.1, grey[90])};
`;
