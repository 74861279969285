import {
  Flex,
  Media,
  Label,
  Body,
  spacing,
  white,
  grey,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import { toMediaProps, getGenericTextNodes } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import { CARD_MEDIA_HEIGHT } from './constants';

type ActiveProps = {
  active: boolean;
  isInView?: boolean;
};

type Props = TypeComponentGenericTextWithMediaFields & ActiveProps;

const VideoCarouselCard: React.FC<React.PropsWithChildren<Props>> = ({
  media,
  text,
  active,
  isInView,
}) => {
  const { headline, body } = getGenericTextNodes(text);
  const showText = Boolean(headline || body);
  const mediaProps = {
    ...toMediaProps(media!),
    autoplay: active,
    progressTracked: true,
    playPauseButtonPosition: active ? 'right' : 'hide',
  } as const;

  return (
    <Card flexDirection="column" gap={spacing[16]} active={active}>
      {isInView ? (
        <CardMedia key={`${active}`} media={mediaProps} />
      ) : (
        <PlaceholderMedia data-test-id="placeholder-media" />
      )}
      {showText && (
        <Flex flexDirection="column" gap={spacing[8]} style={{ whiteSpace: 'normal' }}>
          {headline && (
            <Label size="extraLarge" textColor={white}>
              {headline}
            </Label>
          )}
          {body && (
            <Body size="small" textColor={grey[50]}>
              {body}
            </Body>
          )}
        </Flex>
      )}
    </Card>
  );
};

const Card = styled(Flex)`
  opacity: ${(props: ActiveProps) => (props.active ? 1 : 0.45)};
  transition: opacity 400ms ease;
`;

const CardMedia = styled(Media)`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  scroll-snap-align: center;
`;

const PlaceholderMedia = styled.div`
  height: ${CARD_MEDIA_HEIGHT.mobile};
  ${mediaBreakpoints.desktopLarge`
      height: ${CARD_MEDIA_HEIGHT.desktop};
  `}
  border-radius: 12px;
  background: ${grey[60]};
`;

export default VideoCarouselCard;
