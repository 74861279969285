import React from 'react';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toMediaProps } from '@page-builder/utils/helpers/media';
import FeaturedSpacesGeneric from './FeaturedSpacesGeneric';

const FeaturedSpaces: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text }) => {
  const { headline, body } = getGenericTextNodes(text, ['headline', 'body']);

  const images = items.map(item => {
    if (!item.fields.media) return {};
    return toMediaProps(item.fields.media);
  });

  return (
    <FeaturedSpacesGeneric title={headline} shortDescription={body} images={images} />
  );
};

export default FeaturedSpaces;
