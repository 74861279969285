import type { Locale } from '@peloton/internationalize';
import type { ProductProjectionType } from '../models/main';
import { isAccessoryBundleCT } from '../utils/isAccessoryBundleCT';
import { mapBundlesToAccessoriesList } from './mapBundlesToAccessoriesList';
import { mapProductsToAccessoriesList } from './mapProductsToAccessoriesList';

export const mapAllCTAccessories = (
  result: ProductProjectionType[],
  locale: Locale,
  context: Record<string, boolean> = {},
) => {
  const products: ProductProjectionType[] = [];
  const bundles: ProductProjectionType[] = [];

  result.forEach(item => {
    const isBundle = isAccessoryBundleCT(item);

    if (isBundle) {
      bundles.push(item);
    } else {
      products.push(item);
    }
  });

  return {
    bundles: mapBundlesToAccessoriesList(bundles, locale, context),
    products: mapProductsToAccessoriesList(products, locale, context),
  };
};
