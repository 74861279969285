import { Flex, spacing, white, Support, grey, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { Difficulty, DifficultyLabels } from '../Modal/ClassDifficultyRate';
import ClassDifficultyRate from '../Modal/ClassDifficultyRate';

type Props = {
  difficultyRate: Difficulty | null;
  difficultyLabels: DifficultyLabels;
  instructor: string;
  discipline: string;
  title: string;
};

const ClassCardDescription: React.FC<React.PropsWithChildren<Props>> = ({
  difficultyRate = 'beginner',
  instructor,
  discipline,
  title,
  difficultyLabels,
}) => {
  const difficultyLabel =
    difficultyRate! in difficultyLabels ? ` (${difficultyLabels[difficultyRate!]})` : '';
  const ariaLabel = instructor ? `${title}${difficultyLabel}, ${instructor}` : title;
  return (
    <ClassCardDetailsWrapper aria-label={ariaLabel}>
      <Flex flexDirection="column">
        <ClassDifficultyRate
          difficultyRate={difficultyRate}
          difficultyLabels={difficultyLabels}
        />
        <TitleWrapper margin={difficultyRate ? '4px 0 0' : 'unset'}>
          <StyledLabel size="extraLarge" data-test-id="card-title">
            {title}
          </StyledLabel>
        </TitleWrapper>
        <StyledSupport size="medium" data-test-id="instructor-discipline">
          {instructor} • {discipline}
        </StyledSupport>
      </Flex>
    </ClassCardDetailsWrapper>
  );
};

export default ClassCardDescription;

const ClassCardDetailsWrapper = styled.div`
  border-radius: 0 0 ${spacing[4]} ${spacing[4]};
  color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: auto;
  padding: ${spacing[24]} ${spacing[16]};
  height: auto;

  ${media.tabletXLarge`
    padding: ${spacing[24]};
    height: 127px;
  `}
`;

const TitleWrapper = styled.div<{ margin: string }>`
  margin: ${props => props.margin};
`;

const StyledLabel = styled(Label)`
  ${media.tabletXLarge`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 98%;
  `}
`;

const StyledSupport = styled(Support)`
  margin-top: 4px;
  color: ${grey[40]};
`;
