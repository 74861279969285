import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { Spinner } from '@ecomm/spinner';

const HeroLoader = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
);

export default HeroLoader;

const SpinnerContainer = styled.div`
  width: 100%;
  min-height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    height: 550px;
  `}

  ${media.desktop`
    height: 760px;
  `}
`;
