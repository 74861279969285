import type { Entry } from 'contentful';
import React from 'react';
import { PageContentType, ComponentContentTypes } from '@page-builder/lib/constants';
import type { TypePage } from '@page-builder/lib/types';
import Variation from '@page-builder/modules/Variation/NextVariation';
import { ContentTypeMap as NextContentTypeMap } from './maps/nextTypeMap';
import createBlock from './utils/createBlock';

const fromPage = (fieldName: string) => {
  return function getBlock(parent: Entry<TypePage>) {
    return <NextBlock block={{ ...parent?.fields[fieldName], parent }} />;
  };
};

export const ContentTypeMap = {
  ...NextContentTypeMap,
  [PageContentType]: fromPage('content'),
  [ComponentContentTypes.Variation]: Variation,
};

const NextBlock = createBlock(ContentTypeMap);
export default NextBlock;
