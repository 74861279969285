import { useEffect, useState } from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import type {
  ReviewEquipmentType,
  ReviewsOrderByInput,
  Language,
} from '@ecomm/graphql/types.generated';
import { useReviewsQuery } from '@ecomm/reviews/data/reviews/Reviews.generated';
import { useStatsQuery } from '@ecomm/reviews/data/reviews/Stats.generated';
import { useUgcReviewsQuery } from '@ecomm/reviews/data/ugc/UGCReviews.generated';
import { useUgcReviewsStatsQuery } from '@ecomm/reviews/data/ugc/UGCReviewsStats.generated';

export type ReviewsQueryResponse = { totalCount: number; nodes: any };
export type Includes = { RatingValue: number; Count: number };
export type SecondaryRatingAverages = {
  delivery?: number;
  easeOfUse?: number;
  quality?: number;
  value?: number;
  varietyOfInstructors?: number;
};
export type ReviewsStatsQueryResponse = {
  totalCount: number;
  averageOverallRating: number;
  includes: Includes[];
  secondaryRatingAverages: SecondaryRatingAverages;
};

type EmptyData = {};

export const useUGCQuery = (
  queryType: UGCQueryType,
  options: {
    suspend?: boolean;
    variables: {
      equipmentType: ReviewEquipmentType;
      lang: Language | undefined;
      orderBy?: ReviewsOrderByInput;
      excludeFamily?: boolean;
      offset?: number;
      limit?: number;
      minimalRating?: number;
      overallRating?: number;
    };
  },
): ReviewsQueryResponse | ReviewsStatsQueryResponse | EmptyData => {
  const [reviewData, setData] = useState<
    ReviewsQueryResponse | ReviewsStatsQueryResponse | EmptyData
  >({});
  if (!queriesMap[queryType]) {
    throw new Error('Method not supported');
  }
  const { data, errors } = queriesMap[queryType].microservice.query(options);
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  useEffect(() => {
    if (data) {
      setData(data[queriesMap[queryType].microservice.method]);
    }
    if (errors) {
      const { monolithData } = queriesMap[queryType].monolith.query(options);
      console.error('Error fetching reviews from microservice', errors);
      const tags = {
        userAction: 'fetch',
        dataSource: 'reviews',
      };
      reportError(errors[0].message, { tags });
      if (monolithData) {
        setData(monolithData[queriesMap[queryType].monolith.method]);
      }
    }
  }, [data, options, errors, queryType, reportError]);

  return reviewData;
};

export type UGCQueryType = 'getReviews' | 'getReviewsStats';
type APIProvider = 'monolith' | 'microservice';

type ProviderProps = { query: (options: any) => any; method: string };

const queriesMap: Record<UGCQueryType, { [key in APIProvider]: ProviderProps }> = {
  getReviews: {
    monolith: {
      query: useReviewsQuery,
      method: 'reviewsByEquipmentType',
    },
    microservice: {
      query: useUgcReviewsQuery,
      method: 'reviewsByCriteria',
    },
  },
  getReviewsStats: {
    monolith: {
      query: useStatsQuery,
      method: 'reviewsByEquipmentType',
    },
    microservice: {
      query: useUgcReviewsStatsQuery,
      method: 'reviewsByCriteria',
    },
  },
};
