import { Grid, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useMicroCopy, useMicroCopyBulk } from '@content/client/microCopy';
import type { SecondaryRatingAverages } from '@ecomm/reviews/data/utils/useUGCQuery';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';
import { reviewsScoresMicroCopyKeys, secondaryRatingAveragesKeyNames } from './constants';
import ScoreWheel from './ScoreWheel';

type ScoreWheelsProps = {
  secondaryRatingAverages: SecondaryRatingAverages;
  theme: TypeComponentReviewsFields['theme'];
};

const ScoreWheels: React.FC<ScoreWheelsProps> = ({ secondaryRatingAverages, theme }) => {
  const outOfCopy = useMicroCopy('reviewsOutOf');
  const microCopyBulk = useMicroCopyBulk(reviewsScoresMicroCopyKeys);
  return (
    <Grid
      columnCount={{ mobile: 2, desktop: 3 }}
      verticalPadding={{
        mobile: `${spacing[24]} 0`,
        desktop: `${spacing[32]} 0`,
      }}
      gap={spacing[16]}
    >
      {secondaryRatingAveragesKeyNames.map(name => {
        const score = secondaryRatingAverages[name];
        return (
          score && (
            <ScoreWheel
              key={name}
              score={score}
              title={microCopyBulk[`reviewsScores.${name}`]}
              subtitle={outOfCopy}
              theme={theme}
            />
          )
        );
      })}
    </Grid>
  );
};

export default ScoreWheels;
