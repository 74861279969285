import {
  Support,
  Flex,
  Container as DSContainer,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { EcommI18nLinkButton } from '@ecomm/internationalize-ui/EcommI18nLinkButon';
import Markdown from '@page-builder/utils/Markdown';

export type HeadbandCta = Partial<{
  text: string;
  href: string;
  onClick: () => void;
}>;

export type HeadbandCtaWithInfoProps = {
  cta?: HeadbandCta;
  dataTestId?: string;
  renderCustomCta?: () => React.ReactNode;
  infoHeading?: string;
  infoText?: string;
};

const HeadbandCtaWithInfo: React.FC<
  React.PropsWithChildren<HeadbandCtaWithInfoProps>
> = ({ cta, dataTestId, renderCustomCta = () => null, infoHeading, infoText }) => {
  const hasInfo = infoHeading || infoText;
  const infoId = 'headband-cta-info';

  return (
    <StyledWrapper>
      <Flex justifyContent="flex-end" alignItems="center" flexGrow={1}>
        {/* Info */}
        {hasInfo && (
          <DSContainer
            minWidth="196px"
            maxWidth="262px"
            padding="0 15px 0 0"
            textAlign="right"
            style={{ flexDirection: 'column', alignItems: 'flex-end' }}
            display={{ mobile: 'none', desktop: 'flex' }}
            id={infoId}
          >
            {infoHeading && (
              <Support is="p" size="medium">
                <Markdown content={infoHeading} />
              </Support>
            )}
            {infoText && (
              <Support is="p" size="small">
                <Markdown content={infoText} />
              </Support>
            )}
          </DSContainer>
        )}

        {/* Custom CTA */}
        {renderCustomCta()}

        {/* CTA */}
        {cta?.href && cta?.text ? (
          <StyledLinkButton>
            <EcommI18nLinkButton
              href={cta.href}
              size="small"
              width="adjustable"
              margin={`${spacing[8]} 0;`}
              onClick={cta.onClick}
              text={cta.text}
              aria-describedby={hasInfo ? infoId : undefined}
              data-test-id={dataTestId || 'headband-cta'}
            />
          </StyledLinkButton>
        ) : null}
      </Flex>
    </StyledWrapper>
  );
};

export default HeadbandCtaWithInfo;

const StyledWrapper = styled.div`
  flex-shrink: 0;
`;

const StyledLinkButton = styled.div`
  flex-shrink: 0;
`;
