import { grey, white, spacing } from '@pelotoncycle/design-system';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { focusHighlight } from '@ecomm/styles';

export const ModalContent = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
`;

export const StickyHeader = styled.div`
  z-index: 1000;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing[16]};
  width: 100%;
  height: ${spacing[48]};
  background-color: ${white};
  border-bottom: 1px solid ${grey[40]};
  ${media.tabletLarge`
    height: ${spacing[72]};
    padding: 0 ${spacing[24]};
  `}
  ${media.desktopLarge`
    padding: 0 ${spacing[32]};
  `}
`;

export const StyledModal = styled(ReactModal)`
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

export const CloseButton = styled.button`
  display: flex;
  ${focusHighlight}
`;

/* placeholder section css, to be removed later */
export const SectionWrapper = styled.div`
  ${media.tabletLarge`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${grey[40]};
    }`}
`;

export const Section = styled.div`
  width: 100vw;
  display: grid;
  place-items: center;

  background-color: ${white};
`;

/*
 * This fixes a silly iOS bug where the cursor is put outside of the input.
 *
 * The bug happens only to the first fixed element on the page, so putting an empty fixed
 * element on the page before the modal fixes the issue.
 *
 */
export const Fixed = styled.div`
  background: ${white};
  height: 1px;
  position: fixed;
  top: -1px;
  width: 100%;
`;
