import { Display, spacing, grey, Flex, Badge } from '@pelotoncycle/design-system';
import React from 'react';
import { startCase } from '@peloton/text/titleize';
import { useGlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import toBundleType from '@ecomm/graphql-bridge/EquipmentType/toBundleType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { isRentalSlug } from '@ecomm/rentals/models';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import { PromoPill } from '@page-builder/modules/Promo';
import Markdown from '@page-builder/utils/Markdown';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';
import { useColorSelectionContext } from '../ColorSelectionContext';
import { colorSlugMap } from '../constants';

export type Props = {
  ribbon?: string;
  title: string;
};

const Header: React.FC<React.PropsWithChildren<Props>> = ({ ribbon, title }) => {
  const { globalTheme } = useGlobalUiStateContext();
  const { activeColorSlug, hasColorSelection } = useColorSelectionContext();
  const { packageSlug, productPackage, productPackageLoading } = useShopContext();
  const colorProductTitle =
    activeColorSlug && startCase(colorSlugMap[activeColorSlug].displayName);
  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);
  const shouldUseContentPromo = useIsToggleActive()('usePromoContentMgmt');

  // as of now, we don't have build time data for FaaS
  // we can check if the package is a rental, and pull it from the context when readys
  const isRentalReady = isRentalSlug(packageSlug) && !productPackageLoading;
  const rentalPackage = isRentalReady && productPackage;

  const packageData = buildTimeProductData || rentalPackage;

  // Prevent initial badge render when we expect to change its color on load of CFU data
  const globalThemeLoaded = !(hasColorSelection && productPackageLoading);

  return (
    <Flex
      flexDirection="column"
      data-test-id="hero-overview-header"
      alignItems="flex-start"
      gap={spacing[12]}
    >
      {ribbon
        ? globalThemeLoaded && (
            <Badge variant="horizontal" theme={globalTheme || 'accent'}>
              {ribbon}
            </Badge>
          )
        : shouldUseContentPromo &&
          packageData && (
            <PromoPill
              cfu={toBundleType(packageData.equipmentType as EquipmentType)!}
              bundleSlug={packageData.slug}
            />
          )}
      <Markdown
        content={title}
        values={{ color: colorProductTitle }}
        markdown={{
          renderers: {
            paragraph: ({ node, ...props }) => (
              <Display as="h2" size="small" color={grey[90]}>
                {props.children}
              </Display>
            ),
          },
        }}
      />
    </Flex>
  );
};

export default Header;
