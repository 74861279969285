import { Card, Eyebrow, Flex, Headline, spacing } from '@pelotoncycle/design-system';
import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import InfiniteScrollCarousel from '../InfiniteScrollCarousel';
import { cellSizes } from './utils';

type Image = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
  alt?: string;
  type?: string;
};

export type Props = {
  title: ReactNode;
  shortDescription: ReactNode;
  images?: Image[];
};

const FeaturedSpacesGeneric: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  shortDescription,
  images,
}) => {
  return (
    <div>
      <Flex
        gap={spacing[16]}
        flexDirection="column"
        verticalMargin={{
          mobile: `${spacing[48]} ${spacing[56]}`,
          tablet: `${spacing[64]} ${spacing[56]}`,
          desktop: `${spacing[120]} ${spacing[56]}`,
        }}
        textAlign="center"
        centered
        maxWidth={{ mobile: '330px', tablet: '477px', desktop: '615px' }}
      >
        <Eyebrow size="small"> {title} </Eyebrow>
        <Headline size="small"> {shortDescription} </Headline>
      </Flex>
      {images && (
        <StyledCarousel
          arrowPadding={40}
          name="featured-spaces-carousel"
          cellSizes={cellSizes}
          dots
          arrows={true}
          centerMode={true}
          responsive={[
            {
              breakpoint: BreakpointWidth.smallScreen,
              settings: {
                arrows: false,
                centerMode: true,
              },
            },
          ]}
        >
          {images.map(({ alt, mobile, desktop, tablet }, index) => (
            <StyledCard
              key={index}
              image={{
                alt: alt || '',
                mobile: mobile || '',
                desktop,
                tablet,
              }}
            />
          ))}
        </StyledCarousel>
      )}
    </div>
  );
};

export default FeaturedSpacesGeneric;

export const StyledCard = styled(Card)`
  box-shadow: none;

  img {
    pointer-events: none;
  }
`;

export const StyledCarousel = styled(InfiniteScrollCarousel)`
  margin-bottom: ${spacing[120]};

  .slick-arrow {
    top: calc(50% - 50px);
  }

  .slick-bottom {
    padding-top: ${spacing[24]};
  }

  @media (max-width: ${BreakpointWidth.tabletXLarge}px) {
    margin-top: ${spacing[40]};
    margin-bottom: ${spacing[80]};
  }

  @media (max-width: ${BreakpointWidth.smallScreen}px) {
    margin-top: ${spacing[32]};
  }

  .slick-track {
    @media (min-width: ${BreakpointWidth.tablet}px) {
      margin-left: 50vw;
    }
  }
`;
