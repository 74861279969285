import React from 'react';
import styled from 'styled-components';
import useBannerReference from '@acme-ui/global/hooks/useBannerReference';
import EcommBanner from '@ecomm/cms-promo-banner/EcommBanner';
import type { TypeComponentBannerFields } from '@page-builder/lib/types';
import BannerContainer from './components/BannerContainer';

export type Props = Omit<TypeComponentBannerFields, 'text'> & {
  text: JSX.Element | string;
};

const UnclickableBanner: React.FC<React.PropsWithChildren<Props>> = ({ text, theme }) => {
  const bannerRef = useBannerReference();

  return (
    <BannerContainer theme={theme}>
      <WrapBalancedDiv>
        <EcommBanner ref={bannerRef} clickable={false} theme={theme}>
          {text}
        </EcommBanner>
      </WrapBalancedDiv>
    </BannerContainer>
  );
};

export default UnclickableBanner;

const WrapBalancedDiv = styled.div`
  @supports (text-wrap: balance) {
    text-wrap: balance;
  }
  p {
    font-weight: 400;
    line-height: 20px;
    display: block;
  }
`;
