import ReactModal from 'react-modal';
import getRootElement from './getRootElement';

const setRootElement = () => {
  if (typeof window !== 'undefined') {
    const root = getRootElement();
    if (root) {
      ReactModal.setAppElement(root);
    }
  }
};

export default setRootElement;
