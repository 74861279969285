import { grey, white, accessibility, spacing } from '@pelotoncycle/design-system';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { focusHighlight } from '@ecomm/styles/accessibility';
import type { BreadcrumbProps } from './types';
import { SUBNAV_SIZE, BREADCRUMB_SIZE, BREADCRUMB_GAP, isEven } from './utils';

export const ModalContent = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
`;

export const StickyHeader = styled.div`
  z-index: 1000;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing[16]};
  width: 100%;
  height: ${spacing[48]};
  background-color: ${white};
  border-bottom: 1px solid ${grey[40]};
  ${media.tabletLarge`
    height: ${spacing[72]};
    padding: 0 ${spacing[24]};
  `}
  ${media.desktopLarge`
    padding: 0 ${spacing[32]};
  `}
`;

export const StyledModal = styled(ReactModal)`
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

export const CloseButton = styled.button`
  display: flex;
  ${focusHighlight}
`;

/* placeholder section css, to be removed later */
export const SectionWrapper = styled.div`
  ${media.tabletLarge`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${grey[40]};
    }`}
`;

export const Section = styled.div`
  width: 100vw;
  display: grid;
  place-items: center;

  background-color: ${white};
`;

export const Breadcrumb = styled.button<BreadcrumbProps>`
  all: unset;
  display: none;
  position: fixed;
  cursor: pointer;
  top: ${props =>
    `calc(50vh - ${
      (props.total / 2) * (BREADCRUMB_SIZE + BREADCRUMB_GAP) -
      (isEven(props.total) ? BREADCRUMB_GAP / 2 : 0) -
      props.position * (BREADCRUMB_SIZE + BREADCRUMB_GAP) -
      SUBNAV_SIZE / 2
    }px)`};
  right: 1rem;
  width: ${spacing[8]};
  height: ${spacing[8]};
  border-radius: ${spacing[8]};
  background-color: ${props => (props.active ? grey[90] : grey[50])};
  &:hover {
    background-color: ${grey[70]};
  }
  &:focus-visible {
    background-color: ${grey[70]};
    outline: ${spacing[2]} solid ${accessibility.outline};
  }
  &::after {
    content: '';
    position: absolute;
    top: -${BREADCRUMB_GAP / 2}px;
    left: -${BREADCRUMB_GAP / 2}px;
    width: ${BREADCRUMB_SIZE + BREADCRUMB_GAP}px;
    height: ${BREADCRUMB_SIZE + BREADCRUMB_GAP}px;
  }

  ${media.desktopLarge`
    display: block;
  `}
`;

/*
 * This fixes a silly iOS bug where the cursor is put outside of the input.
 *
 * The bug happens only to the first fixed element on the page, so putting an empty fixed
 * element on the page before the modal fixes the issue.
 *
 */
export const Fixed = styled.div`
  background: ${white};
  height: 1px;
  position: fixed;
  top: -1px;
  width: 100%;
`;
