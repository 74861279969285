import { useEffect, useState } from 'react';

const useWindowPathname = () => {
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  return pathname;
};

export default useWindowPathname;
