import React from 'react';

export const LeftArrow: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 2 Copy">
      <path
        id="Oval Copy 8 (Stroke)"
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.799999C7.60527 0.799999 0.799999 7.60527 0.799999 16C0.799999 24.3947 7.60527 31.2 16 31.2C24.3947 31.2 31.2 24.3947 31.2 16C31.2 7.60527 24.3947 0.799999 16 0.799999ZM16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0Z"
        fill="white"
      />
      <g id="icon / arrow / large">
        <path
          id="Path 59 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8332 23.765L18.966 22.635L12.3296 15.9828L18.9316 9.36501L17.7988 8.235L10.0696 15.9828L17.8332 23.765Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
