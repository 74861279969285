import { hasVariablePricing, toPriceRange } from '@ecomm/accessories/models';
import { Type } from '@ecomm/pg-checkout-commercetools/utils/types';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { AccessoryFieldsFragment } from '@ecomm/product-recommendations/queries/AllProducts.generated';
import { toPriceRangeTuple } from '@ecomm/product-recommendations/utils/productUtils';

import type { SparePartCommerceTools } from '@page-builder/modules/SpareParts/models';

export type SparePart = SparePartCommerceTools | AccessoryFieldsFragment;

export const toProductFromSparePart = (sparePart: SparePart): Product => {
  const price = hasVariablePricing(sparePart)
    ? toPriceRangeTuple(toPriceRange(sparePart))
    : sparePart.price.amount;

  return {
    productType: Type.SPARE_PART,
    slug: sparePart.slug,
    name: sparePart.name,
    description: sparePart.description,
    imageUrl: '',
    images: sparePart.images.map(i => i.src),
    price,
    id: sparePart.id,
    isConfigurable: sparePart.attributes.length > 0,
    attributes: sparePart.attributes,
    variants: sparePart.variants,
  };
};
