import {
  // black,
  Flex,
  grey,
  Label,
  spacing,
  Support,
  white,
} from '@pelotoncycle/design-system';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { FreeClass } from '@peloton/models/PelotonClass';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { useMicroCopy } from '@content/client/microCopy';
import { useVideoTrackEvent } from '@page-builder/modules/ClassesGrid/utils/useVideoTrackEvent';
import FreeClassCard from './FreeClassCard';
import FreeClassCardImageResponsive from './FreeClassCardImageResponsive';

type Props = {
  freeClass: FreeClass;
};

const FreeClassCardDesktop: React.FC<React.PropsWithChildren<Props>> = ({
  freeClass,
}) => {
  const freeClassStream = useMicroCopy('freeClassStream');
  const { imageUrl, h1, instructorName, fitnessDisciplineDisplayName } = freeClass;
  const ariaLabel = instructorName ? `${h1}, ${instructorName}` : h1;

  return (
    <Wrapper>
      <FreeClassCardImageResponsive
        image={toCloudinarySrc({ src: imageUrl, width: 392 })}
      />
      <ClassCardDetailsWrapper aria-label={ariaLabel}>
        <Flex flexDirection="column">
          <StyledLabel size="large">{freeClassStream}</StyledLabel>
          <Label size="extraLarge" data-test-id="card-title">
            {h1}
          </Label>
          <StyledSupport size="medium" is="span" data-test-id="instructor-discipline">
            {instructorName} • {fitnessDisciplineDisplayName}
          </StyledSupport>
        </Flex>
      </ClassCardDetailsWrapper>
    </Wrapper>
  );
};

const FreeClassCardResponsive: React.FC<React.PropsWithChildren<Props>> = ({
  freeClass,
}) => {
  const cardHref = `/classes/video/${freeClass.slug}`;
  const { onClickTrackEvent } = useVideoTrackEvent();

  return (
    <Link href={cardHref} passHref legacyBehavior>
      <a
        href={cardHref}
        onClick={() =>
          onClickTrackEvent({
            parent: 'Prospect Classes Discipline Page',
            parentType: 'Component: Generic List',
            linkName: freeClass.title,
          })
        }
      >
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: (
              <MobileWrapper>
                <FreeClassCard freeClass={freeClass} />
              </MobileWrapper>
            ),
            tablet: <FreeClassCardDesktop freeClass={freeClass} />,
          }}
        />
      </a>
    </Link>
  );
};

export default FreeClassCardResponsive;

const Wrapper = styled.div`
  cursor: pointer;
  background-color: ${grey['80']};
  border-radius: ${spacing['4']};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: calc(100vw - ${spacing['64']});
  min-height: auto;
`;

const ClassCardDetailsWrapper = styled.div`
  border-radius: 0 0 ${spacing['4']} ${spacing['4']};
  color: ${white};
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  padding: ${spacing['24']};
`;

const StyledSupport = styled(Support)`
  margin-top: 4px;
  color: ${grey['40']};
`;

const StyledLabel = styled(Label)`
  margin-bottom: 4px;
`;

const MobileWrapper = styled.div`
  padding: ${spacing['16']};
  border-radius: ${spacing['4']};
  background-color: ${grey['80']};
`;
