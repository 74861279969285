import { grey, spacing, Flex, Headline, Support } from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toImageProps } from '@page-builder/utils/helpers/media';
import Markdown from '@page-builder/utils/Markdown';
import { ModalContentWrapper } from '../ModalContentWrapper/ModalContentWrapper';
import {
  StyledBackgroundImage,
  TextContainer,
  StyledList,
} from '../ModalContentWrapper/styles';

type MembershipModalProps = {
  modalContent: TypeComponentGenericTextWithMediaFields;
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
};

const MembershipModal: React.FC<React.PropsWithChildren<MembershipModalProps>> = ({
  modalContent,
  isOpen,
  openHandler,
  closeHandler,
}) => {
  const { name, text, ctas, media } = modalContent;
  const { eyebrow, body, support } = getTextFieldsWithRequiredKeys(['body'], text);
  const image = toImageProps(media, true);

  const parsedCtas = ctas?.map(cta => ctaExtractData(cta));

  return (
    <ModalContentWrapper
      isOpen={isOpen}
      openHandler={openHandler}
      closeHandler={closeHandler}
      contentLabel={name}
      maxWidth="864px"
    >
      <Flex
        flexDirection={{ mobile: 'column-reverse', desktop: 'row-reverse' }}
        maxWidth={{ mobile: '100%', desktop: '864px' }}
        height="100%"
        backgroundColor={grey[30]}
        borderRadius="5px"
      >
        <TextContainer
          padding={{
            mobile: `${spacing[32]} ${spacing[24]}`,
            tablet: `${spacing[64]} 52px ${spacing[64]} ${spacing[56]}`,
            desktop: `62px 60px 62px ${spacing[56]}`,
          }}
        >
          <Headline size="small" marginBottom={spacing[8]} is="h3" textColor={grey[90]}>
            {eyebrow}
          </Headline>
          <Markdown
            content={body}
            markdown={{
              renderers: {
                paragraph: ({ node, ...props }) => (
                  <Support
                    is="h4"
                    size="medium"
                    marginBottom={spacing[32]}
                    textColor={grey[70]}
                  >
                    {props.children}
                  </Support>
                ),
                list: ({ node, ...props }) => (
                  <StyledList
                    items={props.children}
                    size="small"
                    textColor={grey[70]}
                    listStyle="none"
                    marginBottom={spacing[32]}
                    gap={spacing[12]}
                  />
                ),
              },
            }}
          />
          <div data-test-id="modal-cta">
            <LinkButtons
              ctas={parsedCtas}
              name={name}
              size="medium"
              margin={`0 0 ${spacing[24]} 0;`}
              hasDropShadow={false}
              horizontalAlignment="left"
            />
          </div>
          {support && (
            <Markdown
              content={support}
              markdown={{
                renderers: {
                  root: ({ node, ...props }) => (
                    <StyledSupport
                      is="h5"
                      size="medium"
                      textColor={grey[70]}
                      marginBottom={{ mobile: 0, desktop: spacing[24] }}
                    >
                      {props.children}
                    </StyledSupport>
                  ),
                  link: ({ node, ...props }) => (
                    <Link
                      variant="body"
                      size="small"
                      href={props.href}
                      to={props.href}
                      children={props.children}
                    />
                  ),
                },
              }}
            />
          )}
        </TextContainer>
        <StyledBackgroundImage
          mobile={image?.mobile}
          tablet={image?.tablet || image?.mobile}
          desktop={image?.desktop || image?.mobile}
        />
      </Flex>
    </ModalContentWrapper>
  );
};
const StyledSupport = styled(Support)`
  a {
    text-decoration: none;
  }
`;

export default MembershipModal;
