import type { ResponsiveCellDimensions } from '@ecomm/carousel/InfiniteScrollCarousel';

export const cellSizes: Required<ResponsiveCellDimensions> = {
  mobile: {
    width: 166,
    height: 184,
    spacing: 8,
  },
  tablet: {
    width: 280,
    height: 320,
    spacing: 12,
  },
  desktop: {
    width: 280,
    height: 320,
    spacing: 12,
  },
};
