import { Container, Support, grey, spacing } from '@pelotoncycle/design-system';
import type { TagLink } from 'contentful';
import React from 'react';
import type { ElementType } from 'react';
import styled from 'styled-components';
import useTagHandler from '@page-builder/hooks/useTagHandler';
import type {
  TypeComponentLegalText,
  TypeComponentLegalTextFields,
} from '@page-builder/lib/types';
import Markdown from '@page-builder/utils/Markdown';

const ListItem: ElementType = props => <Support as="li" {...props} />;
const SupportItem: ElementType = props => <Support as="p" display="block" {...props} />;

type Props = TypeComponentLegalTextFields & {
  tags: TagLink[] | string[];
};

export const LegalTextFieldsBlock: React.FC<React.PropsWithChildren<Props>> = ({
  tags,
  text,
}) => {
  const { resolvedStatus } = useTagHandler(tags);
  return resolvedStatus ? (
    <InnerContainer>
      <Markdown
        content={text}
        markdown={{
          renderers: {
            paragraph: SupportItem,
            listItem: ListItem,
          },
        }}
      />
    </InnerContainer>
  ) : null;
};

const LegalTextBlock: React.FC<React.PropsWithChildren<TypeComponentLegalText>> = ({
  fields,
  metadata,
}) => <LegalTextFieldsBlock {...fields} tags={metadata.tags || []} />;

export default LegalTextBlock;

const InnerContainer = styled(Container)`
  padding-block-end: ${spacing[16]};

  h2,
  li {
    max-width: 1000px;
    color: ${grey['70']};
  }

  ol {
    list-style: decimal;
    li {
      display: list-item;
    }
  }

  a {
    text-decoration: underline;
  }

  &:last-child {
    border-block-end: solid 1px ${grey['40']};
    padding-block-end: ${spacing[32]};
  }
`;
