import { spacing, white, Icon, BreakpointWidths } from '@pelotoncycle/design-system';
import React, { useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import type {
  Playlist as PlaylistType,
  Album as AlbumType,
  Song as SongType,
} from '@peloton/models/PelotonClass';
import { defaultTransition } from '@peloton/styles';
import { b3, reg } from '@ecomm/typography';
import {
  getMaxRows,
  getVisibleRowsCount,
  getHeight,
  SONG_ROW_HEIGHT,
} from '../utils/playlistHelpers';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';
import { ClassDetailsPlaylistSong } from './ClassDetailsPlaylistSong';

type Props = {
  namespace?: string;
  playlist: PlaylistType;
  playlistLabel: string;
  viewMoreLabel: string;
  viewLessLabel: string;
  numColumns?: number;
};

export const groupAlbumsBySong = (albums: AlbumType[], songs: SongType[]) => {
  const albumsBySong = songs.reduce((acc, song) => {
    if (song.title in acc) return acc;
    const matchingAlbum = albums.find(album => album.imageUrl === song.album.imageUrl);
    acc[song.title] = matchingAlbum;
    return acc;
  }, {});

  return albumsBySong;
};

/*
 1. The song data (Walk Phase) don’t seem to include any album reference in their model,
    so let’s fallback on the matching top albums for now.
 */
export const ClassDetailsPlaylist: React.FC<React.PropsWithChildren<Props>> = ({
  namespace,
  playlist,
  playlistLabel,
  viewMoreLabel,
  viewLessLabel,
  numColumns = 1,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(prevOpen => !prevOpen);

  const { topAlbums, songs } = playlist;

  /* [1] */
  const albumsBySong = useMemo(() => groupAlbumsBySong(topAlbums, songs), [
    topAlbums,
    songs,
  ]);

  const maxRows = getMaxRows(numColumns);
  const hiddenItemsCount = maxRows * numColumns;

  const hasMoreSongs = songs.length > maxRows * numColumns;

  const visibleRowsCount = getVisibleRowsCount(
    isOpen || !hasMoreSongs,
    songs.length,
    numColumns,
  );

  const height = getHeight(visibleRowsCount);

  const songRefs = useRef<(HTMLLIElement | null)[]>([]);
  songRefs.current = [];

  useEffect(() => {
    if (!isOpen) return;

    songRefs.current?.[hiddenItemsCount]?.focus({
      preventScroll: true,
    });
  }, [isOpen]);

  return (
    <ClassDetailsCardSection title={playlistLabel} namespace={namespace}>
      {/* eslint-disable-next-line styled-components-a11y/no-redundant-roles */}
      <Playlist
        id={`${namespace || ''}playlist-songs`}
        role="list"
        height={height}
        data-numcolumns={numColumns}
      >
        {songs.map((song, idx) => {
          const visible = isOpen || idx < hiddenItemsCount;
          return (
            <ClassDetailsPlaylistSong
              key={idx}
              namespace={namespace}
              song={song}
              matchingAlbum={albumsBySong[song.title]} /* [1] */
              idx={idx}
              visible={visible}
              ref={el => {
                songRefs.current[idx] = el;
              }}
            />
          );
        })}
      </Playlist>
      {hasMoreSongs && (
        <AccordionButton
          aria-label={`${isOpen ? viewLessLabel : viewMoreLabel}: ${playlistLabel}`}
          aria-expanded={isOpen}
          aria-controls={`${namespace || ''}playlist-songs`}
          onClick={toggleOpen}
          id={`${namespace || ''}playlist-accordion`}
          data-test-id="playlist-accordion"
        >
          {isOpen ? viewLessLabel : viewMoreLabel}
          <Icon
            name="chevron"
            primaryColor={white}
            rotate={isOpen ? 90 : 270}
            aria-hidden="true"
          />
        </AccordionButton>
      )}
    </ClassDetailsCardSection>
  );
};

const Playlist = styled.ul<{
  height: number;
  'data-numcolumns': number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    props['data-numcolumns'] > 1 ? `repeat(${props['data-numcolumns']}, 1fr)` : '100%'};
  gap: ${spacing[24]};
  overflow: hidden;
  height: ${props => props.height}px;

  li {
    /* Maintains uniform column width w/ ellipsis */
    overflow: hidden;
    /* Required (for single column) because of overflow: hidden */
    height: ${SONG_ROW_HEIGHT}px;
  }

  ${defaultTransition('height', 350)}
`;

const AccordionButton = styled.button`
  ${b3};
  ${reg};

  color: ${white};
  padding: ${spacing[24]} ${spacing[24]} 0;
  width: 100%;
  display: flex;
  gap: ${spacing[8]};
  justify-content: center;
  align-items: center;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    padding-top: ${spacing[32]};
  }
`;
