import { BreakpointWidths } from '@pelotoncycle/design-system';
import type { Asset } from 'contentful';
import type { Options } from '@peloton/images-ui';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';

export type ImageCloudinaryOptions = Partial<
  Record<'mobile' | 'tablet' | 'desktop', Options>
>;

type CloudinaryTransformerOpts = {
  asset: Asset;
  breakpoint: keyof typeof BreakpointWidths;
  skipCloudinary?: boolean;
  imageCloudinaryOptions: ImageCloudinaryOptions;
};

export const imageWidths = {
  ...BreakpointWidths,
  mobile: 600,
};

export const toUrlOrBreakpointCloudinarySrc = ({
  asset: {
    fields: {
      file: { url: src },
    },
  },
  breakpoint,
  skipCloudinary,
  imageCloudinaryOptions,
}: CloudinaryTransformerOpts) =>
  skipCloudinary
    ? src
    : toCloudinarySrc({
        src,
        width: imageWidths[breakpoint],
        ...imageCloudinaryOptions[breakpoint],
      });
