import { useShowroomData } from '@ecomm/showrooms/hooks';
import {
  getOperationalShowrooms,
  filterOutInvisibleShowrooms,
} from '@ecomm/showrooms/utils';
import { filterShowroomsByLocation, findNearestShowroom } from './../showroomUtils';
import { useIpGeolocation } from './useIpGeolocation';

export const useNearestShowroom = (isEnabled: boolean = true) => {
  const showrooms = useShowroomData() || [];
  const visibleShowrooms = filterOutInvisibleShowrooms(showrooms);
  const operationalShowrooms = getOperationalShowrooms(visibleShowrooms);

  const { position } = useIpGeolocation(isEnabled);
  const nearestShowrooms = filterShowroomsByLocation(operationalShowrooms, position);
  const nearestShowroom = findNearestShowroom(nearestShowrooms);

  if (nearestShowroom) {
    return nearestShowroom;
  }
  return null;
};
