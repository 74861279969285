import { ProductStates } from '@ecomm/product-states/models';
import useRequiresPostalCode from '@page-builder/modules/Overview/ProductAvailability/useRequiresPostalCode';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';

const useCanAddToCart = () => {
  const { isPostalCodeEligible, productBundleType, productPackage } = useShopContext();
  const doesRequirePostalCode = useRequiresPostalCode();

  if (!productBundleType) {
    return false;
  }

  if (doesRequirePostalCode(productBundleType)) {
    return isPostalCodeEligible;
  }

  const availabilityState = productPackage?.availability.state;

  return !availabilityState || availabilityState === ProductStates.Available;
};

export default useCanAddToCart;
