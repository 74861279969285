export const CARD_MEDIA_HEIGHT = {
  desktop: '432px',
  mobile: '265px',
};
export const CARD_WIDTHS = {
  mobile: '304px',
  tablet: '391px',
  desktop: '496px',
};
export const MAX_TEXT_WIDTH = '1224px';
export const MAX_SCROLL_WIDTH = '1384px';

export const CAROUSEL_CLICK_DELAY = 150;
export const OBSERVER_HANDLER_DELAY = 200;
export const INTERSECTION_RATIO = 0.99;
