export const toSlug = (string: string) => {
  try {
    return string
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/(\.|\?|!)/g, '');
  } catch (_e) {
    return string;
  }
};
