import { ComponentContentTypes } from '@page-builder/lib/constants';
import BannerLoader from '../../Banner/Loader';
import HeadbandLoader from '../../Headband/Loader';
import HeroLoader from '../../HeroCarousel/Loader';

export const ContentTypeMap = {
  [ComponentContentTypes.Banner]: BannerLoader,
  [ComponentContentTypes.Headband]: HeadbandLoader,
  [ComponentContentTypes.Hero]: HeroLoader,
  [ComponentContentTypes.GenericList]: HeroLoader,
  [ComponentContentTypes.LeadGenModal]: () => null, // Don't show
};
