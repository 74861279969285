import { ProspectsDiscipline } from '../../../../models/PelotonClass';
import type { Locale } from '../../../models';
import { mapDisciplineToDisplayName } from './mapDisciplineToDisplayName';

type LanguageLocale = 'en' | 'de';

const CLASS_LABEL_OVERRIDES: Partial<
  Record<ProspectsDiscipline, Record<LanguageLocale, string | null>>
> = {
  [ProspectsDiscipline.RUNNING]: { en: null, de: 'Lauf-Kurse' },
  [ProspectsDiscipline.ROWING]: { en: null, de: 'Ruder-Kurse' },
  [ProspectsDiscipline.MEDITATION]: { en: null, de: 'Meditations-Kurse' },
};

export const mapDisciplineToClassDisplayName = (
  discipline: ProspectsDiscipline,
  locale: Locale,
): string => {
  const languageLocale = locale.split('-')[0] as LanguageLocale;

  if (CLASS_LABEL_OVERRIDES?.[discipline]?.[languageLocale]) {
    return CLASS_LABEL_OVERRIDES?.[discipline]?.[languageLocale]!;
  }

  const disciplineDisplayName = mapDisciplineToDisplayName(discipline, locale);

  if (languageLocale === 'de') {
    return `${disciplineDisplayName}-Kurse`;
  }

  return `${disciplineDisplayName} Classes`;
};
