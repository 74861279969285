import type { MediaImageProps } from '@pelotoncycle/design-system';
import {
  Flex,
  grey,
  red,
  Media,
  spacing,
  Support,
  List,
} from '@pelotoncycle/design-system';
import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import NextContentSizedDiv from '@peloton/content-sized-div/NextContentSizedDiv';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import {
  toPriceRange,
  toBundlePriceRange,
  hasVariablePricing,
  hasVariablePricingBundle,
} from '@ecomm/accessories/models';
import { toDollars } from '@ecomm/models';
import type {
  AccessoryCommerceTools,
  ProductItemOfBundle,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import { TRANSITION_SETTINGS } from '@page-builder/modules/Overview/animations';
import type { PageBuilderData } from '@page-builder/modules/Overview/DrawerContentContext';
import type {
  UpsellAccessoryContextProps,
  AccessoryVariantsType,
} from '@page-builder/modules/Overview/UpsellAccessoryContext';
import { isAccessoryBundle } from '@page-builder/modules/Overview/utils';
import Markdown from '@page-builder/utils/Markdown';
import { strikethroughTreatment } from '../../priceUtils';

type Setter<V> = Dispatch<SetStateAction<V>>;

export type Props = UpsellContextProps & {
  accessory: AccessoryCommerceTools | BundleAccessoryType;
  accessoryVariants?: AccessoryVariantsType;
  isExpanded: boolean;
  setIsExpanded: Setter<boolean>;
  isAnimating: boolean;
  handleAnimation: () => void;
  handleTracking: (isSelectedState: boolean) => void;
  image: MediaImageProps;
  setVariantForAccessory: (
    variant?: VariantCommerceTools,
    product?: ProductItemOfBundle,
    index?: string,
  ) => void;
  isSelected: boolean;
  showErrorMessage: boolean;
  setShowErrorMessage: Setter<boolean>;
  upsellItemContent: PageBuilderData['upsellItemContent'][number];
  upsellSectionIntro: PageBuilderData['upsellSectionIntro'];
};

type UpsellContextProps = Omit<
  UpsellAccessoryContextProps,
  'upsellAccessories' | 'upsellAccessoryLoading' | 'clearSelectedVariants'
>;

type PriceProps = {
  includedValueText?: string;
  accessory: AccessoryCommerceTools | BundleAccessoryType;
};

const ORIGINAL_PRICE_STYLING_PROPS = {
  textColor: grey[90],
  style: { fontWeight: 300 },
};

export const Price: React.FC<React.PropsWithChildren<PriceProps>> = ({
  includedValueText,
  accessory,
}) => {
  const toFormattedText = useGetTextFormatter();

  const addCurrency = (amount: number) =>
    toFormattedText('{price, number, currency}', {
      price: toDollars(amount),
    });

  const formatIncludedValueText = (text: string) => {
    return toFormattedText(text, {
      price: toDollars(accessory.price.amount),
    });
  };

  const priceRange = isAccessoryBundle(accessory)
    ? hasVariablePricingBundle(accessory) && toBundlePriceRange(accessory.products)
    : hasVariablePricing(accessory) && toPriceRange(accessory);
  const originalPrice = priceRange
    ? `${addCurrency(priceRange.low)} – ${addCurrency(priceRange.high)}`
    : addCurrency(accessory.price.amount);
  const discountedPrice = accessory.discountPrice
    ? addCurrency(accessory.discountPrice.amount)
    : undefined;
  const formattedIncludedValueText = includedValueText
    ? formatIncludedValueText(includedValueText)
    : undefined;

  if (formattedIncludedValueText || !accessory.discountPrice) {
    const stylingProps = formattedIncludedValueText ? {} : ORIGINAL_PRICE_STYLING_PROPS;

    return (
      <Support size="medium" {...stylingProps}>
        <Markdown
          content={(formattedIncludedValueText || originalPrice) as string}
          markdown={{
            renderers: {
              emphasis: props => <Support textColor={red[80]} {...props} />,
              strong: props => (
                <Support textColor={red[80]} style={{ fontWeight: 400 }} {...props} />
              ),
            },
          }}
        />
      </Support>
    );
  } else {
    return (
      <Flex flexDirection="row">
        <PriceStrikethroughSupport size="medium">
          <DiscountedPrice>{discountedPrice}</DiscountedPrice>
          <OriginalPrice>{originalPrice}</OriginalPrice>
        </PriceStrikethroughSupport>
      </Flex>
    );
  }
};

export const HeightTransition = styled(NextContentSizedDiv)<{
  $isExpanded: boolean;
  $isAnimating: boolean;
}>`
  transition: ${TRANSITION_SETTINGS};
  overflow: ${props =>
    props.$isExpanded && !props.$isAnimating ? 'unset' : 'hidden'} !important;
`;

export const StyledList = styled(List)`
  li {
    font-size: 13px;
  }
  padding-left: ${spacing[16]};
`;

export const StyledMedia = styled(Media)`
  img {
    height: ${spacing[80]};
    width: ${spacing[80]};
  }
`;

const DiscountedPrice = styled.strong`
  && {
    font-weight: 400;
    padding-right: ${spacing[4]};
  }
`;

const OriginalPrice = styled.del``;

const PriceStrikethroughSupport = styled(Support)`
  ${strikethroughTreatment};
`;

export const Divider = styled.hr<{ topPadding: number; borderColor?: string }>`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${props => props.borderColor || grey[40]};
  padding-top: ${props => spacing[props.topPadding]};
`;
