import { brand, grey, spacing, Flex, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  containerProps: React.ComponentProps<typeof Flex>;
  labelText?: string;
  CtaComponent?: React.JSX.Element | null;
};

const DrawerCtaBox: React.FC<React.PropsWithChildren<Props>> = ({
  containerProps,
  labelText,
  CtaComponent = null,
}) => {
  return (
    <Flex
      backgroundColor={grey[30]}
      position="relative"
      style={{ zIndex: 2 }} // Ensure cursor is expected
      padding={`${spacing[4]} ${spacing[12]}`}
      verticalMargin={`${spacing[8]} 0`}
      borderRadius={spacing[4]}
      justifyContent="center"
      {...containerProps}
    >
      <Flex alignItems="center" gap={spacing[8]}>
        {labelText && (
          <StyledLabel>
            <Markdown
              content={labelText}
              markdown={{
                renderers: {
                  emphasis: ({ node, ...props }) => (
                    <span style={{ color: brand.primary }}>{props.children}</span>
                  ),
                },
              }}
            />
          </StyledLabel>
        )}
        {CtaComponent}
      </Flex>
    </Flex>
  );
};

const StyledLabel = styled(Label)`
  padding-top: ${spacing[2]};
  color: ${grey[90]};
`;

export default DrawerCtaBox;
