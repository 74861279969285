import type { MediaImageProps, MediaVideoProps } from '@pelotoncycle/design-system';
import { OverlaidChildren, Media, spacing, Flex } from '@pelotoncycle/design-system';
import { LinkButtons, OuterContainer, ctaExtractData } from '@pelotoncycle/page-builder';
import type { ReactNode, CSSProperties } from 'react';
import React from 'react';
import { UpperTextBlock, LowerTextBlock } from '@page-builder/components';
import type {
  UpperTextBlockTextProps,
  LowerTextBlockTextProps,
} from '@page-builder/components';
import type {
  TypeComponentCta,
  TypeComponentTextWithInlineMediaFields,
} from '@page-builder/lib/types';
import type { Themes } from '@page-builder/themes';
import { themes } from '@page-builder/themes';

export type Props = {
  id?: string;
  name?: string;
  className?: string;
  ctas?: TypeComponentCta[];
  text?: TypeComponentTextWithInlineMediaFields['text'];
  image?: MediaImageProps;
  minHeight?: CSSProperties['minHeight'];
  textAlign?: 'left' | 'center' | 'right';
  theme?: Themes;
  video?: MediaVideoProps;
  beforeTextContent?: ReactNode;
  afterTextContent?: ReactNode;
} & UpperTextBlockTextProps &
  LowerTextBlockTextProps;

const defaults = {
  theme: 'Grey 10',
  textAlign: 'center',
};

/**
 * @deprecated prefer import { FullWidthText } from '@pelotoncycle/page-builder'
 */

const FullWidthText = React.forwardRef<HTMLDivElement, Props>(function FullWidthText(
  {
    id,
    name,
    className,
    ctas,
    text,
    minHeight,
    textAlign = defaults.textAlign,
    theme = defaults.theme,
    image,
    video,
    beforeTextContent,
    afterTextContent,
    ...props
  },
  ref,
) {
  const media = image || video;
  const { buttonColor, backgroundColor, headlineColor, textColor } = themes[theme];
  const { eyebrowText, headlineText, bodyText, supportText, labelText } = props;
  const ctasWithTheme = ctas?.map(cta => {
    const parsedCta = ctaExtractData(cta);
    return {
      ...parsedCta,
      color: parsedCta.color ? parsedCta.color : buttonColor,
    };
  });
  /**
   * this is because of `beforeTextContent`, which is legacy and should be removed
   * if we can remove this (and `afterTextContent`) we can remove this condition
   */
  const adjustedPadding = !beforeTextContent ? spacing[120] : `0 ${spacing[120]}`;

  return (
    <OverlaidChildren minHeight={minHeight} id={id} className={className} ref={ref}>
      {!!media ? <Media media={media} /> : null}
      <OuterContainer
        id={id}
        className={className}
        ref={ref}
        backgroundColor={media ? 'transparent' : backgroundColor}
        horizontalPadding={spacing[32]}
        verticalPadding={{
          mobile: spacing[48],
          tablet: `${spacing[64]} ${spacing[80]}`,
          desktop: adjustedPadding,
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          removeOuterMargins
        >
          {/* should this be removed? */}
          {beforeTextContent}
          {(eyebrowText || headlineText || bodyText) && (
            <UpperTextBlock
              eyebrowText={eyebrowText}
              eyebrowTextColor={headlineColor}
              headlineText={headlineText}
              headlineTextColor={headlineColor}
              bodyText={bodyText}
              bodyTextColor={textColor}
              margin={{
                mobile: `0 0 ${spacing[32]}`,
                desktop: `0 0 ${spacing[40]}`,
              }}
              hasDropShadow={!!media}
            />
          )}
          {ctas && (
            <LinkButtons
              buttonColor={buttonColor}
              ctas={ctasWithTheme}
              hasDropShadow={!!media}
              parentId={id}
              parentName={name}
            />
          )}
          {(supportText || labelText) && (
            <LowerTextBlock
              supportText={supportText}
              labelText={labelText}
              textColor={textColor}
              margin={{
                mobile: `${ctas ? spacing[32] : 0} 0 0`,
                desktop: `${ctas ? spacing[40] : 0} 0 0`,
              }}
              hasDropShadow={!!media}
            />
          )}
          {/* should this be removed? */}
          {afterTextContent}
        </Flex>
      </OuterContainer>
    </OverlaidChildren>
  );
});

export default FullWidthText;
