import type { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import { StatusCode } from '@peloton/next/api/status';
import { NextRedirectError } from '@peloton/next/errors/NextRedirectError';
import { DISCIPLINE_SLUGS } from '../models/constants';

export const validateDisciplinePath = ({
  discipline,
  path,
}: {
  discipline: ProspectsDiscipline;
  path: string;
}) => {
  const [classSlug, disciplineSlug, ...remainingSlugParts] = path.split('/').reverse();
  const isUnregisteredDiscipline = !(discipline in DISCIPLINE_SLUGS);

  if (isUnregisteredDiscipline) {
    return;
  }

  const expectedDisciplineSlug = DISCIPLINE_SLUGS[discipline];
  const hasWrongDisciplineSlug = disciplineSlug !== expectedDisciplineSlug;

  if (hasWrongDisciplineSlug) {
    throw new NextRedirectError({
      statusCode: StatusCode.FOUND, // To remove replace with `PERMANENT_REDIRECT` once confirmed.
      currentPath: path,
      newPath: [classSlug, expectedDisciplineSlug, ...remainingSlugParts]
        .reverse()
        .join('/'),
    });
  }
};
