import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import useSubHeaderReference from '@acme-ui/global/hooks/useSubHeaderReference';
import { Spinner } from '@ecomm/spinner';
import { StyledContainer as Container } from './HeadbandContainer';

const HeadbandLoader = () => {
  const headbandRef = useSubHeaderReference();
  return (
    <Container
      ref={headbandRef}
      justifyContent="space-between"
      alignItems="center"
      padding={{
        mobile: `0 ${spacing[16]}`,
        tablet: `0 ${spacing[24]}`,
        desktop: `0 ${spacing[32]}`,
      }}
      position="sticky"
    >
      <Spinner />
    </Container>
  );
};
export default HeadbandLoader;
