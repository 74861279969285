import { Flex, Icon, Support, grey, spacing } from '@pelotoncycle/design-system';
import Script from 'next/script';
import React from 'react';
import styled from 'styled-components';
import { Locale, useLocale } from '@peloton/internationalize';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type FinancingCtaProps from './FinancingCtaProps';

const KLARNA_WEB_SDK_URL = 'https://js.klarna.com/web-sdk/v1/klarna.js';

// these client id's match the "production" environment
// however, from klarna, there is no downside to using prod details for OSM
const productionDataClientIdMap = {
  [Locale.EnglishUnitedKingdom]: '53df18df-a0cb-57f1-9c49-635e3e9779d0',
  [Locale.GermanGermany]: '28797fe5-de7f-58b0-890e-bd98f1410097',
};

const evergreenPaymentMethodIds = ['pi3', 'pl30', 'fin12'];
// when klarna has a promotion, we need to change these ids passed into the placement
const standardPaymentMethodIds = ['fin24', 'fin36'];
const promoPaymentMethodIds = ['Promo24', 'Promo36'];

const KlarnaPlacement: React.FC<{
  locale: Locale;
  price: number;
  onClick: () => void;
}> = ({ locale, price, onClick }) => {
  const isToggleActive = useIsToggleActive();
  const isKlarnaPromotionActive = isToggleActive('klarnaZeroAprPromotion');

  const klarnaRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    const current = klarnaRef.current;
    if (current) {
      current.addEventListener('click', onClick);

      return () => {
        current.removeEventListener('click', onClick);
      };
    }
    return () => {};
  }, [onClick]);

  const paymentMethodIds = evergreenPaymentMethodIds
    .concat(isKlarnaPromotionActive ? promoPaymentMethodIds : standardPaymentMethodIds)
    .join(',');

  const props = {
    id: 'klarna-price-merchandising',
    ['data-key']: 'credit-promotion-auto-size',
    ['data-locale']: locale,
    ['data-test-id']: 'klarna-price-merchandising-placement',
    // this is required for merchants with promo codes
    // "multiple can be called to allow the system to auto decide what may be the best for conversions"
    // for example, pay in 3 is limited to 2k GBP or EUR, but will still not show if the price is over this amount
    // there cannot be spaces between the commas
    ['data-custom-payment-method-ids']: paymentMethodIds,
    ['data-purchase-amount']: price,
    style: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return React.createElement('klarna-placement', { ref: klarnaRef, ...props });
};

const KlarnaScript: React.FC<{ locale: Locale }> = ({ locale }) => {
  return (
    <Script
      async
      data-environment="production"
      src={KLARNA_WEB_SDK_URL}
      data-client-id={productionDataClientIdMap[locale]}
    />
  );
};

const KlarnaPriceMerchandisingSection: React.FC<{
  locale: Locale;
  onClick: () => void;
  price?: number;
}> = ({ locale, onClick, price }) => {
  if (!Object.keys(productionDataClientIdMap).find(loc => loc === locale) || !price)
    return null;

  return (
    <StyledFlex>
      <KlarnaPlacement locale={locale} price={price} onClick={onClick} />
    </StyledFlex>
  );
};

const KlarnaCta: React.FC<React.PropsWithChildren<FinancingCtaProps>> = ({
  cta,
  isDrawerFinancingSection,
  onClick,
  financingPriceOverride,
}) => {
  const { text, ctaIcon } = cta;
  const locale = useLocale();

  return (
    <>
      <KlarnaScript locale={locale} />
      {!isDrawerFinancingSection && (
        <Support size="medium" is="span">
          {text}
          {ctaIcon && <KlarnaLogo name={ctaIcon} height={18} title="Klarna" />}
        </Support>
      )}
      {isDrawerFinancingSection && (
        <KlarnaPriceMerchandisingSection
          locale={locale}
          onClick={onClick}
          price={financingPriceOverride}
        />
      )}
    </>
  );
};

const KlarnaLogo = styled(Icon)`
  vertical-align: top;
  margin-left: ${spacing[4]};
`;

export default KlarnaCta;

const StyledFlex = styled(Flex)`
  padding: ${spacing[4]};

  // background color
  #klarna-price-merchandising::part(osm-container) {
    border-radius: ${spacing[4]};
    border-color: ${grey[30]};
    background-color: ${grey[30]};
    padding: 0;
  }

  // logo
  #klarna-price-merchandising::part(osm-logo) {
    display: none;
  }

  // legal
  #klarna-price-merchandising::part(osm-legal) {
    display: none;
  }

  // hides text node
  #klarna-price-merchandising::part(osm-message) {
    visibility: hidden;
    line-height: 0;
    font-size: 0;
  }

  // link
  #klarna-price-merchandising::part(osm-cta) {
    visibility: visible;
    display: block;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    color: ${grey[90]};
  }
`;
