import React from 'react';

export type CFUPackageContextProps = {
  shouldUseCTProvider: boolean;
};

export const CFUPackageContext = React.createContext<CFUPackageContextProps>({
  shouldUseCTProvider: false,
});

export const useCFUPackageContext = () => React.useContext(CFUPackageContext);
