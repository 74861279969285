import React from 'react';
import type {
  TypeComponentGenericText,
  TypeComponentGenericTextFields,
} from '@page-builder/lib/types';
import WithMarkdown from '@page-builder/modules/WithMarkdown';

type RequiredTextField = keyof Omit<TypeComponentGenericTextFields, 'name'>;
type RequiredTextFields = RequiredTextField[];

const getGenericTextNodes = (
  text?: TypeComponentGenericText,
  fields: RequiredTextFields = [],
) => {
  if (!text) {
    return {
      eyebrow: undefined,
      headline: undefined,
      body: undefined,
      support: undefined,
      label: undefined,
    };
  }

  fields.forEach(field => {
    if (!text.fields[field]) {
      throw new Error(`Missing required fields: ${field}`);
    }
  });

  const { eyebrow, headline, body, support, label } = text.fields;

  return {
    ...(eyebrow && {
      eyebrow: <WithMarkdown text={eyebrow} renderers={{ paragraph: React.Fragment }} />,
    }),
    ...(headline && {
      headline: (
        <WithMarkdown text={headline} renderers={{ paragraph: React.Fragment }} />
      ),
    }),
    ...(body && {
      body: <WithMarkdown text={body} renderers={{ paragraph: React.Fragment }} />,
    }),
    ...(support && {
      support: <WithMarkdown text={support} renderers={{ paragraph: React.Fragment }} />,
    }),
    ...(label && {
      label: <WithMarkdown text={label} renderers={{ paragraph: React.Fragment }} />,
    }),
  };
};

export default getGenericTextNodes;
