import {
  grey,
  Eyebrow,
  Headline,
  Flex,
  Grid,
  spacing,
  Icon,
  white,
  accessibility,
  black,
  Label,
} from '@pelotoncycle/design-system';
import type { LinkButtonProps } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import { ThemeProvider, useTheme } from '@page-builder/themes/ThemeProvider';
import type {
  ImageReferenceType as ImageReference,
  ProductReferenceType as ProductReference,
} from '../../../types/referenceTypes';
import { ProductCard } from './ProductCard';
import { RatingsProvider } from './RatingsProvider';
import { getGridRowCount, getValuePropsLength } from './utils';

export type CompareProps = {
  eyebrow?: string;
  headline?: string;
  products: {
    product: ProductReference;
    imageOverride?: ImageReference;
    description?: string;
    financing?: string;
    valueProps?: {
      icon?: string;
      image?: ImageReference;
      headline: string;
      description: string;
    }[];
  }[];
  theme?: 'White' | 'Grey 90';
  expandButton?: string;
  maxValuePropAmount?: number;
};

export const ThemedCompareModule: React.FC<
  React.PropsWithChildren<CompareProps>
> = props => {
  const theme = props.theme || 'Grey 90';
  return (
    <ThemeProvider theme={theme}>
      <RatingsProvider products={props.products}>
        <CompareModule {...props} />
      </RatingsProvider>
    </ThemeProvider>
  );
};

export const CompareModule: React.FC<CompareProps> = ({
  theme,
  products,
  eyebrow,
  headline,
  maxValuePropAmount = 2,
  expandButton,
}) => {
  const { trackLinkClick } = useTrackLinkClick();
  const { backgroundColor, headlineColor, buttonColor } = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasMoreValueProps = React.useMemo(
    () =>
      products.some(product => (product.valueProps?.length || 0) > maxValuePropAmount),
    [products, maxValuePropAmount],
  );
  const showExpandValuePropsButton = !isExpanded && hasMoreValueProps;

  const handleClick = () => {
    trackLinkClick({
      href: '',
      parent: headline || '',
      additionalProps: {
        parentType: 'CompareModule ValueProps',
        linkTo: '',
        page: window.location.pathname,
        unitName: 'Toggle CTA',
        linkName: expandButton,
      },
    });

    setIsExpanded(true);
  };

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      data-test-id="content-cards-container"
      verticalPadding={{
        mobile: spacing[48],
        tablet: spacing[80],
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={{ mobile: spacing[32], desktop: spacing[48] }}
      >
        <Flex flexDirection="column" textAlign="left" maxWidth="100%" gap={spacing[24]}>
          <Flex flexDirection="column" gap={{ desktop: spacing[8] }}>
            {eyebrow && (
              <Eyebrow size="small" textColor={headlineColor}>
                {eyebrow}
              </Eyebrow>
            )}
            {headline && (
              <Headline size="small" textColor={headlineColor}>
                {headline}
              </Headline>
            )}
          </Flex>
          <Grid
            columnCount={products.length}
            columnSize="minmax(0, 1fr)"
            rowCount={getGridRowCount(products, isExpanded, maxValuePropAmount)}
            textAlign="center"
            maxWidth="100%"
            gap={spacing[24]}
          >
            {products.map(product => {
              const valuePropLength = getValuePropsLength(
                isExpanded,
                product,
                maxValuePropAmount,
              );

              return (
                <ProductCard
                  {...product}
                  valueProps={product.valueProps?.slice(0, valuePropLength)}
                  key={product.product.bundleType}
                />
              );
            })}
          </Grid>
        </Flex>
        {showExpandValuePropsButton && (
          <StyledButton
            data-test-id="expand-value-props-btn"
            onClick={handleClick}
            disabled={isExpanded}
            aria-expanded={isExpanded}
            buttonColor={buttonColor}
          >
            <Icon name="plus" primaryColor={headlineColor} />
            <Label size="large" textColor={headlineColor} style={{ fontWeight: 600 }}>
              {expandButton}
            </Label>
          </StyledButton>
        )}
      </Flex>
    </OuterContainer>
  );
};

const StyledButton = styled.button<{ buttonColor: LinkButtonProps['color'] }>`
  backdrop-filter: blur(12px);
  background: ${({ buttonColor }) => (buttonColor == 'light' ? grey[75] : 'transparent')};
  border-radius: 100px;
  display: flex;
  gap: ${spacing[8]};
  padding: ${spacing[12]} ${spacing[24]};
  transition: 400ms opacity;
  width: fit-content;
  outline: none;

  &:hover {
    svg {
      border-radius: 50%;
      background-color: ${({ buttonColor }) =>
        buttonColor == 'light' ? white : 'transparent'};
    }
    path {
      fill: ${({ buttonColor }) => (buttonColor == 'light' ? black : '')};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  [data-whatintent='keyboard'] &:focus {
    outline: 2px solid ${accessibility.outline};
    outline-offset: 4px;
  }
`;
