import type { TypeComponentImageCard } from '@pelotoncycle/page-builder';
import React from 'react';
import useTagHandler from '@page-builder/hooks/useTagHandler';

const ImageCardWrapper: React.FC<React.PropsWithChildren<TypeComponentImageCard>> = ({
  metadata: { tags },
  children,
}) => {
  const { resolvedStatus } = useTagHandler(tags);
  return resolvedStatus ? <>{children}</> : null;
};

export default ImageCardWrapper;
