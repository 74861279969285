import React from 'react';
import { useGetCommercetoolsCFU } from '@ecomm/commercetools/hooks/useGetCommercetoolsCFU';
import type { TypeComponent_overviewFields } from '@page-builder/lib/types';
import { ShopContextProvider } from '@page-builder/modules/Overview/ShopContext';

type CommercetoolsCFUContextProps = Pick<TypeComponent_overviewFields, 'shopDrawers'> & {
  slug: string;
};

const CommercetoolsCFUProvider: React.FC<
  React.PropsWithChildren<CommercetoolsCFUContextProps>
> = ({ children, slug }) => {
  const {
    data: productPackageData,
    loading: productPackageLoading,
  } = useGetCommercetoolsCFU(slug);

  return (
    <ShopContextProvider
      packageSlug={slug}
      productPackage={productPackageData?.catalog?.packageBySlug}
      productPackageLoading={productPackageLoading}
    >
      {children}
    </ShopContextProvider>
  );
};

export default CommercetoolsCFUProvider;
