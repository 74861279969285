import { Media } from '@pelotoncycle/design-system';
import type { MediaImageProps } from '@pelotoncycle/design-system/media';
import React from 'react';
import type { Options } from '@peloton/images-ui';
import { toCloudinaryOrLocalSrc } from '@peloton/images/toCloudinarySrc';

type Props = {
  image: Omit<MediaImageProps, 'type'>;
  options: Options;
  className?: string;
};

// eslint-disable-next-line react/display-name
const DSImageWithCloudinary = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      image: { mobile, tablet, desktop, ...image },
      options: { src, ...options },
      className,
    },
    ref,
  ) => {
    const _options = {
      format: 'webp',
      ...options,
    };

    const media: MediaImageProps = {
      type: 'image',
      mobile: toCloudinary({ src: mobile, ..._options }),
      tablet: tablet ? toCloudinary({ src: tablet, ..._options }) : undefined,
      desktop: desktop ? toCloudinary({ src: desktop, ..._options }) : undefined,
      ...image,
    };

    return <Media className={className} media={media} ref={ref} />;
  },
);

export default DSImageWithCloudinary;

const toCloudinary = (x: Parameters<typeof toCloudinaryOrLocalSrc>[1]) =>
  toCloudinaryOrLocalSrc(false, x);
