import { getPage } from './lib/pages';
import truncateComposePage from './lib/truncateComposePage';
import { getClient } from './pageBuilderClient';

export const fetchPageCopy = async (slug: string, preview: boolean, locale: string) => {
  const client = getClient(Boolean(preview));
  const page = await getPage(client, {
    locale,
    slug,
  });

  // In the future we may choose to apply different transforms based on page type/source
  return page && truncateComposePage(page);
};
