import type { IconNames } from '@pelotoncycle/design-system';
import {
  Body,
  Headline,
  Eyebrow,
  Icon,
  grey,
  spacing,
  black,
} from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import Markdown from '@page-builder/utils/Markdown';
import { ModalContentWrapper } from '../ModalContentWrapper/ModalContentWrapper';
import { ModalContent, FinancingText } from '../ModalContentWrapper/styles';

type OverviewModalProps = {
  modalContent: TypeComponentGenericTextWithMediaFields;
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
};

const OverviewModal: React.FC<React.PropsWithChildren<OverviewModalProps>> = ({
  modalContent,
  isOpen,
  openHandler,
  closeHandler,
}) => {
  const { name, text, ctas } = modalContent;
  const { headline, body, support } = getTextFieldsWithRequiredKeys(['body'], text);

  const parsedCtas = ctas?.map(cta => ctaExtractData(cta));

  return (
    <ModalContentWrapper
      isOpen={isOpen}
      openHandler={openHandler}
      closeHandler={closeHandler}
      contentLabel={name}
      maxWidth="780px"
    >
      <ModalContent
        backgroundColor={grey[30]}
        height="100%"
        width="100%"
        borderRadius="5px"
        padding={{
          mobile: `${spacing[40]} ${spacing[24]}`,
          tablet: `${spacing[48]} ${spacing[64]}`,
          desktop: `${spacing[64]} ${spacing[80]}`,
        }}
      >
        <Headline textColor={grey[90]} size="small" style={{ marginBottom: spacing[24] }}>
          {headline}
        </Headline>
        <Markdown
          content={body}
          markdown={{
            renderers: {
              heading: ({ node, ...props }) => {
                if (props.level === 2) {
                  return (
                    <StyledEyebrow size="small" textColor={grey[90]}>
                      {props.children}
                    </StyledEyebrow>
                  );
                }
                return (
                  <Headline size="small" style={{ marginBottom: spacing[24] }}>
                    {props.children}
                  </Headline>
                );
              },
              list: IconList,
              listItem: ({ node, ...props }) => (
                <IconListItem>
                  <StyledBody textColor={grey[90]} size="small">
                    {props.children}
                  </StyledBody>
                </IconListItem>
              ),
              image: ({ node, ...props }) => (
                <Icon
                  name={props.src as IconNames}
                  height={24}
                  primaryColor={black}
                  style={{
                    position: 'absolute',
                    marginRight: '1rem',
                    verticalAlign: 'text-bottom',
                    top: 0,
                    left: 0,
                  }}
                />
              ),
              paragraph: ({ node, ...props }) => (
                <Body
                  size="small"
                  textColor={grey[90]}
                  style={{ marginBottom: spacing[24], lineHeight: spacing[24] }}
                >
                  {props.children}
                </Body>
              ),
              strong: ({ node, ...props }) => (
                <StyledStrong>{props.children}</StyledStrong>
              ),
            },
          }}
        />
        {ctas && (
          <StyledLinkButtons
            ctas={parsedCtas}
            name={name}
            size="medium"
            hasDropShadow={false}
            horizontalAlignment="left"
            buttonWidth="adaptive"
          />
        )}
        {support && (
          <FinancingText size="small" textColor={grey[70]} display="inline-block">
            <Markdown content={support} />
          </FinancingText>
        )}
      </ModalContent>
    </ModalContentWrapper>
  );
};

export default OverviewModal;

const IconList = styled.ul`
  margin-top: 0;
  display: flex;
  flex-direction: column;
`;

const IconListItem = styled.li`
  position: relative;
`;

const StyledBody = styled(Body)`
  padding-left: ${spacing[40]};
  margin-bottom: ${spacing[24]};
  line-height: ${spacing[24]};
`;

const StyledStrong = styled.strong`
  font-weight: 400 !important;
`;

const StyledEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[24]};
  font-weight: 450;
  line-height: ${spacing[16]};
`;

const StyledLinkButtons = styled(LinkButtons)`
  width: 211px;

  & > div {
    width: 100%;

    & > a {
      font-weight: 450 !important;
    }
  }
`;
