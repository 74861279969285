import { black, grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';

// TODO: delete once props are coming from PB
export const singlePromptMock = 'Ask me anything';
export const rotatingPromptsMock = [
  'Do I need a Peloton Bike to take this class?',
  'How can I help?',
  'What can I do for you?',
];

const lightBoxShadow = '0px 5px 20px 0px';

const darkPurple = '#7F66FF';
const blue = '#4CA6FF';
const mediumPurple = '#D4CCFF';
const lightPurple = '#EAE6FF';
const mobleLightPurple = '#EAE6FF'; //used for mobile fill light 2
const backgroundPurple = '#D9D3FE';
const backgroundBlue = '#BCDAFF';
const backgroundMediumPurple = '#CED6FE';

export const THEMES = {
  'Light 1': {
    outerContainerTheme: 'White',
    backgroundColor: white,
    textColor: black,
    overlayColor: '90deg, rgba(217, 211, 254, 0.2), rgba(188, 218, 255, 0.2)',
    borderColor: `${rgba(darkPurple, 1)}, ${rgba(mediumPurple, 1)}, ${rgba(blue, 1)}`,
    sparkleColor: rgba(darkPurple, 1),
    sendIconColor: grey[90],
    boxShadow: lightBoxShadow,
    boxShadowHover: lightBoxShadow,
    boxShadowColor: rgba(darkPurple, 0.2),
    boxShadowColorHover: rgba(darkPurple, 0.4),
    buttonBackgroundColor: `
    linear-gradient(90deg, ${rgba(backgroundPurple, 0.15)}, ${rgba(
      backgroundBlue,
      0.15,
    )}) ${rgba(white, 0.75)}
    padding-box
    `,
    hoverButtonBackgroundColor: `
    linear-gradient(90deg, ${rgba(backgroundPurple, 0.15)}, ${rgba(
      backgroundBlue,
      0.15,
    )}) ${rgba(white, 0.85)}
    padding-box
    `,
  },
  Dark: {
    outerContainerTheme: 'Grey 90',
    backgroundColor: grey[90],
    textColor: white,
    overlayColor:
      '270deg, rgba(188, 218, 255, 0.2), rgba(206, 214, 254, 0.2), rgba(217, 211, 254, 0.2)',
    borderColor: `rgba(106, 79, 244, 1), ${rgba(lightPurple, 1)}, ${rgba(blue, 1)}`,
    sparkleColor: rgba(lightPurple, 1),
    sendIconColor: white,
    boxShadow: '0px 24px 40px 12px',
    boxShadowHover: '0px 30px 40px 16px',
    boxShadowColor: rgba(black, 0.38),
    boxShadowColorHover: rgba(black, 0.6),
    buttonBackgroundColor: `linear-gradient(90deg, ${rgba(
      backgroundBlue,
      0.15,
    )} 0%, ${rgba(backgroundMediumPurple, 0.15)} 63.5%, ${rgba(
      backgroundPurple,
      0.15,
    )} 100%), ${rgba(grey[90], 0.4)}`,
    hoverButtonBackgroundColor: `linear-gradient(90deg, ${rgba(
      backgroundBlue,
      0.5,
    )} 0%, ${rgba(backgroundMediumPurple, 0.5)} 63.5%, ${rgba(
      backgroundPurple,
      0.5,
    )} 100%),
    ${rgba(grey[90], 0.5)}`,
  },
  'Light 2': {
    outerContainerTheme: 'White',
    backgroundColor: white,
    textColor: black,
    overlayColor: '90deg, rgba(234, 230, 255, 0.5), rgba(234, 230, 255, 0.5)',
    borderColor: `${rgba(darkPurple, 1)}, ${rgba(mediumPurple, 1)}, ${rgba(blue, 1)}`,
    sparkleColor: rgba(darkPurple, 1),
    sendIconColor: grey[90],
    boxShadow: lightBoxShadow,
    boxShadowHover: lightBoxShadow,
    boxShadowColor: rgba(darkPurple, 0.2),
    boxShadowColorHover: rgba(darkPurple, 0.4),
    buttonBackgroundColor: rgba(mobleLightPurple, 0.5),
    hoverButtonBackgroundColor: rgba(mobleLightPurple, 0.5),
  },
};
