import {
  black,
  grey,
  Icon,
  spacing,
  Tooltip,
  white,
  Label,
  Eyebrow,
  Body,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { defaultTransition, media } from '@peloton/styles';

type Props = {
  icon: IconNames;
  name: string;
  value: string;
  type?: 'select' | 'radio' | string;
  tooltip?: string;
  onChange: () => void;
  selected: boolean;
};

export const GenericOptionCard: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  name,
  value,
  type,
  tooltip,
  onChange,
  selected,
}) => {
  const inputType = type === 'select' ? 'checkbox' : 'radio';
  const [toolTipTitle, toolTipBody] = tooltip ? tooltip.split('\n\n') : [];
  const tooltipIconStyles = selected
    ? { primaryColor: grey[90], secondaryColor: white }
    : { primaryColor: white, secondaryColor: grey[90] };

  return (
    <>
      <Input
        id={value}
        type={inputType}
        value={value}
        name={name}
        checked={selected}
        onChange={onChange}
      />
      <Card htmlFor={value} selected={selected}>
        {tooltip && (
          <button
            onClick={e => {
              e.stopPropagation();
            }}
            disabled
          >
            <StyledTooltip
              trigger="hover"
              icon={tooltipIconStyles}
              backgroundColor={white}
              data-test-id={`${name}-tooltip`}
              position="bottom-end"
            >
              <TooltipEyebrow size="small">{toolTipTitle}</TooltipEyebrow>
              <TooltipBody size="small">{toolTipBody}</TooltipBody>
            </StyledTooltip>
          </button>
        )}
        <IconContainer>
          {icon && (
            <IconWrapper>
              <Icon height={30} name={icon} primaryColor={selected ? grey[90] : white} />
            </IconWrapper>
          )}
          <Label size="large">{name}</Label>
        </IconContainer>
      </Card>
    </>
  );
};

const Input = styled.input`
  opacity: 0;
  position: fixed;
  pointer-events: none;

  &[data-focus-method='keyboard']:focus + label {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
  }
`;

const buttonStyles = ({ selected }: { selected: boolean }) =>
  selected
    ? css`
        background: ${white};
        border: 1px solid ${white};
        color: ${grey[90]};
      `
    : css`
        background-color: ${rgba(black, 0.25)};
        border: 1px solid ${rgba(grey[80], 0.25)};
        color: ${white};
      `;

const Card = styled.label<{ selected: boolean }>`
  align-items: center;
  border-radius: ${spacing[4]};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing[24]} 0;
  position: relative;
  ${buttonStyles}

  ${defaultTransition()}

  ${media.tablet`
    padding: ${spacing[24]} ${spacing[16]};
  `}
    
  ${media.desktopLarge`
    padding: ${spacing[24]};
  `}
`;

const IconContainer = styled.div`
  display: grid;
  grid-gap: ${spacing[16]};
  grid-auto-flow: row;
  justify-items: center;
`;

const IconWrapper = styled.div`
  height: 30px;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: ${spacing[8]};
  right: ${spacing[8]};
  text-align: left;

  // A psuedo element that expands the tap area around the tooltip for mobile devices
  & > span:first-child::after {
    content: '';
    position: absolute;
    background: transparent;
    left: -20px;
    bottom: -20px;
    width: 44px;
    height: 44px;

    ${media.tabletXLarge`
      content: none;
    `}
  }
`;

const TooltipBody = styled(Body)`
  width: 236px;
`;

const TooltipEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[16]};
`;
