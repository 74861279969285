import useSWR from 'swr';
import { IMMUTABLE } from '@content/client/revalidation';
import { PRODUCT_DATA_SWR_KEY } from './swrKeys';
import type { BucketedProductDataSubset } from './types';

const useProductDataForPage = (): BucketedProductDataSubset => {
  const { data: productData } = useSWR(PRODUCT_DATA_SWR_KEY, IMMUTABLE);

  return productData;
};

export default useProductDataForPage;
