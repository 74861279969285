import { white } from '@pelotoncycle/design-system';
import React from 'react';

type Props = {
  height: number;
  className?: string;
};

export const PrevArrowIcon: React.FC<React.PropsWithChildren<Props>> = ({
  height,
  className,
}) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 16 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32474 16.0375L16 0.69101L15.336 0L0 16.0375L15.2643 32L15.9283 31.309L1.32474 16.0375Z"
      fill={white}
    />
  </svg>
);
