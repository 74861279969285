import { z } from 'zod';
import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import { Locale } from '@peloton/internationalize/models';
import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import { getPageConfigs, PageConfigTags } from '@content/client/lib/pageConfig';
import { getClient } from '@content/client/pageBuilderClient';
import type { TypePageConfig } from '@page-builder/lib/types';
import { fetchDisciplineDataApi } from '../utils/fetchDisciplineDataApi';
import {
  CLASS_DISCIPLINE_PAGES_REGEX,
  CLASS_LISTING_LIMIT,
  CLASS_LISTING_PAGES_REGEX,
  DISCIPLINE_SLUGS,
} from './constants';

const validateConfig = (scope: string, config: unknown) => {
  const schema = z.object({
    classes: z.array(z.string()).optional(),
  });

  return schema.parse(config, {
    errorMap: (error, context) => ({
      message: `‘${scope}’ pageConfig error: ${
        context.defaultError
      } (prop: ${error.path.join('.')})`,
    }),
  });
};

export const getDisciplineRoutes = () =>
  Object.values(ProspectsDiscipline).map(
    discipline => `classes/${DISCIPLINE_SLUGS[discipline]}`,
  );

const extractStaticPageRoutes = (pageConfigs: TypePageConfig[]): string[] => {
  return pageConfigs.map(pageConfig => {
    const slug = pageConfig.fields.name;
    return slug;
  });
};

const extractFirstPaginationClasses = (pageConfigs: TypePageConfig[]): string[] => {
  return pageConfigs.flatMap(pageConfig => {
    const slug = pageConfig.fields.name;
    const config = validateConfig(slug, pageConfig.fields.config);
    const firstPaginationClasses = config.classes?.slice?.(0, CLASS_LISTING_LIMIT) || [];
    const firstPaginationClassRoutes = firstPaginationClasses.map(
      classSlug => `${slug}/${classSlug}`,
    );
    return firstPaginationClassRoutes;
  });
};

const checkHasDisciplinePageTag = (pageConfig: TypePageConfig) =>
  pageConfig.metadata.tags.some(
    tag => tag.sys.id === PageConfigTags.CLASS_DISCIPLINE_PAGE,
  );

/**
 * Used in getStaticPaths to determine which pages need to be statically generated.
 * @param dryRun A dryRun returns one of each page type, otherwise returns all pages.
 * @returns a list of pages to generate statically.
 */
export const getStaticProspectsPageRoutes = async (dryRun: boolean = false) => {
  const client = getClient(ALLOW_PREVIEW_COPY);
  const classListingPageConfigs = await getPageConfigs(client, {
    locale: Locale.EnglishUnitedStates,
    tags: [PageConfigTags.CLASS_DISCIPLINE_PAGE, PageConfigTags.CLASS_LANDING_PAGE],
    limit: 999,
  });

  const staticClassListingPageRoutes = extractStaticPageRoutes(classListingPageConfigs);

  const classDisciplinePageConfigs = classListingPageConfigs.filter(
    checkHasDisciplinePageTag,
  );

  const staticClassRoutes = extractFirstPaginationClasses(classDisciplinePageConfigs);

  if (dryRun) {
    const disciplinePage = getDisciplineRoutes()[0];
    const paginationPage = `${disciplinePage}/1`;

    return [
      staticClassListingPageRoutes[0],
      staticClassRoutes[0],
      disciplinePage,
      paginationPage,
    ];
  }

  return staticClassListingPageRoutes.concat(staticClassRoutes);
};

export const getClassLandingPageRoutes = async () => {
  const client = getClient(ALLOW_PREVIEW_COPY);
  const clpPageConfigs = await getPageConfigs(client, {
    locale: Locale.EnglishUnitedStates,
    tags: [PageConfigTags.CLASS_LANDING_PAGE],
    limit: 999,
  });

  const classLandingPageRoutes = clpPageConfigs.map(pageConfig => pageConfig.fields.name);

  return classLandingPageRoutes;
};

export const getDisciplineAndClassLandingPageRoutes = async () =>
  Object.values(ProspectsDiscipline)
    .map(discipline => `classes/${DISCIPLINE_SLUGS[discipline]}`)
    .concat(await getClassLandingPageRoutes());

export const getDisciplineFetchers = () => {
  const routes: Record<string, any> = {};
  Object.values(ProspectsDiscipline).forEach(discipline => {
    const disciplineSlug = DISCIPLINE_SLUGS[discipline];

    routes[`classes/${disciplineSlug}`] = fetchDisciplineDataApi;
  });

  return routes;
};

export const isDisciplinePage = (slug: string) => CLASS_DISCIPLINE_PAGES_REGEX.test(slug);

export const isClassLandingPage = (slug: string) =>
  !isDisciplinePage(slug) && CLASS_LISTING_PAGES_REGEX.test(slug);

export const isDisciplinePageOrClassLandingPage = (slug: string) =>
  isDisciplinePage(slug) || isClassLandingPage(slug);

// Match classes with the format /classes/discipline-type/10-minute-run-193483
export const CLASS_PAGE_REGEX = /^custom+classes|(classes)\/[a-zA-Z-_]+\/[a-zA-Z0-9-]{10,12}/;
export const CLASS_PAGE_REGEX_BROAD = /custom+classes|(classes|prospects)\/(?<discipline>[^/]+)\/(?<slug>[^/]+)/;
