import { ReviewsOrderByInput } from '@ecomm/graphql/types.generated';
import type { SecondaryRatingAverages } from '@ecomm/reviews/data/utils/useUGCQuery';

export const REVIEWS_MODAL = {
  defaultSortOrder: ReviewsOrderByInput.DateDesc,
  defaultNumberOfReviews: 25,
  transitionDuration: 250,
  cardPaddingOffset: 96,
};

export const RECENT_REVIEWS_QUERY_PARAMS = {
  orderBy: ReviewsOrderByInput.DateDesc,
  defaultReviewQuantities: 6,
  reviewsToDisplay: 6,
  minimalReviewRating: 4,
};

export const CARD_WIDTHS = {
  mobile: '304px',
  desktop: '392px',
};

export const CARD_HEIGHTS = {
  mobile: '286px',
  desktop: '286px',
};

export const MAX_CARDS_PER_SLIDE = {
  desktop: 3,
  mobile: 1,
};

const QUALITY_COPY_KEY = 'reviewsScores.quality';
const VALUE_COPY_KEY = 'reviewsScores.value';
const EASE_OF_USE_COPY_KEY = 'reviewsScores.easeOfUse';
const VARIETY_OF_INSTRUCTORS_COPY_KEY = 'reviewsScores.varietyOfInstructors';
const DELIVERY_COPY_KEY = 'reviewsScores.delivery';

const QUALITY_QUERY_KEY = 'quality';
const VALUE_QUERY_KEY = 'value';
const EASE_OF_USE_QUERY_KEY = 'easeOfUse';
const VARIETY_OF_INSTRUCTORS_QUERY_KEY = 'varietyOfInstructors';
const DELIVERY_QUERY_KEY = 'delivery';

export const reviewsScoresMicroCopyKeys = [
  QUALITY_COPY_KEY,
  VALUE_COPY_KEY,
  EASE_OF_USE_COPY_KEY,
  VARIETY_OF_INSTRUCTORS_COPY_KEY,
  DELIVERY_COPY_KEY,
];

export const secondaryRatingAveragesKeyNames: (keyof SecondaryRatingAverages)[] = [
  QUALITY_QUERY_KEY,
  VALUE_QUERY_KEY,
  EASE_OF_USE_QUERY_KEY,
  VARIETY_OF_INSTRUCTORS_QUERY_KEY,
  DELIVERY_QUERY_KEY,
];

export const REVIEWS_MAXIMUM_SCORE = 5.0;

const radius = 21.75;
export const SCORE_WHEELS = {
  minimumScore: 3.5,
  maximumScore: REVIEWS_MAXIMUM_SCORE,
  radius,
  circumference: 2 * Math.PI * radius,
  strokeWidth: 4.5,
  svgContainer: {
    width: 48,
    height: 48,
  },
};

export const REVIEWS_TRACKING = {
  parentType: 'AI Reviews Component (CHAMP)',
  parent: 'Reviews Modal',
};
