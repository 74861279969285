import type { TagLink } from 'contentful';
import { useCallback, useMemo } from 'react';
import { useSplitTestingContext } from '@peloton/split-testing/hooks';
import { hasVariationFor, variationIsControl } from '@peloton/split-testing/SplitTesting';
import { useGenericIsToggleActive } from '@ecomm/feature-toggle/context/ToggleContext';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import { convertTags } from '@page-builder/utils/helpers';

export const useTagResolver = () => {
  const { isProductAvailableForMarketingPages } = useProductStates();
  const isToggleActive = useGenericIsToggleActive();
  const { experiments, hasStalled } = useSplitTestingContext();

  const resolveTag = useCallback(
    (tag: string) => {
      if (Product[tag]) {
        return { [tag]: isProductAvailableForMarketingPages(Product[tag]) };
      }
      if (tag.startsWith('experiment')) {
        const [_, experimentName, experimentBucket] = tag.split('.');
        if (!experiments) {
          return {
            [tag]: hasStalled ? variationIsControl(experimentBucket) : false,
          };
        }
        return {
          [tag]: hasVariationFor(
            experimentName.split('_').join(' '),
            experimentBucket.replace('Variation', 'Variation #'),
            experiments,
          ),
        };
      }
      return { [tag]: isToggleActive(tag) };
    },
    [isProductAvailableForMarketingPages, isToggleActive, experiments, hasStalled],
  );

  return { resolveTag };
};

const useTagHandler = (tags: string[] | TagLink[]) => {
  const { resolveTag } = useTagResolver();
  const convertedTags = convertTags(tags);

  const resolvedTagGroups = useMemo(() => {
    const nonExperimentTags = convertedTags.filter(tag => !tag.startsWith('experiment'));
    const experimentTags = convertedTags.filter(tag => tag.startsWith('experiment'));

    const resolvedNonExperimentTags = nonExperimentTags.reduce((resolved, tagId) => {
      const resolvedTag = resolveTag(tagId);
      return { ...resolved, ...resolvedTag };
    }, {});

    const resolvedExperimentTags = experimentTags.reduce((resolved, tagId) => {
      const resolvedTag = resolveTag(tagId);
      return { ...resolved, ...resolvedTag };
    }, {});

    return { resolvedExperimentTags, resolvedNonExperimentTags };
  }, [convertedTags, resolveTag]);

  const resolution = useMemo(() => {
    const { resolvedExperimentTags, resolvedNonExperimentTags } = resolvedTagGroups;

    // Calculate single value for experiments and then combine then with nonExperiments
    const experimentValues = Object.values(resolvedExperimentTags);
    const experimentStatus =
      experimentValues.length > 0 ? experimentValues.some(value => value) : true;

    const resolvedStatus = [
      ...Object.values(resolvedNonExperimentTags),
      experimentStatus,
    ].every(value => value);

    const resolvedTags = { ...resolvedExperimentTags, ...resolvedNonExperimentTags };

    return { resolvedStatus, resolvedTags };
  }, [resolvedTagGroups]);

  return resolution;
};

export default useTagHandler;
