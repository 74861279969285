import React from 'react';
import type {
  TypeComponentGenericList,
  TypeComponentGenericTextWithMedia,
} from '@page-builder/lib/types';
import TreatmentToComponentMap from './maps';

const GenericModuleWithTreatment: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMedia | TypeComponentGenericList>
> = ({ fields, sys }) => {
  const { treatment } = fields;
  const Component = TreatmentToComponentMap[treatment];

  if (!Component) {
    console.warn(`${treatment} can not be handled`);
    return null;
  }

  const contentTypeId = sys.contentType.sys.id;

  return <Component key={`${contentTypeId}-${treatment}`} {...fields} />;
};

export default GenericModuleWithTreatment;
