import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';

const toSlugArray = (slug?: string[] | string): string[] => {
  if (!slug) return [];
  return Array.isArray(slug) ? [...slug] : slug.split('/');
};

export const getPagefromQuery = (query?: NextParsedUrlQuery) => {
  const lastSegment = toSlugArray(query?.slugs).pop() || '';
  const isLastSegmentPagination = /^\d+$/.test(lastSegment);
  const page = Number((isLastSegmentPagination && lastSegment) || query?.page || '1');
  return page;
};
