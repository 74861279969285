import type * as Contentful from 'contentful';
import { map } from 'ramda';
import type { TypeComponentJson, TypePageFields } from '@page-builder/lib/types';
import type { ReferenceContentTypes } from './truncateComponentJsonReference';
import { parseWithReferences, truncateReference } from './truncateComponentJsonReference';

interface TruncatedEntry<T> extends Pick<Contentful.Entry<T>, 'fields'> {
  sys: {
    id: string;
    contentType: { sys: { id: string } };
    space: { sys: { id: string } };
  };
  metadata: {
    tags: string[];
  };
}

interface TagLink {
  sys: {
    type: 'Link';
    linkType: 'Tag';
    id: string;
  };
}

interface ContentfulEntry<T> extends Contentful.Entry<T> {
  metadata: {
    tags: TagLink[];
  };
}

type Field = string | ContentfulEntry<any> | Array<ContentfulEntry<any>>;

const shouldTruncate = (field: Field) =>
  Array.isArray(field) || (typeof field === 'object' && field.sys !== undefined);

const parseEntry = <T>(entry: ContentfulEntry<T>) => {
  try {
    const {
      sys: {
        id: sysId,
        contentType: {
          sys: { id },
        },
      },
      fields,
    } = entry;
    const tagLinks = entry?.metadata?.tags;
    const spaceId = entry.sys.space?.sys.id ?? '';

    // Checking for metadata being undefined for Page Builder Contentful entries in draft
    if (!entry?.metadata) {
      console.log('Metadata undefined for entry: ', sysId);
    }

    return {
      fields,
      id,
      sysId,
      tagLinks,
      spaceId,
    };
  } catch (err) {
    console.error(
      'Error occurred on entry: ',
      `https://app.contentful.com/spaces/7vk8puwnesgc/entries/${entry?.sys?.id}`,
    );
    throw new Error(err);
  }
};

export const truncateComponentJsonEntry = (
  entry: TypeComponentJson,
): TruncatedEntry<any> => {
  const { sysId, id, fields, tagLinks = [], spaceId } = parseEntry(entry);
  const { component, payload, references = [] } = fields;

  const referenceMap = new Map(
    references.map(reference => [
      reference.sys.id,
      { ...truncateReference(reference as ReferenceContentTypes) },
    ]),
  );

  const parsePayload = parseWithReferences(referenceMap, { sysId, component });
  const parsedPayload = parsePayload(payload);

  return {
    sys: {
      id: sysId,
      contentType: {
        sys: { id },
      },
      space: {
        sys: { id: spaceId },
      },
    },
    metadata: {
      tags: tagLinks.map(({ sys: { id: tagId } }) => tagId),
    },
    fields: {
      component,
      payload: parsedPayload,
    },
  };
};

export const truncateEntry = (entry: ContentfulEntry<any>): TruncatedEntry<any> => {
  if (entry.sys.contentType.sys.id === 'componentJson') {
    return truncateComponentJsonEntry(entry);
  }
  const { sysId, id, fields, tagLinks = [], spaceId } = parseEntry(entry);

  return {
    sys: {
      id: sysId,
      contentType: {
        sys: { id },
      },
      space: {
        sys: { id: spaceId },
      },
    },
    metadata: {
      tags: tagLinks.map(({ sys: { id: tagId } }) => tagId),
    },
    fields: {
      ...map((field: Field) => {
        if (!shouldTruncate(field)) {
          return field;
        }

        // Multi-reference field - referenced entries recursively truncated
        if (Array.isArray(field)) {
          /**
           *  Rich Text fields should not be truncated - they do not have any 'sys' properties and hold their copy in a `content` array
           */
          return field.map(reference =>
            !!reference.sys ? truncateEntry(reference) : reference,
          );
        }

        /**
         * Single reference field
         * referenced entry is truncated
         * asset are flattened to fields only
         * */
        if (typeof field === 'object' && field.sys.type) {
          if (field.sys.type === 'Entry') {
            return truncateEntry(field);
          }
          if (field.sys.type === 'Asset') {
            return { fields: field.fields };
          }
        }

        // Standard text field - returned as is
        return field;
      }, fields),
    },
  };
};

/**
 * Parses a compose page, trimming down all entries in `content`
 * to contain only `contentType` `sys` values and `fields`.
 * @param page full compose page response
 * @returns truncated compose page
 */
const truncateComposePage = (page: Contentful.Entry<TypePageFields>) => {
  return {
    sys: page.sys,
    fields: {
      ...page.fields,
      content: {
        ...truncateEntry(page.fields.content as ContentfulEntry<TypePageFields>),
      },
    },
  };
};

export default truncateComposePage;
