import { List } from '@pelotoncycle/design-system';
import type { ReactNode } from 'react';
import React from 'react';
import gfm from 'remark-gfm';
import styled from 'styled-components';
import LinkRenderer from '@page-builder/modules/LinkRenderer';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  text: string;
  renderers?: Record<string, React.ElementType<any>>;
};

const WithMarkdown: React.FC<React.PropsWithChildren<Props>> = ({ text, renderers }) => {
  return (
    <Markdown
      content={text}
      markdown={{
        renderers: {
          link: LinkRenderer,
          paragraph: StyledParagraph,
          list: ({ ordered, children }: { ordered: boolean; children: ReactNode[] }) => {
            return <List listStyle={ordered ? 'decimal' : 'disc'} items={children} />;
          },
          ...renderers,
        },
        disallowedTypes: ['listItem'],
        unwrapDisallowed: true,
        plugins: [gfm],
      }}
    />
  );
};

export default WithMarkdown;

const StyledParagraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  a {
    text-decoration: underline;
  }
`;
