import React, { createContext, useContext, useMemo } from 'react';

type PanelContextType = {
  togglePanel?: () => void;
  children?: React.ReactNode;
};

export const PanelContext = createContext<PanelContextType>({});

export const PanelContextProvider: React.FC<PanelContextType> = ({
  children,
  togglePanel,
}) => {
  const value = useMemo(() => {
    return { togglePanel };
  }, [togglePanel]);

  return <PanelContext.Provider value={value}>{children}</PanelContext.Provider>;
};

export const usePanelContext = () => useContext(PanelContext);
