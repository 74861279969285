import { brand, grey, white, Icon } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { ieHideScroll } from '@peloton/styles/browsers';
import { useMicroCopy } from '@content/client/microCopy';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import PressQuote from '@page-builder/modules/PressQuotes/PressQuote';
import { toImageProps } from '@page-builder/utils/helpers/media';

export type BannerProps = {
  dark: boolean;
};

type CarouselProps = {
  ariaLabel: string;
  currentSlide: number;
  direction: 'previous' | 'next';
  onClick: () => void;
};

const Chevron = () => (
  <Icon name="chevron" role="presentation" rotate={180} primaryColor={grey[70]} />
);

const PressQuoteBanner: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const nextLabel = useMicroCopy('nextButtonLabel');
  const previousLabel = useMicroCopy('previousButtonLabel');
  const totalSlides = items.length;

  return (
    <CarouselContainer>
      <CarouselButton
        ariaLabel={previousLabel}
        currentSlide={currentSlide}
        direction="previous"
        onClick={() => {
          currentSlide !== 0
            ? setCurrentSlide(currentSlide - 1)
            : setCurrentSlide(totalSlides - 1);
        }}
      >
        <Chevron />
      </CarouselButton>
      {/* eslint-disable-next-line styled-components-a11y/no-noninteractive-tabindex */}
      <Container dark={true} tabIndex={0}>
        <Quotes currentSlide={currentSlide}>
          {items.map(({ fields }, i) => {
            if (fields.media === undefined) {
              return null;
            }
            const image = toImageProps(fields.media);
            const quote = fields?.text?.fields?.body || '';
            return <PressQuote key={i} image={image} quote={quote} />;
          })}
        </Quotes>
      </Container>
      <CarouselButton
        ariaLabel={nextLabel}
        currentSlide={currentSlide}
        direction="next"
        onClick={() => {
          currentSlide !== totalSlides - 1
            ? setCurrentSlide(currentSlide + 1)
            : setCurrentSlide(0);
        }}
      >
        <Chevron />
      </CarouselButton>
    </CarouselContainer>
  );
};

export default PressQuoteBanner;

const CarouselButton: React.FC<React.PropsWithChildren<CarouselProps>> = ({
  ariaLabel,
  children,
  direction,
  onClick,
}) => (
  <StyledCarouselButton aria-label={ariaLabel} direction={direction} onClick={onClick}>
    {children}
  </StyledCarouselButton>
);

export const CarouselContainer = styled.div`
  position: relative;
  width: 100vw;
`;

export const StyledCarouselButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 20px;
  z-index: 3;
  left: ${(props: { direction: string }) =>
    props.direction === 'previous' ? 0 : 'auto'};
  right: ${(props: { direction: string }) => (props.direction === 'next' ? 0 : 'auto')};

  &:first-of-type {
    transform: scaleX(-1);
  }

  ${media.tabletLarge`
    display: none;
  `}
`;

export const Container = styled.section`
  background-color: ${({ dark }: BannerProps) => (dark ? brand.darkest : white)};
  padding: 40px 20px 40px 0;
  overflow-x: scroll;
  width: auto;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }

  ${media.tabletLarge`
    padding-left: 20px;
  `}

  ${ieHideScroll}
`;

export const Quotes = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  transition: transform 200ms ease;
  width: 300vw;
  ${(props: { currentSlide: number }) => `
    transform: translate3d(-${props.currentSlide * 100}vw, 0, 0);
  `}
  z-index: 2;

  ${media.tabletLarge`
    width: 100%;
  `}

  ${media.desktopLarge`
    width: 1000px;
  `}
`;
