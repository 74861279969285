import { Container, Flex, grey, pixelToRem, spacing } from '@pelotoncycle/design-system';
import type { TypeMetadataLeadGenFields } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import React from 'react';
import { HistoryReportFormWrapper } from './HistoryReportFormWrapper';
interface Props {
  children: React.ReactNode;
  emailCaptureMetaData: Entry<TypeMetadataLeadGenFields>;
}

const HistoryReportContainer: React.FC<Props> = ({ children, emailCaptureMetaData }) => {
  return (
    <Flex
      verticalPadding={{
        mobile: spacing[56],
        tablet: spacing[64],
        desktop: spacing[96],
      }}
      backgroundColor={grey[10]}
      horizontalPadding={{ desktop: spacing[40] }}
      justifyContent="center"
    >
      <Container
        maxWidth={{
          mobile: pixelToRem(330),
          tablet: pixelToRem(520),
          desktop: pixelToRem(520),
        }}
        gap={spacing[16]}
        margin={`${spacing[16]} 0 ${spacing[32]}`}
        textAlign="center"
      >
        <HistoryReportFormWrapper emailCaptureMetaData={emailCaptureMetaData}>
          {children}
        </HistoryReportFormWrapper>
      </Container>
    </Flex>
  );
};

export default HistoryReportContainer;
