import { black, Eyebrow, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import ReactModal from 'react-modal';
import { useTracking } from 'react-tracking';
import type { BaseModalProps } from '@ecomm/modal/BaseModal';
import ModalGlobalStyles from '@ecomm/modal/ModalGlobalStyles';
import type { Card } from '../FeatureCards/types';
import FeatureCardModalItem from './FeatureCardModalItem';
import {
  ModalContent,
  StickyHeader,
  StyledModal,
  CloseButton,
  Section,
  SectionWrapper,
  Breadcrumb,
  Fixed,
} from './styles';
import { SUBNAV_SIZE, getRootElement, isEven } from './utils';

type FeatureCardModalProps = {
  subnavText: string;
  modalContent: Card['modal'][];
  selectedItem: number | undefined;
};

const FeatureCardModal: React.FC<
  React.PropsWithChildren<BaseModalProps & FeatureCardModalProps>
> = ({
  isOpen = true,
  closeHandler,
  openHandler,
  contentLabel,
  subnavText,
  modalContent,
  selectedItem,
}) => {
  const [savedScrollPosition, setSavedScrollPosition] = React.useState<
    number | undefined
  >(undefined);

  const modalContentRef = React.useRef<HTMLDivElement>(null);
  const { trackEvent } = useTracking();

  const handleAfterOpen = () => {
    setSavedScrollPosition(window.scrollY || document.documentElement.scrollTop);
    openHandler && openHandler();
    const rootElement = getRootElement()!;
    // Apply the saved 'scroll' position to the #root element when the modal is opened
    document.body.style.width = `100%`;
    document.body.style.top = `${rootElement.getBoundingClientRect().y}px`;
    document.body.style.position = 'fixed';
    setTimeout(() => {
      if (modalContentRef !== null) {
        const el = modalContentRef.current?.querySelector(`.pos${selectedItem}`);
        const top = el?.getBoundingClientRect().top || 0;
        const modal = document.querySelector('.ReactModal__Overlay--after-open');
        if (modal) {
          modal.scrollTo({
            // 48 is the height of the mobile sticky header
            top: top - 48,
            left: 0,
            behavior: 'instant',
          });
        }
      }
    }, 1);
    typeof selectedItem !== 'undefined' &&
      trackEvent({
        event: 'Modal Opened',
        properties: {
          productInterest: modalContent[selectedItem].header,
          propertyType: 'Web',
          unitName: modalContent[selectedItem].name,
          unitPlacement: 'Modal',
          modalTrigger: `Clicked on ${modalContent[selectedItem].name} feature card`,
        },
      });
  };

  const handleRequestClose = () => {
    closeHandler && closeHandler();
    requestAnimationFrame(() => window.scrollTo({ top: savedScrollPosition }));
    document.body.style.width = '';
    document.body.style.position = '';
    document.body.style.top = '';
    setSavedScrollPosition(undefined);
    trackEvent({
      event: 'Modal Closed',
      properties: {
        productInterest: selectedItem && modalContent[selectedItem].header,
        propertyType: 'Web',
        unitName: selectedItem && modalContent[selectedItem].name,
        unitPlacement: 'Modal',
        modalTrigger:
          selectedItem && `Closed the the ${modalContent[selectedItem].name} modal`,
      },
    });
  };

  /*
   * NOTE: DO NOT WRAP THE StyledModal COMPONENT IN ANY DOM ELEMENTS!
   *
   * The StyledModal uses a portal to attach its elements to a different part of the DOM.
   * Wrapping the StyledModal in a DOM element here will leave the element in a spot in the DOM
   * that is likely different from where you are expecting it to go.
   */
  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel={contentLabel || subnavText}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      closeTimeoutMS={400}
      role="dialog"
      aria={{ modal: 'true' } as ReactModal.Aria}
    >
      <ModalGlobalStyles /> {/* Taken from Legacy modal, leaving for stability */}
      <Fixed /> {/* input-cursor fix; see comment on component declaration */}
      {/* eslint-disable styled-components-a11y/no-autofocus */}
      {/* while autoFocus is generally a violation, in this case, it actually *fixes* an issue where the focus trap isn't set within the lightbox modal on iOS Safari */}
      <ModalContent ref={modalContentRef}>
        <StickyHeader>
          <Eyebrow size="small">{subnavText}</Eyebrow>
          <CloseButton
            onClick={handleRequestClose}
            data-test-id="dismissModal"
            aria-label="Close"
            tabIndex={0}
          >
            <Icon name="close" height={24} primaryColor={black}></Icon>
          </CloseButton>
        </StickyHeader>
        {modalContent.length &&
          modalContent.map(({ body, media }, index) => {
            return (
              <FeatureCardModalSection
                key={`feature-card-sections-${index}`}
                position={index}
                total={modalContent.length}
                body={body}
              >
                <FeatureCardModalItem
                  body={body}
                  imagePosition={isEven(index) ? 'left' : 'right'}
                  media={media}
                />
              </FeatureCardModalSection>
            );
          })}
      </ModalContent>
    </StyledModal>
  );
};

type FeatureCardModalSectionProps = {
  position: number;
  total: number;
  children: React.ReactNode;
  body: string;
};

const FeatureCardModalSection: React.FC<
  React.PropsWithChildren<FeatureCardModalSectionProps>
> = ({ position, total, children, body }) => {
  const wrapperEl = React.useRef<HTMLDivElement>(null);
  const wrapperHeight = wrapperEl?.current?.clientHeight;
  const wrapperWithHeaderHeight = wrapperHeight
    ? wrapperHeight + SUBNAV_SIZE
    : SUBNAV_SIZE;
  const modalHeight = window?.innerHeight;
  const marginBottom = wrapperWithHeaderHeight - modalHeight;
  const { ref: notARealRef, inView } = useInView({
    threshold: 0.5,
    rootMargin: `-${SUBNAV_SIZE}px 0% ${marginBottom}px 0%`,
  });
  return (
    <SectionWrapper ref={wrapperEl} className={`pos${position}`}>
      <Section ref={notARealRef}>
        {children}

        <Breadcrumb
          position={position}
          total={total}
          active={inView}
          onClick={() => wrapperEl?.current?.scrollIntoView({ behavior: 'smooth' })}
          aria-label={body.split('\n')[0].replace('#', '')}
        />
      </Section>
    </SectionWrapper>
  );
};

/* Taken from legacy modal */
// http://reactcommunity.org/react-modal/accessibility/#app-element
if (typeof window !== 'undefined') {
  const root = getRootElement();
  if (root) {
    ReactModal.setAppElement(root);
  }
}

export default FeatureCardModal;
