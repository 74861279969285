import { Flex, grey, spacing, ResponsiveImage, Media } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';

export const SectionContainer = styled(Flex)<{ isLastSection: boolean }>`
  border-bottom: ${({ isLastSection }) =>
    isLastSection ? 'unset' : `1px solid ${grey[70]}`};
  ${media.desktopXLarge`
    gap: ${spacing[56]};
    padding: ${spacing[80]} ${spacing[56]};
  `}
`;

export const ThumbnailImage = styled(ResponsiveImage)`
  img {
    max-width: 112px;
    display: block;
    aspect-ratio: 14/9;
    object-fit: cover;
    border-radius: ${spacing[8]} 0 0 ${spacing[8]};
  }
`;

export const ActiveMedia = styled(Media)`
  height: auto;
  video,
  picture > img {
    aspect-ratio: 152/85;
    border-radius: ${spacing[4]};
  }
`;
