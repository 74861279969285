import { brand } from '@pelotoncycle/design-system';
import type { TypeBundlePromo } from '@pelotoncycle/page-builder';
import React from 'react';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import { useGetCommercetoolsCFU } from '@ecomm/commercetools/hooks/useGetCommercetoolsCFU';
import { toDollars } from '@ecomm/models';
import type { CfuAndBundlePromoProps } from '../types';

type CommercetoolsPromoPriceProps = CfuAndBundlePromoProps & {
  bundlePromo?: TypeBundlePromo;
};

export const CommercetoolsPromoPrice: React.FC<
  React.PropsWithChildren<CommercetoolsPromoPriceProps>
> = ({ cfu, bundleSlug, bundlePromo }) => {
  const { data: productPackageData } = useGetCommercetoolsCFU(bundleSlug);
  const basePrice = productPackageData?.catalog.packageBySlug.price.amount;

  if (!basePrice) return null;

  if (bundlePromo) {
    const discount = bundlePromo.fields.couponDiscount;

    return <PromoPriceDisplay price={basePrice} discount={discount} />;
  }

  return <PromoPrice cfu={cfu} bundleSlug={bundleSlug} price={basePrice} />;
};

export type PromoPriceProps = CfuAndBundlePromoProps & { price: number };

const PromoPrice: React.FC<React.PropsWithChildren<PromoPriceProps>> = ({
  cfu,
  bundleSlug,
  price,
}) => {
  const { data } = useBundlePromo(cfu, bundleSlug);

  const discount = data?.fields.couponDiscount;

  return <PromoPriceDisplay price={price} discount={discount} />;
};

type PriceDisplayProps = { price: number; discount?: number };

const toPrices = (basePrice: number, discount: number) => {
  const discountCents = discount * 100;
  const isPriceDrop = discount < 0;

  if (isPriceDrop) {
    /*
    When the discount is negative, the promo is a price drop and the basePrice is actually the new, lower price,
    and the discount can be used to calculate the original "base price"
    */
    return {
      basePrice: basePrice - discountCents,
      discountPrice: basePrice,
    };
  }

  return {
    basePrice,
    discountPrice: basePrice - discountCents,
  };
};

export const PromoPriceDisplay = ({
  price: originalBasePrice,
  discount,
}: PriceDisplayProps) => {
  const toFormattedText = useGetTextFormatter();

  const formatPrice = (price: number) => {
    return toFormattedText('{price, number, currency}', {
      price: toDollars(price),
    });
  };

  if (!discount) {
    return <>{formatPrice(originalBasePrice)}</>;
  }

  const { basePrice, discountPrice } = toPrices(originalBasePrice, discount);

  return (
    <>
      <span style={{ color: brand.primary }}>{formatPrice(discountPrice)}</span>{' '}
      <s>{formatPrice(basePrice)}</s>
    </>
  );
};

export default PromoPrice;
