import { spacing, Container, Button } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import { Link } from '@peloton/next/components/Link';
import type { WwwLinkReferenceType as WwwLinkReference } from '../../../types/referenceTypes';

const HeroCTA: FC<{
  text: string;
  link: WwwLinkReference;
  onClick: () => void;
}> = ({ text, link, onClick }) => {
  return (
    <Container
      margin={{
        mobile: `${spacing[32]} 0 0 0`,
        tablet: `${spacing[56]} 0 0 0`,
        desktop: `${spacing[88]} 0 0 0`,
      }}
      width="auto"
    >
      <Link
        to={link.url}
        href={link.url}
        onClick={onClick}
        hasUnderline={false}
        data-test-id="browseClassesLink"
      >
        <Button color="light" width={{ mobile: 'fixed', tablet: 'adaptive' }}>
          {text}
        </Button>
      </Link>
    </Container>
  );
};

export default HeroCTA;
