import {
  Container,
  Body,
  Eyebrow,
  Icon,
  Flex,
  spacing,
} from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentProductCardFields } from '@page-builder/lib/types';
import { TEXT_MAXWIDTH } from '@page-builder/modules/tokens';
import WithMarkdown from '@page-builder/modules/WithMarkdown';

type IconListProps = {
  iconList: TypeComponentProductCardFields['iconList'];
};

const IconList: React.FC<React.PropsWithChildren<IconListProps>> = ({ iconList }) => (
  <StyledListContainer>
    {iconList &&
      iconList.map((li, i) => (
        <Container
          key={i}
          horizontalPadding={{ mobile: spacing[32], desktop: 0 }}
          maxWidth={TEXT_MAXWIDTH}
          horizontalMargin="auto"
        >
          <Eyebrow size="small" marginBottom={spacing[16]}>
            {li.fields.headlineText}
          </Eyebrow>
          <ul>
            {li.fields.columns.map((column, j) => {
              const { bodyLongText, bodyText, icon, name } = column.fields;
              if (!icon) throw new Error(`Please select an icon in CMS for ${name}`);
              return (
                <li key={j} style={{ marginBottom: spacing[16] }}>
                  <Flex gap={spacing[12]}>
                    <Icon name={icon as IconNames} height={20} style={{ marginTop: 6 }} />
                    <Body size="small" as="span">
                      <WithMarkdown text={bodyLongText ?? bodyText ?? ''} />
                    </Body>
                  </Flex>
                </li>
              );
            })}
          </ul>
        </Container>
      ))}
  </StyledListContainer>
);

const StyledListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};
`;

export default IconList;
