import { Icon, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { LinkView } from '@ecomm/financing/affirm/ModalLinkView';
import { usePackageFinancing } from './../../utils';
import FinancingCtaContentWrapper from './FinancingCtaContentWrapper';
import type FinancingCtaProps from './FinancingCtaProps';

const AffirmCta: React.FC<React.PropsWithChildren<FinancingCtaProps>> = ({
  cta,
  bundleType,
  onClick,
  isDrawerFinancingSection,
  financingPriceOverride,
}) => {
  const { ready, total, term } = usePackageFinancing(bundleType);

  if (!ready) {
    return null;
  }

  return (
    <AffirmLink
      amount={financingPriceOverride || total}
      bundleType={bundleType}
      term={term}
      wrapper="button"
      className="ds__link affirm-modal-trigger"
      onClick={onClick}
    >
      {() => (
        <FinancingCtaContentWrapper>
          <Label size={isDrawerFinancingSection ? 'small' : 'medium'}>{cta.text}</Label>
          {cta.ctaIcon && !isDrawerFinancingSection && (
            <Icon name={cta.ctaIcon} height={16} />
          )}
        </FinancingCtaContentWrapper>
      )}
    </AffirmLink>
  );
};

const AffirmLink = styled(LinkView)`
  &:focus {
    outline: none;
  }
`;

export default AffirmCta;
