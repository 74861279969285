import { spacing } from '@pelotoncycle/design-system';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

const Faq = dynamic(() => import('@pelotoncycle/page-builder').then(mod => mod.Faq), {
  ssr: false,
});

export const StyledFaq = styled(Faq)`
  ul ol {
    list-style: decimal;
    padding-left: ${spacing[24]};
  }

  ul ul {
    list-style: disc;
    padding-left: ${spacing[24]};
  }
`;
