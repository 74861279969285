import { Eyebrow, black, grey, brand } from '@pelotoncycle/design-system';
import type { TagLink } from 'contentful';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media, BreakpointWidth } from '@peloton/styles';
import useTagHandler from '@page-builder/hooks/useTagHandler';
import { themes } from '@page-builder/themes';
import PerformantImage from './PerformantImage';

type Props = {
  testId: string;
  productName: string;
  card: CardData;
  ribbonText?: string;
  tags: TagLink[] | string[];
  themeKey?: string;
};

export type CardData = {
  link: {
    url?: string;
    text?: string;
    isExternalLink?: boolean;
  };
  image: any;
};

const imageSizes = {
  mobile: 120,
  smallScreen: 135,
  tablet: 155,
  desktop: 150,
  desktopLarge: 150,
};

const BellyBandCard: React.FC<React.PropsWithChildren<Props>> = ({
  card: { link, image },
  tags,
  productName,
  testId,
  ribbonText,
  themeKey = 'Grey 30',
}) => {
  const { trackEvent } = useTracking();
  const { resolvedStatus } = useTagHandler(tags);

  const theme = themes[themeKey];
  const cardColor = themeKey === 'Grey 90' ? grey[75] : grey[20];

  return resolvedStatus ? (
    <Card data-test-id={testId} backgroundColor={cardColor}>
      <StyledLink
        href={link.url}
        onClick={() =>
          trackEvent({
            event: 'Clicked Product Belly Band on HP',
            properties: {
              productName,
              parent: 'Prospect Classes Page',
              linkName: productName,
              linkTo: link.url,
              unitName: 'BellyBand',
              parentType: 'Page',
            },
          })
        }
        target={link.isExternalLink ? '_blank' : undefined}
      >
        {ribbonText && <Ribbon color={theme.textColor}>{ribbonText}</Ribbon>}
        <BellyCardImage
          src={image.mobile}
          alt={image.alt}
          breakpointOptions={{
            mobile: { width: imageSizes.mobile },
            tablet: { width: imageSizes.tablet },
            desktop: { width: imageSizes.desktop },
          }}
        />
        <Eyebrow size="small" textColor={theme.textColor} is="p">
          {link.text}
        </Eyebrow>
      </StyledLink>
    </Card>
  ) : null;
};

export default BellyBandCard;

const Card = styled.li<{ backgroundColor: string }>`
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  border-radius: 8px;
  box-shadow: 5px 3px 15px 0 ${rgba(black, 0.03)};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 140px;
  transition: all 300ms ease;
  &:not(:last-of-type) {
    margin-right: 30px;
  }

  ${media.tabletXLarge`
    &:not(:last-of-type) {
      margin-right: 25px;
    }
  `}

  @media (min-width: 580px) and (max-width: 767px) {
    height: 170px;
  }

  @media (min-width: ${BreakpointWidth.tablet}px) and (max-width: ${BreakpointWidth.desktopLarge}px) {
    height: 160px;
  }

  ${media.desktop`
    min-width: 130px;
    max-width: 180px;

    &:hover {
      backface-visibility: hidden;
      box-shadow: 5px 3px 50px 0 ${rgba(black, 0.2)};
      cursor: pointer;
      transform: translate3d(0, 0, 0) scale(1.08);
      transition: all 300ms ease;
    }
  `}

  ${media.desktopLarge`
    height: 170px;
    min-width: 150px;

    &:not(:last-of-type) {
      margin-right: 40px;
    }
  `}
`;

const BellyCardImage = styled(PerformantImage)`
  img {
    left: 0;
    max-width: ${imageSizes.mobile}px;
    position: absolute;
    top: 0;
    transform: translate3d(-15%, -7%, 0);
    width: 150%;

    ${media.smallScreen`
    max-width: ${imageSizes.smallScreen}px;
  `}

    ${media.tablet`
    max-width: ${imageSizes.tablet}px;
  `}

  ${media.tabletXLarge`
    max-width: ${imageSizes.desktop}px;
  `}

  ${media.desktopLarge`
    max-width: ${imageSizes.desktopLarge}px;
  `}
  }
`;

const StyledLink = styled(Link)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 15px;
  position: relative;
  width: 100%;

  @media (min-width: ${BreakpointWidth.tablet}px) and (max-width: ${BreakpointWidth.desktopLarge}px) {
    padding: 8px;
  }
`;

const Ribbon = styled.div<{ color: string }>`
  position: absolute;
  top: 12px;
  left: 12px;
  background: ${brand.accent};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 10px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  padding: 5px 4px 4px 4px;
  min-width: 60px;
  z-index: 3;

  ${({ color }) => `color: ${color};`}
`;
