import { spacing } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClient } from '@peloton/api/ClientContext';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import { usePostalCodeContext } from '@ecomm/postal-code/PostalCodeContext';
import { ProductStates } from '@ecomm/product-states/models';
import checkPostalCodeEligibility from '@ecomm/shop/api/checkPostalCodeEligibility';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import type {
  TypeComponentFormFields,
  TypeComponentLeadGenFields,
} from '@page-builder/lib/types';
import { useColorSelectionContext } from '@page-builder/modules/Overview/ColorSelectionContext';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import OtdEstimateWithPostalCode from './OtdEstimateWithPostalCode';
import PostalCodeInput from './PostalCodeInput';
import PreorderEstimate from './PreorderEstimate';
import SubmitEmailLeadGen from './SubmitEmailLeadGen';
import useRequiresPostalCode from './useRequiresPostalCode';

export type Props = {
  connectedFitnessUnitSlug?: string;
  emailLeadGenFields?: TypeComponentLeadGenFields;
  postalCodeInputFields?: TypeComponentFormFields;
  productBundleType: BundleType;
  productEquipmentType: EquipmentType;
  soldOutLeadGenFields?: TypeComponentLeadGenFields;
};

enum FormState {
  InitialLoad = 'initialLoad',
  PostalCodeRequired = 'postalCodeRequired',
  Unavailable = 'unavailable',
  SoldOut = 'soldOut',
  Available = 'available',
  AvailablePreorder = 'availablePreorder',
}

const ProductAvailabilityController: React.FC<React.PropsWithChildren<Props>> = ({
  connectedFitnessUnitSlug,
  emailLeadGenFields,
  postalCodeInputFields,
  productBundleType,
  productEquipmentType,
  soldOutLeadGenFields,
}) => {
  const { postalCode } = usePostalCodeContext();
  const { productPackage, setIsPostalCodeEligible } = useShopContext();
  const { activeColorCaption } = useColorSelectionContext();
  const [isLoadingEligibility, setIsLoadingEligibility] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const productSlug = productPackage?.slug;
  const connectedFitnessUnit = productPackage?.connectedFitnessUnit;
  const cfuSku = connectedFitnessUnit?.slug;

  const availabilityRequiresPostalCode = useRequiresPostalCode()(productBundleType);

  const availabilityState = productPackage?.availability.state;
  // preorder availability state set on product cfu in CMS
  const isPreorder =
    productPackage?.connectedFitnessUnit?.availability?.state ===
    ProductStates.AvailablePreorder;
  const isAvailable =
    availabilityState === ProductStates.Available &&
    !availabilityRequiresPostalCode &&
    !isPreorder;
  const isSoldOut = availabilityState === ProductStates.UnavailableShopSoldOut;

  const getInitialFormState = (): FormState => {
    if (isAvailable) {
      return FormState.Available;
    } else if (isSoldOut) {
      return FormState.SoldOut;
    } else if (isPreorder) {
      return FormState.AvailablePreorder;
    } else if (postalCode) {
      return FormState.InitialLoad;
    } else if (availabilityRequiresPostalCode) {
      return FormState.PostalCodeRequired;
    } else {
      return FormState.Unavailable;
    }
  };

  const [formState, setFormState] = useState<FormState>(getInitialFormState());

  const client = useClient();
  useEffect(() => {
    (async () => {
      if (isAvailable) {
        setFormState(FormState.Available);
      } else if (isSoldOut) {
        setFormState(FormState.SoldOut);
      } else if (isPreorder) {
        setFormState(FormState.AvailablePreorder);
      } else if (
        connectedFitnessUnitSlug &&
        postalCode &&
        availabilityRequiresPostalCode
      ) {
        setIsLoadingEligibility(true);

        try {
          const isEligible = await checkPostalCodeEligibility(
            client,
            connectedFitnessUnitSlug,
            postalCode,
          );
          const newFormState = isEligible ? FormState.Available : FormState.Unavailable;
          setFormState(newFormState);
          setIsPostalCodeEligible(isEligible);
          // eslint-disable-next-line no-useless-catch
        } catch (e) {
          setHasError(true);
        } finally {
          setIsLoadingEligibility(false);
        }
      }
    })();
  }, [
    availabilityState,
    client,
    connectedFitnessUnitSlug,
    productSlug,
    postalCode,
    availabilityRequiresPostalCode,
    setIsPostalCodeEligible,
    isAvailable,
    isSoldOut,
    isPreorder,
  ]);

  const onTogglePostalCode = () => {
    setFormState(FormState.PostalCodeRequired);
    setHasError(false);
  };

  switch (formState) {
    case FormState.AvailablePreorder:
      return (
        <Wrapper>
          <PreorderEstimate />
        </Wrapper>
      );
    case FormState.Available:
      // Available (potentially after entering postal code)
      return (
        <Wrapper>
          <OtdEstimateWithPostalCode
            productEquipmentType={productEquipmentType}
            postalCodeInputFields={postalCodeInputFields}
            cfuSku={cfuSku}
          />
        </Wrapper>
      );
    case FormState.Unavailable:
    case FormState.SoldOut: {
      // Not available (potentially after entering postal code); enter email to be notified when available
      const activeLeadGenFields =
        formState === FormState.SoldOut ? soldOutLeadGenFields : emailLeadGenFields;
      return (
        <Wrapper>
          <SubmitEmailLeadGen
            emailLeadGenFields={activeLeadGenFields}
            eyebrowValues={{ colorCaption: activeColorCaption }}
            postalCode={postalCode}
            onTogglePostalCode={onTogglePostalCode}
          />
        </Wrapper>
      );
    }
    case FormState.PostalCodeRequired:
      // Availability unknown; enter postal code to check
      return (
        <Wrapper>
          <PostalCodeInput
            postalCodeInputFields={postalCodeInputFields}
            isLoadingEligibility={isLoadingEligibility}
            hasError={hasError}
            renderHeadings
          />
        </Wrapper>
      );
    default:
      return null;
  }
};

export default ProductAvailabilityController;

const Wrapper = styled.div`
  margin-bottom: ${spacing[40]};
`;
