import { spacing, Icon } from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { THEMES as BANNER_THEMES } from '@ecomm/cms-promo-banner/themes';
import type { TypeComponentBannerFields } from '@page-builder/lib/types';

type MarkdownIconProps = {
  iconName: IconNames;
  bannerTheme: TypeComponentBannerFields['theme'];
  isUnclickable?: boolean;
};

export const MarkdownIcon: React.FC<React.PropsWithChildren<MarkdownIconProps>> = ({
  iconName,
  bannerTheme,
  isUnclickable = false,
}) => {
  const iconColor = bannerTheme && BANNER_THEMES[bannerTheme].color;

  const IconContainer = isUnclickable
    ? UnclickableBannerIconContainer
    : ClickableIconContainer;

  return (
    <IconContainer style={{ height: '20px' }}>
      <Icon
        name={iconName}
        height={20}
        primaryColor={iconColor}
        style={{ verticalAlign: 'middle' }}
      />
    </IconContainer>
  );
};

const UnclickableBannerIconContainer = styled.span`
  padding-right: ${spacing[8]};
  display: inline-block;
`;

const ClickableIconContainer = styled.span`
  padding-right: ${spacing[8]};

  ${media.tabletLarge`
    padding-right: 0px;
`}
`;
