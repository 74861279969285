import { Button, Flex } from '@pelotoncycle/design-system';
import type {
  TypeComponentCta,
  TypeComponentGenericList,
} from '@pelotoncycle/page-builder';
import React, { useState } from 'react';
import { LinkButton } from '@peloton/next/components/LinkButton';
import type { ModalProps } from '@page-builder/modules/FullWidthMediaModule/FullWidthMediaModuleModal';
import { toCtaFields } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import StickyCta from './StickyCta';
import useStickyProps from './useStickyProps';

type CTAProps = {
  cta: TypeComponentCta;
  Modal?: React.FC<ModalProps>;
  handleOnClick?: () => void;
  parent: { parentName: string; parentType: string };
};

const LinkOrModalCTA: React.FC<CTAProps> = ({ cta, Modal, handleOnClick, parent }) => {
  const [ctaFields] = toCtaFields([cta]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { activeCtaRef, isStickyCta, showStickyCta } = useStickyProps(cta);

  if (ctaFields.link) {
    return (
      <Flex ref={activeCtaRef}>
        <LinkButton
          href={ctaFields.link.fields.url}
          color={ctaFields.color}
          variant={ctaFields.variant}
          size="medium"
          width="adjustable"
          onClick={handleOnClick}
        >
          {ctaFields.text}
        </LinkButton>
        {isStickyCta && (
          <StickyCta
            cta={cta}
            showStickyCta={showStickyCta}
            handleOnClick={handleOnClick}
          />
        )}
      </Flex>
    );
  }

  if (ctaFields.modal) {
    return (
      <>
        <Flex ref={activeCtaRef}>
          <Button
            onClick={() => setIsModalOpen(true)}
            color={ctaFields.color}
            variant={ctaFields.variant}
            size="medium"
            width="adjustable"
          >
            {ctaFields.text}
          </Button>
          {isStickyCta && <StickyCta cta={cta} showStickyCta={showStickyCta} />}
        </Flex>

        {isEntryType<TypeComponentGenericList>(ctaFields.modal, 'text') && Modal && (
          <Modal
            isModalOpen={isModalOpen}
            onModalClose={() => setIsModalOpen(false)}
            modalContent={ctaFields.modal}
            linkName={ctaFields.text}
            parent={{ ...parent }}
          />
        )}
      </>
    );
  }

  return null;
};

export default LinkOrModalCTA;
