import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import type { InViewHookResponse } from 'react-intersection-observer';
import { useInView } from 'react-intersection-observer';
import toBundleType from '@ecomm/graphql-bridge/EquipmentType/toBundleType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import type { AvailabilityState } from '@ecomm/product-states/models/availability';
import {
  UNAVAILABLE,
  isAvailableForPurchase,
} from '@ecomm/product-states/models/availability';
import { ProductStates } from '@ecomm/product-states/models/productState';
import type {
  CommercetoolsPackageQuery,
  CommercetoolsPackage,
} from '@ecomm/shop-configuration/models';
import type { PackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import type { BundleType } from '@ecomm/shop/models/BundleType';

type Setter<V> = Dispatch<SetStateAction<V>>;

export type InViewRef = InViewHookResponse[0];

type ProviderProps = {
  packageSlug: string;
  productPackage:
    | CommercetoolsPackageQuery['catalog']['packageBySlug']
    | PackageBySlugOptionalWarrantyQuery['catalog']['packageBySlugOptionalWarranty']
    | undefined;
  productPackageLoading: boolean;
};

export type ProductPackageType = ProviderProps['productPackage'] | CommercetoolsPackage;

export type ShopContextProps = {
  packageSlug: string;
  productEquipmentType?: EquipmentType;
  shouldFocusError: boolean;
  setShouldFocusError: Setter<boolean>;
  activeCtaRef: InViewRef;
  shouldShowHeadband: boolean;
  shouldShowMobileATCBand: boolean;
  productPackageLoading: boolean;
  productPackage?: ProductPackageType;
  productBundleType?: BundleType;
  isPostalCodeEligible: boolean;
  setIsPostalCodeEligible: Setter<boolean>;
};

const noop = () => {};

export const ShopContext = React.createContext<ShopContextProps>({
  packageSlug: '',
  productEquipmentType: undefined,
  shouldFocusError: false,
  setShouldFocusError: noop,
  activeCtaRef: noop,
  shouldShowHeadband: true,
  shouldShowMobileATCBand: true,
  productPackageLoading: true,
  isPostalCodeEligible: false,
  setIsPostalCodeEligible: noop,
});

export const ShopContextProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  packageSlug,
  productPackage,
  productPackageLoading,
  children,
}) => {
  const [shouldFocusError, setShouldFocusError] = React.useState(false);
  const [isPostalCodeEligible, setIsPostalCodeEligible] = React.useState(false);

  const availabilityState = !!productPackage
    ? productPackage.availability
    : { state: ProductStates.Unknown, code: UNAVAILABLE };

  const isProductAvailable = isAvailableForPurchase(
    availabilityState as AvailabilityState,
  );

  const [activeCtaRef, ctaInView, entry] = useInView();
  // entry is an IntersectionObserverEntry - when the y value is negative, that means the CTA has left the viewport
  // by moving towards negative y, which is the top of the screen. This effectively means that the user has scrolled
  // past the CTA
  const hasScrolledPastCta = (entry?.boundingClientRect.y || 0) < 0;
  const shouldShowHeadband = isProductAvailable && !ctaInView && hasScrolledPastCta;
  const shouldShowMobileATCBand = !ctaInView && isProductAvailable;

  const value = React.useMemo(() => {
    // This cast shouldn't be necessary, but the query types equipmentType as string instead of EquipmentType
    const productEquipmentType = productPackage?.equipmentType as EquipmentType;
    const productBundleType = productPackage
      ? toBundleType(productEquipmentType!)
      : undefined;

    return {
      productEquipmentType,
      shouldFocusError,
      setShouldFocusError,
      activeCtaRef,
      shouldShowHeadband,
      shouldShowMobileATCBand,
      productPackageLoading,
      productPackage,
      productBundleType,
      isPostalCodeEligible,
      setIsPostalCodeEligible,
      packageSlug,
    };
  }, [
    activeCtaRef,
    shouldFocusError,
    setShouldFocusError,
    shouldShowHeadband,
    shouldShowMobileATCBand,
    productPackageLoading,
    productPackage,
    isPostalCodeEligible,
    setIsPostalCodeEligible,
    packageSlug,
  ]);

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

export const useShopContext = () => React.useContext(ShopContext);
