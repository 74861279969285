import { Flex, Label, spacing, white } from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import { toCtaProps } from '@pelotoncycle/page-builder';
import React from 'react';
import { filterFalsyValues } from '@page-builder/utils/unions';
import { HighlightReelCta } from './HighlightReelCta';
import useTrackCardClick from './useTrackCardClick';

type Props = {
  items: TypeComponentGenericListFields['items'];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
};

const HighlightReelMobileCtas: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  activeIndex,
  setActiveIndex,
}) => {
  const onCardClick = useTrackCardClick();
  const ctas = items.map(item => item.fields.ctas?.[0]).filter(filterFalsyValues);

  return (
    <Flex minWidth="100%" gap={spacing[8]} as="ul">
      {ctas.map((cta, idx) => {
        const active = activeIndex === idx;
        const { text } = toCtaProps(cta);

        return (
          <Flex flexGrow={1} as="li" key={idx}>
            <HighlightReelCta
              onClick={() => {
                if (!active) {
                  onCardClick('Mobile Highlight Reel', cta);
                  setActiveIndex(idx);
                }
              }}
              active={active}
              as="button"
              disabled={active}
            >
              <Label size="medium" textColor={white}>
                {text}
              </Label>
            </HighlightReelCta>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default HighlightReelMobileCtas;
