import React from 'react';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import usePromoDiscount from '@content/client/hooks/usePromoDiscount';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { PromoPriceDisplay } from '../Promo/PromoPrice/PromoPrice';
import DisplayPrice, { PriceDisplay } from './DisplayPrice';

type Props = {
  price?: string;
  bundleType: BundleType;
};

const DisplayPriceWithPromo: React.FC<React.PropsWithChildren<Props>> = ({
  bundleType,
}) => {
  const financingValues = useFinancingNext(bundleType);
  const isToggleActive = useIsToggleActive();
  const showPromoPricing = isToggleActive('usePromoContentMgmt');
  const { data } = useCfuPromo(bundleType);
  const discount = usePromoDiscount(data);

  if (showPromoPricing) {
    return (
      <PriceDisplay>
        <PromoPriceDisplay price={financingValues.total} discount={discount} />
      </PriceDisplay>
    );
  }

  return <DisplayPrice bundleType={bundleType} displayValue="total" />;
};

export default DisplayPriceWithPromo;
