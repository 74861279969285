import React from 'react';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import { ProductRecommendationsGrid } from '@ecomm/product-recommendations';
import { useCustomPageData } from '@page-builder/hooks/useCustomPageData';

export type ProductRecommendationsCustomData = {
  cohort: ProductRecommendationsCohort;
};

export const CompleteYourCartProductRecommendations = () => {
  const customData = useCustomPageData<ProductRecommendationsCustomData>();

  if (!customData?.cohort?.cohortKeyName) {
    return null;
  }

  return (
    <ProductRecommendationsGrid
      cohort={customData.cohort}
      treatment={'Product Recommendations Upsell Grid'}
    />
  );
};
