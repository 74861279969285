import Slider from '@dior/react-slick';
import { Container, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';

const VideoCarousel: React.FC<
  React.PropsWithChildren<{
    beforeChange: (_: number, next: number) => void;
    slider: React.RefObject<Slider>;
  }>
> = ({ beforeChange, slider, children }) => {
  return (
    <Container margin={`${spacing[16]} 0 0 0`} width="auto">
      <ReactSlickGlobalStyle />
      {/*  @ts-expect-error (TODO:robinojw) @onedior/react-slick is not compatible with React 18's implicit children type  */}
      <Slider
        accessibility
        useAriaRole={false} // Avoid react-slick adding tabpanel role, which leads to a11y failures here
        arrows={false}
        centerMode={false}
        centerPadding={0}
        beforeChange={beforeChange}
        dots={false}
        draggable={false}
        infinite={false}
        initialSlide={0}
        speed={800}
        swipe={true}
        ref={slider}
        variableWidth={true}
        waitForAnimate
      >
        {children}
      </Slider>
    </Container>
  );
};

// Prevent desktop rendering issues when `VideoCarousel` is rendered via SSG/SSR
const fullVideoSliderWidth = 14346;
const ReactSlickGlobalStyle = createGlobalStyle`
  .slick-track {
   width: ${fullVideoSliderWidth}px !important;
  }
`;

export default VideoCarousel;
