import { spacing } from '@pelotoncycle/design-system';
import { createGlobalStyle, css } from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';

export const MODAL_CLOSE_TRANSITION_TIME = 400;

const lessThanDesktopLargePortrait = (...args: any[]) => css`
  @media only screen and (max-width: ${BreakpointWidth.desktopLarge - 1}px) {
    ${css.call(this, ...args)}
  }
`;

// these global styles are used in the QuickViewModal and CompareBikesModal
//    to have the modal scroll up from the bottom on mobile
export const ScrollFromBottomModalStyles = createGlobalStyle`
  div.ReactModal__Content.scroll-from-bottom-modal {
    margin: 0 auto;

    ${media.desktopLarge`
      margin: 30px 0 90px;
    `}
  }


  div.ReactModal__Content.scroll-from-bottom-modal > button:first-of-type {
    position: sticky;
    top: 0;

    padding-right: ${spacing[16]};

    ${media.desktopLarge`
      padding-right: 0;
    `}

    & svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  ${lessThanDesktopLargePortrait`
    .ReactModalPortal .ReactModal__Content.scroll-from-bottom-modal {
      bottom: -100%;
      position: fixed;
      transition: all ${MODAL_CLOSE_TRANSITION_TIME}ms ease;
    }
    .ReactModal__Content.ReactModal__Content--after-open.scroll-from-bottom-modal {
      bottom: 0%;
    }
    .ReactModal__Content.ReactModal__Content--before-close.scroll-from-bottom-modal {
      bottom: -100%;
    }

    .ReactModal__Overlay.ReactModal__Overlay--before-close {
      transition: background-color ${MODAL_CLOSE_TRANSITION_TIME}ms ease !important;
      background-color: rgba(0, 0, 0, 0) !important;
      opacity: 1 !important;
    }

    .quick-view-modal {
      max-height: calc(100dvh - 50px);
      overflow-y: scroll;
      height: 100%;
    }
  `}

`;
