import { createGlobalStyle } from 'styled-components';

const zIndex = {
  OVERLAY: 100,
};

const ModalGlobalStyles = createGlobalStyle`
  .ReactModalPortal .ReactModal__Overlay--after-open  {
    z-index: ${zIndex.OVERLAY};
    height: 100%;
  }
`;

export default ModalGlobalStyles;
