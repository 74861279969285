import { spacing } from '@pelotoncycle/design-system';
import { createGlobalStyle, css } from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';

const onlyMobileBreakpoint = (...args: any[]) => css`
  @media only screen and (max-width: ${BreakpointWidth.tablet - 1}px) {
    ${css.call(this, ...args)}
  }
`;

export const ViewportHeroModalStyles = createGlobalStyle`
  div.ReactModal__Content {
    ${onlyMobileBreakpoint`
      display: flex;
      flex-direction: column;
      height: 100vh;
      margin: 0;
    `}

    ${media.desktopLarge`
      margin: 30px 0 90px;
    `}
  }

  div.ReactModal__Content > button:first-of-type {
    padding: ${spacing[16]};

    & svg {
      height: 24px !important;
      width: 24px !important;
    }
  }
`;
