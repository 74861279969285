import { isProdEnv } from '@peloton/env/models';

export const PROD_APP_ONE_TIER_ID = '06207e0e85f54b6b86233d8ec8a7d3a8';
export const PROD_APP_PLUS_TIER_ID = 'e1c5024c76b046d08341e834b605b2fa';

export const STAGE_APP_ONE_TIER_ID = '2ba59815379449c2bb024f0670b532d5';
export const STAGE_APP_PLUS_TIER_ID = 'a563d1d00ea14b9697f748606d5e9145';

export const getTierIdFromProductName = (productName: string) => {
  if (isProdEnv('www')) {
    return productName.includes('App One') ? PROD_APP_ONE_TIER_ID : PROD_APP_PLUS_TIER_ID;
  } else {
    return productName.includes('App One')
      ? STAGE_APP_ONE_TIER_ID
      : STAGE_APP_PLUS_TIER_ID;
  }
};
