import type { ApolloError } from '@apollo/client';
import React from 'react';
import type {
  AccessoryCommerceTools,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import type { TypeComponent_overviewFields } from '@page-builder/lib/types';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import useGetUpsellTagNames from '@page-builder/modules/Overview/useGetUpsellTagNames';
import useUpsellProducts from '@page-builder/modules/Overview/useUpsellProducts';
import { toUpsellProductSlugsForDrawer } from '@page-builder/modules/Overview/utils';

type ProductSlug = string;

export type AccessoryVariantsType = Record<
  ProductSlug,
  Record<ProductSlug, VariantCommerceTools | undefined>
>;

export type UpsellAccessoryContextProps = {
  upsellAccessories?: (AccessoryCommerceTools | BundleAccessoryType)[];
  upsellAccessoryLoading: boolean;
  upsellAccessoryError?: ApolloError;
  accessoryVariants: AccessoryVariantsType;
  setAccessoryVariant: (
    accessorySlug: ProductSlug, // top level slug, like cycling-shoes or bikes-starter-acc
    productSlug: ProductSlug, // product within the accessory slug; for regular accessories, same as top level, for bundles, cycling-shoes or dumbbells
    variant?: VariantCommerceTools,
  ) => void;
  clearSelectedVariants: (accessorySlug?: ProductSlug) => void;
  upsellAccessoriesSelected: ProductSlug[];
  setAccessorySelected: (slug: ProductSlug, isSelected: boolean) => void;
  upsellAccessoriesMisconfigured: ProductSlug[];
  setAccessoryMisconfigured: (slug: ProductSlug, isMisconfigured: boolean) => void;
};

export const UpsellAccessoryContext = React.createContext<UpsellAccessoryContextProps>({
  accessoryVariants: {},
  upsellAccessoriesSelected: [],
  setAccessorySelected: () => {},
  upsellAccessoriesMisconfigured: [],
  setAccessoryMisconfigured: () => {},
  setAccessoryVariant: () => {},
  clearSelectedVariants: () => {},
  upsellAccessories: undefined,
  upsellAccessoryLoading: true,
  upsellAccessoryError: undefined,
});

type ProviderProps = Pick<TypeComponent_overviewFields, 'product'>;

const setSlugPresence = (
  existingValues: ProductSlug[],
  slug: ProductSlug,
  shouldAddSlugToArray: boolean,
): ProductSlug[] => {
  if (!shouldAddSlugToArray) {
    return existingValues.filter(s => s !== slug);
  }
  if (!existingValues.includes(slug)) {
    return [...existingValues, slug];
  }
  return existingValues;
};

export const UpsellAccessoryContextProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ product, children }) => {
  const { activeShopDrawer } = useDrawerSelectionContext();
  const [accessoryVariants, _setAccessoryVariants] = React.useState<
    UpsellAccessoryContextProps['accessoryVariants']
  >({});
  const [upsellAccessoriesSelected, setUpsellAccessoriesSelected] = React.useState<
    ProductSlug[]
  >([]);
  const [
    upsellAccessoriesMisconfigured,
    setUpsellAccessoriesMisconfigured,
  ] = React.useState<ProductSlug[]>([]);

  const setAccessoryVariant: UpsellAccessoryContextProps['setAccessoryVariant'] = React.useCallback(
    (accessorySlug, productSlug, variant) => {
      _setAccessoryVariants(prevState => {
        return {
          ...prevState,
          [accessorySlug]: {
            ...prevState[accessorySlug],
            [productSlug]: variant,
          },
        };
      });
    },
    [],
  );

  const clearSelectedVariants: UpsellAccessoryContextProps['clearSelectedVariants'] = React.useCallback(
    accessorySlug => {
      _setAccessoryVariants(prevState => {
        if (!accessorySlug) {
          return {};
        }

        const newValue = {};
        for (const [key, value] of Object.entries(prevState)) {
          if (key !== accessorySlug) {
            newValue[key] = value;
          }
        }

        return newValue;
      });
    },
    [],
  );

  const setAccessorySelected: UpsellAccessoryContextProps['setAccessorySelected'] = React.useCallback(
    (slug, isSelected) => {
      return setUpsellAccessoriesSelected(prevState =>
        setSlugPresence(prevState, slug, isSelected),
      );
    },
    [],
  );

  const setAccessoryMisconfigured: UpsellAccessoryContextProps['setAccessoryMisconfigured'] = React.useCallback(
    (slug, isMisconfigured) => {
      return setUpsellAccessoriesMisconfigured(prevState =>
        setSlugPresence(prevState, slug, isMisconfigured),
      );
    },
    [],
  );

  const {
    fields: { productId },
  } = product;

  const tagNames = useGetUpsellTagNames(productId);
  const upsellSlugs = activeShopDrawer
    ? toUpsellProductSlugsForDrawer(activeShopDrawer, tagNames)
    : [];

  const {
    loading: upsellAccessoryLoading,
    error: upsellAccessoryError,
    bundles,
    products,
  } = useUpsellProducts(upsellSlugs);

  const upsellItems = [...bundles, ...products];
  const upsellAccessories = upsellItems.length ? upsellItems : undefined;
  // This ts-ignore is due to Apollo 3 upgrades
  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  const value = React.useMemo<UpsellAccessoryContextProps>(() => {
    return {
      accessoryVariants,
      setAccessoryVariant,
      clearSelectedVariants,
      upsellAccessoryLoading,
      upsellAccessories,
      upsellAccessoryError,
      upsellAccessoriesSelected,
      setAccessorySelected,
      upsellAccessoriesMisconfigured,
      setAccessoryMisconfigured,
    };
  }, [
    upsellAccessoryLoading,
    upsellAccessories,
    upsellAccessoryError,
    accessoryVariants,
    setAccessoryVariant,
    clearSelectedVariants,
    upsellAccessoriesSelected,
    setAccessorySelected,
    upsellAccessoriesMisconfigured,
    setAccessoryMisconfigured,
  ]);

  return (
    <UpsellAccessoryContext.Provider value={value}>
      {children}
    </UpsellAccessoryContext.Provider>
  );
};

export const useUpsellAccessoryContext = () => React.useContext(UpsellAccessoryContext);
