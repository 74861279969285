import { Eyebrow, Headline, spacing, Flex } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React, { useContext } from 'react';
import PageContext from '@page-builder/layout/context/PageContext';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import BlogCard from './BlogCard';
import { TEXT_MAXWIDTH } from './tokens';

export type Props = TypeComponentGenericListFields;

export const defaults = {
  theme: 'White',
} as const;

const BlogCards: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  text,
  theme = defaults.theme,
}) => {
  const { textColor, backgroundColor, headlineColor } = themes[theme];

  const { name: page } = useContext(PageContext);
  const { eyebrow, headline } = getGenericTextNodes(text, ['eyebrow', 'headline']);
  return (
    <OuterContainer
      theme={theme}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[24],
      }}
      verticalPadding={{
        mobile: spacing[32],
        desktop: spacing[80],
      }}
      backgroundColor={backgroundColor}
      flexDirection="column"
      justifyContent="center"
    >
      <Flex
        centered
        flexDirection="column"
        maxWidth={{ desktop: TEXT_MAXWIDTH.desktop }}
        textAlign="center"
        gap={spacing[16]}
      >
        <Eyebrow as="h2" textColor={textColor} data-test-id={`${page}BlogModuleEyebrow`}>
          {eyebrow}
        </Eyebrow>
        <Headline
          textColor={headlineColor}
          as="p"
          data-test-id={`${page}BlogModuleHeading`}
        >
          {headline}
        </Headline>
      </Flex>

      <Flex
        verticalPadding={{ mobile: `${spacing[32]} 0`, desktop: `${spacing[64]} 0` }}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        justifyContent="center"
        maxWidth={{ desktop: '1400px' }}
        width={{ desktop: '100%' }}
      >
        {items.map((card, index) => (
          <BlogCard key={card.sys.id} cardIndex={index} headingTag="h3" {...card} />
        ))}
      </Flex>
    </OuterContainer>
  );
};

export default BlogCards;
