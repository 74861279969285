import axios from 'axios';
import { camelizeKeys } from 'humps';
import { PELOFAX_API_BASE_URL } from '@peloton/app-config';
import type { HistoryAPIResponse, HistorySummary } from './types';

interface Props {
  email: string;
  serialNumber: string;
}

export const postHistorySummary = async ({
  email,
  serialNumber,
}: Props): Promise<HistoryAPIResponse> => {
  try {
    const response = await axios.post(
      `${PELOFAX_API_BASE_URL}/report`,
      {
        email,
        serial_number: serialNumber,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );

    if (!response.data.success) {
      return { data: null, success: false, message: response.data.message || '' };
    }

    return {
      data: (camelizeKeys(response.data) as unknown) as HistorySummary,
      success: response.data.success || false,
    };
  } catch (error) {
    if (error.response) {
      if (error.response?.status) {
        return {
          data: null,
          success: false,
          message: error.response.data.message,
          error: true,
        };
      }
    } else if (error.request) {
      return {
        data: null,
        success: false,
        error: true,
        limitError: true,
      };
    }
    return { data: null, success: false };
  }
};
