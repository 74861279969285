export const HEADER_HEIGHT_MOBILE = 48;
export const HEADER_HEIGHT_TABLET_XLARGE = 64;
export const HEADER_HEIGHT_DESKTOP_LARGE = 72;
export const HEADBAND_HEIGHT_MOBILE = 56;
export const HEADBAND_HEIGHT_TABLET_XLARGE = 80;

export const zIndex = {
  HEADER_DROPDOWN: 5,
  HEADER: 4,
  HEADBAND: 3,
};
