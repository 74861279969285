import { datadogRum } from '@datadog/browser-rum';
import { ProductStates } from '@ecomm/product-states/models';
import { AccessoryKeys } from '../constants';
import { hasCurrentChannelAvailability, toWebTld } from '../models/channelAvailability';
import type { ProductVariant } from '../types.generated';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';

export const getChannelAvailabilityStatus = (master: ProductVariant) => {
  const warningMessage = `Accessory [${master.key}] is not available for [${toWebTld()}]`;
  const isAvailable = hasCurrentChannelAvailability(
    getAttributeValueByName(master.attributesRaw, AccessoryKeys.CHANNEL_AVAILABILITY) ??
      [],
  );

  if (!isAvailable) {
    datadogRum.addError(warningMessage);

    return ProductStates.Unavailable;
  }

  return ProductStates.Available;
};
