import { CardGroup as CardGroupView } from '@pelotoncycle/page-builder';
import React from 'react';
import type { BlockProps } from '@page-builder/lib/pages';
import type { TypeComponentImageCardGroup } from '@page-builder/lib/types';
import { toCloudinaryImageCards } from '@page-builder/utils/helpers/media';
import ImageCardWrapper from './ImageCardWrapper';

type Props = BlockProps & TypeComponentImageCardGroup;

const CardGroup: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => {
  const cloudinaryImageCards = toCloudinaryImageCards(props);
  const newProps = {
    ...props,
    fields: {
      ...props.fields,
      imageCards: cloudinaryImageCards,
    },
  };
  return (
    <>
      <CardGroupView {...newProps} wrapper={ImageCardWrapper} />
    </>
  );
};

export default CardGroup;
