import type { MediaImageProps, MediaVideoProps } from '@pelotoncycle/design-system';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';
import PageContext from '@page-builder/layout/context/PageContext';
import CarouselCard from '../CarouselCard';
import ClassesCarouselVideo from '../ClassesCarouselVideo';
import InfiniteScrollCarousel from '../InfiniteScrollCarousel';
import 'slick-carousel/slick/slick.css'; // Anything using 'slick' requires this. Was also imported in apps/www/src/index.tsx
import { cellSizes } from './utils';

export type Card = {
  image: MediaImageProps | undefined;
  title: string;
  modalVideo: MediaVideoProps | undefined;
  tracks: {
    src: string;
    kind: 'subtitles' | 'descriptions' | 'captions';
  }[];
};

type Props = {
  cards: Card[];
  support: string;
  label: string;
};

const ClassesCarousel: React.FC<React.PropsWithChildren<Props>> = ({
  cards,
  support,
  label,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const { additionalTrackingData } = useContext(PageContext);
  const {
    isLoggedIn,
    hasDeviceSubscription,
    hasDigitalSubscription,
  } = additionalTrackingData;

  // a11y requirement
  useEffect(() => {
    const listWeNeedToChange = document.querySelector(
      '#valuesSection .slick-list .slick-track',
    );
    listWeNeedToChange?.setAttribute('role', 'group');
  }, []);

  const setModalState = (setValue: boolean) => {
    setIsModalOpen(setValue);
  };

  const openNextModal = () => {
    const nextIndex = currentVideoIndex + 1;
    if (nextIndex < cards.length) {
      setCurrentVideoIndex(nextIndex);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  const openPreviousModal = () => {
    const nextIndex = currentVideoIndex - 1;
    if (nextIndex >= 0) {
      setCurrentVideoIndex(nextIndex);
    } else {
      setCurrentVideoIndex(cards.length - 1);
    }
  };

  return (
    <Container aria-live="polite" id="valuesSection">
      <CarouselWrapper ariaLabelText={label} currentSlideText={support}>
        {cards &&
          cards.length > 0 &&
          cards.map((card: any, index: number) => {
            const { title, image } = card;
            const openModal = () => {
              setCurrentVideoIndex(index);
              setIsModalOpen(true);
            };
            return (
              <span key={title}>
                <button onClick={openModal} data-test-id={`videoModalButton_${index}`}>
                  <StyledCard title={title} image={image} index={index} lazy />
                </button>
              </span>
            );
          })}
      </CarouselWrapper>
      <ClassesCarouselVideo
        modalVideo={cards?.[currentVideoIndex]?.modalVideo?.src || ''}
        poster={cards?.[currentVideoIndex]?.modalVideo?.poster || ''}
        carouselLength={cards.length}
        header={cards[currentVideoIndex].title || ''}
        index={currentVideoIndex}
        isModalOpen={isModalOpen}
        openNextModal={openNextModal}
        openPreviousModal={openPreviousModal}
        setModalState={setModalState}
        trackingProps={{
          campaignName: 'Classes discipline video',
          additionalProps: {
            isLoggedIn,
            hasDeviceSubscription,
            hasDigitalSubscription,
          },
        }}
        tracks={cards[currentVideoIndex].tracks}
        currentSlideText={support}
        ariaLabelText={label}
      />
    </Container>
  );
};

export default ClassesCarousel;

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const StyledCard = styled(CarouselCard)`
  width: 166px;
  height: 184px;
  max-width: 166px;
  max-width: 184px;
  box-shadow: none;
  overflow: hidden;
  border-radius: 3px;

  ${media.tablet`
    width: 280px;
    height: 320px;
    max-width: 280px;
    max-height: 320px;
  `}
`;

type WrapperProps = {
  ariaLabelText: string;
  currentSlideText: string;
};

const CarouselWrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  ariaLabelText,
  currentSlideText,
}) => {
  return (
    <StyledInfiniteScrollCarousel
      swipe
      arrowPadding={60}
      cellSizes={cellSizes}
      name="classesCarousel"
      dots
      responsive={[
        {
          breakpoint: BreakpointWidth.desktop,
          settings: {
            centerMode: true,
          },
        },
        {
          breakpoint: BreakpointWidth.tabletXLarge,
          settings: {
            arrows: false,
            centerMode: true,
          },
        },
      ]}
      ariaLabelText={ariaLabelText}
      currentSlideText={currentSlideText}
    >
      {children}
    </StyledInfiniteScrollCarousel>
  );
};

const StyledInfiniteScrollCarousel = styled(InfiniteScrollCarousel)`
  cursor: ew-resize;
  position: relative;

  .slick-slide.slick-active button {
    background-color: transparent;
  }
`;
