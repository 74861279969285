import { Container, black, grey, spacing, Flex } from '@pelotoncycle/design-system';
import type { TypeProductFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import type { BundleType } from '@ecomm/shop/models';
import type {
  TypeComponentGenericList,
  TypeComponentProductCard,
} from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import { Divider } from '../ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import CompareBikesModal from './CompareBikesModal';
import CrossShopToggle from './CrossShopToggle';
import { useActiveButton } from './crossShopToggleUtils';

type Props = {
  crossShopToggle: TypeComponentProductCard[];
  currentProductId: TypeProductFields['productId'];
};

const CrossShopSection: React.FC<React.PropsWithChildren<Props>> = ({
  crossShopToggle,
  currentProductId,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  // gets current button side
  const [leftButton, rightButton] = crossShopToggle;
  const activeButton = useActiveButton(leftButton, rightButton, currentProductId);

  // gets the cta/modal for the active button
  const { ctas } = activeButton.fields;

  // cta fields for active button and cta fields for both buttons
  const activeCta = ctas && toCtaFields(ctas)[0];
  const bikeAndBikePlusCtaData = crossShopToggle.map(
    button => toCtaFields(button.fields.ctas!)[0],
  );

  const { ctas: leftButtonCtas } = leftButton.fields;
  const { ctas: rightButtonCtas } = rightButton.fields;

  const compareBikesText =
    (leftButtonCtas && toCtaFields(leftButtonCtas)[0].text) ||
    (rightButtonCtas && toCtaFields(rightButtonCtas)[0].text);

  return (
    <Container verticalPadding={`0 ${spacing[32]}`}>
      <CrossShopToggle
        crossShopToggle={crossShopToggle}
        currentProductId={currentProductId}
      />
      {compareBikesText && (
        <Flex justifyContent="center">
          <ModalButton onClick={() => setIsModalOpen(true)}>
            {compareBikesText}
          </ModalButton>
        </Flex>
      )}
      <StyledDivider topPadding={0} />

      {activeCta &&
        activeCta.modal &&
        isEntryType<TypeComponentGenericList>(activeCta.modal, 'text') && (
          <CompareBikesModal
            activeModal={activeCta.modal}
            bikeAndBikePlusCtaData={bikeAndBikePlusCtaData}
            isModalOpen={isModalOpen}
            onModalClose={() => setIsModalOpen(false)}
            productBundleType={
              activeButton.fields.product.fields.bundleType as BundleType
            }
          />
        )}
    </Container>
  );
};

const ModalButton = styled.button`
  border-bottom: solid 1px ${black};
  font-size: 14px;

  &:hover {
    color: ${grey[70]};
    border-bottom: solid 1px ${grey[70]};
    transition: all 300ms ease;
  }
`;

const StyledDivider = styled(Divider)`
  padding-top: ${spacing[24]};

  ${media.tablet`
    padding-top: ${spacing[32]};
  `}
`;
export default CrossShopSection;
