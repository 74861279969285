import { useEffect } from 'react';
import useSWR from 'swr';
import { useLocale } from '@peloton/internationalize';
import { IMMUTABLE } from '@content/client/revalidation';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { useIsCTAvailabilityActive } from '@ecomm/commercetools/hooks/useIsCTAvailabilityActive';
import fetchSpareParts from '@ecomm/commercetools/utils/fetchSpareParts';
import type { SparePartsListCommerceTools } from '@page-builder/modules/SpareParts/models';
import { SPARE_PARTS_DATA_SWR_KEY } from './swrKeys';

const useSparePartDataForPage = (): SparePartsListCommerceTools => {
  const client = useCommercetoolsClient()!;
  const locale = useLocale();
  const isCTAvailabilityActive = useIsCTAvailabilityActive();

  const fetcher = async () => {
    return fetchSpareParts({
      client,
      locale,
      isCTAvailabilityActive,
      fetchPolicy: 'network-only', // Ensures that we're always getting the latest data, not serving from cache
    });
  };

  const { data: productData, mutate } = useSWR(
    SPARE_PARTS_DATA_SWR_KEY,
    fetcher,
    IMMUTABLE,
  );

  // We don't need to continuously revalidate product data, so we mark the config as immutable but then mutate it
  // on mount, which forces a refetch of the product data a single time
  useEffect(() => {
    mutate();
  }, [mutate]);

  return productData;
};

export default useSparePartDataForPage;
