import { truncateReference } from '@content/client/lib/truncateComponentJsonReference';
import type {
  TypeComponentCta,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import type { TableOfContentsProps } from './TableOfContents';
import type { LegacyTOCItemsProps, TOCItemLinkProps, TOCItemsProps } from './types';

// When neighboring modules share the same theme, we reduce top-padding in OuterContainer component.
// So when computing height, we should re-add that padding as buffer.

// relatedSection represents the TOC div, so we must compare its neighbors.
const getSharedThemeBuffer = (relatedSection: HTMLElement) => {
  const previousModule = relatedSection.previousElementSibling;
  const prevModuleTheme = previousModule?.getAttribute('data-theme');

  const nextModule = relatedSection.nextElementSibling;
  const nextModuleTheme = nextModule?.getAttribute('data-theme');
  const prevModuleOuterContainerSection = previousModule?.firstElementChild;

  if (
    prevModuleTheme &&
    prevModuleTheme === nextModuleTheme &&
    prevModuleOuterContainerSection
  ) {
    const bottomPadding = window.getComputedStyle(prevModuleOuterContainerSection)
      .paddingBottom;
    return parseInt(bottomPadding) || 0;
  }

  return 0;
};

export const scrollToSection = (
  sectionId?: string,
  headbandRef?: HTMLDivElement | HTMLElement | null,
  options?: {
    scrollBehavior?: ScrollBehavior;
  },
) => {
  if (!sectionId) {
    return;
  }

  const jumpScrollId = sectionId.split('#')[1];
  const relatedSection = document.getElementById(jumpScrollId);

  if (relatedSection) {
    const tocHeight = headbandRef ? headbandRef.offsetHeight : 0;
    const sharedThemeBuffer = getSharedThemeBuffer(relatedSection);
    const offset = tocHeight + sharedThemeBuffer;

    const y = relatedSection.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: y, behavior: options?.scrollBehavior ?? 'smooth' });
  }
};

const ctaToTOCItemLink = (cta: TypeComponentCta): TOCItemLinkProps => {
  return {
    text: cta.fields.text,
    link: cta.fields.link ? truncateReference(cta.fields.link) : { url: '' },
    variant: cta.fields.variant,
  };
};

export const toPbxTOCItemsProps = (props: LegacyTOCItemsProps): TOCItemsProps => {
  const transformedCtas = props.ctas
    ? props.ctas.map((cta: TypeComponentCta) => ctaToTOCItemLink(cta))
    : ([{ text: '', link: { url: '' }, variant: 'outline' }] as TOCItemLinkProps[]);

  return {
    ctas: transformedCtas,
    headbandRef: props.headbandRef,
    parentName: props.parentName,
    activeId: props.activeId,
  };
};

export const toPbxTOCProps = (
  props: TypeComponentGenericListFields,
): TableOfContentsProps => {
  const transformedCtas = props.ctas?.map((cta: TypeComponentCta) =>
    ctaToTOCItemLink(cta),
  );

  return {
    ctas: transformedCtas,
    title: getTextFieldsWithRequiredKeys(['eyebrow'], props.text).eyebrow,
  };
};
