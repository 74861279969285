import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { toCtaProps } from '@pelotoncycle/page-builder';
import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';

const useTrackCardClick = () => {
  const { trackEvent } = useTracking();

  return (unitName: string, cta?: TypeComponentCta) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        unitName,
        linkName: cta ? toCtaProps(cta).text : '',
      },
    });
  };
};

export default useTrackCardClick;
