import React, { createContext, useState, useContext, useEffect } from 'react';

export type LoadMoreContext = {
  page: number;
  isLoading: boolean;
  isLoadingPrevious: boolean;
  isLoadingNext: boolean;
  isComplete: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  incrementPage: () => void;
  decrementPage: () => void;
  previousPageHref: string | null;
  nextPageHref: string | null;
  highestLoadedPage: number;
  lowestLoadedPage: number;
};

export type PaginationState = {
  highestLoadedPage: number;
  lowestLoadedPage: number;
};

export type LoadMoreProviderProps = {
  page: number;
  setPage: (page: number, previousPage: number, state: PaginationState) => void;
  totalPages: number;
  getPageHref?: (page: number) => string;
  initialState?: PaginationState;
  listingKey?: string;
};

const Context = createContext<LoadMoreContext | null>(null);

export const LoadMoreProvider: React.FC<
  React.PropsWithChildren<LoadMoreProviderProps>
> = ({ page, setPage, totalPages, getPageHref, initialState, listingKey, children }) => {
  const [highestLoadedPage, setHighestLoadedPage] = useState(
    initialState?.highestLoadedPage ?? page,
  );
  const [lowestLoadedPage, setLowestLoadedPage] = useState(
    initialState?.lowestLoadedPage ?? page,
  );
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [registeredListingKey, setRegisteredListingKey] = useState(listingKey);

  const isLoading = isLoadingPrevious || isLoadingNext;
  const hasPrevious = !!totalPages && lowestLoadedPage !== 1;
  const hasNext = !!totalPages && highestLoadedPage !== totalPages;
  const isComplete = !hasPrevious && !hasNext;

  useEffect(() => {
    const isSameListingKey = registeredListingKey === listingKey;
    if (isSameListingKey) return;
    setRegisteredListingKey(listingKey);
    setHighestLoadedPage(page);
    setLowestLoadedPage(page);
  }, [listingKey]);

  const incrementPage = async () => {
    if (isLoading || !hasNext) {
      return;
    }
    setIsLoadingNext(true);
    const newPage = highestLoadedPage + 1;
    const newState = { highestLoadedPage: newPage, lowestLoadedPage };
    await setPage(newPage, page, newState);
    setHighestLoadedPage(newPage);
    setIsLoadingNext(false);
  };

  const decrementPage = async () => {
    if (isLoading || !hasPrevious) {
      return;
    }
    setIsLoadingPrevious(true);
    const newPage = lowestLoadedPage - 1;
    const newState = { highestLoadedPage, lowestLoadedPage: newPage };
    await setPage(newPage, page, newState);
    setLowestLoadedPage(newPage);
    setIsLoadingPrevious(false);
  };

  const previousPageHref =
    getPageHref && hasPrevious ? getPageHref(lowestLoadedPage - 1) : null;
  const nextPageHref = getPageHref && hasNext ? getPageHref(highestLoadedPage + 1) : null;

  const state = {
    page,
    isLoading,
    isLoadingPrevious,
    isLoadingNext,
    isComplete,
    hasPrevious,
    hasNext,
    incrementPage,
    decrementPage,
    previousPageHref,
    nextPageHref,
    highestLoadedPage,
    lowestLoadedPage,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useLoadMoreContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Missing LoadMoreProvider');
  }
  return context;
};
