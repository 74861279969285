import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import type { BucketedProducts } from '@ecomm/commercetools/hooks/useProductsByKeys';
import type {
  AccessoryCommerceTools,
  AccessoryBundleCommercetools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { toCommonProductFieldsFromBundle } from '@ecomm/product-recommendations/utils/mappers/toCommonProductFieldsFromBundle';
import { toProductFromAccessory } from '@ecomm/product-recommendations/utils/mappers/toProductFromAccessory';
import { toProductFromCfuPackage } from '@ecomm/product-recommendations/utils/mappers/toProductFromCfuPackage';
import { toProductFromSparePart } from '@ecomm/product-recommendations/utils/mappers/toProductFromSparePart';
import type { AvailabilityState } from '@ecomm/product-states/models/availability';
import { isAvailableForPurchase } from '@ecomm/product-states/models/availability';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration';
import type { SparePartCommerceTools } from '@page-builder/modules/SpareParts/models';

export const toProductFromCommerceToolsAccessory = (
  accessory: AccessoryCommerceTools,
): Product | undefined => {
  if (!isAvailableForPurchase(accessory.availability)) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  return {
    ...toProductFromAccessory(accessory),
    name: accessory.displayName || accessory.name,
    imageUrl: accessory.images[0]?.src || '',
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    ctProduct: accessory,
  };
};

export const toProductFromCommerceToolsSparePart = (
  sparePart: SparePartCommerceTools,
): Product | undefined => {
  if (!isAvailableForPurchase(sparePart.availability)) {
    return undefined;
  }

  return {
    ...toProductFromSparePart(sparePart),
    name: sparePart.displayName ?? sparePart.name,
    imageUrl: sparePart.images[0]?.src || '',
  };
};

export const toProductFromCommerceToolsBundle = (
  bundle: AccessoryBundleCommercetools,
): Product | undefined => {
  if (!isAvailableForPurchase(bundle.availability)) {
    return undefined;
  }

  return {
    ...toCommonProductFieldsFromBundle(bundle),
    id: bundle.legacyBundleId,
    name: bundle.displayName || bundle.name,
    ctProduct: bundle,
  };
};

export const toProductFromCommerceToolsPackage = (
  pkg: CommercetoolsPackage,
): Product | undefined => {
  if (!isAvailableForPurchase(pkg.availability as AvailabilityState)) {
    return undefined;
  }

  const mappedProduct = toProductFromCfuPackage(pkg);
  const resultProduct = {
    ...mappedProduct,
    imageUrl: pkg.image?.src || mappedProduct.imageUrl,
    images: pkg.image ? [pkg.image.src] : mappedProduct.images,
    ctProduct: pkg,
  };
  // eslint-disable-next-line content/no-copy-type-assertions
  return resultProduct as Product;
};

export const toRecommendedProductsForCohort = (
  cohort: ProductRecommendationsCohort,
  bucketedProducts: BucketedProducts,
) => {
  const products: Product[] = [];

  for (const productTile of cohort.products) {
    const matchingAccessory = bucketedProducts.accessories[productTile.slug];
    const matchingBundle = bucketedProducts.bundles[productTile.slug];
    const matchingCfuPackage = bucketedProducts.cfuPackages[productTile.slug];

    let mappedProduct: Product | undefined;

    if (matchingAccessory) {
      mappedProduct = toProductFromCommerceToolsAccessory(matchingAccessory);
    } else if (matchingBundle) {
      mappedProduct = toProductFromCommerceToolsBundle(matchingBundle);
    } else if (matchingCfuPackage) {
      mappedProduct = toProductFromCommerceToolsPackage(matchingCfuPackage);
    }

    if (mappedProduct) {
      const name = productTile.overrideName || mappedProduct.name;

      products.push({
        ...mappedProduct,
        ...productTile,
        name,
      });
    }
  }

  return products;
};
