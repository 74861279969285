import { useLocale } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { BundleType } from '@ecomm/shop/models/BundleType';

export type MockupStrikethroughPrice = {
  isActive: boolean;
  mockupStrikethroughPriceOffset: number;
  slug?: string;
};

export const StrikethroughPriceOffsetMap = {
  '': {
    [BundleType.Bike]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 25000,
      },
    },
    [BundleType.RefurbishedBike]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 15000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 20000,
      },
    },
    [BundleType.RefurbishedBikePlus]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 55000,
      },
    },
    [BundleType.BikePlus]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 25000,
      },
    },
    [BundleType.Tread]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 30000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 25000,
      },
    },
    [BundleType.TreadPlus]: {},
    [BundleType.RainforestCafe]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 10000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 10000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 13000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 5000,
      },
    },
    [BundleType.Row]: {},
  },
  AUwinterpromo2023: {
    [BundleType.Bike]: {
      'en-AU': {
        'bike-basics-package-au': {
          mockupStrikethroughPriceOffset: 20000,
        },
        'bike-starter-package-au': {
          mockupStrikethroughPriceOffset: 40000,
        },
        'bike-ultimate-package-au': {
          mockupStrikethroughPriceOffset: 50000,
        },
      },
    },
    [BundleType.BikePlus]: {
      'en-AU': {
        'bike-plus-basics-package-au': {
          mockupStrikethroughPriceOffset: 40000,
        },
        'bike-plus-starter-package-au': {
          mockupStrikethroughPriceOffset: 50000,
        },
        'bike-plus-ultimate-package-au': {
          mockupStrikethroughPriceOffset: 60000,
        },
      },
    },
    [BundleType.Tread]: {
      'en-AU': {
        'tread-basics-package-au': {
          mockupStrikethroughPriceOffset: 40000,
        },
        'tread-starter-package-au': {
          mockupStrikethroughPriceOffset: 50000,
        },
        'tread-ultimate-package-au': {
          mockupStrikethroughPriceOffset: 60000,
        },
      },
    },
    [BundleType.RainforestCafe]: {
      'en-AU': {
        'guide-starter-package-au': {
          mockupStrikethroughPriceOffset: 20000,
        },
        'guide-select-package-au': {
          mockupStrikethroughPriceOffset: 20000,
        },
        'guide-ultimate-package-au': {
          mockupStrikethroughPriceOffset: 20000,
        },
      },
    },
  },
  mothersDay23: {
    [BundleType.Bike]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 60000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 55000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 30000,
      },
    },
    [BundleType.RefurbishedBike]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 15000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 20000,
      },
    },
    [BundleType.RefurbishedBikePlus]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 55000,
      },
    },
    [BundleType.BikePlus]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 60000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 55000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 30000,
      },
    },
    [BundleType.Tread]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'de-DE': {
        mockupStrikethroughPriceOffset: 40000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 60000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 55000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 30000,
      },
    },
    [BundleType.TreadPlus]: {},
    [BundleType.RainforestCafe]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 15000,
      },
      'en-AU': {
        mockupStrikethroughPriceOffset: 25000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 20000,
      },
      'en-GB': {
        mockupStrikethroughPriceOffset: 15000,
      },
    },
    [BundleType.Row]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 40000,
      },
    },
  },
  showRowAndTreadStrikethrough: {
    [BundleType.Tread]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 50000,
      },
      'en-CA': {
        mockupStrikethroughPriceOffset: 65000,
      },
    },
    [BundleType.Row]: {
      'en-US': {
        mockupStrikethroughPriceOffset: 20000,
      },
    },
  },
  showRowStrikethrough: {
    [BundleType.Row]: {
      'en-CA': {
        mockupStrikethroughPriceOffset: 27500,
      },
    },
  },
};

export const getStrikethroughPricingOffset = (
  toggleName: string,
  bundleType: BundleType,
  locale: Locale,
  slug: string = '',
): number => {
  const localeDiscounts = StrikethroughPriceOffsetMap[toggleName]?.[bundleType]?.[locale];

  return (
    localeDiscounts?.[slug]?.mockupStrikethroughPriceOffset ||
    localeDiscounts?.mockupStrikethroughPriceOffset ||
    0
  );
};

export const useStrikethroughPriceOffsetMap = (
  toggleName: string,
  bundleType: BundleType,
): MockupStrikethroughPrice => {
  const isToggleActive = useIsToggleActive();
  const locale = useLocale();
  const isMercuryActive = isToggleActive('mercury');
  const isShowBikePlusStrikethroughActive =
    isToggleActive('showBikePlusStrikethrough') && bundleType === BundleType.BikePlus;
  const isShowRowStrikethroughActive =
    isToggleActive('showRowStrikethrough') && bundleType === BundleType.Row;
  const isShowGuideStrikethroughActive =
    isToggleActive('showGuideStrikethrough') &&
    bundleType === BundleType.RainforestCafe &&
    locale !== Locale.EnglishUnitedKingdom;

  return {
    isActive:
      isMercuryActive ||
      isShowBikePlusStrikethroughActive ||
      isShowGuideStrikethroughActive ||
      isShowRowStrikethroughActive,
    mockupStrikethroughPriceOffset: getStrikethroughPricingOffset(
      toggleName,
      bundleType,
      locale,
    ),
  };
};
