import { Container, Flex, Icon, spacing, Headline } from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { usePanelContext } from '@page-builder/components/PanelContext';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import {
  CARD_WIDTHS,
  MAX_SCROLL_WIDTH,
  MAX_TEXT_WIDTH,
  CAROUSEL_CLICK_DELAY,
  OBSERVER_HANDLER_DELAY,
} from './constants';
import Pagination from './Pagination';
import { getScrollToLeftPosition, getObserver, usePaginationPropValues } from './utils';
import VideoCarouselCard from './VideoCarouselCard';

/**
 * This component is the Generic List with the 'Video Carousel' treatment.
 * All the destructured props come from the contentful entry.
 * A card in the carousel can be set to active through the following:
 *    - Clicking on the previous or next buttons on the pagination.
 *    - Clicking or tabbing on an inactive card.
 *    - Scrolling an inactive card to the center of the viewport (using Intersection Observers).
 * @param items renamed to rawItems, is an array of items (Generic Text with Media) from the contentful entry. These are used for the cards in the carousel.
 * @param text the Generic Text component from the contentful entry. This is used for the headline and pagination text.
 * @param theme the Theme from contentful entry. Theme is defaulted to Grey 90 when undefined.
 */

const VideoCarousel: React.FC<TypeComponentGenericListFields> = ({
  items: rawItems,
  text,
  theme = 'Grey 90',
}) => {
  const { trackEvent } = useTracking();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [inViewRef, isCarouselInView] = useInView({ triggerOnce: true });
  const { backgroundColor, headlineColor, textColor } = themes[theme];

  const items = rawItems.filter(item => Boolean(item.fields.media));
  const totalNumberOfItems = items.length;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLElement | null)[]>([]);
  const timeoutIdsRef = useRef<Record<number, ReturnType<typeof setTimeout>>>({});
  const { eyebrow, headline, label: paginationTextRaw } = getTextFieldsWithRequiredKeys(
    ['eyebrow', 'label'],
    text,
  );

  const observerRefs = React.useRef<(IntersectionObserver | null)[]>([]);
  const activeIndexRef = useRef(activeIndex);

  const sendPaginationTrackingEvent = useCallback(() => {
    trackEvent({
      event: TrackingEvent.ClickedCarouselSlide,
      properties: {
        page: window.location.pathname,
        parent: 'Component: PLP',
        parentType: eyebrow,
        unitName: 'Recommendation Module',
      },
    });
  }, [eyebrow, trackEvent]);

  const handleActiveCardOnScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]; // each observer is observing a single element
      if (!entry) return;

      const isElementWithinRootBounds = entry.isIntersecting;
      const cardIndex = itemRefs.current.indexOf(entry.target as HTMLElement);

      if (isElementWithinRootBounds && activeIndexRef.current !== cardIndex) {
        timeoutIdsRef.current[cardIndex] = setTimeout(() => {
          setActiveIndex(cardIndex);
          sendPaginationTrackingEvent();
        }, OBSERVER_HANDLER_DELAY);
      } else {
        clearTimeout(timeoutIdsRef.current[cardIndex]);
      }
    },
    [sendPaginationTrackingEvent],
  );

  useEffect(() => {
    // Disconnect all current observers
    observerRefs.current.forEach(observer => observer?.disconnect());
    const observers = observerRefs.current;

    if (isCarouselInView) {
      for (const [cardIndex, cardElement] of itemRefs.current.entries()) {
        const totalCardsLength = itemRefs.current.length;

        if (scrollContainerRef.current && cardElement) {
          const newObserver = getObserver(
            scrollContainerRef,
            cardElement,
            cardIndex,
            totalCardsLength,
            handleActiveCardOnScroll,
          );

          observers[cardIndex] = newObserver;
          observers[cardIndex]?.observe(cardElement);
        }
      }
    }

    return () => {
      observers.forEach(observer => observer?.disconnect());
    };
  }, [isCarouselInView, handleActiveCardOnScroll]);

  useEffect(() => {
    // Used to keep track of the active index as state is not up to date within the observer handler
    activeIndexRef.current = activeIndex;
  }, [activeIndex]);

  const scrollToActiveItem = (index: number) => {
    const scrollContainer = scrollContainerRef.current;
    const activeItem = itemRefs.current[index];

    if (activeItem && scrollContainer) {
      const activeItemPosition = getScrollToLeftPosition(
        activeItem,
        scrollContainer.offsetWidth,
        index,
        totalNumberOfItems,
      );

      setTimeout(
        () => scrollContainer.scrollTo({ left: activeItemPosition }),
        CAROUSEL_CLICK_DELAY,
      );
    }
  };

  const handleActiveItem = (cardIndex: number) => {
    setActiveIndex(cardIndex);
    scrollToActiveItem(cardIndex);
    sendPaginationTrackingEvent();
  };

  const handleCardClick = (cardIndex: number) => {
    setActiveIndex(cardIndex);
    scrollToActiveItem(cardIndex);
    sendPaginationTrackingEvent();
  };

  const { togglePanel } = usePanelContext();

  // Values to pass into the Pagination component
  const paginationPropValues = usePaginationPropValues(
    activeIndex,
    totalNumberOfItems,
    paginationTextRaw,
    handleActiveItem,
  );

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      horizontalPadding={0}
      flexDirection="column"
      maxWidth={MAX_SCROLL_WIDTH}
      ref={inViewRef}
    >
      <Flex
        horizontalPadding={{ mobile: spacing[16], desktop: spacing[40] }}
        justifyContent="center"
      >
        <HeaderContainer
          verticalPadding={`0 ${spacing[24]}`}
          alignItems={{ mobile: 'flex-start', desktop: 'center' }}
          gap={spacing[16]}
          width="100%"
          maxWidth={MAX_TEXT_WIDTH}
        >
          <Flex
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems={{ mobile: 'flex-start', desktop: 'center' }}
            flexGrow={1}
            justifyContent={headline ? 'space-between' : 'flex-end'}
            gap={spacing[16]}
          >
            {headline && (
              <Headline size="small" textColor={headlineColor}>
                {headline}
              </Headline>
            )}
            <Pagination {...paginationPropValues} />
          </Flex>
          {togglePanel && (
            <button onClick={togglePanel}>
              <Icon name="close" primaryColor={textColor} />
            </button>
          )}
        </HeaderContainer>
      </Flex>
      <ScrollContainer
        data-test-id="scroll-container"
        backgroundColor={backgroundColor}
        gap={{
          mobile: spacing[16],
          tablet: spacing[24],
        }}
        verticalPadding={{ desktop: `0 ${spacing[16]}` }}
        horizontalPadding={{ mobile: spacing[16], desktop: spacing[40] }}
        ref={scrollContainerRef}
      >
        <CardsContainer>
          {items.map((item, index) => (
            <Container
              as="li"
              display="flex"
              key={item.fields.name}
              ref={el => {
                itemRefs.current[index] = el;
              }}
              width={CARD_WIDTHS}
              tabIndex={0}
              onClick={() => {
                if (index !== activeIndex) {
                  handleCardClick(index);
                }
              }}
              onKeyUp={keyboardEvent => {
                if (keyboardEvent.key === 'Enter' && index !== activeIndex) {
                  handleCardClick(index);
                }
              }}
            >
              <Card flexDirection="column" gap={spacing[16]} width={CARD_WIDTHS}>
                <VideoCarouselCard
                  active={activeIndex === index}
                  isInView={isCarouselInView}
                  {...item.fields}
                />
              </Card>
            </Container>
          ))}
        </CardsContainer>
      </ScrollContainer>
    </OuterContainer>
  );
};

export default VideoCarousel;

const Card = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

const elementContentsHidden = 'rgba(0, 0, 0, 0)';
const elementContentsVisible = 'rgba(0, 0, 0, 1)';

const ScrollContainer = styled(Flex)<{ backgroundColor: string }>`
  overflow: scroll;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  white-space: nowrap;

  // to hide the scrollbar
  box-sizing: content-box;

  // hide scrollbar on safari
  ::-webkit-scrollbar {
    display: none;
  }

  // fade edge styling on larger viewports
  @media (min-width: ${MAX_SCROLL_WIDTH}) {
    padding: 0 calc((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2);

    mask-image: linear-gradient(
      to left,
      ${elementContentsHidden} 0%,
      ${elementContentsVisible} calc((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2),
      ${elementContentsVisible}
        calc(${MAX_SCROLL_WIDTH} - ((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2)),
      ${elementContentsHidden} 100%
    );
  }
`;

const HeaderContainer = styled(Flex)`
  width: 100%;
`;

const CardsContainer = styled.ul`
  margin: 0;
  position: relative;
  display: grid;
  grid-gap: ${spacing[24]};
  grid-auto-flow: column;
  width: fit-content;
  list-style-type: none;
`;
