import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { TogglePause, TogglePlay } from '@ecomm/icons';

type Theme = 'light' | 'dark';
type Bearing = 'left' | 'right';

type Props = {
  theme?: Theme;
  bearing: Bearing;
  playing: boolean;
  onClick?: VoidFunction;
};

const VideoToggle: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  playing,
  theme,
  bearing,
}) => {
  const pauseLabel = useMicroCopy('pauseLabel');
  const playLabel = useMicroCopy('playLabel');

  return (
    <StyledButton bearing={bearing} onClick={onClick}>
      {playing ? (
        <TogglePause label={pauseLabel} theme={theme} />
      ) : (
        <TogglePlay label={playLabel} theme={theme} />
      )}
    </StyledButton>
  );
};

export default VideoToggle;

const StyledButton = styled.button<{ bearing: Bearing }>`
  position: absolute;
  bottom: 0;
  ${props => (props.bearing === 'left' ? 'left: 0' : 'right: 0')};
  z-index: 3;
  padding: 16px;
  ${media.desktopLarge`
    padding: 24px;
  `}

  svg {
    width: 26px;

    ${media.desktopLarge`
      width: 34px;
    `}
  }

  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.6;
  }
`;
