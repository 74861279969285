import { Flex, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import NextContentSizedDiv from '@peloton/content-sized-div/NextContentSizedDiv';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { EstimateSuccessResp } from '@ecomm/cart/summary/OtdEstimate/DynamicOtdEstimate';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import type { TypeComponentFormFields } from '@page-builder/lib/types';
import OverviewOtdEstimate from './OverviewOtdEstimate';
import PostalCodeInput from './PostalCodeInput';

type Props = {
  productEquipmentType: EquipmentType;
  postalCodeInputFields?: TypeComponentFormFields;
  cfuSku?: string;
};

const OtdEstimateWithPostalCode: React.FC<React.PropsWithChildren<Props>> = ({
  postalCodeInputFields,
  productEquipmentType,
  cfuSku,
}) => {
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const [postalCodeSubmitSuccess, setPostalCodeSubmitSuccess] = React.useState(false);
  const onTogglePostalCode = () => {
    setIsExpanded(prevState => !prevState);
  };

  const onOtdEstimateLoading = (isOtdLoading: boolean) => {
    setIsLoading(isOtdLoading);
    if (!isOtdLoading) {
      setIsExpanded(false);
    }
  };

  const onFormSubmit = () => {
    setPostalCodeSubmitSuccess(true);
  };

  const onEstimateSuccess = (data: EstimateSuccessResp) => {
    if (postalCodeSubmitSuccess) {
      trackEvent({
        event: TrackingEvent.EstimateOTD,
        properties: {
          calculationStatus: data.resp.calculationStatus,
          category: productEquipmentType,
          zipcode: data.postalCode,
          display: data.messaging.key,
          startDate: data.resp?.estimate?.startDate,
          endDate: data.resp?.estimate?.endDate,
        },
      });
      setPostalCodeSubmitSuccess(false);
    }
  };

  return (
    <HeightTransition>
      <Flex flexDirection="column">
        <OverviewOtdEstimate
          productEquipmentType={productEquipmentType}
          onTogglePostalCode={onTogglePostalCode}
          onOtdEstimateLoading={onOtdEstimateLoading}
          isExpanded={isExpanded}
          onEstimateSuccess={onEstimateSuccess}
          cfuSku={cfuSku}
        />
        <PostalInputVisibilityWrapper isExpanded={isExpanded} aria-hidden={!isExpanded}>
          <PostalInputWrapper>
            <PostalCodeInput
              postalCodeInputFields={postalCodeInputFields}
              isLoadingEligibility={isLoading}
              isHidden={!isExpanded}
              onFormSubmit={onFormSubmit}
            />
          </PostalInputWrapper>
        </PostalInputVisibilityWrapper>
      </Flex>
    </HeightTransition>
  );
};

export default OtdEstimateWithPostalCode;

const HeightTransition = styled(NextContentSizedDiv)`
  transition-property: height;
  transition-duration: 250ms;
  transition-timing-function: ease;
`;

const PostalInputVisibilityWrapper = styled.div<{ isExpanded: boolean }>`
  height: ${props => (props.isExpanded ? '' : 0)};
`;

const PostalInputWrapper = styled.div`
  padding-top: ${spacing[16]};
`;
