import {
  Grid,
  spacing,
  BreakpointWidths,
  Button,
  Container,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { toCtaFields } from '@page-builder/utils/helpers/cms';
import GalleryModal from './GalleryModal';
import TextCards from './TextCards';
import type { GalleryData } from './utils';
import { applyMediaTreatment } from './utils';

const Gallery: React.FC<
  React.PropsWithChildren<{ gallery: GalleryData; product: string }>
> = ({ gallery, product }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { trackEvent } = useTracking();

  const cta = gallery.ctas && toCtaFields(gallery.ctas)[0];

  const handleGalleryItemClick = (galleryIndex?: number) => {
    setCurrentImageIndex(galleryIndex || 0);
    setIsModalOpened(true);

    const item = galleryIndex ? gallery.media?.[galleryIndex]?.fields : cta;

    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Overview',
        parent: `${product} Overview Media Gallery`,
        unitName: galleryIndex
          ? `${product} Overview Gallery: Media ${galleryIndex + 1}`
          : 'View Gallery CTA',
        linkName: galleryIndex ? '' : item?.text,
        linkTo: '',
      },
    });
  };

  return (
    <>
      <DesktopGrid
        gap={spacing[16]}
        columnCount={2}
        data-test-id={`overview-gallery-${gallery.unitName}`}
      >
        {gallery.media &&
          applyMediaTreatment(gallery.media, handleGalleryItemClick).slice(0, 6)}
        {gallery.textItems && <TextCards textItems={gallery.textItems} />}
      </DesktopGrid>
      <Container
        maxWidth="146px"
        margin="0 auto"
        display={{ mobile: 'none', desktop: 'block' }}
      >
        {cta && (
          <Button
            variant={cta.variant}
            color={cta.color}
            style={{ margin: 'auto', marginTop: spacing[24], padding: '0' }}
            width="adaptive"
            size="medium"
            onClick={() => handleGalleryItemClick()}
          >
            {cta.text}
          </Button>
        )}
      </Container>

      {gallery.media && (
        <GalleryModal
          galleryMedia={gallery.media}
          isOpen={isModalOpened}
          closeHandler={() => setIsModalOpened(false)}
          selectedItem={currentImageIndex}
          subnavText={gallery.modalTitle || ''}
          contentLabel=""
        />
      )}
    </>
  );
};

export default Gallery;

const DesktopGrid = styled(Grid)`
  display: none;
  @media (min-width: ${BreakpointWidths.desktop}px) {
    display: grid;
  }
`;
