import React, { useRef } from 'react';
import styled from 'styled-components';
import AddZipScriptToPage from '@ecomm/financing/zip/AddZipScriptToPage';
import { ZipModalView } from '@ecomm/financing/zip/ZipModalView';
import { toDollars } from '@ecomm/models';
import { BundleType } from '@ecomm/shop/models/BundleType';
import { usePackageFinancing } from '@page-builder/modules/Overview/utils';

type ModalLinkProps = {
  bundleType?: BundleType;
  className?: string;
  name: string;
};

export const ZipModalLink: React.FC<React.PropsWithChildren<ModalLinkProps>> = ({
  bundleType = BundleType.Bike,
  name,
  children,
}) => {
  const { total } = usePackageFinancing(bundleType);
  const zipModalRef = useRef<null | HTMLDivElement>(null);

  const onButtonClick = () => {
    zipModalRef.current?.click();
  };
  return (
    <StyledDiv onClick={onButtonClick}>
      <AddZipScriptToPage />
      <ZipModalView
        price={toDollars(total)}
        ref={zipModalRef}
        bundleType={bundleType}
        data-test-id={`zip-${name}-widget`}
      />
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`` as any;
