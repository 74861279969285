import styled from 'styled-components';

type Props = {
  isLoading: boolean;
};

const LoadingContentContainer = styled.div.attrs<Props>(props => ({
  'aria-hidden': props.isLoading,
}))`
  opacity: ${(props: Props) => (props.isLoading ? 0 : 1)};
`;

export default LoadingContentContainer;
