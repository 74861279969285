import type { GuestPassInfo } from '@prospects/guest-pass/hooks/useGuestPassInfo';
import type * as Contentful from 'contentful';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import type { HeadbandCta } from '../../Headband/HeadbandCtaWithInfo';

type HeadbandData = {
  name?: string;
  title?: string;
  cta: HeadbandCta;
  guestPass: GuestPassInfo | null;
  financingText?: string;
  disclaimerText?: string;
} | null;

export const getStaticHeadbandTitle = (
  headbandKey: string,
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
): string | null => {
  const headbandItem = items.find(item => item.fields.name === headbandKey);
  return headbandItem?.fields?.text?.fields?.headline || null;
};

export const getHeadbandData = (
  headbandKey: string,
  classTitle: string,
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
  guestPass: GuestPassInfo | null,
): HeadbandData => {
  const headbandItem = items.find(item => item.fields.name === headbandKey);

  if (!headbandItem) return null;

  const name = headbandItem?.fields?.text?.fields?.name || headbandItem?.fields?.name;
  const titleFallback = headbandItem?.fields?.text?.fields?.headline;
  const cta: HeadbandCta = {
    text: headbandItem?.fields?.ctas?.[0]?.fields?.text,
    href: guestPass?.guestPassCode
      ? guestPassUrl(guestPass.query)
      : headbandItem?.fields?.ctas?.[0]?.fields?.link?.fields?.url,
  };
  const financingText = headbandItem?.fields?.text?.fields?.support;
  const disclaimerText = headbandItem?.fields?.text?.fields?.label;

  return {
    name,
    title: classTitle || titleFallback,
    cta,
    guestPass,
    financingText,
    disclaimerText,
  };
};

const guestPassUrl = (queryParams: URLSearchParams) =>
  `/app-membership/guest-pass?${queryParams.toString()}`;
