import React from 'react';
import type { Progress } from '../models/progress';
import { useSessionProgressState, writeProgressToSession } from '../utils/session';

type ContextInterface = {
  progress: Progress;
  setProgress: (callback: (prevProgress: Progress) => Progress) => void;
  resetProgress: () => void;
  quizSessionKey: string;
};

type Props = {
  sessionKey: string;
};

const defaultContext: ContextInterface = {
  progress: {},
  setProgress: () => {},
  resetProgress: () => {},
  quizSessionKey: '',
};

export const ProgressContext = React.createContext<ContextInterface>(defaultContext);

const ProgressProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  sessionKey,
}) => {
  const [progress, setProgress, quizSessionKey] = useSessionProgressState(sessionKey);

  const contextValue = React.useMemo(() => {
    const resetProgress = () => {
      setProgress({});
      writeProgressToSession({}, sessionKey);
    };

    return {
      progress,
      setProgress,
      resetProgress,
      quizSessionKey,
    };
  }, [progress, setProgress, quizSessionKey]);

  return (
    <ProgressContext.Provider value={contextValue}>{children}</ProgressContext.Provider>
  );
};

export default ProgressProvider;
