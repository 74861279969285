import React from 'react';

export type VideoProps = {
  src: string;
  loop?: boolean;
  autoPlay: boolean;
  controls: boolean;
  tabbable?: boolean;
  className?: string;
  subtitles?: SubtitleProps;
  tracks?: TrackProps[];
  playsInline?: boolean;
  muted?: boolean;
};

export type EventProps = {
  onPlay?: (event: React.SyntheticEvent<HTMLMediaElement>) => void;
  onCanPlay?: (event: React.SyntheticEvent<HTMLMediaElement>) => void;
  onPause?: (event: React.SyntheticEvent<HTMLMediaElement>) => void;
  onEnded?: (event: React.SyntheticEvent<HTMLMediaElement>) => void;
};

export type TrackProps = {
  src: string;
  kind: string;
};

export type SubtitleProps = {
  src: string;
  label: string;
  kind: string;
  srcLang: string;
};

export type Options = {
  poster?: string;
};

type Props = VideoProps & EventProps & Options;

const noop = () => {};

const VideoElement: React.FC<React.PropsWithChildren<Props>> = ({
  src,
  loop,
  autoPlay,
  controls,
  tabbable,
  className,
  subtitles,
  tracks,
  playsInline,
  poster = '',
  muted,
  onPlay = noop,
  onPause = noop,
  onEnded = noop,
  onCanPlay = noop,
}) => {
  const opts: Options = {};
  if (poster) {
    opts.poster = poster;
  }

  // We should work on getting captions/subs for all locales but we don't have them at the moment

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      muted={muted}
      loop={loop}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
      onCanPlay={onCanPlay}
      src={src}
      autoPlay={autoPlay}
      controls={controls}
      className={`modal-video ${className ? className : ''}`}
      crossOrigin="anonymous"
      tabIndex={tabbable ? 0 : -1}
      playsInline={playsInline}
      {...opts}
    >
      {subtitles && (
        <track
          src={subtitles.src}
          label={subtitles.label}
          srcLang={subtitles.srcLang}
          kind={subtitles.kind}
          default
        />
      )}
      {tracks &&
        tracks.map((track, index) => (
          <track key={index} src={track.src} kind={track.kind} />
        ))}
    </video>
  );
};

export default VideoElement;
