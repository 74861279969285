import React from 'react';
import type { TypeComponentFormFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { ctaExtractData } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import type { Select } from './ValueCalculator';
import ValueCalculator from './ValueCalculator';

type Props = TypeComponentFormFields;

const ValueCalculatorEntry: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  formFields,
  media,
  submit,
  text: textField,
}) => {
  const { eyebrow, headline } = getGenericTextNodes(textField, ['eyebrow', 'headline']);

  const primaryCta = ctaExtractData(submit);
  const [formField] = formFields;

  if (!formField || formFields.length > 1 || formField.fields.type !== 'select') {
    console.error(
      `Malformed Contentful Entry for ${name}. This entry requires a only 1 formFields and must be type select`,
    );
    return <div data-test-id="form-error"></div>;
  }

  const images = toImageProps(media);

  if (!images || !images.mobile || !images.tablet || !images.desktop) {
    console.error(
      `Malformed Contentful Entry for ${name}. 
        * Ensure you have defined media with an image
        * Ensure you have all breakpoints defined
          * mobile
          * tablet
          * desktop
      `,
    );
    return <div data-test-id="image-error"></div>;
  }

  const { text, url, color, variant } = primaryCta;

  if (!text || !url) {
    console.error(
      `Malformed Contentful Entry for ${name}. Ensure you have a cta with text and url`,
    );
    return <></>;
  }

  const { mobile, tablet, desktop } = images;

  const select = {
    label: formField.fields.label,
    items: formField.fields.items?.map(item => {
      const [option, value] = item.split(':');
      return {
        option,
        value,
      };
    }),
  } as Select;

  return (
    <ValueCalculator
      backgroundImages={{ mobile, tablet, desktop }}
      headline={headline}
      eyebrow={eyebrow}
      select={select}
      cta={{ text, url, color, variant }}
      id={name}
    />
  );
};

export default ValueCalculatorEntry;
