// TODO: drop this file once Views use the types agreed on in the schema
import { invertObj } from 'ramda';
import * as Types from '@ecomm/graphql/types.generated';
import type { ReviewableTypes } from '@ecomm/models/DeviceType';
import type { ReviewsQuery } from '@ecomm/reviews/data/reviews/Reviews.generated';
import type { ReviewQuery, SupportComment } from '@ecomm/reviews/models';

type ViewProps = {
  reviewsProps: ReviewQuery[];
  totalReviews: number;
};

export const transform = ({
  deviceType,
  nodes,
  totalCount,
}: {
  totalCount: number;
  nodes: any;
  deviceType: ReviewableTypes;
}): ViewProps => ({
  reviewsProps: nodes && nodes.length ? nodes.map(transformReview(deviceType)) : [],
  totalReviews: totalCount,
});

export const transformReview = (deviceType: ReviewableTypes) => ({
  id,
  title,
  author,
  overallRating,
  body,
  locale,
  mainReason,
  whoUses,
  wouldRecommend,
  photoUrl,
  response,
  frequency,
  date,
  photos,
}: ReviewsQuery['reviewsByEquipmentType']['nodes'][0]): Omit<ReviewQuery, 'intl'> => ({
  id,
  title,
  authorName: (author && author.name) || '',
  authorLocation: (author && author.location) || '',
  deviceType,
  isVerifiedPurchaser: author && author.verifiedPurchaser,
  rating: overallRating,
  reviewText: body,
  rideBecause: mainReason ? mainReasonMap[mainReason] : undefined,
  rideFrequency: frequency ? frequencyMap[frequency] : undefined,
  wouldRecommend: wouldRecommend || undefined,
  share: whoUses ? whoUsesMap[whoUses] : undefined,
  photoUrl: photoUrl || undefined,
  photos: transormPhotos(photos),
  submittedAt: date,
  supportComments: transformResponse(response),
  locale: locale || undefined,
  // TODO: remove unused fields from view props
  authorAge: '',
  authorCity: '',
  authorState: '',
  ratingQuality: 0,
  ratingValue: 0,
  score: 0,
  yearJoined: '',
});

const mainReasonMap: Record<Types.MainReason, string> = {
  [Types.MainReason.Fitness]: 'ToImproveFitness',
  [Types.MainReason.Fun]: 'Other',
  [Types.MainReason.Love]: 'ForTheLoveOfIndoorCycling',
  [Types.MainReason.Social]: 'ToWorkoutWithFriends',
  [Types.MainReason.Training]: 'ForTraining',
  [Types.MainReason.Weight]: 'ToLoseWeight',
};

export const toMainReason = (mainReason: string) =>
  invertObj(mainReasonMap)[mainReason] as Types.MainReason;

const whoUsesMap: Record<Types.WhoUses, string> = {
  [Types.WhoUses.JustMe]: 'JustMe',
  [Types.WhoUses.SignificantOther]: 'IShareWithASignificantOther',
  [Types.WhoUses.Family]: 'IShareWithFamily',
  [Types.WhoUses.Friends]: 'IShareWithFriends',
};

export const toWhoUses = (whoUses: string) =>
  invertObj(whoUsesMap)[whoUses] as Types.WhoUses;

const frequencyMap: Record<Types.Frequency, string> = {
  [Types.Frequency.Occasionally]: 'Occasionally',
  [Types.Frequency.AFewTimesAMonth]: 'AFewTimesAMonth',
  [Types.Frequency.OnceAWeek]: 'OnceAWeek',
  [Types.Frequency.AFewTimesAWeek]: 'AFewTimesAWeek',
  [Types.Frequency.Everyday]: 'EveryDay',
};

export const toFrequency = (frequency: string) =>
  invertObj(frequencyMap)[frequency] as Types.Frequency;

const transformResponse = (
  response?: string | SupportComment | null,
): SupportComment | null => {
  if (typeof response === 'string') {
    return { commentText: response } as SupportComment;
  } else if (response?.submittedAt) {
    return response as SupportComment;
  }

  return null;
};

const transormPhotos = (photos?: Types.Maybe<Types.Maybe<string>[]>): string[] => {
  if (photos === null || photos === undefined) {
    return [];
  }

  return photos.filter((url): url is string => url !== null);
};
