import {
  Container,
  Display,
  Eyebrow,
  Flex,
  Label,
  Media,
  Support,
  grey,
  spacing,
  white,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import { OuterContainer, getGenericTextNodes } from '@pelotoncycle/page-builder';
import type {
  TypeComponentGenericTextWithMediaFields,
  OuterContainerProps,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import { LinkOrModalCTA } from '@page-builder/components';
import { themes } from '@page-builder/themes';
import { toMediaProps } from '@page-builder/utils/helpers';
import { FullWidthMediaModuleModal } from './FullWidthMediaModuleModal';

const toTypeSpecificMediaProps = (
  media: TypeComponentGenericTextWithMediaFields['media'],
) => {
  if (!media) {
    return null;
  }

  const baseMediaProps = toMediaProps(media, false, {
    mobile: {
      width: 600,
      devicePixelRatio: 2,
    },
    tablet: {
      width: BreakpointWidths.tablet,
      devicePixelRatio: 2,
    },
    desktop: {
      width: BreakpointWidths.desktop,
      devicePixelRatio: 2,
    },
  });

  if (baseMediaProps.type === 'video') {
    return {
      ...baseMediaProps,
      autoplay: true,
      progressTracked: true,
      loop: false,
    };
  }

  return baseMediaProps;
};

type Props = TypeComponentGenericTextWithMediaFields &
  Pick<OuterContainerProps, 'verticalPadding'>;

export const FullWidthMediaModuleContainer: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  ctas,
  media,
  theme = 'White',
  verticalPadding,
  children,
}) => {
  const { backgroundColor } = themes[theme];
  const { eyebrow, headline, support, label } = getGenericTextNodes(text);

  const mediaProps = toTypeSpecificMediaProps(media);

  return (
    <LargeDesktopOuterContainer
      backgroundColor={backgroundColor}
      theme={theme}
      verticalPadding={
        verticalPadding ?? {
          desktop: spacing[80],
        }
      }
      horizontalPadding={{
        desktop: spacing[16],
      }}
      maxWidth={{ desktop: '1336px' }}
    >
      <Container position="relative" height={{ mobile: '560px', desktop: '600px' }}>
        {mediaProps && <StyledMediaCard media={mediaProps} />}
        <StyledTextContainer theme={backgroundColor}>
          <LargeDesktopMediaFlex
            padding={{
              mobile: `${spacing[32]} ${spacing[16]}`,
              tablet: `${spacing[24]} ${spacing[64]}`,
              desktop: `${spacing[48]} ${spacing[24]}`,
            }}
            margin="0 auto"
            style={{ height: '100%' }}
          >
            <StyledDesktopHalfFlex flexDirection="column">
              <Flex flexDirection="column">
                {eyebrow && (
                  <StyledEyebrow size="medium" textColor={white}>
                    {eyebrow}
                  </StyledEyebrow>
                )}
                {headline && (
                  <Display size="large" textColor={white}>
                    {headline}
                  </Display>
                )}
              </Flex>
              <BottomFlex flexDirection="column">
                {children}
                <Flex
                  flexDirection={{ mobile: 'column', tablet: 'row' }}
                  gap={spacing[16]}
                >
                  {ctas &&
                    ctas.map(cta => (
                      <LinkOrModalCTA
                        key={cta.fields.name}
                        cta={cta}
                        Modal={FullWidthMediaModuleModal}
                        parent={{
                          parentName: 'FullWidthMediaModule',
                          parentType: 'Component: Generic Text with Media',
                        }}
                      />
                    ))}
                </Flex>
                {label && (
                  <StyledLabel weight="medium" size="extraLarge" textColor={white}>
                    {label}
                  </StyledLabel>
                )}
                {support && (
                  <StyledSupport size="medium" textColor={grey[40]}>
                    {support}
                  </StyledSupport>
                )}
              </BottomFlex>
            </StyledDesktopHalfFlex>
          </LargeDesktopMediaFlex>
        </StyledTextContainer>
      </Container>
    </LargeDesktopOuterContainer>
  );
};

const LargeDesktopOuterContainer = styled(OuterContainer)`
  @media (min-width: 1304px) {
    padding-inline: ${spacing[32]};
  }
`;

const LargeDesktopMediaFlex = styled(Flex)`
  @media (min-width: 1304px) {
    padding: ${spacing[48]} ${spacing[56]};
  }
`;

const StyledMediaCard = styled(Media)`
  ${mediaBreakpoints.desktopLarge`
    div > video {
      border-radius: ${spacing[12]};
    }

    picture > img {
      border-radius: ${spacing[12]};
    }
  `}
`;

const StyledTextContainer = styled(Container)<{ theme: string }>`
  position: absolute;
  inset: 0;
  height: 100%;
`;

const StyledDesktopHalfFlex = styled(Flex)`
  height: 100%;

  ${mediaBreakpoints.desktopLarge`
    width: 50%;
  `}
`;

const BottomFlex = styled(Flex)`
  margin-top: auto;
`;

const StyledEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[8]};
`;

const StyledLabel = styled(Label)`
  margin-top: ${spacing[16]};
`;

const StyledSupport = styled(Support)`
  margin-top: ${spacing[8]};
`;
