type FocusFirstNewElementParams = {
  isInsertedBefore: boolean;
  list: HTMLElement[];
  previousLength: number;
};

export const focusFirstNewElement = ({
  isInsertedBefore,
  list,
  previousLength,
}: FocusFirstNewElementParams): void => {
  const firstNewElement = isInsertedBefore ? list[0] : list[previousLength];
  const firstNewFocusableElement =
    firstNewElement?.querySelector<HTMLElement>('a, button') || firstNewElement;
  firstNewFocusableElement?.focus({
    preventScroll: true,
  });
};
