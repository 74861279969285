import {
  Container,
  Flex,
  grey,
  spacing,
  Eyebrow,
  Label,
  Body,
} from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { media } from '@peloton/styles';
import { markdownifyUrls } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';
import type { HistorySummary } from '../HistoryReport/types';

const ReportTable: React.FC<React.PropsWithChildren<TypeComponentGenericListFields>> = ({
  text,
  items,
}) => {
  const headerText = text?.fields.headline;
  const { data } = useSWR('historySummaryData');

  if (!data) {
    return null;
  }

  const transformedData = Object.fromEntries(
    Object.entries(data as HistorySummary).map(([key, value]) => [
      key,
      markdownifyUrls(value),
    ]),
  );

  return (
    <StyledContainer
      horizontalPadding={{
        mobile: spacing[16],
        tablet: 0,
      }}
    >
      <StyledWrapper flexDirection="column" alignItems="center">
        <StyledHeader>
          <Eyebrow size="large" as="h2">
            {headerText}
          </Eyebrow>
        </StyledHeader>
        {items.map(item => {
          return (
            <StyledRow key={item?.fields.name}>
              <StyledLabel size="extraLarge">
                {item?.fields?.text?.fields?.label}
              </StyledLabel>
              <StyledBody size="small">
                <Markdown
                  content={item?.fields?.text?.fields?.body ?? ''}
                  values={transformedData ?? ''}
                  markdown={{
                    renderers: {
                      link: SummaryLink,
                    },
                  }}
                />
              </StyledBody>
            </StyledRow>
          );
        })}
      </StyledWrapper>
    </StyledContainer>
  );
};

const SummaryLink = styled.a.attrs({ target: '_blank' })``;

const StyledContainer = styled(Container)`
  & + [data-test-id='report-table'] {
    padding-top: 0;
  }
  padding-bottom: ${spacing[24]};
`;

const StyledWrapper = styled(Flex)`
  max-width: 800px;
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  width: 100%;
  border-bottom: 2px solid ${grey[90]};
  padding-bottom: ${spacing[16]};
`;

const StyledRow = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${grey[40]};

  &:nth-child(2n) {
    background-color: ${grey[10]};
  }

  ${media.tabletLarge`
    flex-direction: row;
  `}
`;

const StyledLabel = styled(Label)`
  display: block;
  padding: ${spacing[16]};
  width: 238px;
  flex-shrink: 0;
  ${media.tabletLarge`
    border-right: 1px solid ${grey[40]};
  `}
`;

const StyledBody = styled(Body)`
  display: block;
  padding: ${spacing[16]};
  padding-right: none;
  word-wrap: break-word;
`;

export default ReportTable;
