import {
  Container,
  Flex,
  Eyebrow,
  Headline,
  Body,
  spacing,
} from '@pelotoncycle/design-system';
import type { LinkButtonsProps } from '@pelotoncycle/page-builder';
import React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { useTracking } from 'react-tracking';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { TrackingEvent } from '@ecomm/analytics/models';
import { TEXT_MAXWIDTH } from '@page-builder/modules/tokens';

type Props = React.HTMLAttributes<HTMLElement> &
  LinkButtonsProps & {
    ctaText?: string;
    eyebrowText?: ReactNode;
    headlineText?: ReactNode;
    bodyText?: ReactNode;
    primaryColor?: CSSProperties['color'];
    secondaryColor?: CSSProperties['color'];
  };

export const getAppsFlyerLink = () => {
  // oneLinkURL is the URL that is specific to the app3for1 campaign.
  // https://hq1.appsflyer.com/onelink/multi-platform/update/2fedbb34-96d5-4b6f-ac36-e67320aaae11?xp=qr
  const oneLinkURL = 'https://onepeloton.onelink.me/Wabb/xlz03w6r';
  const mediaSource = { keys: ['utm_source'], defaultValue: 'onepeloton' };
  const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };
  const deepLinkValue = { keys: ['page_name'], defaultValue: window?.location?.pathname };

  // Global parameter on the window object called window.AF_SMART_SCRIPT is embedded in
  // packages/@peloton/next/components/_app.tsx with apps/next-www/public/appsFlyerSmartScript.js
  // @ts-expect-error
  const result = window?.AF_SMART_SCRIPT?.generateOneLinkURL({
    oneLinkURL: oneLinkURL,
    afParameters: {
      mediaSource: mediaSource,
      deepLinkValue: deepLinkValue,
      afCustom: [custom_ss_ui],
    },
  });
  return result;
};

const TextAndCta: React.FC<React.PropsWithChildren<Props>> = ({
  eyebrowText,
  headlineText,
  bodyText,
  primaryColor,
  secondaryColor,
  ctaText = '',
  name,
  ...props
}) => {
  const { getUser } = useUserInfo();
  const { trackEvent } = useTracking();

  const appsFlyerLink =
    typeof window !== 'undefined' ? getAppsFlyerLink()?.clickURL : null;

  const clickHandler = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Generic Text with Media',
        parent: props.id,
        unitName: `${props.id} CTA`,
        linkName: ctaText,
        linkTo: appsFlyerLink,
        ...getUser(),
      },
    });
  };

  return (
    <Flex
      removeOuterMargins
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={spacing[16]}
      horizontalPadding={{ mobile: spacing[32], desktop: 0 }}
      verticalPadding={{
        mobile: spacing[48],
        tablet: spacing[64],
        desktop: spacing[16],
      }}
      maxWidth={{
        mobile: TEXT_MAXWIDTH['mobile'],
        desktop: '320px',
      }}
    >
      <Eyebrow size="small" textColor={primaryColor}>
        {eyebrowText}
      </Eyebrow>

      <Headline size="small" textColor={primaryColor}>
        {headlineText}
      </Headline>

      <Body size="small" textColor={secondaryColor}>
        {bodyText}
      </Body>

      <Container
        verticalMargin={`${spacing[8]} 0`}
        display={{ mobile: 'block', desktop: 'none' }}
      >
        <LinkButton
          onClick={clickHandler}
          to={appsFlyerLink}
          href={appsFlyerLink}
          size="small"
        >
          {ctaText}
        </LinkButton>
      </Container>
    </Flex>
  );
};

export default TextAndCta;
