import {
  Flex,
  Checkbox,
  Button,
  Eyebrow,
  Icon,
  white,
  Label,
  FilterButton,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import dynamic from 'next/dynamic';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { asyncComponent } from '@peloton/code-splitting';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import type { FiltersCheckedMap, FilterNames, CategoriesType } from '../hooks/useFilters';

const FilterFlyout = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk" */ '../FilterFlyout/FilterFlyout'),
);

// needed to work around the SSR build problems around the
// resizeobserver component
const AccordionItem = dynamic(
  () => import('@pelotoncycle/design-system/ui/components/Accordion/AccordionItem'),
  {
    ssr: false,
  },
);

export type FilterProps = {
  filterNames: FilterNames;
  filterGroups: CategoriesType[];
  filtersChecked: FiltersCheckedMap;
  setFilters: React.Dispatch<React.SetStateAction<FiltersCheckedMap>>;
  filteredCount?: number;
  display: 'desktop' | 'mobile';
};

const createFilterSection = (
  category: CategoriesType,
  setFiltersChecked: React.Dispatch<React.SetStateAction<FiltersCheckedMap>>,
  filtersChecked: FiltersCheckedMap,
) => {
  return {
    content: (
      <Flex flexDirection="column" gap={spacing[12]}>
        {category.subCategories.map(sub => (
          <Checkbox
            data-test-id={`filter-checkbox-${sub.key}`}
            key={sub.key}
            labelText={sub.name}
            name={sub.key}
            theme="light"
            checked={filtersChecked[sub.key]}
            handleChange={() =>
              setFiltersChecked(prev => {
                return { ...prev, [sub.key]: !prev[sub.key] };
              })
            }
          />
        ))}
      </Flex>
    ),
    title: category.name,
  };
};

const createFilterSections = (
  categories: CategoriesType[],
  setFiltersChecked: React.Dispatch<React.SetStateAction<FiltersCheckedMap>>,
  filtersChecked: FiltersCheckedMap,
) =>
  categories.map(category =>
    createFilterSection(category, setFiltersChecked, filtersChecked),
  );

const createFilterButtons = (
  filtersChecked: FiltersCheckedMap,
  filterNames: FilterNames,
  setFiltersChecked: React.Dispatch<React.SetStateAction<FiltersCheckedMap>>,
) => {
  return Object.entries(filtersChecked).map(([key]) => {
    if (filtersChecked[key]) {
      return (
        <FilterButtonListItem key={key}>
          <FilterButton
            text={filterNames[key]}
            onClick={() => setFiltersChecked(prev => ({ ...prev, [key]: false }))}
          />
        </FilterButtonListItem>
      );
    }

    return null;
  });
};

const clearAllFilters = (
  filtersChecked: FiltersCheckedMap,
  setFiltersChecked: React.Dispatch<React.SetStateAction<FiltersCheckedMap>>,
) => {
  Object.keys(filtersChecked).forEach(key => {
    setFiltersChecked(prev => ({ ...prev, [key]: false }));
  });
};

const Filter: React.FC<React.PropsWithChildren<FilterProps>> = ({
  setFilters,
  filteredCount,
  display,
  filterNames,
  filterGroups,
  filtersChecked,
}) => {
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const activeFilters = useMemo(
    () => Object.values(filtersChecked).filter(x => x).length,
    [filtersChecked],
  );

  const filterSections = createFilterSections(filterGroups, setFilters, filtersChecked);
  const filtersLabel = useMicroCopy('sparePartsFiltersLabel');
  const clearAllLabel = useMicroCopy('sparePartsClearAllLabel');
  const showFilterResultsLabelOne = useMicroCopy('sparePartsShowFilterResultsLabelOne');
  const showFilterResultsLabelTwo = useMicroCopy('sparePartsShowFilterResultsLabelTwo');
  const filterCountLabel =
    filtersLabel + (activeFilters > 0 ? ` (${activeFilters})` : '');

  return (
    <>
      {display === 'desktop' && (
        <DesktopSupport data-test-id="filter-desktop">
          {filterSections.map(filterSection => {
            return (
              <AccordionItem
                dataTestId={`accordion-desktop-${filterSection.title}`}
                key={filterSection.title}
                title={filterSection.title}
                content={filterSection.content}
                bgColorHover={white}
                borderColor={grey[40]}
                arrowColor={grey[60]}
                variation="faq"
                isOpen
              />
            );
          })}
        </DesktopSupport>
      )}
      {display === 'mobile' && (
        <MobileSupport data-test-id="filter-mobile">
          <FilterFlyoutButton
            data-test-id="flyout-button"
            onClick={() => setIsOpenMobile(true)}
          >
            <Eyebrow is="span" size="small">
              {filterCountLabel}
            </Eyebrow>
          </FilterFlyoutButton>
          <FilterFlyout
            isOpen={isOpenMobile}
            ariaLabel="navigation"
            backgroundColor={grey[90]}
            handleRequestClose={() => setIsOpenMobile(false)}
          >
            <>
              <IconContainer
                data-test-id="navigation-close"
                onClick={() => setIsOpenMobile(false)}
                aria-label="Close"
              >
                <Icon name="close" primaryColor={white} />
              </IconContainer>
              <MobileFilterContainer>
                <MobileHeader>
                  <Label as="h1" size="extraLarge" weight="medium">
                    {filtersLabel}
                  </Label>
                  <FilterFlyoutButton
                    onClick={() => clearAllFilters(filtersChecked, setFilters)}
                  >
                    {clearAllLabel}
                  </FilterFlyoutButton>
                </MobileHeader>
                <FilterButtonContainer>
                  {createFilterButtons(filtersChecked, filterNames, setFilters)}
                </FilterButtonContainer>
                <MobileFilterSection>
                  {filterSections.map(filterSection => {
                    return (
                      <MobileAccordionItem key={filterSection.title}>
                        <MobileAccordionItemInner>
                          <AccordionItem
                            dataTestId={`accordion-mobile-${filterSection.title}`}
                            title={filterSection.title}
                            content={filterSection.content}
                            bgColorHover={white}
                            borderColor={white}
                            arrowColor={grey[60]}
                            variation="faq"
                          />
                        </MobileAccordionItemInner>
                      </MobileAccordionItem>
                    );
                  })}
                </MobileFilterSection>
              </MobileFilterContainer>
              <FilterButtonsContainer>
                <Button
                  onClick={() => setIsOpenMobile(false)}
                >{`${showFilterResultsLabelOne} ${filteredCount} ${showFilterResultsLabelTwo}`}</Button>
              </FilterButtonsContainer>
            </>
          </FilterFlyout>
        </MobileSupport>
      )}
    </>
  );
};

export default Filter;

const FilterButtonsContainer = styled.div`
  padding: ${spacing[16]} ${spacing[24]};
  text-align: center;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.08);
`;

const DesktopSupport = styled.ul`
  display: none;

  ${media.desktopLarge`
    display: block;

    > div {
      width: 222px;
      text-align: left;
    }
  `}
`;

const MobileSupport = styled.div`
  ${media.desktopLarge`
    display: none;
  `}
`;

const IconContainer = styled.button`
  position: absolute;
  top: -${spacing[48]};
  right: 16px;
`;

const FilterFlyoutButton = styled.button`
  border-bottom: 1px solid black;
  font-weight: 500;
`;

const MobileFilterContainer = styled.div`
  overflow-y: scroll;
  padding: ${spacing[24]} 0;
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${spacing[24]};
  padding: 0 ${spacing[32]} ${spacing[24]} ${spacing[32]};
`;

const FilterButtonContainer = styled.ul`
  padding: 0 ${spacing[32]};
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;

const FilterButtonListItem = styled.li`
  margin-right: ${spacing[12]};
  margin-bottom: ${spacing[24]};
`;

const MobileFilterSection = styled.div`
  border-top: solid 1px ${grey[40]};
`;

const MobileAccordionItem = styled.div`
  border-bottom: solid 1px ${grey[40]};
`;

const MobileAccordionItemInner = styled.div`
  padding: 0 ${spacing[32]};
`;
