import type {
  TypeComponentBanner,
  TypeComponentLegalText,
  TypeVariation,
  TypeComponentLeadGen,
} from '@page-builder/lib/types';

type GenericEntry<T = {}> = {
  fields: T;
};

const isEntry = <T, FinalType extends GenericEntry>(
  entry: T | FinalType,
  path: string,
): entry is FinalType => {
  return (entry as FinalType).fields[path] !== undefined;
};

const toModuleOrVariation = <T extends GenericEntry>(
  entry: T | TypeVariation,
  uniqueField: string,
) => {
  let moduleData, variation;

  if (isEntry<T, TypeVariation>(entry, 'value')) {
    variation = entry;
  }

  if (isEntry<TypeVariation, T>(entry, uniqueField)) {
    moduleData = entry;
  }

  return { moduleData, variation };
};

export const toBannerOrVariation = (entry: TypeComponentBanner | TypeVariation) =>
  toModuleOrVariation<TypeComponentBanner>(entry, 'text');

export const toLeadGenModalOrVariation = (entry: TypeComponentLeadGen | TypeVariation) =>
  toModuleOrVariation<TypeComponentLeadGen>(entry, 'headlineText');

export const toLegalTextOrVariation = (entry: TypeComponentLegalText | TypeVariation) =>
  toModuleOrVariation<TypeComponentLegalText>(entry, 'key');

export const isEntryType = <FinalType extends GenericEntry>(
  entry: GenericEntry | FinalType,
  path: string,
): entry is FinalType => {
  return (entry as FinalType).fields[path] !== undefined;
};

export const filterFalsyValues = <T extends any>(arg: T): arg is NonNullable<T> =>
  Boolean(arg);
