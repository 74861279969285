// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type AvailabilityStateBySlugQueryVariables = Types.Exact<{
  locale: Types.Locale;
  currency: Types.Currency;
  catalog: Types.Scalars['Slug'];
  package: Types.Scalars['Slug'];
}>;

export type AvailabilityStateBySlugQuery = { __typename?: 'Query' } & {
  catalog: { __typename?: 'Catalog' } & {
    packageBySlug?: Types.Maybe<
      { __typename?: 'Package' } & Pick<Types.Package, 'slug'> & {
          availability: { __typename?: 'Availability' } & Pick<
            Types.Availability,
            'state' | 'code'
          >;
        }
    >;
  };
};

export const AvailabilityStateBySlugDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailabilityStateBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalog' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Slug' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'package' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Slug' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalog' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageBySlug' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'package' },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availability' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAvailabilityStateBySlugQuery__
 *
 * To run a query within a React component, call `useAvailabilityStateBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityStateBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityStateBySlugQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      catalog: // value for 'catalog'
 *      package: // value for 'package'
 *   },
 * });
 */
export function useAvailabilityStateBySlugQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AvailabilityStateBySlugQuery,
    AvailabilityStateBySlugQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    AvailabilityStateBySlugQuery,
    AvailabilityStateBySlugQueryVariables
  >(AvailabilityStateBySlugDocument, baseOptions);
}
export function useAvailabilityStateBySlugLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AvailabilityStateBySlugQuery,
    AvailabilityStateBySlugQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    AvailabilityStateBySlugQuery,
    AvailabilityStateBySlugQueryVariables
  >(AvailabilityStateBySlugDocument, baseOptions);
}
export type AvailabilityStateBySlugQueryHookResult = ReturnType<
  typeof useAvailabilityStateBySlugQuery
>;
export type AvailabilityStateBySlugLazyQueryHookResult = ReturnType<
  typeof useAvailabilityStateBySlugLazyQuery
>;
export type AvailabilityStateBySlugQueryResult = ApolloReactCommon.QueryResult<
  AvailabilityStateBySlugQuery,
  AvailabilityStateBySlugQueryVariables
>;
