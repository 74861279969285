import React from 'react';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import CtaBanner from './CtaBanner';

const defaults = {
  theme: 'Grey 90',
} as const;

const CtaBannerDark: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ theme = defaults.theme, ...props }) => {
  const { backgroundColor } = themes[theme];

  return <CtaBanner {...props} backgroundColor={backgroundColor} />;
};

export default CtaBannerDark;
