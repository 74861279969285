import React from 'react';
import { useClient } from '@peloton/api';
import type { AccessCodeErrorCode } from './api/AccessCodeErrorCode';
import type { RedemptionCodePayload } from './api/fetchAppleRedemptionCodeUrl';
import { fetchAppleRedemptionCodeUrl } from './api/fetchAppleRedemptionCodeUrl';
import { useAsync } from './utils/useAsync';

export const useAppleRedemptionCodeUrl = (payload: RedemptionCodePayload) => {
  const client = useClient();
  const useAsyncValues = useAsync<string, AccessCodeErrorCode>();
  const { run, isError, error } = useAsyncValues;

  React.useEffect(() => {
    void run(fetchAppleRedemptionCodeUrl(client, payload));
  }, [client, run, payload.tierId, payload.accessCode]);

  React.useEffect(() => {
    if (isError) {
      // error track
    }
  }, [isError, error]);

  return useAsyncValues;
};
