import type { ResponsiveCellDimensions } from '@ecomm/carousel';

export const cellSizes: ResponsiveCellDimensions = {
  mobile: {
    width: 240,
    height: 320,
    spacing: 8,
  },
  tablet: {
    width: 240,
    height: 320,
    spacing: 12,
  },
  desktop: {
    width: 240,
    height: 320,
    spacing: 12,
  },
};
