import type { StarsProps } from '@pelotoncycle/design-system';
import { grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';

export type ThemeProps = {
  backgroundColor: string;
  secondaryBackgroundColor: string;
  cardBackgroundColor: string;
  modalBackgroundColor: string;
  modalButtonBackgroundColor: string;
  totalReviewCountColor: string;
  moreReviewsButtonColor: 'light' | 'dark';
  allReviewsCtaColor: string;

  textColor: string;
  itemTextColor: string;

  starsColor: StarsProps['theme'];
};
type ThemeColor = NonNullable<TypeComponentReviewsFields['theme']>;
type Themes = Record<ThemeColor, ThemeProps>;

export const themes: Themes = {
  White: {
    backgroundColor: white,
    secondaryBackgroundColor: grey[40],
    cardBackgroundColor: grey[30],
    modalBackgroundColor: white,
    modalButtonBackgroundColor: grey[30],
    totalReviewCountColor: grey[70],
    moreReviewsButtonColor: 'dark',
    allReviewsCtaColor: grey[90],

    textColor: grey[90],
    itemTextColor: grey[70],

    starsColor: 'light',
  },
  Black: {
    backgroundColor: grey[90],
    secondaryBackgroundColor: grey[75],
    cardBackgroundColor: grey[75],
    modalBackgroundColor: grey[80],
    modalButtonBackgroundColor: rgba(grey[70], 0.25),
    totalReviewCountColor: grey[40],
    moreReviewsButtonColor: 'light',
    allReviewsCtaColor: white,

    textColor: white,
    itemTextColor: grey[40],

    starsColor: 'dark',
  },
} as const;
