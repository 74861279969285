import {
  Display,
  grey,
  Badge,
  Body,
  Label,
  Flex,
  FlexChild,
  Grid as DSGrid,
  spacing,
  Container,
  white,
  ResponsiveImage,
  BreakpointWidths,
  Button,
} from '@pelotoncycle/design-system';
import { LinkButtons, ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media } from '@peloton/styles';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Product } from '@ecomm/product-states/models/product';
import { toBundleType } from '@ecomm/shop/models/Bundle';
import useDigitalPrice from '@ecomm/subscription-plans/useDigitalPrice';
import type { CustomPricing } from '@onewellness/pg-benefit-options/ui/CustomPriceDisplay';
import CustomPrice from '@onewellness/pg-benefit-options/ui/CustomPriceDisplay';
import checkEntryNameSubscriptionType from '@onewellness/utils/helpers/page-builder/checkEntryNameSubscriptionType';
import type {
  TypeComponentProductCard,
  TypeProductFields,
} from '@page-builder/lib/types';
import { CONTAINER_MAXWIDTH, TEXT_MAXWIDTH } from '@page-builder/modules/tokens';
import {
  getContentfulAssetDescription,
  getContentfulAssetUrl,
} from '@page-builder/utils/helpers/cms';
import { usePriceToText } from '@page-builder/utils/helpers/priceToText';
import { toProductDisplayName } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';
import Banner from '../Banner/Banner';
import { PromoBanner, PromoPill } from '../Promo';
import DisplayPrice from './DisplayPrice';
import DisplayPriceForBundlePromo from './DisplayPriceForBundlePromo';
import DisplayPriceWithPromo from './DisplayPriceWithPromo';
import IconList from './IconList';
import Legal from './Legal';

export type AlignmentProps = 'column' | 'row';

type Props = {
  card: TypeComponentProductCard;
  alignment?: AlignmentProps;
  hideUpperSpacing?: boolean;
  hideLowerSpacing?: boolean;
  customPricing?: CustomPricing[];
};

const ProductCard: React.FC<React.PropsWithChildren<Props>> = ({
  card,
  alignment = 'column',
  hideLowerSpacing = false,
  hideUpperSpacing = false,
  customPricing,
}) => {
  const {
    fields: {
      name: entryName,
      product: {
        fields: { name: productName, image, bundleType, productId },
      },
      totalPricing,
      ribbon,
      disclaimer,
      ctas,
      banner,
      ribbonTheme,
      priceInfo,
      iconList,
      specialOffer,
    },
  } = card;

  const appPrice = usePriceToText(useDigitalPrice());
  const ctasExtractedData = ctas?.map(cta => {
    const parsedCta = ctaExtractData(cta);
    return {
      ...parsedCta,
      color: parsedCta.color || 'dark',
      variant: parsedCta.variant || 'outline',
      styledAs: parsedCta.styledAs
        ? parsedCta.styledAs
        : parsedCta.variant && parsedCta.color
        ? 'button'
        : 'link',
    };
  });
  /**
   * Adding a quick condition specific to the feature work from TAKO-1312
   * @see https://pelotoncycle.atlassian.net/browse/TAKO-1312
   */
  const isToggleActive = useIsToggleActive();
  const isTierMobilityEnabled = isToggleActive('tierMobilityEnabled');
  const isAppTieringVariant = isTierMobilityEnabled && bundleType === 'app';

  // find custom pricing for product card based on entry name
  const customCardPricing = customPricing?.find(customPrice =>
    checkEntryNameSubscriptionType(entryName, customPrice.subscriptionType),
  );
  const customCardButton = customCardPricing && ctasExtractedData && ctasExtractedData[0];

  const usePromoContentMgmt = isToggleActive('usePromoContentMgmt') && !customPricing;

  const primaryCtaProductSlug = ctas?.[0].fields.productSlug;

  return (
    <StyledContainer
      backgroundColor={white}
      borderRadius={{ desktop: spacing[12] }}
      display="grid"
      maxWidth={CONTAINER_MAXWIDTH}
      hasBanner={!!banner}
      data-test-id="product-card-container"
    >
      {isCfu(bundleType) && usePromoContentMgmt && !specialOffer ? (
        <StyledBanner>
          <PromoBanner cfu={bundleType} />
        </StyledBanner>
      ) : (
        banner && (
          <StyledBanner>
            <Banner data={banner} />
          </StyledBanner>
        )
      )}
      <Grid alignment={alignment}>
        <ContentBlock alignment={alignment}>
          <StyledFlexContainer
            flexDirection="column"
            gap={spacing[16]}
            verticalMargin={`0 ${spacing[24]}`}
            justifyContent="center"
            maxWidth={{ tablet: TEXT_MAXWIDTH['tablet'] }}
            centered
            textAlign="center"
          >
            {isCfu(bundleType) && usePromoContentMgmt && !specialOffer ? (
              <FlexChild className="ribbon">
                <PromoPill cfu={bundleType}></PromoPill>
              </FlexChild>
            ) : specialOffer?.fields.pillMessaging ? (
              <FlexChild className="ribbon">
                <Badge variant="horizontal" theme={ribbonTheme as any}>
                  {specialOffer.fields.pillMessaging}
                </Badge>
              </FlexChild>
            ) : (
              ribbon &&
              ribbonTheme && (
                <FlexChild className="ribbon">
                  <Badge variant="horizontal" theme={ribbonTheme as any}>
                    {ribbon}
                  </Badge>
                </FlexChild>
              )
            )}

            <StyledH2 size="small" forwardedAs="h2">
              {toProductDisplayName(productName)}
            </StyledH2>

            {isAppTieringVariant && !hideUpperSpacing && (
              <Container as="div">
                <DSGrid columnCount={1}>
                  {totalPricing && (
                    <Label size="extraLarge" textColor="black">
                      <Markdown content={totalPricing} />
                    </Label>
                  )}
                  {priceInfo ? (
                    <Body size="small" textColor={grey[90]}>
                      <Markdown content={priceInfo} />
                    </Body>
                  ) : (
                    // so buttons still align if priceInfo is empty
                    <>&nbsp;</>
                  )}
                </DSGrid>
              </Container>
            )}

            {!isAppTieringVariant && priceInfo && (
              <Body size="small" textColor={grey[90]}>
                <Markdown content={priceInfo} />
              </Body>
            )}

            {bundleType !== 'accessories' &&
              bundleType !== 'app' &&
              bundleType !== 'apparel' && (
                <Label size="extraLarge" is="span" textColor={grey[90]}>
                  {customCardPricing ? (
                    <CustomPrice
                      retailRate={customCardPricing.retailRate}
                      userPays={customCardPricing.userPays}
                    />
                  ) : (
                    <>
                      {productId === Product.DigitalApp ? (
                        <DisplayPrice
                          price={appPrice}
                          bundleType={toBundleType(bundleType)}
                          displayValue="monthly"
                        />
                      ) : specialOffer ? (
                        <DisplayPriceForBundlePromo
                          bundleType={toBundleType(bundleType)}
                          specialOffer={specialOffer}
                          productSlug={primaryCtaProductSlug}
                        />
                      ) : (
                        <DisplayPriceWithPromo bundleType={toBundleType(bundleType)} />
                      )}
                    </>
                  )}
                </Label>
              )}
          </StyledFlexContainer>
          <Flex
            gap={spacing[24]}
            alignItems="center"
            verticalMargin={{
              mobile: `0 ${spacing[4]}`,
              tablet: `0 ${spacing[16]}`,
              desktop: `0 ${spacing[8]}`,
            }}
            justifyContent="center"
            maxWidth={TEXT_MAXWIDTH['mobile']}
            centered
          >
            {customCardButton ? (
              <Button
                size="medium"
                children={customCardButton.text}
                color={customCardButton.color}
                variant={customCardButton.variant}
                onClick={customCardPricing && customCardPricing.overrideOnClick}
              />
            ) : (
              <StyledLinkButtons
                ctas={ctasExtractedData}
                hasDropShadow={!!media}
                parentId={'WWW Link'}
                parentName={'Product Info Card'}
              />
            )}
          </Flex>
          {!hideLowerSpacing && (
            <Container as="div" verticalMargin={`${spacing[24]} 0`}>
              <Legal alignment={alignment} disclaimer={disclaimer} />
            </Container>
          )}
        </ContentBlock>
        {iconList && <IconList iconList={iconList} />}
        {image && (
          <MediaBlock alignment={alignment}>
            <StyledPerformantImage
              mobile={toCloudinarySrc({ src: getContentfulAssetUrl(image), width: 600 })}
              alt={getContentfulAssetDescription(image)}
              loading="lazy"
            />
          </MediaBlock>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default ProductCard;

const isCfu = (bundleType: TypeProductFields['bundleType']) => {
  return !['accessories', 'apparel', 'app'].includes(bundleType);
};

type ConfigProps = {
  alignment: AlignmentProps;
};

type GridProps = {
  padding?: { [m: string]: string };
};

const StyledH2 = styled(Display)`
  color: ${grey[90]};
`;

const StyledFlexContainer = styled(Flex)`
  line-height: 1;
  margin-top: ${spacing[16]};
  @media (min-width: ${BreakpointWidths.tablet}) {
    margin-top: ${spacing[8]};
  }
`;

const StyledPerformantImage = styled(ResponsiveImage)`
  img {
    max-width: 100%;
  }
`;

const StyledContainer = styled(Container)<{ hasBanner?: boolean }>`
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.3s ease-in-out 0s;
  & > a {
    width: 100%;
    display: grid;
    ${({ hasBanner }) => hasBanner && 'grid-template-rows: 40px 1fr'}
  }
  ${media.tabletXLarge` 
    &:hover,
    &:focus-within {
      box-shadow: 0px 6px 24px 0px rgb(0 0 0 / 16%);
    }
  `}
`;

const Grid = styled.div<ConfigProps & GridProps>`
  display: grid;
  gap: 24px;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-auto-rows: auto;
  margin: 0 auto;
  padding-block: ${spacing[24]} ${spacing[16]};

  ${media.tabletXLarge<ConfigProps & GridProps>` 
    grid-template-columns: repeat(2, 332px);
    grid-template-rows: auto auto;
    padding-block: ${spacing[48]} ${spacing[16]};
  `}
  ${media.desktopLarge<ConfigProps>`
    grid-template-columns: ${({ alignment }) =>
      alignment === 'row'
        ? `repeat(2, minmax(${TEXT_MAXWIDTH['mobile']}, 520px))`
        : 'fit-content(100%)'};
    grid-template-rows: ${({ alignment }) =>
      alignment === 'row' ? 'auto auto' : 'auto 1fr'};
    padding-inline: ${spacing[24]};
  `}
`;

const ContentBlock = styled.div<ConfigProps>`
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  padding-inline: ${spacing[32]};
  ${media.tabletXLarge<ConfigProps>`
    padding-inline: unset;
  `}
`;

const MediaBlock = styled.div<ConfigProps>`
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  align-self: center;
  padding-inline: ${spacing[16]};

  ${media.tabletXLarge<ConfigProps>`
    padding-inline: unset;
    grid-column: 2;
    grid-row: 1;
  `}

  ${media.desktopLarge<ConfigProps>`
    grid-column: ${({ alignment }) => (alignment === 'row' ? '2' : '1')};
    grid-row: ${({ alignment }) => (alignment === 'row' ? '1' : '2')};
  `}
`;

const StyledBanner = styled.div`
  & > div,
  & > a > span {
    min-height: unset !important;
    padding: ${spacing[12]} ${spacing[16]} !important;
  }

  [data-element-id='banner'] {
    ${media.desktopLarge`
      border-radius: ${spacing[12]} ${spacing[12]} 0 0;
    `}
  }
`;

const StyledLinkButtons = styled(LinkButtons)`
  gap: ${spacing[24]};

  & > div:first-child a {
    width: auto;
  }
`;
