import type { MediaImageProps } from '@pelotoncycle/design-system';
import {
  Label,
  Badge,
  Flex,
  Eyebrow,
  ResponsiveImage,
  spacing,
  grey,
} from '@pelotoncycle/design-system';

import type { PropsWithChildren } from 'react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Markdown from '@page-builder/utils/Markdown';

export type Props = {
  title: string;
  image: MediaImageProps;
  caption: string;
  newLabel?: string;
};

const Card: React.FC<
  React.PropsWithChildren<
    PropsWithChildren<
      Props & {
        LinkRenderer?: React.FC<
          React.PropsWithChildren<PropsWithChildren<{ href: string }>>
        >;
        ImageContainer?: React.FC<React.PropsWithChildren<unknown>>;
        headlineColor: string;
        textColor: string;
      }
    >
  >
> = ({
  title,
  image,
  newLabel,
  caption,
  children,
  LinkRenderer,
  ImageContainer = props => <>{props.children}</>,
  headlineColor,
  textColor = `${grey[70]}`,
}) => (
  <Flex flexDirection="column" gap={spacing[12]}>
    <ImageContainer>
      <ImageStack>
        <StyleImage alt={image.alt} mobile={image.mobile} />
        <Gradient aria-hidden="true" />
        {newLabel && (
          <Badge
            variant="horizontal"
            theme="accent"
            position="absolute"
            top={spacing[16]}
            left={spacing[16]}
          >
            {newLabel}
          </Badge>
        )}
        {children}
      </ImageStack>
    </ImageContainer>
    <Eyebrow size="small" textColor={headlineColor}>
      {title}
    </Eyebrow>
    <Label size="small" textColor={textColor}>
      <Markdown
        content={caption}
        markdown={
          LinkRenderer
            ? {
                renderers: {
                  link: LinkRenderer,
                },
              }
            : undefined
        }
      />
    </Label>
  </Flex>
);

export default Card;

const rotate = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const ImageStack = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 6px;
  transition: all 0.3s ease-in-out 0s;
  height: 192px;
  width: 288px;
  background-color: ${grey[50]};
  background: linear-gradient(300deg, ${grey[50]}, ${grey[30]}, ${grey[50]});
  background-size: 180% 180%;

  animation: ${rotate} 10s linear infinite;

  & > svg {
    position: absolute;
    bottom: ${spacing[16]};
    left: ${spacing[16]};
  }

  img {
    transition: all 500ms ease-in-out;
    border-radius: 6px;
  }

  &:hover,
  &:focus-within {
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.24);
    img {
      transform: scale(1.03);
    }
    svg {
      fill: ${grey[50]};
      opacity: 0.8;
    }
  }
`;

const StyleImage = styled(ResponsiveImage)`
  overflow: hidden;
  border-radius: ${spacing[4]};

  & * {
    max-width: 288px;
    display: block;
  }
`;

const Gradient = styled.div`
  width: 200px;
  height: 88px;
  background-image: linear-gradient(19deg, rgba(0, 0, 0, 0.72) 0%, transparent 44%);
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.8;
`;
