import { BundleType } from '@ecomm/shop/models/BundleType';

export enum DeviceType {
  App = 'app',
  Bike = 'bike',
  BikePlus = 'bike-plus',
  Tread = 'tread',
  TreadPlus = 'tread-plus',
  Guide = 'guide',
  Row = 'row',
  RefurbishedBike = 'refurbished-bike',
  RefurbishedBikePlus = 'refurbished-bike-plus',
}

export type ReviewableTypes = Exclude<DeviceType, DeviceType.App>;

export enum DeviceFamily {
  Bikes = 'bikes',
  Treads = 'treads',
  Strength = 'strength',
  Row = 'row',
}

export const getNameFromDeviceType = (deviceType: DeviceType | ReviewableTypes) => {
  switch (deviceType) {
    case DeviceType.App:
      return 'App';
    case DeviceType.Bike:
      return 'Bike';
    case DeviceType.BikePlus:
      return 'BikePlus';
    case DeviceType.Tread:
      return 'Tread';
    case DeviceType.TreadPlus:
      return 'TreadPlus';
    case DeviceType.Guide:
      return 'Guide';
    case DeviceType.Row:
      return 'Row';
    case DeviceType.RefurbishedBike:
      return 'Refurbished Bike';
    case DeviceType.RefurbishedBikePlus:
      return 'Refurbished BikePlus';
    default:
      return '';
  }
};

export const deviceTypeToDisplayName = (deviceType: DeviceType | ReviewableTypes) => {
  switch (deviceType) {
    case DeviceType.App:
      return 'App';
    case DeviceType.Bike:
      return 'Bike';
    case DeviceType.BikePlus:
      return 'Bike+';
    case DeviceType.Tread:
      return 'Tread';
    case DeviceType.TreadPlus:
      return 'Tread+';
    case DeviceType.Guide:
      return 'Guide';
    case DeviceType.RefurbishedBike:
      return 'Refurbished Bike';
    case DeviceType.RefurbishedBikePlus:
      return 'Refurbished Bike+';
    case DeviceType.Row:
      return 'Row';
    default:
      throw new Error(`Cannot convert ${deviceType} to display name`);
  }
};

export const getDeviceTypeFromDisplayName = (
  displayName: string,
): DeviceType | undefined => {
  switch (displayName) {
    case 'App':
      return DeviceType.App;
    case 'Bike':
      return DeviceType.Bike;
    case 'Bike+':
      return DeviceType.BikePlus;
    case 'Tread':
      return DeviceType.Tread;
    case 'Tread+':
      return DeviceType.TreadPlus;
    case 'Guide':
      return DeviceType.Guide;
    case 'Refurbished Bike':
      return DeviceType.RefurbishedBike;
    case 'Refurbished Bike+':
      return DeviceType.RefurbishedBikePlus;
    case 'Row':
      return DeviceType.Row;
    default:
      return undefined;
  }
};

export const getDeviceFamilyTypeFromString = (deviceType: string) => {
  switch (deviceType) {
    case 'bike':
    case 'bike-plus':
      return DeviceFamily.Bikes;
    case 'tread':
    case 'tread-plus':
      return DeviceFamily.Treads;
  }
  return '';
};

export const bundleTypeToDeviceType = (bundle: BundleType): DeviceType => {
  switch (bundle) {
    case BundleType.Bike:
      return DeviceType.Bike;
    case BundleType.BikePlus:
      return DeviceType.BikePlus;
    case BundleType.Tread:
      return DeviceType.Tread;
    case BundleType.TreadPlus:
      return DeviceType.TreadPlus;
    case BundleType.RainforestCafe:
      return DeviceType.Guide;
    case BundleType.Row:
      return DeviceType.Row;
    case BundleType.RefurbishedBike:
      return DeviceType.RefurbishedBike;
    case BundleType.RefurbishedBikePlus:
      return DeviceType.RefurbishedBikePlus;
    default:
      throw new Error(`CAN'T CONVERT ${bundle} to DeviceType`);
  }
};
