import { SingleSelect } from '@pelotoncycle/design-system';
import React from 'react';
import { useMicroCopy } from '@content/client/microCopy';
import { useProductConfigurationContext } from '../ProductConfigurationContext';
import type { AccessoryWithMetaData, Option } from './types';

const ItemDropdown: React.FC<
  React.PropsWithChildren<{
    accessory: AccessoryWithMetaData;
  }>
> = ({ accessory }) => {
  const upsellRequiredCopy = useMicroCopy('upsellRequiredField');
  const selectSizeMicroCopy = useMicroCopy('selectSizeLabel');
  const {
    setProductConfigurations,
    clearSelectedConfiguration,
    productConfigurations,
    misconfiguredProducts,
    trackProductConfigurationSelection,
  } = useProductConfigurationContext();

  const hasError = misconfiguredProducts[accessory.slug];
  const selectedVariantSlug =
    productConfigurations[accessory.slug]?.[accessory.slug]?.slug;
  const items = accessory.attributes[0].options.map(({ name, slug }: any) => ({
    option: name,
    value: slug,
  }));

  return (
    <SingleSelect
      key={accessory.attributes[0].name}
      data-test-id="product-configuration-dropdown"
      items={items}
      label={selectSizeMicroCopy}
      theme="light"
      required
      disabled={false}
      errorMessage={hasError && upsellRequiredCopy}
      selectedItem={accessory.variantSlugToAccessory?.[selectedVariantSlug!]?.name}
      handleSelectedItemChange={(item: Option) => {
        if (!item) {
          clearSelectedConfiguration(accessory.slug, accessory.slug);
          return;
        }

        const variant = accessory.attributeSlugToVariant?.[item?.value];
        setProductConfigurations({
          accessorySlug: accessory.slug,
          productSlug: accessory.slug,
          variant,
        });
        trackProductConfigurationSelection(accessory, variant, Boolean(item));
      }}
    />
  );
};

export default ItemDropdown;
