import { grey, white } from '@pelotoncycle/design-system';
import React, { useRef } from 'react';
import { useTracking } from 'react-tracking';
import { LiftedModal } from '@page-builder/modules/NextModal';
import type { LiftedModalProps } from '@page-builder/modules/NextModal';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import type { TypeModalSection } from '../utils';
import { ModalSection } from './ModalSection';

type ContentCardsModalProps = {
  modalContent: TypeModalSection[];
  selectedItem?: string;
};

export const ContentCardsModal: React.FC<
  React.PropsWithChildren<LiftedModalProps & ContentCardsModalProps>
> = ({ isOpen = true, closeHandler, contentLabel, selectedItem, modalContent }) => {
  const selectedSectionIndex = modalContent.findIndex(
    section => section.sectionName === selectedItem,
  );
  const isFirstSection = selectedSectionIndex === 0;
  const { headlineColor, type } = useTheme();
  const { Track } = useTracking({
    properties: {
      parent: 'Content Cards Modal',
      parentName: `${contentLabel} Modal`,
    },
  });
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleAfterOpen = () => {
    setTimeout(() => {
      const selectedSection = modalContentRef.current?.querySelector(
        `.pos-${selectedItem}`,
      );
      const selectedSectionTop = selectedSection?.getBoundingClientRect().top ?? 0;
      const modalTop = modalContentRef.current?.getBoundingClientRect().top ?? 0;
      const innerContent = document.querySelector('.ReactModal__Overlay--after-open');

      if (innerContent) {
        innerContent.scrollTo({
          top: isFirstSection ? 0 : selectedSectionTop - modalTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  return (
    <Track>
      <LiftedModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        closeHandler={closeHandler}
        onAfterOpen={handleAfterOpen}
        modalContentRef={modalContentRef}
        backgroundColor={type === 'light' ? white : grey[80]}
        textColor={headlineColor}
      >
        {modalContent.map((section, idx) => (
          <ModalSection
            key={section.sectionName}
            section={section}
            sectionIndex={idx}
            isLastSection={idx + 1 === modalContent.length}
          />
        ))}
      </LiftedModal>
    </Track>
  );
};
