import { ALLOW_PREVIEW_COPY, COMMIT_REF } from '@peloton/app-config';
import { fetchPageCopy } from './fetchPageCopy';

const FETCH_COPY = ALLOW_PREVIEW_COPY || COMMIT_REF === 'local';

export const toPageFetcherWithoutCache = (
  slug: string,
  locale: string,
  preview: boolean = ALLOW_PREVIEW_COPY,
) => async () => {
  try {
    return FETCH_COPY || preview ? await fetchPageCopy(slug, preview, locale) : undefined;
  } catch {
    return undefined;
  }
};
