import {
  BreakpointWidths,
  Icon,
  grey,
  spacing,
  Eyebrow,
  Container,
} from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import type { TypeDisciplineListCard as DisciplineListCard } from './types';

export type DisciplineListProps = {
  cards: DisciplineListCard[];
};

const DisciplineLink: FC<DisciplineListCard> = ({ displayName, slug, iconName }) => {
  const { trackEvent } = useTracking();

  const trackDisciplineLinkClick = () => {
    trackEvent({
      event: 'Clicked Discipline Icon',
      properties: {
        parent: 'Prospect Classes Page',
        linkName: iconName,
        unitName: 'DisciplineList',
        linkTo: `/classes/${slug}`,
        parentType: 'Page',
      },
    });
  };

  return (
    <StyledLink href={`/classes/${slug}`} onClick={trackDisciplineLinkClick}>
      <Icon
        width={24}
        height={24}
        primaryColor="white"
        name={iconName}
        aria-hidden="true"
      />
      <StyledLabel size="small">{displayName}</StyledLabel>
    </StyledLink>
  );
};

const DisciplineList: FC<DisciplineListProps> = ({ cards }) => (
  <Container
    backgroundColor={grey[90]}
    display="flex"
    padding={{
      mobile: `0 0 ${spacing[32]} 0`,
      tablet: `0 0 ${spacing[64]} 0`,
      desktop: `0 0 ${spacing[80]} 0`,
    }}
    style={{
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    <DisciplineListContainer>
      {cards?.map(card => (
        <DisciplineLink
          key={card.displayName}
          displayName={card.displayName}
          slug={card.slug}
          iconName={card.iconName}
        />
      ))}
    </DisciplineListContainer>
  </Container>
);

const DisciplineListContainer = styled.div`
  display: grid;
  gap: ${spacing[8]};
  grid-template-columns: repeat(5, 238px);
  grid-template-rows: repeat(2, 72px);
  padding: ${spacing[8]} 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  @media (min-width: ${BreakpointWidths.mobile}px) {
    padding: ${spacing[8]} ${spacing[16]};
  }
  @media (min-width: ${BreakpointWidths.tablet}px) {
    padding: ${spacing[8]} ${spacing[32]};
  }
  @media (min-width: ${BreakpointWidths.desktop}px) {
    overflow-x: hidden;
  }
`;
const StyledLink = withI18n(styled.a`
  align-items: center;
  background-color: ${grey[80]};
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px #0000000a;
  color: white;
  display: flex;
  padding: ${spacing[24]};
  &:hover {
    background-color: #383a3c;
  }
`);
const StyledLabel = styled(Eyebrow)`
  margin-left: ${spacing[16]};
  font-weight: 600;
`;
export default DisciplineList;
