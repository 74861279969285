import { Container, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { TrackingEvent } from '@ecomm/analytics/models';
import type {
  TypeComponentCta,
  TypeComponent_overviewFields,
} from '@page-builder/lib/types';
import Headband from '@page-builder/modules/Headband';
import {
  mobileScrollHeadbandStyles,
  webScrollHeadbandStyles,
} from '@page-builder/modules/Headband/styles';
import type { ATCBandProps } from '@page-builder/modules/Headband/styles';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import AddToCartCta from '@page-builder/modules/Overview/ShopDrawers/AddToCartCta';
import {
  useHeadbandData,
  toCtaTrackingProperties,
  useScrollStarted,
} from '@page-builder/modules/Overview/utils';

export type Props = Pick<TypeComponent_overviewFields, 'product'>;

const ShopATCBands: React.FC<React.PropsWithChildren<Props>> = ({ product }) => {
  const {
    setShouldFocusError,
    shouldShowHeadband,
    shouldShowMobileATCBand,
  } = useShopContext();
  const { trackEvent } = useTracking();
  const scrollStarted = useScrollStarted();

  const headbandData = useHeadbandData(product);
  if (!headbandData) {
    return null;
  }

  const {
    fields: { cta: headbandCta, ...fieldsExceptCta },
  } = headbandData;

  const cta = headbandCta as TypeComponentCta | undefined;

  if (cta?.fields.productSlug) {
    const headbandPropsWithoutCta = {
      ...headbandData,
      fields: fieldsExceptCta,
    };

    const trackLinkClicked = () => {
      trackEvent({
        event: TrackingEvent.ClickedSubnavATC,
        properties: toCtaTrackingProperties(cta.fields, 'Headband'),
      });
    };

    const onAddToCart = () => {
      setShouldFocusError(true);

      trackLinkClicked();
    };

    const showMobileATCBand = scrollStarted && shouldShowMobileATCBand;

    return (
      <BreakpointSwitchCSS
        breakpoints={{
          mobile: (
            <MobileATCBandWrapper
              bottom={showMobileATCBand ? '0' : '-100px'}
              aria-hidden={!showMobileATCBand}
            >
              <AddToCartCta
                cta={cta.fields}
                onClick={onAddToCart}
                size="medium"
                width="adaptive"
              />
            </MobileATCBandWrapper>
          ),
          desktop: (
            <HeadATCBandWrapper
              top={shouldShowHeadband ? '0' : '-100px'}
              aria-hidden={!shouldShowHeadband}
            >
              <StyledHeadband {...headbandPropsWithoutCta}>
                <AddToCartCta
                  cta={cta.fields}
                  onClick={onAddToCart}
                  size="small"
                  width="adjustable"
                />
              </StyledHeadband>
            </HeadATCBandWrapper>
          ),
        }}
      />
    );
  }

  return <StyledHeadband {...headbandData} />;
};

export default ShopATCBands;

const HeadATCBandWrapper = styled(Container)<ATCBandProps>`
  ${webScrollHeadbandStyles}
`;

const MobileATCBandWrapper = styled(Container)<ATCBandProps>`
  ${mobileScrollHeadbandStyles}
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  padding: ${spacing[16]} ${spacing[24]} ${spacing[24]} ${spacing[24]};
  border-top: 1px solid ${grey[40]};

  button {
    opacity: 1;
  }
`;

const StyledHeadband = styled(Headband)`
  gap: ${spacing[16]};
  background: rgba(255, 255, 255, 0.9);
  color: ${grey[90]};
  border-bottom: 1px solid ${grey[40]};
  backdrop-filter: blur(12px);

  // Targeting the Flex that wraps the right side of the headband
  > div {
    flex-shrink: 0;

    // Targeting the Container that wraps the text on the right side of the headband
    div:first-of-type {
      max-width: unset;
      width: unset;
    }
  }
`;
