import { Container, spacing, Media } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import type { TypeComponentMedia } from '@page-builder/lib/types';
import { toMediaProps } from '@page-builder/utils/helpers/media';

export type MediaCardProps = {
  media?: TypeComponentMedia;
  onClick?: () => void;
};

const MediaCard: React.FC<React.PropsWithChildren<MediaCardProps>> = ({
  media,
  onClick,
}) => {
  const zoomIntoImageCopy = useMicroCopy('zoomIntoImage');
  const mediaWithCloudinary = media && toMediaProps(media);

  return (
    <>
      {mediaWithCloudinary && (
        <StyledContainer
          style={{ aspectRatio: '4/5' }}
          maxWidth="100vw"
          position="relative"
          borderRadius={spacing[4]}
          data-test-id="overview-gallery-media-card"
        >
          <StyledMedia media={mediaWithCloudinary} />
          {/* The below is to ensure that MediaCards can open a modal as a button, while also supporting the pause/play buttons and remaining accessible */}
          {onClick && (
            <ButtonCard
              type="button"
              onClick={onClick}
              aria-label={zoomIntoImageCopy}
              zoomDirection="in"
            />
          )}
        </StyledContainer>
      )}
    </>
  );
};

export default MediaCard;

const StyledMedia = styled(Media)`
  > div > video {
    position: relative;
  }
  > div > video,
  > picture > img {
    border-radius: ${spacing[4]};
    transition: transform 0.3s ease-in-out;
  }
`;

const StyledContainer = styled(Container)`
  // The below is to only expand the video or image in the card on hover,
  // not the entire card or the play/pause icon on top of the video

  ${mediaBreakpoints.desktopLarge`
  overflow: hidden;
  &:hover {
    > ${StyledMedia} {
      > div > video,
      > picture > img {
        transform: scale(1.03);
      }
    }
  }
  `}
`;

export const ButtonCard = styled.button<{ zoomDirection: 'out' | 'in' }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  &:hover {
    cursor: ${({ zoomDirection }) => `zoom-${zoomDirection}`};
  }
`;
