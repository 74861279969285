type LocationMap = {
  path: string;
  queryParams: string;
  hash: string;
};

/* 
 Decomposes a full path (including query parameters and hash) into a readable object. 
 This helps reconstructing URLs more easily.
 */
export const parsePathToLocationMap = (
  pathWithQueryParamsAndHash: string,
): LocationMap => {
  const [pathWithQueryParams, hash] = (pathWithQueryParamsAndHash || '').split('#');
  const [path, queryParams] = (pathWithQueryParams || '').split('?');

  return {
    path: path || '',
    queryParams: queryParams ? `?${queryParams}` : '',
    hash: hash ? `#${hash}` : '',
  };
};
