import { useTracking } from 'react-tracking';
import { toCountry } from '@peloton/internationalize';
import { TrackingEvent } from '@ecomm/analytics/models';
import { useAnswersForQuestion } from '@page-builder/modules/Quiz/utils/progress';

export type BaseProps = {
  slug: string;
  unitName: string;
};

export type LinkProps = {
  linkName: string;
  linkTo: string;
};

export const useTrackLinkClick = (props: BaseProps & LinkProps) => {
  const { trackEvent } = useTracking();
  const { unitName, linkName, linkTo, slug } = props;

  return () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'MWI',
        parent: `MWI ${slug}`,
        unitName,
        linkName,
        linkTo,
      },
    });
  };
};

const useBaseQuizStepProperties = (
  slug: string,
  stepNumber: number,
  quizSessionKey: string,
) => {
  return {
    quiz: quizSessionKey,
    Step: stepNumber + 1,
    StepName: slug,
    country: toCountry(),
  };
};

export const useTrackQuestionComplete = (
  slug: string,
  stepNumber: number,
  quizSessionKey: string,
) => {
  const { answers } = useAnswersForQuestion(slug);
  const baseProps = useBaseQuizStepProperties(slug, stepNumber, quizSessionKey);

  const formattedAnswers = answers.join(', ');
  const trackQuestionCompleteEventProps = {
    event: 'Quiz Step Completed',
    properties: {
      ...baseProps,
      Input: formattedAnswers,
    },
  };

  return trackQuestionCompleteEventProps;
};

export const useTrackStepViewed = (
  slug: string,
  stepNumber: number,
  quizSessionKey: string,
) => {
  const baseProps = useBaseQuizStepProperties(slug, stepNumber, quizSessionKey);

  useTracking(
    {
      event: 'Quiz Step Viewed',
      properties: baseProps,
    },
    {
      dispatchOnMount: true,
    },
  );
};
