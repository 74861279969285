import { Flex, Label, spacing, Icon, white } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled, { keyframes } from 'styled-components';
import { adaExists, openAdaChat } from '@peloton/chat/ada';
import { BreakpointWidth, media as mediaQuery } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import {
  useIsSinglePromptActive,
  useIsOriginalActive,
} from '@ecomm/feature-toggle/CocoEntryPoint';
import { THEMES, rotatingPromptsMock, singlePromptMock } from './constants';
import { RotatingPrompts } from './RotatingPrompts';

export type CocoEntryPointProps = {
  singlePrompt: string;
  rotatingPrompts: string[];
  theme?: 'Dark' | 'Light 1' | 'Light 2';
  includeMobileTopPadding?: boolean;
  includeMobileSidePadding?: boolean;
  includeMobileBottomPadding?: boolean;
};

export const CocoEntryPoint: React.FC<CocoEntryPointProps> = ({
  singlePrompt = singlePromptMock,
  rotatingPrompts = rotatingPromptsMock,
  theme = 'Dark',
  includeMobileTopPadding = true,
  includeMobileSidePadding = false,
  includeMobileBottomPadding = false,
}) => {
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const {
    backgroundColor,
    textColor,
    borderColor,
    sparkleColor,
    sendIconColor,
    boxShadowColor,
    buttonBackgroundColor,
    hoverButtonBackgroundColor,
    boxShadow,
    boxShadowHover,
    boxShadowColorHover,
    outerContainerTheme,
  } = THEMES[theme];
  const { trackEvent } = useTracking();
  const isSinglePrompt = useIsSinglePromptActive();
  const activePrompt = isSinglePrompt
    ? singlePrompt
    : rotatingPrompts[currentPromptIndex];

  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);

  const mobileVerticalPadding = () => {
    const topPadding = includeMobileTopPadding ? spacing[16] : '0';
    const bottomPadding = includeMobileBottomPadding ? spacing[16] : '0';

    return `${topPadding} ${bottomPadding}`;
  };

  const mobileHorizontalPadding = includeMobileSidePadding ? spacing[16] : 0;

  const handleClick = async () => {
    if (adaExists()) {
      await openAdaChat();
    } else {
      console.log('Chat has not been initialized'); // TODO: improve error reporting
    }
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'CocoEntryPoint (CHAMP)',
        parent: 'CocoEntryPoint',
        unitName: 'CocoEntryPoint Prompt',
        linkName: activePrompt,
      },
    });
  };

  if (useIsOriginalActive()) {
    return null;
  }

  return (
    <OuterContainer
      theme={outerContainerTheme}
      backgroundColor={backgroundColor}
      horizontalPadding={{ mobile: mobileHorizontalPadding, desktop: spacing[16] }}
      alignItems={'center'}
    >
      <StyledContainer
        className="mainContainer"
        verticalPadding={{ mobile: mobileVerticalPadding(), desktop: `${spacing[16]} 0` }}
        // horizontalPadding={{ mobile: mobileHorizontalPadding, desktop: spacing[16] }}
        display="flex"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        backgroundColor={isMobileOrTablet ? backgroundColor : 'none'}
      >
        <ButtonPrompt
          onClick={handleClick}
          boxShadowColor={boxShadowColor}
          borderColor={borderColor}
          buttonBackgroundColor={
            !isMobileOrTablet && theme == 'Light 2'
              ? THEMES['Light 1'].buttonBackgroundColor
              : buttonBackgroundColor
          }
          hoverButtonBackgroundColor={
            !isMobileOrTablet && theme == 'Light 2'
              ? THEMES['Light 1'].hoverButtonBackgroundColor
              : hoverButtonBackgroundColor
          }
          boxShadow={boxShadow}
          boxShadowHover={boxShadowHover}
          boxShadowColorHover={boxShadowColorHover}
          isSinglePrompt={isSinglePrompt}
        >
          <Flex gap={spacing[8]} alignItems="center">
            <StyledIcon name="sparkle" primaryColor={sparkleColor} />
            {isSinglePrompt ? (
              <Label
                size="responsiveLgXl"
                textColor={textColor}
                weight="medium"
                className="singlePrompt"
              >
                {singlePrompt}
              </Label>
            ) : (
              <RotatingPrompts
                textColor={textColor}
                rotatingPrompts={rotatingPrompts}
                currentPromptIndex={currentPromptIndex}
                setCurrentPromptIndex={setCurrentPromptIndex}
              />
            )}
          </Flex>
          {isMobileOrTablet ? (
            isSinglePrompt && <Icon name="send" primaryColor={sendIconColor} />
          ) : (
            <Icon name="send" primaryColor={sendIconColor} />
          )}
        </ButtonPrompt>
      </StyledContainer>
    </OuterContainer>
  );
};

const borderRotation = keyframes`
    from {
      background-position: 0% 50%;
    }

    to {
      background-position: 100% 50%;
    }
`;

const ButtonPrompt = styled.button<{
  boxShadowColor: string;
  borderColor: string;
  buttonBackgroundColor: string;
  hoverButtonBackgroundColor: string;
  boxShadow: string;
  boxShadowHover: string;
  boxShadowColorHover: string;
  isSinglePrompt: boolean | null;
}>`
  width: 358px;
  height: 48px;
  border-radius: 128px;
  backdrop-filter: blur(42px);
  padding: ${({ isSinglePrompt }) =>
    isSinglePrompt ? `0 ${spacing[16]}` : `${spacing[16]}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid transparent;
  background: ${({ buttonBackgroundColor }) => `${buttonBackgroundColor}`};
  background-blend-mode: saturation, normal, normal;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-mask: linear-gradient(${white} 0 0) padding-box, linear-gradient(${white} 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    border-radius: 128px;
    border: 2.5px solid transparent;
    background: ${({ borderColor }) => `linear-gradient(
        90deg,
        ${borderColor}
      )
      border-box`};
    background-size: 200%;
    animation: ${borderRotation} 1s ease infinite alternate;
  }

  :hover {
    background: ${({ hoverButtonBackgroundColor }) =>
      `linear-gradient(${hoverButtonBackgroundColor})  padding-box
`};
  }

  ${mediaQuery.desktopLarge<{
    boxShadowColor: string;
    boxShadow: string;
    boxShadowHover: string;
    boxShadowColorHover: string;
  }>`
    width: 628px;
    height: 64px;
    box-shadow: ${({ boxShadow, boxShadowColor }) => `${boxShadow} ${boxShadowColor}`};

    :hover{
    box-shadow: ${({ boxShadowHover, boxShadowColorHover }) =>
      `${boxShadowHover} ${boxShadowColorHover}`};
    }
`};
`;

const StyledContainer = styled(Flex)`
  width: 100%;

  ${mediaQuery.desktopLarge`
    margin-bottom: ${spacing[48]};
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 64px;
    z-index: 3;
    `}
`;

const StyledIcon = styled(Icon)`
  height: 24px;
  ${mediaQuery.desktopLarge`
  height: 32px;
`}
`;
