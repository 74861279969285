import { Container, Flex, Headline, Icon, spacing } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { usePanelContext } from '@page-builder/components/PanelContext';
import {
  CARD_WIDTHS,
  MAX_SCROLL_WIDTH,
  MAX_TEXT_WIDTH,
  CAROUSEL_CLICK_DELAY,
  OBSERVER_HANDLER_DELAY,
} from '@page-builder/modules/VideoCarousel/constants';
import Pagination from '@page-builder/modules/VideoCarousel/Pagination';
import {
  getScrollToLeftPosition,
  getObserver,
  usePaginationPropValues,
} from '@page-builder/modules/VideoCarousel/utils';
import { themes } from '@page-builder/themes';
import type { Theme as TypeTheme } from '../../../types/referenceTypes';
import MediaCarouselCard from './MediaCarouselCard';
import type { MediaCarouselCardProps as TypeMediaCarouselCard } from './types';

export type MediaCarouselProps = {
  headline?: string;
  componentName?: string;
  theme?: TypeTheme;
  paginationTextRaw: string;
  items: TypeMediaCarouselCard[];
};

/**
 * This component renders the Media Carousel module in Page Builder Express and replicates the Generic List with the Video Carousel treatment on Page Builder.
 * This module does not render the items in the carousel until it is scrolled into view.
 *  A card in the carousel can be set to active through the following:
 *    - Clicking on the previous or next buttons on the pagination.
 *    - Clicking or tabbing on an inactive card.
 *    - Scrolling an inactive card to the center of the viewport (using Intersection Observers).
 * @param headline optional string that will be displayed as the headline above the Media Carousel.
 * @param rawPaginationText string with markdown variables that is formatted and maps to the pagination text in order to denote the slide position of the user. Example: `{currentSlide} of {totalSlides}` which will map to something like `3 of 5` in the pagination controls.
 * @param theme the Theme from the entry. Theme is defaulted to Grey 90 when undefined.
 * @param componentName the string that is used for component identification in tracking events. This is not visually displayed on the component.
 * @param items a list of items with each one mapping to a card (MediaCarouselCard) in the Media Carousel
 */
const MediaCarousel: React.FC<
  React.PropsWithChildren<MediaCarouselProps & { carouselIndex?: number }>
> = ({
  children,
  headline,
  paginationTextRaw,
  theme = 'Grey 90',
  componentName,
  items,
  carouselIndex = 0,
}) => {
  const { backgroundColor, headlineColor, textColor } = themes[theme];
  const { trackEvent } = useTracking();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const totalNumberOfItems = items.length;

  const [inViewRef, isCarouselInView] = useInView({ triggerOnce: true });

  // Element Refs that help determine the scroll position of the carousel items.
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  // Used for Intersection Observer when a user scrolls an inactive card into view.
  const timeoutIdsRef = useRef<Record<number, ReturnType<typeof setTimeout>>>({});
  const observerRefs = useRef<(IntersectionObserver | null)[]>([]);
  const activeIndexRef = useRef(activeIndex);

  const { togglePanel } = usePanelContext();

  /**
   * Sends a 'Clicked Carousel Slide' tracking event to analytics.
   */
  const sendTrackingEvent = useCallback(() => {
    trackEvent({
      event: TrackingEvent.ClickedCarouselSlide,
      properties: {
        page: window.location.pathname,
        parent: 'Component: PLP',
        parentType: componentName,
        unitName: 'Recommendation Module',
      },
    });
  }, [componentName, trackEvent]);

  /**
   * Handles setting the inactive card based on the scroll position.
   */
  const handleActiveCardOnScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]; // each observer is observing a single element
      if (!entry) {
        return;
      }

      const isElementWithinRootBounds = entry.isIntersecting;
      const cardIndex = itemRefs.current.indexOf(entry.target as HTMLElement);

      if (isElementWithinRootBounds && activeIndexRef.current !== cardIndex) {
        timeoutIdsRef.current[cardIndex] = setTimeout(() => {
          setActiveIndex(cardIndex);
          sendTrackingEvent();
        }, OBSERVER_HANDLER_DELAY);
      } else {
        clearTimeout(timeoutIdsRef.current[cardIndex]);
      }
    },
    [sendTrackingEvent],
  );

  useEffect(() => {
    // Disconnect all current observers
    observerRefs.current.forEach(observer => observer?.disconnect());
    const observers = observerRefs.current;

    if (isCarouselInView) {
      for (const [cardIndex, cardElement] of itemRefs.current.entries()) {
        const totalCardsLength = itemRefs.current.length;

        if (scrollContainerRef.current && cardElement) {
          const newObserver = getObserver(
            scrollContainerRef,
            cardElement,
            cardIndex,
            totalCardsLength,
            handleActiveCardOnScroll,
          );

          observers[cardIndex] = newObserver;
          observers[cardIndex]?.observe(cardElement);
        }
      }
    }

    return () => {
      observers.forEach(observer => observer?.disconnect());
    };
  }, [isCarouselInView, handleActiveCardOnScroll]);

  useEffect(() => {
    // Used to keep track of the active index as state is not up to date within the observer handler.
    activeIndexRef.current = activeIndex;
  }, [activeIndex]);

  /**
   * Scrolls the new active card to the center position of the scroll container.
   * @param cardIndex index of the selected card.
   */
  const scrollToActiveItem = (cardIndex: number) => {
    const scrollContainer = scrollContainerRef.current;
    const activeItem = itemRefs.current[cardIndex];

    if (activeItem && scrollContainer) {
      const activeItemPosition = getScrollToLeftPosition(
        activeItem,
        scrollContainer.offsetWidth,
        cardIndex,
        totalNumberOfItems,
      );

      setTimeout(
        () => scrollContainer.scrollTo({ left: activeItemPosition }),
        CAROUSEL_CLICK_DELAY,
      );
    }
  };

  /**
   * Resets the active card to the first card when the carousel component changes (via the ToggledCarousels).
   */
  useEffect(() => {
    setActiveIndex(0);
    scrollToActiveItem(0);
  }, [carouselIndex]);

  const handleActiveItem = (cardIndex: number) => {
    setActiveIndex(cardIndex);
    scrollToActiveItem(cardIndex);
    sendTrackingEvent();
  };

  /**
   * Handles all the actions for when a user clicks or tabs and presses ENTER on an inactive card in the carousel.
   * This is added to the onClick and onKeyUp for each card item in the carousel.
   * @param cardIndex index of the card.
   */
  const handleCardClick = (cardIndex: number) => {
    if (cardIndex !== activeIndex) {
      handleActiveItem(cardIndex);
    }
  };

  // Values to pass into the Pagination component
  const paginationPropValues = usePaginationPropValues(
    activeIndex,
    totalNumberOfItems,
    paginationTextRaw,
    handleActiveItem,
  );

  return (
    <OuterContainer
      theme={theme}
      backgroundColor={backgroundColor}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      horizontalPadding={0}
      flexDirection="column"
      justifyContent="center"
      maxWidth={MAX_SCROLL_WIDTH}
      ref={inViewRef}
    >
      <HeaderContainer
        horizontalPadding={{ mobile: spacing[16], desktop: spacing[40] }}
        justifyContent="center"
        flexDirection="column"
      >
        <Flex
          verticalPadding={`0 ${spacing[24]}`}
          alignItems={{ mobile: 'flex-start', desktop: 'center' }}
          gap={spacing[16]}
          width="100%"
          maxWidth={MAX_TEXT_WIDTH}
        >
          <Flex
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems={{ mobile: 'flex-start', desktop: 'center' }}
            flexGrow={1}
            justifyContent={headline ? 'space-between' : 'flex-end'}
            gap={spacing[16]}
          >
            {headline && (
              <Headline size="small" textColor={headlineColor}>
                {headline}
              </Headline>
            )}
            {children && (
              <Flex display={{ mobile: 'flex', desktop: 'none' }}>{children}</Flex>
            )}
            <Pagination {...paginationPropValues} />
          </Flex>
          {togglePanel && (
            <button onClick={togglePanel}>
              <Icon name="close" primaryColor={textColor} />
            </button>
          )}
        </Flex>
        {children && (
          <Flex
            display={{ mobile: 'none', desktop: 'flex' }}
            verticalPadding={`0 ${spacing[24]}`}
          >
            {children}
          </Flex>
        )}
      </HeaderContainer>
      <ScrollContainer
        data-test-id="scroll-container"
        gap={{
          mobile: spacing[16],
          tablet: spacing[24],
        }}
        verticalPadding={{ desktop: `0 ${spacing[16]}` }}
        horizontalPadding={{ mobile: spacing[16], desktop: spacing[40] }}
        ref={scrollContainerRef}
      >
        <CardsContainer>
          {items.map((item, index) => (
            <Container
              as="li"
              display="flex"
              key={item.name}
              width={CARD_WIDTHS}
              tabIndex={0}
              ref={el => {
                itemRefs.current[index] = el;
              }}
              onClick={() => handleCardClick(index)}
              onKeyUp={keyboardEvent => {
                if (keyboardEvent.key === 'Enter') {
                  handleCardClick(index);
                }
              }}
            >
              <Card flexDirection="column" gap={spacing[16]} width={CARD_WIDTHS}>
                <MediaCarouselCard
                  {...item}
                  isActive={activeIndex === index}
                  isInView={isCarouselInView}
                />
              </Card>
            </Container>
          ))}
        </CardsContainer>
      </ScrollContainer>
    </OuterContainer>
  );
};

export default MediaCarousel;

const Card = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(Flex)`
  @media (min-width: ${MAX_TEXT_WIDTH}) {
    padding: 0 calc((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2);
  }
`;

const elementContentsHidden = 'rgba(0, 0, 0, 0)';
const elementContentsVisible = 'rgba(0, 0, 0, 1)';

const ScrollContainer = styled(Flex)`
  overflow: scroll;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  white-space: nowrap;

  // to hide the scrollbar
  box-sizing: content-box;

  // hide scrollbar on safari
  ::-webkit-scrollbar {
    display: none;
  }

  // fade edge styling on larger viewports
  @media (min-width: ${MAX_SCROLL_WIDTH}) {
    padding: 0 calc((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2);

    mask-image: linear-gradient(
      to left,
      ${elementContentsHidden} 0%,
      ${elementContentsVisible} calc((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2),
      ${elementContentsVisible}
        calc(${MAX_SCROLL_WIDTH} - ((${MAX_SCROLL_WIDTH} - ${MAX_TEXT_WIDTH}) / 2)),
      ${elementContentsHidden} 100%
    );
  }
`;

const CardsContainer = styled.ul`
  margin: 0;
  position: relative;
  display: grid;
  grid-gap: ${spacing[24]};
  grid-auto-flow: column;
  width: fit-content;
  list-style-type: none;
`;
