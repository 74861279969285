import {
  Body,
  Eyebrow,
  Flex,
  Icon,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import { getGenericTextNodes } from '@pelotoncycle/page-builder';
import type { TypeComponentGenericTextWithMedia } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { toCtaFields } from '@page-builder/utils/helpers';

type Props = {
  item: TypeComponentGenericTextWithMedia;
};

export const FullWidthMediaModuleModalContent: React.FC<Props> = ({
  item: {
    fields: { ctas, text },
  },
}) => {
  if (!ctas) return null;

  const { eyebrow: iconTextTitle, body: iconTextBody } = getGenericTextNodes(text);
  const [cta] = toCtaFields(ctas);

  if (!iconTextTitle && !iconTextBody) return null;

  return (
    <Flex flexDirection="column" gap={spacing[16]}>
      {cta.ctaIcon && <StyledIcon name={cta.ctaIcon} color={white} />}
      <Flex flexDirection="column" gap={spacing[8]}>
        {iconTextTitle && (
          <Eyebrow size="small" textColor={white}>
            {iconTextTitle}
          </Eyebrow>
        )}
        {iconTextBody && (
          <Body size="small" textColor={grey[40]}>
            {iconTextBody}
          </Body>
        )}
      </Flex>
    </Flex>
  );
};

const StyledIcon = styled(Icon)`
  align-self: start;

  path {
    fill: ${white};
  }

  height: ${spacing[32]};
`;
