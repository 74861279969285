import { Flex, grey, spacing } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { CustomPricing } from '@onewellness/pg-benefit-options/ui/CustomPriceDisplay';
import { UpperTextBlock, LowerTextBlock } from '@page-builder/components';
import type { TypeComponentProductBlock } from '@page-builder/lib/types';
import { DESKTOP_INLINE_PADDING, VERTICAL_PADDING } from '@page-builder/modules/tokens';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import ProductGrid from './ProductGrid';

type Props = TypeComponentProductBlock & {
  customPricing?: CustomPricing[];
};

const ProductCards: React.FC<React.PropsWithChildren<Props>> = ({
  sys: { id },
  fields: { cardAlignment, cards, text },
  customPricing,
}) => {
  if (!cards) {
    console.error(
      `Malformed Contentful Entry for ${id}. This entry requires a minimum of 1 product card`,
    );

    return <></>;
  }

  const { eyebrow, headline, body, label, support } = getGenericTextNodes(text);

  return (
    <OuterContainer
      id={id}
      backgroundColor={grey[30]}
      horizontalPadding={{
        mobile: 0,
        desktop: DESKTOP_INLINE_PADDING,
      }}
      verticalPadding={text ? VERTICAL_PADDING['max'] : spacing[24]}
      data-testid="product-cards-outer-container"
    >
      <Flex flexDirection="column" alignItems="center" style={{ width: '100%' }}>
        {(headline || eyebrow || body) && (
          <StyledTextBlockContainer>
            <UpperTextBlock
              textColor="black"
              eyebrowText={eyebrow}
              headlineText={headline}
              headlineSize="small"
              bodyText={body}
              margin={{
                mobile: `0 auto ${spacing[0]}`,
                tablet: `0 auto ${spacing[40]}`,
                desktop: `0 auto ${spacing[56]}`,
              }}
            />
          </StyledTextBlockContainer>
        )}
        <ProductGrid
          cardAlignment={cardAlignment ? cardAlignment : 'column'}
          cards={cards}
          totalCards={cards.length}
          customPricing={customPricing}
        />
        {(support || label) && (
          <div style={{ margin: spacing[32] }}>
            <LowerTextBlock
              textColor="black"
              supportText={support}
              labelText={!support && label}
            />
          </div>
        )}
      </Flex>
    </OuterContainer>
  );
};

const StyledTextBlockContainer = styled('div')`
  margin: ${spacing[48]} ${spacing[32]};

  ${media.tabletXLarge`
    margin: ${spacing[32]};
  `}
`;

export default ProductCards;
