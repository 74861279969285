import { spacing, BreakpointWidths } from '@pelotoncycle/design-system';
import type { TypeComponentMedia, TypeComponentCta } from '@pelotoncycle/page-builder';
import { HeroView as Hero, ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { nameToModuleId, toMediaProps } from '@page-builder/utils/helpers';

export type MiniHeroProps = {
  media?: TypeComponentMedia;
  ctas?: TypeComponentCta[];
  name: string;
};

const MiniHero: React.FC<React.PropsWithChildren<MiniHeroProps>> = props => {
  const { media, name, ctas } = props;
  const parsedCtas = ctas?.map(cta => ctaExtractData(cta));
  return (
    <>
      {media && (
        <StyledHero
          media={toMediaProps(media)}
          name={name}
          id={nameToModuleId(name)}
          ctas={parsedCtas}
          textAlign="left"
          variation="small"
          overlay={{ isVisible: ctas ? true : false, opacity: 0.75 }}
          theme="dark"
        />
      )}
    </>
  );
};

export default MiniHero;

const StyledHero = styled(Hero)`
  grid-column: 1 / span 2;
  height: unset;
  max-width: 100vw;
  aspect-ratio: 4 / 5;

  > div section {
    padding: 0 0 ${spacing[16]} ${spacing[16]};
    @media (min-width: ${BreakpointWidths.tablet}px) {
      padding: 0 0 ${spacing[24]} ${spacing[24]};
    }
    > div {
      width: auto;
    }
  }

  & [type*='video'] {
    @media (min-width: ${BreakpointWidths.desktop}px) {
      border-radius: ${spacing[4]};
    }
  }

  & [opacity*='.75'] {
    background: linear-gradient(
      139.38deg,
      rgb(0 0 0 / 75%) 11.29%,
      rgba(0, 0, 0, 0) 37.62%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    @media (min-width: ${BreakpointWidths.desktop}px) {
      border-radius: ${spacing[4]};
    }
  }

  @media (min-width: ${BreakpointWidths.desktop}px) {
    height: auto;
    max-width: unset;
    aspect-ratio: 5 / 3;
  }
`;
