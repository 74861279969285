import type { IconNames } from '@pelotoncycle/design-system';
import {
  Body,
  Eyebrow,
  Flex,
  Icon,
  spacing,
  grey,
  red,
  Label,
  white,
  slate,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled, { css } from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { hoverTransition, media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links/Link';
import type {
  TypeComponent_wwwLinkFields,
  TypeComponentExternalLinkFields,
  TypeComponentGenericTextFields,
} from '@page-builder/lib/types';

export type IconCtaProps = {
  name: string;
  parentName: string;
  theme: string;
  icon?: IconNames | undefined;
  text: { fields: TypeComponentGenericTextFields } | undefined;
  link?:
    | { fields: TypeComponentExternalLinkFields | TypeComponent_wwwLinkFields }
    | undefined;
};

const colorTheme: any = {
  White: {
    textColor: grey[90],
    iconColor: grey[60],
  },
  'Grey 90': {
    textColor: grey[40],
    iconColor: grey[60],
  },
};

const IconCta: React.FC<React.PropsWithChildren<IconCtaProps>> = ({
  theme,
  icon: iconName,
  text,
  link,
  name,
  parentName,
}) => {
  const { trackEvent } = useTracking();
  const eyebrowText = text?.fields?.eyebrow;
  const bodyText = text?.fields?.body;
  const linkUrl = link?.fields?.url;
  const whiteTheme = theme === 'White';
  const { iconColor, textColor } = colorTheme[theme];

  const trackOnClick = () => {
    trackEvent({
      event: 'Clicked Discipline Icon',
      properties: {
        href: linkUrl || '',
        parent: parentName,
        linkTo: linkUrl,
        linkName: eyebrowText,
        unitName: name,
        parentType: 'TextWithMultipleIcons',
      },
    });
  };

  const LabelComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) =>
    link ? (
      <StyledLabel size="medium" textColor={textColor}>
        {children}
      </StyledLabel>
    ) : (
      <Eyebrow size="small" textColor={textColor}>
        {children}
      </Eyebrow>
    );

  const Content = (
    <>
      {iconName &&
        (link ? (
          <div style={{ marginBottom: spacing[8] }}>
            <StyledIcon name={iconName} primaryColor={iconColor} />
          </div>
        ) : (
          <div
            style={{
              marginBottom: `${eyebrowText ? '20px' : spacing[12]}`,
            }}
          >
            <Icon name={iconName} height={44} primaryColor={iconColor} />
          </div>
        ))}
      {eyebrowText && <LabelComponent>{eyebrowText}</LabelComponent>}
      {bodyText && (
        <Body overrideSize={{ fontSize: '14px' }} textColor={textColor}>
          {bodyText}
        </Body>
      )}
    </>
  );

  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      margin={{
        mobile: `${link ? 0 : '0 0 6px 0'}`,
        desktop: `${link ? 0 : `0 0 ${spacing[12]} 0`}`,
      }}
    >
      {link ? (
        <StyledLink
          to={linkUrl!}
          href={linkUrl}
          onClick={trackOnClick}
          whiteTheme={whiteTheme}
        >
          {Content}
        </StyledLink>
      ) : (
        Content
      )}
    </Flex>
  );
};

export default IconCta;

const StyledIcon = styled(Icon)`
  height: 24px;

  ${media.tablet`
    height: 32px;
  `}
`;

const StyledLabel = styled(Label)`
  text-transform: capitalize;
  font-size: 12px;

  ${media.tablet`
    font-size: 13px;
  `}
`;

const hoverWhiteTheme = css`
  background-color: ${grey[30]};

  ${hoverTransition(
    {
      property: 'box-shadow',
      to: `0px 5px 20px rgba(0, 0, 0, 0.12)`,
    },
    {
      property: 'background-color',
      to: white,
    },
  )};

  &:hover svg path {
    fill: ${red[50]};
  }

  &:hover p {
    color: ${red[50]};
  }
`;

const hoverDarkTheme = css`
  background-color: ${rgba(slate[50], 0.1)};

  ${hoverTransition(
    {
      property: 'box-shadow',
      to: `0px 0px 40px 4px rgba(0, 0, 0, 0.3)`,
    },
    {
      property: 'border',
      to: `1.5px solid ${white}`,
    },
  )};

  &:hover svg path {
    fill: ${white};
  }

  &:hover p {
    color: ${white};
  }
`;

const StyledLink = styled(Link)<LinkProps & { whiteTheme: boolean }>`
  min-width: 92px;
  border-radius: 10px;
  padding: ${spacing[16]} 0;
  align-items: center;
  justify-content: center;
  display: flex;

  ${media.tabletXLarge`
     min-width: 150px;
     padding: ${spacing[24]} 0;
  `}

  ${(props: { whiteTheme: boolean }) =>
    props.whiteTheme ? hoverWhiteTheme : hoverDarkTheme};

  &::after {
    display: none;
  }
`;
