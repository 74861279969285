import { Flex, Icon, white, red } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
  isLoading: boolean;
};

const LoadingSpinner: React.FC<React.PropsWithChildren<Props>> = ({ isLoading }) => {
  return (
    <LoadingSpinnerContainer
      isLoading={isLoading}
      data-test-id="loading-spinner-container"
      position="absolute"
      justifyContent="center"
      alignItems="center"
      backgroundColor={white}
    >
      <Icon name="spinner" height={32} primaryColor={red[50]} />
    </LoadingSpinnerContainer>
  );
};

const LoadingSpinnerContainer = styled(Flex)<Props>`
  margin: 2px 0;
  top: 0;
  left: -1rem;
  z-index: 3;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  transition: opacity 300ms;
  height: 100%;
  width: calc(100% + 2rem);
`;

export default React.memo(LoadingSpinner);
