import type * as Contentful from 'contentful';
import type {
  TypeComponentFormElementFields,
  TypeQuizQuestionFields,
} from '@page-builder/lib/types';
import { useAnswersForQuestion } from './progress';

export const useQuestionProps = (
  pbQuestion: Contentful.Entry<TypeQuizQuestionFields>,
) => {
  const {
    fields: {
      questionName,
      question,
      theme,
      slug,
      columns,
      questionType,
      getsGroupsFrom,
      singleAnswer,
    },
  } = pbQuestion;
  const { eyebrow, headline, support } = question.fields?.text?.fields || {};
  const options = question.fields.formFields;
  return {
    questionName,
    question,
    theme,
    eyebrow,
    headline,
    support,
    options,
    slug,
    columns,
    questionType,
    getsGroupsFrom,
    singleAnswer,
  };
};

export const getGroups = (answers: string[]) => {
  const addedGroups = new Set();

  for (const answer of answers) {
    const group = answer.split(':')[1];
    if (!addedGroups.has(group)) {
      addedGroups.add(group);
    }
  }

  return addedGroups;
};

export const useSelectedQuestionGroups = (
  questionName: string,
  groupOptions: Contentful.Entry<TypeComponentFormElementFields>[],
) => {
  const { answers } = useAnswersForQuestion(questionName);
  const groups: { group: string; title: string }[] = [];
  const addedGroups = getGroups(answers);

  for (const answer of answers) {
    const group = answer.split(':')[1];
    if (!addedGroups.has(group)) {
      addedGroups.add(group);
    }
  }

  for (const addedGroup of addedGroups) {
    const foundGroupOption = groupOptions.find(
      groupOption => groupOption.fields.name === addedGroup,
    );
    groups.push({
      group: addedGroup as string,
      title: foundGroupOption?.fields.label!,
    });
  }

  return groups;
};
