import { BreakpointWidths } from '@pelotoncycle/design-system';
import React, { useMemo } from 'react';
import type { Options } from '@peloton/images-ui';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';

type OptionalBreakpointOptions = {
  [T in keyof typeof BreakpointWidths]?: Options;
};

export type BreakpointOptions = OptionalBreakpointOptions & {
  mobile: Options;
};

const possibleOptionKeysInOrder = [...Object.keys(BreakpointWidths)];

const sortBreakpointOptionsByMediaWidthAscending = (
  breakpointOptions: BreakpointOptions,
) => {
  return possibleOptionKeysInOrder.reduce(
    (accumulator, breakpointName) => {
      const optionsProvidedForBreakpoint = breakpointOptions[breakpointName];

      if (optionsProvidedForBreakpoint) {
        accumulator[breakpointName] = optionsProvidedForBreakpoint;
      }

      return accumulator;
    },
    { mobile: { ...breakpointOptions.mobile } },
  );
};

const buildComprehensiveDependencyArrayForUseMemo = (
  sortedBreakpointOptions: BreakpointOptions,
  src: string,
) => {
  return possibleOptionKeysInOrder.reduce(
    (dependencyArray, breakpointName) => {
      const optionsForBreakpoint = sortedBreakpointOptions[breakpointName] || {};

      return dependencyArray.concat([
        optionsForBreakpoint.width,
        optionsForBreakpoint.src,
      ]);
    },
    [src],
  );
};

const useGenerateSourceElements = (breakpointOptions: BreakpointOptions, src: string) => {
  const sortedBreakpointOptions: BreakpointOptions = sortBreakpointOptionsByMediaWidthAscending(
    breakpointOptions,
  );

  const memoizationDependencies = buildComprehensiveDependencyArrayForUseMemo(
    sortedBreakpointOptions,
    src,
  );

  const value = useMemo(() => {
    let nextLowestIdentifiedSrc = src;
    const sourceElements = Object.keys(sortedBreakpointOptions).map(breakpointName => {
      const breakpointOptions = sortedBreakpointOptions[breakpointName];

      if (breakpointOptions.src) {
        nextLowestIdentifiedSrc = breakpointOptions.src;
      }

      breakpointOptions.src = nextLowestIdentifiedSrc;
      breakpointOptions.devicePixelRatio = 2.0; // Avoid blurry images with Next

      const cloudinarySrc = toCloudinarySrc(breakpointOptions);

      const media = `(min-width: ${BreakpointWidths[breakpointName]}px)`;

      return breakpointName === 'mobile' ? null : (
        <source
          className={breakpointName}
          key={breakpointName}
          media={media}
          srcSet={cloudinarySrc}
        />
      );
    });

    // while our sources fall back to the lower srcs (hence the sort above), we render the sources
    // in media query width descending, as the browser renders the media match it finds first
    return sourceElements.reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memoizationDependencies);

  return value;
};

export default useGenerateSourceElements;
