import React, { useRef, useEffect } from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const NextContentSizedDiv: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  children,
  ...restProps
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const parentStyle = Object.assign({}, style, { overflow: 'hidden' });

  useEffect(() => {
    const onResize = () => {
      if (!contentRef.current) return;
      const contentHeight = window.getComputedStyle(contentRef.current).height;
      if (contentHeight !== parentRef.current!.style.height) {
        parentRef.current!.style.height = contentHeight;
      }
    };

    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(contentRef.current!);

    onResize();

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div ref={parentRef} style={parentStyle} {...restProps}>
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

export default NextContentSizedDiv;
