import { Container, brand, spacing } from '@pelotoncycle/design-system';
import type { TypeComponentCta } from '@pelotoncycle/page-builder';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media as mediaBreakpoints } from '@peloton/styles';
import { mobileScrollHeadbandStyles } from '@page-builder/modules/Headband/styles';
import type { ATCBandProps } from '@page-builder/modules/Headband/styles';

type Props = {
  cta: TypeComponentCta;
  showStickyCta: boolean;
  handleOnClick?: () => void;
};

const StickyCta: React.FC<Props> = ({ cta, showStickyCta, handleOnClick }) => {
  const mobileBandUrl = cta?.fields.link?.fields.url;

  return (
    <MobileATCBandWrapper
      bottom={showStickyCta ? '0' : '-100px'}
      aria-hidden={!showStickyCta}
      data-test-id="mobile-band-cta"
    >
      <LinkButton
        href={mobileBandUrl}
        size="small"
        width="adaptive"
        onClick={handleOnClick}
      >
        {cta.fields.text}
      </LinkButton>
    </MobileATCBandWrapper>
  );
};

export default StickyCta;

const MobileATCBandWrapper = styled(Container)<ATCBandProps>`
  ${mobileScrollHeadbandStyles}

  background-color: ${rgba(brand.darkest, 0.9)};
  backdrop-filter: blur(12px);
  border-top: 1px solid ${rgba(brand.light, 0.15)};
  padding: 20px ${spacing[16]};
  transition: bottom 0.3s ease-in-out;

  a {
    width: 100%;
  }

  ${mediaBreakpoints.desktop`
    display: none;
  `}
`;
