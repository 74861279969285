import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@page-builder/utils/Markdown';

const HeadbandTitle: React.FC<React.PropsWithChildren<{ title?: string }>> = ({
  title,
}) => {
  if (!title) return null;

  return (
    <StyledEyebrow>
      <Markdown content={title} />
    </StyledEyebrow>
  );
};

export default HeadbandTitle;

const StyledEyebrow = styled(Eyebrow)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
