import type {
  TypeComponentCtaFields,
  TypeComponentGenericTextWithMediaFields,
  TypeComponentGenericListFields,
} from '@pelotoncycle/page-builder';
import type React from 'react';
import VideoCarousel from '../VideoCarousel';

const PanelContentGenericComponentMap: Record<
  string,
  React.FC<TypeComponentGenericTextWithMediaFields | TypeComponentGenericListFields>
> = {
  ['Video Carousel']: VideoCarousel,
};

const allowedTreatmentsDisplay = Object.keys(PanelContentGenericComponentMap)
  .map(key => `"${key}"`)
  .join(', ');

const toPanelContentComponent = ({
  fields: { treatment },
}: NonNullable<TypeComponentCtaFields['modal']>) => {
  const ContentComponent = PanelContentGenericComponentMap[treatment];

  if (!ContentComponent) {
    throw new Error(
      `"${treatment}" treatment cannot be used as the panel content within the "Poster With Content Panel" module. Allowed treatments are: ${allowedTreatmentsDisplay}`,
    );
  }

  return ContentComponent;
};

export default toPanelContentComponent;
