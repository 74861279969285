import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import {
  MODAL_CLOSE_TRANSITION_TIME,
  SlideUpModalStyles,
  ModalLoader as SlideUpModalLoader,
} from '@ecomm/modal/SlideUpModalStyles';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import AsyncBlock from '../../AsyncBlock';
import NextModal from '../../NextModal/NextModal';

const getTextFromFirstChild = (children: React.ReactElement): string | null => {
  const isChildrenValid = !!(
    children &&
    typeof children === 'object' &&
    'props' in children
  );

  if (isChildrenValid && typeof children?.props?.text === 'string') {
    return children.props.text;
  }

  if (isChildrenValid && typeof children?.props?.children === 'string') {
    return children.props.children;
  }

  return null;
};

export enum WaysToTakeClassesEntries {
  ROWER = '2Ezrrvlq5O8WffD8ZA9hd1',
  TREAD = '6aGBSX8VWSZeo5Hwd9HFEK',
  BIKE = '24FFGKJQaFn1HcZpWzw6mX',
  GUIDE = '2PiACyjGAMldFKge1mB66l',
}

const WAYS_TO_TAKE_CLASSES_ENTRY_IDS: string[] = Object.values(WaysToTakeClassesEntries);

/*
 1. Revisit how we’ll be tracking events for all modals once
    the A/B experiment has been confirmed.
 */
const useEventDispatcher = () => {
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const dispatchEvent = ({ entryId, text }: { entryId: string; text: string | null }) => {
    if (WAYS_TO_TAKE_CLASSES_ENTRY_IDS.includes(entryId)) {
      /* [1] */
      trackEvent({
        event: 'Clicked Ways To Take Classes CTA',
        properties: {
          page: pathName,
          unitName: 'Component: CTA',
          linkName: text,
        },
      });
    }
  };

  return dispatchEvent;
};

export type AsyncModalTriggerProps = {
  entryId: string;
};

export const AsyncModalTrigger: React.FC<
  React.PropsWithChildren<AsyncModalTriggerProps>
> = ({ children, entryId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const firstChildText = getTextFromFirstChild(children as React.ReactElement);
  const eventDispatcher = useEventDispatcher();
  const triggerLabel = firstChildText || 'modal';

  const handleButtonClick = () => {
    setIsOpen(true);
    eventDispatcher({ entryId, text: firstChildText });
  };

  return (
    <>
      {/* Modal trigger */}
      <StyledButton className="async-modal-trigger" onClick={handleButtonClick}>
        {children}
      </StyledButton>

      {/* Modal */}
      <StyledNextModal
        id="prospects-slide-up-modal"
        isOpen={isOpen}
        contentLabel={triggerLabel}
        closeHandler={() => setIsOpen(false)}
        closeTimeoutMS={MODAL_CLOSE_TRANSITION_TIME}
      >
        <SlideUpModalStyles />
        <div className="slide-up-modal-body">
          {/* Modal content (async) */}
          <AsyncBlock id={entryId} fallback={<SlideUpModalLoader />} />
        </div>
      </StyledNextModal>
    </>
  );
};

const StyledNextModal = styled(NextModal)``;

const StyledButton = styled.button`
  font-weight: inherit;
`;
