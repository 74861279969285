export const CAROUSEL_CLICK_DELAY = 200;

export const debounceTimer = <Args extends any[]>(callback: (...args: Args) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Args) => {
    clearTimeout(timeoutId!);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, CAROUSEL_CLICK_DELAY);
  };
};
