import type * as Contentful from 'contentful';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';

export type LogInSectionData = {
  questionText?: string;
  linkLabel?: string;
} | null;

export const getLogInSectionData = (
  items: Contentful.Entry<TypeComponentGenericTextWithMediaFields>[],
): LogInSectionData => {
  const logInSectionItem = items.find(item => item.fields.name === 'logInSection');

  if (!logInSectionItem) return null;

  const label = logInSectionItem.fields?.text?.fields?.headline;
  const cta = {
    text: logInSectionItem.fields?.ctas?.[0]?.fields?.text,
  };

  return { questionText: label, linkLabel: cta.text };
};
