import { spacing, grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';

export const StyledSectionHeader = styled.section`
  display: none;
  padding: ${spacing[16]} ${spacing[32]} ${spacing[16]};
  background: ${grey[80]};
  ${media.desktopLarge`
    display: block;
    background: ${rgba(grey[80], 0.75)};
    backdrop-filter: blur(10px);
  `}
`;

export const StyledSectionBody = styled.section`
  padding: 0 ${spacing[16]} 0;
  background: ${grey[80]};
  ${media.tablet`
    padding: 0 ${spacing[32]} 0;
  `}
  ${media.desktop`
    padding: 0 ${spacing[80]} 0;
  `}
  ${media.desktopLarge`
    padding: ${spacing[40]} ${spacing[80]};
  `}
`;

export const StyledActions = styled.section`
  ${media.desktop`
    margin: 0 ${spacing[32]};
  `}
`;

export const StyledMobilePrimaryAction = styled.div`
  padding-bottom: ${spacing[24]};

  ${media.desktop`
    margin: 0 ${spacing[32]};
  `}

  ${media.desktopLarge`
    display: none;
  `}
`;

export const StyledLink = styled(Link)`
  color: ${white};

  span {
    font-size: 13px;
  }
`;
