import { Partners } from '@ecomm/financing/models/Partners';

const partnerTagMap = {
  financingPartnerAffirm: Partners.Affirm,
  financingPartnerZip: Partners.Zip,
  financingPartnerKlarna: Partners.Klarna,
  financingPartnerCitizens: Partners.Citizens,
  financingPartnerTruemed: Partners.Truemed,
};

const toFinancingPartnerFromTags = (tags: string[]) => {
  const partnerTag = tags.find(tag => partnerTagMap[tag]);
  return (partnerTag && partnerTagMap[partnerTag]) || Partners.None;
};

export default toFinancingPartnerFromTags;
