import { spacing, black, grey } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { defaultTransition, media } from '@peloton/styles';
import type { ExtraCFUCardType } from '../utils/insertExtraCFUCard';
import ClassCardImage from './ClassCardImage';
import ClassCardCFUDescription from './ExtraCFUCardDescription';

type Props = {
  item: ExtraCFUCardType;
  isAnimated?: boolean;
  animationDelay?: number;
};

const ExtraCFUCard: React.FC<React.PropsWithChildren<Props>> = ({
  item,
  isAnimated = false,
  animationDelay = 0,
}) => {
  const { imageUrl } = item;
  const { trackEvent } = useTracking();

  const trackCardClick = useCallback(
    (title: string, url: string) => {
      trackEvent({
        event: 'Clicked CFU Card',
        properties: {
          parent: 'Extra CFU Card',
          linkTo: url,
          linkName: title,
          unitName: 'CFU Card',
          parentType: 'Component: Generic List',
        },
      });
    },
    [trackEvent],
  );

  return (
    <CtaWrapper
      isAnimated={isAnimated}
      animationDelay={animationDelay}
      data-test-id={'ExtraCFUCard'}
    >
      <ClassCardImage image={toCloudinarySrc({ src: imageUrl, width: 392 })} />
      <ClassCardCFUDescription item={item} trackCardClick={trackCardClick} />
    </CtaWrapper>
  );
};

export default ExtraCFUCard;

const CtaWrapper = styled.div<{ isAnimated: boolean; animationDelay: number }>`
  background-color: ${grey[80]};
  border-radius: ${spacing[4]};
  display: flex;
  flex-direction: column;
  height: 100%;
  ${defaultTransition('box-shadow')}
  max-width: calc(100vw - ${spacing[32]});
  min-height: auto;

  ${media.tablet`
    max-width: calc(100vw - ${spacing[64]});
  `}

  ${({ isAnimated, animationDelay }) =>
    isAnimated
      ? `
      opacity: 0;
      animation: .4s ${animationDelay}s forwards class-cards-slide-in ease-out;
    `
      : ''}
  &:hover {
    box-shadow: 0 0 15px 6px ${rgba(black, 0.3)};
  }
  @keyframes class-cards-slide-in {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
