import { Layout, Media } from '@pelotoncycle/design-system';
import {
  OuterContainer,
  ctaExtractData,
  getGenericTextNodes,
} from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { Columns } from '@page-builder/components';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import {
  toMediaProps,
  DOUBLE_BREAKPOINT_WIDTH_OPTIONS,
} from '@page-builder/utils/helpers';
import TextAndCta from './TextAndCta';

const staticProps = {
  variant: 'fullWidth',
  theme: 'White',
  maxWidth: 'none',
  gap: 0,
  horizontalPadding: 0,
  verticalPadding: 0,
  reverse: false,
  textColor: themes['White'].textColor,
  headlineColor: themes['White'].headlineColor,
};

const AppFiftyFifty: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ ctas, text, media, treatment }) => {
  const ctaText = ctaExtractData(ctas?.[0])?.text;
  const { eyebrow, headline, body } = getGenericTextNodes(text);
  const mediaProps = media && toMediaProps(media, false, DOUBLE_BREAKPOINT_WIDTH_OPTIONS);
  return (
    <OuterContainer
      id={treatment}
      maxWidth={staticProps.maxWidth}
      verticalPadding={staticProps.verticalPadding}
      horizontalPadding={staticProps.horizontalPadding}
    >
      <Columns reverse={false} gap={staticProps.gap}>
        <MediaContainer>{mediaProps && <Media media={mediaProps} />}</MediaContainer>
        <Layout verticalAlignment="center" horizontalAlignment="center">
          <TextAndCta
            id={treatment}
            ctaText={ctaText}
            primaryColor={staticProps.headlineColor}
            secondaryColor={staticProps.textColor}
            eyebrowText={eyebrow}
            headlineText={headline}
            bodyText={body}
          />
        </Layout>
      </Columns>
    </OuterContainer>
  );
};

export default AppFiftyFifty;

const MediaContainer = styled.div`
  width: 100%;
  vertical-align: middle;

  img,
  video {
    width: 100%;
    height: auto;
  }
`;
