import React from 'react';
import styled from 'styled-components';
import { screenReaderOnly } from '@peloton/accessibility';
import { muscleGroupBucketColors } from './muscleGroupBucketColors';

const times = <T,>(fn: (i: number) => T, n: number): T[] =>
  Array(n)
    .fill(0)
    .map((_, i) => fn(i));

const ICON_SPACING_PIXELS = 5;
const ICON_WIDTH_PIXELS = 8;
const ICON_BORDER_PIXELS = 2;

const containerWidthForBucket = (bucket: number) =>
  ICON_WIDTH_PIXELS + ICON_SPACING_PIXELS * (bucket - 1);

const mapBucketToLabel: React.FC<
  React.PropsWithChildren<{
    bucket: number;
    primaryMuscleGroupLabel: string;
    secondaryMuscleGroupLabel: string;
    tertiaryMuscleGroupLabel: string;
  }>
> = ({
  bucket,
  primaryMuscleGroupLabel,
  secondaryMuscleGroupLabel,
  tertiaryMuscleGroupLabel,
}) => {
  switch (bucket) {
    case 1:
      return <>{primaryMuscleGroupLabel}</>;
    case 2:
      return <>{secondaryMuscleGroupLabel}</>;
    case 3:
      return <>{tertiaryMuscleGroupLabel}</>;
    default:
      return null;
  }
};

type Props = {
  namespace?: string;
  bucket: number;
  height: number;
  primaryMuscleGroupLabel: string;
  secondaryMuscleGroupLabel: string;
  tertiaryMuscleGroupLabel: string;
};

export const MuscleGroupBreakdownBucket: React.FC<React.PropsWithChildren<Props>> = ({
  namespace,
  bucket,
  height,
  primaryMuscleGroupLabel,
  secondaryMuscleGroupLabel,
  tertiaryMuscleGroupLabel,
}) => (
  <BucketIconsContainer
    data-test-id="icon-container"
    width={containerWidthForBucket(bucket)}
    height={height}
  >
    <ScreenReaderInstructions
      id={`${namespace || ''}bucket-icon-description`}
      data-test-id="screenreader"
    >
      {mapBucketToLabel({
        bucket,
        primaryMuscleGroupLabel,
        secondaryMuscleGroupLabel,
        tertiaryMuscleGroupLabel,
      })}
    </ScreenReaderInstructions>
    {times(
      index => (
        <BucketIconBorder
          key={index}
          offset={index * ICON_SPACING_PIXELS}
          zIndex={bucket - index}
          width={ICON_WIDTH_PIXELS + ICON_BORDER_PIXELS}
          height={height}
        >
          <BucketIcon
            data-test-id="bucket-icon"
            color={muscleGroupBucketColors[bucket]}
            width={ICON_WIDTH_PIXELS}
            height={height}
          />
        </BucketIconBorder>
      ),
      bucket,
    )}
  </BucketIconsContainer>
);

const BucketIconsContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: inherit;
`;

const BucketIconBorder = styled.div<{
  offset: number;
  zIndex: number;
  width: number;
  height: number;
}>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  right: ${({ offset }) => offset}px;
  border-radius: 1.5px;
  background-color: inherit;
`;

const BucketIcon = styled.div<{ color: string; width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 1.5px;
  background-color: ${({ color }) => color};
`;

const ScreenReaderInstructions = styled.span`
  ${screenReaderOnly}
`;
