import { Eyebrow, Headline } from '@pelotoncycle/design-system';
import React from 'react';
import { LinkButton } from '@peloton/next/components/LinkButton';
import type {
  ImageReferenceType as ImageReference,
  ExternalLinkReferenceType as ExternalLinkReference,
} from '../types/referenceTypes';

export type Props = {
  eyebrow?: string;
  headline?: string;
  media: ImageReference;
  cta: {
    text: string;
    link: ExternalLinkReference;
  };
  theme?: 'Light' | 'Dark';
};

const Hero = ({
  eyebrow,
  headline,
  media,
  cta: {
    link: { url },
    text,
  },
  theme = 'Dark',
}: Props) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      backgroundColor: theme === 'Light' ? '#FFF' : '#000',
      color: theme === 'Light' ? '#000' : '#FFF',
      padding: '50px',
    }}
  >
    {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
    {headline && <Headline>{headline}</Headline>}
    {media && (
      <picture>
        <img src={media.mobile} alt={media.alt} />
      </picture>
    )}
    <LinkButton to={url} href={url} size="small">
      {text}
    </LinkButton>
  </div>
);

export default Hero;
