import { white } from '@pelotoncycle/design-system';
import React from 'react';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { ModalContentWrapper } from '../ModalContentWrapper/ModalContentWrapper';
import { ModalContent } from '../ModalContentWrapper/styles';
import CompareAccordion from './CompareAccordion';
import CompareTable from './CompareTable';

type CompareModalProps = {
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
  modalContent: TypeComponentGenericTextWithMediaFields;
};

const CompareModal: React.FC<React.PropsWithChildren<CompareModalProps>> = ({
  isOpen,
  openHandler,
  closeHandler,
  modalContent,
}) => {
  const { name, text } = modalContent;
  const { eyebrow, body } = getTextFieldsWithRequiredKeys(['eyebrow', 'body'], text);

  return (
    <ModalContentWrapper
      isOpen={isOpen}
      openHandler={openHandler}
      closeHandler={closeHandler}
      contentLabel={name}
      maxWidth="780px"
    >
      <ModalContent backgroundColor={white} height="100%" width="100%" borderRadius="5px">
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: <CompareAccordion eyebrow={eyebrow} body={body} />,
            tabletXLarge: <CompareTable eyebrow={eyebrow} body={body} />,
          }}
        />
      </ModalContent>
    </ModalContentWrapper>
  );
};

export default CompareModal;
