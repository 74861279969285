// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type ReviewsQueryVariables = Types.Exact<{
  equipmentType: Types.ReviewEquipmentType;
  lang?: Types.Maybe<Types.Language>;
  excludeFamily?: Types.Maybe<Types.Scalars['Boolean']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Types.ReviewsOrderByInput>;
  minimalRating?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type ReviewsQuery = { __typename?: 'Query' } & {
  reviewsByEquipmentType: { __typename?: 'Reviews' } & Pick<
    Types.Reviews,
    'totalCount'
  > & {
      nodes: Array<
        { __typename?: 'Review' } & Pick<
          Types.Review,
          | 'id'
          | 'title'
          | 'locale'
          | 'overallRating'
          | 'body'
          | 'frequency'
          | 'photoUrl'
          | 'photos'
          | 'mainReason'
          | 'whoUses'
          | 'wouldRecommend'
          | 'response'
          | 'date'
        > & {
            author: { __typename?: 'ReviewAuthor' } & Pick<
              Types.ReviewAuthor,
              'name' | 'location' | 'verifiedPurchaser'
            >;
          }
      >;
    };
};

export const ReviewsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Reviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipmentType' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReviewEquipmentType' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Language' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeFamily' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ReviewsOrderByInput' },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minimalRating' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewsByEquipmentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipmentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'equipmentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lang' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeFamily' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludeFamily' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minimalRating' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minimalRating' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCount' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderBy' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'title' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'verifiedPurchaser' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'body' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photoUrl' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photos' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainReason' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'whoUses' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wouldRecommend' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'response' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'date' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      equipmentType: // value for 'equipmentType'
 *      lang: // value for 'lang'
 *      excludeFamily: // value for 'excludeFamily'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      minimalRating: // value for 'minimalRating'
 *   },
 * });
 */
export function useReviewsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    baseOptions,
  );
}
export function useReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReviewsQuery,
    ReviewsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    baseOptions,
  );
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = ApolloReactCommon.QueryResult<
  ReviewsQuery,
  ReviewsQueryVariables
>;
