import type { IconNames } from '@pelotoncycle/design-system';
import { Flex, spacing, Icon, Media, grey, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { Unpacked } from '@peloton/types';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import type { CompareProps } from './CompareModule';

export type ValuePropsType = Unpacked<CompareProps['products']>['valueProps'];
export type ValuePropType = Unpacked<ValuePropsType>;

const ValueProps: React.FC<{
  valuePropSet: ValuePropsType;
}> = ({ valuePropSet }) => {
  const { textColor, headlineColor } = useTheme();

  if (!valuePropSet) {
    return null;
  }

  return valuePropSet.map((valueProp, index) => (
    <ValueProp
      data-test-id="value-prop"
      key={index}
      flexDirection="column"
      gap={spacing[24]}
      justifyContent="start"
      alignContent="center"
      width="100%"
      maxWidth="400px"
      index={index}
    >
      {index !== 0 && <Divider />}
      <Flex justifyContent="center">
        {valueProp?.icon && (
          <Icon
            name={valueProp?.icon as IconNames}
            height={40}
            primaryColor={headlineColor}
          />
        )}
        {valueProp?.image && (
          <ValuePropImageContainer maxHeight="135px" borderRadius={spacing[12]}>
            <Media media={valueProp?.image} />
          </ValuePropImageContainer>
        )}
      </Flex>
      <Flex flexDirection="column" gap={spacing[8]} maxWidth="400px">
        <Label size="large" textColor={headlineColor} weight="medium">
          {valueProp?.headline}
        </Label>
        <Label size="large" textColor={textColor} weight="regular">
          {valueProp?.description}
        </Label>
      </Flex>
    </ValueProp>
  ));
};

export default ValueProps;

const Divider = styled.hr`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${grey[60]};
`;

const ValuePropImageContainer = styled(Flex)`
  aspect-ratio: 19 / 15;
  overflow: hidden;
`;

const ValueProp = styled(Flex)<{ index: number }>`
  // starts at index + 2 to account for the ProductCard's first row (img, rating, etc.)
  grid-row: ${({ index }) => `${index + 2} / ${index + 3}`};
  justify-self: center;
  width: 100%;
`;
