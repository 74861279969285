import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { buildFiltersQueryParams } from './buildFiltersQueryParams';
import type { ListingData } from './fetchDisciplineDataApi';

export const fetchClassListingData = async ({
  slug,
  locale,
  query,
}: {
  slug: string;
  locale: string;
  query?: NextParsedUrlQuery;
}): Promise<ListingData> => {
  const filtersQueryParams = buildFiltersQueryParams(query || {}).replace('?', '&');
  const response = await fetch(
    `/api/classes/listing?slug=${slug}&locale=${locale}${filtersQueryParams}`,
  );
  const data = await response.json();
  return data;
};
