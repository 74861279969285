import type {
  TypeComponentGenericList,
  TypeProduct,
  TypeProductFields,
} from '@pelotoncycle/page-builder';
import { useCallback, useState } from 'react';
import useIsFaasOutOfStock from '@ecomm/feature-toggle/FaasOutOfStock';
import { BundleType, toBundleType } from '@ecomm/shop/models';
import {
  DYNAMIC_DRAWERS_SLUGS,
  PRODUCT_INTEREST,
} from '@page-builder/modules/Overview/ShopDrawers/constants';
import { toEntryTags } from '@page-builder/utils/helpers';
import {
  setUrlQueryParams,
  transformTagToQueryValue,
} from '@page-builder/utils/helpers/urlParams';

type ValidBundleTypeString = Exclude<TypeProductFields['bundleType'], 'apparel' | 'app'>;

export const useDynamicDrawers = (pathname: string) => {
  const visibleDynamicDrawersCount = DYNAMIC_DRAWERS_SLUGS[pathname];
  const hasDynamicDrawers = !!visibleDynamicDrawersCount;
  const [dynamicDrawersExpanded, setDynamicDrawersExpanded] = useState(false);

  const expandDynamicDrawers = useCallback(
    () => setDynamicDrawersExpanded(prevState => !prevState),
    [],
  );

  return {
    hasDynamicDrawers,
    dynamicDrawersExpanded,
    expandDynamicDrawers,
    visibleDynamicDrawersCount,
  };
};

export const toReorderedDynamicDrawers = (
  drawers: TypeComponentGenericList[],
  defaultDrawerIndex: number,
  dynamicDrawersExpanded: boolean,
  visibleDynamicDrawersCount: number,
) => {
  const defaultDrawer = drawers[defaultDrawerIndex];
  const initDrawer = defaultDrawer ? [defaultDrawer] : [];
  const reorderedDrawers = initDrawer.concat(
    drawers.filter(drawer => drawer !== defaultDrawer),
  );

  return dynamicDrawersExpanded
    ? reorderedDrawers
    : reorderedDrawers.slice(0, visibleDynamicDrawersCount);
};

export const useIsRentDrawerDisabled = (product: TypeProduct) => {
  const isBikePlus =
    toBundleType(product.fields.bundleType as ValidBundleTypeString) ===
    BundleType.BikePlus;
  return Boolean(useIsFaasOutOfStock() && !isBikePlus);
};

export const setProductInterestParamForDrawer = (drawer: TypeComponentGenericList) => {
  const productInterestTag = drawer.metadata
    ? toEntryTags(drawer).find(tag => tag.includes(PRODUCT_INTEREST.tagPrefix))
    : undefined;
  const productInterestQueryValue = productInterestTag
    ? transformTagToQueryValue(PRODUCT_INTEREST.tagPrefix, productInterestTag)
    : undefined;

  if (productInterestQueryValue) {
    setUrlQueryParams(PRODUCT_INTEREST.queryParamName, productInterestQueryValue);
  }
};
