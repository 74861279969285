import { spacing, grey, Support, Body, white } from '@pelotoncycle/design-system';
import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { asyncComponent } from '@peloton/code-splitting';
import { scrollToTop } from '@peloton/navigation';
import { media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import type { CommerceToolsSparePart } from '@ecomm/product-recommendations/models/Product';
import { toProductFromCommerceToolsSparePart } from '@ecomm/product-recommendations/utils/toRecommendedProductsCommerceTools';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import { CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';
import useSparePartDataForPage from '@page-builder/utils/product-data/useSparePartDataForPage';
import Filter from './Filter/Filter';
import HeaderLabel from './HeaderLabel/HeaderLabel';
import useFilters from './hooks/useFilters';
import type { FiltersCheckedMap } from './hooks/useFilters';
import type { SparePartsListCommerceTools } from './models';

const SparePartsProductGrid = asyncComponent(() => import('./SparePartsProductGrid'));

export const toMappedProducts = (spareParts: SparePartsListCommerceTools) => {
  const products: CommerceToolsSparePart[] = [];
  if (spareParts) {
    for (const sparePart of spareParts) {
      const mappedProduct = toProductFromCommerceToolsSparePart(sparePart);
      if (mappedProduct && mappedProduct.productType === 'spare_part') {
        products.push({
          ...mappedProduct,
          ctProduct: sparePart,
        });
      }
    }
  }

  return products;
};

const filterProducts = (
  filtersChecked: FiltersCheckedMap,
  products: CommerceToolsSparePart[],
) => {
  const firstFilter = Object.values(filtersChecked)[0];
  const allFiltersEqual = Object.values(filtersChecked).every(val => val === firstFilter);

  // return all products if all filters are unchecked or all filters are checked
  if (allFiltersEqual) {
    return products;
  }

  const result = products.reduce((acc, product) => {
    if (product.ctProduct.categories.some(cat => filtersChecked[cat])) {
      acc.push(product);
    }
    return acc;
  }, [] as CommerceToolsSparePart[]);
  return result;
};

const SparePartsGrid: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = props => {
  const productBuildTimeData = useSparePartDataForPage();
  const mappedSpareParts = useMemo(() => toMappedProducts(productBuildTimeData), [
    productBuildTimeData,
  ]);
  const textFields = props?.text?.fields;
  const [filteredProducts, setFilteredProducts] = React.useState<
    CommerceToolsSparePart[]
  >([]);

  const { filterNames, filterGroups, filtersChecked, setFiltersChecked } = useFilters();
  const classFiltersNavLabel = useMicroCopy('sparePartsItemCountLabel');

  useEffect(() => {
    scrollToTop();
  }, [filtersChecked]);

  useEffect(() => {
    if (mappedSpareParts) {
      setFilteredProducts(mappedSpareParts);
    }
  }, [productBuildTimeData, setFilteredProducts, mappedSpareParts]);

  useEffect(() => {
    if (mappedSpareParts) {
      const fp = filterProducts(filtersChecked, mappedSpareParts);
      setFilteredProducts(fp);
    }
  }, [filtersChecked, setFilteredProducts, mappedSpareParts]);

  return (
    <StyledContainer data-test-id="spare-parts-container">
      <HeaderLabel {...textFields} />
      <StyledItemCount>
        <ItemCountInner>
          <Filter
            filterNames={filterNames}
            filterGroups={filterGroups}
            filtersChecked={filtersChecked}
            display="mobile"
            setFilters={setFiltersChecked}
            filteredCount={filteredProducts.length}
          />
          <StyledSupport size="small">{`${filteredProducts.length} ${classFiltersNavLabel}`}</StyledSupport>
          <StyledBody size="small">{`${filteredProducts.length} ${classFiltersNavLabel}`}</StyledBody>
        </ItemCountInner>
      </StyledItemCount>
      <ProductContainer>
        <FilterContainer>
          <FilterContainerInner>
            <Filter
              filterNames={filterNames}
              filterGroups={filterGroups}
              filtersChecked={filtersChecked}
              display="desktop"
              setFilters={setFiltersChecked}
              filteredCount={filteredProducts.length}
            />
          </FilterContainerInner>
        </FilterContainer>
        <SparePartsProductGrid products={filteredProducts} />
      </ProductContainer>
    </StyledContainer>
  );
};

export default SparePartsGrid;

const StyledContainer = styled.div`
  padding: ${spacing[88]} 0;
  text-align: center;

  ${media.desktopLarge`
    padding: ${spacing[80]} ${spacing[88]} ${spacing[88]} ${spacing[88]};
  `}
`;

const ProductContainer = styled.div`
  margin: 0 auto;
  margin-top: ${spacing[40]};
  max-width: ${CONTAINER_MAXWIDTH};
  padding: 0 ${spacing[16]};

  ${media.desktopLarge`
    display: flex;
    margin-top: ${spacing[24]};
    padding: 0;
  `}
`;

const StyledItemCount = styled.div`
  margin: 0 auto;
  max-width: ${CONTAINER_MAXWIDTH};
  position: sticky;
  z-index: 99;
  top: ${spacing[48]};
  background: ${white};
  width: 100%;
  padding: ${spacing[16]} ${spacing[16]} 0 ${spacing[16]};

  ${media.tabletXLarge`
    top: ${spacing[64]};
  `}

  ${media.desktopLarge`
    position: relative;
    z-index: 0;
    top: 0;
    padding: ${spacing[16]} 0 0 0;
  `}
`;

const ItemCountInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${spacing[16]};
  max-width: ${CONTAINER_MAXWIDTH};
  margin: 0 auto;
  border-bottom: 1px solid ${grey[40]};

  ${media.desktopLarge`
    justify-content: flex-end;
  `}
`;

const StyledSupport = styled(Support)`
  ${media.desktopLarge`
    display: none;
  `}
`;

const StyledBody = styled(Body)`
  display: none;

  ${media.desktopLarge`
    display: block;
  `}
`;

const FilterContainer = styled.div`
  width: 222px;
  margin-right: ${spacing[24]};
  flex: 0 0 222px;
`;

const FilterContainerInner = styled.div`
  top: ${spacing[96]};
  position: sticky;
`;
