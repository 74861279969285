import {
  Body,
  Flex,
  Icon,
  Label,
  spacing,
  Support,
  Stars,
} from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles';
import { toTime } from '@peloton/time';
import { useMicroCopy } from '@content/client/microCopy';
import { TrackingEvent } from '@ecomm/analytics/models';
import { toFormattedReviewAuthor } from '@ecomm/reviews/data/utils/formattedReviewValues';
import type { ReviewQuery } from '@ecomm/reviews/models';

type CardDimensionsType = {
  mobile: string;
  desktop: string;
};

export type AIReviewCardProps = {
  review: ReviewQuery;
  textColor: string;
  itemTextColor: string;
  starsColor: 'dark' | 'light' | 'primary' | undefined;
  cardBackgroundColor: string;
  cardWidths?: CardDimensionsType;
  cardHeights?: CardDimensionsType;
  isInCarousel?: boolean;
  setActiveRating: (rating: number | undefined) => void;
  setActiveReviewId?: (reviewId: string | undefined) => void;
  setIsReviewsModalOpen?: () => void;
  activeReviewId?: string;
};

const AIReviewCard = ({
  review,
  textColor,
  itemTextColor,
  starsColor,
  cardBackgroundColor,
  cardWidths,
  cardHeights,
  isInCarousel,
  setActiveRating,
  setActiveReviewId,
  setIsReviewsModalOpen,
  activeReviewId,
}: AIReviewCardProps) => {
  const { trackEvent } = useTracking();
  const {
    authorName,
    authorCity,
    authorState,
    authorLocation,
    isVerifiedPurchaser,
    title: reviewTitle,
    reviewText,
    rating,
    submittedAt,
  } = review;

  const postedFromNow = toTime(submittedAt).fromNow();

  const formattedAuthorDetails = toFormattedReviewAuthor({
    authorName,
    city: authorCity,
    state: authorState,
    authorLocation,
  });

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const [truncatedTextElement, setTruncatedTextElement] = useState<HTMLDivElement | null>(
    null,
  );

  const ctaLocation = isInCarousel ? 'Carousel' : 'Modal';
  const showMoreLabel = useMicroCopy('showMoreLabel');
  const showLessLabel = useMicroCopy('showLessLabel');
  const ctaLabel = isExpanded ? showLessLabel : showMoreLabel;
  const verifiedBadgeText = useMicroCopy('reviewsVerifiedBadgeText');
  const renderVerificationBadge = () => {
    return (
      <VerificationBadge data-test-id="verification-badge">
        <Icon name="verified" primaryColor={textColor} width={16} height={16} />
        <Label size="medium" textColor={textColor}>
          {verifiedBadgeText}
        </Label>
      </VerificationBadge>
    );
  };

  const handleCardClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        page: window.location.pathname,
        parent: 'Component: Reviews',
        parentType: 'AI Most Recent Reviews Carousel',
        unitName: `${ctaLabel} Reviews CTA in ${ctaLocation}`,
        linkName: ctaLabel,
        linkTo: `${ctaLabel} Reviews Modal`,
      },
    });

    if (isInCarousel) {
      setActiveRating(undefined);
      setActiveReviewId?.(review.id);
      setIsReviewsModalOpen?.();
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (truncatedTextElement) {
      const { scrollHeight, clientHeight } = truncatedTextElement;
      const shouldTruncate = scrollHeight > clientHeight;
      setIsTruncated(shouldTruncate);
      setIsExpanded(shouldTruncate && review.id === activeReviewId);
    }
  }, [review.id, activeReviewId, truncatedTextElement]);

  return (
    <SingleAIReviewCard
      data-test-id="ai-review-card"
      backgroundColor={cardBackgroundColor}
      borderRadius={spacing[12]}
      flexDirection="column"
      padding={spacing[24]}
      gap={spacing[24]}
      cardWidths={cardWidths}
      cardHeights={cardHeights}
    >
      <StarsAndPostedAtContainer>
        <Stars rating={rating} starSize={14} theme={starsColor} />
        <Support size="small" textColor={itemTextColor}>
          {postedFromNow}
        </Support>
      </StarsAndPostedAtContainer>
      <Flex flexDirection="column" gap={spacing[16]}>
        <Flex flexDirection="column" gap={spacing[8]}>
          <Label size="extraLarge" textColor={textColor} weight="medium">
            {reviewTitle}
          </Label>
          <TruncatedReviewText
            ref={setTruncatedTextElement}
            size="small"
            textColor={textColor}
            isExpanded={isExpanded}
          >
            {reviewText}
          </TruncatedReviewText>
        </Flex>
        <Flex>
          <Label size="large" textColor={textColor}>
            {formattedAuthorDetails}
          </Label>
          {isVerifiedPurchaser && renderVerificationBadge()}
        </Flex>

        {(isTruncated || isExpanded) && (
          <Label size="large" textColor={textColor}>
            <StyledButton onClick={handleCardClick}>{ctaLabel}</StyledButton>
          </Label>
        )}
      </Flex>
    </SingleAIReviewCard>
  );
};

export default AIReviewCard;

const SingleAIReviewCard = styled(Flex)<{
  cardWidths?: CardDimensionsType;
  cardHeights?: CardDimensionsType;
}>`
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  scroll-snap-align: center;
  height: ${({ cardHeights }) => (cardHeights ? `${cardHeights?.mobile}` : '100%')};
  width: ${({ cardWidths }) => (cardWidths ? `${cardWidths?.mobile}` : '100%')};

  ${mediaBreakpoints.tablet<{
    cardWidths: CardDimensionsType;
    cardHeights: CardDimensionsType;
  }>`
    flex: 1;
    flex-shrink: 1;
    height: ${({ cardHeights }) => (cardHeights ? `${cardHeights?.desktop}` : '100%')};
    max-width: ${({ cardWidths }) => (cardWidths ? `${cardWidths?.desktop}` : '100%')};
  `}
`;

const VerificationBadge = styled(Flex)`
  align-items: center;
  margin-left: ${spacing[16]};
  gap: ${spacing[4]};
`;

const TruncatedReviewText = styled(Body)<{ isExpanded: boolean }>`
  ${({ isExpanded }) =>
    !isExpanded &&
    `
    overflow: hidden;
    -webkit-line-clamp: 3; // truncates if text exceeds 3 lines
  `}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
`;

const StarsAndPostedAtContainer = styled(Flex)`
  justify-content: space-between;
`;

const StyledButton = styled.button`
  text-decoration: underline;
  text-underline-offset: 3px;
`;
