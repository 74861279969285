export type ModalLabelValues = {
  rideDateFormat: string;
  explicitLabel: string;
  subtitlesLabel: string;
  equipmentLabel: string;
  featuringMusicByLabel: string;
  playlistLabel: string;
  viewMoreLabel: string;
  viewLessLabel: string;
  classPlanLabel: string;
  moreInfoLabel: string;
  beginnerLabel: string;
  intermediateLabel: string;
  advancedLabel: string;
};

const modalLabelValues: ModalLabelValues = {
  rideDateFormat: 'rideDateFormat',
  explicitLabel: 'explicitLabel',
  subtitlesLabel: 'subtitlesLabel',
  equipmentLabel: 'equipmentLabel',
  featuringMusicByLabel: 'featuringMusicByLabel',
  playlistLabel: 'playlistLabel',
  viewMoreLabel: 'viewMoreLabel',
  viewLessLabel: 'viewLessLabel',
  classPlanLabel: 'classPlanLabel',
  moreInfoLabel: 'moreInfoLabel',
  beginnerLabel: 'beginnerLabel',
  intermediateLabel: 'intermediateLabel',
  advancedLabel: 'advancedLabel',
};

export type ModalItems = {
  fields: {
    name: string;
    text: { fields: { headline: string } };
  };
};

export const mapModalLabelsFromContentfulToProps = (arrayOfItems: ModalItems[]) => {
  const obj = arrayOfItems.reduce((acc, { fields: { name, text } }): ModalLabelValues => {
    if (modalLabelValues[name]) {
      acc[modalLabelValues[name]] = text.fields.headline;
    }
    return <ModalLabelValues>acc;
  }, {});
  const missingLabels = Object.keys(modalLabelValues).filter(label => !(label in obj));
  if (missingLabels.length > 0) {
    throw new Error(`Labels are missing: ${missingLabels.join(', ')}`);
  }
  return obj;
};
