import { PROSPECTS_FILTERS_API_DOMAIN } from '@peloton/app-config';
import { monitoredFetch } from '@peloton/next/api/monitoredFetch';
import type { FiltersResponse } from '../../../../apps/prospects/pages/api/classes/filters';
import filtersIndex from '../../../../apps/prospects/scripts/index.json';

type FetchFiltersDataParams = {
  discipline: string;
  duration: string | null;
  classType: string | null;
};

const API_PATH = process.env.PROSPECTS_FILTERS_API_PATH || '/api/classes/filters';
const IS_BUILD_TIME = process.env.IS_BUILD_TIME === 'true';

export enum ClassFilters {
  CLASS_TYPES = 'classTypes',
  DURATIONS = 'durationBuckets',
}

export enum ClassFiltersQueryParams {
  DISCIPLINE = 'discipline',
  CLASS_TYPE = 'classtype',
  DURATION = 'durationbucket',
}

export type ClassFiltersData = {
  filters: Record<ClassFilters, string[]>;
  validFilterCombinations: string[];
  classSlugs: string[];
  suggestedClassSlugs: string[];
  noIndex: boolean;
};

type FilterParams = { [index: string]: string };

/**
 * Fetches the filters data from the prospects filters API or the static index
 * file if this function is called within the build itself.
 * @param queryParams - The query parameters to be passed to the API.
 * @param trackerLabel - The label to be used for tracking the fetch request.
 */
export const fetchData = async (
  filterParams: FilterParams,
  queryParams: string,
  trackerLabel: string,
) => {
  if (IS_BUILD_TIME) {
    const searchString = Object.values(filterParams).filter(Boolean).join('/');

    const {
      filterOptions: filters,
      validFilterCombinations,
      slugs: classSlugs,
      suggestedSlugs: suggestedClassSlugs = [],
      noIndex,
    } = filtersIndex[searchString];

    return {
      filters,
      validFilterCombinations,
      classSlugs,
      suggestedClassSlugs,
      noIndex,
    };
  }

  const response = await monitoredFetch(trackerLabel, { timeout: 100000 })(
    `${PROSPECTS_FILTERS_API_DOMAIN}${API_PATH}?${queryParams}`,
    {
      headers: {
        authorization: process.env.PROSPECTS_FILTERS_API_KEY!,
      },
    },
  );

  if (!response.ok) {
    const { message } = await response.json();
    throw new Error(`Status ${response.status}: ${message}`);
  }

  const {
    filterOptions: filters,
    validFilterCombinations,
    slugs: classSlugs,
    suggestedSlugs: suggestedClassSlugs = [],
    noIndex,
  } = (await response.json()) as FiltersResponse;

  return {
    filters,
    validFilterCombinations,
    classSlugs,
    suggestedClassSlugs,
    noIndex,
  };
};

export const fetchFiltersData = async ({
  discipline,
  duration,
  classType,
}: FetchFiltersDataParams): Promise<ClassFiltersData> => {
  const filterParams = {
    [ClassFiltersQueryParams.DISCIPLINE]: discipline,
    [ClassFiltersQueryParams.DURATION]: duration || '',
    [ClassFiltersQueryParams.CLASS_TYPE]: classType || '',
  };
  const queryParams = new URLSearchParams(filterParams).toString();
  const stringParams = Object.values(filterParams).filter(Boolean).join(',');
  const trackerLabel = `[PROSPECTS] Filters API (${stringParams})`;

  if (!process.env.PROSPECTS_FILTERS_API_KEY) {
    throw new Error('Missing ‘PROSPECTS_FILTERS_API_KEY’ env variable');
  }

  const {
    filters,
    validFilterCombinations,
    classSlugs,
    suggestedClassSlugs,
    noIndex,
  } = await fetchData(filterParams, queryParams, trackerLabel);

  return {
    filters,
    validFilterCombinations,
    classSlugs,
    suggestedClassSlugs,
    noIndex,
  };
};
