import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useMicroCopyBulk } from '@content/client/microCopy';
import { ClassFilters } from '@ecomm/classes/api/fetchFiltersData';
import type { DisciplineListingData } from '@ecomm/classes/utils/fetchDisciplineDataApi';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useCacheKey } from '@page-builder/hooks/useCustomPageData';
import { toFilters } from '../helpers/toFilters';
import type { FiltersWithAvailability } from '../helpers/toFiltersWithAvailability';
import { toFiltersWithAvailability } from '../helpers/toFiltersWithAvailability';

export type { Filters } from '../helpers/toFilters';

export type SelectedFilters = Record<ClassFilters, string>;
export type SelectedFiltersSetter = React.Dispatch<React.SetStateAction<SelectedFilters>>;

export type FiltersConfig = {
  isFeatureEnabled: boolean;
  filters: FiltersWithAvailability;
  hasSelectedFilters: boolean;
  selectedFilters: SelectedFilters;
  initialSelectedState: SelectedFilters;
  clearedState: SelectedFilters;
  setSelectedFilters: SelectedFiltersSetter;
  clearFilters: () => void;
};

export type RawFilters = DisciplineListingData['filters'];

export const toPageSlug = (
  disciplineSlug: string,
  selectedFilters: SelectedFilters,
): string => {
  const duration = selectedFilters[ClassFilters.DURATIONS];
  const classType = selectedFilters[ClassFilters.CLASS_TYPES];
  const pageSlug =
    '/classes/' + [disciplineSlug, duration, classType].filter(Boolean).join('/');

  return pageSlug;
};

const DEFAULT_FILTERS = {} as RawFilters;
const SELECTED_FILTERS_FALLBACK = {} as SelectedFilters;

export const useRawFiltersData = () => {
  const cacheKey = useCacheKey();
  const { data } = useSWR<DisciplineListingData>(cacheKey, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const hasFiltersData = !!data?.filters;
  const rawFilters = data?.filters || DEFAULT_FILTERS;
  const validFilterCombinations = data?.validFilterCombinations || [];
  const initialSelectedFilters = data?.selectedFilters || SELECTED_FILTERS_FALLBACK;

  return {
    hasFiltersData,
    rawFilters,
    validFilterCombinations,
    initialSelectedFilters,
    noIndex: data?.noIndex || false,
  };
};

export const useClassFilters = (): FiltersConfig => {
  const microcopy = useMicroCopyBulk();
  const {
    hasFiltersData,
    rawFilters,
    validFilterCombinations,
    initialSelectedFilters,
  } = useRawFiltersData();

  const { filters, clearedState, initialSelectedState } = useMemo(() => {
    const filtersResult = toFilters(rawFilters, microcopy, initialSelectedFilters);

    const clearedStateResult = filtersResult.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.name]: '',
      }),
      {},
    ) as SelectedFilters;

    const initialSelectedStateResult = filtersResult.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.name]: filter.defaultValue,
      }),
      {},
    ) as SelectedFilters;

    return {
      filters: filtersResult,
      clearedState: clearedStateResult,
      initialSelectedState: initialSelectedStateResult,
    };
  }, [rawFilters]);

  const [selectedFilters, setSelectedFilters] = useState(initialSelectedState);
  const isFeatureEnabled = useIsToggleActive()('classFilters') && hasFiltersData;

  useEffect(() => {
    if (selectedFilters === initialSelectedState) return;
    setSelectedFilters(initialSelectedState);
  }, [initialSelectedState]);

  const filtersWithAvailability = useMemo(() => {
    return toFiltersWithAvailability(filters, selectedFilters, validFilterCombinations);
  }, [filters, selectedFilters]);

  const hasSelectedFilters = !!Object.values(selectedFilters).join('');

  const clearFilters = () => setSelectedFilters(clearedState);

  return {
    filters: filtersWithAvailability,
    hasSelectedFilters,
    selectedFilters,
    setSelectedFilters,
    clearFilters,
    isFeatureEnabled,
    initialSelectedState,
    clearedState,
  };
};
