import { grey, spacing, Badge } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { EquipmentWithCfu } from '../utils/addCfuToEquipment';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

type Props = {
  equipmentTags: EquipmentWithCfu[] | null;
  equipmentLabel: string;
};

export const ClassDetailsEquipment: React.FC<React.PropsWithChildren<Props>> = ({
  equipmentTags,
  equipmentLabel,
}) => {
  if (!equipmentTags?.length) return null;

  return (
    <ClassDetailsCardSection title={equipmentLabel} isTextOnly>
      <EquipmentBadgeList>
        {equipmentTags.map(({ name, slug }) => (
          <li key={slug}>
            <StyledBadge theme="dark" variant="horizontal">
              {name}
            </StyledBadge>
          </li>
        ))}
      </EquipmentBadgeList>
    </ClassDetailsCardSection>
  );
};

const EquipmentBadgeList = styled.ul`
  display: flex;
  gap: ${spacing[8]};
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledBadge = styled(Badge)`
  display: block;
  background-color: ${rgba(grey[70], 0.25)};
`;
