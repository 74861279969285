import type { TagLink } from 'contentful';
import type { TypeComponentCta } from '@page-builder/lib/types';
import { toCtaFields } from '@page-builder/utils/helpers';

const FINANCING_DRAWER_TAG = ('financingDrawer' as unknown) as TagLink;

export const getFinancingDrawerExpandCta = (ctas: TypeComponentCta[]) => {
  const financingDrawerCta = toCtaFields(ctas).find(cta =>
    cta.tags.includes(FINANCING_DRAWER_TAG),
  );

  return financingDrawerCta;
};

export const getKlarna = (): any => (window as any).Klarna;
