import { Eyebrow, Support, white, Flex, spacing } from '@pelotoncycle/design-system';
import type { WithBreakpoints } from '@pelotoncycle/design-system/breakpoints';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import gfm from 'remark-gfm';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { getCtaTrackingData, getTrackingName } from '@ecomm/cms-hero/trackingUtils';
import type { TypeComponentCta } from '@page-builder/lib/types';
import { getCtaFields } from '@page-builder/utils/helpers/cms';
import Markdown from '@page-builder/utils/Markdown';
import LinkRenderer from '../LinkRenderer';
import {
  ContentWrapperWithGradient,
  SupportTextWrapper,
  StyledDisplay,
  Subhead,
} from './styles';

type Props = {
  className?: string;
  index: number;
  eyebrow?: string;
  headline: string;
  subhead: string;
  support?: string;
  ctas?: TypeComponentCta[];
  isActive: boolean;
};

const HeroContent: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  ctas,
  eyebrow,
  headline,
  index: slideIndex = undefined,
  subhead,
  support,
  isActive = false,
}) => {
  const [trackingName, setTrackingName] = useState<string>('');
  const [path, setPath] = useState<string>('');

  const { trackEvent } = useTracking();

  useEffect(() => {
    setPath(window.location.pathname);
    setTrackingName(getTrackingName(window.location.pathname, slideIndex));
  }, [slideIndex]);

  const { primaryCta, secondaryCta } = getCtaFields('link', ctas);

  const buttonSize: WithBreakpoints<'small' | 'large' | 'medium'> = {
    mobile: 'small',
    tablet: 'large',
    desktop: 'large',
  };

  // match for any bold characters in subhead markup (this indicates a price)
  const increaseMaxWidth = /\*\*.+\*\*/.test(subhead);

  return (
    <ContentWrapperWithGradient className={className}>
      <Flex
        flexDirection="column"
        gap={{ mobile: spacing[16], desktop: spacing[24] }}
        verticalMargin={{ mobile: `0 ${spacing[24]}`, desktop: `0 ${spacing[32]}}` }}
      >
        {eyebrow && (
          <Eyebrow
            data-test-id={`${trackingName}-eyebrow`}
            textColor={white}
            size="large"
            as="span"
            hasShadow
          >
            {eyebrow}
          </Eyebrow>
        )}
        {headline && isActive && (
          <Markdown
            content={headline}
            markdown={{
              renderers: {
                paragraph: toRendererWithTracking(HeadlineRenderer, trackingName),
              },
              plugins: [gfm],
            }}
          />
        )}
        {subhead && (
          <Markdown
            content={subhead}
            markdown={{
              renderers: {
                paragraph: toRendererWithTracking(SubheadRenderer, trackingName, {
                  increaseMaxWidth,
                }),
              },
            }}
          />
        )}
      </Flex>
      {ctas && (
        <Flex
          gap={{ mobile: spacing[16], tablet: spacing[24] }}
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          verticalMargin={{ mobile: `0 ${spacing[16]}`, desktop: `0 ${spacing[24]}}` }}
        >
          {primaryCta && (
            <LinkButton
              data-test-id={`${trackingName}-cta-1`}
              href={primaryCta.link?.fields.url}
              variant="solid"
              size={buttonSize}
              color="accent"
              text={primaryCta.text}
              onClick={() => {
                const url = primaryCta.link?.fields.url;
                if (url) {
                  const anchorIdx = url?.lastIndexOf('#');
                  if (anchorIdx !== -1) {
                    const el = document.querySelector(url.substring(anchorIdx));

                    if (el) {
                      el.scrollIntoView({ behavior: 'smooth' });
                    }
                  }

                  trackEvent(getCtaTrackingData(path, url || '', 0, slideIndex));
                }
              }}
            />
          )}
          {secondaryCta && (
            <LinkButton
              data-test-id={`${trackingName}-cta-2`}
              href={secondaryCta.link?.fields.url}
              variant="outline"
              size={buttonSize}
              color="light"
              text={secondaryCta.text}
              onClick={() => {
                const url = secondaryCta.link?.fields.url;
                if (url) {
                  const anchorIdx = url?.lastIndexOf('#');
                  if (anchorIdx !== -1) {
                    const el = document.querySelector(url.substring(anchorIdx));

                    if (el) {
                      el.scrollIntoView({ behavior: 'smooth' });
                    }
                  }

                  trackEvent(getCtaTrackingData(path, url || '', 1, slideIndex));
                }
              }}
            />
          )}
        </Flex>
      )}
      {support && (
        <SupportTextWrapper>
          <Support
            data-test-id={`${trackingName}-support`}
            textColor={white}
            size="large"
            as="h2"
          >
            <Markdown
              content={support}
              markdown={{
                renderers: {
                  link: LinkRenderer,
                },
              }}
            />
          </Support>
        </SupportTextWrapper>
      )}
    </ContentWrapperWithGradient>
  );
};

export default HeroContent;

const toRendererWithTracking = (
  Renderer: React.FC<React.PropsWithChildren<unknown>>,
  trackingName: string,
  additionalProps?: any,
) => {
  Renderer.defaultProps = { trackingName, ...additionalProps };
  return Renderer;
};

type RendererProps = { trackingName: string; size: 'large' | 'medium' };

const HeadlineRenderer: React.FC<React.PropsWithChildren<RendererProps>> = ({
  children,
  trackingName,
}) => (
  <StyledDisplay
    data-test-id={`${trackingName}-header`}
    textColor={white}
    size="large"
    hasShadow
  >
    {children}
  </StyledDisplay>
);

const SubheadRenderer: React.FC<
  React.PropsWithChildren<RendererProps & { increaseMaxWidth: boolean }>
> = ({ children, increaseMaxWidth, trackingName }) => (
  <Subhead increaseMaxWidth={increaseMaxWidth} data-test-id={`${trackingName}-subhead`}>
    {children}
  </Subhead>
);
