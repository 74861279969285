import React, { createContext, useContext, useMemo, useState } from 'react';
import type { CompareProps } from './CompareModule';
import type { Rating } from './types';
import { isValidRating } from './utils';

export const RatingsContext = createContext({
  shouldShowRatings: false,
  updateShouldShowRatings: (product: string, rating: Rating) => {},
});

export const RatingsProvider: React.FC<
  React.PropsWithChildren<{ products: CompareProps['products'] }>
> = ({ products, children }) => {
  const defaultMap = products.reduce((accumulator, product) => {
    accumulator.set(product.product.bundleType, {
      averageOverallRating: 0,
      totalCount: 0,
    });
    return accumulator;
  }, new Map<string, Rating>());
  const [shouldShowRatings, setShouldShowRatings] = useState(false);
  const [ratingsMap, setRatingsMap] = useState(defaultMap);

  const value = useMemo(() => {
    const updateShouldShowRatings = (product: string, rating: Rating) => {
      setRatingsMap(ratingsMap.set(product, rating));
      const invalidatedRatings = Array.from(ratingsMap.values());

      if (
        invalidatedRatings.every(invalidatedRating => isValidRating(invalidatedRating))
      ) {
        setShouldShowRatings(true);
      } else {
        setShouldShowRatings(false);
      }
    };

    return {
      shouldShowRatings,
      updateShouldShowRatings,
    };
  }, [shouldShowRatings, ratingsMap]);

  return <RatingsContext.Provider value={value}>{children}</RatingsContext.Provider>;
};

export const useRatings = () => useContext(RatingsContext);
