import { Label, white, spacing, grey, black, Flex } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition } from '@peloton/styles';
import { designMaxWidth } from '@page-builder/modules/Quiz/utils/layout';
import { RadioButton } from './RadioButton';

type Props = {
  group: string;
  answers: string[];
  options: { title: string; value: string }[];
  saveGroupAnswer: (group: string, answer: string) => void;
};

export const RadioGroupContainer: React.FC<React.PropsWithChildren<Props>> = ({
  group,
  answers,
  options,
  saveGroupAnswer,
  children,
}) => {
  const id = `${group}_label`;

  return (
    <RadioGroup
      flexDirection="column"
      alignItems="center"
      role="group"
      key={group}
      aria-labelledby={id}
    >
      <Label is="span" size="extraLarge" textColor={white} id={id}>
        {children}
      </Label>
      <RadioButtonsWrapper>
        {options.map(option => {
          const checked = answers.includes(option.value);
          return (
            <RadioButton
              key={option.value}
              name={group}
              value={option.value}
              title={option.title}
              checked={checked}
              onChange={() => saveGroupAnswer(group, option.value)}
            />
          );
        })}
      </RadioButtonsWrapper>
    </RadioGroup>
  );
};

const RadioGroup = styled(Flex)`
  width: 100%;
  margin-top: ${spacing[40]};

  ${designMaxWidth(4)}
`;

const RadioButtonsWrapper = styled.div`
  background: ${rgba(black, 0.25)};
  border: 1px solid ${rgba(grey[80], 0.25)};
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: ${spacing[16]};

  ${defaultTransition('box-shadow')}
`;
