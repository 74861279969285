import type { Class } from '@peloton/models/PelotonClass';
import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import { AppDiscipline } from '../../models/constants';

export const DISCIPLINE_OVERRIDES: Record<AppDiscipline, ProspectsDiscipline> = {
  [AppDiscipline.CIRCUIT]: ProspectsDiscipline.RUNNING,
  [AppDiscipline.BIKE_BOOTCAMP]: ProspectsDiscipline.CYCLING,
  [AppDiscipline.CAESAR]: ProspectsDiscipline.ROWING,
  [AppDiscipline.CAESAR_BOOTCAMP]: ProspectsDiscipline.ROWING,
  [AppDiscipline.ROW_BOOTCAMP]: ProspectsDiscipline.ROWING,
  [AppDiscipline.TREAD_BOOTCAMP]: ProspectsDiscipline.RUNNING,
};

const OUTDOOR_BROWSE_CATEGORY = 'outdoor';

export const mapDiscipline = (
  discipline: string,
  browseCategorySlugs?: string[],
): Class['fitnessDiscipline'] => {
  const isOutdoor = !!browseCategorySlugs?.includes(OUTDOOR_BROWSE_CATEGORY);

  if (isOutdoor) {
    return ProspectsDiscipline.OUTDOOR;
  }

  if (discipline in DISCIPLINE_OVERRIDES) {
    return DISCIPLINE_OVERRIDES[discipline];
  }

  if (!(Object.values(ProspectsDiscipline) as string[]).includes(discipline)) {
    console.warn(`Unknown ‘${discipline}’ discipline provided.`);
  }

  return discipline as ProspectsDiscipline;
};
