import {
  Flex,
  grey,
  spacing,
  Container,
  EyebrowHeadlineBody,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import {
  getTextFieldsWithRequiredKeys,
  nameToModuleId,
} from '@page-builder/utils/helpers';

type TextCardsProps = {
  textItems: TypeComponentGenericTextWithMedia[];
};

const rowHorizontalSpace = `0 ${spacing[32]}`;
const rowVerticalSpace = `${spacing[32]} 0`;
const columnHorizontalSpace = `0 ${spacing[24]}`;
const columnVerticalSpace = `${spacing[24]} 0`;
const rowItemMaxWidth = 'calc(50% - .5px)';

const TextCards: React.FC<React.PropsWithChildren<TextCardsProps>> = ({ textItems }) => {
  return (
    <Container
      style={{ gridColumn: '1 / span 2', gridRow: '2', overflow: 'hidden' }}
      backgroundColor={grey[30]}
      borderRadius={spacing[4]}
    >
      <FlexContainer
        backgroundColor={grey[40]}
        flexDirection={{ mobile: 'column', tablet: 'row', desktop: 'column' }}
        margin={{
          mobile: columnHorizontalSpace,
          tablet: rowVerticalSpace,
          desktop: columnHorizontalSpace,
        }}
        gap="1px"
        justifyContent="center"
      >
        {textItems.map((textItem, textItemIndex) => {
          const {
            fields: { text },
          } = textItem;
          const { eyebrow, headline, body, name } = getTextFieldsWithRequiredKeys(
            ['eyebrow', 'name'],
            text,
          );
          return (
            <TextCard
              key={`${nameToModuleId(name)}-${textItemIndex}`}
              textAlign="center"
              maxWidth={{ tablet: rowItemMaxWidth, desktop: '100%' }}
              backgroundColor={grey[30]}
              padding={{
                mobile: columnVerticalSpace,
                tablet: rowHorizontalSpace,
                desktop: columnVerticalSpace,
              }}
            >
              <EyebrowHeadlineBody
                size="small"
                eyebrowText={eyebrow}
                headlineText={headline}
                bodyText={body}
              />
            </TextCard>
          );
        })}
      </FlexContainer>
    </Container>
  );
};

export default TextCards;

const FlexContainer = styled(Flex)`
  ${media.desktopXLarge`
    flex-direction: row;
    margin: ${rowVerticalSpace};
  `}
`;

const TextCard = styled(Container)`
  ${media.desktopXLarge`
    max-width: ${rowItemMaxWidth};
    padding: ${rowHorizontalSpace};
  `}
  > p {
    margin-bottom: 0;
  }
  > div[role='heading'] > span {
    margin-bottom: ${spacing[8]};
  }
`;
