import React from 'react';
import { Link } from '@peloton/next/components/Link';
import ClassCard from '@page-builder/modules/ClassesGrid/Card/ClassCard';
import ExtraCFUCard from '@page-builder/modules/ClassesGrid/Card/ExtraCFUCard';
import FreeClassCardResponsive from '@page-builder/modules/ClassesGrid/Card/FreeClassCardResponsive';

export const renderExtraCard = (item: any, newPortionIndex: number | null) => {
  if (item.extraCardType === 'CFU') {
    return (
      <ExtraCFUCard
        item={item}
        isAnimated={false}
        animationDelay={newPortionIndex !== null ? newPortionIndex * 0.1 : 0}
      />
    );
  } else if (item.extraCardType === 'FreeClass') {
    return <FreeClassCardResponsive freeClass={item} />;
  } else {
    return null;
  }
};

export const renderClassCard = (
  item: any,
  itemHref: string,
  trackCardClick: (title: string, url: string) => void,
  difficultyLabels: any,
  isNew: boolean,
  newPortionIndex: number | null,
) => {
  return (
    <Link
      href={itemHref}
      onClick={() => trackCardClick(item.title, item.slug)}
      hasUnderline={false}
    >
      <span data-test-id="class-card">
        <ClassCard
          difficultyLabels={difficultyLabels}
          item={item}
          isAnimated={isNew}
          animationDelay={newPortionIndex !== null ? newPortionIndex * 0.1 : 0}
        />
      </span>
    </Link>
  );
};
