import {
  Tooltip,
  Eyebrow,
  Body,
  white,
  grey,
  Flex,
  spacing,
  Container,
} from '@pelotoncycle/design-system';
import React from 'react';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';

type Props = {
  tooltip: TypeComponentGenericTextWithMedia;
};

const FinancingTooltip: React.FC<React.PropsWithChildren<Props>> = ({ tooltip }) => {
  const { body, eyebrow } = getTextFieldsWithRequiredKeys(
    ['body', 'eyebrow'],
    tooltip.fields.text,
  );

  return (
    <Container display={{ mobile: 'none', desktop: 'contents' }}>
      <Tooltip backgroundColor={white} icon={{ primaryColor: grey[70], height: 16 }}>
        <Flex flexDirection="column" gap={spacing[16]}>
          <Eyebrow size="small">{eyebrow}</Eyebrow>
          <Body size="small">{body}</Body>
        </Flex>
      </Tooltip>
    </Container>
  );
};

export default FinancingTooltip;
