import type { Locale } from '@peloton/internationalize';
import type { AccessoriesListCommerceTools } from '@ecomm/pg-shop-accessories/models';
import type { ProductProjectionType } from '../models/main';
import type { ProductCatalogData } from '../types.generated';
import { mapProductToAccessory } from './mapProductToAccessory';

export const mapProductsToAccessoriesList = (
  result: ProductProjectionType[],
  locale: Locale,
  context: Record<string, boolean> = {},
): AccessoriesListCommerceTools =>
  result.map(accessory => {
    const productsForMap = {
      key: accessory.key,
      id: accessory.id,
      productType: accessory.productType,
      masterData: ({
        current: accessory,
      } as unknown) as ProductCatalogData,
    };

    const { images, ...rest } = mapProductToAccessory(productsForMap, locale, context);
    const [firstImage] = images;
    const categories = accessory.categories.map(category => String(category.key));
    return {
      ...rest,
      image: firstImage,
      images,
      categories,
    };
  });
