import { Label } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  rotatingPrompts: string[];
  textColor: string;
  currentPromptIndex: number;
  setCurrentPromptIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const RotatingPrompts: React.FC<React.PropsWithChildren<Props>> = ({
  rotatingPrompts,
  textColor,
  currentPromptIndex,
  setCurrentPromptIndex,
}) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPromptIndex(
        (prevIndex: number) => (prevIndex + 1) % rotatingPrompts.length,
      );
    }, 3500); // Matches the animation cycle time

    return () => clearInterval(intervalId);
  }, [rotatingPrompts.length]);

  return (
    <StyledLabel
      size="responsiveLgXl"
      textColor={textColor}
      weight="medium"
      className="rotatingPrompts"
    >
      {rotatingPrompts[currentPromptIndex]}
    </StyledLabel>
  );
};
const fadeAndSlide = keyframes`
    0%,
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
    20% {
      opacity: 0;
      transform: translateY(-100%);
    }
    21% {
      opacity: 0;
      transform: translateY(
        100%
      ); /* Positions the next text off-screen, ready to move in */
    }
    50% {
      opacity: 1;
      transform: translateY(0); /* Text settles into its final position */
    }
    75% {
      opacity: 1;
      transform: translateY(0); /* Maintains visibility before fading out */
    }
`;

const StyledLabel = styled(Label)`
  margin-left: 4px;
  opacity: 0;
  animation: ${fadeAndSlide} 3500ms ease-in-out infinite;
  white-space: nowrap;
`;
