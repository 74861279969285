import type { ReactEventHandler } from 'react';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericList,
  TypeComponentGenericListFields,
  TypeComponentGenericTextWithMedia,
} from '@page-builder/lib/types';
import { toMediaProps } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';

export type TypeConfiguredMediaProps = ReturnType<typeof configureMediaProps>;

export const configureMediaProps = (
  mediaItem: TypeComponentGenericTextWithMedia,
  onPause?: ReactEventHandler<HTMLVideoElement>,
  onEnded?: ReactEventHandler<HTMLVideoElement>,
) =>
  ({
    ...toMediaProps(mediaItem.fields.media!),
    autoPlay: true,
    progressTracked: true,
    onPause,
    onEnded,
  } as const);

export const generateRowsOfTwo = (items: TypeComponentGenericTextWithMedia[]) => {
  const rows = [];
  for (let i = 0; i < items.length; i += 2) {
    const row = [];
    row.push(items[i]);
    if (Boolean(items[i + 1])) row.push(items[i + 1]);
    rows.push(row);
  }
  return rows;
};

export type TypeModalSection = {
  sectionName: string;
  sectionContent: TypeComponentGenericList;
};

export const toKebabCase = (str: string) => str.replace(/\s+/g, '-').toLowerCase();

export const getModalSections = (
  items: TypeComponentGenericListFields['items'],
): TypeModalSection[] => {
  const modalSections: TypeModalSection[] = [];

  items.forEach(item => {
    const itemCtas = item?.fields.ctas;
    if (!itemCtas?.length) return;
    const itemTitle = itemCtas[0].fields.text;
    const itemModal = itemCtas[0].fields.modal;
    isGenericList(itemModal) &&
      modalSections.push({
        sectionName: toKebabCase(itemTitle),
        sectionContent: itemModal as TypeComponentGenericList,
      });
  });
  return modalSections;
};

export const isGenericList = (modal: TypeComponentCtaFields['modal']) =>
  Boolean(modal && isEntryType<TypeComponentGenericList>(modal, 'text'));
