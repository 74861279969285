import { useHasVariation } from '@peloton/split-testing/hooks';

const CLASS_DETAIL_PAGE_VIDEO_EXPERIMENT = 'Class Detail Page Video Test';
const CLASS_DETAIL_PAGE_VIDEO_VARIATION = 'Variation #1';

const useIsClassDetailPageVideoExperimentActive = () => {
  return useHasVariation(
    CLASS_DETAIL_PAGE_VIDEO_EXPERIMENT,
    CLASS_DETAIL_PAGE_VIDEO_VARIATION,
  );
};

export default useIsClassDetailPageVideoExperimentActive;
