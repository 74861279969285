import { Flex, spacing, Label } from '@pelotoncycle/design-system';
import React from 'react';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';

type SectionTitleProps = {
  title: TypeComponentGenericTextWithMedia;
};

const SectionTitle: React.FC<React.PropsWithChildren<SectionTitleProps>> = ({
  title,
}) => {
  const {
    fields: { text },
  } = title;
  const { eyebrow } = getTextFieldsWithRequiredKeys(['eyebrow'], text);

  return (
    <Flex justifyContent="space-between" verticalMargin={`0 ${spacing[16]}`}>
      <Label size="extraLarge" weight="medium">
        {eyebrow}
      </Label>
    </Flex>
  );
};

export default SectionTitle;
