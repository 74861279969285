import type { ApolloQueryResult } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useLocale } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { SPARE_PARTS } from '@ecomm/commercetools/constants';
import type { GetCategoryByKeyQuery } from '@ecomm/commercetools/queries/GetCategoryByKey.generated';
import { queryProductCategory } from '@ecomm/commercetools/utils/fetchSpareParts';
import { sortByOrderHint } from '@ecomm/commercetools/utils/sortByOrderHint';

type ResultType = {
  id: string;
  key: string;
  name: string;
  orderHint: string;
  __typename: string;
};

export type CategoryType = {
  name: string;
  key: string;
};

export type CategoriesType = CategoryType & {
  subCategories: CategoryType[];
};

export type FiltersCheckedMap = {
  [key: string]: boolean;
};

export type FilterNames = {
  [key: string]: string;
};

const orderAndMap = (
  result: ApolloQueryResult<GetCategoryByKeyQuery>,
): CategoryType[] => {
  // sort based on orderHint defined in Commercetools
  if (result.data.category?.children) {
    const orderedResult = sortByOrderHint(result.data.category?.children) as ResultType[];
    return orderedResult.map(cat => ({
      key: cat.key,
      name: cat.name,
    }));
  }

  return [];
};

const constructFilterNames = (categories: CategoriesType[]): FilterNames => {
  const allFilters: FilterNames = {};
  categories.forEach(cat => {
    cat.subCategories.forEach(subCat => {
      allFilters[subCat.key] = subCat.name;
    });
  });

  return allFilters;
};

const constructFilterCheckedMap = (categories: CategoriesType[]): FiltersCheckedMap => {
  const filtersChecked: FiltersCheckedMap = {};
  categories.forEach(cat => {
    cat.subCategories.forEach(subCat => {
      filtersChecked[subCat.key] = false;
    });
  });

  return filtersChecked;
};

function useFilters() {
  const locale = useLocale();
  const client = useCommercetoolsClient()!;
  const [filterGroups, setFilterGroups] = useState<CategoriesType[]>([]);
  const [filtersChecked, setFiltersChecked] = useState<FiltersCheckedMap>({});
  const filterNames = useMemo(() => constructFilterNames(filterGroups), [filterGroups]);

  useEffect(() => {
    const acceptLanguage = [locale, Locale.Default];

    const queryProductWithKey = async (key: string) => {
      return await queryProductCategory(client, {
        acceptLanguage,
        fetchPolicy: 'cache-first',
        key: key,
      });
    };

    const getCategories = async () => {
      const categoryDataLevelOne = await queryProductWithKey(SPARE_PARTS);
      const subCategoriesLevelOne = orderAndMap(categoryDataLevelOne);

      const categoryDataLevelTwo: CategoriesType[] = await Promise.all(
        subCategoriesLevelOne.map(async cat => {
          const result = await queryProductWithKey(cat.key);

          const subCategoriesLevelTwo = orderAndMap(result);
          const isAdditionalParts = cat.key === 'spare_parts_additional_parts';
          const subCategories = isAdditionalParts
            ? [{ name: cat.name, key: cat.key }]
            : subCategoriesLevelTwo;

          return { name: cat.name, key: cat.key, subCategories };
        }),
      );
      // Only display filter groups that have sub categories
      const filteredFilterGroups = categoryDataLevelTwo.filter(
        x => x.subCategories.length > 0,
      );
      setFilterGroups(filteredFilterGroups);
      setFiltersChecked(constructFilterCheckedMap(filteredFilterGroups));
    };

    getCategories();
  }, [locale, client]);

  return {
    filterGroups,
    filterNames,
    filtersChecked,
    setFiltersChecked,
  };
}

export default useFilters;
