import type { PeloLink } from '@peloton/external-links/models';
import { toWWWLink } from '@peloton/external-links/models';
import { appendPackageSlug, shopAccessories } from '@peloton/links/www';
import {
  isProductAccessory,
  isProductBundle,
  isProductSparePart,
} from '@ecomm/product-recommendations/models/checkProductType';
import type {
  ProductSelection,
  ProductSelectionsArray,
  SelectionType,
} from '@ecomm/product-recommendations/utils/useConfigureProductFormData';
import { BundleType } from '@ecomm/shop/models';
import type {
  Attribute,
  AttributeOption,
  Bundle,
  PriceOrPriceRange,
  Product,
  Variant,
} from '../models/Product';

export type SelectionOptions = {
  option: string;
  value: string;
  disabled?: boolean;
};

export const allSelectionsMade = (product: Product, selections: SelectionType) => {
  // non-configurable products don't require a selection, so they can be added
  if (!product.isConfigurable) {
    return true;
  }
  // bundles are an array of selections, check each one has an option
  else if (isProductBundle(product) && Array.isArray(selections)) {
    return selections.every((selection: ProductSelection) =>
      selection.selections.length ? selection.selections[0].option : true,
    );
    // non-bundles with one configurable attribute are an object
  } else {
    return Object.values(selections)[0];
  }
};

// configure product form selections mapping
export const toFormSelectOption = ({ name, slug }: AttributeOption) => {
  return {
    label: name,
    value: slug,
  };
};

export const toFormSelectOptionDS = ({
  name,
  slug,
}: AttributeOption): SelectionOptions => {
  return {
    option: name,
    value: slug,
  };
};

const toShopPath = (basePath: PeloLink, product: Product) =>
  appendPackageSlug(basePath, product.slug).path;

const toRefurbishedPath = (bundleType: BundleType) =>
  appendPackageSlug(toWWWLink('/shop/refurbished'), bundleType).path;

// builds link used when clicking on product card
export const toDetailsUrl = (product: Product) => {
  if (isProductAccessory(product)) {
    return toShopPath(shopAccessories, product);
  } else if (isProductBundle(product)) {
    return toShopPath(toWWWLink(shopAccessories.path + '/set/'), product);
  } else if (isProductSparePart(product)) {
    return '';
  } else {
    if (product.bundleType === BundleType.RefurbishedBike) {
      return toRefurbishedPath(BundleType.Bike);
    } else if (product.bundleType === BundleType.RefurbishedBikePlus) {
      return toRefurbishedPath(BundleType.BikePlus);
    } else {
      return toShopPath(toWWWLink(`/shop/${product.bundleType}`), product);
    }
  }
};

// creates empty selection array for bundles
export const createBundleSelections = (product: Bundle) => {
  return [
    ...product.attributes.map(attribute => ({
      product: attribute.productSlug!,
      selections: [
        {
          attribute: attribute.slug,
          option: '',
        },
      ],
    })),
    ...product.nonConfigurableProducts.map(prod => ({
      product: prod,
      selections: [],
    })),
  ];
};

export const getVariantForAttributeSelection = (
  product: Product,
  attribute: Attribute,
  option?: string,
): Variant | undefined => {
  return product.variants.find(({ configurations }) =>
    configurations.some(
      config => config.attribute === attribute.slug && config.option === option,
    ),
  );
};

// pricing
export const isSinglePrice = (price: PriceOrPriceRange): price is number => {
  return typeof price === 'number';
};

export const toPriceRangeTuple = (obj: {
  low: number;
  high: number;
}): [number, number] => [obj.low, obj.high];

export const productHasVariablePricing = (product: Product): boolean =>
  product.variants.length > 1 && // if there's only 1 variant, there can't be a price range
  product.variants.some(variant => variant.price.amount);

const getMinOrMaxOfVariants = (
  variants: Variant[],
  funct: (...values: number[]) => number,
): number => {
  // expected function to apply: Math.min or Math.max
  return funct(...variants.map(variant => variant.price.amount));
};

const getVariantForSelection = (
  product: Bundle,
  attributeSlug: string,
  option: string,
) => {
  return product.variants.find(({ configurations }) =>
    configurations.some(
      config => config.attribute === attributeSlug && config.option === option,
    ),
  );
};

// handles bundles with variable pricing
export const getBundlePriceRangeWithVariants = (
  product: Bundle,
  selections: ProductSelectionsArray,
): PriceOrPriceRange => {
  const nonConfigProductPrices = product.nonConfigurableProductsPrices.reduce(
    (a, b) => a + b,
    0,
  );
  let minPrice = 0;
  let maxPrice = 0;

  selections.forEach(select => {
    const selection = select.selections[0];

    // non-configurable items will not have a selection
    if (selection) {
      // if this configurable item already has a selected value
      if (selection.option) {
        const currentVariant = getVariantForSelection(
          product,
          selection.attribute,
          selection.option,
        );

        minPrice += currentVariant ? currentVariant.price.amount : 0;
        maxPrice += currentVariant ? currentVariant.price.amount : 0;
      } else {
        // otherwise, calculate the range
        const variantsForAttribute = product.variants.filter(({ configurations }) =>
          configurations.some(
            ({ attribute: attributeSlug }) => attributeSlug === selection.attribute,
          ),
        );

        minPrice += getMinOrMaxOfVariants(variantsForAttribute, Math.min);
        maxPrice += getMinOrMaxOfVariants(variantsForAttribute, Math.max);
      }
    }
  });

  return minPrice === maxPrice
    ? nonConfigProductPrices + minPrice
    : [nonConfigProductPrices + minPrice, nonConfigProductPrices + maxPrice];
};

export const everyAttributeConfigured = (selections: ProductSelectionsArray) => {
  return selections.every(selection =>
    // only looks at configurable products
    // avoids non configurable products, which do not have a selection
    selection.selections.length ? selection.selections[0].option : true,
  );
};

export const getAttributeToSelectedSlugMap = (
  product: Product,
  selections: SelectionType,
) => {
  return product.attributes.reduce((acc, attribute, attributeIndex) => {
    const selectedSlug: string = isProductBundle(product)
      ? selections[attributeIndex].selections[0].option
      : selections[attribute.slug];

    if (acc[attribute.slug] !== undefined) {
      acc[attribute.slug].push(selectedSlug);
    } else {
      acc[attribute.slug] = [selectedSlug];
    }
    return acc;
  }, {});
};

export const disableDuplicateOptionSelection = (
  itemsForSelect: SelectionOptions[],
  alreadySelectedSlugs: string[],
  selectedItemSlug?: string,
  selectedLabel?: string,
) => {
  return itemsForSelect.map(item => {
    if (alreadySelectedSlugs.includes(item.value) && item.value !== selectedItemSlug) {
      return { ...item, option: `${item.option} - ${selectedLabel}`, disabled: true };
    }
    return item;
  });
};
