import type { ButtonProps, StarsProps } from '@pelotoncycle/design-system';
import { grey, white } from '@pelotoncycle/design-system';
import type { TypeComponentReviewsFields } from '@page-builder/lib/types';

export type ThemeProps = {
  backgroundColor: string;
  textColor: string;
  secondaryTextColor: string;
  itemTextColor: string;
  secondaryButtonColor: ButtonProps['color'];
  borderColor: string;
  starsColor: StarsProps['theme'];
};
type ThemeColor = NonNullable<TypeComponentReviewsFields['theme']>;
type Themes = Record<ThemeColor, ThemeProps>;

export const themes: Themes = {
  White: {
    backgroundColor: white,
    textColor: grey[90],
    secondaryTextColor: grey[70],
    itemTextColor: grey[70],
    secondaryButtonColor: 'dark',
    borderColor: grey[40],
    starsColor: 'light',
  },
  Black: {
    backgroundColor: grey[90],
    textColor: white,
    secondaryTextColor: grey[50],
    itemTextColor: grey[40],
    secondaryButtonColor: 'light',
    borderColor: grey[70],
    starsColor: 'dark',
  },
} as const;
