import type { TypePromo } from '@pelotoncycle/page-builder';
import { useMemo } from 'react';
import { useLocale } from '@peloton/internationalize';
import toBasicsPackage from '@ecomm/shop/localizedBasicPackagesMap';
import { toBundleType } from '@ecomm/shop/models/Bundle';

// Get general promo discount from the basics bundle
const usePromoDiscount = (promo?: TypePromo) => {
  const locale = useLocale();
  const slug = promo && toBasicsPackage(toBundleType(promo.fields.product), locale);

  return useMemo(() => {
    if (!slug) return;
    const basicsBundlePromo = promo?.fields.bundles.find(
      bundlePromo => bundlePromo.fields.slug === slug,
    );
    return basicsBundlePromo?.fields.couponDiscount;
  }, [slug, promo]);
};

export default usePromoDiscount;
