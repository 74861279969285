import { BreakpointWidths, Icon, Label, grey, white } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SupportedTLD } from '@peloton/internationalize/models/locale';
import { usePreviousRouteContext } from '@peloton/next/components/PreviousRouteProvider';
import { useMicroCopy } from '@content/client/microCopy';
import { themes } from '@page-builder/themes';
import type { Themes } from '@page-builder/themes';

type HeroFiftyFiftyHybridBackLinkProps = {
  theme: Themes;
};

export const getReferrer = () => document.referrer;

const HeroFiftyFiftyHybridBackLink: React.FC<
  React.PropsWithChildren<HeroFiftyFiftyHybridBackLinkProps>
> = ({ theme }) => {
  const backLabel = useMicroCopy('disciplineBackButton');
  const router = useRouter();
  const [showBackButton, setShowBackButton] = useState(false);
  const prevRoute = usePreviousRouteContext();
  const hasPreviousPath = !!prevRoute;

  useEffect(() => {
    const referrer = getReferrer();
    const regex = `onepeloton(${Object.values(SupportedTLD)
      .join('|')
      .replace(/\./g, '\\.')})`;

    const internalDomainsRegex = new RegExp(regex, 'i');
    const isFromOnePeloton = internalDomainsRegex.test(referrer);
    setShowBackButton(hasPreviousPath || isFromOnePeloton);
  }, [hasPreviousPath]);

  return showBackButton ? (
    <StyledOuterDiv>
      <StyledDiv>
        <button onClick={() => router.back()}>
          <Container theme={theme}>
            <Icon name="chevron" primaryColor={white} data-test-id="chevronIcon" />
            <Label size="medium" data-test-id="back-button-label">
              {backLabel}
            </Label>
          </Container>
        </button>
      </StyledDiv>
    </StyledOuterDiv>
  ) : null;
};

export default HeroFiftyFiftyHybridBackLink;

const StyledOuterDiv = styled.div`
  @media (min-width: ${BreakpointWidths.desktop}px) {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: 1920px;
    padding-top: 3.5rem;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledDiv = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  background-color: ${grey[80]};
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${BreakpointWidths.desktop}px) {
    padding: ${11 / 16}rem ${13 / 16}rem 0;
    background-color: transparent;
  }
`;

const Container = styled.div<{ theme: Themes }>`
  display: flex;
  align-items: center;
  color: ${white};
  height: 3rem;
  > svg {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
  path {
    fill: ${white};
  }
  //back button adjusts with theme in desktop
  @media (min-width: ${BreakpointWidths.desktop}px) {
    color: ${props => themes[props.theme].headlineColor};
    path {
      fill: ${props => themes[props.theme].headlineColor};
    }
  }
`;
