import {
  Container,
  Flex,
  Headline,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import { toCountry } from '@peloton/internationalize/models';
import { media as mediaBreakpoints } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import ModalView from '@ecomm/modal/ModalView';
import { ScrollFromBottomModalStyles } from '@ecomm/modal/ScrollFromBottomModalStyles';
import toBasicsPackage from '@ecomm/shop/localizedBasicPackagesMap';
import type { BundleType } from '@ecomm/shop/models';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericList,
} from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import { Divider } from '../ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import CompareBikesModalContent from './CompareBikesModalContent';

type ModalProps = {
  activeModal: TypeComponentGenericList;
  bikeAndBikePlusCtaData: TypeComponentCtaFields[];
  isModalOpen: boolean;
  onModalClose: () => void;
  productBundleType: BundleType;
};

const CompareBikesModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  activeModal,
  bikeAndBikePlusCtaData,
  isModalOpen,
  onModalClose,
  productBundleType,
}) => {
  const { trackEvent } = useTracking();
  const locale = useLocale();

  const { name: modalUnitName, text: modalHeaderText } = activeModal.fields;
  const { headline: modalHeader } = getTextFieldsWithRequiredKeys(
    ['headline'],
    modalHeaderText,
  );

  const modalOpenCloseEvent = (eventType: TrackingEvent) =>
    trackEvent({
      event: eventType,
      properties: {
        // the test is launching US only anyways
        country: toCountry().toLocaleUpperCase(),
        pageName: `/shop/${productBundleType}/${toBasicsPackage(
          productBundleType,
          locale,
        )}`,
        productInterest: productBundleType,
        propertyType: 'Web',
        unitName: modalUnitName,
        unitPlacement: 'Modal',
        modalTrigger: 'Click',
      },
    });

  const handleModalOpen = () => {
    modalOpenCloseEvent(TrackingEvent.ModalOpened);
  };

  const handleModalClose = () => {
    modalOpenCloseEvent(TrackingEvent.ModalClosed);
    onModalClose();
  };
  const [bike, bikePlus] = bikeAndBikePlusCtaData;
  const bikeModalContent =
    bike.modal &&
    isEntryType<TypeComponentGenericList>(bike.modal, 'text') &&
    bike.modal.fields;
  const bikePlusModalContent =
    bikePlus.modal &&
    isEntryType<TypeComponentGenericList>(bikePlus.modal, 'text') &&
    bikePlus.modal.fields;
  const modalContentLength = bikeModalContent ? bikeModalContent?.items?.length : 0;
  return (
    <StyledModalView
      isOpen={isModalOpen}
      openHandler={handleModalOpen}
      closeHandler={handleModalClose}
      contentLabel={modalHeader!}
      padded={false}
      className="quick-view-modal"
      contentClassname="scroll-from-bottom-modal"
      data-test-id="compare-bikes-modal"
    >
      <ScrollFromBottomModalStyles />
      <StyledFlex backgroundColor={white} textAlign="center">
        <Container
          padding={{
            mobile: `${spacing[40]} ${spacing[16]} ${spacing[80]}`,
            tablet: `${spacing[40]}`,
            desktop: `${spacing[40]}`,
          }}
        >
          {/* title section */}
          <Headline size="small" textColor={grey[90]}>
            {modalHeader}
          </Headline>
          <StyledDivider borderColor={grey[90]} topPadding={0} />

          {/* image and icons section */}
          {bikePlusModalContent && bikeModalContent && (
            <Flex
              flexDirection="row"
              padding={{
                mobile: `${spacing[24]} 0`,
                desktop: `${spacing[32]} 0`,
              }}
            >
              <StyledContainer borderRadius={spacing[4]}>
                {Array.from({ length: modalContentLength }, (_, i) => (
                  <CompareBikesModalContent
                    items={[bikeModalContent.items[i], bikePlusModalContent.items[i]]}
                  />
                ))}
              </StyledContainer>
            </Flex>
          )}
        </Container>
      </StyledFlex>
    </StyledModalView>
  );
};

const StyledDivider = styled(Divider)`
  padding-top: ${spacing[24]};

  ${mediaBreakpoints.desktop`
    padding-top: ${spacing[32]};
  `}
`;

const StyledModalView = styled(ModalView)`
  padding: 0;
  max-width: 784px;

  border-top-left-radius: ${spacing[12]};
  border-top-right-radius: ${spacing[12]};

  ${mediaBreakpoints.desktop`
    border-radius: 5px;
  `}
`;

const StyledFlex = styled(Flex)`
  border-top-left-radius: ${spacing[12]};
  border-top-right-radius: ${spacing[12]};

  ${mediaBreakpoints.desktop`
    border-radius: 5px;
  `}
`;

const StyledContainer = styled(Container)`
  overflow: hidden;
`;

export default CompareBikesModal;
