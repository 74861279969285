import { spacing, Media, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media } from '@peloton/styles';

type Props = {
  image: string;
};

const FreeClassCardImage: React.FC<React.PropsWithChildren<Props>> = ({ image }) => {
  return (
    <Wrapper>
      <CardImage
        width="100%"
        objectFit="cover"
        media={{
          alt: '',
          mobile: toCloudinarySrc({ src: image, width: 155, height: 87 }),
          desktop: toCloudinarySrc({ src: image, width: 183, height: 102 }),
          loading: 'eager',
          type: 'image',
        }}
      />
      <StyledIcon primaryColor="#fff" secondaryColor="#000" name="play" />
    </Wrapper>
  );
};

export default FreeClassCardImage;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;

  ${media.desktop`
    width: 32px;
    height: 32px;
  `}
`;

const Wrapper = styled.div`
  position: relative;
`;

const CardImage = styled(Media)`
  border-radius: ${spacing[8]};
  height: 87px;
  width: 155px;
  overflow: hidden;

  picture img {
    object-fit: cover;
    min-height: 100%;
    max-width: 100%;
    width: 100vw;
  }

  ${media.desktop`
    width: 183px;
    height: 102px;
  `}
`;
