import NextLink from 'next/link';
import React from 'react';
import type { FreeClass } from '@peloton/models/PelotonClass';
import FreeClassCard from '@page-builder/modules/ClassesGrid/Card/FreeClassCard';
import { ClassDetailsCardSection } from '@page-builder/modules/ClassesGrid/Modal/ClassDetailsCardSection';
import { useVideoTrackEvent } from '@page-builder/modules/ClassesGrid/utils/useVideoTrackEvent';

type Props = {
  freeClass: FreeClass;
};

export const FreeClassSection: React.FC<Props> = ({ freeClass }) => {
  const cardHref = `/classes/video/${freeClass.slug}`;
  const { onClickTrackEvent } = useVideoTrackEvent();

  return (
    <ClassDetailsCardSection>
      <NextLink href={cardHref} passHref legacyBehavior>
        <a
          href={cardHref}
          data-test-id="free-class-card"
          onClick={() =>
            onClickTrackEvent({
              parent: 'Prospect Classes Page',
              parentType: 'Page',
              linkName: freeClass.title,
            })
          }
        >
          <FreeClassCard freeClass={freeClass} />
        </a>
      </NextLink>
    </ClassDetailsCardSection>
  );
};
