import React from 'react';
import CitizensCopy from '@ecomm/financing/citizens/CitizensCopy';
import { BundleType } from '@ecomm/shop/models/BundleType';

type ModalLinkProps = {
  bundleType?: BundleType;
  className?: string;
  name: string;
  price: number;
};

export const CitizensModalLink: React.FC<React.PropsWithChildren<ModalLinkProps>> = ({
  bundleType = BundleType.Bike,
  name,
  price,
}) => {
  return (
    <CitizensCopy
      pageType="financing"
      price={price}
      id={`citizens-${name}-widget`}
      data-test-id={`citizens-${name}-widget`}
      text="Check eligibility"
      className={'citizens-financing-widget'}
      bundleType={bundleType}
    />
  );
};
