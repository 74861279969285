import { useCallback, useEffect, useRef } from 'react';

// Updated version @ecomm/cms-hero-carousel/hooks/useInterval

const useInterval = (callback: Function, delay?: number) => {
  const savedCallback = useRef(callback);
  const intervalId = useRef<ReturnType<typeof setInterval> | undefined>();

  const createInterval = useCallback(() => {
    const tick = () => {
      savedCallback && savedCallback.current();
    };
    const id = setInterval(tick, delay);
    intervalId.current = id;
  }, [delay]);

  useEffect(() => {
    // If there's no delay value dont create the interval
    if (delay && !intervalId.current) {
      createInterval();
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = undefined;
      }
    }
    return () => intervalId.current && clearInterval(intervalId.current);
  }, [delay, createInterval]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const resetInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      createInterval();
    }
  };

  return { resetInterval };
};

export default useInterval;
