import type { Entry } from 'contentful';
import { useEffect, useMemo, useState } from 'react';
import { useTimeoutFn, useToggle } from 'react-use';
import { GUIDE_PACKAGE_LOCALE_MAP } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import {
  UMICH_PACKAGE_SLUG,
  UMICH_RENTAL_PACKAGE_SLUG,
} from '@ecomm/cart-next/hooks/useIsCartEligibleForFinancing';
import { useFinancingValues } from '@ecomm/copy/helpers/useFinancingValues';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import { roundByFinancingPartner } from '@ecomm/financing/models/monthlyPaymentWithApr';
import { toDollars } from '@ecomm/models';
import type {
  AccessoryCommerceTools,
  ProductItemOfBundle,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import { isRentalSlug } from '@ecomm/rentals/models';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration';
import isRefurbishedBundleType from '@ecomm/shop/isRefurbishedBundleType';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { useTagResolver } from '@page-builder/hooks/useTagHandler';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericList,
  TypeComponentGenericTextWithMedia,
  TypeComponentGenericTextWithMediaFields,
  TypeComponentHeadband,
  TypeProduct,
  TypeComponent_overviewFields,
} from '@page-builder/lib/types';
import { useDrawerSelectionContext } from '@page-builder/modules/Overview/DrawerSelectionContext';
import {
  toCtaFields,
  toEntryTags,
  toProductDisplayName,
} from '@page-builder/utils/helpers';
import {
  getExistingQueryValueFor,
  transformTagToQueryValue,
} from '@page-builder/utils/helpers/urlParams';
import useCFUProductDataForSlug from '@page-builder/utils/product-data/useCFUProductDataForSlug';
import { colorSlugMap, UPSELL_TAGS } from './constants';
import type { DrawerFinancingData } from './DrawerContentContext';
import { usePromoContext } from './PromoProvider';
import { useShopContext } from './ShopContext';
import {
  DRAWER_RIBBON_TAG,
  DRAWER_TO_ROUTE_MATCHER,
  PRODUCT_INTEREST,
} from './ShopDrawers/constants';
import type {
  AccessoryVariantsType,
  UpsellAccessoryContextProps,
} from './UpsellAccessoryContext';

type VariantConfiguration = VariantCommerceTools['configurations'][0] & {
  upsellItemIdx: number;
};
type VariantConfigurationMap = {
  [variantSlug: string]: VariantConfiguration;
};

export const useHeadbandData = (product: TypeProduct): TypeComponentHeadband | null => {
  const financingFormattingValues = useFinancingFormattingValues('subnav');
  const toFormattedText = useGetTextFormatter();
  const { activeShopDrawer } = useDrawerSelectionContext();

  if (!activeShopDrawer) {
    return null;
  }

  const financingText = toFormattedText(
    activeShopDrawer.fields.text?.fields.support,
    financingFormattingValues,
  );

  const headband: TypeComponentHeadband = ({
    sys: { ...product.sys },
    fields: {
      name: product.fields.name,
      title: toProductDisplayName(product.fields.name),
      cta: toPrimaryDrawerCta(activeShopDrawer),
      financingText,
      disclaimerText: activeShopDrawer.fields.text?.fields.label,
    },
  } as unknown) as TypeComponentHeadband;
  return headband;
};

export const toCtaTrackingProperties = (cta: TypeComponentCtaFields, parent: string) => {
  return {
    href: cta.link?.fields.url || '',
    parentType: 'Component: Overview',
    parent,
    unitName: cta.name,
    linkName: cta.text,
    linkTo: cta.link?.fields.url || '',
  };
};

export const toPrimaryDrawerCta = (
  drawer: TypeComponentGenericList,
  tagName = 'addToCartCta',
) => {
  if (!drawer) return;

  const { items } = drawer.fields;
  const cta = checkTagsForItem(items, tagName)?.ctas![0];
  return cta ?? items[0]?.fields.ctas![0];
};

const checkTagsForItem = (
  items: TypeComponentGenericTextWithMedia[],
  tagName: string,
) => {
  for (const item of items) {
    const tags = toEntryTags(item);
    if (tags.includes(tagName)) {
      return { ...item.fields, id: item.sys.id };
    }
  }
  return undefined;
};

export const toDrawerBulletsBody = (
  drawer: TypeComponentGenericList,
  tagName = 'drawerBulletsItem',
) => {
  const { items } = drawer.fields;
  const item = checkTagsForItem(items, tagName);
  return item?.text?.fields.body ?? items[0]?.fields.text?.fields.body;
};

export const toDrawerFinancingData = (
  shopDrawers: TypeComponentGenericList[] | undefined,
  tagName = 'drawerBulletsItem',
) => {
  const drawerFinancingDataMap = new Map<number, DrawerFinancingData>();
  if (!shopDrawers) return drawerFinancingDataMap;

  return shopDrawers.reduce((accumulator, drawer, index) => {
    const { items } = drawer.fields;
    const item = checkTagsForItem(items, tagName);

    accumulator.set(index, {
      eyebrow: item?.text?.fields.eyebrow,
      cta: item?.ctas?.[0],
    });
    return accumulator;
  }, drawerFinancingDataMap);
};

const toUpsellSectionIntro = (drawer: TypeComponentGenericList, tagName: string) => {
  const { items } = drawer.fields;
  const item = checkTagsForItem(items, tagName);
  return {
    heading: item?.text?.fields.eyebrow,
    subheading: item?.text?.fields.headline,
  };
};

const filterDrawerItemsByTags = (
  items: Entry<TypeComponentGenericTextWithMediaFields>[],
  tagNames: string[],
) =>
  items.filter(item => {
    const itemTags = toEntryTags(item);
    return tagNames.some(tag => itemTags.includes(tag));
  });

const toAllDrawerUpsellItems = (drawer: TypeComponentGenericList, tagNames: string[]) => {
  const { items } = drawer.fields;
  const upsellTaggedItems = filterDrawerItemsByTags(items, tagNames);

  return upsellTaggedItems.map(({ sys: { id }, fields }) => {
    const { name, ctas, text } = fields;
    const [cta] = toCtaFields(ctas || []);
    const itemText = text?.fields;

    return {
      text: {
        productSubhead: itemText?.headline,
        productDescription: itemText?.body,
        isIncludedText: itemText?.label,
        configDropdownLabel: itemText?.support,
        editConfigText: itemText?.eyebrow,
      },
      productSlug: cta?.productSlug,
      itemName: name,
      key: id,
    };
  });
};

type Options = {
  isMultiShopOptions?: boolean;
  tagNames?: string[];
};

export const toDrawerContent = (
  drawer?: TypeComponentGenericList,
  options: Options = {},
) => {
  if (!drawer) {
    return undefined;
  }

  const { isMultiShopOptions = false, tagNames = [UPSELL_TAGS.accessory] } = options;

  const upsellItemContent = toAllDrawerUpsellItems(drawer, tagNames);

  const upsellMultiIntro =
    upsellItemContent.length > 1 && tagNames.length > 1
      ? toUpsellSectionIntro(drawer, UPSELL_TAGS.introMulti)
      : undefined;
  const useMulti = upsellMultiIntro?.heading || upsellMultiIntro?.subheading;
  // fall back if multiple upsells shown but drawer item with "upsellIntroMulti" tag does not exist
  const upsellSectionIntro =
    upsellMultiIntro && useMulti
      ? upsellMultiIntro
      : toUpsellSectionIntro(drawer, UPSELL_TAGS.intro);

  return {
    ctas: [toPrimaryDrawerCta(drawer)!],
    drawerBody: isMultiShopOptions
      ? toDrawerBulletsBody(drawer, 'drawerBulletsItem')
      : undefined,
    financingCtas: drawer.fields.ctas,
    financingText: drawer.fields.text?.fields.support,
    isOpen: true,
    upsellSectionIntro,
    upsellItemContent,
  };
};

export const toColorContent = (colorSelection: TypeComponentGenericList) => {
  const colorSlugs = (colorSelection.fields.ctas || [])
    .map(({ fields }) => fields.productSlug)
    .filter(slug => slug && colorSlugMap[slug]) as string[];

  return {
    colorSlugs,
    hasColorSelection: colorSlugs.length > 0,
    colorSelectionTitle: colorSelection.fields.text?.fields.label || '',
  };
};

export const toUpsellProductSlugsForDrawer = (
  drawer: TypeComponentGenericList,
  tagNames: string[],
): TypeComponentCtaFields['productSlug'][] => {
  const { items } = drawer.fields;
  const upsellTaggedItems = filterDrawerItemsByTags(items, tagNames);

  return upsellTaggedItems.flatMap(({ fields: { ctas } }) =>
    toCtaFields(ctas || []).map(cta => cta.productSlug),
  );
};

export const isUpsellItemGuide = (
  upsellItem: AccessoryCommerceTools | BundleAccessoryType | ProductItemOfBundle,
  locale: Locale,
) => {
  return upsellItem && GUIDE_PACKAGE_LOCALE_MAP[locale] === upsellItem.slug;
};

export const isAccessoryBundle = (
  accessory: AccessoryCommerceTools | BundleAccessoryType | ProductItemOfBundle,
): accessory is BundleAccessoryType => accessory && accessory.hasOwnProperty('products');

const isWarranty = (
  accessory: AccessoryCommerceTools | BundleAccessoryType | ProductItemOfBundle,
): boolean => accessory.slug.includes('warr');

export const isConfigurable = (
  accessory: AccessoryCommerceTools | BundleAccessoryType | ProductItemOfBundle,
) => {
  if (isWarranty(accessory)) {
    return false;
  }

  // if acc is a bundle, make sure at least one of its non-warranty products has attributes
  // if acc is not a bundle, make sure it itself has attributes
  return isAccessoryBundle(accessory)
    ? accessory.products.some(
        product => !isWarranty(product) && product.attributes.length > 0,
      )
    : accessory.attributes.length > 0;
};

export const getBundleVariantsWithSelection = (
  bundleAccessory: BundleAccessoryType,
  accessory: ProductItemOfBundle,
  indexOfProductInBundle: string,
  selectedOption?: string,
  currentVariants?: Record<string, VariantCommerceTools | undefined>,
) => {
  return {
    [bundleAccessory.slug]: {
      ...currentVariants,
      [accessory.slug + '-' + indexOfProductInBundle]: selectedOption
        ? getUpsellAccessoryVariant(accessory, selectedOption)
        : undefined,
    },
  };
};

export const allBundleProductsHaveVariants = (
  accessory: BundleAccessoryType,
  variants: AccessoryVariantsType,
) => {
  return accessory.products.every(
    (product, index) =>
      variants[accessory.slug]?.[product.slug + '-' + index.toString()] !== undefined,
  );
};

type UpsellBundleObject = {
  bundleId: string;
  productOptions: string[];
};

export const getUserAccessorySelections = (
  singleVariant?: VariantCommerceTools,
  bundleVariants?: Record<string, VariantCommerceTools | undefined>,
  bundleAccessory?: BundleAccessoryType,
) => {
  if (singleVariant) return singleVariant.configurations;
  else if (bundleVariants && bundleAccessory) {
    const numberOfConfigurableAccessories = bundleAccessory.products.filter(product =>
      isConfigurable(product),
    ).length;

    // omit variants which are nonconfigurable (ex. bike mat) or not configured by user (ex. shoes without size selected)
    const configuredVariantSlugs = Object.keys(bundleVariants).filter(
      slug => !!bundleVariants[slug]?.configurations.length,
    );

    // return early if any configurable accessories are not configured by user
    if (configuredVariantSlugs.length !== numberOfConfigurableAccessories) {
      return;
    }

    const variantMap = configuredVariantSlugs.reduce(
      (map: VariantConfigurationMap, variantSlug) => {
        const variant = bundleVariants[variantSlug];

        if (!variant) return map;

        // track bundle accessory by de-indexed slug so that we can combine user selections in UI
        const [deIndexedSlug, indexStr] = variantSlug.split(/-([0-9]+)$/);

        if (!map[deIndexedSlug]) {
          // get user's accessory selection, and track it by slug
          const baseConfig = variant.configurations[0];

          const bundleAttribute = bundleAccessory?.products?.find(product =>
            product?.variants.find(v => v.legacyOptionId === baseConfig.legacyOptionId),
          );

          map[deIndexedSlug] = {
            ...baseConfig,
            attribute: bundleAttribute?.displayName || baseConfig.attribute,
            upsellItemIdx: parseInt(indexStr) || -1,
          };
        } else {
          // if accessory is already tracked, append rendered text (ex. additional dumbbell choice)
          map[deIndexedSlug].option += `, ${variant.configurations[0].option}`;
        }

        return map;
      },
      {},
    );

    // maintain ordering per upsell items
    return Object.values(variantMap).sort((a, b) => a.upsellItemIdx - b.upsellItemIdx);
  }

  return undefined;
};

const createUpsellBundleParam = (
  accessory: BundleAccessoryType,
  accessoryVariants: UpsellAccessoryContextProps['accessoryVariants'],
) => {
  return {
    bundleId: accessory.legacyBundleId,
    productOptions: Object.values(accessoryVariants[accessory.slug] || {}).map(
      variant => variant?.legacyOptionId,
    ),
  } as UpsellBundleObject;
};

export const toAccessoryIds = (
  selectedAccessories: NonNullable<UpsellAccessoryContextProps['upsellAccessories']>,
  accessoryVariants: UpsellAccessoryContextProps['accessoryVariants'],
) => {
  return selectedAccessories
    .map(accessory => {
      const variant = toVariantForAccessory(accessory, accessoryVariants);

      return variant ? [accessory.id, variant.legacyOptionId] : null;
    })
    .filter(Boolean) as [string, string][];
};

export const toAddPackageMutationParams = (
  packageSlug: string,
  bundleType: BundleType,
  selectedAccessories: NonNullable<UpsellAccessoryContextProps['upsellAccessories']>,
  accessoryVariants: UpsellAccessoryContextProps['accessoryVariants'],
) => {
  // The following params are required if we want to be able to pass values for the isRental and isRefurb flags
  // TODO CHAMP-543: Check what name goes here + in case with multiple items
  const accessoryName =
    selectedAccessories && selectedAccessories[0]
      ? selectedAccessories[0] && selectedAccessories[0].slug
      : undefined;

  const addedFromCart = false;

  const hasGuide = false;

  const isRental = isRentalSlug(packageSlug);
  const isRefurb = isRefurbishedBundleType(bundleType);

  const includeAccessory = selectedAccessories && selectedAccessories.length > 0;

  const accessoryIds = toAccessoryIds(selectedAccessories, accessoryVariants);

  const upsellBundles: UpsellBundleObject[] = selectedAccessories
    .filter(isAccessoryBundle)
    .map(accessory => createUpsellBundleParam(accessory, accessoryVariants));

  const hasAccessoryBundle = Boolean(upsellBundles.length);

  return [
    packageSlug,
    bundleType,
    includeAccessory,
    hasAccessoryBundle,
    accessoryName,
    addedFromCart,
    hasGuide,
    isRental,
    isRefurb,
    accessoryIds,
    upsellBundles,
  ] as const;
};

export const toAddCTPackageToCartParams = (
  packageSlug: string,
  bundleType: BundleType,
  selectedAccessories: NonNullable<UpsellAccessoryContextProps['upsellAccessories']>,
  accessoryVariants: UpsellAccessoryContextProps['accessoryVariants'],
  cfuPackage: CommercetoolsPackage,
) => {
  const accessoryIds = toAccessoryIds(selectedAccessories, accessoryVariants);

  const firstAccessory = selectedAccessories?.[0];
  const accessoryName =
    (firstAccessory && !isAccessoryBundle(firstAccessory) && firstAccessory.slug) || '';

  const addedFromCart = false;

  const hasGuide = false;

  const isRental = isRentalSlug(packageSlug);
  const isRefurb = isRefurbishedBundleType(bundleType);

  const hasAccessory = selectedAccessories && selectedAccessories.length > 0;

  const upsellBundles: UpsellBundleObject[] = selectedAccessories
    .filter(isAccessoryBundle)
    .map(accessory => createUpsellBundleParam(accessory, accessoryVariants));

  const hasAccessoryBundle = Boolean(upsellBundles.length);

  return {
    bundleType,
    bundlePackage: cfuPackage,
    hasAccessory,
    hasAccessoryBundle,
    accessoryName,
    addedFromCart,
    hasGuide,
    hasOPC: isRental,
    hasCPO: isRefurb,
    upsellIds: accessoryIds,
    upsellBundles,
  };
};

// When the commercetools rentals feature toggle is enabled, rentals are valid CT products
// Umich packages are not currently valid CT products in any case
export const isValidCommercetoolsProductSlug = (
  productSlug: string,
  isCTRentalsEnabled: boolean,
) => {
  if (!productSlug) return false;

  const isUMichBike = productSlug === UMICH_PACKAGE_SLUG;
  const isUMichBikeRental = UMICH_RENTAL_PACKAGE_SLUG.includes(productSlug);

  if (isUMichBike || isUMichBikeRental) {
    return false;
  }

  const isOPC =
    isRentalSlug(productSlug) ||
    productSlug.includes('opc') ||
    productSlug.includes('faas');

  if (isOPC) {
    return isCTRentalsEnabled;
  }

  return true;
};

export const toVariantForAccessory = (
  accessory: NonNullable<UpsellAccessoryContextProps['upsellAccessories']>[number],
  accessoryVariants: UpsellAccessoryContextProps['accessoryVariants'],
  productOfBundle?: ProductItemOfBundle,
  index?: number,
) => {
  if (productOfBundle && isAccessoryBundle(accessory)) {
    return accessoryVariants[accessory.slug]?.[
      productOfBundle.slug + '-' + index?.toString()
    ];
  } else {
    return accessoryVariants[accessory.slug]?.[accessory.slug];
  }
};

export const getUpsellAccessoryVariant = (
  accessory: AccessoryCommerceTools | ProductItemOfBundle,
  variantOption: string,
) => {
  if (!accessory || !variantOption) return undefined;

  return accessory.variants.find(
    (variant: VariantCommerceTools) => variant.configurations[0].option === variantOption,
  );
};

const LOADING_MINIMUM_WAIT = 1000;

export const useOverviewLoadingWithMinimumWait = (trackedLoadingState: boolean) => {
  const [isLoading, toggleIsLoading] = useToggle(true);
  const [minimumWaitReached, toggleMinimumWaitReached] = useToggle(false);

  useTimeoutFn(toggleMinimumWaitReached, LOADING_MINIMUM_WAIT);

  useEffect(() => {
    if (!trackedLoadingState && minimumWaitReached) {
      toggleIsLoading(false);
    }
  }, [trackedLoadingState, minimumWaitReached, toggleIsLoading]);

  return isLoading;
};

// the product interest in analytics events is referring to the product group a users is interested in,
// so all bikes should fall under 'bike'
export const toProductInterest = (bundleType?: BundleType) =>
  bundleType === 'bike-plus' ? 'bike' : bundleType;

export const useScrollStarted = () => {
  const [scrollStarted, setScrollStarted] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrollStarted(true);
      // once we've scrolled, we don't need to listen for scroll events anymore
      window.removeEventListener('scroll', handleScroll);
    };
    window.addEventListener('scroll', handleScroll);
    // remove event listener on unmount (duplicate calls are ok)
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return scrollStarted;
};

export const useProductPackagePrice = () => {
  const { packageSlug } = useShopContext();
  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);

  const productPackagePriceCents = buildTimeProductData?.basePrice;
  const productPackagePriceDollars = productPackagePriceCents
    ? toDollars(productPackagePriceCents)
    : undefined;

  return productPackagePriceDollars;
};

const getDefaultDrawerIndexFromProductSlug = (
  drawers: TypeComponentGenericList[] | undefined,
  pathname: string,
) => {
  const drawerMatch = drawers?.find(drawer => {
    const ctaProductSlug = toPrimaryDrawerCta(drawer)?.fields.productSlug;

    return ctaProductSlug && pathname.includes(ctaProductSlug);
  });

  return drawerMatch && drawers?.indexOf(drawerMatch);
};

const getDefaultDrawerIndexFromTag = (
  drawers: TypeComponentGenericList[] | undefined,
  pathname: string,
) => {
  const drawerMatch = drawers?.find(drawer => {
    const routeMatcherTag = drawer.metadata
      ? toEntryTags(drawer).find(tag => tag.includes(DRAWER_TO_ROUTE_MATCHER))
      : undefined;
    const routeMatcherTagValue = routeMatcherTag
      ? transformTagToQueryValue(DRAWER_TO_ROUTE_MATCHER, routeMatcherTag)
      : undefined;

    // Contentful tags don't support '/', so we use '_' and then reformat the tag to match the route pattern
    // ex: /shop/refurbished/bike is inputted as _shop_refurbished_bike and then formatted back below
    const formattedTagValue = routeMatcherTagValue?.replace(/_/g, '/');

    return formattedTagValue && pathname.includes(formattedTagValue);
  });

  return drawerMatch && drawers?.indexOf(drawerMatch);
};

export const getDefaultDrawerIndexFromQueryParams = (
  drawers: TypeComponentGenericList[] | undefined,
) => {
  const productInterestQueryValue = getExistingQueryValueFor(
    PRODUCT_INTEREST.queryParamName,
  );

  if (!productInterestQueryValue || !drawers) return null;

  return getDrawerIndexForProductInterest(drawers, productInterestQueryValue);
};

const getDrawerIndexForProductInterest = (
  drawers: TypeComponentGenericList[] = [],
  productInterest: string,
) => {
  const index = drawers.findIndex(drawer => {
    const productInterestTag = drawer.metadata
      ? toEntryTags(drawer).find(tag => tag.includes(PRODUCT_INTEREST.tagPrefix))
      : undefined;

    const drawerQueryValue = transformTagToQueryValue(
      PRODUCT_INTEREST.tagPrefix,
      productInterestTag,
    );

    return drawerQueryValue === productInterest;
  });
  return index > -1 ? index : null;
};

export const getDefaultIndex = ({
  drawers,
  pathname,
  includeQueryParams,
  rentDrawerDisabled,
}: {
  drawers?: TypeComponentGenericList[];
  pathname: string;
  includeQueryParams: boolean;
  rentDrawerDisabled?: boolean | null;
}) => {
  // when multiple drawers, default to the drawer that matches the path, else second drawer
  const isSingleDrawer = Boolean(drawers && drawers.length <= 1);

  if (!drawers || isSingleDrawer) return 0;

  const queryParamsIndex = includeQueryParams
    ? getDefaultDrawerIndexFromQueryParams(drawers)
    : null;

  const defaultIndex =
    queryParamsIndex ??
    getDefaultDrawerIndexFromTag(drawers, pathname) ??
    getDefaultDrawerIndexFromProductSlug(drawers, pathname) ??
    1;

  if (rentDrawerDisabled) {
    const rentDrawerIndex = getDrawerIndexForProductInterest(
      drawers,
      PRODUCT_INTEREST.rent,
    );
    if (rentDrawerIndex === defaultIndex) {
      // Default to the first drawer which is not a rent drawer
      return rentDrawerIndex === 0 ? 1 : 0;
    }
  }

  return defaultIndex;
};

export const useFinancingFormattingValues = (placement: 'subnav' | 'shopDrawer') => {
  const financingValues = useFinancingValues();
  const { promo } = usePromoContext();

  const promoFinancingText = promo?.fields.financingMessaging;

  return {
    ...financingValues,
    placement,
    promoFinancing: promoFinancingText ? 'enabled' : 'disabled',
    promoFinancingText,
    productPackagePriceWithStrikethrough: useProductPackagePriceWithStrikethrough(),
  };
};

const useProductPackagePriceWithStrikethrough = () => {
  const { bundlePromo } = usePromoContext();
  const { packageSlug } = useShopContext();
  const buildTimeProductData = useCFUProductDataForSlug(packageSlug);
  const toFormattedText = useGetTextFormatter();

  if (!buildTimeProductData) {
    return '';
  }

  const { basePrice } = buildTimeProductData;

  const formattedBasePrice = toFormattedText('{price, number, currency}', {
    price: toDollars(basePrice),
  });
  if (bundlePromo?.fields.couponDiscount) {
    const discountAmount = bundlePromo.fields.couponDiscount * 100;
    const discountedPrice = basePrice - discountAmount;
    const formattedDiscountPrice = toFormattedText('{price, number, currency}', {
      price: toDollars(discountedPrice),
    });

    if (bundlePromo.fields.couponDiscount < 0) {
      return `${formattedBasePrice} ~${formattedDiscountPrice}~`;
    }

    return `${formattedDiscountPrice} ~${formattedBasePrice}~`;
  }

  return formattedBasePrice;
};

export const usePackageFinancing = (bundleType: BundleType) => {
  const { bundlePromo } = usePromoContext();
  const { productPackage } = useShopContext();
  const { term, financingPartner, apr } = useFinancingNext(bundleType);

  if (!productPackage) {
    return {
      ready: false,
      total: 0,
      term: 0,
      monthly: 0,
    };
  }

  const discount = bundlePromo?.fields.couponDiscount || 0;
  const total = productPackage.price.amount - discount * 100;

  const monthly = roundByFinancingPartner(total, term, apr, financingPartner);

  return {
    ready: true,
    total,
    term,
    monthly,
  };
};

export const useUrgencyMessagingMarkdownValues = () => {
  const { bundlePromo } = usePromoContext();

  const urgencyMessagingEnabled = bundlePromo?.fields.urgencyMessaging
    ? 'enabled'
    : 'disabled';
  const urgencyMessaging = bundlePromo?.fields.urgencyMessaging;

  return {
    urgencyMessagingEnabled,
    urgencyMessaging,
  };
};

const COMPONENT_RESERVED_TAGS = [PRODUCT_INTEREST.tagPrefix, DRAWER_TO_ROUTE_MATCHER];

export const useToggledComponents = (
  components:
    | TypeComponent_overviewFields['shopDrawers']
    | TypeComponent_overviewFields['tabs'],
) => {
  const { resolveTag } = useTagResolver();

  return useMemo(() => {
    if (!components) {
      return undefined;
    }

    const filteredComponents = (components as any[]).filter(component => {
      // Filters out tags that are used for other functionality within the overview
      const filteredTags = toEntryTags(component).filter(
        tag => !COMPONENT_RESERVED_TAGS.some(reservedTag => tag.includes(reservedTag)),
      );
      return filteredTags.every(tag => resolveTag(tag)[tag]);
    });

    return filteredComponents.length > 0 ? filteredComponents : undefined;
  }, [components, resolveTag]);
};

export const useActiveDrawerRibbon = () => {
  const { resolveTag } = useTagResolver();

  // If there isn't an active drawer, there can't be a ribbon for it
  const { activeShopDrawer } = useDrawerSelectionContext();
  if (!activeShopDrawer) {
    return undefined;
  }

  // Find any items that are tagged with the ribbon tag...
  const ribbonTaggedItems = filterDrawerItemsByTags(activeShopDrawer.fields.items, [
    DRAWER_RIBBON_TAG,
  ]);

  //...then apply the tag resolver to each item to filter them by experiments, etc...
  const activeRibbonTaggedItems = ribbonTaggedItems.filter(item => {
    const filteredTags = toEntryTags(item).filter(tag => tag !== DRAWER_RIBBON_TAG);
    return filteredTags.every(tag => resolveTag(tag)[tag]);
  });

  // ...then return the body of the text in the first remaining item after filtering.
  const [firstActiveRibbonTaggedItem] = activeRibbonTaggedItems;

  return firstActiveRibbonTaggedItem?.fields.text?.fields?.body;
};
