import React from 'react';
import type { TypeComponentGenericListFields, TypeTrack } from '@page-builder/lib/types';

import { toImageProps, videoFieldsToMediaVideoProps } from '@page-builder/utils/helpers';
import {
  getCtaFields,
  getTextFieldsWithRequiredKeys,
} from '@page-builder/utils/helpers/cms';
import type { Card } from './ClassesCarousel';
import ClassesCarousel from './ClassesCarousel';

const toLightboxTracks = (tracks: TypeTrack[]) =>
  tracks.map(item => ({
    src: item.fields?.src?.fields?.file?.url,
    kind: item.fields?.kind,
  }));

const ClassesCarouselHelper: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ text, items }) => {
  const { support, label } = getTextFieldsWithRequiredKeys(['support', 'label'], text);

  const cards: Card[] = items.map(item => {
    const mediaFields = toImageProps(item.fields.media);
    const { primaryCta } = getCtaFields('lightboxVideo', item.fields.ctas);
    const lightboxTracks = toLightboxTracks(
      primaryCta?.lightboxVideo?.fields?.tracks || [],
    );
    let modalVideo = undefined;
    if (primaryCta.lightboxVideo) {
      modalVideo = videoFieldsToMediaVideoProps(primaryCta.lightboxVideo.fields, {
        loop: false,
      });
    }
    return {
      image: mediaFields,
      title: primaryCta.text,
      modalVideo,
      tracks: lightboxTracks,
    };
  });

  return <ClassesCarousel cards={cards} label={label} support={support} />;
};

export default ClassesCarouselHelper;
