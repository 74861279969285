import { Locale } from '@peloton/internationalize';
import type { SparePartCommerceTools } from '@page-builder/modules/SpareParts/models';
import { SparePartKeys, COMMERCETOOLS_AVAILABILITY_FF } from '../constants';
import type { ProductCatalogType } from '../models/main';
import {
  getCurrentAvailabilityState,
  getProductAvailabilityState,
} from '../product-states/getAvailabilityState';
import {
  filterVariantsByAvailability,
  filterVariantsBySalesStatus,
} from '../product-states/getSalesStatus';
import type { ProductData, Image, ProductVariant } from '../types.generated';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';
import { sortVariantsByOrder } from '../utils/sortVariantsByOrder';
import { sortVariantsByPrice } from '../utils/sortVariantsByPrice';
import { mapProductAttributesToConfigs } from './mapProductAttributesToConfigs';
import { mapProductAttributesToOptions } from './mapProductAttributesToOptions';

const evaluateToDefault = (input: any, def: any) => {
  return input ?? def;
};

const getFilteredVariants = (
  variants: ProductVariant[],
  masterVariant: ProductVariant,
  context: Record<string, boolean>,
  locale: Locale,
): ProductVariant[] => {
  if (variants.length > 0 && context[COMMERCETOOLS_AVAILABILITY_FF]) {
    return filterVariantsByAvailability([masterVariant, ...variants], locale);
  } else if (variants.length > 0 && !context[COMMERCETOOLS_AVAILABILITY_FF]) {
    return filterVariantsBySalesStatus([masterVariant, ...variants], locale);
  }

  return [masterVariant];
};

export const mapProductToSparePart = (
  product: ProductCatalogType,
  locale: Locale,
  context: Record<string, boolean> = {},
): SparePartCommerceTools => {
  const { masterData, key, productType } = product;
  const sparePartBase = masterData.current as ProductData;
  const {
    name,
    description,
    masterVariant,
    variants = [],
    categories = [],
  } = sparePartBase;
  const productImages = evaluateToDefault(masterVariant.images, []);
  const { centAmount, currencyCode } = evaluateToDefault(masterVariant.price?.value, {});
  const sku = masterVariant.sku ?? '';

  const sparePartImages = productImages.map((productImage: Image) => ({
    src: productImage.url,
    alt: String(productImage.label),
  }));

  const availabilityState = context[COMMERCETOOLS_AVAILABILITY_FF]
    ? getProductAvailabilityState(sparePartBase, locale)
    : getCurrentAvailabilityState(sparePartBase, locale);

  const filteredVariants: ProductVariant[] = getFilteredVariants(
    variants,
    masterVariant,
    context,
    locale,
  );

  const configurations = mapProductAttributesToConfigs(
    masterVariant,
    filteredVariants,
    locale,
  );

  const { amount, currency } = configurations[0]?.price || {};

  const sparePartPrice: SparePartCommerceTools['price'] = {
    amount: amount ?? centAmount,
    currency: currency ?? currencyCode,
  };

  const sortedVariants = sortVariantsByOrder(filteredVariants);
  const attributes = mapProductAttributesToOptions(sortedVariants, locale);
  const legacyProductId: string = evaluateToDefault(
    getAttributeValueByName(masterVariant.attributesRaw, SparePartKeys.LEGACY_PRODUCT_ID),
    '',
  );

  const sortedByPriceVariants = sortVariantsByPrice(filteredVariants, true);
  const discountPriceBase = sortedByPriceVariants[0]?.price?.discounted?.value;
  const sparePartDiscountedPrice = discountPriceBase && {
    amount: discountPriceBase.centAmount,
    currency: currency ?? currencyCode,
  };

  const displayNameForAllLocales = evaluateToDefault(
    getAttributeValueByName(masterVariant.attributesRaw, SparePartKeys.DISPLAY_NAME),
    {},
  );

  const displayName =
    displayNameForAllLocales[locale] ||
    displayNameForAllLocales[Locale.EnglishUnitedStates];

  const sizeLabelForAllLocales = evaluateToDefault(
    getAttributeValueByName(masterVariant.attributesRaw, SparePartKeys.SIZE_LABEL),
    {},
  );

  const sizeLabel =
    sizeLabelForAllLocales[locale] || sizeLabelForAllLocales[Locale.EnglishUnitedStates];

  return {
    id: legacyProductId,
    name: String(name),
    displayName,
    sizeLabel,
    slug: String(key),
    description: (description as unknown) as string,
    images: sparePartImages,
    attributes,
    price: sparePartPrice,
    discountPrice: sparePartDiscountedPrice,
    productType: String(productType?.key),
    availability: availabilityState,
    variants: configurations,
    categories: categories.map(c => c.id),
    sku,
  };
};
