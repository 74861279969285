export const SITE_NAME = 'Peloton - Pluto';

export const PageContentType = 'page';

export enum PageContentTypes {
  LegalPage = 'pageLegalPage',
  MarketingPage = 'page_marketingPage',
  EcommPage = 'pageEcommPage',
  InstructorMatchResultsPage = 'instructorMatchResultsPage',
  InstructorPage = 'instructorPage',
}

export const pageContentTypesArray = Object.values(PageContentTypes);

export const ComponentContentTypes = {
  Banner: 'componentBanner',
  ComponentJson: 'componentJson',
  Headband: 'componentHeadband',
  ContainedMedia: 'componentContainedMedia',
  Faq: 'componentFaq',
  Forms: 'componentForm',
  Hero: 'component_hero',
  GenericList: 'componentGenericList',
  GenericTextWithMedia: 'componentGenericTextWithMedia',
  Image: 'component_image',
  ImageCards: 'componentImageCardGroup',
  ImageText: 'component_ImageText',
  LegalText: 'componentLegalText',
  LeadGen: 'componentLeadGen',
  LeadGenModal: 'componentLeadGenModal', // Not a Contentful type id - used for Variation purposes
  Overview: 'component_overview',
  Placeholder: 'componentPlaceholder',
  ProductBlock: 'componentProductBlock',
  ProductCard: 'componentProductCard',
  Quiz: 'quiz',
  Reviews: 'componentReviews',
  Section: 'component_section',
  Tabs: 'componentTabs',
  Text: 'component_text',
  TextWithInlineMedia: 'componentTextWithInlineMedia',
  ValueProps: 'component_valueProps',
  Variation: 'variation',
  Video: 'componentVideo',
};

export const fallbackImage = {
  title: 'Thumbnail placeholder',
  url: 'https://dummyimage.com/720x400',
};
