import type { ProductVariant } from '../types.generated';

const comparePrices = (a: ProductVariant, b: ProductVariant): number => {
  return a.price?.value.centAmount - b.price?.value.centAmount;
};

const comparePricesWithDiscounts = (a: ProductVariant, b: ProductVariant): number => {
  const priceA = Math.min(
    a.price?.value.centAmount,
    a.price?.discounted?.value.centAmount,
  );
  const priceB = Math.min(
    b.price?.value.centAmount,
    b.price?.discounted?.value.centAmount,
  );

  return priceA - priceB;
};

export const sortVariantsByPrice = (
  variants: ProductVariant[],
  includeDiscounts = false,
) => {
  return variants.sort(includeDiscounts ? comparePricesWithDiscounts : comparePrices);
};
