import {
  brand,
  Eyebrow,
  Flex,
  Media,
  OverlaidChildren,
  spacing,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { toCtaProps, toMediaProps } from '@page-builder/utils/helpers';
import PlusIcon from '../FeatureCards/PlusIcon';

type CardProps = {
  card: TypeComponentGenericTextWithMedia;
  handleClick: (title: string) => void;
};

export const ContentCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  card,
  handleClick,
}) => {
  const {
    fields: { media: cardMedia, ctas },
  } = card;
  if (!cardMedia || !ctas) return null;
  const mediaProps = toMediaProps(cardMedia);
  const ctaProps = ctas[0] && toCtaProps(ctas[0]);

  return (
    <button onClick={() => handleClick(ctaProps.text)}>
      <MediaOverlay>
        <StyledMedia media={mediaProps} />
        <DropShadow />
        <Flex
          alignItems="flex-end"
          margin={{ mobile: spacing[12], desktop: spacing[24] }}
        >
          <Flex gap={{ mobile: spacing[8], desktop: spacing[12] }} alignItems="center">
            <PlusIcon />
            <Eyebrow size="large" textColor={brand.lightest}>
              {ctaProps.text}
            </Eyebrow>
          </Flex>
        </Flex>
      </MediaOverlay>
    </button>
  );
};

const MediaOverlay = styled(OverlaidChildren)`
  overflow: hidden;
  border-radius: ${spacing[8]};
  ${media.desktopLarge`
    border-radius: ${spacing[12]};
    &:hover {
      svg {
        fill: ${brand.lightest};
        & rect {
          fill: ${brand.darkest};
        }
      }
    }
  `}
`;

const StyledMedia = styled(Media)`
  & picture > img {
    transition: transform 0.4s ease 0s;
    display: block;
    object-fit: cover;
    height: 212px;
    border-radius: ${spacing[8]};
    ${media.tabletXLarge`
      height: 440px;
    `}
    ${media.desktopLarge`
      height: 376px;
      object-fit: cover;
      border-radius: ${spacing[12]};
      &:hover {
        transform: scale(1.03);
        cursor: pointer;
      }
    `}
  }
`;

const DropShadow = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    6deg,
    rgba(0, 0, 0, 0.6) 0%,
    transparent 30%,
    transparent 100%
  );
  border-radius: ${spacing[8]};
  ${media.desktopLarge`
    border-radius: ${spacing[12]};
  `}
`;
