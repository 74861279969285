import type { MuscleGroupScore, BucketByMuscle } from '@peloton/models/PelotonClass';

function buildBucketByMuscle(muscleGroupScores: MuscleGroupScore[]): BucketByMuscle {
  return muscleGroupScores.reduce(
    (acc: BucketByMuscle, { muscleGroup, bucket }: MuscleGroupScore) => {
      if (muscleGroup) {
        acc[muscleGroup] = bucket;
      }
      return acc;
    },
    {},
  );
}

export default buildBucketByMuscle;
