import {
  Container,
  Flex,
  Label,
  Icon,
  spacing,
  grey,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

type PaginationButtonsProps = {
  activeSlide: number;
  totalNumberOfSlides: number;
  handleNextSlide: () => void;
  handlePreviousSlide: () => void;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
};

const PaginationButtons: FC<PaginationButtonsProps> = ({
  activeSlide,
  totalNumberOfSlides,
  handleNextSlide,
  handlePreviousSlide,
  prevButtonDisabled = false,
  nextButtonDisabled = false,
}) => {
  return (
    <Container
      margin={{
        mobile: `${spacing[16]} 0 0 ${spacing[16]}`,
        tablet: `0 ${spacing[32]} 0 auto`,
        desktop: '0 0 0 auto',
      }}
      height={spacing[32]}
      width="126px"
    >
      <Flex display="inline-flex" alignItems="center" justifyContent="flex-end">
        <ChevronButton
          data-test-id="prev-slide-button"
          aria-label="previous"
          onClick={() => handlePreviousSlide()}
          disabled={prevButtonDisabled}
        >
          <Icon height={32} name="carouselArrow" primaryColor={white} />
        </ChevronButton>
        <ChevronButton
          data-test-id="next-slide-button"
          aria-label="next"
          onClick={() => handleNextSlide()}
          disabled={nextButtonDisabled}
        >
          <Icon height={32} name="carouselArrow" rotate={180} primaryColor={white} />
        </ChevronButton>
        <Label
          textColor={white}
          style={{
            textAlign: 'right',
            fontSize: '13px',
            fontWeight: 400,
            width: '38px',
            lineHeight: spacing[16],
            marginLeft: spacing[8],
          }}
        >
          {`${activeSlide + 1} of ${totalNumberOfSlides}`}
        </Label>
      </Flex>
    </Container>
  );
};

const ChevronButton = styled.button`
  border-radius: 50%;
  height: ${spacing[32]};
  margin-right: ${spacing[8]};
  transition: all 0.15s ease-in-out 0s;

  svg {
    opacity: 0.8;
  }

  &:hover {
    cursor: pointer;
    background: ${({ disabled }) => (disabled ? 'transparent' : grey[30])};

    path {
      fill: ${({ disabled }) => (disabled ? grey[30] : grey[90])};
      transition: all 0.15s ease-in-out 0s;
    }
  }

  :disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.5;
    }
  }
`;

export default PaginationButtons;
