import ReactSlick from '@dior/react-slick';
import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { FocusManager } from '@peloton/accessibility';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import { BreakpointWidth, hoverTransition, media } from '@peloton/styles';
import type { BreakpointValues } from '@peloton/styles/breakpoints';
import { useMicroCopy } from '@content/client/microCopy';
import { Chevron, Orientation } from '@ecomm/icons';
import { b6 } from '@ecomm/typography';

// DISCLAIMER: This is a Next compatible copy of @ecomm/carousel/InfiniteScrollCarousel

type Props = {
  arrowPadding?: number;
  arrows?: boolean;
  cellSizes: ResponsiveCellDimensions;
  centerMode?: boolean;
  className?: string;
  dots?: boolean;
  infinite?: boolean;
  name: string;
  responsive?: BreakpointWidthProps[];
  swipe?: boolean;
  tabNumbers?: boolean;
  afterChange?: (index?: number) => void | undefined;
  ariaLabelText?: string;
  currentSlideText?: string;
  speed?: number;
  onInit?: () => void;
};

type SlickSettingsProps = {
  arrows: boolean;
  centerMode: boolean;
  customPaging: (index: number) => JSX.Element;
  dots: boolean;
  infinite: boolean;
  name: string;
  nextArrow: JSX.Element;
  prevArrow: JSX.Element;
  responsive: BreakpointWidthProps[];
  swipe: boolean;
  swipeToSlide: boolean;
  useAriaRole: boolean;
  variableWidth: boolean;
  ariaLabelText?: string;
  currentSlideText?: string;
  speed?: number;
};

type CellDimensions = {
  width: number;
  height: number | string;
  spacing: number;
};

export type ResponsiveCellDimensions = {
  mobile: CellDimensions;
  tablet?: CellDimensions;
  desktop?: CellDimensions;
};

type BreakpointWidthProps = {
  breakpoint: BreakpointValues;
  settings: {
    arrows?: boolean;
    centerMode?: boolean;
    infinite?: boolean;
    swipe?: boolean;
  };
};

const dot = () => <Dot />;
dot.displayName = 'dot';

const getULParent = (el: Element): HTMLElement | null =>
  !el.parentElement || el.parentElement.tagName === 'UL'
    ? el.parentElement
    : getULParent(el.parentElement);

const InfiniteScrollCarousel: React.FC<React.PropsWithChildren<Props>> = ({
  arrowPadding = 0,
  arrows = true,
  cellSizes,
  centerMode = false,
  children,
  className = '',
  dots = false,
  infinite = true,
  name,
  responsive,
  swipe = true,
  tabNumbers = false,
  afterChange = () => {},
  ariaLabelText,
  currentSlideText,
  speed = 300,
  onInit = () => {},
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const previousButtonLabel = useMicroCopy('previousButtonLabel');
  const nextButtonLabel = useMicroCopy('nextButtonLabel');

  const settings: SlickSettingsProps = {
    arrows,
    centerMode,
    dots,
    infinite,
    name,
    swipeToSlide: true,
    swipe,
    useAriaRole: dots, // add aria-role="tabpanel" only when dots are displayed
    variableWidth: true,
    speed,
    prevArrow: (
      <StyledButton value="prev" id={`${name}-prev`}>
        <StyledChevron title={previousButtonLabel} orientation={Orientation.Left} />
      </StyledButton>
    ),
    nextArrow: (
      <StyledButton value="next" id={`${name}-next`}>
        <StyledChevron title={nextButtonLabel} orientation={Orientation.Right} />
      </StyledButton>
    ),
    responsive: responsive || [
      {
        breakpoint: BreakpointWidth.tabletXLarge,
        settings: {
          arrows: false,
        },
      },
    ],
    customPaging: dot,
    ariaLabelText,
    currentSlideText,
  };
  const slideCount = React.Children.count(children);

  // const ariaLabelText = usePromotionalTextData('infiniteScrollCarousel.slidesAriaLabel');
  // const currentSlideText = usePromotionalTextData('infiniteScrollCarousel.currentSlide');
  const slideValues = { currentSlide: currentIndex + 1, totalSlides: slideCount };
  const ariaLabel = useFormattedText(ariaLabelText, slideValues) as string;
  const formattedCurrentSlideText = useFormattedText(currentSlideText, slideValues);

  const handleBeforeChange = (_: number, newIndex: number) => {
    if (tabNumbers) {
      setCurrentIndex(newIndex);
    }
  };

  const [swiped, setSwiped] = React.useState(false);

  const handleSwiped = React.useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleClickCapture: React.MouseEventHandler<HTMLDivElement> = e => {
    if (swiped) {
      e.preventDefault();
      e.stopPropagation();
      setSwiped(false);
    }
  };

  const handleFocusCapture: React.FocusEventHandler<HTMLDivElement> = e => {
    if (settings.infinite) {
      // `e.relatedTarget` is the element which previously had focus
      const relatedTarget = e.relatedTarget instanceof Element ? e.relatedTarget : null;
      const slidesContainer = getULParent(e.target);
      // If the focus came in from outside the slides container
      if (slidesContainer && !slidesContainer.contains(relatedTarget)) {
        // focus the first focusable card, which react-slick
        // helpfully tags with aria-hidden="false"
        const focusableSlide = slidesContainer.querySelector(
          '[aria-hidden=false]',
        ) as HTMLElement;
        FocusManager.passFocusToElement(focusableSlide);
      }
    }
  };

  return (
    <>
      <StyledCarousel
        cellSizes={cellSizes}
        arrowPadding={arrowPadding}
        className={className}
        beforeChange={handleBeforeChange}
        afterChange={afterChange}
        onSwipe={handleSwiped}
        onInit={onInit}
        {...settings}
      >
        {React.Children.map(children, child => (
          <StyledWrapper
            onClickCapture={handleClickCapture}
            onFocusCapture={handleFocusCapture}
          >
            {child}
          </StyledWrapper>
        ))}
      </StyledCarousel>
      {tabNumbers && (
        <TabNumbers aria-live="polite" aria-label={ariaLabel}>
          {formattedCurrentSlideText}
        </TabNumbers>
      )}
    </>
  );
};

export default InfiniteScrollCarousel;

const StyledButton = styled.button`
  position: absolute;
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: calc(50% - 20px);
  width: 40px !important;
  height: 40px !important;
  background-color: ${grey[70]} !important;
  border-radius: 40px;
  transition: all 0.15s ease-in-out 0s;
  opacity: 0.6;

  &[data-focus-method='keyboard']:focus {
    /* Set outline for non-webkit browsers */
    outline: Highlight 2px solid !important;

    /* Sets outline to default for webkit browsers */
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline: -webkit-focus-ring-color auto 5px !important;
    }
  }

  :hover {
    opacity: 0.9;
  }

  ::before,
  ::after {
    content: '' !important;
  }
`;

const StyledChevron = styled(Chevron)`
  width: 18px;
  fill: white;
`;

const StyledCarousel = styled(ReactSlick)<
  Props & {
    beforeChange: (oldIndex: number, newIndex: number) => void;
    children: React.ReactNode;
  }
>`
  max-width: 100%;

  .slick-slide {
    > div > div > div {
      ${({ cellSizes: { mobile, tablet, desktop } }) => css`
        width: ${mobile.width}px !important;
        height: ${mobile.height}px;
        margin: 0 ${mobile.spacing}px;

        ${tablet &&
        css`
          ${media.tablet`
                width: ${tablet.width}px !important;
                height: ${tablet.height}px;
                margin: 0 ${tablet.spacing}px;
              `}
        `};

        ${desktop &&
        css`
          ${media.desktop`
                width: ${desktop.width}px !important;
                height: ${desktop.height}px;
                margin: 0 ${desktop.spacing}px;
              `}
        `};
      `};
    }
  }

  .slick-prev {
    ${props => css`
      left: ${props.arrowPadding}px;
    `};
  }

  .slick-next {
    ${props => css`
      right: ${props.arrowPadding}px;
    `};
  }

  .slick-track {
    ${props => css`
      ${!props.centerMode && `margin-left: 50vw`}
    `}

    ${props =>
      props.responsive!.map(
        ({ breakpoint, settings }: BreakpointWidthProps) =>
          `@media (max-width: ${breakpoint}px) {
            ${settings.centerMode ? `margin-left: 0;` : `margin-left: 50vw;`}
          }`,
      )};

    display: flex;
  }

  .slick-bottom {
    margin: 0 auto;
    width: 50%;
    padding-top: 16px;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
  }

  .slick-active button {
    background-color: ${grey[70]};
  }
`;

const Dot = styled.button`
  background-color: ${grey[50]};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin-left: 8px;
  width: 8px;

  ${hoverTransition({
    property: 'background-color',
    to: grey[70],
  })}
`;

const TabNumbers = styled.p`
  ${b6}
  margin: 0 auto;
  width: 50%;
  padding-top: 16px;
  text-align: center;
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
