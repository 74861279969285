import {
  Eyebrow,
  Headline,
  Media,
  Support,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import type {
  GenericCtaData,
  TypeComponentGenericListFields,
} from '@pelotoncycle/page-builder';
import { toMediaProps } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import { ctaExtractData } from '@page-builder/utils/helpers/cta';
import Markdown from '@page-builder/utils/Markdown';

export const TwoColumnsModal: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ name, items, text }) => {
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const dispatchClickEvent = (cta: GenericCtaData) => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        page: pathName,
        parentType: 'Component: Generic List',
        parent: name,
        unitName: cta.name,
        linkName: cta.text,
        linkTo: cta.url,
      },
    });
  };

  const headline = text?.fields?.headline;

  return (
    <div>
      {/* Heading */}
      {headline && (
        <StyledTitle>
          <Headline as="h2" size="small">
            {headline}
          </Headline>
        </StyledTitle>
      )}

      {/* Cards */}
      <StyledGrid>
        {items?.map((item, idx) => {
          const itemId = item?.sys?.id;
          const itemHeadline = item?.fields?.text?.fields?.headline;
          const itemBody = item?.fields?.text?.fields?.body;
          const itemMediaProps = item?.fields?.media && toMediaProps(item.fields.media);
          const itemCta = ctaExtractData(item?.fields?.ctas?.[0]);

          return (
            <StyledSection key={itemId}>
              {/* Card media */}
              {itemMediaProps && (
                <StyledMedia media={itemMediaProps} width="100%" height="auto" />
              )}
              {/* Card headline */}
              {itemHeadline && (
                <StyledItemHeading>
                  <Eyebrow as="h3">
                    <Markdown content={itemHeadline} />
                  </Eyebrow>
                </StyledItemHeading>
              )}
              {/* Card body */}
              {itemBody && (
                <StyledItemBody>
                  <Support as="p" size="large">
                    <Markdown content={itemBody} />
                  </Support>
                </StyledItemBody>
              )}
              {/* Card CTA */}
              {itemCta && (
                <StyledItemCta>
                  <LinkButton
                    variant={itemCta?.variant}
                    href={itemCta?.url}
                    color={itemCta?.color}
                    text={itemCta?.text}
                    data-test-id={`two-columns-modal-cta-${idx}`}
                    size="small"
                    onClick={() => dispatchClickEvent(itemCta)}
                  />
                </StyledItemCta>
              )}
            </StyledSection>
          );
        })}
      </StyledGrid>
    </div>
  );
};

const StyledTitle = styled.div`
  text-align: center;
  margin-bottom: ${spacing[32]};

  ${media.tablet`
    margin-bottom: ${spacing[32]};
  `}
`;

const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[16]};

  ${media.tablet`
  gap: ${spacing[24]};
  `}
`;

const StyledSection = styled.section`
  width: 100%;
  padding: ${spacing[32]};
  text-align: center;
  color: ${white};
  background-color: ${grey[80]};
  border-radius: 0.5rem;

  ${media.tablet`
    width: calc(50% - ${12 / 16}rem);
  `}
`;

const StyledMedia = styled(Media)`
  aspect-ratio: 1.5;
  background-color: ${grey[90]};

  ${media.desktop`
    aspect-ratio: 1.66;
  `}
`;

const StyledItemHeading = styled.div`
  margin-top: ${spacing[32]};
`;

const StyledItemBody = styled.div`
  margin-top: ${spacing[8]};
`;

const StyledItemCta = styled.div`
  margin-top: ${spacing[24]};

  & a {
    max-width: 100%;
    height: auto;
    padding: ${spacing[8]};
  }
`;
