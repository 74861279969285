import type { EquipmentSlug, Equipment } from '@peloton/models/PelotonClass';
import { ProspectsDiscipline } from '@peloton/models/PelotonClass';
import type { ClassPageLabels } from '../../ClassPage/utils/mapClassPageLabelsFromContentfulToProps';

export type EquipmentWithCfu =
  | Equipment
  | {
      slug: EquipmentSlug | 'bike' | 'row' | 'tread';
      name: string;
    };

export function addCfuToEquipment(
  equipmentTags: Equipment[],
  fitnessDiscipline: ProspectsDiscipline,
  classPageLabels: ClassPageLabels,
): EquipmentWithCfu[] {
  switch (fitnessDiscipline) {
    case ProspectsDiscipline.CYCLING:
      return [
        ...equipmentTags,
        { name: classPageLabels.bikeEquipmentLabel, slug: 'bike' },
      ];
    case ProspectsDiscipline.ROWING:
      return [
        ...equipmentTags,
        { name: classPageLabels.rowerEquipmentLabel, slug: 'row' },
      ];
    case ProspectsDiscipline.RUNNING:
    case ProspectsDiscipline.WALKING:
      return [
        ...equipmentTags,
        { name: classPageLabels.treadEquipmentLabel, slug: 'tread' },
      ];
    default:
      return equipmentTags;
  }
}
