import {
  AVAILABLE,
  UNAVAILABLE_SHOP_SOLD_OUT,
} from '@ecomm/product-states/models/availability';
import { ProductStates } from '@ecomm/product-states/models/productState';
import type { ProductVariant } from '../types.generated';

export const getInventoryAvailability = (variants: ProductVariant[]) => {
  const allVariantsOutOfStock = variants.every(variant => {
    const availabilityResults = variant.availability?.channels.results || [];

    return availabilityResults.some(
      ({ availability }) => availability.availableQuantity === 0,
    );
  });

  if (allVariantsOutOfStock) {
    return {
      code: UNAVAILABLE_SHOP_SOLD_OUT,
      state: ProductStates.UnavailableShopSoldOut,
    };
  }

  return { code: AVAILABLE, state: ProductStates.Available };
};
