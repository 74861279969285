import { Flex, Label, Icon, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import type { FunctionComponentElement } from 'react';
import styled from 'styled-components';
import { CAROUSEL_CLICK_DELAY } from '@page-builder/modules/VideoCarousel/constants';
import { debounceTimer } from '@page-builder/modules/VideoCarousel/utils';
import { useTheme } from '@page-builder/themes/ThemeProvider';

type OverridePaginationStyles = {
  textColorOverride?: string;
  buttonOutlineColorOverride?: string;
  backgroundColorOverride?: string;
  disabledStateColorOverride?: string;
};

export type PaginationProps = {
  activeIndex: number;
  lastItemIndex: number;
  formattedPaginationText: string | FunctionComponentElement<{ children?: any }>;
  handlePrevious: () => void;
  handleNext: () => void;
};

/**
 * This component encapsulates the pagination controls for the MediaCarousel.
 * It includes the previous button, the next button and the pagination text.
 * @param activeIndex the index of the active card in the carousel.
 * @param lastItemIndex the index of the last item in the carousel.
 * @param formattedPaginationText the text that is formatted via rawPaginationText to denote the slide position of the user.
 * @param handlePrevious the function that gets passed into the onClick in the previous button.
 * @param handleNext the function that gets passed into the onClick in the next button.
 * @param textColorOverride color to override the text color. Defaults to textColor from the theme if undefined.
 * @param buttonOutlineColorOverride color to override the background color. Defaults to textColor from the theme if undefined.
 * @param backgroundColorOverride color to override background color of the buttons. Defaults to textColor from the theme if undefined.
 * @param disabledStateColorOverride color to override the disabled state of the buttons. Defaults to a current outline color with a styling opacity of 0.5.
 */
const Pagination = ({
  activeIndex,
  lastItemIndex,
  formattedPaginationText,
  handlePrevious,
  handleNext,
  textColorOverride,
  buttonOutlineColorOverride,
  backgroundColorOverride,
  disabledStateColorOverride,
}: PaginationProps & OverridePaginationStyles) => {
  const { backgroundColor, textColor } = useTheme();
  const buttonBackgroundColor = backgroundColorOverride ?? backgroundColor;
  const outlineColor = buttonOutlineColorOverride ?? textColor;
  const paginationTextColor = textColorOverride ?? textColor;
  const disabledStateColor = disabledStateColorOverride ?? undefined;

  /**
   * Invokes the methods that handle setting the previous or next card item with a click delay to account for smoother transition.
   */
  const debouncedHandleNextItem = debounceTimer(handleNext, CAROUSEL_CLICK_DELAY);
  const debouncedHandlePreviousItem = debounceTimer(handlePrevious, CAROUSEL_CLICK_DELAY);

  return (
    <Flex
      data-test-id="media-carousel-pagination"
      gap={spacing[8]}
      alignItems="center"
      justifyContent="center"
    >
      <ChevronButton
        aria-label="previous"
        onClick={debouncedHandlePreviousItem}
        disabled={activeIndex === 0}
        backgroundColor={buttonBackgroundColor}
        outlineColor={outlineColor}
        disabledStateColor={disabledStateColor ?? outlineColor}
      >
        <Icon height={32} name="carouselArrow" primaryColor={outlineColor} />
      </ChevronButton>
      <ChevronButton
        aria-label="next"
        onClick={debouncedHandleNextItem}
        disabled={activeIndex === lastItemIndex}
        backgroundColor={buttonBackgroundColor}
        outlineColor={outlineColor}
        disabledStateColor={disabledStateColor ?? outlineColor}
      >
        <Icon height={32} name="carouselArrow" rotate={180} primaryColor={outlineColor} />
      </ChevronButton>
      <Label
        data-test-id="pagination-text"
        size="small"
        textColor={paginationTextColor}
        style={{ marginLeft: spacing[8], minWidth: '35px' }}
      >
        {formattedPaginationText}
      </Label>
    </Flex>
  );
};

export default Pagination;

const ChevronButton = styled.button<{
  backgroundColor: string;
  outlineColor: string;
  disabledStateColor: string;
}>`
  border-radius: 50%;
  height: 32px;
  transition: all 0.15s ease-in-out 0s;

  svg {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    background: ${({ disabled, outlineColor }) =>
      disabled ? 'transparent' : outlineColor};

    path {
      fill: ${({ disabled, outlineColor, backgroundColor }) =>
        disabled ? outlineColor : backgroundColor};
      transition: all 0.15s ease-in-out 0s;
    }
  }

  :disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.5;

      // overrides the color of the disabled button
      ${({ disabledStateColor }) =>
        `path {
            fill: ${disabledStateColor};
          }
          circle {
            stroke: ${disabledStateColor};
          }
        `};
    }
  }
`;
