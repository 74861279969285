import type { GenericFormMap } from '@page-builder/utils/maps';
import HistoryReport from '../HistoryReport';
import ValueCalculator from '../ValueCalculator';

const formGenericModules: Partial<GenericFormMap> = {
  ['Value Calculator']: ValueCalculator,
  ['History Report']: HistoryReport,
};

const FormTreatmentMap: Partial<GenericFormMap> = {
  ...formGenericModules,
};

export default FormTreatmentMap;
