import type {
  ContainerProps,
  EyebrowProps,
  FlexProps,
} from '@pelotoncycle/design-system';
import { spacing } from '@pelotoncycle/design-system';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';

type TreatmentOptions = {
  mobileColumnCount: number;
  desktopColumnCount: number;
  maxDesktopColumnCount: number;
  showQuickViewButton: boolean;
  headerAlignment: FlexProps['textAlign'];
  showHeaderDivider: boolean;
  headerGap: FlexProps['gap'];
  desktopHorizontalPadding: number | string;
  verticalPadding: ContainerProps['verticalPadding'];
  hideDriftOnMobile: boolean;
  reduceColumnCount: Record<'desktop' | 'mobile', boolean>;
  minHeight: number;
  mobileHorizontalPadding: number | string;
  taletHorizontalPadding: number | string;
  showSectionDivider: boolean;
  headerPadding: number | string;
  removeOuterContainerTheme: boolean;
  eyebrowHeadingSize: {
    mobile: EyebrowProps['size'];
    desktop: EyebrowProps['size'];
  };
};

const gridTreatmentMap: Record<string, TreatmentOptions> = {
  ['Product Recommendations Upsell Grid']: {
    mobileColumnCount: 2,
    desktopColumnCount: 3,
    maxDesktopColumnCount: 3,
    showQuickViewButton: true,
    headerAlignment: 'center',
    showHeaderDivider: false,
    headerGap: spacing[48],
    desktopHorizontalPadding: 0,
    mobileHorizontalPadding: spacing[16],
    taletHorizontalPadding: spacing[64],
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    hideDriftOnMobile: false,
    reduceColumnCount: {
      desktop: true,
      mobile: false,
    },
    minHeight: 600,
    showSectionDivider: false,
    headerPadding: 0,
    removeOuterContainerTheme: false,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
  },
  ['Deals Page Product Grid']: {
    mobileColumnCount: 2,
    desktopColumnCount: 4,
    maxDesktopColumnCount: 4,
    showQuickViewButton: false,
    headerAlignment: 'left',
    showHeaderDivider: true,
    headerGap: { mobile: spacing[24], desktop: spacing[32] },
    desktopHorizontalPadding: spacing[40],
    mobileHorizontalPadding: spacing[16],
    taletHorizontalPadding: spacing[64],
    verticalPadding: {
      mobile: spacing[24],
      desktop: spacing[40],
    },
    hideDriftOnMobile: true,
    reduceColumnCount: {
      desktop: false,
      mobile: false,
    },
    minHeight: 600,
    showSectionDivider: false,
    headerPadding: 0,
    removeOuterContainerTheme: false,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
  },
  ['Package Upsell In Overview']: {
    mobileColumnCount: 2,
    desktopColumnCount: 2,
    maxDesktopColumnCount: 2,
    showQuickViewButton: false,
    headerAlignment: 'left',
    showHeaderDivider: false,
    headerGap: { mobile: spacing[16], desktop: spacing[16] },
    mobileHorizontalPadding: 0,
    desktopHorizontalPadding: 0,
    taletHorizontalPadding: 0,
    verticalPadding: {
      mobile: `0 ${spacing[24]}`,
      desktop: `0 ${spacing[32]}`,
    },
    hideDriftOnMobile: true,
    reduceColumnCount: {
      desktop: false,
      mobile: false,
    },
    minHeight: 0,
    showSectionDivider: true,
    headerPadding: `${spacing[16]} 0`,
    removeOuterContainerTheme: true,
    eyebrowHeadingSize: {
      mobile: 'medium',
      desktop: 'small',
    },
  },
  ['PLP Product Grid']: {
    mobileColumnCount: 2,
    desktopColumnCount: 4,
    maxDesktopColumnCount: 4,
    showQuickViewButton: false,
    headerAlignment: 'center',
    showHeaderDivider: false,
    headerGap: {
      mobile: spacing[24],
      desktop: spacing[32],
    },
    desktopHorizontalPadding: spacing[40],
    mobileHorizontalPadding: spacing[16],
    taletHorizontalPadding: spacing[64],
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    hideDriftOnMobile: false,
    reduceColumnCount: {
      desktop: true,
      mobile: true,
    },
    minHeight: 600,
    showSectionDivider: false,
    headerPadding: 0,
    removeOuterContainerTheme: false,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
  },
  ['Carousel Product Grid']: {
    mobileColumnCount: 2,
    desktopColumnCount: 4,
    maxDesktopColumnCount: 4,
    showQuickViewButton: false,
    headerAlignment: 'left',
    showHeaderDivider: false,
    headerGap: {
      mobile: spacing[8],
    },
    desktopHorizontalPadding: spacing[40],
    mobileHorizontalPadding: spacing[16],
    taletHorizontalPadding: spacing[64],
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    hideDriftOnMobile: false,
    reduceColumnCount: {
      desktop: false,
      mobile: false,
    },
    minHeight: 300,
    showSectionDivider: false,
    headerPadding: 0,
    removeOuterContainerTheme: false,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
  },
};

type Opts = {
  cohort: ProductRecommendationsCohort;
  products: Product[];
  treatment: TypeComponentGenericListFields['treatment'];
};

const toGridOptions = ({ cohort, products, treatment }: Opts) => {
  const options = gridTreatmentMap[treatment];
  const tileCount = products.length + cohort.marketingTiles.length;

  const desktopColumnCount = options.reduceColumnCount.desktop
    ? Math.min(tileCount, options.desktopColumnCount)
    : options.desktopColumnCount;
  const mobileColumnCount = options.reduceColumnCount.mobile
    ? Math.min(tileCount, options.mobileColumnCount)
    : options.mobileColumnCount;

  return {
    ...options,
    mobileColumnCount,
    desktopColumnCount,
  };
};

export default toGridOptions;
