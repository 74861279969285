import { map, mergeAll, mergeDeepLeft, mergeDeepRight, pipe, prop } from 'ramda';

import type { Shop } from '@ecomm/copy/models.generated';
import type {
  Product as BaseProduct,
  Attribute,
  Option,
} from '@ecomm/graphql/types.generated';
import type { ID } from '@ecomm/models';
import type {
  AccessoryCommerceTools,
  VariantCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { AccessoriesProduct } from '@ecomm/pg-shop-package-configure-graphql/models';
import type { BundlePackage } from '@ecomm/shop-packages/models';
import type {
  ProductPartsFragment as Product,
  PackageBySlugQuery,
} from '@ecomm/shop/graphql/PackageBySlugQuery.generated';
import type {
  Entities,
  WithCount,
  EquipmentBundleType,
  BundleType,
} from '@ecomm/shop/models';
import { toEntitiesWithCount } from '@ecomm/shop/models';
import { bikes, treads, rows } from '@ecomm/shop/sortProducts';

export type ProductWithCountAndShopConfigurationEntities = Entities<ProductWithCountAndShopConfiguration>;
export type ProductWithCountAndShopConfiguration = ProductWithShopConfiguration &
  WithCount;
export type ProductWithShopConfiguration = Product & {
  shopConfiguration: ShopConfiguration;
};

export type AccessoriesProductWithCountAndShopConfiguration = AccessoriesProduct &
  WithCount & { shopConfiguration: ShopConfiguration };

export type NumberOfColumns = {
  mobile: number;
  desktop: number;
};

export type ShopConfiguration = {
  showProductOptionDescription: boolean;
  numberOfColumns: NumberOfColumns;
  component: ConfigurationComponent;
  hasPartnership: boolean;
  isDynamic: boolean;
  requiresAssembly?: boolean;
  descriptor?: Shop;
  hideAttributeSelector?: boolean;
  preselectAllValues?: boolean;
};

export enum ConfigurationComponent {
  SingleSelect = 'singleSelect',
  MultiSelect = 'multiSelect',
  Dropdown = 'dropdown',
}

type ProductSlug = string;
type BundleSlug = string;

export const useToProductWithShopConfigurationEntities = (
  queryProducts: Product[],
  bundleSlug: string,
): Entities<ProductWithCountAndShopConfiguration> => {
  const toShopConfiguration = useToShopConfiguration();
  const productsWithShopConfiguration = pipe<
    Product[],
    ProductWithShopConfiguration[],
    Entities<ProductWithShopConfiguration>[],
    Entities<ProductWithShopConfiguration>
  >(
    map(({ image, slug, ...rest }) => ({
      imageUrls: [image?.src ?? ''],
      slug,
      shopConfiguration: toShopConfiguration(slug, bundleSlug),
      ...rest,
    })),
    map((obj: ProductWithShopConfiguration) => ({ [obj.id]: obj })),
    mergeAll,
  )(queryProducts);

  const productCounts = pipe<Product[], ID[], Entities<WithCount>>(
    map<Product, ID>(prop('id')),
    toEntitiesWithCount,
  )(queryProducts);
  return mergeDeepLeft(productsWithShopConfiguration, productCounts);
};

const toRequiresAssembly = (slug: ProductSlug): boolean =>
  [...bikes, ...treads, ...rows].includes(slug);

export const toDefaultShopConfiguration = (slug: ProductSlug): ShopConfiguration => ({
  showProductOptionDescription: false,
  hasPartnership: false,
  isDynamic: false,
  numberOfColumns: {
    mobile: 1,
    desktop: 1,
  },
  component: ConfigurationComponent.Dropdown,
  requiresAssembly: toRequiresAssembly(slug),
});

export const doesRequireAssembly = (
  products: ProductWithCountAndShopConfiguration[],
): boolean => Boolean(products.find(p => p.shopConfiguration.requiresAssembly));

export const hasDynamicComponent = (shopConfiguration: ShopConfiguration) =>
  shopConfiguration.isDynamic;
const defaultDynamicNumberOfColumns = {
  mobile: 2,
  desktop: 3,
};

type ShopConfigurationEntry = Record<BundleSlug, Partial<ShopConfiguration>>;
const shopConfigurationMap: Record<ProductSlug, ShopConfigurationEntry> = {
  'dumbbells-pkg': {
    default: {
      numberOfColumns: defaultDynamicNumberOfColumns,
      component: ConfigurationComponent.MultiSelect,
      descriptor: 'dumbbells.descriptor',
    },
    ...['us', 'ca', 'de', 'au', 'uk'].reduce(
      (acc, locale) => ({
        ...acc,
        ...{
          [`guide-${locale}`]: {
            numberOfColumns: defaultDynamicNumberOfColumns,
            component: ConfigurationComponent.SingleSelect,
          },
        },
        ...{
          [`guide-ultimate-package-${locale}`]: {
            hideAttributeSelector: true,
            preselectAllValues: true,
          },
        },
      }),
      {},
    ),
  },
  'peloton-weights': {
    default: {
      numberOfColumns: defaultDynamicNumberOfColumns,
      component: ConfigurationComponent.SingleSelect,
    },
  },
  dumbbells: {
    default: {
      numberOfColumns: defaultDynamicNumberOfColumns,
      component: ConfigurationComponent.MultiSelect,
      isDynamic: true,
      descriptor: 'dumbbells.descriptor',
    },
  },
  'heart-rate-band': {
    default: {
      showProductOptionDescription: true,
      component: ConfigurationComponent.SingleSelect,
    },
  },
  'dumbbells-rogue-na': {
    default: {
      numberOfColumns: defaultDynamicNumberOfColumns,
      component: ConfigurationComponent.MultiSelect,
      hasPartnership: true,
      isDynamic: true,
      descriptor: 'dumbbellsRogueNa.descriptor',
    },
  },
};

export const useToShopConfiguration = (): ((
  slug: ProductSlug,
  bundleSlug: string,
) => ShopConfiguration) => {
  return (slug: ProductSlug, bundleSlug: BundleSlug) => {
    const productConfig = shopConfigurationMap[slug];
    const defaultConfig = toDefaultShopConfiguration(slug) || {};
    const bundleDefault = productConfig ? productConfig['default'] || {} : {};
    const bundleConfig = productConfig ? productConfig[bundleSlug] || {} : {};

    const config = mergeDeepRight(
      mergeDeepRight(defaultConfig, bundleDefault),
      bundleConfig,
    );
    return config;
  };
};

export type CfuAttributes = (Pick<Attribute, 'name' | 'slug'> & {
  options: Option[];
})[];

export type CfuVariant = Pick<VariantCommerceTools, 'price' | 'configurations'> & {
  name: string;
  slug: string;
  description: string;
  skus: string[];
  id: string;
};

export type CfuProductItem = Pick<
  BaseProduct,
  'id' | 'name' | 'slug' | 'description' | 'image' | 'availability' | 'price'
> & {
  attributes: CfuAttributes;
  variants: CfuVariant[];
};

export type CfuCartModel = {
  upsellIds: [productId: string, productOptionId?: string][];
  upsellBundles: { bundleId: string; productOptions: string[] }[];
};

export type CfuCartAnalyticsModel = {
  bundleType: EquipmentBundleType | BundleType;
  bundlePackage: BundlePackage;
  hasAccessory?: boolean;
  hasAccessoryBundle?: boolean;
  accessoryName?: string;
  addedFromCart?: boolean;
  hasGuide?: boolean;
  hasOPC?: boolean;
  hasCPO?: boolean;
  cartId: string;
};

export type CommercetoolsPackageQuery = {
  catalog: {
    packageBySlug: CommercetoolsPackage;
  };
};

export type CommercetoolsPackage = PackageBySlugQuery['catalog']['packageBySlug'] & {
  upsells: AccessoryCommerceTools[];
  subscriptionId?: string;
  discountPrice?: BaseProduct['price'];
};

export type CommercetoolsPrepaidSubscription = {
  variants?: VariantCommerceTools[];
};
