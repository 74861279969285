import type { LinkButtonProps } from '@pelotoncycle/design-system';
import {
  Container,
  spacing,
  brand,
  Eyebrow,
  Headline,
  Flex,
  SingleSelect,
  grey,
} from '@pelotoncycle/design-system';
import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/internationalize-ui/components/LinkButton';
import { media } from '@peloton/styles';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import { CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';

export type Images = {
  mobile: string;
  tablet: string;
  desktop: string;
};

type Item = { value: string; option: string };

export type Select = {
  label: string;
  items: Item[];
};

export type Cta = {
  text: string;
  url: string;
  variant?: LinkButtonProps['variant'];
  color?: LinkButtonProps['color'];
};

type Props = {
  backgroundImages: Images;
  headline: ReactNode;
  eyebrow: ReactNode;
  select: Select;
  cta: Cta;
  id: string;
};

const ValueCalculator: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundImages: { mobile, tablet, desktop },
  eyebrow,
  headline,
  select,
  cta,
  id,
}) => {
  const slug = 'household-size';
  const testIdPrefix = 'value_calc';

  const { trackLinkClick } = useTrackLinkClick();

  const [selectedItem, setSelectedItem] = React.useState<Item | undefined>(
    select.items[1],
  );

  React.useEffect(() => {
    sessionStorage.setItem(slug, '2');
  }, [slug]);

  function handleSelectedItemChange(item: any) {
    setSelectedItem(item);
    if (item !== undefined) {
      sessionStorage.setItem(slug, item.value);
    }
  }
  return (
    <Flex
      verticalPadding={{ mobile: 0, desktop: `0 ${spacing[64]}` }}
      horizontalPadding={{ desktop: spacing[40] }}
      backgroundColor={grey[30]}
      justifyContent="center"
      id={id}
    >
      <StyledBackgroundContainer
        backgroundColor={grey[90]}
        verticalPadding={{
          mobile: spacing[64],
          tablet: spacing[64],
        }}
        horizontalPadding={{ mobile: spacing[32], tablet: spacing[64] }}
        maxWidth={{ desktop: CONTAINER_MAXWIDTH }}
        mobile={mobile}
        tablet={tablet}
        desktop={desktop}
        borderRadius={{ desktop: spacing[12] }}
      >
        <Flex
          gap={spacing[16]}
          flexDirection="column"
          margin={`0 0 ${spacing[32]}`}
          alignItems="center"
          textAlign="center"
        >
          <Eyebrow
            textColor={brand.lightest}
            size="medium"
            data-test-id={`${testIdPrefix}_heading`}
          >
            {eyebrow}
          </Eyebrow>
          <Headline
            textColor={brand.lightest}
            size="small"
            data-test-id={`${testIdPrefix}_copy`}
          >
            {headline}
          </Headline>
        </Flex>
        <Flex
          gap={spacing[16]}
          justifyContent="center"
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          maxWidth={{ mobile: '260px', tablet: 'unset' }}
          centered
        >
          <StyledOverride>
            <SingleSelect
              width="260px"
              theme="dark"
              label={select.label}
              handleSelectedItemChange={handleSelectedItemChange}
              selectedItem={selectedItem}
              style={{ textAlign: 'unset' }}
              items={select.items}
            />
          </StyledOverride>
          <StyledLinkButton>
            <LinkButton
              width={{ mobile: 'adaptive', tablet: 'adjustable' }}
              href={cta.url}
              variant={cta.variant}
              color={cta.color}
              isDisabled={selectedItem === undefined}
              onClick={() => {
                sessionStorage.setItem(slug, selectedItem?.value || '2');
                trackLinkClick({
                  href: cta.url,
                  parent: 'valueCalculatorEntryPoint',
                  additionalProps: {
                    parentType: 'HeaderBodyImage',
                    unitName: 'ValueCalcEntryPointCta',
                    linkName: cta.text,
                    step: 1,
                    stepName: select.label,
                    input: selectedItem?.option,
                  },
                });
              }}
              text={cta.text}
            />
          </StyledLinkButton>
        </Flex>
      </StyledBackgroundContainer>
    </Flex>
  );
};

export default ValueCalculator;

type BackgroundProps = {
  mobile: string;
  tablet: string;
  desktop: string;
};

const StyledBackgroundContainer = styled(Container)<BackgroundProps>`
  ${({ mobile }) => mobile && `background-image: url(${mobile})`};

  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;

  ${media.tabletXLarge<BackgroundProps>`
    ${({ tablet }) => tablet && `background-image: url(${tablet})`};
    background-position: bottom left;
  `}

  ${media.desktopLarge<BackgroundProps>`
    ${({ desktop }) => desktop && `background-image: url(${desktop})`};
    background-size: cover;
  `}
`;

const StyledLinkButton = styled.div`
  a {
    position: unset !important;
  }
`;

const StyledOverride = styled.div`
  [role='listbox'] {
    & > :first-of-type {
      opacity: 0.5;
    }
  }
`;
