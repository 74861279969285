import React from 'react';
import DownloadRedirect from '@ecomm/app-download/DownloadRedirect';
import type { BlockProps } from '@page-builder/lib/pages';
import type { TypeComponentPlaceholder } from '@page-builder/lib/types';
import { CompleteYourCartProductRecommendations } from '../ProductRecommendations';
import { HideDriftChatAlways, HideDriftChatMobile } from './Views/HideDriftChat';

type Props = TypeComponentPlaceholder & BlockProps;

const NextPlaceholder: React.FC<React.PropsWithChildren<Props>> = ({
  fields: { name },
}) => {
  switch (name) {
    /**
     * CHAMP Placeholders
     */
    case 'Hide Drift Chat':
      return <HideDriftChatAlways />;
    case 'Hide Drift Chat Mobile':
      return <HideDriftChatMobile />;
    case 'Complete Your Cart Product Recommendations':
      return <CompleteYourCartProductRecommendations />;
    case 'App Download Redirect':
      return <DownloadRedirect />;
    default:
      return null;
  }
};

export default NextPlaceholder;
