import type { ButtonProps as BaseButtonProps } from '@pelotoncycle/design-system';
import { Button as BaseButton } from '@pelotoncycle/design-system';
import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { LinkButton as BaseLinkButton } from '@ecomm/internationalize-ui';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import { useLoadMoreContext } from './LoadMoreContext';

export type ButtonProps = {
  label: string;
  loadingLabel: string;
} & BaseButtonProps;

export type ButtonMouseEvent = React.MouseEvent<
  HTMLButtonElement | HTMLAnchorElement,
  MouseEvent
>;

export const LoadMoreButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  label,
  loadingLabel,
  ...remainingProps
}) => {
  const {
    isLoading,
    isLoadingNext,
    hasNext,
    incrementPage,
    nextPageHref,
  } = useLoadMoreContext();

  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const trackClick = useCallback(
    (name: string, url: string) => {
      trackEvent({
        event: 'Clicked Show More Classes',
        properties: {
          parentType: 'Component: CTA',
          parent: 'Component: CTA',
          unitName: 'Load More Button',
          linkName: name,
          page: pathName,
          linkTo: url,
        },
      });
    },
    [pathName, trackEvent],
  );

  if (!hasNext) {
    return null;
  }

  const handleClick = (name: string, href: string = '') => (e: ButtonMouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    trackClick(name, href);
    incrementPage();
  };

  const buttonLabel = isLoadingNext ? loadingLabel : label;
  const ariaLive = isLoadingNext ? 'assertive' : 'off';

  return nextPageHref ? (
    <BaseLinkButton
      {...remainingProps}
      href={nextPageHref}
      role="button"
      onClick={handleClick(buttonLabel, nextPageHref)}
      isDisabled={isLoading}
      aria-live={ariaLive}
    >
      {buttonLabel}
    </BaseLinkButton>
  ) : (
    <BaseButton
      {...remainingProps}
      onClick={handleClick(buttonLabel)}
      isDisabled={isLoading}
      aria-live={ariaLive}
    >
      {buttonLabel}
    </BaseButton>
  );
};
