import React from 'react';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import Banner from '@page-builder/modules/Banner';
import type { PromoProps, CfuPromoProps } from '../types';

const PromoBanner: React.FC<React.PropsWithChildren<PromoProps>> = ({ cfu }) => {
  if (cfu) {
    return <CfuPromo cfu={cfu} />;
  }

  return null;
};

export default PromoBanner;

const CfuPromo: React.FC<React.PropsWithChildren<CfuPromoProps>> = ({ cfu }) => {
  const { data } = useCfuPromo(cfu);
  const banner = data?.fields.banner;
  if (!banner || data?.fields.hideBanner) return null;
  return <Banner data={banner} />;
};
