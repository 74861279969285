import { mapDisciplineToDisplayName } from '@peloton/internationalize/consolidatedAppConfigs/ecomm/classes/mapDisciplineToDisplayName';
import type { Locale } from '@peloton/internationalize/models/locale';
import type { ApiClass, Class } from '@peloton/models/PelotonClass';
import { parseContent } from '@content/client/lib/parseContent';
import { fetchClassData } from '../api/fetchClassData';
import { mapDiscipline } from './mappers/mapDiscipline';
import { mapEquipmentTags } from './mappers/mapEquipmentTags';

type ProspectsApiResponse = {
  slug: string;
  id: string;
  classData: Record<Locale, ApiClass>;
};

export const fetchClassContent = async (
  classSlug: string,
  locale: Locale,
  disciplineSlug?: string,
  isCLP?: boolean,
): Promise<Class> => {
  const classContentData: ProspectsApiResponse = await fetchClassData(
    classSlug,
    locale,
    disciplineSlug,
    isCLP,
  );
  const localeClassData = mapApiClassData(classContentData, locale);
  const slugIsNotClassId = classSlug !== localeClassData.id;

  if (slugIsNotClassId) {
    localeClassData.slug = classSlug;
  }

  return parseContent(localeClassData);
};

const mapApiClassData = (apiData: ProspectsApiResponse, locale: Locale): Class => {
  const localeClassData = apiData.classData[locale as Locale];
  const equipmentTags = mapEquipmentTags(localeClassData.equipmentTags);
  const fitnessDiscipline = mapDiscipline(
    localeClassData.fitnessDiscipline,
    localeClassData.browseCategories?.map(browseCategory => browseCategory.slug),
  );
  const fitnessDisciplineDisplayName = mapDisciplineToDisplayName(
    fitnessDiscipline,
    locale,
  );

  const dataWithAdjustments = {
    ...localeClassData,
    fitnessDiscipline,
    fitnessDisciplineDisplayName,
    equipmentTags,
    slug: apiData.slug,
  };

  return dataWithAdjustments;
};
