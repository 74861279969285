import toBundleType from '@ecomm/graphql-bridge/EquipmentType/toBundleType';
import type { EquipmentType } from '@ecomm/graphql/types.generated';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import type { PackageFieldsFragment } from '@ecomm/product-recommendations/queries/AllProducts.generated';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration';

type CfuPackage = CommercetoolsPackage | PackageFieldsFragment;

export const toProductFromCfuPackage = (pkg: CfuPackage): Product => {
  return {
    productType: 'cfu',
    slug: pkg.slug,
    name: pkg.name,
    description: pkg.description,
    imageUrl: pkg.connectedFitnessUnit.image!.src,
    // TODO: Update CT mapper to return package images
    images: [pkg.connectedFitnessUnit.image!.src],
    price: pkg.price.amount,
    bundleType: toBundleType(pkg.equipmentType as EquipmentType)!,
    packageId: pkg.id,
    cfuProductSlug: pkg.connectedFitnessUnit.slug,
    warrantySlug: pkg.warranty.slug,
    id: pkg.connectedFitnessUnit.id,
    // TODO: Support configurable CFU packages
    isConfigurable: false,
    attributes: [],
    variants: [],
  };
};
