import { grey, white, lime, red, black } from '@pelotoncycle/design-system';
import React, { createContext } from 'react';

export const LIGHT: 'Light' = 'Light';
export const DARK: 'Dark' = 'Dark';

export type ThemeProps = {
  name: typeof LIGHT | typeof DARK;

  modalBackgroundColor: string;
  modalTextColor: string;
  modalCtaTextColor: string;
  modalIconPrimaryColor: string;
  modalIconSecondaryColor: string;
  modalDividerColor: string;

  productCardBackgroundColor: string;
  productCardAndHeaderTextColor: string;
  productCardActiveBorderColor: string;
  productCardInactiveBorderColor: string;

  toggleActiveBorderColor: string;
  toggleInactiveBackgroundColor: string;
  toggleInactivePrimaryColor: string;
  toggleInactiveSecondaryColor: string;

  secondaryTextColor: string;
  secondaryBackgroundColor: string;
  badgeColor: string;
  highlightColor: string;
  checkoutCtaColor: string;
  promoBackgroundColor: string;
};
type Themes = Record<typeof LIGHT | typeof DARK, ThemeProps>;

export const themeMap: Themes = {
  Light: {
    name: LIGHT,

    modalBackgroundColor: white,
    modalTextColor: grey[90],
    modalCtaTextColor: grey[90],
    modalIconPrimaryColor: grey[80],
    modalIconSecondaryColor: white,
    modalDividerColor: grey[40],

    productCardBackgroundColor: white,
    productCardAndHeaderTextColor: grey[90],
    productCardInactiveBorderColor: grey[50],
    productCardActiveBorderColor: black,

    toggleActiveBorderColor: grey[90],
    toggleInactiveBackgroundColor: grey[30],
    toggleInactivePrimaryColor: grey[90],
    toggleInactiveSecondaryColor: grey[70],

    secondaryTextColor: grey[80],
    secondaryBackgroundColor: grey[30],
    badgeColor: lime[50],
    highlightColor: red[80],
    checkoutCtaColor: red[80],
    promoBackgroundColor: grey[30],
  },
  Dark: {
    name: DARK,

    modalBackgroundColor: grey[90],
    modalTextColor: white,
    modalCtaTextColor: white,
    modalIconPrimaryColor: white,
    modalIconSecondaryColor: grey[90],
    modalDividerColor: grey[70],

    productCardBackgroundColor: black,
    productCardAndHeaderTextColor: white,
    productCardInactiveBorderColor: grey[60],
    productCardActiveBorderColor: white,

    toggleActiveBorderColor: grey[40],
    toggleInactiveBackgroundColor: `rgba(255, 255, 255, 0.15)`,
    toggleInactivePrimaryColor: grey[40],
    toggleInactiveSecondaryColor: lime[50],

    secondaryTextColor: grey[50],
    secondaryBackgroundColor: grey[50],
    badgeColor: lime[50],
    highlightColor: lime[50],
    checkoutCtaColor: red[80],
    promoBackgroundColor: grey[90],
  },
};
export const ThemeContext = createContext(themeMap.Light);
export const ThemeProvider: React.FC<
  React.PropsWithChildren<{ theme: typeof LIGHT | typeof DARK }>
> = ({ theme, children }) => {
  return (
    <ThemeContext.Provider value={themeMap[theme]}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
