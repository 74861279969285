import { spacing, Icon, grey, Flex } from '@pelotoncycle/design-system';
import type { TagLink } from 'contentful';
import React from 'react';
import styled, { css } from 'styled-components';
import useTagHandler from '@page-builder/hooks/useTagHandler';
import type { TypeComponentCtaFields } from '@page-builder/lib/types';
import { OverviewTooltip } from '../OverviewTooltip/OverviewTooltip';
import { Text } from './ValuePropText';

type FormattedCtaProps = Pick<
  TypeComponentCtaFields,
  'name' | 'text' | 'ctaIcon' | 'modal' | 'link'
> & {
  tags: TagLink[] | string[];
};

export type ClickEventProps = {
  properties: {
    href?: string;
    parentType: string;
    parent?: string;
    unitName: string;
    linkName?: string;
    linkTo?: string;
  };
};

type Props = {
  parentName?: string;
  variant: 'body' | 'primary';
  size: 'small' | 'large';
  formattedCta: FormattedCtaProps;
  handleClick: (isModal: boolean, index: number, eventProps: ClickEventProps) => void;
  index: number;
};

const ValuePropsFormattedCta: React.FC<React.PropsWithChildren<Props>> = ({
  parentName,
  variant,
  size,
  formattedCta,
  handleClick,
  index,
}) => {
  const { name, text, ctaIcon, modal, link, tags } = formattedCta;
  const tooltip = modal?.fields.text?.fields.label;
  const { resolvedStatus } = useTagHandler(tags);

  if (!resolvedStatus) return null;

  let valueProp;
  const eventProps = {
    properties: {
      href: link?.fields.url,
      parentType: 'Component: Overview',
      parent: 'Overview Module',
      unitName: 'Value Props CTA Link Click',
      linkName: text,
      linkTo: link?.fields.url,
    },
  };
  const textProps = {
    text,
    size,
    variant,
  };
  if (link) {
    valueProp = (
      <LinkTrigger
        className="ds__link"
        onClick={() => handleClick(false, index, eventProps)}
        href={link?.fields.url}
      >
        <Text textProps={textProps} isInteractive />
      </LinkTrigger>
    );
  } else if (modal) {
    valueProp = (
      <ButtonTrigger
        className="ds__link"
        onClick={() => handleClick(true, index, eventProps)}
      >
        <Text textProps={textProps} isInteractive />
      </ButtonTrigger>
    );
  } else {
    valueProp = <Text textProps={textProps} />;
  }

  const gap = {
    body: {
      small: 8,
      large: 12,
    },
    primary: {
      small: 16,
      large: 16,
    },
  }[variant][size];

  const iconHeight = {
    body: {
      large: 24,
      small: 16,
    },
    primary: {
      small: 24,
      large: 24,
    },
  }[variant][size];

  return (
    <Flex key={name} alignItems="center" as="li" gap={spacing[gap]}>
      {ctaIcon && (
        <Icon
          name={ctaIcon}
          height={iconHeight}
          primaryColor={grey[90]}
          style={{ flex: `0 0 ${spacing[24]}` }}
        />
      )}
      <Flex alignItems="center">
        {valueProp}
        {tooltip && (
          <OverviewTooltip tooltip={tooltip} data-test-id="value-prop-tooltip" />
        )}
      </Flex>
    </Flex>
  );
};

export default ValuePropsFormattedCta;

const triggerSharedStyles = css`
  line-height: 0;

  &:focus {
    outline: none;
  }
`;

const ButtonTrigger = styled.button`
  ${triggerSharedStyles};
`;

const LinkTrigger = styled.a`
  ${triggerSharedStyles};
`;
