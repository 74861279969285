import type { LocaleLanguagePrefix } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models/locale';
import type { FilteredPageLabels } from '../hooks/useFilteredPageLabels';

export const applySentenceCasing = (word: string, index: number) => {
  if (index === 0 || word === 'Pilates') {
    return word;
  } else {
    return word!.toLowerCase();
  }
};

export const checkDisciplineInClassType = (
  classTypeLabel: string | null,
  disciplineLabel: string,
) => {
  const sentence = classTypeLabel?.toLowerCase();
  const words = disciplineLabel.toLowerCase().split(' ');

  return words.some(word => {
    const sentenceWords = sentence?.split(' ');
    return sentenceWords?.some(sentenceWord => word.includes(sentenceWord));
  });
};

export const toFilteredPageTitle = ({
  filteredPageLabels,
  locale,
}: {
  filteredPageLabels: FilteredPageLabels;
  locale: Locale;
}) => {
  const durationLabel = filteredPageLabels.durationLabel;
  const classTypeLabel = filteredPageLabels.classTypeLabel;
  const disciplineLabel = filteredPageLabels.disciplineLabel;
  const disciplineClassLabel = filteredPageLabels.disciplineClassLabel;

  const languageLocale = locale.split('-')[0] as LocaleLanguagePrefix;
  const discipline = checkDisciplineInClassType(classTypeLabel, disciplineLabel)
    ? ''
    : disciplineLabel;

  if (languageLocale === 'de') {
    return [durationLabel, disciplineClassLabel, classTypeLabel]
      .filter(Boolean)
      .join(' ');
  }

  return [
    durationLabel?.replace(/minutes/gi, 'minute'),
    classTypeLabel,
    discipline,
    'classes',
  ]
    .filter(Boolean)
    .map(applySentenceCasing)
    .join(' ');
};
