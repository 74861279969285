import {
  BreakpointWidths,
  Flex,
  Toggle,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import type { TypeComponentProductBlockFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import { ANNUAL, MONTHLY } from './CheckoutContext';
import type { DARK } from './themes';
import { LIGHT, useTheme } from './themes';

export type Props = {
  handleToggleChange: () => void;
  annualPromotion?: TypeComponentProductBlockFields['annualPromotion'];
  monthlyPromotion?: TypeComponentProductBlockFields['monthlyPromotion'];
};

const ProductComparisonCardsToggle: React.FC<React.PropsWithChildren<Props>> = ({
  handleToggleChange,
  annualPromotion,
  monthlyPromotion,
}) => {
  const monthlyText = useMicroCopy(MONTHLY);
  const annualText = useMicroCopy(ANNUAL);

  const {
    name: themeName,
    toggleActiveBorderColor,
    toggleInactiveBackgroundColor,
    toggleInactivePrimaryColor,
    toggleInactiveSecondaryColor,
  } = useTheme();

  return (
    <ToggleContentsFlex centered>
      <StyledToggle
        data-test-id="product-comparison-toggle"
        themeName={themeName}
        backgroundColor={toggleInactiveBackgroundColor}
        inactiveEyebrowColor={toggleInactivePrimaryColor}
        inactiveLabelColor={toggleInactiveSecondaryColor}
        toggleActiveBorderColor={toggleActiveBorderColor}
        leftButton={{
          eyebrowText: monthlyText,
          labelText: monthlyPromotion,
        }}
        rightButton={{
          eyebrowText: annualText,
          labelText: annualPromotion,
        }}
        onChange={handleToggleChange}
      />
    </ToggleContentsFlex>
  );
};

const ToggleContentsFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BreakpointWidths.tablet}px) {
    min-width: 392px;
    max-width: 392px;
  }
`;

export default ProductComparisonCardsToggle;

const StyledToggle = styled(Toggle)<{
  themeName: typeof LIGHT | typeof DARK;
  toggleActiveBorderColor: string;
}>`
  width: 100%;
  border: ${({ themeName }) =>
    themeName === LIGHT ? `border 1px solid ${grey[40]}` : 'unset'};
  backdrop-filter: ${({ themeName }) =>
    themeName === LIGHT ? 'blur(12.5px)' : 'blur(7px)'};

  div {
    padding: 0;
    height: ${spacing[64]};
    justify-content: center;
  }

  :after {
    border: ${({ themeName }) => (themeName === LIGHT ? '2px' : '1px')} solid
      ${({ toggleActiveBorderColor }) => toggleActiveBorderColor};
    border-radius: 3.125rem;
    box-shadow: unset;
  }

  .toggle-eyebrow {
    margin: 0;
  }

  // changes the secondary text color of the active toggle
  [aria-checked='true'] {
    // box-shadow: 0px 16px 40px 4px rgba(0, 0, 0, 0.05);

    .toggle-label {
      color: ${grey[90]};
    }
  }

  border-radius: 3.125rem;
`;
