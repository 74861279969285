import { Headline, white, black, spacing, Flex } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '@peloton/styles';
import { useTheme } from '../utils/theme';

type Props = {
  loadingPageText?: string;
};

const Loading: React.FC<React.PropsWithChildren<Props>> = ({ loadingPageText }) => {
  const { questionBackground } = useTheme();

  return (
    <Content background={questionBackground}>
      {loadingPageText && (
        <Headline textColor={white} size="small">
          {loadingPageText}
        </Headline>
      )}
      <Flex style={{ width: '300px' }}>
        <LoadingBarShadow />
        <LoadingBar data-test-id="loading-page-bar" />
      </Flex>
    </Content>
  );
};

// 40 headline, 5 bar, 80 padding
const LOADING_CONTENT_HEIGHT = 125;

const LoadingBarShadow = styled.div`
  height: 5px;
  border-radius: ${spacing[2]};
  background: ${rgba(black, 0.25)};

  width: 100%;

  margin-top: ${spacing[64]};
  ${media.desktop`
    margin-top: ${spacing[80]};
  `};
  z-index: 1;
`;

const animateWidth = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 300px;
  }
`;

const LoadingBar = styled.div`
  position: absolute;
  height: 5px;
  border-radius: ${spacing[2]};
  background: ${white};

  animation: 4s ${animateWidth} linear;
  animation-fill-mode: forwards;

  margin-top: ${spacing[64]};
  ${media.desktop`
    margin-top: ${spacing[80]};
  `};
  z-index: 2;
`;

const Content = styled(Flex)<{ background: string }>`
  background: ${props => props.background};
  padding: ${spacing[80]} 0;
  min-height: calc(100vh - ${LOADING_CONTENT_HEIGHT}px);

  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export default Loading;
