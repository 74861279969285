import { BreakpointWidths } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';
import { driftSelector } from '@peloton/drift/ChatStyles';

export const HideDriftChatAlways = createGlobalStyle`
  body ${driftSelector} {
    display: none !important;
  }
`;

export const HideDriftChatMobile = createGlobalStyle`
  @media (max-width: ${BreakpointWidths.tablet}px) {
    body ${driftSelector} {
      display: none !important;
    }
  }
`;
