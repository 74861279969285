import type Contentful from 'contentful';
import type { TagLink, Entry } from 'contentful';
import type {
  TypeComponentCta,
  TypeComponentCtaFields,
  TypeComponentGenericTextFields,
} from '@page-builder/lib/types';

export const getContentfulAssetUrl = (asset?: Contentful.Asset): string =>
  asset ? asset.fields?.file?.url : '';

export const getContentfulAssetDescription = (asset?: Contentful.Asset): string =>
  asset ? asset.fields?.description : '';

type DeepNonNullable<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

const getExpectedTextField = (
  fields: TypeComponentGenericTextFields,
  key: string,
): string => {
  const value = fields[key];
  if (value) {
    return value;
  }
  throw new Error(`Missing expected text field: ${key} in Contentful`);
};

// Get all text fields but require some
export const getTextFieldsWithRequiredKeys = <
  T extends TypeComponentGenericTextFields,
  U extends Contentful.Entry<T>,
  V extends keyof TypeComponentGenericTextFields,
  W extends V[]
>(
  fields: W,
  text?: U,
) => {
  if (!text?.fields) {
    throw new Error('Missing text from Contentful');
  }

  // Create types based on expected keys from fields parameter
  type Keys = typeof fields[number];
  type RequiredFields = Pick<TypeComponentGenericTextFields, Keys>;
  type OptionalFields = Omit<TypeComponentGenericTextFields, Keys>;
  type ValidRequiredFields = DeepNonNullable<RequiredFields>;
  type ReturnValue = OptionalFields & ValidRequiredFields;

  const defaults: DeepNonNullable<TypeComponentGenericTextFields> = {
    name: '',
    eyebrow: '',
    headline: '',
    body: '',
    support: '',
    label: '',
  };

  const validFields = ['name', 'eyebrow', 'headline', 'body', 'support', 'label'];

  // If field is required, run a check and return the string value
  // If field is not required, just apply the default value (could be string or undefined)
  const requiredFields = validFields.reduce(
    (acc, curr: Keys) => ({
      ...acc,
      [curr]: fields.includes(curr)
        ? getExpectedTextField(text.fields, curr)
        : text.fields[curr],
    }),
    defaults,
  );

  return (requiredFields as unknown) as ReturnValue;
};

export const toCtaFields = (ctas: TypeComponentCta[]) =>
  ctas.reduce(
    (
      acc,
      {
        fields: {
          name,
          text,
          modal,
          link,
          color,
          variant,
          lightboxVideo,
          driftInteractionId,
          ctaIcon,
          styledAs,
          productSlug,
        },
        metadata: { tags },
      },
    ) => {
      return [
        ...acc,
        {
          name,
          text,
          modal,
          link,
          color,
          variant,
          lightboxVideo,
          driftInteractionId,
          ctaIcon,
          styledAs,
          productSlug,
          tags,
        },
      ];
    },
    [],
  );

export type CtaFieldType = 'link' | 'lightboxVideo' | 'driftInteractionId';

export const hasCtaFields = (
  field: CtaFieldType,
  ctas?: Contentful.Entry<TypeComponentCtaFields>[],
): boolean => {
  return Object.hasOwnProperty.call(ctas?.[0].fields ?? {}, field);
};

export const getCtaFields = (
  field: CtaFieldType,
  ctas?: Contentful.Entry<TypeComponentCtaFields>[],
) => {
  const requiredPrimaryField = ctas?.[0].fields[field];

  if (!ctas?.[0].fields || !requiredPrimaryField) {
    throw new Error(`Missing required field '${field}' from Contentful`);
  }

  const primaryCta = {
    ...ctas?.[0].fields,
  };

  const requiredSecondaryField = ctas?.[1]?.fields[field];
  let secondaryCta = undefined;

  if (ctas[1] && requiredSecondaryField) {
    secondaryCta = {
      ...ctas[1].fields,
    };
  }

  return {
    primaryCta,
    secondaryCta,
  };
};

export const convertTags = (tags: string[] | TagLink[]) =>
  tags.map(tag => {
    if (typeof tag === 'string') {
      return tag;
    }
    if (typeof tag === 'object') {
      return tag.sys.id;
    }
    return '';
  });

export const toEntryTags = (entry: Entry<any>): string[] =>
  convertTags(entry.metadata.tags);
