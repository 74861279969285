import {
  Body,
  Headline,
  Label,
  spacing,
  Flex,
  Layout,
  Media,
  grey,
  Container,
} from '@pelotoncycle/design-system';
import { OuterContainer, Columns, toMediaProps } from '@pelotoncycle/page-builder';
import type { TypeComponentGenericTextWithMediaFields } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@peloton/copy/Markdown';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { containedProps } from './types';

export type Props = TypeComponentGenericTextWithMediaFields;

const defaults = {
  theme: 'White',
};

const FiftyFiftySteps: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ media, theme = defaults.theme, text }) => {
  const mediaProps = media && toMediaProps(media, true);
  const { backgroundColor, textColor, headlineColor } = themes[theme];
  const { maxWidth, gap, horizontalPadding, verticalPadding } = containedProps;
  const { body } = getTextFieldsWithRequiredKeys(['body'], text);

  if (!mediaProps) {
    throw new Error('Missing media');
  }

  return (
    <OuterContainer
      maxWidth={maxWidth}
      backgroundColor={backgroundColor}
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
    >
      <Columns reverse={true} gap={gap}>
        <MediaContainer>{media && <Media media={mediaProps} />}</MediaContainer>
        <Layout verticalAlignment="center" horizontalAlignment="center">
          <Container
            horizontalMargin={spacing[16]}
            style={{
              maxWidth: 454,
            }}
          >
            <Markdown
              content={body}
              markdown={{
                renderers: {
                  heading:
                    // eslint-disable-next-line react/display-name
                    ({ node, ...props }) => {
                      const value = props.children[0]?.props?.value || '';
                      const step = parseInt(value);
                      const heading = value.replace(step, '').trim();

                      return (
                        <Flex flexDirection="row" alignItems="center">
                          {!isNaN(step) ? (
                            <StepNumber justifyContent="center" alignItems="center">
                              <Label>{step}</Label>
                            </StepNumber>
                          ) : null}
                          <Headline
                            size="small"
                            style={{ marginLeft: spacing[40], color: headlineColor }}
                          >
                            {heading}
                          </Headline>
                        </Flex>
                      );
                    },
                  paragraph:
                    // eslint-disable-next-line react/display-name
                    ({ node, ...props }) => (
                      <Body
                        size="medium"
                        style={{
                          marginLeft: spacing[72],
                          marginTop: spacing[16],
                          color: textColor,
                        }}
                        marginBottom={{
                          mobile: spacing[24],
                          desktop: spacing[40],
                        }}
                      >
                        {props.children}
                      </Body>
                    ),
                },
              }}
            />
          </Container>
        </Layout>
      </Columns>
    </OuterContainer>
  );
};

export default FiftyFiftySteps;

const MediaContainer = styled.div`
  width: 100%;
  vertical-align: middle;

  img,
  video {
    width: 100%;
    height: auto;
  }
`;

const StepNumber = styled(Flex)`
  width: ${spacing[32]};
  height: ${spacing[32]};
  min-height: ${spacing[32]};
  border: solid 1px ${grey[50]};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${grey[60]};
`;
