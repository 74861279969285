import { white, spacing } from '@pelotoncycle/design-system';
import StyledIcon from '@pelotoncycle/design-system/icons/AsyncIcon';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';

const PlayPauseToggle: FC<{ onClick: () => void; playing: boolean }> = ({
  onClick,
  playing,
}) => {
  return (
    <StyledButton onClick={onClick}>
      {playing ? (
        <StyledIcon name="pauseOutline" height={24} primaryColor={white} />
      ) : (
        <StyledIcon name="playOutline" height={24} primaryColor={white} />
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  position: absolute;
  bottom: 114px;
  right: 0;
  padding: ${spacing[16]};
  z-index: 1;
  cursor: pointer;
  margin-left: auto;

  ${media.tabletXLarge`
    bottom: ${spacing[24]};
    right: ${spacing[16]};
    padding: 0;
  `}
`;

export default PlayPauseToggle;
