import type { MediaImageProps } from '@pelotoncycle/design-system';
import React, { useEffect, useState, useCallback } from 'react';
import { useTracking } from 'react-tracking';
import { useLocale } from '@peloton/internationalize';
import { TrackingEvent } from '@ecomm/analytics/models';
import { isRentalSlug } from '@ecomm/rentals/models';
import { localizedBasicPackagesMap } from '@ecomm/shop/localizedBasicPackagesMap';
import { BundleType } from '@ecomm/shop/models';
import { Spinner } from '@ecomm/spinner';
import { TRANSITION_DURATION } from '@page-builder/modules/Overview/animations';
import type { PageBuilderData } from '@page-builder/modules/Overview/DrawerContentContext';
import { useDrawerContentContext } from '@page-builder/modules/Overview/DrawerContentContext';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import useCanAddToCart from '@page-builder/modules/Overview/ShopDrawers/useCanAddToCart';
import { useUpsellAccessoryContext } from '@page-builder/modules/Overview/UpsellAccessoryContext';
import { isAccessoryBundle } from '@page-builder/modules/Overview/utils';
import type { Props as SharedProps } from './SharedComponents';
import UpsellItemDrawer from './UpsellItemDrawer';

type Props = {
  upsellItemContent: PageBuilderData['upsellItemContent'][number];
};

const UpsellItem: React.FC<React.PropsWithChildren<Props>> = ({ upsellItemContent }) => {
  const { packageSlug } = useShopContext();
  const { trackEvent } = useTracking();
  const locale = useLocale();

  const {
    upsellAccessories,
    upsellAccessoryLoading,
    upsellAccessoryError,
    accessoryVariants,
    setAccessoryVariant,
    upsellAccessoriesSelected,
    setAccessorySelected,
    upsellAccessoriesMisconfigured,
    setAccessoryMisconfigured,
  } = useUpsellAccessoryContext();
  const {
    pageBuilderData: { upsellSectionIntro },
  } = useDrawerContentContext();

  const upsellItemSlug = upsellItemContent.productSlug;
  const accessory = upsellAccessories?.find(a => a.slug === upsellItemSlug);

  // TODO: Remove hard coded slug checks once we have a better way to do this
  const isCyclingShoes = upsellItemSlug === 'cycling-shoes';
  const isRentalUpsellSet = upsellItemSlug === 'rental-essentials-set';
  const isRentalPackage = isRentalSlug(packageSlug);
  const isExpandedRentalAcc = isRentalPackage && (isCyclingShoes || isRentalUpsellSet);

  const isGuideAcc = !!(
    upsellItemSlug &&
    upsellItemSlug === localizedBasicPackagesMap[locale]?.[BundleType.RainforestCafe]
  );

  const [isExpanded, setIsExpanded] = useState(isExpandedRentalAcc || isGuideAcc);
  const [isAnimating, setIsAnimating] = React.useState<boolean>(false);
  const { productBundleType } = useShopContext();

  const canAddToCart = useCanAddToCart();

  const setVariantForAccessory: SharedProps['setVariantForAccessory'] = useCallback(
    (variant, product, index) => {
      if (accessory) {
        if (product && isAccessoryBundle(accessory)) {
          // in a bundle: top level is the bundle slug, second level is the individual product
          setAccessoryVariant(accessory.slug, product?.slug + '-' + index, variant);
        } else {
          // in single accessory: top and second level are the accessory slug
          setAccessoryVariant(accessory.slug, accessory.slug, variant);
        }
      }
    },
    [accessory, setAccessoryVariant],
  );

  const isSelected = Boolean(
    accessory && upsellAccessoriesSelected.includes(accessory.slug),
  );

  const showErrorMessage = Boolean(
    accessory && upsellAccessoriesMisconfigured.includes(accessory.slug),
  );
  const setShowErrorMessage = (isMisconfigured: boolean) => {
    if (accessory) {
      setAccessoryMisconfigured(accessory.slug, isMisconfigured);
    }
  };

  // resets the error on container closing
  useEffect(() => {
    if (accessory && upsellAccessoriesMisconfigured.includes(accessory.slug)) {
      setAccessoryMisconfigured(accessory.slug, isExpanded);
    }
  }, [isExpanded, setAccessoryMisconfigured, accessory, upsellAccessoriesMisconfigured]);

  if (upsellAccessoryError?.message) {
    console.warn(
      `An error with the selected accessory key, ${upsellItemContent?.productSlug}, occurred.`,
    );
  }
  if (upsellAccessoryLoading) return <Spinner />;
  if (!upsellAccessories || !canAddToCart || !accessory) return null;

  const [accessoryImage] = accessory.images;
  const image: MediaImageProps = {
    alt: accessoryImage?.alt,
    mobile: accessoryImage?.src,
    type: 'image',
  };

  const handleAnimation = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, TRANSITION_DURATION);
  };

  const handleTracking = (isSelectedState: boolean) => {
    trackEvent({
      event: TrackingEvent.ClickedTile,
      properties: {
        category: productBundleType,
        tileCategory: isAccessoryBundle(accessory)
          ? 'Bundle accessory upsell'
          : 'A la carte accessory upsell',
        selected: isSelectedState,
        product: accessory.name,
      },
    });
  };

  const props: SharedProps = {
    accessory,
    accessoryVariants,
    setAccessoryVariant,
    setVariantForAccessory,
    upsellAccessoriesSelected,
    setAccessorySelected,
    upsellAccessoriesMisconfigured,
    setAccessoryMisconfigured,
    isExpanded,
    setIsExpanded,
    isAnimating,
    handleAnimation,
    handleTracking,
    image,
    isSelected,
    showErrorMessage,
    setShowErrorMessage,
    upsellItemContent,
    upsellSectionIntro,
  };

  return <UpsellItemDrawer {...props} />;
};

export default UpsellItem;
