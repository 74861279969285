import type { LabelProps, EyebrowProps } from '@pelotoncycle/design-system';
import { spacing, grey, Label, Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { defaultTransition, hoverTransition } from '@peloton/styles';
import Markdown from '@page-builder/utils/Markdown';

export type TextProps = {
  textProps: {
    text: string;
    size: 'small' | 'medium' | 'large';
    variant: 'body' | 'primary';
  };
  isInteractive?: boolean;
};

export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  textProps,
  isInteractive,
}) => {
  const { text, size, variant } = textProps;

  switch (variant) {
    case 'body':
      return (
        <StyledLabel
          size={size}
          textColor={grey[90]}
          isInteractive={isInteractive}
          is="span"
        >
          <Markdown content={text} />
        </StyledLabel>
      );
    default:
      return (
        <StyledEyebrow
          size={size}
          textColor={grey[90]}
          isInteractive={isInteractive}
          is="span"
        >
          <Markdown content={text} />
        </StyledEyebrow>
      );
  }
};

type InteractiveTextProps = { isInteractive?: boolean };

const HOVER_TRANSITION = hoverTransition({
  property: 'color',
  to: grey[70],
});

const StyledLabel = styled(Label)<LabelProps & InteractiveTextProps>`
  ${props =>
    props.isInteractive &&
    css`
      ${HOVER_TRANSITION};

      border-bottom: 1px solid currentColor;
      padding-bottom: ${spacing[2]};
      line-height: 20px;
    `}

  display: inline;
`;

const StyledEyebrow = styled(Eyebrow)<EyebrowProps & InteractiveTextProps>`
  ${props => props.isInteractive && HOVER_TRANSITION}

  &::after {
    display: ${props => (props.isInteractive ? 'block' : 'none')};
    margin-top: ${spacing[2]};
    content: '';
    height: 1px;
    background-color: currentColor;
    ${props => props.isInteractive && defaultTransition('background-color')}
  }
`;
