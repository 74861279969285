import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import type React from 'react';
import { useEffect, useState } from 'react';
import type { ConfigurableOptions } from '../utils/slide';
import { slide } from '../utils/slide';

export const useCarouselBehavior = (
  scrollableElRef: React.RefObject<HTMLElement | null>,
  options?: ConfigurableOptions,
) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [hasScrollEnded, setHasScrollEnded] = useState(true);
  const [canSlideLeft, setCanSlideLeft] = useState(false);
  const [canSlideRight, setCanSlideRight] = useState(false);

  useEffect(() => {
    const detectListOverflow = debounce(() => {
      if (!scrollableElRef.current) {
        setIsOverflowing(false);
        return;
      }

      const scrollableEl = scrollableElRef.current as HTMLElement;

      setIsOverflowing(scrollableEl.scrollWidth > scrollableEl.offsetWidth);
    }, 100);

    const resizeObserver = new ResizeObserver(detectListOverflow);

    if (scrollableElRef.current) {
      resizeObserver.observe(scrollableElRef.current);
    }

    detectListOverflow();
    addEventListener('resize', detectListOverflow);

    return () => {
      resizeObserver.disconnect();
      removeEventListener('resize', detectListOverflow);
    };
  }, []);

  useEffect(() => {
    if (!scrollableElRef.current) {
      return;
    }

    const scrollableEl = scrollableElRef.current as HTMLElement;

    const detectScrollStart = debounce(
      () => {
        setHasScrollEnded(false);
      },
      250,
      { leading: true },
    );
    const detectScrollEnd = debounce(
      () => {
        setHasScrollEnded(true);
      },
      250,
      { trailing: true },
    );

    scrollableEl.addEventListener('scroll', detectScrollStart);
    scrollableEl.addEventListener('scroll', detectScrollEnd);

    return () => {
      scrollableEl.removeEventListener('scroll', detectScrollStart);
      scrollableEl.removeEventListener('scroll', detectScrollEnd);
    };
  }, []);

  useEffect(() => {
    if (!scrollableElRef.current) {
      return;
    }

    const scrollableEl = scrollableElRef.current as HTMLElement;

    const detectScrollPosition = throttle(() => {
      const isStart = scrollableEl.scrollLeft === 0;
      const isEnd =
        Math.floor(
          scrollableEl.scrollWidth - scrollableEl.scrollLeft - scrollableEl.offsetWidth,
        ) <= 0;
      setCanSlideLeft(!isStart);
      setCanSlideRight(!isEnd);
    }, 100);

    detectScrollPosition();
    scrollableEl.addEventListener('scroll', detectScrollPosition);

    return () => {
      scrollableEl.removeEventListener('scroll', detectScrollPosition);
    };
  }, []);

  useEffect(() => {
    if (!scrollableElRef.current) {
      return;
    }

    const scrollableEl = scrollableElRef.current as HTMLElement;

    const scrollToFocusedListItem = (e: FocusEvent) => {
      const target = e.target as HTMLAnchorElement;
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };

    scrollableEl.addEventListener('focus', scrollToFocusedListItem, true);

    return () => scrollableEl.removeEventListener('focus', scrollToFocusedListItem, true);
  }, []);

  return {
    isOverflowing,
    hasScrollEnded,
    canSlideLeft,
    canSlideRight,
    slideLeft: () =>
      slide({
        direction: 'left',
        scrollableEl: scrollableElRef.current as HTMLElement,
        childEls: [...(scrollableElRef.current?.children || [])] as HTMLElement[],
        options,
      }),
    slideRight: () =>
      slide({
        direction: 'right',
        scrollableEl: scrollableElRef.current as HTMLElement,
        childEls: [...(scrollableElRef.current?.children || [])] as HTMLElement[],
        options,
      }),
  };
};
