import type { LinkProps } from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React from 'react';
import { Link } from '@peloton/next/components/Link';
import { usePreviousRouteContext } from '@peloton/next/components/PreviousRouteProvider';

export type BackLinkProps = LinkProps;
const CLASSES_ROUTE = 'classes';

export const BackLink: React.FC<React.PropsWithChildren<BackLinkProps>> = ({
  children,
  ...props
}) => {
  const router = useRouter();
  const previousRoute = usePreviousRouteContext();
  const isClassesRoute = previousRoute?.includes(CLASSES_ROUTE);
  const child = React.Children.only(children);

  const childOnClick = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();

      const isInternalRef = document.referrer.includes('peloton');
      if (previousRoute && isClassesRoute && isInternalRef) {
        router.back();
        return;
      }

      props.href && router.push(props.href);
    },
    [props.href, previousRoute, child, isClassesRoute],
  );

  return (
    <Link {...props} scroll={false} hasUnderline={false}>
      {React.isValidElement(child) &&
        React.cloneElement(child as React.ReactElement, {
          onClick: childOnClick,
        })}
    </Link>
  );
};
