import { Media } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { toMediaProps } from '@page-builder/utils/helpers/media';
import ConnectedVideo from '../Video/ConnectedVideo';
import HeroContent from './HeroContent';
import { ContainerStyles } from './styles';

export type Props = TypeComponentGenericTextWithMediaFields & {
  active: boolean;
  isPlaying: boolean;
  index: number;
  preview?: boolean;
};

const handlePause: React.ReactEventHandler<HTMLVideoElement> = e => {
  e.currentTarget.currentTime = 0;
};

const HeroCarouselSlide: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  media,
  ctas,
  active,
  isPlaying,
  index,
}) => {
  const { headline, eyebrow, body, support } = getTextFieldsWithRequiredKeys(
    ['headline'],
    text,
  );

  const mediaProps = media && toMediaProps(media);

  if (!mediaProps) {
    throw new Error('Missing media');
  }

  const shouldPlay = isPlaying && active;

  return (
    <SlideContainer>
      <MediaWrapper>
        {mediaProps.type === 'video' ? (
          <ConnectedVideo
            src={mediaProps.src}
            poster={mediaProps.poster}
            shouldPlay={shouldPlay}
            loop
            muted
            playsInline
            onPause={handlePause}
            responsiveSrc={mediaProps.responsiveVideoSrc}
          />
        ) : (
          <Media media={mediaProps} />
        )}
      </MediaWrapper>
      <ContentContainer>
        <HeroContent
          className={active ? 'active' : ''}
          eyebrow={eyebrow}
          headline={headline}
          subhead={body || ''}
          support={support}
          index={index}
          ctas={ctas}
          isActive={active}
        />
      </ContentContainer>
    </SlideContainer>
  );
};

const memo = (prevProps: Props, nextProps: Props) =>
  prevProps.active === nextProps.active &&
  prevProps.isPlaying === nextProps.isPlaying &&
  !nextProps.preview; // If previewCopy is on, rerender the slide

export default React.memo(HeroCarouselSlide, memo);

const SlideContainer = styled.div`
  ${ContainerStyles}
  position: relative;
`;

const MediaWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
`;
