import { white, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { createPortal } from 'react-dom';
import { useTracking } from 'react-tracking';
import styled, { css } from 'styled-components';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import useBannerReference from '@acme-ui/global/hooks/useBannerReference';
import { useLocalStorage } from '@ecomm/hooks';

import { CloseIcon } from '@ecomm/icons';
import useNextMobilePlatform from '@page-builder/hooks/useNextMobilePlatform';

import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { ctaExtractData } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import type { HybridBanner } from './utils';

export type Props = Omit<HybridBanner, 'text'> & {
  text: JSX.Element | string;
};
export type SocialLink = {
  type: 'social';
  href: string;
};

export const toSocialLink = (href: string): SocialLink => ({
  type: 'social',
  href,
});

export const getAppsFlyerLink = () => {
  // The script has already been implemented on this page.

  //Initializing Smart Script arguments
  const oneLinkURL = 'https://onepeloton.onelink.me/Wabb/a0obdg3e';
  const mediaSource = { keys: ['utm_source'], defaultValue: 'onepeloton' };
  const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };
  const deepLinkValue = { keys: ['page_name'], defaultValue: '/app' };

  //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
  //Onelink URL is generated.
  // @ts-expect-error
  const result = window?.AF_SMART_SCRIPT?.generateOneLinkURL({
    oneLinkURL: oneLinkURL,
    afParameters: {
      mediaSource: mediaSource,
      deepLinkValue: deepLinkValue,
      afCustom: [custom_ss_ui],
    },
  });
  return result;
};

const DownloadAppBanner: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ ctas, text, media }) => {
  const { isiOS, isAndroid } = useNextMobilePlatform();
  const isMobileDevice = isAndroid || isiOS;
  const appsFlyerLink = getAppsFlyerLink();

  const bannerRef = useBannerReference();
  const {
    eyebrow: title,
    headline: downloadAppBannerDescription,
    body,
  } = getGenericTextNodes(text, ['eyebrow', 'headline', 'body']);
  const image = toImageProps(media);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const downloadAppBannerCTAText = primaryCta && primaryCta?.text;
  const [closeBanner, setCloseBanner] = useLocalStorage<boolean>('closeAppBanner', false);
  const { getUser } = useUserInfo();
  const { trackEvent } = useTracking();
  const pathname = typeof window !== 'undefined' ? window?.location?.pathname : '';

  const bannerRoot = document.getElementById('download-app-banner');

  const closeBannerHandler = () => {
    trackEvent({
      event: 'Minimized Smart Banner',
      properties: {
        parentType: 'Generic Text with Media',
        parent: 'AppsFlyer App Banner',
        unitName: 'Close Button',
        page: pathname,
        ...getUser(),
      },
    });
    setCloseBanner(true);
  };

  const clickBannerHandler = () => {
    trackEvent({
      event: 'Clicked Smart Banner',
      properties: {
        parentType: 'Generic Text with Media',
        parent: 'AppsFlyer App Banner',
        unitName: 'Download App Banner Text',
        linkName: downloadAppBannerCTAText ?? 'View',
        linkTo: appsFlyerLink?.clickURL,
        page: pathname,
        ...getUser(),
      },
    });
  };

  if (!isMobileDevice) {
    return null;
  }

  const banner =
    isMobileDevice && !closeBanner && appsFlyerLink ? (
      <BannerContainer ref={bannerRef} data-test-id="download-banner-container">
        <PelotonAppIconTextWrapper>
          <CloseButton data-test-id="banner-close-button" onClick={closeBannerHandler}>
            <CloseIcon width={8} height={8} />
          </CloseButton>
          <PelotonAppIcon
            data-test-id="pelotonAppIcon"
            src={image?.mobile ?? ''}
            alt={image?.alt ?? ''}
            loading="lazy"
          />
          <TextWrapper>
            <DownloadAppTitle>{title}</DownloadAppTitle>
            <DownloadAppDescription>
              {downloadAppBannerDescription}
            </DownloadAppDescription>
            <DownloadAppSubtitle>{body}</DownloadAppSubtitle>
          </TextWrapper>
        </PelotonAppIconTextWrapper>
        <DownloadAppLink
          onClick={clickBannerHandler}
          href={appsFlyerLink?.clickURL}
          target="_blank"
        >
          {downloadAppBannerCTAText ?? 'View'}
        </DownloadAppLink>
      </BannerContainer>
    ) : (
      <></>
    );

  if (!bannerRoot) {
    return banner;
  }

  return createPortal(banner, bannerRoot, 'download-app-link-portal');
};

export default DownloadAppBanner;

const sharedStyles = css`
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  font-weight: 400;
`;

const BannerContainer = styled.div`
  height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px ${spacing[16]} 3px 0;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: ${grey[80]};
  }
  @media (prefers-color-scheme: light) {
    background-color: #f2f1f6;
  }
`;

const CloseButton = styled.button`
  margin-right: 7px;

  @media (prefers-color-scheme: dark) {
    > svg {
      fill: ${grey[50]};
    }
  }
  @media (prefers-color-scheme: light) {
    > svg {
      fill: ${grey[70]};
    }
  }
`;

const PelotonAppIconTextWrapper = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  z-index: 2;
  height: 79px;
  padding-left: ${spacing[16]};
  @media (prefers-color-scheme: dark) {
    background-color: ${grey[80]};
  }
  @media (prefers-color-scheme: light) {
    background-color: #f2f1f6;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  gap: 3px;
`;

const PelotonAppIcon = styled.img`
  height: 64px;
`;

const DownloadAppTitle = styled.h2`
  ${sharedStyles};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.015em;
  color: ${white};
  @media (prefers-color-scheme: dark) {
    color: ${white};
  }
  @media (prefers-color-scheme: light) {
    color: ${grey[90]};
  }
`;

const DownloadAppDescription = styled.h3`
  ${sharedStyles};
  font-size: 12px;
  line-height: 12px;
  @media (prefers-color-scheme: dark) {
    color: ${grey[50]};
  }
  @media (prefers-color-scheme: light) {
    color: ${grey[70]};
  }
`;

const DownloadAppSubtitle = styled.h3`
  ${sharedStyles};
  font-size: 12px;
  line-height: 12px;
  @media (prefers-color-scheme: dark) {
    color: ${grey[50]};
  }
  @media (prefers-color-scheme: light) {
    color: ${grey[70]};
  }
`;

const DownloadAppLink = styled.a`
  ${sharedStyles};
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #367af6;
  letter-spacing: 0.03em;
`;
