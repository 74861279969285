import React from 'react';
import { useTracking } from 'react-tracking';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { TrackingEvent } from '@ecomm/analytics/models';
import { ErrorBoundary, ErrorMessage } from '@page-builder/layout/error-handling';
import type { BlockProps } from '@page-builder/lib/pages';
import type { TypeComponentHeadband } from '@page-builder/lib/types';
import { ctaLinkToUrlWithFallback } from '@page-builder/utils/helpers/cta';
import { filterFalsyValues } from '@page-builder/utils/unions';
import {
  toPbxTOCItemsProps,
  TOCItems,
  TOCItemsDropdown,
  useSectionObserver,
} from '../../../../apps/freeform/modules/champ-ui/TableOfContents';
import HeadbandContainer from './HeadbandContainer';
import HeadbandCtaWithInfo from './HeadbandCtaWithInfo';
import HeadbandTitle from './HeadbandTitle';

export type HeadbandProps = TypeComponentHeadband & BlockProps;

const Headband: React.FC<React.PropsWithChildren<HeadbandProps>> = ({
  fields,
  sys,
  className,
  children,
}) => {
  const {
    name,
    tableOfContents,
    title,
    cta,
    financingText,
    disclaimerText,
    stackHeadband,
    theme = 'Grey 90',
  } = fields;

  const ctaHref = ctaLinkToUrlWithFallback(cta);
  const { trackEvent } = useTracking();
  const findProductFromCtaHref = (s: string) => {
    const products = ['accessories', 'bike-plus', 'bike', 'tread', 'guide', 'row'];
    return products.find(product => s.includes(product));
  };

  const { subHeaderElement: headbandRef = null } = React.useContext(
    GlobalReferenceContext,
  );

  const sectionIds = React.useMemo(
    () =>
      (tableOfContents || [])
        .map(tocCta => tocCta.fields.link?.fields.url)
        .filter(filterFalsyValues),
    [tableOfContents],
  );
  const { activeId } = useSectionObserver(sectionIds, {
    observerOptions: {
      // An anchor section is considered in-view when it's halfway up the viewport (50%)
      rootMargin: '50% 0px -50% 0px',
    },
  });

  const parsedCta = {
    href: ctaHref,
    text: cta?.fields?.text,
    onClick: () => {
      trackEvent({
        event: TrackingEvent.ClickedSubnavATC,
        properties: {
          linkTo: ctaHref,
          parent: name,
          parentType: sys.contentType.sys.id,
          unitName: cta?.fields?.name ?? '',
          linkName: cta?.fields?.text ?? '',
          productName: findProductFromCtaHref(ctaHref),
        },
      });
    },
  };

  const newTOCItemsProps = toPbxTOCItemsProps({
    activeId,
    ctas: tableOfContents,
    parentName: name,
    headbandRef,
  });

  return (
    <ErrorBoundary
      renderError={({ error }) => <ErrorMessage error={error} module={{ fields, sys }} />}
    >
      <HeadbandContainer
        className={className}
        stackHeadband={stackHeadband}
        theme={theme}
      >
        <HeadbandTitle title={title} />
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: tableOfContents?.length ? (
              <TOCItemsDropdown {...newTOCItemsProps} />
            ) : (
              <HeadbandCtaWithInfo
                cta={parsedCta}
                renderCustomCta={() => children}
                infoHeading={financingText}
                infoText={disclaimerText}
                dataTestId="headband-cta-mobile"
              />
            ),
            desktop: (
              <>
                {tableOfContents && <TOCItems {...newTOCItemsProps} />}
                <HeadbandCtaWithInfo
                  cta={parsedCta}
                  renderCustomCta={() => children}
                  infoHeading={financingText}
                  infoText={disclaimerText}
                />
              </>
            ),
          }}
        />
      </HeadbandContainer>
    </ErrorBoundary>
  );
};

export default Headband;
