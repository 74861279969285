import { spacing, Flex } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { TrackingEvent } from '@ecomm/analytics/models';
import { useCartException } from '@ecomm/cart-next/context/CartContext';
import { toDollars } from '@ecomm/models/Money';
import type {
  TypeComponentCta,
  TypeComponentCtaFields,
  TypeComponentGenericTextWithMedia,
} from '@page-builder/lib/types';
import { toCtaFields, toEntryTags } from '@page-builder/utils/helpers';
import { isEntryType } from '@page-builder/utils/unions';
import MembershipModal from '../Membership/MembershipModal';
import OverviewModal from '../OverviewModal/OverviewModal';
import { useShopContext } from './../ShopContext';
import { toCtaTrackingProperties } from './../utils';
import AddToCartCta from './AddToCartCta';
import AddToCartError from './AddToCartError';
import FinancingCta from './Financing/FinancingCta';
import toFinancingPartnerFromTags from './Financing/toFinancingPartnerFromTags';
import useCanAddToCart from './useCanAddToCart';

export type CtaHandlerProps = {
  cta: TypeComponentCta;
  product: string;
  drawerName: string;
  modalLayout?: 'financing' | 'membership';
  isActive: boolean;
  ctaSize?: 'small' | 'medium' | 'large';
  isDrawerFinancingSection?: boolean;
  financingPriceOverride?: number;
  packageNameOverride?: string;
};

const CtaHandler: React.FC<React.PropsWithChildren<CtaHandlerProps>> = ({
  cta: ctaEntry,
  product,
  drawerName,
  modalLayout,
  ctaSize = 'medium',
  isActive,
  isDrawerFinancingSection = false,
  financingPriceOverride,
  packageNameOverride,
}) => {
  const [cta] = toCtaFields([ctaEntry]);
  const tags = toEntryTags(ctaEntry);

  const { trackEvent } = useTracking();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cartException = useCartException();

  const { setShouldFocusError, activeCtaRef, productPackage } = useShopContext();

  const price = productPackage && toDollars(productPackage?.price.amount);
  const billingPartner = cta.styledAs === 'link' && toFinancingPartnerFromTags(tags);

  const trackLinkClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedPrequalifyCTA,
      properties: {
        parentType: 'Component: Overview',
        parent: 'Overview Drawer',
        unitName: 'Prequalify CTA',
        linkName: cta.text,
        productName: product,
        price: financingPriceOverride ? toDollars(financingPriceOverride) : price,
        packageName: packageNameOverride || productPackage?.name,
        pageName: productPackage?.slug,
        ...(billingPartner && { billingPartner }),
      },
    });
  };

  const handleLinkClick = () => {
    cta.modal && setIsModalOpen(true);
    trackLinkClicked();
  };

  const handleModalOpen = (modalCta: TypeComponentCtaFields) => {
    trackEvent({
      event: 'Modal Opened',
      properties: {
        productInterest: product,
        parentType: 'Component: Overview',
        parent: 'Overview Drawer',
        propertyType: 'Web',
        unitName: 'Modal CTA',
        unitPlacement: 'Modal',
        modalTrigger: `Clicked on '${modalCta.name}' cta`,
      },
    });
  };

  const canAddToCart = useCanAddToCart();

  const isAddToCartButton = Boolean(cta.productSlug);
  if (isAddToCartButton) {
    const onAddToCart = () => {
      setShouldFocusError(false);
      trackEvent({
        event: TrackingEvent.ClickedLink,
        properties: toCtaTrackingProperties(cta, drawerName),
      });
    };

    if (!canAddToCart) {
      return null;
    }

    return (
      <Flex flexDirection="column" gap={spacing[16]}>
        <AddToCartCta
          cta={cta}
          onClick={onAddToCart}
          activeCtaRef={isActive ? activeCtaRef : undefined}
        />
        {cartException && isActive && <AddToCartError errorCode={cartException} />}
      </Flex>
    );
  }

  if (cta.styledAs === 'link') {
    return (
      <FinancingCta
        cta={cta}
        tags={tags}
        onClick={handleLinkClick}
        isDrawerFinancingSection={isDrawerFinancingSection}
        financingPriceOverride={financingPriceOverride}
      />
    );
  }

  return (
    <>
      <LinkButton
        margin={`${spacing[16]} 0 0 0;`}
        width="adaptive"
        color={cta.color}
        variant={cta.variant}
        text={cta.text}
        href={cta.link?.fields.url}
        size={ctaSize}
        style={{ zIndex: 2 }}
        onClick={handleLinkClick}
      />
      {cta.modal &&
        isEntryType<TypeComponentGenericTextWithMedia>(cta.modal, 'text') &&
        (modalLayout === 'financing' ? (
          <OverviewModal
            modalContent={cta.modal.fields}
            isOpen={isModalOpen}
            openHandler={() => handleModalOpen(cta)}
            closeHandler={() => setIsModalOpen(false)}
          />
        ) : (
          <MembershipModal
            modalContent={cta.modal.fields}
            isOpen={isModalOpen}
            openHandler={() => handleModalOpen(cta)}
            closeHandler={() => setIsModalOpen(false)}
          />
        ))}
    </>
  );
};

export default CtaHandler;
