import React from 'react';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import { usePackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import type { TypeComponent_overviewFields } from '@page-builder/lib/types';
import { ShopContextProvider } from '@page-builder/modules/Overview/ShopContext';

type GetPackageBySlugContextProps = Pick<TypeComponent_overviewFields, 'shopDrawers'> & {
  slug: string;
};

const GetPackageBySlugProvider: React.FC<
  React.PropsWithChildren<GetPackageBySlugContextProps>
> = ({ children, slug }) => {
  const opts = useCatalogQueryOptions({
    throwError: false,
    variables: { package: slug },
    suspend: false,
  });

  const { data, loading } = usePackageBySlugOptionalWarrantyQuery({
    ...opts,
  });

  return (
    <ShopContextProvider
      packageSlug={slug}
      productPackage={data?.catalog?.packageBySlugOptionalWarranty}
      productPackageLoading={loading}
    >
      {children}
    </ShopContextProvider>
  );
};

export default GetPackageBySlugProvider;
