import {
  ResponsiveImage,
  Container,
  Support,
  Grid,
  spacing,
  Eyebrow,
  grey,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media } from '@peloton/styles';
import { useNearestShowroom } from '@ecomm/geolocation/hooks/useNearestShowroomNext';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers/cms';
import { ctaExtractData } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  nearestShowroom: TypeComponentGenericTextWithMediaFields;
  product: string;
};

const toBookingLink = (location: string = '') => {
  const queryString = `/?location=${location.replace(/ /g, '-').toLowerCase()}`;
  return queryString;
};

const NearestShowroomCard: React.FC<React.PropsWithChildren<Props>> = ({
  nearestShowroom: { text, media: mediaProps, ctas },
  product,
}) => {
  const { trackEvent } = useTracking();
  const showroom = useNearestShowroom();

  const { headline, body } = getTextFieldsWithRequiredKeys(['body', 'headline'], text);
  const cta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const image = toImageProps(mediaProps);

  const ctaUrl = cta?.url || '';
  const bookingLink = showroom ? ctaUrl.concat(toBookingLink(showroom.name)) : ctaUrl;
  const isExternal = Boolean(cta?.isExternalLink);
  const linkTarget = isExternal ? '_blank' : undefined;

  const nearestShowroomTracking = () => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        href: bookingLink,
        parentType: 'Component: Hero Overview',
        parent: `${product} Overview Module`,
        unitName: `${product} Nearest Showroom Module`,
        linkName: cta?.text,
        linkTo: bookingLink,
      },
    });
  };

  return (
    <Container
      data-test-id="overview-nearest-showroom"
      hasShadow={false}
      borderRadius="6px"
      padding={`${spacing[24]} 0`}
      style={{
        borderBottom: `1px solid ${grey[40]}`,
        borderRadius: 0,
      }}
    >
      <Container>
        <StyledEyebrow size="medium">{headline}</StyledEyebrow>
        <StyledGrid>
          {image?.mobile && (
            <StyledImage
              alt={image.alt || ''}
              data-test-id="static-showroom-image"
              mobile={image.mobile}
              loading="lazy"
            />
          )}
          <Markdown
            content={body}
            values={{
              showroomGoogleMapsUrl: showroom?.googleMapsUrl || ctaUrl,
              showroomName: showroom?.displayName || 'none',
            }}
            markdown={{
              renderers: {
                link: ({ node, ...props }) => (
                  <Link
                    target={linkTarget}
                    variant="body"
                    to={props.href}
                    href={props.href}
                    size="small"
                  >
                    {props.children}
                  </Link>
                ),
              },
            }}
          />
          <StyledSupport>
            <Link
              target={linkTarget}
              variant="body"
              onClick={nearestShowroomTracking}
              to={bookingLink}
              href={bookingLink}
              size="large"
            >
              {cta && cta.text}
            </Link>
          </StyledSupport>
        </StyledGrid>
      </Container>
    </Container>
  );
};

export default NearestShowroomCard;

const StyledImage = styled(ResponsiveImage)`
  display: grid;

  > img {
    border-radius: 2px;
    width: 80px;
    height: 80px;
  }

  grid-area: auto;
`;

const StyledSupport = styled(Support)`
  grid-column: 2;
  padding-top: ${spacing[8]};
`;

const StyledGrid = styled(Grid)`
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: ${spacing[24]};

  ${media.tablet`
  grid-template-rows: auto auto;
  column-gap: ${spacing[16]};
  `};
`;

const StyledEyebrow = styled(Eyebrow)`
  padding-bottom: ${spacing[24]};
`;
