import type * as Contentful from 'contentful';
import { useRouter } from 'next/router';
import React from 'react';
import type { TypeQuizQuestionFields } from '@page-builder/lib/types';
import Loading from '@page-builder/modules/Quiz/components/Loading';
import NavControls from '@page-builder/modules/Quiz/components/NavControls';
import QuizQuestion from '@page-builder/modules/Quiz/components/QuizQuestion';
import { useQuestions } from '@page-builder/modules/Quiz/utils/questions';
import { ThemeProvider } from '@page-builder/modules/Quiz/utils/theme';
import { getQueryParams, useFormProgress } from '../utils/progress';
import { writeProgressToSession } from '../utils/session';

type Props = {
  questions: Contentful.Entry<TypeQuizQuestionFields>[];
  resultPageUrl: string;
  loadingPageText?: string;
};

const QuizContent: React.FC<React.PropsWithChildren<Props>> = ({
  questions,
  resultPageUrl,
  loadingPageText,
}) => {
  const router = useRouter();
  const { progress, setProgress, quizSessionKey } = useFormProgress();

  const {
    getCurrentQuestion,
    canGoToPreviousQuestion,
    canGoToNextQuestion,
    isResultsPage,
    getCurrentQuestionIndex,
  } = useQuestions(questions, resultPageUrl, progress);
  const currentQuestion = getCurrentQuestion();

  React.useEffect(() => {
    // avoids double setting on results page (handled in redirect component)
    // and query params not hydrated on router for results page
    if (!isResultsPage() && router.isReady) {
      const queryParams = getQueryParams(questions, router.query);
      // if query params, overwrite
      // if not, use session storage
      if (Object.keys(queryParams).length !== 0) {
        setProgress(prevProgress => ({ ...prevProgress, ...queryParams }));
        writeProgressToSession(queryParams, quizSessionKey);
      }
    }
  }, [isResultsPage, router, questions, quizSessionKey, setProgress]);

  return (
    <ThemeProvider
      theme={
        isResultsPage()
          ? questions[questions.length - 1].fields.theme
          : currentQuestion.fields.theme
      }
    >
      {isResultsPage() ? (
        <Loading loadingPageText={loadingPageText} />
      ) : (
        <>
          <QuizQuestion
            question={currentQuestion}
            currentQuestionIndex={getCurrentQuestionIndex()}
            quizSessionKey={quizSessionKey}
          />
          <NavControls
            questions={questions}
            question={currentQuestion}
            canGoBack={canGoToPreviousQuestion()}
            canGoForward={canGoToNextQuestion()}
            resultPageUrl={resultPageUrl}
            quizSessionKey={quizSessionKey}
          />
        </>
      )}
    </ThemeProvider>
  );
};

export default QuizContent;
