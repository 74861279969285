import { Container, spacing, Label, Support } from '@pelotoncycle/design-system';
import React from 'react';
import { useMicroCopy, useMicroCopyBulk } from '@content/client/microCopy';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Divider } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import Markdown from '@page-builder/utils/Markdown';

import { useProductConfigurationContext } from '../ProductConfigurationContext';

import SelectDropdown from './ItemDropdown';
import MutliSelectCheckboxGroup from './MutliSelectCheckboxGroup';
import SingleSelectRadioGroup from './SingleSelectRadioGroup';

const AccessoryComponentMap = {
  dropdown: SelectDropdown,
  singleSelect: SingleSelectRadioGroup,
  multiSelect: MutliSelectCheckboxGroup,
};

const ProductConfigurations: React.FC<React.PropsWithChildren<unknown>> = () => {
  const selectMicroCopy = useMicroCopy('selectQuantity');
  const selectedItemsMicroCopy = useMicroCopy('multiselectSelectionLabel');
  const isCommerceToolsActive = useIsToggleActive()('commercetoolsNewPDP');
  const {
    productConfigurations,
    configurableProducts,
  } = useProductConfigurationContext();
  const microCopyKeys = configurableProducts.map(
    accessory => `pdpSubheads.${accessory.slug}`,
  );
  const accessorySubheads = useMicroCopyBulk(microCopyKeys);

  if (!configurableProducts.length || !isCommerceToolsActive) {
    return null;
  }

  return (
    <>
      <Divider topPadding={0} />
      <Container
        position="relative"
        style={{
          zIndex: 2,
        }}
      >
        {configurableProducts.map((accessory, i) => {
          const isSingleSelect =
            accessory.component === 'singleSelect' ||
            (accessory.component == 'multiSelect' && accessory.occurrence == 1);
          const AccessoryComponent =
            (isSingleSelect
              ? AccessoryComponentMap['singleSelect']
              : AccessoryComponentMap[accessory.component]) ?? Container;
          const subheadKey = `pdpSubheads.${accessory.slug}`;
          const subheadText = accessorySubheads[subheadKey];

          const selectedAmount = Object.keys(productConfigurations[accessory.slug] ?? {})
            .length;

          return (
            <Container
              key={`product-configurations-${accessory.name}`}
              data-test-id={`product-configurations-${accessory.slug}`}
              style={{
                marginBottom: i < configurableProducts.length - 1 ? spacing[32] : 0,
              }}
            >
              <Label
                size="large"
                weight="medium"
                style={{
                  paddingRight: spacing[12],
                  marginBottom: subheadText ? '0' : spacing[12],
                }}
              >
                {accessory.name}
              </Label>
              {accessory.component !== 'dropdown' && (
                <Support size="large">
                  {selectedAmount ? (
                    <Markdown
                      content={selectedItemsMicroCopy}
                      values={{
                        selectedAmount: selectedAmount,
                        requiredAmount: accessory.occurrence,
                      }}
                    />
                  ) : (
                    `${selectMicroCopy} ${accessory.occurrence}`
                  )}
                </Support>
              )}
              {subheadText && (
                <Container verticalMargin={`0 ${spacing[16]}`}>
                  <Support size="large">{subheadText}</Support>
                </Container>
              )}
              <AccessoryComponent accessory={accessory} />
            </Container>
          );
        })}
      </Container>
    </>
  );
};

export default ProductConfigurations;
