import { grey, Icon, Label, spacing, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import type { Filters } from './hooks/useClassFilters';

export type DesktopClassesFiltersProps = {
  filters: Filters;
};

export type GenericDropdownProps = {
  label: string;
  ariaLabel: string;
  id: string;
  isHighlighted: boolean;
  isBorderHighlighted: boolean;
  isLoading: boolean;
};

export const GenericDropdown: React.FC<React.PropsWithChildren<GenericDropdownProps>> = ({
  label,
  ariaLabel,
  id,
  isHighlighted,
  isBorderHighlighted,
  isLoading,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const openButtonRef = useRef<HTMLButtonElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const genericCloseLabel = useMicroCopy('genericCloseLabel');
  const buttonId = `${id}-label`;
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();

  const boxA11yProps = {
    id: id,
    inert: isExpanded ? undefined : true,
    'aria-hidden': !isExpanded,
    'aria-labelledby': buttonId,
    'aria-controls': id,
    tabIndex: -1,
  };

  const trackClick = useCallback(
    (filterName: string) => {
      trackEvent({
        event: 'Clicked Filter Dropdown',
        properties: {
          parentType: 'Component: Generic List',
          parent: 'Generic Dropdown',
          unitName: filterName,
          page: pathName,
        },
      });
    },
    [pathName, trackEvent],
  );

  const focusOpenButton = () => openButtonRef.current?.focus({ preventScroll: true });

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    const clickHandler = (e: MouseEvent) => {
      const isButtonClicked = openButtonRef.current?.contains(e.target as Node);
      const isBoxClicked = boxRef.current?.contains(e.target as Node);
      const isInternalClick = isButtonClicked || isBoxClicked;

      if (!isInternalClick) {
        setIsExpanded(false);
      }
    };

    addEventListener('click', clickHandler);

    return () => removeEventListener('click', clickHandler);
  }, [isExpanded]);

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      const isEscapeKey = e.key === 'Escape';

      if (isEscapeKey) {
        setIsExpanded(false);
        focusOpenButton();
      }
    };

    addEventListener('keydown', keyDownHandler);

    return () => removeEventListener('keydown', keyDownHandler);
  }, [isExpanded]);

  useEffect(() => {
    if (!isLoading) return;
    setIsExpanded(false);
  }, [isLoading]);

  return (
    <StyledContainer>
      <StyledButton
        onClick={() => {
          setIsExpanded(!isExpanded);
          trackClick(label);
        }}
        ref={openButtonRef}
        aria-expanded={isExpanded}
        aria-controls={id}
        id={buttonId}
        isHighlighted={isHighlighted}
        isBorderHighlighted={isBorderHighlighted}
        isLoading={isLoading}
      >
        <Label size="medium" is="span" aria-label={ariaLabel}>
          {label}
        </Label>
        <StyledIcon
          aria-hidden={true}
          name="chevron"
          primaryColor={white}
          rotate={isExpanded ? 90 : -90}
          width={5}
          height={9}
        />
      </StyledButton>
      <StyledBox
        ref={boxRef}
        className={isExpanded ? 'is-expanded' : ''}
        {...boxA11yProps}
      >
        <StyledScroller>{children}</StyledScroller>
        <StyledCloseButton
          aria-label={genericCloseLabel}
          type="button"
          onClick={() => {
            setIsExpanded(false);
            focusOpenButton();
          }}
        >
          <Icon name="close" primaryColor="white" width={24} height={24} />
        </StyledCloseButton>
      </StyledBox>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledButton = styled.button<{
  isHighlighted: boolean;
  isBorderHighlighted: boolean;
  isLoading: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${11 / 16}rem ${spacing[12]};
  ${({ isBorderHighlighted }) =>
    `border: 1px solid ${isBorderHighlighted ? white : grey[50]};`}
  border-radius: 8px;
  transition: background-color 0.2s ease-out;
  ${({ isHighlighted, isLoading }) =>
    isHighlighted && !isLoading ? `background-color: ${rgba(grey[70], 0.25)};` : ''}
  ${({ isLoading }) =>
    isLoading
      ? `
    animation: blinker 1s linear infinite;

    @keyframes blinker {
      50% {
        background-color: ${grey[70]};
      }
    }
  `
      : ''}
`;

const StyledCloseButton = styled.button`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const StyledIcon = styled(Icon)`
  margin-left: ${spacing[12]};
`;

const StyledBox = styled.div`
  position: absolute;
  top: ${spacing[48]};
  left: 0;
  transform: translateY(3rem);
  z-index: 1;
  background: ${grey[90]};
  border: 1px solid ${grey[50]};
  opacity: 0;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
  border-radius: 8px;
  pointer-events: none;

  &.is-expanded {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }
`;

const StyledScroller = styled.div`
  min-width: ${320 / 16}rem;
  max-height: ${432 / 16}rem;
  max-height: min(${432 / 16}rem, 75vh);
  padding: ${spacing[40]};
  border-radius: 8px;
  overflow: auto;
`;
