import {
  ResponsiveImage,
  Eyebrow,
  Headline,
  Label,
  Flex,
  Icon,
  Support,
  grey,
  white,
  spacing,
  Container,
} from '@pelotoncycle/design-system';
import React, { useState, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { media as mediaQuery, BreakpointWidth } from '@peloton/styles';
import type {
  TypeCollectionCardProps as CollectionCardProps,
  TypeCollectionListItem as CollectionListItem,
} from './types';

enum AnalyticsTypes {
  SEE_MORE_CLASSES_LINK = 'see more classes link',
  CARD_LINK = 'card link',
}

const analyticsEventTypeToName = {
  [AnalyticsTypes.SEE_MORE_CLASSES_LINK]: 'Clicked Link',
  [AnalyticsTypes.CARD_LINK]: 'Clicked Classes Card',
};

const trackingParent = 'Prospect Classes Page';

export type CollectionCardRowProps = {
  rowItems: CollectionCardProps[];
  title: string;
};

const CollectionClassItem = ({ media, label, link, support }: CollectionListItem) => {
  const { trackEvent } = useTracking();

  const trackClassesCardLinkClick = (eventType: AnalyticsTypes) => {
    trackEvent({
      event: analyticsEventTypeToName[eventType],
      properties: {
        parent: trackingParent,
        linkTo: `${link.url}`,
        linkName: `${label}`,
        unitName: 'CollectionCardRow',
        parentType: 'Page',
      },
    });
  };

  return (
    <>
      <Link
        to={link.url}
        href={link.url}
        hasUnderline={false}
        data-test-id={`class-${label}`}
        onClick={() => trackClassesCardLinkClick(AnalyticsTypes.CARD_LINK)}
      >
        <Flex flexDirection="row" gap={spacing[16]}>
          {media && <ClassThumbnailImage {...media} />}
          <Flex flexDirection="column" justifyContent="center">
            <Label size="medium" weight="medium" style={{ color: white }}>
              {label}
            </Label>
            {support && (
              <Support style={{ color: grey[50], fontWeight: 400 }} size="medium">
                {support}
              </Support>
            )}
          </Flex>
        </Flex>
      </Link>
    </>
  );
};

const CollectionClassList = ({ listItems }: { listItems: CollectionListItem[] }) => {
  return (
    <Flex flexDirection="column" gap={spacing[16]}>
      {listItems.map((item, index) => (
        <CollectionClassItem key={`${item.label}-${index}`} {...item} />
      ))}
    </Flex>
  );
};

const CollectionCard = ({
  media,
  heading,
  subHeading,
  listItems,
  cta,
  collectionIndex,
}: CollectionCardProps) => {
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);
  const [isCollapsed, setIsCollapsed] = useState(
    isMobileOrTablet && collectionIndex !== 0,
  );
  useEffect(() => {
    setIsCollapsed(isMobileOrTablet && collectionIndex !== 0);
  }, [isMobileOrTablet, collectionIndex]);
  const { trackEvent } = useTracking();

  const trackSeeMoreClassesLinkClick = (eventType: AnalyticsTypes) => {
    trackEvent({
      event: analyticsEventTypeToName[eventType],
      properties: {
        parent: trackingParent,
        linkTo: `${cta.link.url}`,
        linkName: `${cta.text}`,
        unitName: 'CollectionCardRow',
        parentType: 'Page',
      },
    });
  };

  const trackCollectionCardLinkClick = (eventType: AnalyticsTypes) => {
    trackEvent({
      event: analyticsEventTypeToName[eventType],
      properties: {
        parent: trackingParent,
        linkTo: `${cta.link.url}`,
        linkName: `${heading}`,
        unitName: 'CollectionCardRow',
        parentType: 'Page',
      },
    });
  };

  return (
    <CardMaxWidthFlex flexDirection="column" data-test-id={`collection-card-${heading}`}>
      {media && (
        <LinkContainer
          data-test-id="cardMediaLink"
          to={cta.link.url}
          href={cta.link.url}
          hasUnderline={false}
          onClick={() => trackCollectionCardLinkClick(AnalyticsTypes.CARD_LINK)}
        >
          <CollectionImage {...media} />
        </LinkContainer>
      )}
      <CardContentFlex
        flexDirection="column"
        backgroundColor={grey[80]}
        borderRadius="0 0 8px 8px"
        gap={spacing[24]}
        padding="24px 32px 32px 24px"
        maxHeight={isCollapsed ? '89px' : 'auto'}
        style={{ overflow: 'hidden' }}
      >
        <div>
          {isMobileOrTablet ? (
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              <Flex flexDirection="column" gap={spacing[4]}>
                <Eyebrow size="large" style={{ color: white }}>
                  {heading}
                </Eyebrow>
                {subHeading && (
                  <Label size="medium" style={{ color: grey[50], fontWeight: 400 }}>
                    {subHeading}
                  </Label>
                )}
              </Flex>
              <Icon
                height={12}
                name="chevron"
                primaryColor={white}
                style={{
                  transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(90deg)',
                }}
              />
            </Flex>
          ) : (
            <Link
              data-test-id="cardLink"
              to={cta.link.url}
              href={cta.link.url}
              hasUnderline={false}
            >
              <Eyebrow size="large" style={{ color: white }}>
                {heading}
              </Eyebrow>
              {subHeading && (
                <Label size="medium" style={{ color: grey[50], fontWeight: 400 }}>
                  {subHeading}
                </Label>
              )}
            </Link>
          )}
        </div>
        {listItems && <CollectionClassList listItems={listItems} />}
        {cta && (
          <StyledLink
            data-test-id="seeMoreClassesLink"
            to={cta.link.url}
            href={cta.link.url}
            size="small"
            hasUnderline={true}
            onClick={() =>
              trackSeeMoreClassesLinkClick(AnalyticsTypes.SEE_MORE_CLASSES_LINK)
            }
          >
            {cta.text}
          </StyledLink>
        )}
      </CardContentFlex>
    </CardMaxWidthFlex>
  );
};

const CollectionCardRow = ({ rowItems, title }: CollectionCardRowProps) => {
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);

  return (
    <Container
      backgroundColor={grey[90]}
      padding={{
        mobile: `${spacing[32]} ${spacing[16]} ${spacing[32]}`,
        tablet: `0 ${spacing[32]} ${spacing[64]}`,
        desktop: `0 ${spacing[32]} ${spacing[80]}`,
      }}
    >
      <Flex justifyContent="center" maxWidth="1224px" margin="auto">
        <StyledHeadline
          size="extraSmall"
          style={{ color: white }}
          data-test-id="collection-card-row-title"
        >
          {title}
        </StyledHeadline>
      </Flex>
      <Flex
        maxWidth="1224px"
        flexDirection="row"
        gap={spacing[24]}
        padding={'24px 0 0 0'}
        justifyContent="center"
        flexWrap={isMobileOrTablet ? 'wrap' : 'nowrap'}
        data-test-id="collection-card-row"
        margin="auto"
      >
        {rowItems?.map((item, index) => (
          <CollectionCard {...item} key={index} collectionIndex={index} />
        ))}
      </Flex>
    </Container>
  );
};

const CardMaxWidthFlex = styled(Flex)`
  min-width: 0px;
  max-width: 357px;

  ${mediaQuery.tabletXLarge`
    min-width: auto;
    max-width: 704px;
  `}

  ${mediaQuery.desktopXLarge`
    min-width: auto;
    height: auto;
    max-width: 392px;
  `}
`;

const CardContentFlex = styled(Flex)`
  ${mediaQuery.desktopLarge`
    flex-grow: 1;
    justify-content: space-between;
  `}
`;

const StyledHeadline = styled(Headline)`
  margin: 0 auto;
  width: 357px;

  ${mediaQuery.tabletXLarge`
    width: 704px;
  `}

  ${mediaQuery.desktopLarge`
    width: 100%;
  `}
`;

const StyledLink = styled(Link)`
  max-width: fit-content;
  color: #fff;
`;

const LinkContainer = styled(Link)`
  > span {
    line-height: 0px;
  }
`;

const ClassThumbnailImage = styled(ResponsiveImage)`
  width: 114px;
  height: 64px;

  > img {
    border-radius: 8px;
    width: 114px;
    height: 64px;
  }
`;

const CollectionImage = styled(ResponsiveImage)`
  max-width: 100%;
  min-width: 0px;

  > img {
    border-radius: 8px 8px 0 0;
    max-width: 100%;
  }
`;

export default CollectionCardRow;
