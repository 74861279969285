import React from 'react';
import styled, { css } from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { LazyImageProps } from '@ecomm/image/LazyImage';
import LazyImage from '@ecomm/image/LazyImage';
import type { BreakpointOptions } from './useGenerateSourceElements';
import useGenerateSourceElements from './useGenerateSourceElements';

export type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  LazyImageProps & {
    breakpointOptions: BreakpointOptions;
    src: string;
  };

const PerformantImage: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  loading,
  layout,
  breakpointOptions,
  src,
  ...props
}) => {
  const sourceElements = useGenerateSourceElements(breakpointOptions, src);

  const mobileImgOptions = { src, ...breakpointOptions?.mobile };
  const cloudinarifiedImgSrc = toCloudinarySrc(mobileImgOptions);

  return (
    <StyledPicture className={className}>
      {sourceElements}

      {loading !== 'lazy' ? (
        <img alt={props.alt} role={props.role} src={cloudinarifiedImgSrc} />
      ) : (
        <LazyImage
          alt={props.alt}
          height={props.height}
          layout={layout}
          loading="lazy"
          role={props.role}
          src={cloudinarifiedImgSrc}
          width={props.width}
        />
      )}
    </StyledPicture>
  );
};

export default PerformantImage;

const StyledPicture = styled.picture`
  display: block;
  user-select: none;

  img {
    object-fit: cover;
    user-select: none;
    vertical-align: middle; // This property addresses an issue where the picture element will expand to be taller than the img it contains.
  }
`;

const blockImageStyles = css`
  min-height: 100%;
  object-fit: cover;
  transition: all 500ms ease-in-out;
  vertical-align: middle;
`;

export const BlockPerformantImage = styled(PerformantImage)`
  ${blockImageStyles}

  img {
    max-width: 100%;
  }
`;
