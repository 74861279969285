import { Media, spacing, Grid, Container } from '@pelotoncycle/design-system';
import { OuterContainer, getGenericTextNodes } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoint } from '@peloton/styles';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import {
  nameToModuleId,
  toMediaProps,
  DOUBLE_BREAKPOINT_WIDTH_OPTIONS,
} from '@page-builder/utils/helpers';
import { CONTAINER_MAXWIDTH } from '../tokens';
import HeaderText from './HeaderText';
import TextAndCTA from './TextAndCTA';

export const containedProps = {
  maxWidth: CONTAINER_MAXWIDTH,
  gap: { mobile: 0, desktop: spacing[24] },
  verticalPadding: {
    mobile: spacing[32],
    tablet: spacing[64],
    desktop: spacing[80],
  },
  horizontalPadding: {
    mobile: spacing[16],
    tablet: spacing[64],
    desktop: spacing[40],
  },
};

const FiftyFiftyMedia: React.FC<TypeComponentGenericTextWithMediaFields> = ({
  name,
  ctas,
  text,
  media,
  theme = 'Grey 80',
}) => {
  const { eyebrow, headline, body, support, label } = getGenericTextNodes(text);
  const { backgroundColor, textColor, headlineColor } = themes[theme];

  const mediaProps = media && {
    ...toMediaProps(media, false, DOUBLE_BREAKPOINT_WIDTH_OPTIONS),
    autoplay: true,
    progressTracked: true,
    loop: false,
  };

  return (
    <OuterContainer
      id={nameToModuleId(name)}
      backgroundColor={backgroundColor}
      theme={theme}
      verticalPadding={containedProps.verticalPadding}
      horizontalPadding={containedProps.horizontalPadding}
    >
      <FiftyFiftyContainer
        maxWidth={containedProps.maxWidth}
        backgroundColor={backgroundColor}
        gap={containedProps.gap}
        removeOuterMargins
      >
        <HeaderText
          eyebrowText={eyebrow}
          headlineText={headline}
          bodyText={body}
          primaryColor={headlineColor}
          secondaryColor={textColor}
        />
        <TextAndCTA
          ctas={ctas}
          labelText={label}
          supportText={support}
          primaryColor={headlineColor}
          secondaryColor={textColor}
        />
        <MediaContainer
          verticalPadding={{ mobile: `${spacing[16]}`, desktop: 0 }}
          style={{ gridArea: 'media' }}
        >
          {mediaProps && <Media media={mediaProps} />}
        </MediaContainer>
      </FiftyFiftyContainer>
    </OuterContainer>
  );
};

export default FiftyFiftyMedia;

const MediaContainer = styled(Container)`
  width: 100%;
  vertical-align: middle;

  img,
  video {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;

const FiftyFiftyContainer = styled(Grid)`
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-template-areas:
    'header'
    'media'
    'cta';

  ${mediaBreakpoint.desktopLarge`
  grid-template-areas:
    'header media'
    'cta    media';

  grid-template-columns: 1fr 1fr;
  `};
`;
