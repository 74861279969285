import { white, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import useFinancingNext from '@ecomm/financing/hooks/useFinancingNext';
import { Partners } from '@ecomm/financing/models/Partners';
import { isValidBundleType } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { FinancingDetailsContainer } from './FinancingDetailsContainer';
import { QuoteCard } from './QuoteCard';

const defaults = {
  theme: 'Grey 90',
} as const;

const PrequalifyWithCard: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ name, media, ctas, text, theme = defaults.theme }) => {
  const path = typeof window === 'undefined' ? 'undefined' : window?.location?.pathname;
  const bundleFromPath = isValidBundleType(path) ? path : 'bike-plus';

  const { financingPartner } = useFinancingNext(bundleFromPath as BundleType);
  const isAffirmLocale = financingPartner === Partners.Affirm;

  if (!isAffirmLocale) return null;

  const image = toImageProps(media);
  const { headline, body, support } = getGenericTextNodes(text, [
    'headline',
    'body',
    'support',
  ]);
  const cta = ctas?.[0].fields?.text || '';

  if (!image) {
    throw new Error('Missing media');
  }

  const { backgroundColor, textColor, headlineColor, buttonColor } = themes[theme];

  return (
    <Flex
      minHeight={{ mobile: '590px', desktop: '394px' }}
      justifyContent="center"
      backgroundColor={backgroundColor}
    >
      <Flex
        color={white}
        alignItems="center"
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        justifyContent={{ mobile: 'space-around', desktop: 'center' }}
        width="100%"
        padding={{ mobile: '56px 24px', desktop: '69px 24px' }}
      >
        <QuoteCard cardText={support} />
        <FinancingDetailsContainer
          name={name}
          headline={headline}
          body={body}
          cta={cta}
          bundle={bundleFromPath as BundleType}
          textColor={textColor}
          headlineColor={headlineColor}
          buttonColor={buttonColor}
        />
      </Flex>
    </Flex>
  );
};

export default PrequalifyWithCard;
