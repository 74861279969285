import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { usePromoContext } from '@page-builder/modules/Overview/PromoProvider';
import { UPSELL_TAGS } from './constants';

const useGetUpsellTagNames = (parentCfuProductId: string): string[] => {
  const isToggleActive = useIsToggleActive();
  const { hasNonBasicsPackagePromo } = usePromoContext();

  const showUpsellBundles =
    isToggleActive(`showUpsellBundles_${parentCfuProductId}`) ||
    !hasNonBasicsPackagePromo;
  const showUpsellGuide = isToggleActive('showUpsellGuide');

  return [
    UPSELL_TAGS.accessory,
    showUpsellBundles && UPSELL_TAGS.bundle,
    showUpsellGuide && UPSELL_TAGS.guide,
  ].filter(Boolean) as string[];
};

export default useGetUpsellTagNames;
