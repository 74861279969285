import type { Locale } from '@peloton/internationalize';
import type { ApiClass } from '@peloton/models/PelotonClass';
import { NextNotFoundError } from '@peloton/next/errors/NextNotFoundError';
import { NextRedirectError } from '@peloton/next/errors/NextRedirectError';

import { isClassIdSlug } from '../models/constants';
import { API_DOMAIN, API_HEADERS, API_CLASS_PATH } from './';

const LOGIN_PATH = '/login';

export type ApiClassData = {
  slug: string;
  id: string;
  classData: Record<Locale, ApiClass>;
};

export async function fetchClassData(
  slug: string,
  locale: string,
  disciplineSlug?: string,
  isCLP?: boolean,
) {
  const request = await fetch(`${API_DOMAIN}${API_CLASS_PATH}${slug}?locales=${locale}`, {
    headers: API_HEADERS,
  });
  const badResponse = request.ok === false;
  const shouldRedirectToLoginPage = isClassIdSlug(slug) && badResponse && !disciplineSlug;

  if (shouldRedirectToLoginPage) {
    // Redirect to login page if class is missing from prospects and not a part of the page.
    throw new NextRedirectError({
      statusCode: 302,
      currentPath: slug,
      newPath: LOGIN_PATH,
    });
  }

  if (badResponse) {
    let message = `Error fetching class data for slug ${slug}, locale ${locale}`;

    if (disciplineSlug) {
      const listedOn = isCLP ? 'CLP' : 'Discipline';

      message = message.concat(
        `, listing on ${listedOn} page /classes/${disciplineSlug}`,
      );
    }

    throw new NextNotFoundError({ message });
  }

  const classes = await request.json();
  return classes as ApiClassData;
}
