import {
  Body,
  BreakpointWidths,
  Container,
  Flex,
  Grid,
  Headline,
  Support,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import { getGenericTextNodes } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCountry } from '@peloton/internationalize/models';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { TypeComponentGenericList } from '@page-builder/lib/types';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import { LiftedModal } from '../NextModal';
import { FullWidthMediaModuleModalContent } from './FullWidthMediaModuleModalContent';

// accounts for the max width of the modal content being 1224px with the additional padding (24px on each side)
const CONTAINER_WITH_PADDING_MAX_WIDTH = 1272;

export type ModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  modalContent: TypeComponentGenericList;
  parent: {
    parentName: string;
    parentType: string;
  };
  linkName: string;
};

export const FullWidthMediaModuleModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isModalOpen,
  onModalClose,
  modalContent: {
    fields: { text, items },
  },
  parent: { parentName, parentType },
  linkName,
}) => {
  const { trackEvent } = useTracking();
  const { eyebrow: modalTitle } = getTextFieldsWithRequiredKeys(['eyebrow'], text);
  const { headline, body, support } = getGenericTextNodes(text);

  const modalOpenCloseEvent = (eventType: TrackingEvent) =>
    trackEvent({
      event: eventType,
      properties: {
        country: toCountry().toLocaleUpperCase(),
        propertyType: 'Web',
        unitPlacement: 'Modal',
        modalTrigger: 'Click',
        unitName: `${modalTitle} Modal`,
      },
    });

  const handleModalOpen = () => {
    modalOpenCloseEvent(TrackingEvent.ModalOpened);
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parent: parentName,
        parentType: parentType,
        linkName: linkName,
        unitName: `${modalTitle} Modal`,
        linkTo: '',
      },
    });
  };

  const handleModalClose = () => {
    modalOpenCloseEvent(TrackingEvent.ModalClosed);
    onModalClose();
  };

  return (
    <LiftedModal
      isOpen={isModalOpen}
      contentLabel={modalTitle}
      onAfterOpen={handleModalOpen}
      closeHandler={handleModalClose}
      backgroundColor={grey[80]}
      textColor={white}
    >
      <Container
        maxWidth={`${CONTAINER_WITH_PADDING_MAX_WIDTH}px`}
        margin="auto"
        padding={{
          mobile: `${spacing[32]} ${spacing[16]}`,
          tablet: `${spacing[32]} ${spacing[24]}`,
          desktop: `${spacing[80]} ${spacing[24]}`,
        }}
      >
        <Flex flexDirection="column" gap={spacing[8]} verticalMargin={`0 ${spacing[32]}`}>
          {headline && (
            <Headline size="small" textColor={white}>
              {headline}
            </Headline>
          )}
          {body && (
            <Body size="small" textColor={white}>
              {body}
            </Body>
          )}
        </Flex>
        <StyledGrid
          gap={{
            mobile: `${spacing[24]} ${spacing[16]}`,
            desktop: `${spacing[48]} ${spacing[24]}`,
          }}
        >
          {items.map(item => (
            <FullWidthMediaModuleModalContent key={item.fields.name} item={item} />
          ))}
        </StyledGrid>
        {support && (
          <Container verticalMargin={`${spacing[32]} 0`}>
            <Support textColor={white}>{support}</Support>
          </Container>
        )}
      </Container>
    </LiftedModal>
  );
};

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media (min-width: ${BreakpointWidths.desktop}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
