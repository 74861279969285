import { EquipmentType } from '@ecomm/graphql/types.generated';
import { toBundleType as toShopModelBundleType } from '@ecomm/shop/models/Bundle';
import { BundleType } from '@ecomm/shop/models/BundleType';

const toBundleType = (equipmentType: EquipmentType): BundleType | undefined => {
  const dumbBundleConversion: BundleType = {
    [EquipmentType.Bike]: BundleType.Bike,
    [EquipmentType.Bikeplus]: BundleType.BikePlus,
    [EquipmentType.Guide]: BundleType.RainforestCafe,
    [EquipmentType.Tread]: BundleType.Tread,
    [EquipmentType.Treadplus]: BundleType.TreadPlus,
    [EquipmentType.Row]: BundleType.Row,
    [EquipmentType.Refurbbike]: BundleType.RefurbishedBike,
    [EquipmentType.Refurbbikeplus]: BundleType.RefurbishedBikePlus,
  }[equipmentType];

  if (!dumbBundleConversion) {
    return undefined;
  }
  return toShopModelBundleType(dumbBundleConversion);
};

export default toBundleType;
