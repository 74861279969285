import { Icon, Label, spacing, Support, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import FinancingCtaContentWrapper from './FinancingCtaContentWrapper';
import type FinancingCtaProps from './FinancingCtaProps';

const TruemedCta: React.FC<React.PropsWithChildren<FinancingCtaProps>> = ({ cta }) => {
  const splitText = cta.text.split('|');
  return (
    <StyledFlex>
      <Support>{splitText[0]}</Support>
      <TruemedCTAWrapper>
        <Label size="medium">{splitText[1]}</Label>
        {cta.ctaIcon && <Icon name={cta.ctaIcon} height={12} />}
      </TruemedCTAWrapper>
    </StyledFlex>
  );
};

export default TruemedCta;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  gap: ${spacing[8]};
  padding-top: ${spacing[24]};
`;

const TruemedCTAWrapper = styled(FinancingCtaContentWrapper)`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: fit-content;
`;
