import type {
  TypeComponent_ctaWithUrl,
  TypeComponentCta,
  TypeComponentCtaFields,
  TypeComponentGenericTextWithMediaFields,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import type { LightboxVideoProps } from './lightbox';
import { toLightboxVideoProps } from './lightbox';

export type GenericCtaData = {
  name?: string;
  url?: string;
  text?: string;
  isExternalLink?: boolean;
  driftInteractionId?: string;
  styledAs?: string;
  color?: TypeComponentCtaFields['color'];
  variant?: TypeComponentCtaFields['variant'];
  modal?: TypeComponentGenericTextWithMediaFields | TypeComponentGenericListFields;
  icon?: string;
  lightboxVideo?: LightboxVideoProps;
};

/**
 * Return optional CTA link URL
 * @param data - CTAWithUrl or CTA data
 * @return URL or undefined
 */
export const ctaLinkToUrl = (data?: TypeComponent_ctaWithUrl | TypeComponentCta) =>
  data?.fields.link?.fields.url;

/**
 * Return CTA link URL or fallback route
 * @param data - CTAWithUrl or CTA data
 * @param fallback - (optional) URL to fallack to
 * @return URL
 */
export const ctaLinkToUrlWithFallback = (
  data?: TypeComponent_ctaWithUrl | TypeComponentCta,
  fallback: string = '/',
) => {
  const url = ctaLinkToUrl(data);
  if (url) {
    return url;
  }
  return fallback;
};

/**
 * Return CTA link URL or throw error
 * @param data - CTAWithUrl or CTA data
 * @return URL
 */
export const ctaLinkToUrlRequired = (
  data?: TypeComponent_ctaWithUrl | TypeComponentCta,
) => {
  const url = ctaLinkToUrl(data);
  if (url) {
    return url;
  }
  throw new Error('Missing required CTA url');
};

export const ctaExtractData = (data?: TypeComponentCta): GenericCtaData => {
  return {
    name: data?.fields?.name,
    url: data?.fields?.link?.fields.url,
    text: data?.fields?.text,
    isExternalLink:
      data?.fields?.link?.sys?.contentType?.sys?.id === 'componentExternalLink',
    driftInteractionId: data?.fields?.driftInteractionId,
    styledAs: data?.fields?.styledAs,
    color: data?.fields?.color,
    variant: data?.fields?.variant,
    modal: data?.fields?.modal?.fields,
    icon: data?.fields?.ctaIcon,
    lightboxVideo: data?.fields?.lightboxVideo
      ? toLightboxVideoProps(data?.fields?.lightboxVideo)
      : undefined,
  };
};

export const toCtaProps = (data: TypeComponent_ctaWithUrl | TypeComponentCta) => {
  const isExternalLink =
    data.fields.link?.sys?.contentType.sys.id === 'componentExternalLink';
  const target: '_blank' | undefined = isExternalLink ? '_blank' : undefined;

  const href = ctaLinkToUrl(data);

  return {
    ...data.fields,
    target,
    href,
  };
};
