import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import type { Class, FreeClass } from '@peloton/models/PelotonClass';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { getPagePath } from '@peloton/next/utils/getPagePath';
import {
  checkHasPaginationSlug,
  transformPaginatedPath,
} from '@peloton/next/utils/pagination';
import { fetchClassListingData } from '@ecomm/classes/utils/fetchClassListingData';
import { CLASS_LISTING_LIMIT } from '@ecomm/classes/utils/fetchDisciplineDataApi';
import { getPagefromQuery } from '@ecomm/classes/utils/getPageFromQuery';
import type { PaginationState } from '@ecomm/pagination/LoadMore/LoadMoreContext';
import { LoadMoreProvider } from '@ecomm/pagination/LoadMore/LoadMoreContext';
import { useCacheKey } from '@page-builder/hooks/useCustomPageData';
import type {
  ClassesGridComponent,
  ClassesGridComponentWithClasses,
} from '../ClassesGrid';

interface CustomPageData {
  classes: Class[];
  totalClasses: number;
  freeClass?: FreeClass;
  paginationState?: PaginationState;
}

export const withPagination = (
  Component: ClassesGridComponentWithClasses,
): ClassesGridComponent => {
  const ClassesGridWithPagination: ClassesGridComponent = props => {
    const router = useRouter();
    const locale = useLocale();
    const { mutate } = useSWRConfig();
    const cacheKey = useCacheKey();
    const cacheKeyWithoutPage = cacheKey.split('?')[0];
    const [frozenCacheKey, setFrozenCacheKey] = useState(cacheKey);
    const paginationStateRef = useRef<PaginationState | null>(null);
    const { data } = useSWR<CustomPageData>(frozenCacheKey, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

    const page = getPagefromQuery(router.query);
    const {
      classes: initialClasses,
      totalClasses: initialTotalClasses,
      paginationState,
      freeClass,
      ...additionalData
    } = data || {
      classes: [],
      totalClasses: 0,
    };

    if (paginationState && !paginationStateRef.current) {
      paginationStateRef.current = paginationState;
    }

    const [classes, setClasses] = useState(initialClasses || []);
    const [totalClasses, setTotalClasses] = useState(initialTotalClasses || 0);
    const totalPages = Math.ceil(totalClasses / CLASS_LISTING_LIMIT);

    const getPageHref = (pageIndex: number, includeBasePath = true) => {
      const hrefPath = getPagePath(router, { includeBasePath });
      const hrefQueryParams = new URLSearchParams(router.asPath.split('?')[1] || '');
      hrefQueryParams.set('page', `${pageIndex}`);
      const hrefQueryString = hrefQueryParams.toString();
      const href = `${hrefPath}?${hrefQueryString}`;
      return href;
    };

    const setPage = async (
      pageIndex: number,
      previousPageIndex: number,
      state: PaginationState,
    ) => {
      paginationStateRef.current = state;
      const insertBefore = pageIndex < previousPageIndex;
      const newClassList = await fetchPaginatedClasses({
        query: { ...router.query, page: `${pageIndex}` },
        insertBefore,
      });
      setClasses(newClassList);
      const destination = getPageHref(pageIndex, false);
      router.replace(destination, undefined, { shallow: true });
    };

    useEffect(() => {
      const pathWithoutParams = router.asPath.split('?')[0];
      if (checkHasPaginationSlug(pathWithoutParams)) {
        router.replace(transformPaginatedPath(pathWithoutParams));
      }
    }, []);

    useEffect(() => {
      const frozenCacheKeyWithoutPage = frozenCacheKey.split('?')[0];
      const isDifferentRoute = cacheKeyWithoutPage !== frozenCacheKeyWithoutPage;

      if (isDifferentRoute) {
        setFrozenCacheKey(cacheKey);
      } else {
        mutate(cacheKey, {
          classes,
          freeClass,
          totalClasses,
          paginationState: paginationStateRef.current,
          ...additionalData,
        });
      }
    }, [cacheKey]);

    useEffect(() => {
      setClasses(data?.classes || []);
      setTotalClasses(data?.totalClasses || 0);
    }, [frozenCacheKey]);

    const fetchPaginatedClasses = async ({
      query,
      insertBefore,
    }: {
      query: NextParsedUrlQuery;
      insertBefore: boolean;
    }) => {
      const classListingData = await fetchClassListingData({
        slug: getPagePath(router),
        locale: locale as string,
        query,
      });
      const newClassList = insertBefore
        ? classListingData.classes.concat(classes)
        : classes.concat(classListingData.classes);
      return newClassList;
    };

    return (
      <LoadMoreProvider
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        getPageHref={getPageHref}
        initialState={paginationState}
        listingKey={cacheKeyWithoutPage}
      >
        <Component
          {...props}
          freeClass={freeClass}
          classes={classes}
          totalClasses={totalClasses}
          isPaginated={totalClasses > CLASS_LISTING_LIMIT}
        />
      </LoadMoreProvider>
    );
  };

  return ClassesGridWithPagination;
};
