import { white } from '@pelotoncycle/design-system';
import React from 'react';

type Props = {
  height: number;
  className?: string;
  fill?: string;
  background?: string;
};

export const PlayIcon: React.FC<React.PropsWithChildren<Props>> = ({
  fill = white,
  background = '#222529',
  height,
  className,
}) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <ellipse className="circle" cx="25" cy="25" fill={background} rx="25" ry="25" />
      <path
        d="m26.25 18.125 7.0512821 13.75h-14.1025642z"
        fill={fill}
        stroke={fill}
        transform="matrix(0 1 -1 0 51.25 -1.25)"
        className="triangle"
      />
    </g>
  </svg>
);
