import { white } from '@pelotoncycle/design-system';
import React from 'react';

type Props = {
  height: number;
  className?: string;
};

export const NextArrowIcon: React.FC<React.PropsWithChildren<Props>> = ({
  height,
  className,
}) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 16 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6753 16.0375L0 0.69101L0.663956 0L16 16.0375L0.735662 32L0.0717068 31.309L14.6753 16.0375Z"
      fill={white}
    />
  </svg>
);
