import { Locale } from '@peloton/internationalize/models/locale';
import type { Attributes, Options } from '@ecomm/pg-shop-accessories-display/models';
import { CONFIGURABLE_ATTRIBUTES } from '../constants';
import type { ProductVariant } from '../types.generated';

export const mapProductAttributesToOptions = (
  variants: ProductVariant[],
  locale: string,
): Attributes => {
  const attributesObject = variants.reduce((productVariant, acc) => {
    const sku = acc.sku;
    (acc.attributesRaw || []).forEach(attribute => {
      const { name: attName, value: attrValue } = attribute;
      const options: Options = productVariant[attName]?.options || [];

      const hasOption = options.some(option => option.slug !== attrValue);
      const currentOptions = hasOption ? options : [];

      const existOptions = [
        ...currentOptions,
        {
          name: String(attrValue[locale] || attrValue[Locale.Default] || attrValue), // undefined
          slug: String(attrValue[locale] || attrValue[Locale.Default] || attrValue), // undefined
          sku,
        },
      ];

      productVariant[attName] = {
        options: existOptions,
      };
    });

    return productVariant;
  }, {} as ProductVariant);

  const attributeKeys = Object.keys(attributesObject);

  const varAttributes = attributeKeys
    .map(attrName => {
      const labelTemplate = `${attrName}-label`;
      const labelName = attributesObject[labelTemplate]?.options[0]?.name;

      return {
        name: attributeKeys.includes(labelTemplate) ? labelName : attrName,
        slug: attrName,
        options: attributesObject[attrName].options,
      };
    })
    .filter(attrName => CONFIGURABLE_ATTRIBUTES.includes(attrName.slug));

  return varAttributes;
};
