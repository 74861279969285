import { Label, spacing } from '@pelotoncycle/design-system';
import type { IconNames } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useGlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';
import { a11yOutline } from '@ecomm/buttons/buttons';
import { THEMES as BANNER_THEMES } from '@ecomm/cms-promo-banner/themes';
import type { TypeComponentBanner } from '@page-builder/lib/types';
import Markdown from '@page-builder/utils/Markdown';
import DriftInteractionBanner from './DriftInteractionBanner';
import DualCtaBanner from './DualCtaBanner';
import { MarkdownIcon } from './MarkdownIcon';
import SingleCtaBanner from './SingleCtaBanner';
import TrackedLinkRenderer from './TrackedLinkRenderer';
import UnclickableBanner from './UnclickableBanner';
import { toBannerLinks } from './utils';

type Props = {
  data?: TypeComponentBanner;
};

const Banner: React.FC<React.PropsWithChildren<Props>> = ({ data: bannerData }) => {
  const { globalTheme } = useGlobalUiStateContext();

  if (!bannerData) {
    return null;
  }

  const { name, text, theme } = bannerData.fields;

  const bannerProps = {
    name,
    text,
    theme: globalTheme && BANNER_THEMES[globalTheme] ? globalTheme : theme,
  };

  const links = toBannerLinks(bannerData.fields.links);

  const TextWithDataTestId: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => (
    <StyledLabel
      size="responsive"
      weight="medium"
      data-test-id={`${bannerProps.name}Text`}
    >
      {children}
    </StyledLabel>
  );

  if (links.length && links[0].driftInteractionId) {
    return <DriftInteractionBanner links={links} {...bannerProps} />;
  }

  switch (links?.length) {
    case 2:
      return <DualCtaBanner links={links} {...bannerProps} />;
    case 1:
      return (
        <SingleCtaBanner
          {...bannerProps}
          text={
            <Markdown
              content={bannerProps.text}
              markdown={{
                renderers: {
                  paragraph: TextWithDataTestId,
                  inlineCode: ({ children }) => (
                    <MarkdownIcon
                      bannerTheme={bannerProps.theme}
                      iconName={children as IconNames}
                    />
                  ),
                },
              }}
            />
          }
          links={links}
        />
      );
    default:
      return (
        <UnclickableBanner
          {...bannerProps}
          text={
            <Markdown
              content={bannerProps.text}
              markdown={{
                renderers: {
                  paragraph: TextWithDataTestId,
                  link: TrackedLinkRenderer,
                  inlineCode: ({ children }) => (
                    <MarkdownIcon
                      bannerTheme={bannerProps.theme}
                      iconName={children as IconNames}
                      isUnclickable={true}
                    />
                  ),
                },
              }}
            />
          }
        />
      );
  }
};

export default Banner;

export const toBannerBlock = (
  Component: React.FC<React.PropsWithChildren<Props>>,
  // eslint-disable-next-line react/display-name
): React.FC<React.PropsWithChildren<TypeComponentBanner>> => (
  props: TypeComponentBanner,
) => <Component data={props} />;

const StyledLabel = styled(Label)`
  ${a11yOutline}

  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    span {
      font-size: inherit !important;
      font-weight: inherit !important;
    }
  }

  display: inline;

  ${media.tabletLarge`
    display: inline-flex;
    align-items: center;
    gap: ${spacing[8]};
`}
`;
