import { Flex, LinkButton, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import ModalView from '@ecomm/modal/ModalView';
import { TrackingVideoElement } from '@page-builder/utils/TrackingVideoElement';
import type { LightBoxProps } from '../types';
import { ViewportHeroModalStyles } from '../ViewportHeroModalStyles';

type LightboxModalProps = {
  isOpen: boolean;
  lightbox: LightBoxProps;
  closeHandler: () => void;
  trackingProps: any;
  isMobile: boolean;
  handleLinkClick: (text: string, linkTo?: string) => void;
};

const LightboxModal: React.FC<LightboxModalProps> = ({
  isOpen,
  lightbox,
  closeHandler,
  trackingProps,
  isMobile,
  handleLinkClick,
}) => {
  const {
    lightboxVideo: { src, tracks },
    lightboxCtaLink: { url },
    lightboxCtaText,
  } = lightbox;

  return (
    <StyledModalView
      contentLabel=""
      closeHandler={closeHandler}
      padded={false}
      isOpen={isOpen}
      width={1280}
      data-test-id="lightbox-modal"
    >
      <ViewportHeroModalStyles />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={`${spacing[32]} ${spacing[16]}`}
        gap={spacing[32]}
      >
        <StyledTrackedVideo
          muted={false}
          src={src}
          tracks={tracks || []}
          autoPlay
          controls={!isMobile}
          playsInline
          eventProps={{
            properties: trackingProps,
          }}
        />

        <LinkButton
          color="primary"
          size="medium"
          href={url}
          to={url}
          variant="solid"
          onClick={() => handleLinkClick(lightboxCtaText, url)}
        >
          {lightboxCtaText}
        </LinkButton>
      </Flex>
    </StyledModalView>
  );
};

const StyledModalView = styled(ModalView)`
  background-color: ${grey[90]} !important;

  border-top-left-radius: ${spacing[8]};
  border-top-right-radius: ${spacing[8]};
`;

const StyledTrackedVideo = styled(TrackingVideoElement)`
  width: 100%;
  max-width: 1120px;
`;

export default LightboxModal;
