import { Flex, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import CompareBikesIconSection from './CompareBikesIconSection';
import CompareBikesMediaSection from './CompareBikesMediaSection';
type ContentProps = {
  items: TypeComponentGenericTextWithMedia[];
};

const CompareBikesModalContent: React.FC<React.PropsWithChildren<ContentProps>> = ({
  items,
}) => {
  const { media } = items[0].fields;
  return (
    <StyledFlex justifyContent="space-around">
      {media ? (
        // section with media (image)
        <>
          {items.map((item, i) => (
            <CompareBikesMediaSection item={item} key={item.fields.name} />
          ))}
        </>
      ) : (
        // sections with icon
        <>
          {items.map((item, i) => (
            <CompareBikesIconSection key={item.fields.name} item={item} />
          ))}
        </>
      )}
    </StyledFlex>
  );
};
const StyledFlex = styled(Flex)`
  > div:nth-child(even) {
    background-color: ${grey[30]};
  }
`;
export default CompareBikesModalContent;
