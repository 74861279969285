import { useMemo } from 'react';
import { useMedia } from 'react-use';
import type { Language, ReviewsOrderByInput } from '@ecomm/graphql/types.generated';
import type { ReviewableTypes } from '@ecomm/models/DeviceType';
import { MINIMUM_REVIEWS_QUANTITY } from '@ecomm/reviews/consts/minimum-reviews-quantity';
import type { ReviewQuery } from '@ecomm/reviews/models';
import { REVIEWS_MAXIMUM_SCORE } from '@page-builder/modules/ReviewCards/AIReviewCards/constants';
import { toEquipmentType } from './toEquipmentType';
import { transform } from './transform';
import type {
  Includes,
  ReviewsQueryResponse,
  ReviewsStatsQueryResponse,
  SecondaryRatingAverages,
} from './useUGCQuery';
import { useUGCQuery } from './useUGCQuery';

export type RatingStats = {
  ratingValue: number;
  count: number;
  percentOfTotal: number;
};

export type ReviewWithStatsQueryResult = {
  statsProps: number;
  ratingsDistribution: RatingStats[];
  reviewsProps: ReviewQuery[];
  totalReviews: number;
  secondaryRatingAverages: SecondaryRatingAverages;
};

export const useReviewWithStatsQuery = (
  deviceType: ReviewableTypes,
  orderBy: ReviewsOrderByInput,
  reviewsToDisplay: number,
  defaultReviewQuantities: number,
  lang?: Language,
  excludeFamily?: boolean,
  minimalRating?: number,
  suspend?: boolean,
  overallRating?: number,
): ReviewWithStatsQueryResult => {
  const equipmentType = toEquipmentType(deviceType);
  const isTabletOrMobile = useMedia('(max-width: 1023px)');

  const dataFilteredByMinimalRating = useUGCQuery('getReviews', {
    suspend,
    variables: {
      equipmentType,
      orderBy,
      lang,
      excludeFamily,
      limit: reviewsToDisplay,
      minimalRating,
      overallRating,
    },
  }) as ReviewsQueryResponse;

  const { reviewsProps } = transform({
    deviceType,
    ...dataFilteredByMinimalRating,
  });

  const statsData = useUGCQuery('getReviewsStats', {
    suspend,
    variables: {
      equipmentType,
      lang,
      excludeFamily,
    },
  }) as ReviewsStatsQueryResponse;

  const reviewStats = useMemo(
    () => ({
      reviewsProps: isTabletOrMobile
        ? reviewsProps.slice(0, defaultReviewQuantities)
        : reviewsProps,
      ratingsDistribution: parseRatingsDistribution(
        statsData?.includes,
        statsData?.totalCount,
      ),
      totalReviews: statsData?.totalCount,
      statsProps: statsData?.averageOverallRating,
      secondaryRatingAverages: statsData?.secondaryRatingAverages,
    }),
    [reviewsProps, statsData, isTabletOrMobile, defaultReviewQuantities],
  );

  return statsData?.totalCount >= MINIMUM_REVIEWS_QUANTITY
    ? reviewStats
    : {
        ratingsDistribution: [],
        reviewsProps: [],
        totalReviews: 0,
        statsProps: 0,
        secondaryRatingAverages: {},
      };
};

const parseRatingsDistribution = (rawRatings: Includes[] = [], totalReviews: number) => {
  const parsedRatings: RatingStats[] = [];
  let totalPercent = 0;

  const sorted = rawRatings.filter(Boolean).sort((a, b) => b.RatingValue - a.RatingValue);

  // inject missing ratings in between the existing ones
  sorted.forEach((rating, i) => {
    const expectedPrevious = rating.RatingValue + 1;
    if (i > 0 && sorted[i - 1].RatingValue !== expectedPrevious) {
      parsedRatings.push({ ratingValue: expectedPrevious, count: 0, percentOfTotal: 0 });
    }

    const percentOfTotal = Math.round((rating.Count / totalReviews) * 100);

    totalPercent += percentOfTotal;
    parsedRatings.push({
      ratingValue: rating.RatingValue,
      count: rating.Count,
      percentOfTotal,
    });
  });

  // fill in the remaining ratings if missing
  for (let i = REVIEWS_MAXIMUM_SCORE; i > 0; i--) {
    if (!parsedRatings.some(rating => rating.ratingValue === i)) {
      parsedRatings.push({
        ratingValue: i,
        count: 0,
        percentOfTotal: 0,
      });
    }
  }

  parsedRatings.sort((a, b) => b.ratingValue - a.ratingValue);

  // ensure total percent matches 100
  const firstWithValue = parsedRatings.find(rating => rating.percentOfTotal > 0);
  if (firstWithValue) {
    firstWithValue.percentOfTotal -= totalPercent - 100;
  }

  return parsedRatings;
};
