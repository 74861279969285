import { ContentLocale } from '@peloton/internationalize';
import type { Class } from '@peloton/models/PelotonClass';

export const getCaptions = (currentClass: Class) => {
  const { captions, hasClosedCaptions } = currentClass;
  if (!hasClosedCaptions || !captions) return [];

  const supportedLocales = Object.values(ContentLocale) as string[];
  const filteredCaptions = captions.filter(caption => supportedLocales.includes(caption));

  return filteredCaptions;
};
