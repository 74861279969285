import React from 'react';
import type { TypePageFields } from '@page-builder/lib/types';

export type PageContextValue = Omit<TypePageFields, 'content'> &
  Partial<TypePageFields> & {
    additionalTrackingData?: any;
  };

const PageContext = React.createContext<PageContextValue>({
  name: '',
  title: '',
  slug: '',
  additionalTrackingData: {},
});

export default PageContext;
