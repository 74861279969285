import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import MapMarker from '@ecomm/map-marker/MapMarker';

import pelotonPin from './img/super_red_peloton_pin.png';

const pin = () => ({
  url: pelotonPin,
  scaledSize: google && new google.maps.Size(30, 36),
});

const GOOGLE_MAP_DEFAULT_OPTIONS: google.maps.MapOptions = {
  styles: [],
  streetViewControl: false,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  zoomControl: true,
  clickableIcons: false,
};

type MapProps = {
  latitude: number;
  longitude: number;
  zoom?: number;
  options?: google.maps.MapOptions;
  children?: React.ReactNode;
};

const Map: React.FC<MapProps> = ({ latitude, longitude, zoom = 15, options = {} }) => {
  const defaultOptions = { ...GOOGLE_MAP_DEFAULT_OPTIONS, ...options };

  return (
    // @ts-expect-error (TODO:robinojw  react-google-maps types do not include implicit children for React 18. This is a known issue.)
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={{ lat: latitude, lng: longitude }}
      defaultOptions={defaultOptions}
    >
      <MapMarker
        position={{ lat: latitude, lng: longitude }}
        icon={pin()}
        isOpen={false}
        clickable={false}
      />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap<MapProps>(Map));
