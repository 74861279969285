import {
  Body,
  Display,
  Container,
  Headline,
  Flex,
  spacing,
  Eyebrow,
} from '@pelotoncycle/design-system';
import type { ReactNode } from 'react';
import React from 'react';
import { Divider } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import { CONTAINER_MAXWIDTH } from '../tokens';
import { useTheme, DARK } from './themes';

export type Props = {
  header: string | ReactNode;
  eyebrow?: string | ReactNode;
  body?: string | ReactNode;
};

const ProductComparisonCardsHeader: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  eyebrow,
  body,
}) => {
  const { name: themeName, productCardAndHeaderTextColor } = useTheme();

  return (
    <>
      {themeName === DARK ? (
        <Flex
          data-test-id="product-comparison-cards-header"
          margin="0 auto"
          maxWidth="fit-content"
          textAlign="center"
          padding={{
            mobile: `${spacing[104]} ${spacing[24]} ${spacing[32]}`,
            desktop: `144px ${spacing[56]} ${spacing[40]}`,
          }}
          flexDirection="column-reverse"
          gap={spacing[16]}
        >
          <Display size="large" textColor={productCardAndHeaderTextColor}>
            {header}
          </Display>
          {eyebrow && (
            <Eyebrow size="small" textColor={productCardAndHeaderTextColor}>
              {eyebrow}
            </Eyebrow>
          )}
          {body && (
            <Flex data-test-id="product-comparison-cards-header_body">
              <Body size="small" textColor={productCardAndHeaderTextColor}>
                {body}
              </Body>
            </Flex>
          )}
        </Flex>
      ) : (
        <Container
          data-test-id="product-comparison-cards-header"
          margin="0 auto"
          maxWidth="fit-content"
          verticalPadding={{
            mobile: spacing[24],
            desktop: spacing[40],
          }}
          horizontalPadding={{
            mobile: spacing[16],
            tablet: spacing[64],
            desktop: spacing[40],
          }}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            maxWidth={CONTAINER_MAXWIDTH}
            gap={{ mobile: spacing[24], desktop: spacing[32] }}
            centered
          >
            <Flex
              textAlign="left"
              flexDirection="column-reverse"
              maxWidth={{
                tablet: '440px',
                desktop: '680px',
              }}
            >
              <Headline size="small" textColor={productCardAndHeaderTextColor}>
                {header}
              </Headline>
              {eyebrow && (
                <Eyebrow size="small" textColor={productCardAndHeaderTextColor}>
                  {eyebrow}
                </Eyebrow>
              )}
            </Flex>
            {body && (
              <Flex data-test-id="product-comparison-cards-header_body">
                <Body size="small" textColor={productCardAndHeaderTextColor}>
                  {body}
                </Body>
              </Flex>
            )}
            <Divider topPadding={0} />
          </Flex>
        </Container>
      )}
    </>
  );
};

export default ProductComparisonCardsHeader;
