import {
  Label,
  grey,
  spacing,
  white,
  black,
  Button,
  Eyebrow,
  Radio,
} from '@pelotoncycle/design-system';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { rgba } from 'polished';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { BreakpointWidth, media } from '@peloton/styles';
import { useMicroCopy } from '@content/client/microCopy';
import { FiltersIcon } from '@ecomm/icons';
import {
  SlideUpModalStyles,
  MODAL_CLOSE_TRANSITION_TIME,
} from '@ecomm/modal/SlideUpModalStyles';
import useWindowPathname from '@page-builder/hooks/useWindowPathname';
import NextModal from '../NextModal/NextModal';
import { Collapsible } from './Collapsible';
import { CLASSES_FILTERS_ID } from './constants';
import type { FiltersWithAvailability } from './helpers/toFiltersWithAvailability';
import type { SelectedFilters, SelectedFiltersSetter } from './hooks/useClassFilters';
import { toPageSlug } from './hooks/useClassFilters';

export type MobileClassesFiltersProps = {
  filters: FiltersWithAvailability;
  initialSelectedState: SelectedFilters;
  selectedFilters: SelectedFilters;
  setSelectedFilters: SelectedFiltersSetter;
  hasSelectedFilters: boolean;
  shrinkFilterButton: boolean;
  clearFilters: () => void;
  disciplineSlug: string;
  requiresSeparation: boolean;
};

export const MobileClassesFilters: React.FC<
  React.PropsWithChildren<MobileClassesFiltersProps>
> = ({
  filters,
  initialSelectedState,
  selectedFilters,
  setSelectedFilters,
  hasSelectedFilters,
  shrinkFilterButton,
  clearFilters,
  disciplineSlug,
  requiresSeparation,
}) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentPageSlug = router.asPath.split('?')[0].split('#')[0];
  const [prefetchedPages, setPrefetchedPages] = useState([currentPageSlug]);
  const classFiltersLabel = useMicroCopy('classFiltersLabel');
  const classFiltersClearLabel = useMicroCopy('classFiltersClearLabel');
  const classFiltersSubmitLabel = useMicroCopy('classFiltersSubmitLabel');
  const openButtonRef = useRef<HTMLButtonElement | null>(null);
  const { trackEvent } = useTracking();
  const pathName = useWindowPathname();
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const hasInitialSelectedFilters = !!Object.values(initialSelectedState).join('');

  const trackClick = useCallback(
    (filterName: string, itemSlug: string) => {
      trackEvent({
        event: 'Clicked Filter Option',
        properties: {
          parentType: 'Component: Generic Dropdown',
          parent: 'Mobile Classes Filters',
          unitName: filterName,
          linkName: itemSlug,
          page: pathName,
        },
      });
    },
    [pathName, trackEvent],
  );

  useEffect(() => {
    if (!isOpen) return;

    const handleResize = debounce(() => {
      const isAboveTablet = window.matchMedia(
        `(min-width: ${BreakpointWidth.tabletXLarge}px)`,
      ).matches;
      if (isAboveTablet) {
        setIsOpen(false);
      }
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      modalBodyRef.current
        ?.querySelector<HTMLInputElement>('input[type="radio"]:checked')
        ?.focus({
          preventScroll: true,
        });
    }, 100);
  }, [isOpen]);

  useEffect(() => {
    const targetPageSlug = toPageSlug(disciplineSlug, selectedFilters);

    if (prefetchedPages.includes(targetPageSlug)) {
      return;
    }

    setPrefetchedPages(prefetchedPages.concat([targetPageSlug]));
    router.prefetch(targetPageSlug);
  }, [selectedFilters]);

  const applyFilters = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const targetHash = !hasSelectedFilters ? `#${CLASSES_FILTERS_ID}` : '';
    const targetPageSlug = toPageSlug(disciplineSlug, selectedFilters);
    setIsLoading(true);
    await router.push(targetPageSlug + targetHash);
    setIsOpen(false);
    setIsLoading(false);
    openButtonRef.current?.focus({ preventScroll: true });
  };

  return (
    <>
      <SlideUpModalStyles />

      {/* Filters */}
      <StyledFiltersWrapper
        requiresSeparation={requiresSeparation}
        data-test-id="mobile-classes-filters-modal-button-wrapper"
      >
        <StyledFiltersButton
          isHighlighted={hasInitialSelectedFilters}
          onClick={() => setIsOpen(true)}
          data-test-id="mobile-classes-filters-modal-button"
          ref={openButtonRef}
        >
          <Label size="small" is="span">
            <Collapsible collapseWidth={shrinkFilterButton} toggleInert={false}>
              <StyledFiltersLabel>{classFiltersLabel}</StyledFiltersLabel>
            </Collapsible>
          </Label>
          <FiltersIcon />
        </StyledFiltersButton>
      </StyledFiltersWrapper>

      {/* Modal */}
      <StyledNextModal
        id="prospects-slide-up-modal"
        isOpen={isOpen}
        contentLabel={classFiltersLabel}
        closeHandler={() => setIsOpen(false)}
        closeTimeoutMS={MODAL_CLOSE_TRANSITION_TIME}
      >
        <div
          className="slide-up-modal-body"
          data-test-id="mobile-classes-filters-modal-body"
          ref={modalBodyRef}
        >
          <StyledForm role="search" onSubmit={applyFilters}>
            {filters.map(({ label: filterLabel, name, options }) => (
              <StyledFieldsetWrapper key={`filter-${name}`}>
                <fieldset>
                  {/* Filter label */}
                  <StyledLegend>
                    <Label size="extraLarge" is="span">
                      {filterLabel}
                    </Label>
                  </StyledLegend>

                  {/* Filter options */}
                  <ol>
                    {options.map(({ label, value, hasResults }) => {
                      return (
                        <StyledFilterOption
                          as="li"
                          key={`filter-${name}-${value || 'all'}`}
                        >
                          <StyledRadioContainer>
                            <Radio
                              onClick={() => trackClick(name, value)}
                              labelText={label}
                              name={name}
                              value={value}
                              disabled={!hasResults}
                              checked={selectedFilters[name] === value}
                              controlled
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  [name]: value,
                                })
                              }
                              theme="dark"
                            />
                          </StyledRadioContainer>
                        </StyledFilterOption>
                      );
                    })}
                  </ol>
                </fieldset>
              </StyledFieldsetWrapper>
            ))}

            {/* Controls */}
            <StyledStickyBar>
              {/* Reset */}
              <StyledResetButton
                type="reset"
                disabled={!hasSelectedFilters}
                onClick={clearFilters}
                data-test-id="mobile-classes-filters-reset-button"
              >
                <StyledResetButtonLabel>
                  <Eyebrow size="medium" as="span">
                    {classFiltersClearLabel}
                  </Eyebrow>
                </StyledResetButtonLabel>
              </StyledResetButton>

              {/* Submit */}
              <StyledSubmitButton
                size="small"
                type="submit"
                color="light"
                data-test-id="mobile-classes-filters-submit-button"
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                {classFiltersSubmitLabel}
              </StyledSubmitButton>
            </StyledStickyBar>
          </StyledForm>
        </div>
      </StyledNextModal>
    </>
  );
};

const StyledFiltersWrapper = styled.div<{ requiresSeparation: boolean }>`
  padding: 0 ${spacing[8]} 0 0;
  flex-shrink: 0;
  padding-right: ${spacing[8]};
  border-right: 1px solid
    ${({ requiresSeparation }) => (requiresSeparation ? grey[70] : 'transparent')};

  ${media.tabletXLarge`
    display: none;
  `}
`;

const StyledFiltersLabel = styled.span`
  display: block;
  padding-right: ${spacing[4]};
`;

const StyledFiltersButton = styled.button<{ isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: ${11 / 16}rem ${spacing[12]};
  border: 1px solid ${grey[50]};
  border-radius: 8px;
  ${({ isHighlighted }) =>
    isHighlighted
      ? `
    background-color: ${rgba(grey[70], 0.25)};
    border-color: ${white};
  `
      : ''}
`;

const StyledForm = styled.form`
  padding-bottom: ${spacing[48]};
`;

const StyledFieldsetWrapper = styled.div`
  margin-bottom: ${spacing[32]};

  & + & {
    padding-top: ${spacing[24]};
    border-top: 1px solid ${grey[70]};
  }
`;

const StyledLegend = styled.legend`
  display: block;
`;

const StyledFilterOption = styled.div`
  margin-top: ${spacing[16]};

  & .visible-input {
    background: transparent;
  }
`;

const StyledStickyBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing[16]} ${spacing[24]} ${spacing[24]};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${black};
`;

const StyledResetButton = styled.button`
  align-self: center;
  min-height: ${40 / 16}rem;
  color: ${white};
  transition: color 0.15s ease-out;

  &:disabled {
    color: ${grey[50]};
  }
`;

const StyledResetButtonLabel = styled.span`
  display: block;
  background-image: linear-gradient(180deg, currentColor, currentColor);
  background-size: 100% 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
`;

const StyledSubmitButton = styled(Button)`
  width: auto !important;
  min-width: 10rem !important;
`;

const StyledNextModal = styled(NextModal)``;

const StyledRadioContainer = styled.div`
  * {
    transition: color 0.2s ease-out;
  }
`;
