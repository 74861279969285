export enum ErrorMessage {
  BAD_REQUEST = 'Bad Request',
  UNAUTHORIZED = 'Unauthorized',
  FORBIDDEN = 'Forbidden',
  NOT_FOUND = 'Not Found',
  METHOD_NOT_ALLOWED = 'Method Not Allowed',
  TOO_MANY_REQUESTS = 'Rate limit exceeded, too many requests',
  SERVER_ERROR = 'Internal Server Error 500: Something went wrong',
  SERVER_UNAVAILABLE = 'Server Unavailable',
  HEALTH_CHECK_ERROR = 'Health Check failure',
}

export enum StatusCode {
  OK = 200,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  TOO_MANY_REQUESTS = 429,
  SERVER_ERROR = 500,
  SERVER_UNAVAILABLE = 503,
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
}
