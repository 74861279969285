import { useGuestPassInfo } from '@prospects/guest-pass/hooks/useGuestPassInfo';
import { useRouter } from 'next/router';
import React, { forwardRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { toClient } from '@peloton/api/NextClient';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import type { ClassWithFreeClass } from '@peloton/models/PelotonClass';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useProductStates } from '@ecomm/product-states/Context';
import { useCustomPageData } from '@page-builder/hooks/useCustomPageData';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import ClassTitle from '@page-builder/modules/ClassPage/modules/ClassTitle';
import { MembershipSection } from '@page-builder/modules/ClassPage/modules/MembershipSection/MembershipSection';
import { nameToModuleId } from '@page-builder/utils/helpers';
import {
  ClassDetailsEquipment,
  ClassDetailsFeaturedArtists,
  ClassDetailsPlaylist,
  ClassDetailsClassPlan,
  ClassDetailsMoreInfo,
  ClassDetailsBodyActivity,
} from '../ClassesGrid';
import { ClassDetailsWaysToAccess } from '../ClassesGrid/Modal/ClassDetailsWaysToAccess';
import { addCfuToEquipment } from '../ClassesGrid/utils/addCfuToEquipment';
import { toCloudinaryImage } from '../ClassesGrid/utils/toCloudinaryImage';
import {
  StyledClassContainer,
  StyledBackground,
  StyledClassPage,
  StyledPaddedContainer,
  StyledMediaContainer,
  StyledHeroGradient,
  StyledFixedHero,
  StyledFixedHeroBox,
  StyledClassMedia,
  StyledMirroredMedia,
  StyledBlurredArea,
} from './ClassPage.styles';
import BackToDisciplineLink from './modules/BackToDisciplineLink';
import { ClassHeadband } from './modules/ClassHeadband/ClassHeadband';
import { FreeClassSection } from './modules/FreeClassSection';
import { TrialBanner } from './modules/TrialBanner';
import { getCaptions } from './utils/getCaptions';
import {
  getCFUBannerData,
  getCFUByDiscipline,
  getWaysToAccessData,
} from './utils/getCFUbyDiscipline';
import type { BannerData } from './utils/getCFUbyDiscipline';
import { getStaticHeadbandTitle, getHeadbandData } from './utils/getHeadbandData';
import { getLogInSectionData } from './utils/getLogInSectionData';
import { mapClassPageCTAsFromContentfulToProps } from './utils/mapClassPageCTAsFromContentfulToProps';
import { mapClassPageLabelsFromContentfulToProps } from './utils/mapClassPageLabelsFromContentfulToProps';
import { mapDisciplineToClassLabel } from './utils/mapDisciplineToClassLabel';
import useIsClassDetailPageVideoExperimentActive from './utils/useIsClassDetailPageVideoExperimentActive';

const CTAScrollWrapper = forwardRef(function CTAScrollWrapper(
  { children }: { children: React.ReactNode },
  ref: React.MutableRefObject<HTMLDivElement | null>,
) {
  return <div ref={ref}>{children}</div>;
});

const ClassPage: React.FC<React.PropsWithChildren<TypeComponentGenericListFields>> = ({
  name,
  items,
  ctas,
}) => {
  const { isProductAvailableForMarketingPages } = useProductStates();
  const classPageCfuBannerCta = useIsToggleActive()('classPageCfuBannerCta');
  const isVideoTestActive = useIsClassDetailPageVideoExperimentActive();
  const moduleId = nameToModuleId(name);
  const currentClass = useCustomPageData<ClassWithFreeClass>()!;
  const client = toClient({});
  const { api } = useExtLinkEnv();
  const guestPass = useGuestPassInfo(client, api);
  const router = useRouter();
  const fitnessDisciplineBreadcrumb =
    router.basePath === '/classes'
      ? router.asPath.split('/')[1]
      : router.asPath.split('/')[2];
  const capitalizedDiscipline = `${fitnessDisciplineBreadcrumb[0].toUpperCase()}${fitnessDisciplineBreadcrumb
    .slice(1)
    .toLowerCase()}`;

  const {
    title,
    imageUrl,
    playlist,
    description,
    segments,
    equipmentTags,
    fitnessDiscipline,
    muscleGroupPercentages,
    freeClass,
  } = currentClass;
  const showFreeClass = isVideoTestActive && freeClass;

  const classPageLabels = mapClassPageLabelsFromContentfulToProps(items);

  const equipmentTagsWithCfu = addCfuToEquipment(
    equipmentTags || [],
    fitnessDiscipline,
    classPageLabels,
  );

  const CFU = getCFUByDiscipline(fitnessDiscipline);
  const isProductAvailable = isProductAvailableForMarketingPages(CFU.product);
  const bannerData = getCFUBannerData(
    CFU.slug,
    items,
    isProductAvailable,
    classPageCfuBannerCta,
  );

  const waysToAccessCFUData = getWaysToAccessData(CFU.slug, items, 'CFU');
  const waysToAccessAppData = getWaysToAccessData(CFU.slug, items, 'APP');

  const isCfuBasedClass = CFU.slug !== 'App';

  const shouldShowWaysToAccessCTAs =
    isProductAvailable &&
    !!waysToAccessAppData &&
    !!waysToAccessCFUData &&
    isCfuBasedClass;

  const shouldShowTrialBanner = !isCfuBasedClass || !isProductAvailable;

  const backToDisciplineLink = ctas?.find(
    cta => cta.fields.name === `backTo${capitalizedDiscipline}ClassesCTA`,
  );
  const defaultBackLink = ctas?.find(cta => cta.fields.name === 'backToClassesCTA');

  const { joinForFree, membershipSection } = mapClassPageCTAsFromContentfulToProps(items);

  const headbandKey = guestPass?.guestPassCode ? 'guestPassLabel' : 'headbandLabel';
  const staticHeadbandTitle = getStaticHeadbandTitle(headbandKey, items);
  const headbandData = getHeadbandData(headbandKey, title, items, guestPass)!;
  const logInSectionData = getLogInSectionData(items);

  const [scrollCtaRef, ctaInView, entry] = useInView({
    trackVisibility: true,
    delay: 100,
    rootMargin: '-88px',
  });

  const shouldShowHeadband = useCallback(() => {
    const hasScrolledPastCta = (entry?.boundingClientRect.y || 0) < 0;
    const shouldShow = !ctaInView && hasScrolledPastCta;
    return shouldShow;
  }, [ctaInView, entry]);

  const classLabel = mapDisciplineToClassLabel({
    classPageLabels,
    disciplineDisplayName: currentClass.fitnessDisciplineDisplayName,
  });

  const captions = getCaptions(currentClass);

  return (
    <StyledBackground data-test-id={'class-page'}>
      <StyledClassPage>
        {headbandData && (
          <ClassHeadband
            {...headbandData}
            headbandTitle={staticHeadbandTitle!}
            shouldShowHeadband={shouldShowHeadband()}
          />
        )}
        <StyledMediaContainer>
          <BackToDisciplineLink
            backToDisciplineLink={backToDisciplineLink}
            defaultBackLink={defaultBackLink}
          />
          <StyledFixedHero>
            <StyledFixedHeroBox>
              <StyledClassMedia
                media={{
                  alt: title,
                  type: 'image',
                  ...toCloudinaryImage({ mobile: imageUrl }, { width: 1800 }),
                }}
                showBackground={false}
              />
              <div aria-hidden="true">
                <StyledMirroredMedia
                  media={{
                    alt: '',
                    type: 'image',
                    ...toCloudinaryImage({ mobile: imageUrl }, { width: 1800 }),
                  }}
                  showBackground={false}
                />
              </div>
              <StyledBlurredArea />
            </StyledFixedHeroBox>
            <StyledHeroGradient />
          </StyledFixedHero>
          <ClassTitle currentClass={currentClass} classPageLabels={classPageLabels} />
        </StyledMediaContainer>
        <StyledClassContainer id={moduleId}>
          <CTAScrollWrapper ref={scrollCtaRef}>
            <MembershipSection
              headbandTitle={staticHeadbandTitle}
              guestPass={guestPass}
              primaryCta={
                guestPass?.guestPassCode
                  ? { ...headbandData.cta, url: headbandData.cta.href }
                  : joinForFree[0]
              }
              membershipSectionCTAs={membershipSection}
              className={currentClass.title}
              classId={currentClass.id}
              discipline={currentClass.fitnessDisciplineDisplayName}
              logInSection={logInSectionData}
            />
          </CTAScrollWrapper>
          <StyledPaddedContainer>
            {showFreeClass && <FreeClassSection freeClass={freeClass} />}
            {shouldShowWaysToAccessCTAs && (
              <ClassDetailsWaysToAccess
                waysToAccessLabel={classPageLabels.waysToAccessLabel}
                fitnessDiscipline={classLabel}
                waysToAccessCFUData={waysToAccessCFUData as BannerData}
                waysToAccessAppData={waysToAccessAppData as BannerData}
              />
            )}

            {!!description && (
              <ClassDetailsMoreInfo
                description={description}
                isExplicit={currentClass.isExplicit}
                captions={captions}
                moreInfoLabel={classPageLabels.moreInfoLabel}
                explicitLabel={classPageLabels.explicitLabel}
                subtitlesLabel={classPageLabels.subtitlesLabel}
                contentSensitivityLabel={classPageLabels.contentSensitivityLabel}
              />
            )}
            <ClassDetailsEquipment
              equipmentTags={equipmentTagsWithCfu}
              equipmentLabel={classPageLabels.equipmentLabel}
            />
            {playlist?.topArtists && playlist?.topArtists?.length > 0 && (
              <ClassDetailsFeaturedArtists
                playlist={playlist}
                featuringMusicByLabel={classPageLabels.featuringMusicByLabel}
              />
            )}
            {playlist?.songs && playlist?.songs?.length > 0 && (
              <BreakpointSwitchCSS
                breakpoints={{
                  mobile: (
                    <ClassDetailsPlaylist
                      namespace="mobile-"
                      playlist={playlist}
                      playlistLabel={classPageLabels.playlistLabel}
                      viewMoreLabel={classPageLabels.viewMoreLabel}
                      viewLessLabel={classPageLabels.viewLessLabel}
                      numColumns={1}
                    />
                  ),
                  tablet: (
                    <ClassDetailsPlaylist
                      playlist={playlist}
                      playlistLabel={classPageLabels.playlistLabel}
                      viewMoreLabel={classPageLabels.viewMoreLabel}
                      viewLessLabel={classPageLabels.viewLessLabel}
                      numColumns={2}
                    />
                  ),
                }}
              />
            )}
            {segments && segments.length > 0 && (
              <ClassDetailsClassPlan
                segments={segments}
                classPlanLabel={classPageLabels.classPlanLabel}
                movementLabel={classPageLabels.movementLabel}
                detailedSection
              />
            )}
            {muscleGroupPercentages && muscleGroupPercentages.length > 0 && (
              <ClassDetailsBodyActivity
                muscleGroupScore={muscleGroupPercentages}
                muscleGroupLabel={classPageLabels.muscleGroupLabel}
                otherGroupLabel={classPageLabels.otherGroupLabel}
                primaryMuscleGroupLabel={classPageLabels.primaryMuscleGroupLabel}
                secondaryMuscleGroupLabel={classPageLabels.secondaryMuscleGroupLabel}
                tertiaryMuscleGroupLabel={classPageLabels.tertiaryMuscleGroupLabel}
              />
            )}
            {!!bannerData && shouldShowTrialBanner && (
              <TrialBanner {...bannerData} productSlug={CFU.product} />
            )}
          </StyledPaddedContainer>
        </StyledClassContainer>
      </StyledClassPage>
    </StyledBackground>
  );
};

export default ClassPage;
