import { Eyebrow, Headline, Body, Flex, spacing } from '@pelotoncycle/design-system';
import type { FlexProps } from '@pelotoncycle/design-system';
import type { ValidTypographyHTMLTags } from '@pelotoncycle/design-system/types';
import type { ReactNode, CSSProperties } from 'react';
import React from 'react';
import { TEXT_MAXWIDTH } from '@page-builder/modules/tokens';

export type UpperTextBlockTextProps = {
  headlineText?: string | ReactNode;
  headlineHtmlTag?: ValidTypographyHTMLTags;
  eyebrowText?: string | ReactNode;
  eyebrowHtmlTag?: ValidTypographyHTMLTags;
  bodyText?: string | ReactNode;
  bodyHtmlTag?: ValidTypographyHTMLTags;
};

export type UpperTextBlockStyleProps = {
  textColor?: CSSProperties['color'];
  eyebrowTextColor?: CSSProperties['color'];
  headlineTextColor?: CSSProperties['color'];
  bodyTextColor?: CSSProperties['color'];
  textAlign?: FlexProps['textAlign'];
  maxWidth?: FlexProps['maxWidth'];
  margin?: FlexProps['margin'];
  hasDropShadow?: boolean;
  headlineSize?: 'small' | 'large' | 'medium' | undefined;
  style?: CSSProperties;
};

export type UpperTextBlockProps = UpperTextBlockTextProps & UpperTextBlockStyleProps;

const defaults: UpperTextBlockProps = {
  textAlign: 'center',
  maxWidth: TEXT_MAXWIDTH,
  margin: {
    mobile: `0 auto ${spacing[32]}`,
    tablet: `0 auto ${spacing[40]}`,
    desktop: `0 auto ${spacing[56]}`,
  },
};

/**
 * @todo do we want to forwardRef here?
 * @deprecated prefer import { UpperTextBlock } from '@pelotoncycle/page-builder'
 */

const UpperTextBlock: React.FC<React.PropsWithChildren<UpperTextBlockProps>> = ({
  textColor,
  eyebrowText,
  headlineText,
  bodyText,
  eyebrowTextColor,
  headlineTextColor,
  bodyTextColor,
  textAlign = defaults.textAlign,
  maxWidth = defaults.maxWidth,
  margin = defaults.margin,
  hasDropShadow,
  bodyHtmlTag = 'p',
  headlineHtmlTag = 'h1',
  eyebrowHtmlTag = 'h2',
  headlineSize = 'medium',
  style,
}) => {
  return (
    <Flex
      removeOuterMargins
      flexDirection="column"
      textAlign={textAlign}
      maxWidth={maxWidth}
      margin={margin}
      style={style}
    >
      {eyebrowText && (
        <Eyebrow
          size="small"
          marginBottom={spacing[16]}
          textColor={eyebrowTextColor || textColor}
          hasShadow={hasDropShadow}
          as={eyebrowHtmlTag}
        >
          {eyebrowText}
        </Eyebrow>
      )}
      {headlineText && (
        <Headline
          size={headlineSize}
          marginBottom={{ mobile: spacing[16], tablet: spacing[24] }}
          textColor={headlineTextColor || textColor}
          hasShadow={hasDropShadow}
          as={headlineHtmlTag}
        >
          {headlineText}
        </Headline>
      )}
      {bodyText && (
        <Body
          size="small"
          marginBottom={spacing[16]}
          textColor={bodyTextColor || textColor}
          hasShadow={hasDropShadow}
          as={bodyHtmlTag}
        >
          {bodyText}
        </Body>
      )}
    </Flex>
  );
};

export default UpperTextBlock;
