export const toFormattedReviewRating = (rating: number): number =>
  Number((Math.round(rating / 0.1) * 0.1).toPrecision(2));

type ReviewAuthorProps = {
  authorName?: string;
  city?: string;
  state?: string;
  authorLocation?: string | null;
};

export const toFormattedReviewAuthor = ({
  authorName,
  city,
  state,
  authorLocation,
}: ReviewAuthorProps): string => {
  const name = authorName || 'Anonymous';
  if (authorLocation) return `${name}, ${authorLocation}`;
  else if (city && !state) return `${name}, ${city}`;
  else if (state && !city) return `${name}, ${state}`;
  else if (state && city) return `${name}, ${city}, ${state}`;
  else return name;
};
