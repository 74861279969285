import type { SyntheticEvent, VideoHTMLAttributes } from 'react';
import { useTracking } from 'react-tracking';
import { TrackingEvent } from '@ecomm/analytics/models';

type VideoProps = VideoHTMLAttributes<HTMLVideoElement>;

type VideoEventHandlers = Pick<
  VideoProps,
  'onPlay' | 'onPause' | 'onEnded' | 'onCanPlay'
>;

export type Params = VideoEventHandlers & {
  properties?: Record<string, any>;
};

const toTarget = (e: SyntheticEvent<HTMLVideoElement>) => {
  return e.currentTarget;
};

const useVideoAnalyticsEventHandlers = ({
  properties = {},
  onCanPlay,
  onPlay,
  onPause,
  onEnded,
}: Params): VideoEventHandlers => {
  const { trackEvent } = useTracking();

  const trackVideo = (eventType: string, target: HTMLVideoElement) => {
    trackEvent({
      event: eventType,
      properties: {
        length: Math.floor(target.duration),
        currentTime: Math.floor(target.currentTime),
        ...properties,
      },
    });
  };

  return {
    onCanPlay: e => {
      const target = toTarget(e);
      if (target.autoplay) {
        trackVideo(TrackingEvent.VideoContentStarted, target);
        onCanPlay?.(e);
      }
    },
    onPlay: e => {
      trackVideo(TrackingEvent.VideoContentPlayed, toTarget(e));
      onPlay?.(e);
    },
    onPause: e => {
      const target = toTarget(e);
      // Browsers seem to fire the pause event when the video ends, but we only want to track when the user pauses it
      if (!target.ended) {
        trackVideo(TrackingEvent.VideoContentPaused, target);
      }
      onPause?.(e);
    },
    onEnded: e => {
      trackVideo(TrackingEvent.VideoContentCompleted, toTarget(e));
      onEnded?.(e);
    },
  };
};

export default useVideoAnalyticsEventHandlers;
