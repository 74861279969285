import type { Entry } from 'contentful';
import React from 'react';
import type { ComponentType } from 'react';
import type {
  TypeComponentGenericTextWithMediaFields,
  TypeComponentTextWithInlineMediaFields,
  TypeComponentFormFields,
  TypePage,
} from '../../../lib/types';
import type { BlockRendererProps } from '../types';

type TemplateOptions =
  | TypeComponentTextWithInlineMediaFields['template']
  | TypeComponentGenericTextWithMediaFields['treatment']
  | TypeComponentFormFields['treatment'];

export type BlockMap = {
  [k: string]:
    | ComponentType<React.PropsWithChildren<any>>
    | ((parent: Entry<TypePage>) => JSX.Element);
};

const getClassName = (
  contentTypeId: string,
  index?: number,
  template?: TemplateOptions,
) => {
  let className = template?.split(' ').join('_') || contentTypeId;

  if (index === 0) {
    className += ` first-block-offset`;
  }

  return className;
};

type Options = BlockRendererProps & { map: BlockMap };

// TODO: Update block type to global module type union when created
const getBlockProps = ({ block, map, index, fallback }: Options) => {
  const contentTypeId = block.sys.contentType.sys.id;
  let Component = map[contentTypeId];

  if (!Component) {
    // We need to have this check to avoid a server error in Next (client error is preferred)
    if (typeof window !== 'undefined' && !fallback) {
      throw new Error(`${contentTypeId} can not be handled`);
    } else {
      Component = fallback ? () => <>{fallback}</> : () => null;
    }
  }

  const { id } = block.sys;

  const props = {
    ...block,
    parent: block.parent,
  };

  const template = block.fields.template || block.fields.treatment;
  const className = getClassName(contentTypeId, index, template);

  return { id, props, className, contentTypeId, template, Component };
};

export default getBlockProps;
