import { spacing, grey } from '@pelotoncycle/design-system';
import styled from 'styled-components';

const FinancingCtaContentWrapper = styled.span`
  border-bottom: 1px solid currentColor;
  line-height: 1.7;

  &,
  svg {
    transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  svg {
    margin-left: ${spacing[4]};
  }

  &:hover {
    color: ${grey[70]};

    svg {
      opacity: 0.7;
    }
  }
`;

export default FinancingCtaContentWrapper;
