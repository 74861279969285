import { CocoEntryPoint } from '../../../../apps/freeform/modules/champ-ui/CocoEntryPoint';
import { CompareModule } from '../../../../apps/freeform/modules/champ-ui/CompareModule';
import Headband from '../../../../apps/freeform/modules/champ-ui/Headband';
import MediaCarousel from '../../../../apps/freeform/modules/champ-ui/MediaCarousel/MediaCarousel';
import { TableOfContents } from '../../../../apps/freeform/modules/champ-ui/TableOfContents';
import { ToggledCarousels } from '../../../../apps/freeform/modules/champ-ui/ToggledCarousels';
import ViewportHero from '../../../../apps/freeform/modules/champ-ui/ViewportHero/ViewportHero';
import CollectionCardRow from '../../../../apps/freeform/modules/prospects-ui/CollectionCardRow/CollectionCardRow';
import DisciplineList from '../../../../apps/freeform/modules/prospects-ui/DisciplineList/DisciplineList';
import HeroBanner from '../../../../apps/freeform/modules/prospects-ui/HeroBanner/HeroBanner';
import TabsListing from '../../../../apps/freeform/modules/prospects-ui/TabsListing';
import TestHero from '../../../../apps/freeform/modules/TestHero';

// Key should match `component` value in Component: JSON content type
const JsonComponentMap = {
  ['CollectionCardRow (PROSPECTS)']: CollectionCardRow,
  ['CompareModule (CHAMP)']: CompareModule,
  ['DisciplineList (PROSPECTS)']: DisciplineList,
  ['Headband (CHAMP)']: Headband,
  ['HeroBanner (PROSPECTS)']: HeroBanner,
  ['MediaCarousel (CHAMP)']: MediaCarousel,
  ['TableOfContents (CHAMP)']: TableOfContents,
  ['TabsListing (PROSPECTS)']: TabsListing,
  ['TestHeroProps (TAKO)']: TestHero,
  ['ToggledCarousels (CHAMP)']: ToggledCarousels,
  ['ViewportHero (CHAMP)']: ViewportHero,
  ['CocoEntryPoint (CHAMP)']: CocoEntryPoint,
};

export default JsonComponentMap;
