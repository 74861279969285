import React from 'react';
import { SHOW_PB_COMPONENT_ERRORS } from '@peloton/app-config';
import type { ReportError } from '@peloton/error-reporting/ErrorReporter';

type Props = {
  renderError: (props: RenderProps) => JSX.Element;
  reportError?: ReportError;
  children: React.ReactNode;
  tags?: Record<string, string>;
};

export type RenderProps = {
  error: Error | undefined;
};

type State = RenderProps;

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: undefined };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorProps = { tags: this.props.tags, extra: { ...errorInfo } };
    this.props.reportError?.(error, errorProps);
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, renderError } = this.props;
    const showCustomErrorMessage = SHOW_PB_COMPONENT_ERRORS as boolean | undefined;

    if (error) {
      if (showCustomErrorMessage) {
        return renderError({ error });
      }

      console.error(error);
      // React expects a fallback component to be returned
      return <></>;
    }

    return children;
  }
}
