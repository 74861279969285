import { spacing } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles/breakpoints';

export const CarouselGradient = styled.div`
  ${media.desktopLarge`
    position: absolute;
    top: 0;
    right: 0;
    width: ${spacing[88]};
    height: 450px;
    margin-top: ${spacing[48]};
    pointer-events: none;
    background-image: linear-gradient(90deg, rgba(24, 26, 29, 0) 0%, #181a1d 100%);
  `}
`;
