import {
  Flex,
  Label,
  spacing,
  white,
  Media,
  Badge,
  grey,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { Segment as SegmentType } from '@peloton/models/PelotonClass';
import { media } from '@peloton/styles';
import { toCloudinaryImage } from '../utils/toCloudinaryImage';
import { ClassDetailsCardSection } from './ClassDetailsCardSection';

type Props = {
  segments: SegmentType[];
  classPlanLabel: string;
  detailedSection?: boolean;
  movementLabel?: string;
};

type SegmentProps = {
  iconUrl: string;
  iconName: string;
  segmentLength: number;
  name: string;
};

type DetailsProps = {
  movementLength: number;
  movementLabel: string;
};

const Segment: React.FC<React.PropsWithChildren<SegmentProps>> = ({
  iconUrl,
  iconName,
  segmentLength,
  name,
}) => (
  <Flex gap={spacing[32]} alignItems="center">
    <Media
      width="32px"
      height="32px"
      objectFit="contain"
      media={{
        alt: iconName,
        type: 'image',
        ...toCloudinaryImage({ mobile: iconUrl }, { width: 32 }),
      }}
    />
    <Label size="large" textColor={white} style={{ width: '45px' }}>
      {`${segmentLength} min`}
    </Label>
    <Label size="large" textColor={white}>
      {name}
    </Label>
  </Flex>
);

const DetailedSegment: React.FC<React.PropsWithChildren<SegmentProps & DetailsProps>> = ({
  iconUrl,
  iconName,
  segmentLength,
  name,
  movementLength,
  movementLabel,
}) => {
  const labels = movementLabel.split('/');
  const adjustedMovementLabel = movementLength > 1 ? labels[1] : labels[0];
  const v2IconUrl = !iconUrl.includes('_v2')
    ? iconUrl.replace('.png', '_v2.png')
    : iconUrl;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex gap={spacing[16]} alignItems="center">
        <StyledMedia
          width="24px"
          height="24px"
          objectFit="contain"
          media={{
            alt: iconName,
            type: 'image',
            mobile: v2IconUrl,
            tablet: v2IconUrl,
            desktop: v2IconUrl,
          }}
        />
        <Flex
          gap={{ mobile: spacing[2], tablet: spacing[16] }}
          alignItems={{ mobile: 'start', tablet: 'center' }}
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          style={{ marginLeft: spacing[8] }}
        >
          <Label size="large" textColor={white}>
            {name}
          </Label>
          {movementLength > 0 && (
            <StyledBadge theme="dark" variant="horizontal">
              {movementLength}&nbsp;{adjustedMovementLabel}
            </StyledBadge>
          )}
        </Flex>
      </Flex>
      <Label size="large" textColor={white} style={{ width: '50px' }} textAlign="right">
        {`${segmentLength} min`}
      </Label>
    </Flex>
  );
};

export const ClassDetailsClassPlan: React.FC<React.PropsWithChildren<Props>> = ({
  segments,
  classPlanLabel,
  detailedSection = false,
  movementLabel = '',
}) => {
  return (
    <ClassDetailsCardSection title={classPlanLabel}>
      <ClassPlanList detailedList={detailedSection}>
        {segments.map(({ iconName, iconUrl, name, length, movementCount }, index) => {
          const segmentLength = Math.round(length / 60);
          const movementLength = movementCount;

          return (
            <li key={`${name}-${index}`} data-test-id={`class-segment-${name}`}>
              {detailedSection ? (
                <DetailedSegment
                  iconUrl={iconUrl}
                  iconName={iconName}
                  segmentLength={segmentLength}
                  name={name}
                  movementLength={movementLength}
                  movementLabel={movementLabel}
                />
              ) : (
                <Segment
                  iconUrl={iconUrl}
                  iconName={iconName}
                  segmentLength={segmentLength}
                  name={name}
                />
              )}
            </li>
          );
        })}
      </ClassPlanList>
    </ClassDetailsCardSection>
  );
};

const ClassPlanList = styled.ul<{ detailedList: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.detailedList ? spacing[24] : spacing[32])};

  ${media.tablet`
    padding-left: ${spacing[8]};
  `}
`;

const StyledBadge = styled(Badge)`
  background-color: ${rgba(grey[70], 0.25)};
  font-size: 10px;
`;

export const StyledMedia = styled(Media)`
  * > img {
    filter: invert(61%) sepia(11%) saturate(228%) hue-rotate(186deg) brightness(89%)
      contrast(85%);
  }
`;
