import { Flex, spacing } from '@pelotoncycle/design-system';
import type { TypeComponentGenericTextWithMedia } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import { ContentCard } from './ContentCard';
import { ContentCardsModal } from './Modal/ContentCardsModal';
import { generateRowsOfTwo, getModalSections, toKebabCase } from './utils';

type Props = {
  cards: TypeComponentGenericTextWithMedia[];
  modalTitle?: string;
};

export const ContentCards: React.FC<React.PropsWithChildren<Props>> = ({
  cards,
  modalTitle = '',
}) => {
  const [selectedCard, setSelectedCard] = React.useState<string | undefined>(undefined);
  const modalContent = getModalSections(cards);
  const rowsOfContentCards = generateRowsOfTwo(cards);
  const { trackEvent } = useTracking();

  const handleClick = (title: string) => {
    trackEvent({
      event: TrackingEvent.ClickedLink,
      properties: {
        parentType: 'Component: Generic List',
        parent: 'Content Cards',
        unitName: `Content Card - ${title}`,
        linkName: title,
        linkTo: '',
      },
    });

    setSelectedCard(toKebabCase(title));

    trackEvent({
      event: TrackingEvent.ModalOpened,
      properties: {
        propertyType: 'Web',
        unitName: 'Content Cards Modal',
        unitPlacement: 'Modal',
        modalTrigger: `Clicked ${title} Content Card`,
      },
    });
  };

  const handleCloseRequest = () => {
    trackEvent({
      event: TrackingEvent.ModalClosed,
      properties: {
        propertyType: 'Web',
        unitName: 'Content Cards Modal',
        unitPlacement: 'Modal',
        modalTrigger: 'Closed the Content Cards modal',
      },
    });
    setSelectedCard(undefined);
  };

  return (
    <>
      <StyledFlex
        flexDirection="column"
        gap={{ mobile: spacing[16], desktop: spacing[24] }}
      >
        {rowsOfContentCards.map((rowOfContentCards, rowIndex) => {
          const rowNumber = rowIndex + 1;
          return (
            <Flex
              key={`content-cards-row-${rowNumber}`}
              data-test-id={`content-cards-row-${rowNumber}`}
              flexDirection={{ mobile: 'column', desktop: 'row' }}
              gap={{ mobile: spacing[16], desktop: spacing[24] }}
            >
              {rowOfContentCards.map(contentCard => (
                <ContentCard
                  key={contentCard.fields.name}
                  card={contentCard}
                  handleClick={handleClick}
                />
              ))}
            </Flex>
          );
        })}
      </StyledFlex>
      <ContentCardsModal
        contentLabel={modalTitle}
        selectedItem={selectedCard}
        isOpen={Boolean(selectedCard)}
        closeHandler={handleCloseRequest}
        modalContent={modalContent}
      />
    </>
  );
};

const StyledFlex = styled(Flex)`
  ${media.desktopLarge`
    // Even rows should have the wide card followed by the narrow card
    & > *:nth-child(even) {
      & > *:first-child {
        flex: 7;
      }
      & > *:last-child {
        flex: 5;
      }
    }
    // Odd rows should have the narrow card followed by the wide card
    & > *:nth-child(odd) {
      & > *:first-child {
        flex: 5;
      }
      & > *:last-child {
        flex: 7;
      }
    }
  `}
`;
