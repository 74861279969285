import { useCallback, useContext, useRef, useState } from 'react';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';

const useCollapsiblePanel = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { subHeader } = useContext(GlobalReferenceContext);
  const subheaderHeight = subHeader?.height || 0;

  const outerContentRef = useRef<HTMLDivElement>(null);

  const toggleExpanded = useCallback(() => {
    const newExpandedValue = !isExpanded;

    setTimeout(() => {
      const scrollTarget = outerContentRef.current;
      // If we're going from false => true (newExpandedValue is true) then we want to scroll to bottom of the outer content,
      // which will line up with the panel content. Otherwise, we scroll to the top of the outer content
      const targetProperty = newExpandedValue ? 'bottom' : 'top';

      if (scrollTarget) {
        const scrollTargetPosition =
          scrollTarget.getBoundingClientRect()[targetProperty] || 0;
        const top = scrollTargetPosition + window.scrollY - subheaderHeight;

        window.scrollTo({
          top,
          behavior: 'smooth',
        });

        // We want to try to focus on the first button of the target element, but without scrolling so that it does
        // not mess up our scrolling animation
        const [firstButton] = scrollTarget.querySelectorAll<HTMLButtonElement>(
          'button:not([disabled])',
        );
        if (firstButton) {
          firstButton.focus({ preventScroll: true });
        }
      }
    }, 1);

    setIsExpanded(newExpandedValue);
  }, [subheaderHeight, isExpanded]);

  return {
    isExpanded,
    toggleExpanded,
    outerContentRef,
  };
};

export default useCollapsiblePanel;
