import { useHasVariation } from '@peloton/split-testing/hooks';

const TREAD_PROMO_OVERVIEW_EXPERIMENT = 'Tread Promo Overview';
const TREAD_PROMO_OVERVIEW_VARIATION_1 = 'Variation #1';
const TREAD_PROMO_OVERVIEW_VARIATION_2 = 'Variation #2';

export const useIsTreadPromoOverviewVariation1Active = () => {
  return Boolean(
    useHasVariation(TREAD_PROMO_OVERVIEW_EXPERIMENT, TREAD_PROMO_OVERVIEW_VARIATION_1),
  );
};

export const useIsTreadPromoOverviewVariation2Active = () => {
  return Boolean(
    useHasVariation(TREAD_PROMO_OVERVIEW_EXPERIMENT, TREAD_PROMO_OVERVIEW_VARIATION_2),
  );
};
