import { black, Label, spacing, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition } from '@peloton/styles';

type Props = {
  name: string;
  value: string;
  title: string;
  checked: boolean;
  onChange: () => void;
};

export const RadioButton: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  value,
  title,
  checked,
  onChange,
}) => {
  const id = `${name}_${value}`;
  return (
    <>
      <HiddenRadioInput
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <RadioLabel htmlFor={id}>
        <LabelTextWrapper>
          <LabelText size="large" textColor={checked ? black : white}>
            {title}
          </LabelText>
        </LabelTextWrapper>
      </RadioLabel>
    </>
  );
};

const HiddenRadioInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const LabelTextWrapper = styled.div`
  width: 100%;
  border-left: 1px solid ${rgba(34, 37, 41, 0.25)};
  padding: 0 ${spacing[4]};
`;

const LabelText = styled(Label)`
  line-height: normal;
  padding: ${spacing[16]} 0;
  width: 100%;
  border-radius: 4px;

  ${defaultTransition('background,color')}
`;

const RadioLabel = styled.label`
  cursor: pointer;
  padding: ${spacing[8]} 0;
  text-align: center;
  line-height: 0;

  [data-whatintent='keyboard'] ${HiddenRadioInput}:focus + & {
    outline: 2px auto Highlight;
    outline: 2px auto -webkit-focus-ring-color;
  }

  &:first-of-type ${LabelTextWrapper} {
    border-left: none;
  }

  ${HiddenRadioInput}:checked + & ${LabelText} {
    background: white;
  }
`;
