import { Support, grey, Media, slate, spacing } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { media } from '@peloton/styles';
import type { Product } from '@ecomm/product-states/models/product';
import type { BannerData } from '../utils/getCFUbyDiscipline';

export type CtaBanner = BannerData & {
  productSlug: Product;
};

export const TrialBanner: React.FC<React.PropsWithChildren<CtaBanner>> = ({
  image,
  bannerText,
  productSlug,
  ctaData,
}) => {
  const { trackEvent } = useTracking();

  const trackCTAClick = () =>
    trackEvent({
      event: 'Clicked Link',
      properties: {
        linkTo: ctaData.url,
        linkName: ctaData.text,
        unitName: productSlug,
      },
    });

  return (
    <BannerContainer>
      <StyledMedia
        media={{
          desktop: toCloudinarySrc({
            src: image.desktop || image.mobile,
            height: 117 * 2,
          }),
          mobile: toCloudinarySrc({
            src: image.mobile,
            height: 104,
          }),
          alt: image.alt,
          type: 'image',
          loading: 'lazy',
        }}
      ></StyledMedia>
      <StyledSupport is="h2" size="large" textColor={grey[50]}>
        {bannerText}
      </StyledSupport>
      <LinkButton
        size="small"
        color="light"
        text={ctaData.text}
        variant="outline"
        width="adjustable"
        to={ctaData.url}
        href={ctaData.url}
        onClick={trackCTAClick}
        data-test-id="trialBannerCta"
      />
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${rgba(slate[50], 0.1)};
  padding: ${spacing[8]} 0;
  margin: 0 ${spacing[16]};

  ${media.tablet`
    margin: ${spacing[24]} ${spacing[32]};
  `}

  ${media.desktopLarge`
    padding: ${spacing[24]} 0;
    height: ${spacing[88]};
    margin: ${spacing[32]};
  `}
`;

const StyledSupport = styled(Support)`
  display: none;
  max-width: 206px;

  ${media.desktopLarge`
    display: block;
  `}
`;

const StyledMedia = styled(Media)`
  height: auto;
  width: auto;
  max-width: 104px;

  ${media.desktopLarge`
    max-width: 132px;
    
    picture > img {
     max-height: 117px;
   }
  `}
`;
