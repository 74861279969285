import {
  CommerceToolsChannelAvailability,
  MAP_WEB_TO_COMMERCE_TOOLS_CHANNELS,
  MAP_WEB_TO_TLD_FOR_COMMERCETOOLS_CHANNELS,
} from '@peloton/internationalize/consolidatedAppConfigs/models';
import { toStoreSlug } from '@peloton/stores/Store';
import { Store } from '@peloton/stores/WebStore';

export interface ChannelAvailabilityAttribute {
  key: string;
  label: string;
}

export const toChannelAvailability = (): CommerceToolsChannelAvailability[] => {
  const currentWebStore = toStoreSlug();

  if (currentWebStore === Store.UnitedKingdom) {
    return [
      CommerceToolsChannelAvailability.GbWeb,
      CommerceToolsChannelAvailability.UkWeb,
    ];
  }

  return [MAP_WEB_TO_COMMERCE_TOOLS_CHANNELS[currentWebStore]];
};

export const getChannelAvailability = (
  availabilityAttributes: ChannelAvailabilityAttribute[],
): CommerceToolsChannelAvailability | undefined => {
  const currentChannelAvailability = toChannelAvailability();
  const channelAvailability = availabilityAttributes.find(attribute =>
    currentChannelAvailability.includes(
      attribute?.key as CommerceToolsChannelAvailability,
    ),
  );

  return channelAvailability?.key as CommerceToolsChannelAvailability;
};

export const hasCurrentChannelAvailability = (
  availabilityAttributes: ChannelAvailabilityAttribute[],
): boolean => {
  return getChannelAvailability(availabilityAttributes) !== undefined;
};

export const toWebTld = (): string =>
  MAP_WEB_TO_TLD_FOR_COMMERCETOOLS_CHANNELS[toStoreSlug()];
