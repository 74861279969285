import {
  spacing,
  EyebrowHeadlineBody,
  Button,
  Container,
} from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import type { Themes } from '@page-builder/themes';
import FeatureCardModal from '../FeatureCardModal/FeatureCardModal';
import FeatureCard from './FeatureCard';
import GridCards from './GridCards';
import type { Card, ShowButtons } from './types';
import { DEFAULT_DISPLAY_CARDS_LENGTH } from './types';

type Props = {
  headerBody?: string;
  headerTitle: string;
  cards: Card[];
  parentName: string;
  buttons: ShowButtons;
  modalContent: Card['modal'][];
  theme?: Themes;
};

const FeatureCards: React.FC<React.PropsWithChildren<Props>> = ({
  headerBody,
  headerTitle,
  cards,
  parentName,
  buttons,
  modalContent,
  theme = 'White',
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
  const activeButton = isExpanded ? buttons.lessButton : buttons.moreButton;
  const { trackEvent } = useTracking();

  const handleModalClick = (index: number) => {
    setIsModalOpen(true);
    setSelectedIndex(index);
    trackEvent({
      event: 'Clicked Link',
      properties: {
        parentType: 'Component: Generic List',
        parent: parentName,
        unitName: modalContent[index].name,
        linkName: modalContent[index].header,
        linkTo: '',
      },
    });
  };

  const handleShowMoreClick = () => {
    setIsExpanded(state => !state);
    trackEvent({
      event: 'Clicked Link',
      properties: {
        parentType: 'Component: Generic List',
        parent: parentName,
        unitName: activeButton.name,
        linkName: activeButton.text,
        linkTo: '',
      },
    });
  };

  const buildFeatureCards = (
    { title, images }: Card,
    index: number,
    isVisible: boolean = true,
  ) => {
    return (
      <FeatureCard
        title={title}
        images={images}
        onClick={() => handleModalClick(index)}
        key={`Feature card${index + 1}`}
        testId={`Feature card${index + 1}`}
        isVisible={isVisible}
      />
    );
  };

  let hiddenCards: Card[] = [];

  const visibleGallery = cards.slice(0, DEFAULT_DISPLAY_CARDS_LENGTH);
  if (cards.length > DEFAULT_DISPLAY_CARDS_LENGTH) {
    hiddenCards = cards.slice(DEFAULT_DISPLAY_CARDS_LENGTH);
  }

  const hasHeader = Boolean(headerBody);

  return (
    <>
      <OuterContainer
        verticalPadding={{
          mobile: spacing[32],
          desktop: spacing[80],
        }}
        theme={theme}
        flexDirection="column"
      >
        {hasHeader && (
          <Container
            maxWidth={{ mobile: '100%', tablet: '640px', desktop: '680px' }}
            horizontalPadding={{ mobile: `${spacing[32]}`, tablet: '0' }}
            margin={{
              mobile: `0 auto ${spacing[16]}`,
              tablet: `${spacing[24]} auto ${spacing[48]}`,
              desktop: `0 auto ${spacing[32]}`,
            }}
            textAlign="center"
          >
            <EyebrowHeadlineBody
              size="small"
              eyebrowText={headerTitle}
              headlineText={headerBody}
            />
          </Container>
        )}

        <GridCards>
          {visibleGallery.map((card, index) => buildFeatureCards(card, index, true))}
        </GridCards>
        {hiddenCards.length ? (
          <>
            <GridCards
              isVisible={isExpanded}
              marginTop={{ mobile: spacing[16], tablet: spacing[24] }}
            >
              {hiddenCards.map((card, index) =>
                buildFeatureCards(card, index + 4, isExpanded),
              )}
            </GridCards>

            <Container
              margin={{
                mobile: `${spacing[48]} auto 0`,
                desktop: `${spacing[64]} auto 0`,
              }}
              textAlign="center"
            >
              {cards.length > DEFAULT_DISPLAY_CARDS_LENGTH && (
                <Button
                  variant={activeButton.variant}
                  color={activeButton.color}
                  onClick={handleShowMoreClick}
                >
                  {activeButton.text}
                </Button>
              )}
            </Container>
          </>
        ) : null}
      </OuterContainer>
      <FeatureCardModal
        selectedItem={selectedIndex}
        modalContent={modalContent}
        subnavText={headerTitle}
        contentLabel={headerTitle}
        isOpen={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
          setSelectedIndex(undefined);
        }}
      />
    </>
  );
};

export default FeatureCards;
