import {
  Flex,
  FlexChild,
  Eyebrow,
  Container,
  spacing,
  Headline,
  Body,
  Media,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@peloton/copy/Markdown';
import { media } from '@peloton/styles/breakpoints';
import type { ModalMediaProps } from '../FeatureCards/types';

export type Props = {
  body: string;
  imagePosition: 'left' | 'right';
  media: ModalMediaProps;
};

const FeatureCardModalItem: React.FC<React.PropsWithChildren<Props>> = ({
  body,
  imagePosition,
  media: mediaFiles,
}) => {
  const { primaryMedia, secondaryMedia } = mediaFiles;

  if (!primaryMedia || !secondaryMedia) {
    throw new Error('Missing media for modal section');
  }

  return (
    <Wrapper>
      <Container
        maxWidth={{ desktop: '1200px' }}
        margin="0 auto"
        style={{ boxSizing: 'content-box' }}
      >
        <Flex
          flexDirection={{
            mobile: 'column-reverse',
            desktop: imagePosition === 'left' ? 'row-reverse' : 'row',
          }}
        >
          <FlexChild flex={1} alignSelf="center">
            <Container
              maxWidth={{ mobile: '320px', tablet: '440px', desktop: '332px' }}
              padding={{ mobile: `0 ${spacing[32]}` }}
              style={{ boxSizing: 'content-box' }}
              width="unset"
              margin="0 auto"
              verticalPadding={{
                mobile: spacing[48],
                tablet: spacing[64],
                desktop: 'unset',
              }}
            >
              <StyledMarkdown>
                <Markdown
                  content={body}
                  markdown={{
                    renderers: {
                      heading:
                        // eslint-disable-next-line react/display-name
                        ({ node, ...props }) => {
                          if (props.level === 1) {
                            return (
                              <Headline
                                size="small"
                                style={{ marginBottom: spacing[16] }}
                              >
                                {props.children}
                              </Headline>
                            );
                          } else if (props.level === 2) {
                            return (
                              <Eyebrow size="small" style={{ marginBottom: spacing[16] }}>
                                {props.children}
                              </Eyebrow>
                            );
                          }
                          // in the event a prop level is defined but not dealt
                          // with a default div with the Corresponding aria level defined
                          // No styles will be applied but the content will display.
                          return <div aria-level={props.level}>{props.children}</div>;
                        },
                      paragraph:
                        // eslint-disable-next-line react/display-name
                        ({ node, ...props }) => (
                          <Body
                            size="small"
                            marginBottom={{
                              mobile: spacing[24],
                              desktop: spacing[40],
                            }}
                          >
                            {props.children}
                          </Body>
                        ),
                    },
                  }}
                />
              </StyledMarkdown>
            </Container>
          </FlexChild>
          <FlexChild flex={1} alignSelf={{ desktop: 'center' }}>
            <MediaLayout direction={imagePosition}>
              <MediaWrapper height="100%" direction={imagePosition}>
                <StyledMedia media={primaryMedia} />
              </MediaWrapper>
              <MediaWrapperSmall height="100%" direction={imagePosition}>
                <StyledMedia media={secondaryMedia} />
              </MediaWrapperSmall>
            </MediaLayout>
          </FlexChild>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default FeatureCardModalItem;

const tabletColumnLeft = `calc(75% - ${spacing[48]}) ${spacing[48]} 1fr`;
const tabletColumnRight = `1fr  ${spacing[48]} calc(75% - ${spacing[48]})`;

type LayoutProps = { direction: 'left' | 'right' };

const StyledMarkdown = styled.div`
  h1 + h2 {
    margin-top: calc(${spacing[24]} - ${spacing[16]});
  }
  ${media.desktopLarge`
    h1 + h2 {
      margin-top: calc(${spacing[40]} - ${spacing[16]});
    }
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  ${media.tabletXLarge`
    padding-top: ${spacing[32]};
  `}
  ${media.desktopLarge`
    padding: ${spacing[64]} ${spacing[40]};
  `}
`;

const MediaLayout = styled.div<LayoutProps>`
  display: grid;
  grid-template-columns: ${spacing[32]} 208px auto;
  grid-template-rows: auto ${spacing[88]} ${spacing[120]};

  ${media.tabletXLarge<LayoutProps>`
      margin-inline: ${spacing[64]};
      grid-template-columns: ${({ direction }) =>
        direction === 'left' ? tabletColumnLeft : tabletColumnRight};
      grid-template-rows: 1fr auto 1fr;
  `}

  ${media.desktopLarge<LayoutProps>`
      margin-inline: 0;
      grid-template-columns: ${({ direction }) =>
        direction === 'left' ? `auto 144px ${spacing[64]}` : `${spacing[64]} 144px auto`};
      grid-template-rows: auto 144px ${spacing[64]};
    `}

    ${media.desktopXLarge<LayoutProps>`
      margin-inline: unset;
      grid-template-columns: ${({ direction }) =>
        direction === 'left' ? `auto 192px ${spacing[48]}` : `${spacing[48]} 192px auto`};
      grid-template-rows: auto 192px ${spacing[48]};
    `}
`;

const MediaWrapper = styled(Container)<LayoutProps>`
  aspect-ratio: 1;
  grid-column: 1 / -1;
  grid-row: 1 / -2;

  ${media.tabletXLarge<LayoutProps>`
    grid-column:  ${({ direction }) => (direction === 'left' ? ' 1 / -2' : '-3 / -1')};
    grid-row: 1 / -1;
  `}

  ${media.desktopLarge<LayoutProps>`
    grid-row: 1 / -2;
    grid-column: ${({ direction }) => (direction === 'left' ? '1 / -2' : ' 2 / -1')};
  `}
`;

const MediaWrapperSmall = styled(Container)<LayoutProps>`
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  grid-column: 2;
  grid-row: -3 / -1;
  display: block;
  height: fit-content;

  ${media.tabletXLarge<LayoutProps>`
    grid-column: ${({ direction }) => (direction === 'left' ? '-3 / -1' : '1 / 3')};
    grid-row: 2;
  `}

  ${media.desktopLarge<LayoutProps>`
    grid-row: -3 / -1;
    grid-column: ${({ direction }) => (direction === 'left' ? '-3 / -1' : '1 / 3')};
  `}
`;

const StyledMedia = styled(Media)`
  display: block;

  & > img,
  video {
    transition: transform 0.4s ease 0s;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
