import { Container, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TypeComponent_overview } from '@page-builder/lib/types';
import CFUPackageProvider from '@page-builder/modules/Overview/CFUPackageProvider';
import PromoProvider from '@page-builder/modules/Overview/PromoProvider';
import { ShopDrawersContextProvider } from '@page-builder/modules/Overview/ShopDrawersContextProvider';
import { ctaExtractData } from '@page-builder/utils/helpers';
import { ColorSelectionContextProvider } from './ColorSelectionContext';
import { DrawerContentContextProvider } from './DrawerContentContext';
import Overview from './Overview';
import { ProductConfigurationContextProvider } from './ProductConfigurationContext';
import StructuredDataWrapper from './StructuredData';
import { UpsellAccessoryContextProvider } from './UpsellAccessoryContext';

export const OverviewContainer: React.FC<
  React.PropsWithChildren<TypeComponent_overview>
> = ({
  fields: {
    name,
    reviews,
    ribbon,
    product,
    tabs,
    buyrentToggleContainerTitleLine: waysToShopIntro,
    valueProps,
    nearestShowroom,
    shopDrawers,
    gallery,
    breadcrumbs,
    postalCodeInput,
    availabilityLeadGen,
    soldOutLeadGen,
    colorSelection,
    crossShopToggle,
    colorSpecificGalleries = [],
    aiChatEntrypoint,
    packageUpsell,
  },
}) => {
  const breadcrumbProps = breadcrumbs?.map(ctaExtractData);

  const galleryCollection = gallery
    ? [gallery].concat(colorSpecificGalleries)
    : colorSpecificGalleries;

  return (
    <ColorSelectionContextProvider colorSelection={colorSelection}>
      <ShopDrawersContextProvider shopDrawers={shopDrawers} product={product}>
        <CFUPackageProvider>
          <PromoProvider>
            <Container
              data-test-id="pb-overview-container"
              padding={{
                mobile: `0 ${spacing[16]}`,
                tablet: `0 ${spacing[64]}`,
                desktop: `${spacing[48]} ${spacing[40]} 0`,
              }}
            >
              <StyledTwoColumnLayout
                margin="0 auto"
                maxWidth="1224px"
                display={{ desktop: 'flex' }}
              >
                <UpsellAccessoryContextProvider product={product}>
                  <DrawerContentContextProvider product={product}>
                    <ProductConfigurationContextProvider>
                      <StructuredDataWrapper />
                      <Overview
                        name={name}
                        galleries={galleryCollection}
                        reviews={reviews}
                        ribbon={ribbon}
                        shopSectionTitle={waysToShopIntro}
                        product={product}
                        valueProps={valueProps}
                        tabs={tabs}
                        nearestShowroom={nearestShowroom}
                        breadcrumbs={breadcrumbProps}
                        postalCodeInputFields={postalCodeInput?.fields}
                        emailLeadGenFields={availabilityLeadGen?.fields}
                        crossShopToggle={crossShopToggle}
                        soldOutLeadGenFields={soldOutLeadGen?.fields}
                        aiChatEntrypoint={aiChatEntrypoint}
                        packageUpsell={packageUpsell}
                      />
                    </ProductConfigurationContextProvider>
                  </DrawerContentContextProvider>
                </UpsellAccessoryContextProvider>
              </StyledTwoColumnLayout>
            </Container>
          </PromoProvider>
        </CFUPackageProvider>
      </ShopDrawersContextProvider>
    </ColorSelectionContextProvider>
  );
};

const StyledTwoColumnLayout = styled(Container)`
  // TODO: update to remove legacy desktop breakpoints brought over from @ecomm Overview;
  // There should only be one desktop breakpoint used.
  ${media.desktopLarge`
    gap: ${spacing[40]};
  `}
  ${media.desktopXLarge`
    gap: ${spacing[64]};
  `}
`;
