import { Eyebrow, grey, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

export type RadioLinkProps = {
  label: string;
  disabled?: boolean;
  checked?: boolean;
} & React.ComponentPropsWithoutRef<'a'>;

export const RadioLink: React.FC<React.PropsWithChildren<RadioLinkProps>> = ({
  label,
  disabled,
  checked,
  ...props
}) => {
  return (
    <StyledLink
      aria-current={checked ? 'page' : 'false'}
      disabled={disabled}
      rel={disabled ? 'nofollow' : undefined}
      tabIndex={disabled ? -1 : undefined}
      {...props}
    >
      <StyledChecker checked={checked} />
      <Eyebrow size="small" is="span">
        {label}
      </Eyebrow>
    </StyledLink>
  );
};

const StyledChecker = styled.div<{ checked?: boolean }>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: ${spacing[12]};
  position: relative;
  border-radius: 50%;
  transition: border-color 0.2s ease-out;
  ${({ checked }) =>
    checked ? `border: 1px solid ${white};` : `border: 1px solid ${grey[40]};`}

  a:hover & {
    border: 1px solid ${white};
  }
  a:hover span[aria-current='false'] &:before {
    transform: translate(-50%, -50%) scale(0.65);
    background: ${white};
  }

  &:before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${white};
    border-radius: 50%;
    transition: transform 0.2s ease-out, background 0.2s ease-out;

    ${({ checked }) =>
      checked
        ? `
            transform: translate(-50%, -50%) scale(1.35);
            background: ${white};
        `
        : `
            transform: translate(-50%, -50%) scale(0);
            background: transparent;
        `}
  }
`;

const StyledLink = styled.span<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  ${({ disabled }) =>
    disabled
      ? `
      ${StyledChecker} {
        border: 1px solid ${grey[70]};
      }
    color: ${grey[60]};
    pointer-events: none;
  `
      : ''}
`;
