import type { IconNames } from '@pelotoncycle/design-system';
import type * as Contentful from 'contentful';
import React from 'react';
import type { TypeComponentFormElementFields } from '@page-builder/lib/types';
import { CardGrid } from '../components/CardGrid';
import { GenericOptionCard } from '../components/GenericOptionCard';
import { useAnswersForQuestion } from '../utils/progress';

type Props = {
  options: Contentful.Entry<TypeComponentFormElementFields>[];
  questionName: Contentful.EntryFields.Symbol;
  singleAnswer: Contentful.EntryFields.Boolean;
  columns: Contentful.EntryFields.Integer;
};

const SingleQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  questionName,
  options,
  singleAnswer,
  columns,
}) => {
  const { answers, toggleAnswer, saveAnswers } = useAnswersForQuestion(questionName);

  return (
    <CardGrid columns={columns}>
      {options.map(option => {
        const { icon, tooltip, type } = option.fields;
        const [answer, groupAnswer] = option.fields!.items!;
        const [name, buttonValue] = answer.split(':');
        const optionValue = groupAnswer
          ? `${buttonValue}:${groupAnswer.split(':')[0]}`
          : buttonValue;

        return (
          <GenericOptionCard
            key={optionValue}
            type={type}
            tooltip={tooltip}
            name={name}
            value={optionValue}
            icon={icon as IconNames}
            onChange={() =>
              singleAnswer ? saveAnswers([optionValue]) : toggleAnswer(optionValue)
            }
            selected={answers.includes(optionValue)}
          />
        );
      })}
    </CardGrid>
  );
};

export default SingleQuestion;
