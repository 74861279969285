import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTracking } from 'react-tracking';

import { useToShopConfiguration } from '@ecomm/shop-configuration';
import toTrackingCategory from '@ecomm/shop/models/toTrackingCategory';
import type {
  AccessoryVariant,
  ConfigurableProductsList,
  ConfigurableProductsMap,
  Slug,
  AccessoryWithMetaData,
  ProductSelection,
  SelectedProductsMap,
  NonConfigurableProductsList,
} from './ProductConfigurations/types';
import {
  buildConfigurableProducts,
  buildNonConfigurableProducts,
} from './ProductConfigurations/utils';
import { useShopContext } from './ShopContext';

export type ProductConfigurationContextProps = {
  configurableProducts: ConfigurableProductsList;
  misconfiguredProducts: ConfigurableProductsMap;
  productConfigurations: SelectedProductsMap;
  getMisconfiguredPackageProducts: () => AccessoryWithMetaData[];
  getPackageProductCartData: () => {
    productSelections: ProductSelection[];
    productOptionIds: string[];
  };
  setProductConfigurations: (arg: {
    accessorySlug: Slug;
    productSlug: Slug;
    variant: AccessoryVariant;
  }) => void;
  trackProductConfigurationSelection: (
    product: AccessoryWithMetaData,
    variant: AccessoryVariant | undefined,
    selected: boolean,
  ) => void;
  setMisconfiguredProducts: (products: AccessoryWithMetaData[]) => void;
  clearSelectedConfiguration: (accessorySlug: Slug, productSlug: Slug) => void;
  clearAllSelectedConfigurations: () => void;
};

export const ProductConfigurationContext = React.createContext<ProductConfigurationContextProps>(
  {
    configurableProducts: [],
    misconfiguredProducts: {},
    productConfigurations: {},
    getMisconfiguredPackageProducts: () => [],
    getPackageProductCartData: () => ({ productSelections: [], productOptionIds: [] }),
    setProductConfigurations: () => {},
    setMisconfiguredProducts: () => {},
    clearSelectedConfiguration: () => {},
    clearAllSelectedConfigurations: () => {},
    trackProductConfigurationSelection: () => {},
  },
);

export const ProductConfigurationContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { productPackage } = useShopContext();
  const { trackEvent } = useTracking();
  const [
    productConfigurations,
    _setProductConfigurations,
  ] = useState<SelectedProductsMap>({});
  const [
    misconfiguredProducts,
    _setMisconfiguredProducts,
  ] = useState<ConfigurableProductsMap>({});
  const toShopConfigurationFn = useToShopConfiguration();

  const configurableProducts = useMemo<ConfigurableProductsList>(() => {
    if (!productPackage) return [];

    return Object.values(
      buildConfigurableProducts(productPackage, toShopConfigurationFn),
    );
  }, [productPackage]);

  const nonConfigurableProducts = useMemo(() => {
    if (!productPackage) return [];
    return buildNonConfigurableProducts(productPackage) as NonConfigurableProductsList;
  }, [productPackage]);

  useEffect(() => {
    Object.keys(productConfigurations).forEach(key => {
      if (misconfiguredProducts[key]) {
        clearMisconfiguredProducts(key);
      }
    });
  }, [productConfigurations]);

  const clearMisconfiguredProducts = (key: Slug) => {
    _setMisconfiguredProducts(prev => {
      const { [key]: _, ...rest } = prev;

      return rest;
    });
  };

  const getMisconfiguredPackageProducts: ProductConfigurationContextProps['getMisconfiguredPackageProducts'] = useCallback(() => {
    const selectedProducts = Object.keys(productConfigurations);

    if (!selectedProducts.length) return configurableProducts;

    const requiredCounts = configurableProducts.reduce(
      (map, product) => ({
        ...map,
        [product.slug]: product.occurrence,
      }),
      {},
    );

    return configurableProducts.filter(
      product =>
        !productConfigurations[product.slug] ||
        Object.values(productConfigurations[product.slug]).length !=
          requiredCounts[product.slug],
    );
  }, [configurableProducts, productConfigurations]);

  const getPackageProductCartData: ProductConfigurationContextProps['getPackageProductCartData'] = useCallback(() => {
    const productOptionIds: string[] = [];
    const productSelections: ProductSelection[] = [];
    const selectedProductEntries = Object.entries(productConfigurations);

    selectedProductEntries.forEach(([slug, productSelection]) => {
      Object.values(productSelection).forEach(selection => {
        productOptionIds.push(selection?.configurations?.[0].legacyOptionId);
        productSelections.push({
          product: slug,
          selections: [
            {
              attribute: selection?.configurations?.[0].attribute,
              option: selection?.configurations?.[0].option,
            },
          ],
        });
      });
    });

    nonConfigurableProducts.forEach(product => {
      productOptionIds.push(product.variants[0].id);
      productSelections.push({
        product: product.slug,
        selections: [],
      });
    });

    return { productSelections, productOptionIds };
  }, [productConfigurations, nonConfigurableProducts]);

  const setMisconfiguredProducts: ProductConfigurationContextProps['setMisconfiguredProducts'] = useCallback(
    products => {
      _setMisconfiguredProducts(
        products.reduce((acc, product) => ({ ...acc, [product.slug]: product }), {}),
      );
    },
    [],
  );

  const setProductConfigurations: ProductConfigurationContextProps['setProductConfigurations'] = useCallback(
    ({ accessorySlug, productSlug, variant }) => {
      _setProductConfigurations(prevState => {
        return {
          ...prevState,
          [accessorySlug]: {
            ...prevState[accessorySlug],
            [productSlug]: variant,
          },
        } as SelectedProductsMap;
      });
    },
    [],
  );

  const clearSelectedConfiguration: ProductConfigurationContextProps['clearSelectedConfiguration'] = useCallback(
    (accessorySlug, productSlug) => {
      _setProductConfigurations(prevState => {
        const newValue = { ...prevState, [accessorySlug]: {} } as SelectedProductsMap;
        for (const [key, value] of Object.entries(prevState[accessorySlug])) {
          if (key !== productSlug) {
            newValue[accessorySlug][key] = value;
          }
        }

        if (Object.keys(newValue[accessorySlug]).length == 0) {
          delete newValue[accessorySlug];
        }

        return newValue;
      });
    },
    [],
  );

  const clearAllSelectedConfigurations: ProductConfigurationContextProps['clearAllSelectedConfigurations'] = useCallback(() => {
    _setProductConfigurations({});
  }, []);

  const trackProductConfigurationSelection: ProductConfigurationContextProps['trackProductConfigurationSelection'] = (
    product,
    variant,
    selected,
  ) => {
    const selectedItems = Object.values(productConfigurations?.[product.slug] || {});
    const variantOption = variant?.configurations[0].option;
    const maxQuantity = product.occurrence;
    // selected product quant - since track called while we select,
    // said selected product (1) needs to inlcuded
    const quantity =
      selected && selectedItems.length > 0 && maxQuantity > 1
        ? selectedItems.length + 1
        : !selected && selectedItems.length > 0 && maxQuantity > 1
        ? selectedItems.length - 1
        : selected
        ? 1
        : 0;

    trackEvent({
      event: 'productAttributeSelected',
      properties: {
        category: toTrackingCategory(productPackage!.equipmentType),
        attributeCategory: product.slug,
        page: window.location.pathname,
        selected: selected,
        product: {
          quantity,
          name: `${product.name} - ${variantOption ?? ''}`,
          price: variant?.price.amount,
          max_quantity: maxQuantity, // the max number of items that can be seleted in grouping
          sku: variant?.slug,
          product_id: product.id,
          product_slug: product.slug,
          package_name: productPackage!.slug,
        },
      },
    });
  };

  const value = React.useMemo(
    () => ({
      configurableProducts,
      misconfiguredProducts,
      productConfigurations,
      getMisconfiguredPackageProducts,
      getPackageProductCartData,
      setMisconfiguredProducts,
      setProductConfigurations,
      clearSelectedConfiguration,
      clearAllSelectedConfigurations,
      trackProductConfigurationSelection,
    }),
    [productConfigurations, configurableProducts, misconfiguredProducts],
  );

  return (
    <ProductConfigurationContext.Provider value={value}>
      {children}
    </ProductConfigurationContext.Provider>
  );
};

export const useProductConfigurationContext = () =>
  React.useContext(ProductConfigurationContext);
