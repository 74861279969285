import {
  Body,
  Container,
  grey,
  brand,
  Headline,
  Label,
  spacing,
  Support,
  Table,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@page-builder/utils/Markdown';

type Props = {
  eyebrow: string;
  body: string;
};

const CompareTable: React.FC<React.PropsWithChildren<Props>> = ({ eyebrow, body }) => {
  return (
    <Container horizontalPadding={spacing[40]} verticalPadding={spacing[56]}>
      <StyledHeadline size="small" textColor={grey[90]} textAlign="center">
        {eyebrow}
      </StyledHeadline>
      <Markdown
        content={body}
        markdown={{
          renderers: {
            table: ({ node, ...props }) => <StyledTable children={props.children} />,
            tableRow: ({ children }) => {
              // This table row just renders its children but also passes them an index prop, which is
              // their position in the row - we can use this to render a different DS typography component
              return (
                <tr>
                  {React.Children.map(children, (child, index) =>
                    React.cloneElement(child, { index }),
                  )}
                </tr>
              );
            },
            tableCell: ({ isHeader, children, index }) =>
              isHeader ? (
                <HeaderCell>{children}</HeaderCell>
              ) : (
                <BodyCell index={index}>{children}</BodyCell>
              ),
            strong: LargeText,
            emphasis: RedText,
          },
        }}
      />
    </Container>
  );
};

const StyledHeadline = styled(Headline)`
  margin-bottom: ${spacing[48]};
`;

const StyledTable = styled(Table)`
  color: ${grey[90]};
  width: 100%;

  // set a bottom border for all table rows
  tr {
    border-bottom: 1px solid ${grey[40]};
  }

  // fixes Safari bug where the first column would have a double bottom border
  td {
    vertical-align: top;
  }

  // ... but no bottom border for the table
  tbody tr:last-child {
    border-bottom: none;
  }

  th,
  td {
    padding: ${spacing[24]} ${spacing[16]};
  }

  /* styling excluding the first column of table */
  th:not(:first-child),
  td:not(:first-child) {
    background-color: ${grey[30]};
  }
  th:not(:last-child),
  td:not(:last-child) {
    // create the appearance of a gap between the columns
    box-shadow: inset -8px 0 0 0 white;
  }

  /* rounded corners on grey columns */
  th:not(:first-child) {
    border-radius: ${spacing[4]} ${spacing[4]} 0 0;
  }
  tbody tr:last-child td:not(:first-child) {
    border-radius: 0 0 ${spacing[4]} ${spacing[4]};
  }
`;

const HeaderCell: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <th>
      <Label size="medium">{children}</Label>
    </th>
  );
};

const BodyCell: React.FC<React.PropsWithChildren<{ index: number }>> = ({
  index,
  children,
}) => {
  const isRowHeader = index === 0;

  return (
    <td>
      {isRowHeader ? (
        <Label size="large" is="span">
          {children}
        </Label>
      ) : (
        <Support size="small" is="span">
          {children}
        </Support>
      )}
    </td>
  );
};

const LargeText = styled(Body).attrs({ size: 'large', is: 'span' })`
  margin-bottom: ${spacing[4]};
`;

const RedText = styled(Body).attrs({ size: 'large', is: 'span' })`
  color: ${brand.primary};
`;

export default CompareTable;
