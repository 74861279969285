import React from 'react';

function useSafeDispatch<T>(dispatch: React.Dispatch<Partial<T>>) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return React.useCallback(
    (args: Partial<T>) => (mounted.current ? dispatch(args) : void 0),
    [dispatch],
  );
}
type Status = 'idle' | 'pending' | 'rejected' | 'resolved';
type UseAsyncState<T, E> = {
  status: Status;
  data: T | null;
  error: E | null;
};
function useAsync<T, E = string>(initialState: Partial<UseAsyncState<T, E>> = {}) {
  const defaultInitialState: UseAsyncState<T, E> = {
    status: 'idle',
    data: null,
    error: null,
  };
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s: UseAsyncState<T, E>, a: Partial<UseAsyncState<T, E>>) => ({ ...s, ...a }),
    initialStateRef.current,
  );

  const safeSetState = useSafeDispatch<UseAsyncState<T, E>>(setState);
  const setData: (data: T) => void = React.useCallback(
    d => safeSetState({ data: d, status: 'resolved' }),
    [safeSetState],
  );
  const setError: (error: E) => void = React.useCallback(
    e => safeSetState({ error: e, status: 'rejected' }),
    [safeSetState],
  );
  const reset: () => void = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  );

  const run: (promise: Promise<T>) => Promise<T | null> = React.useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (d: T) => {
          setData(d);
          return d;
        },
        (e: E) => {
          setError(e);
          return null;
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export { useAsync, useSafeDispatch };
export type { UseAsyncState };
