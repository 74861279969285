import {
  Flex,
  Label,
  Support,
  Media,
  spacing,
  grey,
  Button,
  Container,
  white,
} from '@pelotoncycle/design-system';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import useSubHeaderReference from '@acme-ui/global/hooks/useSubHeaderReference';
import useCartViewedAnalytics from '@ecomm/cart-next/analytics/useCartViewedAnalytics';
import { useOpenCartPanel } from '@ecomm/cart-next/context/CartContext';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import { BundleType } from '@ecomm/shop/models';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import {
  toMediaProps,
  toCtaFields,
  getTextFieldsWithRequiredKeys,
} from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';

const useShouldShowSubnav = (
  cart: ReturnType<typeof useCartWithVariables>,
  router: NextRouter,
) => {
  const { isReady, query } = router;
  const { data } = cart;

  if (!isReady) {
    return false;
  }

  const cfuInCartMap = {
    [BundleType.RefurbishedBike]: {
      buy: data?.cart?.hasRefurbBike,
    },
    [BundleType.RefurbishedBikePlus]: {
      buy: data?.cart?.hasRefurbBikePlus,
    },
    [BundleType.Bike]: {
      buy: data?.cart?.hasBike,
      rent: data?.cart?.hasEquipmentLease && !data?.cart?.hasBikePlusLease,
    },
    [BundleType.BikePlus]: {
      buy: data?.cart?.hasBikePlus,
      rent: data?.cart?.hasBikePlusLease,
    },
    [BundleType.Tread]: {
      buy: data?.cart?.hasTread,
    },
    [BundleType.Row]: {
      buy: data?.cart?.hasRow,
    },
    [BundleType.RainforestCafe]: {
      buy: data?.cart?.hasGuide,
    },
  };

  const [, cfu, maybeBuyMethod] = query.slugs as string[];

  const buyMethod =
    !maybeBuyMethod || maybeBuyMethod === 'complete-cart' ? 'buy' : maybeBuyMethod;

  return Boolean(cfuInCartMap[cfu]?.[buyMethod]);
};

const PreCartSubnav: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ name, text, media, ctas }) => {
  const headbandRef = useSubHeaderReference();
  const openCartPanel = useOpenCartPanel();
  const mediaProps = media && toMediaProps(media);
  const cartCta = ctas && toCtaFields(ctas)[0];

  const { trackEvent } = useTracking();
  const { trackCartViewed } = useCartViewedAnalytics();
  const cart = useCartWithVariables();
  const router = useRouter();

  const {
    eyebrow: productTitle,
    headline: productSubhead,
  } = getTextFieldsWithRequiredKeys(['eyebrow', 'headline'], text);

  const handleOnClick = () => {
    trackEvent({
      event: 'Clicked View Cart',
      properties: {
        parentType: 'Component: Generic Text with Media',
        parent: name,
        unitName: cartCta?.name,
        linkName: cartCta?.text,
        linkTo: cartCta?.link,
      },
    });
    openCartPanel();
    trackCartViewed(cart.data);
  };

  const shouldShowSubnavContents = useShouldShowSubnav(cart, router);
  const hasFinishedLoading = !cart.loading && router.isReady;
  const hideEntireSubnav = !shouldShowSubnavContents && hasFinishedLoading;

  if (hideEntireSubnav) {
    return null;
  }

  return (
    <StyledSubnav
      ref={headbandRef}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={{ mobile: `${spacing[16]}`, desktop: `0 ${spacing[24]}` }}
      position="sticky"
      backgroundColor={white}
    >
      {shouldShowSubnavContents ? (
        <>
          <Flex>
            {mediaProps && (
              <Container display={{ mobile: 'none', desktop: 'contents' }}>
                <Media media={mediaProps} height={spacing[80]} width={spacing[80]} />
              </Container>
            )}
            <Flex
              flexDirection="column"
              justifyContent="center"
              horizontalPadding={{ mobile: '0', desktop: `${spacing[16]} 0` }}
            >
              {productTitle && (
                <Label size="large">
                  <Markdown content={productTitle} />
                </Label>
              )}
              {productSubhead && (
                <Support size="large">
                  <Markdown content={productSubhead} />
                </Support>
              )}
            </Flex>
          </Flex>
          {cartCta?.text && (
            <CartCTA
              color={cartCta?.color}
              variant={cartCta?.variant}
              size="medium"
              text={cartCta.text}
              width="adjustable"
              onClick={handleOnClick}
            />
          )}
        </>
      ) : (
        <LoadingSpacer data-test-id="loading-spacer" />
      )}
    </StyledSubnav>
  );
};

const CartCTA = styled(Button)`
  padding: 0 ${spacing[16]} !important;
`;

const StyledSubnav = styled(Flex)`
  top: 0;
  z-index: 3;
  border-bottom: 1px solid ${grey[40]};
`;

const LoadingSpacer = styled.div`
  height: ${spacing[80]};
`;

export default PreCartSubnav;
