import {
  Container,
  Flex,
  Icon,
  Label,
  black,
  grey,
  spacing,
  white,
} from '@pelotoncycle/design-system';
import type { ReactEventHandler } from 'react';
import React from 'react';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { HeightTransition } from '@page-builder/modules/Overview/ShopDrawers/DrawerContent/UpsellContent/SharedComponents';
import { useTheme } from '@page-builder/themes/ThemeProvider';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';
import { configureMediaProps } from '../utils';
import { ActiveMedia, ThumbnailImage } from './modalStyles';

type Props = {
  items: TypeComponentGenericTextWithMedia[];
  activeIndex: number;
  onClick: (index: number) => void;
  videoEventHandlers?: {
    onPause?: ReactEventHandler<HTMLVideoElement>;
    onEnded?: ReactEventHandler<HTMLVideoElement>;
  };
};

export const MultiItemModalSection: React.FC<Props> = ({
  items,
  activeIndex,
  onClick,
  videoEventHandlers,
}) => {
  const { headlineColor } = useTheme();
  return (
    <Flex gap={spacing[16]} flexDirection="column">
      {items.map((item, idx) => {
        const { fields } = item;

        if (!fields.media) return;

        const mediaProps = configureMediaProps(
          item,
          videoEventHandlers?.onPause,
          videoEventHandlers?.onEnded,
        );

        const isActive = activeIndex === idx;

        if (mediaProps.type !== 'video') return;

        const {
          eyebrow: mediaTitle,
          headline: mediaDescription,
        } = getTextFieldsWithRequiredKeys(['name'], fields.text);

        return (
          <HeightTransition
            key={fields.name}
            $isExpanded={isActive}
            $isAnimating={isActive}
          >
            <Container
              display={{ mobile: `${isActive ? 'block' : 'none'}`, desktop: 'none' }}
            >
              <ActiveMedia key={isActive ? 'active' : 'inactive'} media={mediaProps} />
            </Container>
            <Flex
              onClick={() => onClick(idx)}
              flexDirection="row"
              gap={spacing[16]}
              // The below is not yet in DS but will be grey[75]
              backgroundColor="#2B2D31"
              borderRadius={spacing[8]}
              border={`1px solid ${isActive ? grey[50] : 'transparent'}`}
              display={{
                mobile: isActive ? 'none' : 'flex',
                desktop: 'flex',
              }}
              as={isActive ? 'div' : 'button'}
              style={{ width: '100%' }}
            >
              {mediaProps.thumbnail && <ThumbnailImage {...mediaProps.thumbnail} />}
              <Flex
                height={spacing[72]}
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <Flex
                  flexDirection="column"
                  gap={spacing[4]}
                  padding={`${spacing[16]} 0`}
                  justifyContent="center"
                >
                  <Label textColor={headlineColor}>{mediaTitle}</Label>
                  <Label size="small" textColor={headlineColor}>
                    {mediaDescription}
                  </Label>
                </Flex>
                <Flex
                  horizontalPadding={`0 ${spacing[16]}`}
                  display={isActive ? 'none' : 'flex'}
                >
                  <Icon name="play" primaryColor={white} secondaryColor={black} />
                </Flex>
              </Flex>
            </Flex>
          </HeightTransition>
        );
      })}
    </Flex>
  );
};
