// THIS IS AN AUTOGENERATED FILE (@ecomm/commercetools/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
import * as Types from '../types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type ProductProjectionFragmentFragment = {
  __typename?: 'ProductSearchVariant';
} & Pick<Types.ProductSearchVariant, 'key' | 'sku'> & {
    price?: Types.Maybe<
      { __typename?: 'ProductPriceSearch' } & Pick<Types.ProductPriceSearch, 'id'> & {
          value:
            | ({ __typename?: 'Money' } & Pick<
                Types.Money,
                'currencyCode' | 'centAmount'
              >)
            | ({ __typename?: 'HighPrecisionMoney' } & Pick<
                Types.HighPrecisionMoney,
                'currencyCode' | 'centAmount'
              >);
          discounted?: Types.Maybe<
            { __typename?: 'DiscountedProductSearchPriceValue' } & {
              value:
                | ({ __typename?: 'Money' } & Pick<Types.Money, 'centAmount'>)
                | ({ __typename?: 'HighPrecisionMoney' } & Pick<
                    Types.HighPrecisionMoney,
                    'centAmount'
                  >);
            }
          >;
        }
    >;
    images: Array<
      { __typename?: 'ImageProductSearch' } & Pick<
        Types.ImageProductSearch,
        'url' | 'label'
      >
    >;
  };

export type ProductCatalogFragmentFragment = { __typename?: 'ProductVariant' } & Pick<
  Types.ProductVariant,
  'key' | 'sku'
> & {
    price?: Types.Maybe<
      { __typename?: 'ProductPrice' } & Pick<Types.ProductPrice, 'id'> & {
          value:
            | ({ __typename?: 'Money' } & Pick<
                Types.Money,
                'currencyCode' | 'centAmount'
              >)
            | ({ __typename?: 'HighPrecisionMoney' } & Pick<
                Types.HighPrecisionMoney,
                'currencyCode' | 'centAmount'
              >);
        }
    >;
    images: Array<{ __typename?: 'Image' } & Pick<Types.Image, 'url' | 'label'>>;
  };

export type ProjectionSearchAllAccessoriesQueryVariables = Types.Exact<{
  acceptLanguage?: Types.Maybe<Array<Types.Scalars['Locale']>>;
  currency: Types.Scalars['Currency'];
  country: Types.Scalars['Country'];
  filters?: Types.Maybe<Array<Types.SearchFilterInput>>;
  sorts?: Types.Maybe<Array<Types.Scalars['String']>>;
  limit: Types.Scalars['Int'];
}>;

export type ProjectionSearchAllAccessoriesQuery = { __typename?: 'Query' } & {
  productProjectionSearch: { __typename?: 'ProductProjectionSearchResult' } & {
    results: Array<
      { __typename?: 'ProductProjection' } & Pick<
        Types.ProductProjection,
        'id' | 'key' | 'name' | 'slug' | 'description'
      > & {
          categories: Array<
            { __typename?: 'Category' } & Pick<Types.Category, 'name' | 'id' | 'key'>
          >;
          productType?: Types.Maybe<
            { __typename?: 'ProductTypeDefinition' } & Pick<
              Types.ProductTypeDefinition,
              'key'
            >
          >;
          masterVariant: { __typename?: 'ProductSearchVariant' } & {
            attributesRaw: Array<
              { __typename?: 'RawProductSearchAttribute' } & Pick<
                Types.RawProductSearchAttribute,
                'name' | 'value'
              > & {
                  referencedResourceSet: Array<
                    | { __typename?: 'Cart' }
                    | { __typename?: 'Customer' }
                    | { __typename?: 'TypeDefinition' }
                    | { __typename?: 'CustomerGroup' }
                    | { __typename?: 'Store' }
                    | ({ __typename?: 'Product' } & Pick<Types.Product, 'key' | 'id'> & {
                          masterData: { __typename?: 'ProductCatalogData' } & {
                            current?: Types.Maybe<
                              { __typename?: 'ProductData' } & Pick<
                                Types.ProductData,
                                'name' | 'slug'
                              > & {
                                  masterVariant: { __typename?: 'ProductVariant' } & {
                                    attributesRaw: Array<
                                      { __typename?: 'RawProductAttribute' } & Pick<
                                        Types.RawProductAttribute,
                                        'name' | 'value'
                                      >
                                    >;
                                  } & ProductCatalogFragmentFragment;
                                  variants: Array<
                                    { __typename?: 'ProductVariant' } & {
                                      attributesRaw: Array<
                                        { __typename?: 'RawProductAttribute' } & Pick<
                                          Types.RawProductAttribute,
                                          'name' | 'value'
                                        >
                                      >;
                                    } & ProductCatalogFragmentFragment
                                  >;
                                }
                            >;
                          };
                        })
                    | { __typename?: 'ProductTypeDefinition' }
                    | { __typename?: 'Category' }
                    | { __typename?: 'Channel' }
                    | { __typename?: 'ProductDiscount' }
                    | { __typename?: 'State' }
                    | { __typename?: 'TaxCategory' }
                    | { __typename?: 'CartDiscount' }
                    | { __typename?: 'ShippingMethod' }
                    | { __typename?: 'Zone' }
                    | { __typename?: 'DiscountCode' }
                    | { __typename?: 'BusinessUnit' }
                    | { __typename?: 'AssociateRole' }
                    | { __typename?: 'Order' }
                    | { __typename?: 'ShoppingList' }
                    | { __typename?: 'CustomObject' }
                    | { __typename?: 'InventoryEntry' }
                    | { __typename?: 'Message' }
                    | { __typename?: 'Review' }
                    | { __typename?: 'Extension' }
                  >;
                }
            >;
          } & ProductProjectionFragmentFragment;
          variants: Array<
            { __typename?: 'ProductSearchVariant' } & {
              attributesRaw: Array<
                { __typename?: 'RawProductSearchAttribute' } & Pick<
                  Types.RawProductSearchAttribute,
                  'name' | 'value'
                >
              >;
            } & ProductProjectionFragmentFragment
          >;
        }
    >;
  };
};

export const ProductProjectionFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductProjectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProductCatalogFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectionSearchAllAccessoriesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectionSearchAllAccessories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'acceptLanguage' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SearchFilterInput' },
              },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sorts' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProjectionSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sorts' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'acceptLanguage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'acceptLanguage' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'acceptLanguage' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'acceptLanguage' },
                                  },
                                },
                              ],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productType' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProductProjectionFragment' },
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributesRaw' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'referencedResourceSet',
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'Product' },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'key' },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'masterData',
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'current',
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'name',
                                                            },
                                                            arguments: [
                                                              {
                                                                kind: 'Argument',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value: 'acceptLanguage',
                                                                },
                                                                value: {
                                                                  kind: 'Variable',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'acceptLanguage',
                                                                  },
                                                                },
                                                              },
                                                            ],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'slug',
                                                            },
                                                            arguments: [
                                                              {
                                                                kind: 'Argument',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value: 'acceptLanguage',
                                                                },
                                                                value: {
                                                                  kind: 'Variable',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'acceptLanguage',
                                                                  },
                                                                },
                                                              },
                                                            ],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'masterVariant',
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'ProductCatalogFragment',
                                                                  },
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'attributesRaw',
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value: 'name',
                                                                        },
                                                                        arguments: [],
                                                                        directives: [],
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value: 'value',
                                                                        },
                                                                        arguments: [],
                                                                        directives: [],
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'variants',
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'ProductCatalogFragment',
                                                                  },
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'attributesRaw',
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value: 'name',
                                                                        },
                                                                        arguments: [],
                                                                        directives: [],
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value: 'value',
                                                                        },
                                                                        arguments: [],
                                                                        directives: [],
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ProductProjectionFragment' },
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributesRaw' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductProjectionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discounted' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'centAmount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useProjectionSearchAllAccessoriesQuery__
 *
 * To run a query within a React component, call `useProjectionSearchAllAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectionSearchAllAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectionSearchAllAccessoriesQuery({
 *   variables: {
 *      acceptLanguage: // value for 'acceptLanguage'
 *      currency: // value for 'currency'
 *      country: // value for 'country'
 *      filters: // value for 'filters'
 *      sorts: // value for 'sorts'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProjectionSearchAllAccessoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectionSearchAllAccessoriesQuery,
    ProjectionSearchAllAccessoriesQueryVariables
  >,
) {
  return Apollo.useQuery<
    ProjectionSearchAllAccessoriesQuery,
    ProjectionSearchAllAccessoriesQueryVariables
  >(ProjectionSearchAllAccessoriesDocument, baseOptions);
}
export function useProjectionSearchAllAccessoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectionSearchAllAccessoriesQuery,
    ProjectionSearchAllAccessoriesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ProjectionSearchAllAccessoriesQuery,
    ProjectionSearchAllAccessoriesQueryVariables
  >(ProjectionSearchAllAccessoriesDocument, baseOptions);
}
export type ProjectionSearchAllAccessoriesQueryHookResult = ReturnType<
  typeof useProjectionSearchAllAccessoriesQuery
>;
export type ProjectionSearchAllAccessoriesLazyQueryHookResult = ReturnType<
  typeof useProjectionSearchAllAccessoriesLazyQuery
>;
export type ProjectionSearchAllAccessoriesQueryResult = Apollo.QueryResult<
  ProjectionSearchAllAccessoriesQuery,
  ProjectionSearchAllAccessoriesQueryVariables
>;
