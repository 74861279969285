import { Body, Display, Eyebrow, Flex, spacing } from '@pelotoncycle/design-system';
import type { LinkButtonsProps } from '@pelotoncycle/page-builder';
import type { CSSProperties, ReactNode } from 'react';
import React from 'react';

type Props = React.HTMLAttributes<HTMLElement> &
  LinkButtonsProps & {
    eyebrowText?: ReactNode;
    headlineText?: ReactNode;
    bodyText?: ReactNode;
    primaryColor?: CSSProperties['color'];
    secondaryColor?: CSSProperties['color'];
  };

const HeaderText: React.FC<React.PropsWithChildren<Props>> = ({
  eyebrowText,
  headlineText,
  bodyText,
  primaryColor,
  secondaryColor,
}) => (
  <Flex
    removeOuterMargins
    flexDirection={'column'}
    justifyContent={'flex-start'}
    verticalPadding={{ desktop: `${spacing[48]} 0` }}
    style={{ gridArea: 'header' }}
  >
    {eyebrowText && (
      <Eyebrow
        size="small"
        style={{ marginBottom: spacing[16] }}
        textColor={primaryColor}
      >
        {eyebrowText}
      </Eyebrow>
    )}

    {headlineText && (
      <Display
        size="large"
        style={{ marginBottom: spacing[16] }}
        textColor={primaryColor}
      >
        {headlineText}
      </Display>
    )}

    {bodyText && (
      <Body size="small" style={{ marginBottom: spacing[24] }} textColor={secondaryColor}>
        {bodyText}
      </Body>
    )}
  </Flex>
);

export default HeaderText;
