import { useState, useEffect } from 'react';

export enum Platform {
  Android = 'android',
  iOS = 'ios',
  Web = 'web',
}

const usePlatform = (): Platform | null => {
  const [platform, setPlatform] = useState<Platform | null>(null);

  useEffect(() => {
    const sanitizedUserAgent = navigator.userAgent.toLowerCase();
    const isAndroid = sanitizedUserAgent.includes('android');
    const iPadVersion13 =
      sanitizedUserAgent.includes('mac') &&
      (navigator.maxTouchPoints > 1 || 'ontouchstart' in window);
    const isiOS = /ipad|iphone|ipod/.test(sanitizedUserAgent) || iPadVersion13;

    if (isAndroid) {
      setPlatform(Platform.Android);
    } else if (isiOS) {
      setPlatform(Platform.iOS);
    } else {
      setPlatform(Platform.Web);
    }
  }, []);

  return platform;
};

export default usePlatform;
