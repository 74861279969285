import Slider from '@dior/react-slick';
import { grey, spacing, white, BreakpointWidths } from '@pelotoncycle/design-system';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { hoverTransition, media } from '@peloton/styles';
import 'slick-carousel/slick/slick.css';
import { useMicroCopy } from '@content/client/microCopy';
import { LeftArrow } from '@page-builder/components/CarouselArrows/LeftArrow';
import { RightArrow } from '@page-builder/components/CarouselArrows/RightArrow';

export type Props = {
  preventOnFocusHandler?: boolean;
  activeTab: number;
};

type ArrowProps = {
  className?: string;
  style?: Record<string, string>;
  onClick?: () => void;
};

const CustomLeftArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  const previousLabel = useMicroCopy('previousButtonLabel');
  return (
    <button
      aria-label={previousLabel}
      className={className}
      style={style}
      onClick={onClick}
    >
      <LeftArrow />
    </button>
  );
};

const CustomRightArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  const nextLabel = useMicroCopy('nextButtonLabel');
  return (
    <button aria-label={nextLabel} className={className} style={style} onClick={onClick}>
      <RightArrow />
    </button>
  );
};

const appendDots = (dots: React.ReactElement[]) => (
  <ul aria-hidden="true">
    {React.Children.map(dots, dot =>
      React.isValidElement(dot)
        ? React.cloneElement(dot, {
            tabIndex: -1,
          } as React.HTMLAttributes<HTMLLIElement>)
        : dot,
    )}
  </ul>
);

export const Carousel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  preventOnFocusHandler,
  activeTab,
}) => {
  const scrollableListRef = useRef<Slider>(null);

  const toTileFocusHandler = (index: number) => () => {
    if (!preventOnFocusHandler) {
      scrollableListRef.current?.slickGoTo(index, true);
    }
  };

  useEffect(() => {
    scrollableListRef.current?.slickGoTo(0, true);
  }, [activeTab]);

  useEffect(() => {
    const slides = scrollableListRef.current?.innerSlider?.list?.querySelectorAll(
      'li.slick-slide',
    );

    slides?.forEach(slide => {
      slide.setAttribute('aria-hidden', 'false');
      slide.removeAttribute('role');
    });
  });

  return (
    <OuterSliderWrapper data-test-id="slider">
      <InnerSliderWrapper>
        <StyledReactSlick
          ref={scrollableListRef}
          infinite={true}
          useAriaRole={false}
          variableWidth={true}
          draggable={false}
          arrows={true}
          dots={true}
          customPaging={() => <Dot tabIndex={-1} />}
          appendDots={appendDots}
          slidesToShow={1}
          slidesToScroll={1}
          centerPadding={0}
          prevArrow={<CustomLeftArrow />}
          nextArrow={<CustomRightArrow />}
          waitForAnimate
        >
          {React.Children.map(children, (child, index) => (
            <TileWrapper onFocus={toTileFocusHandler(index)}>{child}</TileWrapper>
          ))}
        </StyledReactSlick>
      </InnerSliderWrapper>
    </OuterSliderWrapper>
  );
};

const OuterSliderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InnerSliderWrapper = styled.div`
  .slick-list {
    overflow: hidden;
  }

  .slick-bottom {
    margin-bottom: ${spacing[24]};

    ${media.tablet`
      margin-top: ${spacing[24]};
      margin-bottom: 0;
    `}
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    line-height: 0;
  }

  .slick-prev {
    left: -45px;
    position: absolute;
    top: calc(50% - 40px);
  }
  .slick-next {
    right: -40px;
    position: absolute;
    top: calc(50% - 40px);
  }

  @media (max-width: ${BreakpointWidths.desktop}px) {
    .slick-prev {
      display: none;
      visibility: hidden;
      top: 0;
      right: 0;
    }

    .slick-next {
      display: none;
      visibility: hidden;
      top: 0;
      right: 0;
    }
  }
`;

const StyledReactSlick = styled(Slider)<{ children: React.ReactNode }>`
  width: 100%;
`;

const TileWrapper = styled.div`
  padding: 0 ${spacing[16]} 0 0;

  &:focus {
    outline: none;
  }
`;

const Dot = styled.button`
  background-color: ${grey[50]};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin: 0 4px;
  width: 8px;

  .slick-active & {
    background-color: ${white};
  }

  ${hoverTransition({
    property: 'background-color',
    to: white,
  })}
`;
