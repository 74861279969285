import { BundleType } from '@ecomm/shop/models/BundleType';
import { toBundlePriceRange as toPriceRangeByProduct } from '@ecomm/shop/sagas/models';

export type WithPrice = {
  price: {
    amount: number;
  };
  discountPrice?: {
    amount: number;
  };
};

export type WithPriceAndVariants = WithPrice & {
  slug: string;
  variants: WithPrice[];
};

export type WithProductsPriceAndVariants = WithPrice & {
  products: WithPriceAndVariants[];
};

type ProductWithCount = {
  variants: WithPrice[];
  range: number;
};

type PriceRange = { low: number; high: number };

export const toPriceRange = ({ price, variants }: WithPriceAndVariants): PriceRange =>
  variants.reduce(
    ({ low, high }, variant) =>
      variant.price.amount < low
        ? { low: variant.price.amount, high }
        : variant.price.amount > high
        ? { low, high: variant.price.amount }
        : { low, high },
    { low: price.amount, high: price.amount },
  );

export const toDiscountPriceRange = (
  discountPrice: number,
  variants: WithPrice[],
): PriceRange =>
  variants.reduce(
    ({ low, high }, variant) => {
      const variantDiscount = variant.discountPrice?.amount || 0;
      if (variantDiscount < low) {
        return { low: variantDiscount, high };
      }
      return variantDiscount > high ? { low, high: variantDiscount } : { low, high };
    },
    { low: discountPrice, high: discountPrice },
  );

export const toBundlePriceRange = (products: WithPriceAndVariants[]): PriceRange => {
  const productSlugsToProduct = Object.fromEntries(
    products.map(product => [product.slug, product]),
  );
  const productSlugsToCount = products.reduce((acc, { slug }) => {
    const existingProductCount = acc[slug] ?? 0;
    acc[slug] = existingProductCount + 1;
    return acc;
  }, {} as Record<string, number>);
  const productsWithCount: ProductWithCount[] = Object.entries(productSlugsToCount).map(
    ([productSlug, range]) => ({
      variants: productSlugsToProduct[productSlug].variants,
      range,
    }),
  );
  const priceRange = productsWithCount
    .map(product => toPriceRangeByProduct(product))
    .reduce(
      (acc: PriceRange, curVal: PriceRange) => ({
        low: acc.low + curVal.low,
        high: acc.high + curVal.high,
      }),
      { low: 0, high: 0 },
    );
  return priceRange;
};

export const hasVariablePricing = (a: WithPriceAndVariants): boolean =>
  a.variants.length > 1 && // if there's only 1 variant, there can't be a price range
  a.variants.some(variant => variant.price.amount !== a.price.amount);

export const hasVariablePricingBundle = (a: WithProductsPriceAndVariants): boolean =>
  a.products.some(product => hasVariablePricing(product));

export const UpsellEligibleBundles = [
  BundleType.Bike,
  BundleType.BikePlus,
  BundleType.Tread,
  BundleType.TreadPlus,
  BundleType.Row,
  BundleType.RainforestCafe,
  BundleType.RefurbishedBike,
  BundleType.RefurbishedBikePlus,
];
