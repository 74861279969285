import { StatusCode } from '../api/status';
import { NextAbstractError } from './NextAbstractError';

const formatPath = (unformattedPath: string): string =>
  unformattedPath.startsWith('/') ? unformattedPath : `/${unformattedPath}`;

type RedirectStatusCode =
  | StatusCode.MOVED_PERMANENTLY
  | StatusCode.FOUND
  | StatusCode.SEE_OTHER
  | StatusCode.TEMPORARY_REDIRECT
  | StatusCode.PERMANENT_REDIRECT;

export class NextRedirectError extends NextAbstractError {
  public currentPath = '';
  public newPath = '';
  public statusCode: RedirectStatusCode = StatusCode.PERMANENT_REDIRECT;

  constructor({
    message,
    currentPath,
    newPath,
    statusCode,
  }: {
    message?: string;
    currentPath: string;
    newPath: string;
    statusCode?: RedirectStatusCode;
  }) {
    super(message || 'Redirect error');
    Object.setPrototypeOf(this, NextRedirectError.prototype);

    this.currentPath = formatPath(currentPath);
    this.newPath = formatPath(newPath);

    if (statusCode) this.statusCode = statusCode;
  }
}
