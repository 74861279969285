import {
  cardioThemeMusicHiitKeywords,
  cyclingMusicKeywords,
  cyclingThemeKeywords,
  meditationThemeKeywords,
  outdoorWorkoutMusicRunKeywords,
  outdoorWorkoutMusicWalkKeywords,
  outdoorWorkoutThemeRunKeywords,
  rowingMusicThemeKeywords,
  runningMusicKeywords,
  runningThemeKeywords,
  walkingMusicKeywords,
  walkingThemeKeywords,
  yogaFlowKeywords,
  yogaThemeKeywords,
} from './slugKeywords';

export const TValuesClassLength = [
  '20-min',
  '30-min',
  '5-min',
  '45-min',
  '10-min',
  '60-min',
  '15-min',
  '75-min',
  '90-min',
  '120-min',
] as const;
export type TKeysClassLength = typeof TValuesClassLength[number];

export const classTypesByDiscipline = {
  strength: {
    'warm-up': ['warm-up'],
    bodyweight: ['bodyweight'],
    'full-body': ['full-body', 'total', 'flash-15'],
    core: ['min-core-strength', 'roll-call-core'],
    'upper-body': ['min-upper-body', 'shoulders', 'chest', 'back', 'biceps', 'triceps'],
    'lower-body': ['min-lower-body', 'glutes-legs'],
    'arms-light-weights': ['light-weights'],
    'for-runners': ['for-runners'],
    'pre-postnatal': ['natal'],
    'resistance-bands': ['resistance'],
    adaptive: ['adaptive'],
    barre: ['barre'],
    pilates: ['pilates'],
    'boxing-bootcamp': ['boxing-bootcamp'],
  },
  yoga: {
    flow: yogaFlowKeywords,
    'focus-flow': ['focus-flow'],
    'slow-flow': ['slow-flow'],
    power: ['power-flow'],
    conditioning: ['conditioning'],
    restorative: ['restorative'],
    theme: yogaThemeKeywords,
    'yoga-anywhere': ['chair', 'standing'],
    'yoga-basics': ['basics'],
    'family-natal': ['family', 'natal', 'trimester'],
    'beyond-the-pose': ['beyond-the-pose'],
    'yin-yoga': ['yin-yoga'],
  },
  meditation: {
    basics: ['basics'],
    sleep: ['sleep'],
    relaxation: ['relax', 'calm'],
    breath: ['breath'],
    emotions: [
      'empathy',
      'kind',
      'peace',
      'courage',
      'energiz',
      'happiness',
      'anxiety',
      'gratitude',
      'heal',
      'accept',
      'emotion',
    ],
    mindfulness: ['morning', 'witness', 'body scan', 'thought'],
    theme: meditationThemeKeywords,
    'fitness-focus': ['level-up', 'rest-day', 'fitness-goals', 'peak-performance'],
    'family-natal': ['family', 'natal', 'trimester'],
  },
  cardio: {
    'warm-up': ['warm-up'],
    hiit: ['min-hiit'],
    'music-theme-hiit': cardioThemeMusicHiitKeywords,
    'low-impact': ['low-impact'],
    shadowboxing: ['shadowboxing', 'boxing-basics'],
    'dance-cardio': ['dance-cardio'],
    family: ['family'],
  },
  stretching: {
    'full-body-stretch': ['full-body-stretch'],
    'upper-body-stretch': ['upper-body-stretch'],
    'lower-body-stretch': ['lower-body-stretch', 'glutes-legs-stretch', 'hips-stretch'],
    mobility: ['mobility'],
    'foam-rolling': ['foam-rolling'],
    'boxing-stretch': ['boxing'],
    'dance-cardio-stretch': ['dance-cardio'],
    'pre-post-ride-stretch': ['ride'],
    'pre-run-stretch': ['prerun'],
    'post-run-stretch': ['postrun'],
    'pre-post-row-stretch': ['row'],
    'core-stretch': ['core'],
  },
  cycling: {
    'warm-up': ['warm-up'],
    'cool-down': ['cool-down'],
    'peloton-studio-original': ['on-the-leaderboard'],
    'live-dj': ['live-dj'],
    beginner: ['beginner'],
    'low-impact': ['low-impact'],
    music: cyclingMusicKeywords,
    theme: cyclingThemeKeywords,
    intervals: ['hiit', 'intervals', 'tabata'],
    climb: ['climb'],
    'power-zone': ['power-zone'],
    groove: ['groove'],
    'pro-cyclist': ['pro-cyclist'],
  },
  rowing: {
    'warm-cool': ['warm-up', 'cool-down'],
    beginner: ['beginner'],
    'forms-drills': ['intro-to-form', 'form-drills'],
    'music-theme': rowingMusicThemeKeywords,
    endurance: ['endurance', 'recovery', 'progression'],
    intervals: ['hiit', 'intervals', 'tabata'],
  },
  running: {
    'warm-up': ['warm-up'],
    'cool-down': ['cool-down'],
    'beginner-run': ['beginner'],
    intervals: ['hiit', 'intervals'],
    endurance: ['endurance', 'recovery', 'progression'],
    speed: ['tempo', 'min-hills-run'],
    music: runningMusicKeywords,
    theme: runningThemeKeywords,
    'live-dj': ['live-dj'],
  },
  walking: {
    'warm-up': ['warm-up'],
    'cool-down': ['cool-down'],
    'peloton-studio-original': ['on-the-leaderboard'],
    music: walkingMusicKeywords,
    theme: walkingThemeKeywords,
    'power-walk': ['power-walk'],
    hiking: ['hike'],
    'walk-run': ['walk-run'],
  },
  'outdoor-workout': {
    'warm-cool-run': ['cool-down-run', 'warm-up-run'],
    'warm-cool-walk': ['cool-down-walk', 'warm-up-walk'],
    'music-run': outdoorWorkoutMusicRunKeywords,
    'music-walk': outdoorWorkoutMusicWalkKeywords,
    'theme-run': outdoorWorkoutThemeRunKeywords,
    'theme-walk': [
      'month-walk',
      'day-walk',
      'heritage-walk',
      'holiday-walk',
      'top-50-.*-walk',
      'awareness-walk',
      'disney-walk',
      'celebration-walk',
      'liverpool-fc-walk',
      'disney-walk',
    ],
    'intervals-run': ['intervals', 'hiit'],
    'walk-run': ['walk-run'],
    'endurance-run': ['endurance', 'recovery', 'progression'],
    'power-walk': ['power-walk'],
    'speed-run': ['tempo'],
    podcast: ['fitness-flipped'],
  },
};

export const durationBuckets = {
  '5-10mins': ['5-min', '10-min'],
  '15-20mins': ['15-min', '20-min'],
  '30-45mins': ['30-min', '45-min'],
  '60mins-up': ['60-min', '90-min', '120-min'],
};

export const TValuesFitnessDiscipline = [
  'running',
  'yoga',
  'strength',
  'outdoor-workout',
  'cycling',
  'meditation',
  'rowing',
  'walking',
  'stretching',
  'tread-bootcamp',
  'cardio',
  'bike-bootcamp',
  'row-bootcamp',
] as const;

export const FILTERS_THRESHOLD = 350;
