import { grey, red, white } from '@pelotoncycle/design-system';
import type { TypeComponentGenericListFields } from '@pelotoncycle/page-builder';

export type PageBuilderTheme = NonNullable<TypeComponentGenericListFields['theme']>;

export type GridTheme = Record<
  'backgroundColor' | 'textColor' | 'strikethroughColor' | 'highlightColor',
  string
>;

const themeMap: Partial<Record<PageBuilderTheme, GridTheme>> = {
  'Grey 90': {
    backgroundColor: grey[90],
    textColor: white,
    strikethroughColor: grey[50],
    highlightColor: white,
  },
  White: {
    backgroundColor: white,
    textColor: grey[90],
    strikethroughColor: grey[70],
    highlightColor: red[80],
  },
};

export const DEFAULT_THEME_NAME: PageBuilderTheme = 'White';

export const defaultGridTheme = themeMap[DEFAULT_THEME_NAME]!;

export const toGridTheme = (themeName: PageBuilderTheme): GridTheme => {
  return themeMap[themeName] || defaultGridTheme;
};
