import { Icon, bounce, iconShadow, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media as mediaBreakpoints } from '@peloton/styles/breakpoints';

type ScrollChevronProps = {
  scrollTo: number;
};

const ScrollChevron: React.FC<ScrollChevronProps> = ({ scrollTo }) => {
  return (
    <SpanWrapper>
      <ChevronWrapper
        onClick={() => {
          window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }}
      >
        <StyledIcon name="carouselLine" hasDropShadow={true} primaryColor="white" />
      </ChevronWrapper>
    </SpanWrapper>
  );
};

const SpanWrapper = styled.span`
  position: relative;
`;

const ChevronWrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%) rotate(-90deg);
  left: 50%;
  /* Enforce min target size */
  height: 44px;
  width: 44px;

  bottom: ${spacing[16]};
  ${mediaBreakpoints.desktop`
    bottom: ${spacing[24]};
  `}

  svg {
    pointer-events: none;
  }
`;

const StyledIcon = styled(Icon)`
  animation: ${bounce} 0.8s infinite alternate ease-in-out;
  filter: drop-shadow(${iconShadow});
  height: ${spacing[40]};
`;

export default ScrollChevron;
