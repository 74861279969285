import React from 'react';
import type { TypeComponentProductBlock } from '@page-builder/lib/types';
import { toEntryTags } from '@page-builder/utils/helpers';
import ProductComparisonCards from '../ProductComparisonCards';
import { CheckoutProvider } from '../ProductComparisonCards/CheckoutContext';
import { LIGHT, DARK, ThemeProvider } from '../ProductComparisonCards/themes';
import ProductCards from './ProductCards';

type Props = TypeComponentProductBlock;
export const BILLING_CYCLE_TOGGLE = 'productBlockBillingCycleToggles';

const ProductBlockSwitch: React.FC<React.PropsWithChildren<Props>> = props => {
  const tags = toEntryTags(props);
  const productBlockWithBillingCycleToggle = tags.find(
    tag => tag === BILLING_CYCLE_TOGGLE,
  );
  const hasBackgroundImage = props.fields.panel?.fields.media;
  const theme = hasBackgroundImage ? DARK : LIGHT;

  return !productBlockWithBillingCycleToggle ? (
    <ProductCards data-test-id="product-cards" {...props} />
  ) : (
    <ThemeProvider theme={theme}>
      <CheckoutProvider>
        <ProductComparisonCards data-test-id="product-comparison-cards" {...props} />
      </CheckoutProvider>
    </ThemeProvider>
  );
};

export default ProductBlockSwitch;
