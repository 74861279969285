import { spacing } from '@pelotoncycle/design-system';
import type { OuterContainerProps } from '@pelotoncycle/page-builder';
import type { ColumnsProps } from '@page-builder/components/Columns/Columns';
import { DESKTOP_INLINE_PADDING, CONTAINER_MAXWIDTH } from '@page-builder/modules/tokens';

export type VariantProps = {
  maxWidth?: OuterContainerProps['maxWidth'];
  gap?: ColumnsProps['gap'];
  verticalPadding?: OuterContainerProps['verticalPadding'];
  horizontalPadding?: OuterContainerProps['horizontalPadding'];
};

export const containedProps: VariantProps = {
  maxWidth: CONTAINER_MAXWIDTH,
  gap: spacing[24],
  horizontalPadding: { mobile: 0, desktop: DESKTOP_INLINE_PADDING },
  verticalPadding: { mobile: 0, desktop: spacing[120] },
};

export const fullWidthProps: VariantProps = {
  maxWidth: 'none',
  gap: 0,
  horizontalPadding: 0,
  verticalPadding: 0,
};
