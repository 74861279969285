import React from 'react';
import type { TypeVariation } from '@page-builder/lib/types';
import { NextVariationBlock } from '@page-builder/modules/Block/NextVariationBlock';
import DateTriggerBlock from './DateTriggerBlock';
import OptimizelyBlock from './OptimizelyBlock';
import ProductAvailabilityBlock from './ProductAvailabilityBlock';

type Props = TypeVariation;

const NextVariation: React.FC<React.PropsWithChildren<Props>> = ({ fields }) => {
  const { value, control, variations } = fields;

  if (!value) {
    throw new Error('Value for variation not set in Contentful');
  }

  if ('experimentName' in value.fields) {
    const experimentName = value.fields.experimentName;
    const controlId = 'Original';
    const variationIds = variations
      ? variations.map((_: any, index: number) => `Variation #${index + 1}`)
      : ['Variation #1'];

    const variationModules = variations ? variations : [];
    const experimentOptions = [controlId, ...variationIds];
    const experimentModules = [control, ...variationModules];

    return (
      <>
        {experimentModules.map((moduleData, index) => (
          <OptimizelyBlock
            key={`${experimentName}-option-${index + 1}`}
            experimentName={experimentName}
            variationName={experimentOptions[index]}
            moduleData={moduleData}
            block={NextVariationBlock}
          />
        ))}
      </>
    );
  }

  if ('start' in value.fields) {
    const { start, end, name } = value.fields;
    return (
      <DateTriggerBlock
        name={name}
        start={start}
        end={end}
        control={control}
        variations={variations}
        block={NextVariationBlock}
      />
    );
  }

  if ('productId' in value.fields) {
    const { productId } = value.fields;

    if (productId === 'Apparel') return null;
    if (productId === 'Accessories') return null;

    return (
      <ProductAvailabilityBlock
        productId={productId}
        control={control}
        variations={variations}
        block={NextVariationBlock}
      />
    );
  }

  return null;
};

export default NextVariation;
