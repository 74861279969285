import { StatusCode } from '../api/status';
import { NextAbstractError } from './NextAbstractError';

type NotFoundStatusCode = StatusCode.NOT_FOUND;

export class NextNotFoundError extends NextAbstractError {
  public currentPath = '';
  public newPath = '';
  public statusCode: NotFoundStatusCode = StatusCode.NOT_FOUND;

  constructor({
    message,
    statusCode,
  }: {
    message?: string;
    statusCode?: NotFoundStatusCode;
  }) {
    super(message || 'Not found error');
    Object.setPrototypeOf(this, NextNotFoundError.prototype);

    if (statusCode) this.statusCode = statusCode;
  }
}
