import type { TypeBundlePromo, TypePromo } from '@pelotoncycle/page-builder';
import React from 'react';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useBundlePromo from '@content/client/hooks/useBundlePromo';
import useCfuPromo from '@content/client/hooks/useCfuPromo';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { localizedBasicPackagesMap } from '@ecomm/shop/localizedBasicPackagesMap';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';

type PromoContextType = {
  promo?: TypePromo;
  bundlePromo?: TypeBundlePromo;
  hasNonBasicsPackagePromo?: boolean;
};

const emptyContextValue = {};

const PromoContext = React.createContext<PromoContextType>(emptyContextValue);

const ContentPromoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const locale = useLocale();
  const { packageSlug, productBundleType } = useShopContext();
  const bundleType = productBundleType!;

  const { data: promo } = useCfuPromo(bundleType);
  const { data: bundlePromo } = useBundlePromo(bundleType, packageSlug);

  const value = React.useMemo(() => {
    const basicsPackageSlug = localizedBasicPackagesMap[locale][bundleType];
    const hasNonBasicsPackagePromo = promo?.fields.bundles.some(
      bundle => bundle.fields.couponDiscount && bundle.fields.slug !== basicsPackageSlug,
    );

    return {
      promo,
      bundlePromo,
      hasNonBasicsPackagePromo,
    };
  }, [locale, bundleType, promo, bundlePromo]);

  return <PromoContext.Provider value={value}>{children}</PromoContext.Provider>;
};

const PromoProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const shouldUseContentPromo = useIsToggleActive()('usePromoContentMgmt');
  const { productBundleType } = useShopContext();

  if (shouldUseContentPromo && productBundleType) {
    return <ContentPromoProvider>{children}</ContentPromoProvider>;
  }

  return (
    <PromoContext.Provider value={emptyContextValue}>{children}</PromoContext.Provider>
  );
};

export default PromoProvider;

export const usePromoContext = () => React.useContext(PromoContext);
