import { Container } from '@pelotoncycle/design-system';
import type { TypeComponentCtaFields } from '@pelotoncycle/page-builder/dist/lib/types/TypeComponentCta';
import React from 'react';
import styled from 'styled-components';
import NextContentSizedDiv from '@peloton/content-sized-div/NextContentSizedDiv';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import { PanelContextProvider } from '@page-builder/components/PanelContext';
import toPanelContentComponent from './toPanelContentComponent';
import usePanelContentVisibility from './usePanelContentVisibility';

type Props = {
  moduleId: string;
  panelContent: NonNullable<TypeComponentCtaFields['modal']>;
  isExpanded: boolean;
  toggleExpanded: () => void;
};

const PanelContent: React.FC<Props> = ({
  moduleId,
  panelContent,
  isExpanded,
  toggleExpanded,
}) => {
  const { isVisible, onTransitionEnd } = usePanelContentVisibility(isExpanded);

  const { trackLinkClick } = useTrackLinkClick();
  const togglePanel = () => {
    trackLinkClick({
      href: '',
      parent: 'Poster With Content Panel',
      additionalProps: {
        unitName: 'Close Panel CTA',
        linkName: 'Close',
      },
    });

    toggleExpanded();
  };

  const ContentComponent = toPanelContentComponent(panelContent);

  return (
    <HeightTransition
      onTransitionEnd={onTransitionEnd}
      data-test-id="panel-content-transition-wrapper"
    >
      <Container height={isExpanded ? 'fit-content' : '0'} id={`${moduleId}-panel`}>
        {isVisible && (
          <PanelContextProvider togglePanel={togglePanel}>
            <ContentComponent {...panelContent.fields} />
          </PanelContextProvider>
        )}
      </Container>
    </HeightTransition>
  );
};

const HeightTransition = styled(NextContentSizedDiv)`
  transition: 500ms height ease;
`;

export default PanelContent;
