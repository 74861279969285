import { spacing } from '@pelotoncycle/design-system';
import type { CSSProperties } from 'react';

/**
 * Layout tokens
 */
export const DESKTOP_INLINE_PADDING = spacing[40];
export const CONTAINER_MAXWIDTH = `1224px`;

/**
 * Text block styles
 */
export const TEXT_MAXWIDTH = {
  mobile: `440px`,
  tablet: `440px`,
  desktop: `680px`,
};

/**
 * Padding values
 */
export type ResponsivePaddingTypes = {
  mobile: CSSProperties['paddingBlock'];
  tablet: CSSProperties['paddingBlock'];
  desktop: CSSProperties['paddingBlock'];
};

export type VerticalPaddingTypes = {
  default: ResponsivePaddingTypes;
  min: ResponsivePaddingTypes;
  mid: ResponsivePaddingTypes;
  max: ResponsivePaddingTypes;
  none: ResponsivePaddingTypes;
};

export type VerticalPaddingOptions = keyof VerticalPaddingTypes;

export const VERTICAL_PADDING: VerticalPaddingTypes = {
  default: {
    mobile: spacing[48],
    tablet: spacing[64],
    desktop: spacing[80],
  },
  min: {
    mobile: spacing[32],
    tablet: spacing[64],
    desktop: `${spacing[80]} ${spacing[32]}`,
  },
  mid: {
    mobile: spacing[48],
    tablet: spacing[80],
    desktop: `${spacing[104]} ${spacing[40]}`,
  },
  max: {
    mobile: spacing[48],
    tablet: spacing[80],
    desktop: `${spacing[120]} ${spacing[40]}`,
  },
  none: {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  },
};
