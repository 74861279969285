import { useApolloClient as useApolloClientV3 } from '@apollo/client';
import { USE_APOLLO_V3 } from '@peloton/app-config';
import type { DataProxyV2 as DataProxy } from '@peloton/graphql/apolloV2';
import { useApolloClientV2 } from '@peloton/graphql/apolloV2';
import type { Resolvers } from './types.generated';

let useApolloClient = useApolloClientV2;
if (USE_APOLLO_V3) {
  // @ts-expect-error
  useApolloClient = useApolloClientV3;
}

export type LocalResolvers<T, R, S> = {
  resolvers: Resolvers<T> | Resolvers<T>[];
  defaults?: DataProxy.WriteQueryOptions<T, S>[];
};

export const useLocalResolvers = <T, R, S>(
  localResolvers: LocalResolvers<T, R, S>,
  shouldAdd: boolean = true,
) => {
  const client = useApolloClient();
  // TODO: fix any's with Apollo first-party hooks
  if (shouldAdd) {
    client.addResolvers(localResolvers.resolvers as any);
    localResolvers.defaults &&
      localResolvers.defaults.map(query => client.writeQuery(query));
  }
};
