import { HeadbandGuestPass } from '@prospects/guest-pass/components/GuestPass/GuestPass';
import type { GuestPassInfo } from '@prospects/guest-pass/hooks/useGuestPassInfo';
import React, { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import {
  HeadbandContainer,
  HeadbandCtaWithInfo,
  HeadbandTitle,
} from '@page-builder/modules/Headband';
import { zIndex } from '@page-builder/modules/Headband/constants';
import type { HeadbandCtaWithInfoProps } from '@page-builder/modules/Headband/HeadbandCtaWithInfo';
type Props = {
  name?: string;
  title?: string;
  headbandTitle?: string;
  guestPass: GuestPassInfo | null;
  cta: HeadbandCtaWithInfoProps['cta'];
  financingText?: string;
  disclaimerText?: string;
  shouldShowHeadband?: boolean;
};
export const ClassHeadband: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  cta,
  guestPass,
  financingText,
  headbandTitle,
  disclaimerText,
  shouldShowHeadband = false,
}) => {
  const { trackEvent } = useTracking();

  const trackCTAClick = useCallback(
    (ctaTitle?: string, href?: string) => {
      trackEvent({
        event: 'Clicked App CTA in Headband',
        properties: {
          parent: 'ClassHeadband',
          linkTo: href ?? '',
          linkName: ctaTitle ?? '',
          unitName: cta?.text,
          parentType: 'Component: CTA',
        },
      });
    },
    [cta, trackEvent],
  );

  return (
    <StyledHeadbandContainer
      data-test-id="headbandWrapper"
      shouldShowHeadband={shouldShowHeadband}
      aria-hidden={!shouldShowHeadband}
      // @ts-expect-error: This property is safe to use but not properly typed yet.
      inert={shouldShowHeadband ? null : ''}
    >
      {guestPass?.username && headbandTitle ? (
        <HeadbandGuestPass guestPass={guestPass} guestPassLabel={headbandTitle} />
      ) : (
        <HeadbandTitle title={title} />
      )}
      <HeadbandCtaWithInfo
        cta={{
          ...cta,
          onClick: () => {
            trackCTAClick(cta?.text, cta?.href);
            cta?.onClick?.();
          },
        }}
        infoHeading={financingText}
        infoText={disclaimerText}
      />
    </StyledHeadbandContainer>
  );
};
type HeadbandProps = {
  shouldShowHeadband: boolean;
};
const StyledHeadbandContainer = styled(HeadbandContainer)<HeadbandProps>`
  z-index: ${props => (props.shouldShowHeadband ? `${zIndex.HEADBAND}` : 0)};
  transition: opacity 0.15s ease-in-out 0.15s;
  opacity: ${props => (props.shouldShowHeadband ? '1' : '0')};
  overflow: hidden;
`;
