import React from 'react';
import type {
  TypeComponentCta,
  TypeComponentGenericList,
  TypeComponentGenericTextWithMedia,
  TypeComponentGenericTextWithMediaFields,
} from '@page-builder/lib/types';
import MediaCard from './MediaCard';
import MiniHero from './MiniHero';

export type GalleryData = {
  media?: TypeComponentGenericTextWithMedia[];
  unitName: string;
  modalTitle?: string;
  textItems?: TypeComponentGenericTextWithMedia[];
  ctas?: TypeComponentCta[];
};

export const toGalleriesMap = (galleries: TypeComponentGenericList[]) => {
  return galleries.reduce((accumulator, gallery) => {
    const { fields } = gallery;
    const theme = fields.theme?.toLowerCase() || 'default';
    const items = gallery && separateItemsByTreatment(gallery, 'Specs View');

    accumulator.set(theme, {
      media: items.defaultItems,
      unitName: fields.name,
      modalTitle: fields.text?.fields.eyebrow,
      textItems: items.itemsWithTreatment,
      ctas: fields.ctas,
    });
    return accumulator;
  }, new Map());
};
export const separateItemsByTreatment = (
  list: TypeComponentGenericList,
  treatment: TypeComponentGenericTextWithMediaFields['treatment'],
) => {
  const {
    fields: { items },
  } = list;

  const itemsWithTreatment = items.filter(item => item.fields.treatment === treatment);

  const defaultItems = items.filter(item => item.fields.treatment !== treatment);

  return { itemsWithTreatment, defaultItems };
};

export const applyMediaTreatment = (
  array: TypeComponentGenericTextWithMedia[],
  onImageClick?: (index: number) => void,
) => {
  return array.map((item, index) => {
    const {
      fields: { treatment, media, ctas, name },
    } = item;
    switch (treatment) {
      case 'CTA Banner':
        return (
          <MiniHero key={`${name}-${index}`} media={media} ctas={ctas} name={name} />
        );
      default:
        return (
          <MediaCard
            key={`${name}-${index}`}
            media={media}
            onClick={onImageClick ? () => onImageClick(index) : undefined}
          />
        );
    }
  });
};
