import { spacing, Grid, ErrorMsg, CheckboxBox } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useMicroCopy } from '@content/client/microCopy';

import { useProductConfigurationContext } from '../ProductConfigurationContext';
import type { AccessoryWithMetaData } from './types';

const ErrorMsgContainer = styled.div`
  margin-top: ${spacing[8]};
`;

const MutliSelectCheckboxGroup: React.FC<
  React.PropsWithChildren<{
    accessory: AccessoryWithMetaData;
  }>
> = ({ accessory }) => {
  const selectMicroCopy = useMicroCopy('selectQuantity');
  const {
    setProductConfigurations,
    productConfigurations,
    clearSelectedConfiguration,
    misconfiguredProducts,
    trackProductConfigurationSelection,
  } = useProductConfigurationContext();
  const selectedAmount = Object.keys(productConfigurations[accessory.slug] ?? {}).length;

  const hasError = !!misconfiguredProducts[accessory.slug];

  const handleCheckboxClick = (selectedVariant: string, name: string, slug: string) => {
    const variant = accessory.attributeSlugToVariant[slug];
    if (selectedVariant) {
      clearSelectedConfiguration(accessory.slug, name);
    } else {
      setProductConfigurations({
        accessorySlug: accessory.slug,
        productSlug: name,
        variant,
      });
    }

    trackProductConfigurationSelection(accessory, variant, !selectedVariant);
  };

  return (
    <Grid
      columnCount={3}
      rowCount={3}
      gap={spacing[8]}
      data-test-id="product-configuration-checkbox-group"
    >
      {accessory.attributes[0].options.map(({ name, slug }, i) => {
        const selectedVariant =
          productConfigurations[accessory.slug]?.[name]?.configurations[0].option;
        const isDisabled = !selectedVariant && selectedAmount == accessory.occurrence;

        return (
          <CheckboxBox
            key={slug}
            data-test-id={`${accessory.slug}-${i}`}
            hasError={hasError}
            errorMessage={hasError && 'error'}
            theme="light"
            labelText={name}
            name="select all"
            checked={selectedVariant === slug}
            disabled={isDisabled}
            onClick={() => handleCheckboxClick(selectedVariant, name, slug)}
          />
        );
      })}
      {hasError && (
        <ErrorMsgContainer
          aria-live="polite"
          data-test-id="product-configuration-checkbox-group-error-box"
        >
          <ErrorMsg id={'select-group-error'}>
            {`${selectMicroCopy} ${accessory.occurrence}`}
          </ErrorMsg>
        </ErrorMsgContainer>
      )}
    </Grid>
  );
};

export default MutliSelectCheckboxGroup;
