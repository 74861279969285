import React from 'react';
import styled from 'styled-components';
import type { BaseModalProps } from '@ecomm/modal/BaseModal';
import VideoContainer from '@ecomm/video/VideoContainer';
import NextModal from '@page-builder/modules/NextModal';

export type VideoModalProps = BaseModalProps & {
  darkMode?: boolean;
  withContent?: boolean;
};

const VideoModal: React.FC<React.PropsWithChildren<VideoModalProps>> = ({
  isOpen = true,
  contentLabel,
  closeHandler,
  openHandler,
  darkMode,
  children,
  withContent,
  ...props
}) => {
  const handleAfterOpen = () => {
    const video = document.getElementsByClassName('modal-video')[0];
    video.addEventListener('webkitendfullscreen', closeHandler);
  };

  const handleRequestClose = () => {
    const video = document.getElementsByClassName('modal-video')[0];
    video.removeEventListener('webkitendfullscreen', closeHandler);
  };

  return (
    <NextModal
      isOpen={isOpen}
      role="dialog"
      contentLabel={contentLabel}
      className="video-modal"
      closeHandler={closeHandler}
      closeTimeoutMS={400}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      darkMode={darkMode}
      {...props}
    >
      <VideoWrapper>
        {withContent ? (
          children
        ) : (
          <VideoContainer ratio="16:9">{children}</VideoContainer>
        )}
      </VideoWrapper>
    </NextModal>
  );
};

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
`;

export default VideoModal;
