import { Flex, spacing } from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { IconCta, UpperTextBlock } from '@page-builder/components';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import { DESKTOP_INLINE_PADDING, VERTICAL_PADDING } from '@page-builder/modules/tokens';
import { themes } from '@page-builder/themes';
import { getTextFieldsWithRequiredKeys } from '@page-builder/utils/helpers';

const TextWithMultipleIcons: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text, treatment, name: parentName }) => {
  const { eyebrow: eyebrowText, headline: headlineText } = getTextFieldsWithRequiredKeys(
    ['eyebrow', 'headline'],
    text,
  );
  const whiteTheme = treatment === 'Text With Multiple Icons';
  const theme = whiteTheme ? 'White' : 'Grey 90';
  const { backgroundColor, headlineColor } = themes[theme];
  const hasLinks = items[0]?.fields?.ctas?.[0]?.fields.link;

  return (
    <OuterContainer
      backgroundColor={backgroundColor}
      horizontalPadding={{
        mobile: spacing[32],
        tablet: 0,
        desktop: DESKTOP_INLINE_PADDING,
      }}
      verticalPadding={VERTICAL_PADDING['max']}
    >
      <Flex flexDirection="column" alignItems="center">
        {headlineText && (
          <UpperTextBlock
            eyebrowText={eyebrowText}
            eyebrowTextColor={headlineColor}
            headlineText={headlineText}
            headlineTextColor={headlineColor}
            margin={{
              mobile: `0 auto ${spacing[40]}`,
              desktop: `0 auto ${spacing[64]}`,
            }}
          />
        )}
        <StyledFlex
          maxWidth={{
            mobile: `${hasLinks ? '348px' : '320px'}`,
            tablet: '560px',
            desktop: `${hasLinks ? '814px' : '600px'}`,
          }}
          flexWrap="wrap"
          justifyContent={`${hasLinks ? 'center' : 'space-evenly'}`}
          gap={
            hasLinks
              ? {
                  mobile: spacing[8],
                  desktop: spacing[16],
                }
              : {
                  mobile: '28px',
                  desktop: '44px',
                }
          }
        >
          {items.map((value, index) => {
            const { text: iconText, ctas, name } = value.fields;
            const link = ctas && ctas[0].fields.link;
            const ctaIcon = ctas && ctas[0].fields.ctaIcon;

            return (
              <IconCta
                name={name}
                parentName={parentName}
                key={index}
                icon={ctaIcon}
                text={iconText}
                theme={theme}
                link={link}
              />
            );
          })}
        </StyledFlex>
      </Flex>
    </OuterContainer>
  );
};

export default TextWithMultipleIcons;

const StyledFlex = styled(Flex)`
  ${media.desktop`
    margin-bottom: ${spacing[80]};
  `};
`;
