import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import { BreakpointWidth } from '@peloton/styles';
import { INTERSECTION_RATIO } from './constants';
import type { PaginationProps } from './Pagination';
/**
 * Calculates and returns the position of the active item in the scrolling container to be scrolled and aligned to the center.
 * The only exception is the first and last item, which should be scrolled and flushed to their respective ends.
 * This function gets the ratio of the item width to scroll container width to decide what calculation is needed to achieve the right scrollToPosition within (this ratio changes based on the size of the view).
 * When the ratio is GREATER than 0.51, then the card item will take up most of the parent container. So it will need a greater offset position inorder for the next card to be able to reach the threshold of snapping to the center.
 * When the ratio is LESS than 0.51, then more cards will fit into the container. Therefore, the scroll container has a smaller threshold for the next card to reach the center.
 * EDGE CASE: when there are a MAX OF 3 ITEMS in the carousel AND the ratio is LESS than 0.51. The amount needed for the scrollTo is slightly less than the usual calculation, so we just use 1/3 of the width of the scroll container.
 * @param activeItem the HTML element of the active card item
 * @param scrollContainerWidth the offsetWidth (in pixels) of the scrolling container that holds the card items
 * @param selectedIndex the selected index of the item that is set to be active
 * @param totalNumberOfItems the total number of items present in the carousel
 * @returns the left position needed for the active element to scroll to the center of the carousel
 */
export const getScrollToLeftPosition = (
  activeItem: HTMLElement,
  scrollContainerWidth: number,
  selectedIndex: number,
  totalNumberOfItems: number,
) => {
  const cardWidth = activeItem.offsetWidth;
  const activeCardLeftPosition = activeItem.offsetLeft;

  if (selectedIndex === 0) {
    return 0;
  }

  if (selectedIndex === totalNumberOfItems - 1) {
    return activeCardLeftPosition;
  }

  const itemToParentRatio = cardWidth / scrollContainerWidth;
  // Need to account for ALL the gap spacing to offset the carousel correctly.
  const gapSpacing = activeCardLeftPosition % cardWidth;

  if (itemToParentRatio > 0.51) {
    return Math.floor(activeCardLeftPosition - gapSpacing);
  }

  // EDGE CASE: calculating scrollTo amount for the 2nd card if there are 3 items and the width of the card is less than half the width of the scroll container.
  if (totalNumberOfItems === 3) {
    return activeCardLeftPosition / 3;
  }

  const halfWidth = cardWidth / 2;
  return Math.floor(activeCardLeftPosition - gapSpacing - halfWidth);
};

/**
 * Calculates and returns the margin bounds that restrict and define the intersection area for a card element.
 * The intersection area is the area that the card element must intersect with in order to trigger the intersection observer callback.
 * The margin bounds are calculated based on the position of the card element within the carousel and the width of the scrolling container.
 * The margin bounds are used to define the root margin property of the intersection observer options.
 * The special case is the last card in the carousel, which mirros the bounds of the first cards position but respesctive to the right edge of the container.
 * @param scrollContainerWidth the offsetWidth (in pixels) of the scrolling container that holds the card items
 * @param cardElement the HTML element of the card item to be observed
 * @param cardIndex the index of the card within the list of carousel cards that is to be observed
 * @param totalCardsLength the total number of cards in the carousel
 * @returns the margin bounds that restrict and define the intersection area for the card element
 */
export const calculateRootMargin = (
  scrollContainerElement: HTMLDivElement,
  cardElement: HTMLElement,
  cardIndex: number,
  totalCardsLength: number,
) => {
  const scrollContainerWidth = scrollContainerElement.offsetWidth;
  const cardWidth = cardElement.offsetWidth;
  const halfCardWith = Math.round(cardWidth / 2);
  const containerEdgeCardOffset = scrollContainerWidth - cardWidth - halfCardWith;

  if (window.innerWidth < BreakpointWidth.tablet) {
    return `0px 0px 0px 0px`;
  }

  if (cardIndex === 0) {
    return `0px -${containerEdgeCardOffset}px 0px 0px`;
  } else if (cardIndex === totalCardsLength - 1) {
    return `0px 0px 0px -${containerEdgeCardOffset}px`;
  } else {
    // Distance from edge of window to edge of middle card (excluding scroll bar width)
    const centerCardOffset = Math.round(
      (document.documentElement.clientWidth - cardWidth) / 2,
    );

    return `0px -${centerCardOffset}px 0px -${centerCardOffset}px`;
  }
};

export const debounceTimer = <Args extends any[]>(
  callback: (...args: Args) => void,
  waitTime: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Args) => {
    clearTimeout(timeoutId!);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, waitTime);
  };
};

/**
 * Takes in the following arguments and returns an IntersectionObserver for the carousel.
 * This calculates the root margin for either the first, last or cards in the middle allows the user to set cards active through scroll.
 * @param scrollContainerRef ref of the scroll container in the carousel.
 * @param cardElement ref of the card element in the carousel.
 * @param cardIndex the index of the card.
 * @param totalCardsLength the total number of card refs.
 * @param handleActiveCardOnScroll function that handles the action setting an active card on scroll.
 * @returns IntersectionObserver | null
 */
export const getObserver = (
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  cardElement: HTMLElement | null,
  cardIndex: number,
  totalCardsLength: number,
  handleActiveCardOnScroll: (entries: IntersectionObserverEntry[]) => void,
) => {
  if (scrollContainerRef.current && cardElement) {
    const isMiddleCard = cardIndex !== 0 && cardIndex !== totalCardsLength - 1;

    const rootMargin = calculateRootMargin(
      scrollContainerRef.current,
      cardElement,
      cardIndex,
      totalCardsLength,
    );

    const observerOptions: IntersectionObserverInit = {
      root: isMiddleCard ? null : scrollContainerRef.current,
      threshold: INTERSECTION_RATIO,
      rootMargin,
    };

    return new IntersectionObserver(handleActiveCardOnScroll, observerOptions);
  }

  return null;
};

/**
 * Takes in the following params and returns the prop values needed for the Pagination component.
 * @param activeIndex the index of the active card in the carousel.
 * @param totalNumberOfItems the total number of items in the carousel.
 * @param paginationTextRaw a string with markdown variables that is formatted to the pagination text in order to denote the slide position of the user.
 * @param handleActiveItem function that takes in an index and handles all the actions and setters for when a user goes to the next or previous video.
 * @returns PaginationProps
 */
export const usePaginationPropValues = (
  activeIndex: number,
  totalNumberOfItems: number,
  paginationTextRaw: string,
  handleActiveItem: (index: number) => void,
): PaginationProps => {
  const toFormattedText = useGetTextFormatter();
  /**
   * Formats the paginationTextRaw entry to denote the current slide position.
   */
  const renderPaginationText = () => {
    return toFormattedText(paginationTextRaw, {
      currentSlide: activeIndex + 1,
      totalSlides: totalNumberOfItems,
    });
  };

  const handleNextItem = () => {
    if (activeIndex < totalNumberOfItems - 1) {
      const nextIndex = activeIndex + 1;
      handleActiveItem(nextIndex);
    }
  };

  const handlePreviousItem = () => {
    if (activeIndex > 0) {
      const prevIndex = activeIndex - 1;
      handleActiveItem(prevIndex);
    }
  };

  return {
    activeIndex: activeIndex,
    lastItemIndex: totalNumberOfItems - 1,
    formattedPaginationText: renderPaginationText(),
    handleNext: handleNextItem,
    handlePrevious: handlePreviousItem,
  };
};
